import {HeatMapConditionInitOptions, JsonObject} from '../types';
import {Color} from './Color';

/**
 * ヒートマップのコンディション
 */
class HeatMapCondition {
  readonly geoJson: JsonObject;
  readonly colorMap: Map<number, Color>;
  readonly weightCallback: (feature: JsonObject) => number;
  readonly opacityCallback: (zoomLevel: number) => number;
  readonly radiusCallback: (weight: number) => number;

  /**
   * コンストラクタ
   * @param options HeatMapConditionInitOptions
   */
  constructor(options: HeatMapConditionInitOptions) {
    this.geoJson = options.geoJson;
    this.colorMap = options.colorMap;
    this.weightCallback = options.weightCallback;
    this.opacityCallback = options.opacityCallback ?? ((): number => 1.0);
    this.radiusCallback = options.radiusCallback ?? ((): number => 40);
  }
}

export {HeatMapCondition};
