import {JsonObject} from '../../../../../gaia/types';
import {Color} from '../../../../../gaia/value';
import {Optional} from '../../../../common/types';
import {createMap, GltfId} from '../../GltfReader';
import {GltfMaterial} from './GltfMaterial';
import {GltfPbrMetallicRoughness} from './GltfPbrMetallicRoughness';

/**
 * 色の読み込み
 * @param json 色が記載されたJSON
 * @returns 読み込み結果
 */
const readColor = (json: JsonObject): Optional<Color> => {
  if (Array.isArray(json) && json.length === 4) {
    return new Color(json[0], json[1], json[2], json[3]);
  }
  return undefined;
};

/**
 * pbrMetallicRoughnessの読み込み
 * @param json JSON
 * @returns 読み込み結果
 */
const readPbrMetallicRoughness = (json: JsonObject): Optional<GltfPbrMetallicRoughness> => {
  const baseColorObject = json.baseColorFactor;
  const baseColor = readColor(baseColorObject);
  if (!baseColor) {
    return undefined;
  }
  const metallicFactor = json.metallicFactor ?? 0;
  const roughnessFactor = json.roughnessFactor ?? 1;
  const pbrMetallicRoughness = new GltfPbrMetallicRoughness(baseColor, metallicFactor, roughnessFactor);
  return pbrMetallicRoughness;
};

/**
 * JsonObjectからマテリアルを作成する
 * @param materialObject オブジェクト
 * @returns マテリアル、オブジェクトの情報が不十分な場合は `undefined`
 */
const createMaterialFromJsonObject = (materialObject: JsonObject): Optional<GltfMaterial> => {
  const pbrMetallicRoughness = readPbrMetallicRoughness(materialObject.pbrMetallicRoughness);
  const name = materialObject.name;
  if (!pbrMetallicRoughness) {
    return undefined;
  }
  return new GltfMaterial(pbrMetallicRoughness, name);
};

/**
 * materials
 */
class GltfMaterialsReader {
  private materials: Map<GltfId, GltfMaterial>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.materials = new Map();
  }

  /**
   * パース
   * @param json glTFのmaterials部分
   * @returns 成功すれば `true` , そうでなければ `false`
   */
  parse(json: JsonObject): boolean {
    const materials = createMap(json, createMaterialFromJsonObject);
    if (!materials) {
      return false;
    }
    this.materials = materials;
    return true;
  }

  /**
   * マテリアルを取得
   * @param key キー
   * @returns キーに対応するマテリアル
   */
  get(key: GltfId): Optional<GltfMaterial> {
    return this.materials.get(key);
  }
}

export {GltfMaterialsReader};
