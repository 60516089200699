import {JsonObject} from '../../../../../gaia/types';
import {Optional} from '../../../../common/types';
import {createMap, GltfId} from '../../GltfReader';
import {GltfMesh} from './GltfMesh';
import {GltfPrimitive, readPrimitiveRenderMode} from './GltfPrimitive';

/**
 * プリミティブの読み込み
 * @param json glTFのプリミティブ部分
 * @returns プリミティブのマップ、情報が不足していれば `undefined`
 */
const readPrimitives = (json: JsonObject): Optional<Map<GltfId, GltfPrimitive>> => {
  if (!json) {
    return undefined;
  }

  const primitives: Map<GltfId, GltfPrimitive> = new Map();
  for (const [primitiveId, primitiveObject] of Object.entries(json)) {
    const attributes = (primitiveObject as JsonObject).attributes;
    const mode = readPrimitiveRenderMode((primitiveObject as JsonObject).mode ?? 4);
    if (mode === undefined) {
      continue;
    }
    const indices = (primitiveObject as JsonObject).indices;
    const material = (primitiveObject as JsonObject).material;
    const primitive = new GltfPrimitive(attributes, mode, indices, material);
    primitives.set(primitiveId, primitive);
  }
  return primitives;
};

/**
 * JsonObjectからメッシュを作成する
 * @param meshObject オブジェクト
 * @returns メッシュ、オブジェクトの情報が不十分な場合は `undefined`
 */
const createMeshFromJsonObject = (meshObject: JsonObject): Optional<GltfMesh> => {
  const primitives = readPrimitives((meshObject as JsonObject).primitives);
  const name = meshObject.name;
  if (!primitives) {
    return undefined;
  }
  return new GltfMesh(primitives, name);
};

/**
 * glTFのメッシュ
 */
class GltfMeshesReader {
  private meshes: Map<GltfId, GltfMesh>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.meshes = new Map();
  }

  /**
   * パース
   * @param json glTFのmeshes部分
   * @returns パースが成功すれば `true` , そうでなければ `false`
   */
  parse(json: JsonObject): boolean {
    const meshes = createMap(json, createMeshFromJsonObject);
    if (!meshes) {
      return false;
    }
    this.meshes = meshes;
    return true;
  }

  /**
   * メッシュを取得
   * @param key キー
   * @returns キーに対応するメッシュ
   */
  get(key: GltfId): Optional<GltfMesh> {
    return this.meshes.get(key);
  }
}

export {GltfMeshesReader};
