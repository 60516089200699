/**
 * glTFのバッファ
 */
class GltfBuffer {
  readonly uri: string;
  readonly byteLength: number;

  /**
   * コンストラクタ
   * @param uri URI(base64文字列など)
   * @param byteLength バイト長
   */
  constructor(uri: string, byteLength: number) {
    this.uri = uri;
    this.byteLength = byteLength;
  }
}

export {GltfBuffer};
