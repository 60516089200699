import {Vector2} from './Vector2';

/**
 * 2次元矩形クラス
 */
class Rect2 {
  private _topLeft: Vector2;
  private _bottomRight: Vector2;

  /**
   * コンストラクタ
   * @param topLeft 左上の座標
   * @param bottomRight 右下の座標
   */
  constructor(topLeft: Vector2, bottomRight: Vector2) {
    this._topLeft = topLeft.clone();
    this._bottomRight = bottomRight.clone();
  }

  /**
   * 上の座標
   */
  get top(): number {
    return this._topLeft.y;
  }

  /**
   * 左の座標
   */
  get left(): number {
    return this._topLeft.x;
  }

  /**
   * 下の座標
   */
  get bottom(): number {
    return this._bottomRight.y;
  }

  /**
   * 右の座標
   */
  get right(): number {
    return this._bottomRight.x;
  }

  /**
   * 左上の座標
   */
  get topLeft(): Vector2 {
    return this._topLeft;
  }

  /**
   * 右下の座標
   */
  get bottomRight(): Vector2 {
    return this._bottomRight;
  }
}

export {Rect2};
