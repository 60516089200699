import {PaletteInfoElement} from '_private/common/infra/response/RoadShapeOpenedInfo';
import {RoadShapeOpenedLineStyle, RoadShapeOpenedLineStyleOption} from '../types';
import {Color} from './Color';

/**
 * 道路の見た目情報
 */
class RoadShapeOpenedAppearance {
  /** 描画スタイル */
  readonly style: RoadShapeOpenedLineStyle;
  /** 表示状態 */
  readonly visible: boolean;

  /**
   * コンストラクタ
   * @param style 描画スタイル
   * @param visible 表示状態
   */
  constructor(style: RoadShapeOpenedLineStyle, visible: boolean) {
    this.style = style;
    this.visible = visible;
  }

  /**
   * RoadShapeOpenedAppearanceを生成
   * @param palette NotePaletteConfig
   * @returns TextAnnotationData
   */
  static createRoadShapeOpenedAppearance(palette: PaletteInfoElement): RoadShapeOpenedAppearance | undefined {
    const fill = palette.geometryFill;
    const outline = palette.geometryOutline;

    if (!fill || !outline) {
      return;
    }

    const fc = fill.color;
    const oc = outline.color;

    // TODO dashArray考慮
    const inlineStyleOption: RoadShapeOpenedLineStyleOption = {
      visible: fill.visible,
      color: new Color(fc.r / 255, fc.g / 255, fc.b / 255, fc.a / 255),
      weight: fill.size * 2,
    };
    const outlineStyleOption: RoadShapeOpenedLineStyleOption = {
      visible: false, // 新規開通道路では縁線を強制無効化
      color: new Color(oc.r / 255, oc.g / 255, oc.b / 255, oc.a / 255),
      weight: outline.size * 2,
    };

    const lineStyle: RoadShapeOpenedLineStyle = {inline: inlineStyleOption, outline: outlineStyleOption};

    return new RoadShapeOpenedAppearance(lineStyle, true);
  }
}

export {RoadShapeOpenedAppearance};
