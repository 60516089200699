import {Vector3} from './Vector3';
import {Optional} from '../types';

/**
 * 3次元レイ
 */
class Ray3 {
  start: Vector3;
  direction: Vector3;

  /**
   * コンストラクタ
   * @param start 始点
   * @param direction 方向ベクトル
   */
  constructor(start: Vector3, direction: Vector3) {
    this.start = start;
    this.direction = direction;
  }

  /**
   * レイを伸ばした際に、指定したx座標になる点を計算
   * @param x x座標
   * @returns 衝突する場合はその点、そうでない場合はnull
   */
  intersectsWithPlaneXEqualsParameter(x: number): Optional<Vector3> {
    if (this.start.x === x) {
      return this.start;
    }

    if (this.direction.x === 0) {
      return null;
    }

    if (this.start.x < x && this.direction.x < 0) {
      return null;
    }

    if (this.start.x > x && this.direction.x > 0) {
      return null;
    }

    const coefficient = (x - this.start.x) / this.direction.x;
    return this.start._add(this.direction._multiply(coefficient));
  }

  /**
   * レイを伸ばした際に、指定したx座標になる点を計算
   * @param y y座標
   * @returns 衝突する場合はその点、そうでない場合はnull
   */
  intersectsWithPlaneYEqualsParameter(y: number): Optional<Vector3> {
    if (this.start.y === y) {
      return this.start;
    }

    if (this.direction.y === 0) {
      return null;
    }

    if (this.start.y < y && this.direction.y < 0) {
      return null;
    }

    if (this.start.y > y && this.direction.y > 0) {
      return null;
    }

    const coefficient = (y - this.start.y) / this.direction.y;
    return this.start._add(this.direction._multiply(coefficient));
  }

  /**
   * レイを伸ばした際に、指定したx座標になる点を計算
   * @param z z座標
   * @returns 衝突する場合はその点、そうでない場合はnull
   */
  intersectsWithPlaneZEqualsParameter(z: number): Optional<Vector3> {
    if (this.start.z === z) {
      return this.start;
    }

    if (this.direction.z === 0) {
      return null;
    }

    if (this.start.z < z && this.direction.z < 0) {
      return null;
    }

    if (this.start.z > z && this.direction.z > 0) {
      return null;
    }

    const coefficient = (z - this.start.z) / this.direction.z;
    return this.start._add(this.direction._multiply(coefficient));
  }
}

export {Ray3};
