/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {mat4} from 'gl-matrix';
import {Material} from '../material/Material';
import {Vector3} from '../../common/math/Vector3';
import {Quaternion} from '../../common/math/Quaternion';
import {RenderTarget} from '../../common/types';

/**
 * 3次元オブジェクト
 */
class Object3D {
  private _position: Vector3;
  private _rotation: Quaternion;
  private _scale: Vector3;

  private _material: Material;

  private _visible: boolean;

  /**
   * コンストラクタ
   * @param position 座標
   * @param rotation 回転
   * @param scale 拡縮
   * @param material マテリアル
   * @param visible 可視状態
   */
  constructor(position: Vector3, rotation: Quaternion, scale: Vector3, material: Material, visible = true) {
    this._position = position.clone();
    this._rotation = rotation.clone();
    this._scale = scale.clone();
    this._material = material;
    this._visible = visible;
  }

  /**
   * 位置
   */
  get position(): Vector3 {
    return this._position;
  }

  /**
   * 回転
   */
  get rotation(): Quaternion {
    return this._rotation;
  }

  /**
   * 拡縮
   */
  get scale(): Vector3 {
    return this._scale;
  }

  /**
   * マテリアルを取得
   * @returns マテリアル
   */
  getMaterial(): Material {
    return this._material;
  }

  /**
   * 位置を設定
   * @param position 位置
   * @returns {void}
   */
  setPosition(position: Vector3): void {
    this._position = position;
  }

  /**
   * 位置を設定
   * @param x x座標の値
   * @param y y座標の値
   * @param z z座標の値
   * @returns {void}
   */
  setPositionValues(x: number, y: number, z: number): void {
    this._position.setValues(x, y, z);
  }

  /**
   * 拡縮を設定
   * @param scale 拡縮
   * @returns {void}
   */
  setScale(scale: Vector3): void {
    this._scale = scale;
  }

  /**
   * 拡縮を設定
   * @param x 拡縮ベクトルのxの値
   * @param y 拡縮ベクトルのyの値
   * @param z 拡縮ベクトルのzの値
   * @returns {void}
   */
  setScaleValues(x: number, y: number, z: number): void {
    this._scale.setValues(x, y, z);
  }

  /**
   * 回転を設定
   * @param rotation 回転
   * @returns {void}
   */
  setRotation(rotation: Quaternion): void {
    this._rotation = rotation;
  }

  /**
   * 表示状態を取得
   * @returns 表示状態
   */
  isVisible(): boolean {
    return this._visible;
  }

  /**
   * 表示状態を設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this._visible = visible;
  }

  /**
   * 更新
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(viewMatrix: mat4, projectionMatrix: mat4): void {
    this._material.update(this, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @param renderTarget 描画対象の設定
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    this._material.draw(renderTarget);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this._material.destroy();
  }
}

export {Object3D};
