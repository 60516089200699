import {CacheKey, Optional} from '../types';

/**
 * シンプルキャッシュ
 */
class SimpleCache<K extends CacheKey, V> {
  private _keys: {[key: string]: K};
  private _values: {[key: string]: V};
  private _size = 0;

  /**
   * コンストラクタ
   */
  constructor() {
    this._keys = {};
    this._values = {};
  }

  /**
   * キャッシュに追加
   * @param key Key
   * @param value Value
   * @returns {void}
   */
  add(key: K, value: V): void {
    if (!this.has(key)) {
      this._size++;
    }
    const k = key.getCacheKey();
    this._keys[k] = key;
    this._values[k] = value;
  }

  /**
   * キャッシュから削除
   * @param key 削除する対象のKey
   * @returns 削除できれば `true` 対象がなく削除していない場合は `false`
   */
  remove(key: K): boolean {
    if (this.has(key)) {
      const k = key.getCacheKey();
      delete this._keys[k];
      delete this._values[k];
      this._size--;

      return true;
    } else {
      return false;
    }
  }

  /**
   * キャッシュからオブジェクト取得
   * @param key キャッシュのKey
   * @returns Keyに該当するオブジェクト
   */
  get(key: K): Optional<V> {
    return this._values[key.getCacheKey()];
  }

  /**
   * 要素がキャッシュされているか判定
   * @param key キャッシュのKey
   * @returns キャッシュ保持していれば `true`
   */
  has(key: K): boolean {
    const k = key.getCacheKey();
    return this._keys[k] !== undefined && this._values[k] !== undefined;
  }

  /**
   * キャッシュされている数を取得
   * @returns キャッシュ数
   */
  size(): number {
    return this._size;
  }

  /**
   * キャッシュからクリア
   * @returns {void}
   */
  clear(): void {
    this._keys = {};
    this._values = {};
    this._size = 0;
  }

  /**
   * キー一覧を取得
   * @returns キー
   */
  keys(): IterableIterator<K> {
    return new Set<K>(Object.values(this._keys)).values();
  }

  /**
   * 値一覧を取得
   * @returns 値
   */
  values(): IterableIterator<V> {
    return new Set<V>(Object.values(this._values)).values();
  }

  /**
   * エントリーを取得
   * @returns エントリー
   */
  entries(): IterableIterator<[K, V]> {
    const map = new Map<K, V>();
    for (const k of this.keys()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map.set(k, this._values[k.getCacheKey()]!);
    }
    return map.entries();
  }
}

export {SimpleCache};
