/* eslint-disable require-jsdoc,valid-jsdoc */
class Config {
  private _endpoint: string;
  constructor(endpoint: string) {
    this._endpoint = endpoint;
  }

  /** 設定取得先 */
  get endpoint(): string {
    return this._endpoint;
  }

  /** 設定取得先 */
  set endpoint(endpoint: string) {
    this._endpoint = endpoint;
    // eslint-disable-next-line no-console
    console.warn('[GaIA] Config endpoint was overwritten. -> ', endpoint);
  }
}

const config = new Config(process.env.CONF_SERVER_ENDPOINT ?? '');

export default config;
