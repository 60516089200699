import {clamp} from '../math/MathUtil';

/**
 * DevicePixelRatioを取得する(1or2で返す)
 * @returns devicePixelRatio
 */
const getDevicePixelRatio = (): number => {
  return clamp(window.devicePixelRatio, 1, 2);
};

export {getDevicePixelRatio};
