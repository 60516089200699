import {JsonObject, Language, TileSize} from '../../../../gaia/types';
import {transMillisecToDegree} from '../../../../gaia/util';
import {LatLng, Size} from '../../../../gaia/value';
import {ValueObject} from '../../../../gaia/value/interface/ValueObject';
import {IconAnnotationInfo, IconPaletteConfig} from '../../../common/infra/response/AnnotationInfo';
import {CacheKey} from '../../../common/types';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {AbstractIconAnnotationData} from './AbstractIconAnnotationData';

/**
 * アイコン注記描画用データ
 */
class IconAnnotationData extends AbstractIconAnnotationData implements CacheKey {
  readonly isForce: boolean;
  readonly size: Size;
  readonly uv: TexturePlaneUVCoordinate;
  readonly angle?: number;
  readonly nodeId?: string;

  private readonly cacheKey: string;

  /**
   * コンストラクタ
   * @param lang 言語
   * @param tileSize タイルサイズ
   * @param priority 描画優先度
   * @param ntjCode NTJコード
   * @param latlng 緯度経度
   * @param isForce 強制描画フラグ
   * @param size 表示サイズ
   * @param uv UV座標
   * @param angle 角度
   * @param nodeId ノードID
   */
  private constructor(
    lang: Language,
    tileSize: TileSize,
    priority: number,
    ntjCode: number,
    latlng: LatLng,
    isForce: boolean,
    size: Size,
    uv: TexturePlaneUVCoordinate,
    angle?: number,
    nodeId?: string
  ) {
    super(lang, tileSize, priority, ntjCode, latlng, isForce, size, uv, angle);
    this.isForce = isForce;
    this.size = size;
    this.uv = uv;
    this.angle = angle;
    this.nodeId = nodeId;

    this.cacheKey = `IA(${latlng.lat},${latlng.lng},${ntjCode})`;
  }

  /** @override */
  getCacheKey(): string {
    return this.cacheKey;
  }

  /**
   * IconAnnotationDataを作成
   * @param lang 言語
   * @param tileSize タイルサイズ
   * @param info IconAnnotationInfo
   * @param palette IconPaletteConfig
   * @param imageSize テクスチャ切り取り後の画像サイズ
   * @param uv UV座標
   * @returns IconAnnotationData
   */
  static createAnnotationData(
    lang: Language,
    tileSize: TileSize,
    info: IconAnnotationInfo,
    palette: IconPaletteConfig,
    imageSize: Size,
    uv: TexturePlaneUVCoordinate
  ): IconAnnotationData {
    return new IconAnnotationData(
      lang,
      tileSize,
      info.priority,
      info.ntjCode,
      new LatLng(transMillisecToDegree(info.lat), transMillisecToDegree(info.lon)),
      palette.force,
      new Size(palette.size * imageSize.width * 0.01, palette.size * imageSize.height * 0.01),
      uv,
      info.angle,
      info.nodeId
    );
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof IconAnnotationData)) {
      return false;
    }
    const other = obj as IconAnnotationData;
    return (
      other.lang === this.lang &&
      other.tileSize === this.tileSize &&
      other.priority === this.priority &&
      other.ntjCode === this.ntjCode &&
      other.latlng.equals(this.latlng) &&
      other.isForce === this.isForce &&
      other.size.equals(this.size) &&
      other.uv.equals(this.uv) &&
      other.angle === this.angle &&
      other.nodeId === this.nodeId
    );
  }

  /** @override */
  clone(): ValueObject {
    return new IconAnnotationData(
      this.lang,
      this.tileSize,
      this.priority,
      this.ntjCode,
      this.latlng,
      this.isForce,
      this.size,
      this.uv,
      this.angle,
      this.nodeId
    );
  }

  /** @override */
  toObject(): JsonObject {
    return {
      lang: this.lang,
      tileSize: this.tileSize,
      priority: this.priority,
      ntjCode: this.ntjCode,
      latlng: this.latlng.toObject(),
      isForce: this.isForce,
      clientSize: this.size.toObject(),
      uv: {
        topLeft: this.uv.topLeft.toObject(),
        bottomLeft: this.uv.bottomLeft.toObject(),
        topRight: this.uv.topRight.toObject(),
        bottomRight: this.uv.bottomRight.toObject(),
      },
      angle: this.angle,
      nodeId: this.nodeId,
    };
  }
}

export {IconAnnotationData};
