import {MapStatus} from '../../models/MapStatus';
import {GaiaContext} from '../../GaiaContext';
import {World} from '../../../engine/world/World';
import {Camera} from '../../../engine/camera/Camera';
import {AbstractTileRenderKit} from './tile/AbstractTileRenderKit';
import {BlankPlaneLayer} from '../../layer/BlankPlaneLayer';
import {Layer} from '../../../engine/layer/Layer';
import {CongestionObjectRenderKit} from './object/CongestionObjectRenderKit';
import {
  Color,
  CongestionInfo,
  GeoJsonFigureCondition,
  HeatMapCondition,
  LatLng,
  UserLocationData,
  TrainRouteCondition,
  RoadShapeOpenedCondition,
} from '../../../../gaia/value';
import {MapTileScanner} from '../MapTileScanner';
import {
  CongestionLevel,
  AnnotationClickListener,
  AnnotationClickListenerOptions,
  MapIconClickListener,
  MapIconClickListenerOptions,
  MapIconMouseEnterListener,
  AnnotationFontFamilyMap,
  PolylineEventMap,
  PolylineEvent,
  LoadingProgressListener,
  TrainRouteClickListener,
  RoadShapeOpenedClickListener,
  ExternalAnnotationClickListener,
} from '../../../../gaia/types';
import {MapTileRenderKit} from './tile/MapTileRenderKit';
import {SatelliteAnnotationRenderKit} from './tile/SatelliteAnnotationRenderKit';
import {ShapeObjectRenderKit} from './object/ShapeObjectRenderKit';
import {ShapeLayer} from '../../layer/ShapeLayer';
import {Polyline, Polygon, Circle} from '../../../../gaia/object';
import {TrafficTileRenderKit} from './tile/TrafficTileRenderKit';
import {GeoJsonFigureHelper} from '../helper/GeoJsonFigureHelper';
import {
  complementWarpForRouteShape,
  createFigureStyleOfRouteShape,
  isRouteShapeProperties,
  joinLineHasSamePropertiesForRouteShape,
} from '../helper/RouteShape';
import {AbstractObjectRenderKit} from './object/AbstractObjectRenderKit';
import {BlankPlaneRenderKit} from './object/BlankPlaneObjectRenderKit';
import {CongestionLayer} from '../../layer/CongestionLayer';
import {RainfallTileRenderKit} from './tile/RainfallTileRenderKit';
import {ThunderTileRenderKit} from './tile/ThunderTileRenderKit';
import {SnowfallTileRenderKit} from './tile/SnowfallTileRenderKit';
import {PollenTileRenderKit} from './tile/PollenTileRenderKit';
import {TyphoonTileRenderKit} from './tile/TyphoonTileRenderKit';
import {GLMarkerObjectRenderKit} from './object/GLMarkerObjectRenderKit';
import {DOMObjectLayer} from '../../layer/DOMObjectLayer';
import {GLMarker} from '../../../../gaia/object/GLMarker';
import {IndoorTileRenderKit} from './tile/IndoorTileRenderKit';
import {IndoorBackgroundPlaneLayer} from '../../layer/IndoorBackgroundPlaneLayer';
import {CongestionTileRenderKit} from './tile/CongestionTileRenderKit';
import {LandmarkObjectRenderKit} from './object/LandmarkObjectRenderKit';
import {AnnotationObjectRenderKit} from './object/AnnotationObjectRenderKit';
import {FogObjectRenderKit} from './object/FogObjectRenderKit';
import {FogObjectLayer} from '../../layer/FogObjectLayer';
import {UserLocationObjectRenderKit} from './object/UserLocationObjectRenderKit';
import {UserLocation} from '../../../../gaia/object/UserLocation';
import {MapIconObjectRenderKit} from './object/MapIconObjectRenderKit';
import {Collision} from '../../../engine/collision/Collision';
import {Ray3} from '../../../common/math/Ray3';
import {MapIconCondition} from '../../../../gaia/value/MapIconCondition';
import {OrbitTileRenderKit} from './tile/OrbitTileRenderKit';
import {Optional} from '../../../common/types';
import {HeatMapObjectRenderKit} from './object/HeatMapObjectRenderKit';
import {HeatMapObjectLayer} from '../../layer/HeatMapObjectLayer';
import {CenterMarkerObjectRenderKit} from './object/CenterMarkerObjectRenderKit';
import {CenterMarkerObjectLayer, LAYER_NAME_CENTER_MARKER} from '../../layer/CenterMarkerObjectLayer';
import {CenterMarkerCondition} from '../../../../gaia/value/CenterMarkerCondition';
import {AnimationOption} from '../../../../gaia/value/animation';
import {TrainRouteObjectRenderKit} from './object/TrainRouteObjectRenderKit';
import {RoadShapeOpenedObjectRenderKit} from './object/RoadShapeOpenedObjectRenderKit';
import {AdditionTileRenderKit} from './tile/AdditionTileRenderKit';
import {AdditionTileCondition} from '../../../../gaia/value/AdditionTileCondition';
import {RainfallGradationTileRenderKit} from './tile/RainfallGradationTileRenderKit';
import {AltitudeObjectRenderKit} from './object/AltitudeObjectRenderKit';
import {AltitudeCondition} from '../../../../gaia/value/AltitudeCondition';
import {LandmarkObjectLayer} from '../../layer/LandmarkObjectLayer';
import {ExternalAnnotationObjectRenderKit} from './object/ExternalAnnotationObjectRenderKit';
import {ExternalAnnotationCondition} from 'gaia/value/ExternalAnnotationCondition';

type TileRenderKitMapping = {
  map: MapTileRenderKit;
  satelliteAnnotation: SatelliteAnnotationRenderKit;
  traffic: TrafficTileRenderKit;
  rainfall: RainfallTileRenderKit;
  rainfallGradation: RainfallGradationTileRenderKit;
  thunder: ThunderTileRenderKit;
  congestion: CongestionTileRenderKit;
  typhoon: TyphoonTileRenderKit;
  indoor: IndoorTileRenderKit;
  annotation: AnnotationObjectRenderKit;
  orbit: OrbitTileRenderKit;
  snowfall: SnowfallTileRenderKit;
  pollen: PollenTileRenderKit;
  trainroute: TrainRouteObjectRenderKit;
  roadshapeopened: RoadShapeOpenedObjectRenderKit;
  addition: AdditionTileRenderKit;
  externalAnnotation: ExternalAnnotationObjectRenderKit;
};

type ObjectRenderKitMapping = {
  blank: AbstractObjectRenderKit;
  congestion: AbstractObjectRenderKit;
  shape: AbstractObjectRenderKit;
  glMarker: AbstractObjectRenderKit;
  landmark: LandmarkObjectRenderKit;
  fog: FogObjectRenderKit;
  userLocation: UserLocationObjectRenderKit;
  heatMap: HeatMapObjectRenderKit;
  centerMarker: CenterMarkerObjectRenderKit;
  altitude: AltitudeObjectRenderKit;
};

/**
 * 描画ロジックを一括で管理するコントローラー
 */
class MapRenderKitController {
  private readonly context: GaiaContext;

  private readonly camera: Camera;
  private readonly world: World;

  private readonly domLayer: DOMObjectLayer;

  // タイル画像描画RenderKit
  private readonly tileRenderKits: Map<keyof TileRenderKitMapping, AbstractTileRenderKit> = new Map<
    keyof TileRenderKitMapping,
    AbstractTileRenderKit
  >();

  private readonly additionTileRenderKit: AdditionTileRenderKit;

  // タイルではない描画RenderKit
  private readonly objectRenderKits: Map<keyof ObjectRenderKitMapping, AbstractObjectRenderKit> = new Map<
    keyof ObjectRenderKitMapping,
    AbstractObjectRenderKit
  >();

  private readonly annotationRenderKit: AnnotationObjectRenderKit;
  private readonly mapIconRenderKit: MapIconObjectRenderKit;
  private readonly trainRouteRenderKit: TrainRouteObjectRenderKit;
  private readonly roadShapeOpenedRenderKit: RoadShapeOpenedObjectRenderKit;
  private readonly altitudeRenderKit: AltitudeObjectRenderKit;
  private readonly heatMapRenderKit: HeatMapObjectRenderKit;
  private readonly externalAnnotationRenderKit: ExternalAnnotationObjectRenderKit;

  private readonly geoJsonFigureHelper: GeoJsonFigureHelper;

  /** 描画更新フラグ */
  private requireUpdate = true;
  /** NoteLayer描画更新フラグ */
  private requireUpdateNoteLayer = true;
  /** ステータス変化前ズームレベル */
  private previousZoom = 0;
  /** loader実行debounceID */
  private debounceId = 0;
  /** loader実行抑制フラグ */
  private suspendLoader = false;
  /** 地図タイル読み込み完了フラグ */
  private isMapLoadAlmostFinished = true;

  /** 定期的に描画を更新するinterval */
  private intervalId: Optional<NodeJS.Timeout>;

  private isDestroyed = false;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param camera カメラ
   * @param domLayer DOMLayer
   */
  constructor(context: GaiaContext, camera: Camera, domLayer: DOMObjectLayer) {
    this.context = context;
    this.camera = camera;
    this.domLayer = domLayer;

    this.world = new World(this.camera, context.getGLContext());

    const fogObjectLayer = new FogObjectLayer(this.context);
    const fogObjectRenderKit = new FogObjectRenderKit(this.context, fogObjectLayer, this, camera);
    this.addObjectRenderKit(fogObjectRenderKit);

    const blankPlaneLayer = new BlankPlaneLayer(this.context);
    const blankPlaneRenderKit = new BlankPlaneRenderKit(this.context, blankPlaneLayer, this);
    blankPlaneLayer.setNotifierFunc(() => {
      this.updateWorld();
    });
    this.addObjectRenderKit(blankPlaneRenderKit);

    const mapTileRenderKit = new MapTileRenderKit(this.context, camera);
    this.addTileRenderKit(mapTileRenderKit);

    const orbitTileRenderKit = new OrbitTileRenderKit(this.context, camera);
    this.addTileRenderKit(orbitTileRenderKit);

    const satelliteAnnotationRenderKit = new SatelliteAnnotationRenderKit(this.context, camera);
    this.addTileRenderKit(satelliteAnnotationRenderKit);

    const trafficTileRenderKit = new TrafficTileRenderKit(this.context, camera);
    this.addTileRenderKit(trafficTileRenderKit);

    const congestionLayer = new CongestionLayer(this.context);
    const congestionRenderKit = new CongestionObjectRenderKit(this.context, congestionLayer, this);
    this.addObjectRenderKit(congestionRenderKit);

    const shapeLayer = new ShapeLayer(context, this, camera);
    const shapeObjectRenderKit = new ShapeObjectRenderKit(this.context, shapeLayer, this, camera);
    shapeObjectRenderKit.setNotifierFunc(() => {
      this.updateWorld();
    });
    this.addObjectRenderKit(shapeObjectRenderKit);

    // MAP-6080 ハードウェアアクセラレーションオフの対応として円を描画
    shapeObjectRenderKit.addCircle(
      new Circle({
        center: new LatLng(0, 0),
        radius: 1,
        fillColor: new Color(0, 0, 0, 0.0001),
      })
    );

    const centerMarkerObjectLayer = new CenterMarkerObjectLayer(this.context);
    const centerMarkerObjectRenderKit = new CenterMarkerObjectRenderKit(this.context, centerMarkerObjectLayer, this);
    this.addObjectRenderKit(centerMarkerObjectRenderKit);

    this.geoJsonFigureHelper = new GeoJsonFigureHelper(this.context);

    const rainfallTileRenderKit = new RainfallTileRenderKit(this.context, camera);
    this.addTileRenderKit(rainfallTileRenderKit);

    const rainfallGradationTileRenderKit = new RainfallGradationTileRenderKit(this.context, camera, [6, 8]);
    this.addTileRenderKit(rainfallGradationTileRenderKit);

    const thunderTileRenderKit = new ThunderTileRenderKit(this.context, camera);
    this.addTileRenderKit(thunderTileRenderKit);

    const snowfallTileRenderKit = new SnowfallTileRenderKit(this.context, camera);
    this.addTileRenderKit(snowfallTileRenderKit);

    const pollenTileRenderKit = new PollenTileRenderKit(this.context, camera);
    this.addTileRenderKit(pollenTileRenderKit);

    const congestionTileRenderKit = new CongestionTileRenderKit(this.context, camera);
    this.addTileRenderKit(congestionTileRenderKit);

    const typhoonTileRenderKit = new TyphoonTileRenderKit(this.context, camera);
    this.addTileRenderKit(typhoonTileRenderKit);

    const indoorBackgroundLayer = new IndoorBackgroundPlaneLayer(this.context);
    const indoorTileRenderKit = new IndoorTileRenderKit(this.context, camera, indoorBackgroundLayer);
    this.addTileRenderKit(indoorTileRenderKit);

    this.additionTileRenderKit = new AdditionTileRenderKit(this.context, camera);

    const landmarkObjectRenderKit = new LandmarkObjectRenderKit(this.context, this, camera);
    landmarkObjectRenderKit.setNotifierFunc(() => {
      this.updateWorld();
    });
    this.addObjectRenderKit(landmarkObjectRenderKit);

    const markerObjectRenderKit = new GLMarkerObjectRenderKit(this.context, this, camera);
    markerObjectRenderKit.setNotifierFunc(() => {
      this.updateWorld();
    });
    this.addObjectRenderKit(markerObjectRenderKit);

    this.mapIconRenderKit = new MapIconObjectRenderKit(this.context, this, camera);
    this.mapIconRenderKit.getLayer().setNotifierFunc(() => {
      this.updateWorld();
    });
    mapTileRenderKit.setRequestFinishedNotifier((isFinished: boolean) => {
      this.isMapLoadAlmostFinished = isFinished;
      if (!this.suspendLoader && this.isMapLoadAlmostFinished) {
        // 地図タイル読み込みがある程度終わってから注記リクエストを開始する
        this.mapIconRenderKit.executeLoader();
      }
    });

    this.externalAnnotationRenderKit = new ExternalAnnotationObjectRenderKit(this.context, this, camera);

    mapTileRenderKit.setRequestFinishedNotifier((isFinished: boolean) => {
      this.isMapLoadAlmostFinished = isFinished;
      if (!this.suspendLoader && this.isMapLoadAlmostFinished) {
        // 地図タイル読み込みがある程度終わってから注記リクエストを開始する
        this.externalAnnotationRenderKit.executeLoader();
      }
    });
    this.altitudeRenderKit = new AltitudeObjectRenderKit(this.context, this, camera);
    this.altitudeRenderKit.setNotifierFunc(() => {
      this.updateWorld();
    });
    this.addObjectRenderKit(this.altitudeRenderKit);

    this.annotationRenderKit = new AnnotationObjectRenderKit(this.context, this, camera);
    this.annotationRenderKit.getNoteLayer().setNotifierFunc(() => {
      this.requireUpdateNoteLayer = true;
    });
    this.annotationRenderKit.getMarkLayer().setNotifierFunc(() => {
      this.updateWorld();
    });
    this.annotationRenderKit.setAltitudeCallback((latLng: LatLng): number => {
      return this.altitudeRenderKit.getAltitude(latLng);
    });
    mapTileRenderKit.setRequestFinishedNotifier((isFinished: boolean) => {
      this.isMapLoadAlmostFinished = isFinished;
      if (!this.suspendLoader && this.isMapLoadAlmostFinished) {
        // 地図タイル読み込みがある程度終わってから注記リクエストを開始する
        this.annotationRenderKit.executeLoader();
      }
    });

    const userLocationRenderKit = new UserLocationObjectRenderKit(this.context, this);
    userLocationRenderKit.setNotifierFunc(() => {
      this.updateWorld();
    });
    this.addObjectRenderKit(userLocationRenderKit);

    const heatMapObjectLayer = new HeatMapObjectLayer(this.context);
    heatMapObjectLayer.setNotifierFunc(() => {
      this.updateWorld();
    });

    const heatMapObjectRenderKit = new HeatMapObjectRenderKit(this.context, heatMapObjectLayer, this);
    this.addObjectRenderKit(heatMapObjectRenderKit);
    this.heatMapRenderKit = heatMapObjectRenderKit;

    this.trainRouteRenderKit = new TrainRouteObjectRenderKit(this.context, this, camera);
    this.roadShapeOpenedRenderKit = new RoadShapeOpenedObjectRenderKit(this.context, this, camera);

    this.externalAnnotationRenderKit = new ExternalAnnotationObjectRenderKit(this.context, this, camera);

    this.addLayers([
      blankPlaneLayer,
      mapTileRenderKit.getSpareLayer(),
      mapTileRenderKit.getSubLayer(),
      mapTileRenderKit.getLayer(),
      orbitTileRenderKit.getLayer(),
      this.roadShapeOpenedRenderKit.getLayer(),
      this.additionTileRenderKit.getGroupLayer(),
      this.trainRouteRenderKit.getLayer(),
      landmarkObjectRenderKit.getLayer(),
      indoorBackgroundLayer,
      indoorTileRenderKit.getLayer(),
      satelliteAnnotationRenderKit.getLayer(),
      trafficTileRenderKit.getLayer(),
      congestionLayer,
      congestionTileRenderKit.getLayer(),
      shapeLayer,
      rainfallTileRenderKit.getLayer(),
      rainfallGradationTileRenderKit.getLayer(),
      thunderTileRenderKit.getLayer(),
      snowfallTileRenderKit.getLayer(),
      pollenTileRenderKit.getLayer(),
      typhoonTileRenderKit.getLayer(),
      this.altitudeRenderKit.getLayer(),
      heatMapObjectRenderKit.getLayer(),
      fogObjectLayer,
      this.annotationRenderKit.getNoteLayer(),
      this.annotationRenderKit.getMarkLayer(),
      this.mapIconRenderKit.getLayer(),
      this.externalAnnotationRenderKit.getLayer(),
      markerObjectRenderKit.getLayer(),
      userLocationRenderKit.getLayer(),
      centerMarkerObjectLayer,
    ]);

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
    const updateFn = () => {
      if (!this.isDestroyed && (this.requireUpdate || this.requireUpdateNoteLayer)) {
        const finished = this.world.draw();
        if (this.requireUpdate) {
          this.domLayer.update(this.camera);
        }
        this.requireUpdate = !finished && this.requireUpdate;
        this.requireUpdateNoteLayer = !finished && this.requireUpdateNoteLayer;
      }
      requestAnimationFrame(updateFn);
    };
    requestAnimationFrame(updateFn);

    this.intervalId = setInterval(() => {
      this.update(this.context.getMapStatus());
    }, 1000);

    context.addOnMapStatusUpdateListener((status) => {
      if (Math.abs(this.previousZoom - status.zoomLevel) > 0.0001) {
        this.suspendLoader = true;
        if (this.debounceId !== 0) {
          window.clearTimeout(this.debounceId);
          this.debounceId = 0;
        }
        this.debounceId = window.setTimeout(() => this.executeLoaderDebounce(), 50);
      }
      this.previousZoom = status.zoomLevel;
    });
    this.previousZoom = context.getMapStatus().zoomLevel;
  }

  /**
   * Loaderのリクエスト処理実行
   * @returns {void}
   */
  private executeLoaderDebounce(): void {
    for (const kit of this.tileRenderKits.values()) {
      kit.executeLoader();
    }
    this.additionTileRenderKit.executeLoader();
    this.annotationRenderKit.executeLoader();
    this.mapIconRenderKit.executeLoader();
    this.suspendLoader = false;
    window.clearTimeout(this.debounceId);
  }

  /**
   * CongestionRenderKitのgetter
   */
  private get congestionObjectRenderKit(): CongestionObjectRenderKit {
    return this.objectRenderKits.get('congestion') as CongestionObjectRenderKit;
  }

  /**
   * ShapeRenderKitのgetter
   */
  private get shapeObjectRenderKit(): ShapeObjectRenderKit {
    return this.objectRenderKits.get('shape') as ShapeObjectRenderKit;
  }

  /**
   * GLMarkerObjectRenderKitのgetter
   */
  private get glMarkerObjectRenderKit(): GLMarkerObjectRenderKit {
    return this.objectRenderKits.get('glMarker') as GLMarkerObjectRenderKit;
  }

  /**
   * IndoorTileRenderKitのgetter
   */
  private get indoorTileRenderKit(): IndoorTileRenderKit {
    return this.tileRenderKits.get('indoor') as IndoorTileRenderKit;
  }

  /**
   * UserLocationObjectRenderKitのgetter
   */
  private get userLocationObjectRenderKit(): UserLocationObjectRenderKit {
    return this.objectRenderKits.get('userLocation') as UserLocationObjectRenderKit;
  }

  /**
   * TileRenderKitを追加
   * @param tileRenderKit タイル描画キット
   * @returns {void}
   */
  private addTileRenderKit(tileRenderKit: AbstractTileRenderKit): void {
    this.tileRenderKits.set(tileRenderKit.identicalName, tileRenderKit);
    tileRenderKit.getLayer().setNotifierFunc(() => {
      this.updateWorld();
    });
  }

  /**
   * RenderKitを追加
   * @param objectRenderKit 描画キット
   * @returns {void}
   */
  private addObjectRenderKit(objectRenderKit: AbstractObjectRenderKit): void {
    this.objectRenderKits.set(objectRenderKit.identicalName, objectRenderKit);
  }

  /**
   * レイヤーを追加する
   * @param layers レイヤーリスト
   * @returns {void}
   */
  private addLayers(layers: Layer[]): void {
    for (const layer of layers) {
      this.world.addLayer(layer);
    }
  }

  /**
   * RenderKitを取得
   * @param name RenderKit名
   * @returns RenderKit
   */
  getObjectRenderKit<K extends keyof ObjectRenderKitMapping>(name: K): ObjectRenderKitMapping[K] {
    return this.objectRenderKits.get(name) as ObjectRenderKitMapping[K];
  }

  /**
   * TileRenderKitを取得
   * @param name TileRenderKit名
   * @returns TileRenderKit
   */
  getTileRenderKit<K extends keyof TileRenderKitMapping>(name: K): TileRenderKitMapping[K] {
    return this.tileRenderKits.get(name) as TileRenderKitMapping[K];
  }

  /**
   * タイルの3Dビル表示設定を取得
   * @returns 3Dビルの表示フラグ
   */
  isTileBuilding3DEnabled(): boolean {
    return this.getTileRenderKit('map').isTileBuilding3DEnabled();
  }

  /**
   * タイルの3Dビル表示設定
   * @param enable 表示フラグ
   * @returns {void}
   */
  setTileBuilding3DEnabled(enable: boolean): void {
    this.getTileRenderKit('map').setTileBuilding3DEnabled(enable);
  }

  /**
   * GLMarkerの追加
   * @param markers GLMarkerの配列
   * @returns {void}
   */
  addGLMarkers(markers: GLMarker[]): void {
    this.glMarkerObjectRenderKit.addMarkers(markers);
  }

  /**
   * GLMarkerの削除
   * @param markers 削除対象のGLMarkerの配列
   * @returns {void}
   */
  removeGLMarkers(markers: GLMarker[]): void {
    this.glMarkerObjectRenderKit.removeMarkers(markers);
  }

  /**
   * ポリゴンを追加
   * @param polygon ポリゴン
   * @returns {void}
   */
  addPolygon(polygon: Polygon): void {
    this.shapeObjectRenderKit.addPolygon(polygon);
  }

  /**
   * ポリゴンを削除
   * @param polygon ポリゴン
   * @returns {void}
   */
  removePolygon(polygon: Polygon): void {
    this.shapeObjectRenderKit.removePolygon(polygon);
  }

  /**
   * ポリラインを追加
   * @param polyline ポリライン
   * @returns {void}
   */
  addPolyline(polyline: Polyline): void {
    this.shapeObjectRenderKit.addPolyline(polyline);
  }

  /**
   * ポリラインを削除
   * @param polyline ポリライン
   * @returns {void}
   */
  removePolyline(polyline: Polyline): void {
    this.shapeObjectRenderKit.removePolyline(polyline);
  }

  /**
   * 円を追加
   * @param circle 円
   * @returns {void}
   */
  addCircle(circle: Circle): void {
    this.shapeObjectRenderKit.addCircle(circle);
  }

  /**
   * 円を削除
   * @param circle 円
   * @returns {void}
   */
  removeCircle(circle: Circle): void {
    this.shapeObjectRenderKit.removeCircle(circle);
  }

  /**
   * GeoJSON形状追加
   * @param condition GeoJsonFigureCondition
   * @returns {void}
   */
  addGeoJsonFigure(condition: GeoJsonFigureCondition): void {
    const geoJsonFigureInfoList = this.geoJsonFigureHelper.createGeoJsonFigureInfoList(condition);
    if (geoJsonFigureInfoList.length === 0) {
      return;
    }

    if (condition.isRouteShape) {
      joinLineHasSamePropertiesForRouteShape(geoJsonFigureInfoList);
      complementWarpForRouteShape(geoJsonFigureInfoList);
    }

    if (condition.isRouteShape) {
      for (const geoJsonFigureInfo of geoJsonFigureInfoList) {
        if (!isRouteShapeProperties(geoJsonFigureInfo.properties)) {
          continue;
        }
        const routeShapeStyle = createFigureStyleOfRouteShape(geoJsonFigureInfo.properties, condition);
        geoJsonFigureInfo.setStyle(routeShapeStyle);
      }
      if (condition.showRouteArrow) {
        const arrows = this.geoJsonFigureHelper.createRouteArrows(condition);
        for (const a of arrows) {
          this.domLayer.add(a);
        }
      }
    }

    const figureList = this.geoJsonFigureHelper.geoJsonFigureInfoToFigureList(geoJsonFigureInfoList);
    for (const figure of figureList) {
      if (figure instanceof Polyline) {
        this.shapeObjectRenderKit.addPolyline(figure);

        // イベントリスナー設定
        for (const [eventName, listener] of Object.entries(condition.listeners)) {
          figure.addEventListener(eventName as keyof PolylineEventMap, (ev: PolylineEvent): void => {
            listener({
              sourceObject: condition,
              position: ev.position,
            });
          });
        }
      } else if (figure instanceof Polygon) {
        this.shapeObjectRenderKit.addPolygon(figure);
      }
    }

    let additionalEvent: (ev: PolylineEvent) => void;
    condition.setOnEventUpdatedListener(
      (eventName, func) => {
        additionalEvent = (ev): void => {
          func({
            sourceObject: condition,
            position: ev.position,
          });
        };
        for (const figure of figureList) {
          if (figure instanceof Polyline) {
            figure.addEventListener(eventName, additionalEvent);
          }
        }
      },
      (eventName) => {
        for (const figure of figureList) {
          if (figure instanceof Polyline) {
            figure.removeEventListener(eventName, additionalEvent);
          }
        }
      }
    );
    this.geoJsonFigureHelper.addToFigureList(condition, figureList);
  }

  /**
   * GeoJSON形状削除
   * @param condition 削除対象のGeoJsonFigureCondition
   * @returns {void}
   */
  removeGeoJsonFigure(condition: GeoJsonFigureCondition): void {
    const removeFigureList = this.geoJsonFigureHelper.extractFigureList(condition);
    for (const figure of removeFigureList) {
      if (figure instanceof Polyline) {
        this.shapeObjectRenderKit.removePolyline(figure);
      } else if (figure instanceof Polygon) {
        this.shapeObjectRenderKit.removePolygon(figure);
      }
    }
    if (condition.isRouteShape && condition.showRouteArrow) {
      const arrows = this.geoJsonFigureHelper.extractRouteArrows(condition);
      for (const a of arrows) {
        this.domLayer.remove(a);
      }
    }
  }

  /**
   * ヒートマップを追加
   * @param condition HeatMapCondition
   * @returns {void}
   */
  addHeatMap(condition: HeatMapCondition): void {
    this.heatMapRenderKit.addHeatMap(condition);
  }

  /**
   * ヒートマップを削除
   * @param condition HeatMapCondition
   * @returns {void}
   */
  removeHeatMap(condition: HeatMapCondition): void {
    this.heatMapRenderKit.removeHeatMap(condition);
  }

  /**
   * 注記クリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setAnnotationClickListener(listener: AnnotationClickListener, options?: AnnotationClickListenerOptions): void {
    this.annotationRenderKit.setAnnotationClickListener(listener, options);
  }

  /**
   * 注記クリックリスナーの削除
   * @returns {void}
   */
  removeAnnotationClickListener(): void {
    this.annotationRenderKit.removeAnnotationClickListener();
  }

  /**
   * 注記のフォント情報を設定
   * @param fontFamilyMap フォント情報
   * @returns {void}
   */
  setAnnotationFontFamilyMap(fontFamilyMap: AnnotationFontFamilyMap): void {
    this.annotationRenderKit.setFontFamilyMap(fontFamilyMap);
  }

  /**
   * 自位置マーカーを設定
   * @param userLocation UserLocation
   * @returns {void}
   */
  setUserLocation(userLocation: UserLocation): void {
    this.userLocationObjectRenderKit.setUserLocation(userLocation);
  }

  /**
   * UserLocationObjectの描画位置・角度の更新
   * @param data 位置情報
   * @param animationOption アニメーション設定
   * @returns {void}
   */
  updateUserLocationObjectPosition(data: UserLocationData, animationOption?: AnimationOption): void {
    this.userLocationObjectRenderKit.updateUserLocationObjectPosition(data, animationOption);
  }

  /**
   * 地図アイコンクリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setMapIconClickListener(listener: MapIconClickListener, options?: MapIconClickListenerOptions): void {
    this.mapIconRenderKit.setMapIconClickListener(listener, options);
  }

  /**
   * 地図アイコンクリックリスナーの削除
   * @returns {void}
   */
  removeMapIconClickListener(): void {
    this.mapIconRenderKit.removeMapIconClickListener();
  }

  /**
   * 地図アイコンマウスエンターリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapIconMouseEnterListener(listener: MapIconMouseEnterListener): void {
    this.mapIconRenderKit.setMapIconMouseEnterListener(listener);
  }

  /**
   * 地図アイコンマウスエンターリスナーの削除
   * @returns {void}
   */
  removeMapIconMouseEnterListener(): void {
    this.mapIconRenderKit.removeMapIconMouseEnterListener();
  }

  /**
   * 社外由来注記コンディションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setExternalAnnotationCondition(condition?: ExternalAnnotationCondition): void {
    this.externalAnnotationRenderKit.setExternalAnnotationCondition(condition);
  }

  /**
   * 社外由来注記クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setExternalAnnotationClickListener(listener: ExternalAnnotationClickListener): void {
    this.externalAnnotationRenderKit.setExternalAnnotationClickListener(listener);
  }

  /**
   * 地図タイルの描画進捗更新リスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapTileLoadingProgressListener(listener?: LoadingProgressListener): void {
    this.getTileRenderKit('map').setMapTileLoadingProgressListener(listener);
  }

  /**
   * 注記の描画進捗更新リスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setAnnotationLoadingProgressListener(listener?: LoadingProgressListener): void {
    this.annotationRenderKit.setAnnotationLoadingProgressListener(listener);
  }

  /**
   * 地図アイコン取得条件の設定
   * @param condition 取得条件
   * @returns {void}
   */
  setMapIconCondition(condition?: MapIconCondition): void {
    this.mapIconRenderKit.setMapIconCondition(condition);
  }

  /**
   * 十字マーカーのコンディションを設定
   * @param condition コンディション
   * @returns {void}
   */
  setCenterMarkerCondition(condition?: CenterMarkerCondition): void {
    this.getObjectRenderKit(LAYER_NAME_CENTER_MARKER).setCondition(condition);
  }

  /**
   * 任意タイル地図のオプションを設定
   * @param key 設定対象のキー名
   * @param condition 表示設定
   * @returns {void}
   */
  setAdditionTileCondition(key: string, condition?: AdditionTileCondition): void {
    this.additionTileRenderKit.setAdditionTileCondition(key, condition);
  }

  /**
   * 任意タイル地図のオプション登録済みキー名リストを取得
   * @returns 登録済みキー名リスト
   */
  getAdditionTileKeyNameList(): string[] {
    return this.additionTileRenderKit.getAdditionTileKeyNameList();
  }

  /**
   * 指定したキーが任意タイル地図のオプション登録済みかどうか
   * @param key キー名
   * @returns 登録済みか
   */
  hasAdditionTileKeyName(key: string): boolean {
    return this.additionTileRenderKit.hasAdditionTileKeyName(key);
  }

  /**
   * 鉄道路線図のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setTrainRouteCondition(condition?: TrainRouteCondition): void {
    this.trainRouteRenderKit.setTrainRouteCondition(condition);
  }

  /**
   * 鉄道路線図クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setTrainRouteClickListener(listener: TrainRouteClickListener): void {
    this.trainRouteRenderKit.setTrainRouteClickListener(listener);
  }

  /**
   * 新規開通道路のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setRoadShapeOpenedCondition(condition?: RoadShapeOpenedCondition): void {
    this.roadShapeOpenedRenderKit.setRoadShapeOpenedCondition(condition);
  }

  /**
   * 新規開通道路クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setRoadShapeOpenedClickListener(listener: RoadShapeOpenedClickListener): void {
    this.roadShapeOpenedRenderKit.setRoadShapeOpenedClickListener(listener);
  }

  /**
   * 標高を取得
   * @param latLng 緯度経度
   * @returns 標高
   */
  getAltitude(latLng: LatLng): number {
    return this.altitudeRenderKit.getAltitude(latLng);
  }

  /**
   * 標高のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setAltitudeCondition(condition?: AltitudeCondition): void {
    this.altitudeRenderKit.setAltitudeCondition(condition);

    const isAltitude = condition ? true : false;
    for (const tileRenderKit of this.tileRenderKits.values()) {
      tileRenderKit.setAltitudeMode(isAltitude);
    }
    this.additionTileRenderKit.setAltitudeModeForGroupLayer(isAltitude);
    this.shapeObjectRenderKit.setAltitudeMode(isAltitude);

    // 特許の関係上、ジオラマ表示を行う際に強制的にランドマークを非表示にする
    const landmarkLayer = this.objectRenderKits.get('landmark')?.getLayer();
    (landmarkLayer as LandmarkObjectLayer).setVisible(!isAltitude);
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    const tileList = MapTileScanner.listUpTileList(this.context, this.camera, 2);

    this.additionTileRenderKit.updateDrawObjects(mapStatus, tileList);
    if (!this.suspendLoader) {
      this.additionTileRenderKit.executeLoader();
    }
    this.checkAdditionTileStatus();

    // ここで全Mgrに更新を要求
    // ManagerがLayer更新→描画(GL空間)を更新する場合は、MgrのListener経由でthis.updateWorld()を呼び出す
    for (const kit of this.tileRenderKits.values()) {
      kit.updateDrawObjects(mapStatus, tileList);
      if (!this.suspendLoader) {
        kit.executeLoader();
      }
    }

    for (const renderKit of this.objectRenderKits.values()) {
      renderKit.updateDrawObjects(mapStatus);
    }

    this.externalAnnotationRenderKit.updateDrawObjects(mapStatus);
    if (!this.suspendLoader && this.isMapLoadAlmostFinished) {
      this.externalAnnotationRenderKit.executeLoader();
    }

    this.mapIconRenderKit.updateDrawObjects(mapStatus, tileList);
    this.annotationRenderKit.updateDrawObjects(mapStatus, tileList);
    if (!this.suspendLoader && this.isMapLoadAlmostFinished) {
      this.mapIconRenderKit.executeLoader();
      this.annotationRenderKit.executeLoader();
    }

    this.trainRouteRenderKit.updateDrawObjects(mapStatus, tileList);
    if (!this.suspendLoader) {
      this.trainRouteRenderKit.executeLoader();
    }

    this.roadShapeOpenedRenderKit.updateDrawObjects(mapStatus, tileList);
    if (!this.suspendLoader) {
      this.roadShapeOpenedRenderKit.executeLoader();
    }

    this.updateWorld();
  }

  /**
   * 任意タイルをチェックし、通常地図・注記レイヤーの表示状態を切り替え
   * @returns {void}
   */
  private checkAdditionTileStatus(): void {
    // 地図タイルオフのレイヤーがあれば描画停止
    const mapTileVisible = this.additionTileRenderKit.isMapTileEnabled();
    const mapTileRenderKit = this.tileRenderKits.get('map');
    if (mapTileRenderKit instanceof MapTileRenderKit) {
      mapTileRenderKit.getLayer().setVisible(mapTileVisible);
      mapTileRenderKit.getSubLayer().setVisible(mapTileVisible);
      mapTileRenderKit.getSpareLayer().setVisible(mapTileVisible);
    }

    // 地図初期化オプションで注記無効 または 任意タイル内に注記無効レイヤーがあったら描画しない
    const initialAnnotationFlag = this.context.getMapInitOptions().isAnnotationEnabled ?? false;
    const additionTileAnnotationFlag = this.additionTileRenderKit.isAnnotationEnabled();
    this.annotationRenderKit.setVisible(initialAnnotationFlag && additionTileAnnotationFlag);
  }

  /**
   * GL空間の描画情報を更新
   * @returns {void}
   */
  private updateWorld(): void {
    this.requireUpdate = true;
  }

  /**
   * 混雑度情報を設定
   * @param congestionInfo 混雑度情報
   * @param colorTable 配色
   * @returns {void}
   */
  setCongestionInfo(congestionInfo: CongestionInfo, colorTable?: {[key in CongestionLevel]: Color}): void {
    this.congestionObjectRenderKit.setCongestionInfo(congestionInfo, colorTable);
  }

  /**
   * 混雑度情報をクリア
   * @returns {void}
   */
  clearCongestionInfo(): void {
    this.congestionObjectRenderKit.clearCongestionInfo();
  }

  /**
   * 地図インスタンスが作成されてからの描画回数を取得
   * @returns 描画回数
   */
  getDrawCount(): number {
    return this.world.getDrawCount();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    for (const objectRenderKit of this.objectRenderKits.values()) {
      objectRenderKit.destroy();
    }
    for (const tileRenderKit of this.tileRenderKits.values()) {
      tileRenderKit.destroy();
    }
    this.annotationRenderKit.destroy();
    this.mapIconRenderKit.destroy();
    this.trainRouteRenderKit.destroy();
    this.roadShapeOpenedRenderKit.destroy();
    this.domLayer.destroy();
    if (this.intervalId !== null && this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    this.isDestroyed = true;
  }

  /**
   * 全てのレイヤの衝突情報を取得する
   * @param ray レイキャスト
   * @returns 衝突情報
   */
  getAllCollisions(ray: Ray3): Map<string, Collision[]> {
    const collisionMap = new Map();
    for (const layer of this.world.getLayers().slice().reverse()) {
      const layerName = layer.getIdenticalLayerName();
      const collisions = layer.getCollisions(ray);
      collisionMap.set(layerName, collisions);
    }
    return collisionMap;
  }
}

export {MapRenderKitController, TileRenderKitMapping, ObjectRenderKitMapping};
