import {AltitudeConditionInitOptions} from '../types';

/**
 * 標高情報コンディション
 */
class AltitudeCondition {
  private options: AltitudeConditionInitOptions;

  /**
   * コンストラクタ
   * @param options 表示オプション
   */
  constructor(options: AltitudeConditionInitOptions) {
    this.options = options;
  }
}

export {AltitudeCondition};
