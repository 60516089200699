import {GaiaContext} from '../../../GaiaContext';
import {AltitudeObjectLayer, LAYER_NAME_ALTITUDE} from '../../../layer/AltitudeObjectLayer';
import {MapStatus} from '../../../models/MapStatus';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';
import {AltitudeLoader} from '../../../loader/AltitudeLoader';
import {TileNumber} from '../../../models/TileNumber';
import {AltitudeParameter} from '../../../loader/param/AltitudeParameter';
import {LatLng} from '../../../../../gaia/value';
import {AltitudeCondition} from '../../../../../gaia/value/AltitudeCondition';
import {Camera} from '../../../../engine/camera/Camera';
import {LayerUpdateNotifierFunc} from '../../../../engine/layer/Layer';

/**
 * 標高曲面を扱う描画キット
 */
class AltitudeObjectRenderKit extends AbstractObjectRenderKit {
  private altitudeLayer: AltitudeObjectLayer;
  private altitudeLoader: AltitudeLoader;
  private condition?: AltitudeCondition;
  private camera: Camera;
  private mapStatus: MapStatus;

  private notifyUpdate?: LayerUpdateNotifierFunc;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param renderKitCtl レンダーキットコントローラー
   * @param camera カメラ
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    const altitudeLayer = new AltitudeObjectLayer(context);
    super(context, altitudeLayer, renderKitCtl);

    this.camera = camera;
    this.mapStatus = context.getMapStatus();

    this.altitudeLayer = altitudeLayer;
    this.altitudeLoader = new AltitudeLoader(context, (): void => {
      this.notifyUpdate?.();
    });

    altitudeLayer.setAltitudeCallback((latLng: LatLng) => {
      const altitude = this.getAltitude(latLng);
      return altitude;
    });
  }

  /**
   * コンディションを設定
   * @param condition AltitudeCondition
   * @returns {void}
   */
  setAltitudeCondition(condition?: AltitudeCondition): void {
    this.condition = condition;
    this.altitudeLayer.setCondition(condition);
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return LAYER_NAME_ALTITUDE;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    if (!this.condition) {
      return;
    }

    this.altitudeLoader.executeRequest();
    this.altitudeLayer.update(mapStatus, this.camera);
  }

  /** @override */
  onDestroy(): void {
    this.altitudeLayer.destroy();
  }

  /**
   * 標高を取得
   * @param latLng 緯度経度
   * @returns 標高
   */
  getAltitude(latLng: LatLng): number {
    if (!this.altitudeLoader || !this.condition) {
      return 0;
    }

    const z = this.mapStatus.zoomLevel >= 9 ? 8 : 6;
    const tileNumber = TileNumber.fromLatLng(latLng, z);
    const param = new AltitudeParameter(tileNumber.x, tileNumber.y, tileNumber.z);
    const info = this.altitudeLoader.getAltitudeInfo(param);
    if (!info) {
      this.altitudeLoader.addRequestQueue([param]);
      return 0;
    }

    if (info.numberX === 0 || info.numberY === 0) {
      return 0;
    }

    const latRatio = (latLng.lat - info.minLat) / (info.maxLat - info.minLat);
    const lngRatio = (latLng.lng - info.minLng) / (info.maxLng - info.minLng);
    const latIndex = Math.floor(info.numberY * latRatio);
    const lngIndex = Math.floor(info.numberX * lngRatio);
    const index = (info.numberY - latIndex) * info.numberX + lngIndex;

    /**
     * 標高取得を関数にまとめる
     * @param index インデックス
     * @returns 標高
     */
    const get = (index: number): number => {
      const length = info.gridBody.length;
      if (index * 2 + 1 >= length || index < 0) {
        return 0;
      }
      const value = info.gridBody[index * 2 + 1] * 256 + info.gridBody[index * 2];
      return info.base + value * info.gradation;
    };

    const latRemainder = info.numberY * latRatio - latIndex;
    const lngRemainder = info.numberX * lngRatio - lngIndex;

    const downIndex = index - info.numberX;
    const rightIndex = index + 1;
    const rightDownIndex = index - info.numberX + 1;

    const current = get(index);
    const right = get(rightIndex);
    const down = get(downIndex);
    const rightDown = get(rightDownIndex);

    const averageUp = current * (1.0 - lngRemainder) + right * lngRemainder;
    const averageDown = down * (1.0 - lngRemainder) + rightDown * lngRemainder;
    const altitude = averageUp * (1.0 - latRemainder) + averageDown * latRemainder;
    return altitude;
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }
}

export {AltitudeObjectRenderKit};
