import {Geometry} from './Geometry';

/**
 * カスタム形状
 */
class CustomGeometry implements Geometry {
  private vertices: number[];
  private indices: number[];
  private normals?: number[];
  private colors?: number[];

  /**
   * コンストラクタ
   * @param vertices 頂点
   * @param indices インデックス
   * @param normals 法線
   * @param colors 色
   */
  constructor(vertices: number[], indices: number[], normals?: number[], colors?: number[]) {
    this.vertices = vertices;
    this.indices = indices;
    this.normals = normals;
    this.colors = colors;
  }

  /**  @override */
  getVertices(): number[] {
    return this.vertices;
  }

  /** @override */
  getIndices(): number[] {
    return this.indices;
  }

  /** @override */
  getNormals(): number[] | undefined {
    return this.normals;
  }

  /**
   * 色情報を取得
   * @returns 色情報
   */
  getColors(): number[] | undefined {
    return this.colors;
  }

  /**
   * 頂点情報を更新
   * @param vertices 頂点
   * @returns {void}
   */
  setVertices(vertices: number[]): void {
    this.vertices = vertices;
  }

  /**
   * 頂点座標の一部の値を更新
   * @param value 更新する値
   * @param index インデックス
   * @returns {void}
   */
  setVerticesValue(value: number, index: number): void {
    this.vertices[index] = value;
  }

  /**
   * インデックス情報を更新
   * @param indices インデックス
   * @returns {void}
   */
  setIndices(indices: number[]): void {
    this.indices = indices;
  }

  /**
   * 色情報を更新
   * @param colors 色
   * @returns {void}
   */
  setColors(colors: number[]): void {
    this.colors = colors;
  }
}

export {CustomGeometry};
