import {GaIAError} from './GaIAError';
import {
  TrafficConditionInitOptions,
  TrafficJamSourceType,
  RoadRegulationOptions,
  DefinedRegulationOptions,
} from '../types';

/**
 * 交通情報コンディション
 */
class TrafficCondition {
  readonly source: TrafficJamSourceType;
  readonly uid: string;
  readonly showArrow: boolean;
  readonly trafficTime: Date;

  readonly roadRegulation?: RoadRegulationOptions;
  readonly definedRegulation?: DefinedRegulationOptions;
  readonly regulationTime: Date;

  readonly server?: string;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param server タイル取得向き先URL
   */
  constructor(options: TrafficConditionInitOptions, server?: string) {
    this.source = options.source;
    if ((this.source === 'vics' || this.source === 'both') && !options.uid) {
      throw new GaIAError('uid is required.');
    }
    const now = new Date();
    this.uid = options.uid ?? '';
    this.trafficTime = options.trafficTime ?? options.time ?? now;
    this.showArrow = options.showArrow ?? false;

    this.roadRegulation = options.roadRegulation;
    this.definedRegulation = options.definedRegulation;
    this.regulationTime = options.regulationTime ?? options.time ?? now;

    this.server = server;
  }
}

export {TrafficCondition};
