import {Layer} from '../../engine/layer/Layer';
import {GaiaContext} from '../GaiaContext';
import {calculateWorldCoordinate} from '../utils/MapUtil';
import {Camera} from '../../engine/camera/Camera';
import {AnnotationObject} from '../render/objects/AnnotationObject';
import {IconAnnotationData} from '../models/annotation/IconAnnotationData';
import {AnnotationObjectRenderKit, NoteAnnotationData} from '../render/kit/object/AnnotationObjectRenderKit';
import {AnnotationMainParameter} from '../loader/param/AnnotationMainParameter';
import {AnnotationClickListener, AnnotationClickListenerOptions, AnnotationType} from '../../../gaia/types';
import {Ray3} from '../../common/math/Ray3';
import {Size} from '../../../gaia/value';
import {Collision} from '../../engine/collision/Collision';
import {MapIconObjectRenderKit} from '../render/kit/object/MapIconObjectRenderKit';
import {AbstractNoteAnnotationLayer} from './AbstractNoteAnnotationLayer';
import {TextAnnotationData} from '../models/annotation/TextAnnotationData';
import {Vector3} from '../../common/math/Vector3';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * NoteAnnotationDataのType Guard
 * @param item 判定対象
 * @returns NoteAnnotationData型か
 */
const isNoteAnnotationData = (item: any): item is NoteAnnotationData => {
  return item instanceof TextAnnotationData || item instanceof IconAnnotationData;
};
/* eslint-enable */

const ANNOTATION_LAYER_NAME_NOTE = 'noteAnnotation';

/**
 * テキスト・アイコン注記レイヤー
 */
class NoteAnnotationLayer
  extends AbstractNoteAnnotationLayer<AnnotationMainParameter, IconAnnotationData>
  implements Layer {
  private onAnnotationClick?: AnnotationClickListener;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKit AnnotationObjectRenderKit
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKit: AnnotationObjectRenderKit | MapIconObjectRenderKit, camera: Camera) {
    super(context, renderKit, camera);
  }

  /**
   * 注記クリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setAnnotationClickListener(listener: AnnotationClickListener, options?: AnnotationClickListenerOptions): void {
    this.onAnnotationClick = listener;
    this.isIconClickable = options?.isIconClickable ?? true;
    this.isTextClickable = options?.isTextClickable ?? true;
    this.isNodeIconOnly = options?.isNodeOnly?.icon ?? false;
    this.isNodeTextOnly = options?.isNodeOnly?.text ?? false;
    this.onceClick = options?.once ?? false;
  }

  /**
   * 注記クリックリスナーの削除
   * @returns {void}
   */
  removeAnnotationClickListener(): void {
    this.onAnnotationClick = undefined;
  }

  /** @override */
  createAnnotationObjectMap(dataList: IconAnnotationData[]): Map<string, AnnotationObject<IconAnnotationData>> {
    const iconObjectList: Map<string, AnnotationObject<IconAnnotationData>> = new Map();
    for (const iconData of dataList) {
      const altitude = this.getAltitudeCallback(iconData.latlng);
      const position = calculateWorldCoordinate(iconData.latlng).add(new Vector3(0, 0, altitude));
      const uv = iconData.uv;
      const pixelRatio = iconData.tileSize / 256;
      const clientSize = new Size(iconData.size.height / pixelRatio, iconData.size.width / pixelRatio);
      const object = new AnnotationObject(iconData, position, clientSize, uv);
      iconObjectList.set(iconData.getCacheKey(), object);
    }
    return iconObjectList;
  }

  /** @override */
  getCollisions(ray: Ray3): Collision[] {
    const collisions: Collision[] = [];

    if (this.isIconClickable) {
      const collidedIcon = this.iconGroupObject.getCollidedAnnotation(ray);
      if (collidedIcon && (!this.isNodeIconOnly || collidedIcon.data.nodeId)) {
        const collision = new Collision(this.iconGroupObject, collidedIcon);
        collisions.push(collision);
      }
    }

    if (this.isTextClickable) {
      for (const group of this.inDisplayTextObjects.values()) {
        const collidedText = group.getCollidedAnnotation(ray);
        if (collidedText && (!this.isNodeTextOnly || collidedText.data.nodeId)) {
          const collision = new Collision(group, collidedText);
          collisions.push(collision);
        }
      }
    }

    return collisions;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return ANNOTATION_LAYER_NAME_NOTE;
  }

  /** @override */
  setupHover(): void {
    this.mouse.addEventListener('mousemove', (ev: MouseEvent) => {
      if (
        !this.onAnnotationClick ||
        this.inDisplayTextObjects.size() === 0 ||
        !this.isOnMap(ev) ||
        (!this.isIconClickable && !this.isTextClickable)
      ) {
        return;
      }
      const ray = this.clientPositionToRay(ev.offsetX, ev.offsetY);
      const allCollisions = this.getAllCollisions(ray);

      for (const [layerName, collisions] of allCollisions.entries()) {
        if (layerName === ANNOTATION_LAYER_NAME_NOTE) {
          // 自分のレイヤーで当たり判定があるときは処理を行う
          let collidedAnnotationObject: AnnotationObject<IconAnnotationData> | undefined;
          if (collisions.length > 0) {
            const collision = collisions[0];
            const additional = collision.getAdditional();
            if (additional) {
              collidedAnnotationObject = additional as AnnotationObject<IconAnnotationData>;
            }
          }

          if (collidedAnnotationObject) {
            this.hoveredObject = collidedAnnotationObject;
            this.context.getBaseElement().style.cursor = 'pointer';
          } else {
            this.hoveredObject = undefined;
            this.context.getBaseElement().style.cursor = 'auto';
          }

          break;
        }

        // 自分のレイヤーより前に当たり判定があるときは何もしない
        if (collisions.length > 0) {
          this.hoveredObject = undefined;
          return;
        }
      }
    });
  }

  /**
   * クリック時の設定
   * @returns {void}
   */
  setupClick(): void {
    this.mouse.addEventListener('click', () => {
      if (
        !this.onAnnotationClick ||
        this.inDisplayTextObjects.size() === 0 ||
        (!this.isTextClickable && !this.isIconClickable) ||
        !this.hoveredObject ||
        !isNoteAnnotationData(this.hoveredObject.data)
      ) {
        return;
      }

      const data = this.hoveredObject.data;
      const {latlng, nodeId} = data;
      let type: AnnotationType = 'icon';
      let appearance = undefined;

      if (data instanceof TextAnnotationData) {
        if (!this.isTextClickable) {
          return;
        }
        type = 'text';
        appearance = data.textureData.appearance.replace(/\\n/g, '');
      } else {
        if (!this.isIconClickable) {
          return;
        }
      }

      this.onAnnotationClick?.({type, appearance, latlng, nodeId});
      if (this.onceClick) {
        this.onAnnotationClick = undefined;
        this.context.getBaseElement().style.cursor = 'auto';
      }
    });
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {NoteAnnotationLayer};
