import {mat4} from 'gl-matrix';
import {Vector2} from '../../common/math/Vector2';
import {RenderTarget} from '../../common/types';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {Object3D} from '../object/Object3D';
import {AltitudeProgram} from '../program/AltitudeProgram';
import {Material} from './Material';

/**
 * 標高を描画するマテリアル
 */
class AltitudeMaterial implements Material {
  readonly program: AltitudeProgram;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   */
  constructor(context: WebGLRenderingContext, geometry: CustomGeometry) {
    this.program = new AltitudeProgram(context, geometry);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: CustomGeometry): void {
    this.program.setGeometry(geometry);
  }

  /**
   * テクスチャを設定
   * @param texture テクスチャ
   * @returns {void}
   */
  setGLTexture(texture: WebGLTexture): void {
    this.program.setGLTexture(texture);
  }

  /**
   * uv座標のオフセットを設定
   * @param uvOffset uv座標のオフセット
   * @returns {void}
   */
  setUvOffset(uvOffset: Vector2): void {
    this.program.setUvOffset(uvOffset);
  }

  /**
   * ズームレベルの小数部に1を足した値を設定
   * @param zoomRemainder ズームレベルの小数部に1を足した値
   * @returns {void}
   */
  setZoomRemainder(zoomRemainder: number): void {
    this.program.setZoomRemainder(zoomRemainder);
  }

  /**
   * フレームバッファをクリア
   * @returns {void}
   */
  clearFrameBuffer(): void {
    this.program.clearFrameBuffer();
  }

  /**
   * 更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    this.program.update(object3D, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @param renderTarget 描画対象の状態
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    this.program.draw(renderTarget);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.program.destroy();
  }
}

export {AltitudeMaterial};
