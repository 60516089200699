/* eslint-disable @typescript-eslint/camelcase */
import {JsonObject} from '../../../gaia/types';
import {LRUCache} from '../../common/collection/LRUCache';
import {Queue} from '../../common/collection/Queue';
import {AnnotationDBHandler} from './db/AnnotationDBHandler';
import {
  AnnotationMainInfo,
  AnnotationMetaInfo,
  isAnnotationMainInfo,
  isAnnotationMetaInfo,
} from '../../common/infra/response/AnnotationInfo';
import {Optional, QueryParameter} from '../../common/types';
import {LoaderState, OnFinishRequestFunc} from './AbstractTileLoader';
import {AnnotationMainParameter} from './param/AnnotationMainParameter';
import {GaiaContext} from '../GaiaContext';
import {AnnotationInfoRequester} from '../../common/infra/request/AnnotationInfoRequester';
import {AnnotationMetaParameter} from './param/AnnotationMetaParameter';
import {buildQueryString} from '../../common/util/URL';
import JSZip from 'jszip';
import {MapIconMainParameter} from './param/MapIconMainParameter';
import {isMapIconMainInfo, MapIconMainInfo} from '../../common/infra/response/MapIconMainInfo';
import {TileNumber} from '../models/TileNumber';

const ICON_T_NAME = 'icon.png';
const MARK_T_NAME = 'mark.png';

const PATH_ANNOTATION = 'data/annotation';
const PATH_MAP_ICON = 'data/map/spot';

const DEFAULT_MAP_ICON_PRODUCT = 'tile';

/**
 * 注記ローダー
 */
class AnnotationLoader {
  private serverUrl = '';
  private readonly requester: AnnotationInfoRequester;
  private readonly db?: AnnotationDBHandler;

  private currentMetaParam?: AnnotationMetaParameter;
  private metaRequesting: boolean;
  private iconRequesting: boolean;
  private metaCache?: AnnotationMetaInfo;
  private iconTCache?: HTMLCanvasElement;
  private markTCache?: HTMLCanvasElement;

  private readonly mainCache: LRUCache<AnnotationMainParameter, AnnotationMainInfo>;
  private readonly mainRequestQueue: Queue<AnnotationMainParameter>;
  private readonly requestingTile: Set<string>;
  private readonly onFinishRequest: OnFinishRequestFunc;

  private readonly mapIconMainCache: LRUCache<MapIconMainParameter, MapIconMainInfo>;
  private readonly mapIconMainRequestQueue: Queue<MapIconMainParameter>;
  private readonly mapIconMainRequestingTile: Set<string>;
  private readonly onFinishMapIconMainRequest: OnFinishRequestFunc;

  private state: LoaderState = 'initialized';

  private tags: string[];
  private mapIconProduct?: string;

  private processAnnotationDataTasks: Map<TileNumber, () => void> = new Map();

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest データ読み込み完了通知
   * @param onFinishMapIconMainRequest 地図アイコンのデータ読み込み完了通知
   */
  constructor(
    context: GaiaContext,
    onFinishRequest: OnFinishRequestFunc,
    onFinishMapIconMainRequest: OnFinishRequestFunc
  ) {
    context
      .getGaIAConfiguration()
      .then((res) => {
        if (!res.server.data) {
          return;
        }
        this.serverUrl = `${res.server.data}v1/${res.productId}`;
        this.setState('ready');
        this.executeRequest();
      })
      .catch(() => {
        // do nothing
      });

    this.requester = new AnnotationInfoRequester(
      (metaParam) => {
        return this.createMetaUrl(metaParam);
      },
      (metaParam) => {
        return this.createIconUrl(metaParam);
      },
      (annotationParam) => {
        return this.createUrl(annotationParam);
      },
      (mapIconMainParam) => {
        return this.createMapIconMainUrl(mapIconMainParam);
      }
    );

    const enableAnnotationDB = context.getMapInitOptions().isAnnotationEnabled ?? false;
    const useIndexedDB = context.getMapInitOptions().annotationIndexedDB?.useIndexedDB ?? true;

    if (enableAnnotationDB && useIndexedDB) {
      this.db = new AnnotationDBHandler(context.getMapInitOptions().annotationIndexedDB?.recordMax);
    } else {
      this.db = undefined;
    }

    this.metaRequesting = false;
    this.iconRequesting = false;

    this.mainCache = new LRUCache(100);
    this.mainRequestQueue = new Queue<AnnotationMainParameter>(150);
    this.requestingTile = new Set<string>();
    this.onFinishRequest = onFinishRequest;

    this.mapIconMainCache = new LRUCache(100);
    this.mapIconMainRequestQueue = new Queue<MapIconMainParameter>(150);
    this.mapIconMainRequestingTile = new Set<string>();
    this.onFinishMapIconMainRequest = onFinishMapIconMainRequest;

    this.tags = [];
  }

  /**
   * メタリクエストのURLを生成
   * @param metaParam AnnotationMetaParameter
   * @returns リクエストURL
   */
  private createMetaUrl(metaParam: AnnotationMetaParameter): string {
    const {tileSize, palette} = metaParam;
    const query: QueryParameter = {};
    if (palette.name) {
      query['palette'] = palette.name;
    }
    return `${this.serverUrl}/${PATH_ANNOTATION}/${palette.language}/${tileSize}/meta?${buildQueryString(query)}`;
  }

  /**
   * アイコンリクエストのURLを生成
   * @param metaParam AnnotationMetaParameter
   * @returns リクエストURL
   */
  private createIconUrl(metaParam: AnnotationMetaParameter): string {
    const {tileSize, palette} = metaParam;
    const query: QueryParameter = {};
    if (palette.name) {
      query['palette'] = palette.name;
    }
    return `${this.serverUrl}/${PATH_ANNOTATION}/${palette.language}/${tileSize}/icon?${buildQueryString(query)}`;
  }

  /**
   * メインリクエストのURLを生成
   * @param mainParam AnnotationMainParameter
   * @returns リクエストURL
   */
  private createUrl(mainParam: AnnotationMainParameter): string {
    const {x, y, z, tileSize, palette} = mainParam;
    const query: QueryParameter = {};
    if (palette.name) {
      query['palette'] = palette.name;
    }
    const queryString = buildQueryString(query);
    return `${this.serverUrl}/${PATH_ANNOTATION}/${palette.language}/${tileSize}/${z}/${x}/${y}?${queryString}`;
  }

  /**
   * 地図アイコンメインリクエストのURLを生成
   * @param mapIconMainParam MapIconMainParameter
   * @returns リクエストURL
   */
  private createMapIconMainUrl(mapIconMainParam: MapIconMainParameter): string {
    const {x, y, z, tileSize, palette} = mapIconMainParam;
    const query: QueryParameter = {
      tag: this.tags.join('.'),
      product: this.mapIconProduct ?? DEFAULT_MAP_ICON_PRODUCT,
    };
    if (palette.name) {
      query['palette'] = palette.name;
    }
    const queryString = buildQueryString(query);
    return `${this.serverUrl}/${PATH_MAP_ICON}/${palette.language}/${tileSize}/${z}/${x}/${y}?${queryString}`;
  }

  /**
   * Loaderの状態を判定
   * @param state LoaderState
   * @returns 指定された状態のときにtrue
   */
  private isState(state: LoaderState): boolean {
    return this.state === state;
  }

  /**
   * Loaderの状態を設定
   * @param state LoaderState
   * @returns {void}
   */
  private setState(state: LoaderState): void {
    // TODO: 状態遷移を制御するかどうか
    this.state = state;
  }

  /**
   * 地図アイコン通信時に使用するプロダクトを設定
   * @param mapIconProduct プロダクト
   * @returns {void}
   */
  setMapIconProduct(mapIconProduct: string): void {
    this.mapIconProduct = mapIconProduct;
  }

  /**
   * キャッシュとリクエストを全てクリアする
   * @returns {void}
   */
  clear(): void {
    this.currentMetaParam = undefined;
    this.metaRequesting = false;
    this.metaCache = undefined;
    this.iconTCache = undefined;
    this.markTCache = undefined;
    this.mainCache.clear();
    this.mainRequestQueue.clear();
    this.requestingTile.clear();
    this.requester.clear();
  }

  /**
   * リクエストキューをクリア
   * @returns {void}
   */
  clearRequestQueue(): void {
    this.mainRequestQueue.clear();
    this.mapIconMainRequestQueue.clear();
    this.requestingTile.clear();
    this.requester.clear();
    this.db?.clearMainRequestQueue();
  }

  /**
   * キャッシュにあるメタデータを返す
   * @param metaParam AnnotationMetaParameter
   * @returns AnnotationMetaInfo
   */
  getMetaInfo(metaParam: AnnotationMetaParameter): Optional<AnnotationMetaInfo> {
    if (!metaParam.equals(this.currentMetaParam)) {
      return undefined;
    }
    return this.metaCache;
  }

  /**
   * キャッシュにあるアイコン画像を返す
   * @param metaParam AnnotationMetaParameter
   * @returns アイコン画像
   */
  getIconTCache(metaParam: AnnotationMetaParameter): Optional<TexImageSource> {
    if (!metaParam.equals(this.currentMetaParam)) {
      return undefined;
    }
    return this.iconTCache;
  }

  /**
   * キャッシュにある国道アイコン画像を返す
   * @param metaParam AnnotationMetaParameter
   * @returns 国道アイコン画像
   */
  getMarkTCache(metaParam: AnnotationMetaParameter): Optional<CanvasImageSource> {
    if (!metaParam.equals(this.currentMetaParam)) {
      return undefined;
    }
    return this.markTCache;
  }

  /**
   * キャッシュにあるAnnotationMainInfoを返す
   * @param annotationParam AnnotationMainParameter
   * @returns AnnotationMainInfo
   */
  getMainInfo(annotationParam: AnnotationMainParameter): Optional<AnnotationMainInfo> {
    return this.mainCache.get(annotationParam);
  }

  /**
   * キャッシュにあるMapIconMainInfoを返す
   * @param mapIconMainParam MapIconMainParam
   * @returns MapIconMainInfo
   */
  getMapIconMainInfo(mapIconMainParam: MapIconMainParameter): Optional<MapIconMainInfo> {
    return this.mapIconMainCache.get(mapIconMainParam);
  }

  /**
   * AnnotationMetaParameter設定
   * @param metaParam AnnotationMetaParameter
   * @returns {void}
   */
  setMetaParameter(metaParam: AnnotationMetaParameter): void {
    if (!metaParam.equals(this.currentMetaParam)) {
      this.currentMetaParam = metaParam;
      this.metaCache = undefined;
    }
  }

  /**
   * 描画に必要なタイルをリクエストキューに追加
   * @param paramList AnnotationMainParameterリスト
   * @returns {void}
   */
  addMainRequestQueue(paramList: AnnotationMainParameter[]): void {
    for (const param of paramList) {
      if (this.mainCache.has(param)) {
        continue;
      }
      this.mainRequestQueue.enqueue(param);
    }
  }

  /**
   * 描画に必要なタイルをリクエストキューに追加
   * @param paramList MapIconMainParameterリスト
   * @returns {void}
   */
  addMapIconMainRequestQueue(paramList: MapIconMainParameter[]): void {
    for (const param of paramList) {
      if (this.mapIconMainCache.has(param)) {
        continue;
      }
      this.mapIconMainRequestQueue.enqueue(param);
    }
  }

  /**
   * リクエストを実行
   * @returns {void}
   */
  executeRequest(): void {
    if (!this.isState('ready')) {
      return;
    }

    this.executeMetaRequest(() => {
      this.executeIconRequest();
      this.executeMainRequest();
      this.executeMapIconMainRequest();
    });
  }

  /**
   * メタリクエストを実行
   * @param onMetaLoad メタ読み込み完了通知
   * @returns {void}
   */
  private executeMetaRequest(onMetaLoad: () => void): void {
    // 既にリクエスト中 or メタパラメータが未設定の場合は中止
    if (this.metaRequesting || !this.currentMetaParam) {
      return;
    }

    // 既にキャッシュがある場合は完了とする
    if (this.metaCache) {
      onMetaLoad();
      return;
    }

    this.metaRequesting = true;
    this.requester.metaRequest(this.currentMetaParam, (metaParam, latest) => {
      if (this.isState('destroyed') || !metaParam.equals(this.currentMetaParam)) {
        // リクエスト中に地図が破棄された or メタパラメータが変わっていたら中止
        return;
      }

      if (!this.db) {
        this.metaRequesting = false;
        this.metaCache = latest;
        onMetaLoad();
        return;
      }

      this.db.findMetaData(metaParam, (_, param, local) => {
        this.metaRequesting = false;

        // リクエスト中に地図破棄 or メタパラメータが変わっている
        // or 通信結果とローカルデータの両方が不正の場合は中止
        if (
          this.isState('destroyed') ||
          !param.equals(this.currentMetaParam) ||
          (!isAnnotationMetaInfo(latest) && !isAnnotationMetaInfo(local))
        ) {
          return;
        }

        // 通信結果が不正の場合はローカルデータを利用
        if (!isAnnotationMetaInfo(latest)) {
          this.metaCache = local;
          onMetaLoad();
          return;
        }

        this.metaCache = latest;
        if (
          isAnnotationMetaInfo(local) &&
          local.serial.data === latest.serial.data &&
          local.serial.palette === latest.serial.palette
        ) {
          onMetaLoad();
        } else {
          if (!this.db) {
            return;
          }

          // ローカルデータが不正 or シリアルが変わっている場合はアイコン・メインデータをリセット
          this.db.insertMetaData(metaParam, latest);
          this.db.deleteAllIconData();
          this.db.deleteAllMainData();
          onMetaLoad();
        }
      });
    });
  }

  /**
   * アイコンリクエストを実行
   * @returns {void}
   */
  private executeIconRequest(): void {
    if (!this.metaCache || !this.currentMetaParam || (this.iconTCache && this.markTCache) || this.iconRequesting) {
      return;
    }

    if (!this.db) {
      this.iconRequest(this.currentMetaParam);
      return;
    }

    // DBから取得
    this.db.findIconData(this.currentMetaParam, (_, metaParam, zip) => {
      if (this.isState('destroyed') || !metaParam.equals(this.currentMetaParam)) {
        // リクエスト中に地図が破棄された or AnnotationMetaParameterが変わっている場合はキャッシュしない
        return;
      }

      if (zip) {
        this.decompressZip(zip).then(this.onFinishRequest);
      } else {
        // データがない場合は通信でリクエスト
        this.iconRequest(metaParam);
      }
    });
  }

  /**
   * 通信でのアイコンリクエスト
   * @param param AnnotationMetaParameter
   * @returns {void}
   */
  private iconRequest(param: AnnotationMetaParameter): void {
    this.iconRequesting = true;
    this.requester.iconRequest(param, (metaParam, zip) => {
      this.iconRequesting = false;

      if (this.isState('destroyed') || !metaParam.equals(this.currentMetaParam)) {
        // リクエスト中に地図が破棄された or AnnotationMetaParameterが変わっている場合はキャッシュしない
        return;
      }

      this.decompressZip(zip).then((isSucceeded) => {
        if (isSucceeded && this.db) {
          this.db.insertIconData(metaParam, zip);
        }
        this.onFinishRequest();
      });
    });
  }

  /**
   * アイコンzip解凍
   * @param zip アイコンzip
   * @returns {void}
   */
  private decompressZip(zip: Blob): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Promise.all([this.createIconImage(zip, ICON_T_NAME), this.createIconImage(zip, MARK_T_NAME)])
        .then((images) => {
          const [iconImage, markImage] = images;
          const isSucceeded = iconImage && markImage ? true : false;
          if (iconImage) {
            this.iconTCache = iconImage;
          }
          if (markImage) {
            this.markTCache = markImage;
          }
          resolve(isSucceeded);
        })
        .catch(reject);
    });
  }

  /**
   * zipからアイコン画像作成
   * @param zip アイコンzip
   * @param fileName ファイル名
   * @returns アイコン画像
   */
  private createIconImage(zip: Blob, fileName: string): Promise<Optional<HTMLCanvasElement>> {
    return new Promise((resolve, reject) => {
      JSZip.loadAsync(zip)
        .then((zip) => {
          return zip.file(fileName);
        })
        .then((obj) => {
          return obj?.async('blob');
        })
        .then((blob) => {
          if (!blob) {
            return resolve(undefined);
          }

          const image = new Image();
          image.onload = (): void => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            ctx?.drawImage(image, 0, 0);
            window.URL.revokeObjectURL(image.src);
            resolve(canvas);
          };
          image.src = window.URL.createObjectURL(blob);
        })
        .catch(reject);
    });
  }

  /**
   * メインリクエストを実行
   * @returns {void}
   */
  private executeMainRequest(): void {
    if (this.mainRequestQueue.size === 0 || !this.metaCache) {
      return;
    }

    const dbRequestTiles: AnnotationMainParameter[] = [];
    while (this.mainRequestQueue.size > 0) {
      const parameter = this.mainRequestQueue.dequeue();
      if (!parameter) {
        break;
      }
      if (this.mainCache.has(parameter) || this.requestingTile.has(parameter.getCacheKey())) {
        continue;
      }
      dbRequestTiles.push(parameter);
      this.requestingTile.add(parameter.getCacheKey());
    }

    for (const requestTile of dbRequestTiles) {
      if (!this.db) {
        this.mainRequest(requestTile);
        continue;
      }

      // DBから取得
      this.db.findMainData(requestTile, (_, tile: AnnotationMainParameter, encryptedData?: string) => {
        if (this.isState('destroyed') || !this.requestingTile.has(tile.getCacheKey())) {
          // リクエスト中に地図が破棄された or リクエスト対象から外れたものはキャッシュしない
          return;
        }

        if (encryptedData) {
          this.processAnnotationDataTasks.set(tile, () => {
            const info = this.convertToMainInfo(encryptedData);
            if (isAnnotationMainInfo(info)) {
              this.mainCache.add(tile, info);
              this.requestingTile.delete(tile.getCacheKey());
              this.onFinishRequest();
            }
          });
          return;
        }

        // データがない or ローカルデータが不正の場合は通信でリクエスト
        this.mainRequest(tile);
      });
    }
  }

  /**
   * 地図アイコンのメインリクエストを実行
   * @returns {void}
   */
  private executeMapIconMainRequest(): void {
    if (this.mapIconMainRequestQueue.size === 0 || !this.metaCache) {
      return;
    }

    const dbRequestTiles: MapIconMainParameter[] = [];
    while (this.mapIconMainRequestQueue.size > 0) {
      const parameter = this.mapIconMainRequestQueue.dequeue();
      if (!parameter) {
        break;
      }
      if (this.mapIconMainCache.has(parameter) || this.mapIconMainRequestingTile.has(parameter.getCacheKey())) {
        continue;
      }
      dbRequestTiles.push(parameter);
      this.mapIconMainRequestingTile.add(parameter.getCacheKey());
    }

    for (const requestTile of dbRequestTiles) {
      if (!this.db) {
        this.mapIconMainRequest(requestTile);
        continue;
      }

      this.mapIconMainRequest(requestTile);
    }
  }

  /**
   * 通信でのメインリクエスト
   * @param param AnnotationMainParameter
   * @returns {void}
   */
  private mainRequest(param: AnnotationMainParameter): void {
    this.requester.mainRequest(param, (tile, encryptedData) => {
      if (this.isState('destroyed') || !this.requestingTile.has(tile.getCacheKey())) {
        // リクエスト中に地図が破棄された or リクエスト対象から外れたものはキャッシュしない
        return;
      }

      this.db?.insertMainData(tile, encryptedData);
      const info = this.convertToMainInfo(encryptedData);
      // TODO データ不正の場合無限リクエストの恐れがあるため修正
      // データが不正でもキャッシュに積んでおく？
      if (isAnnotationMainInfo(info)) {
        this.mainCache.add(tile, info);
      }

      this.requestingTile.delete(tile.getCacheKey());
      this.onFinishRequest();
    });
  }

  /**
   * 通信での地図アイコンのメインリクエスト
   * @param param MapIconMainParameter
   * @returns {void}
   */
  private mapIconMainRequest(param: MapIconMainParameter): void {
    this.requester.mapIconMainRequest(param, (tile, info) => {
      if (this.isState('destroyed') || !this.mapIconMainRequestingTile.has(tile.getCacheKey())) {
        // リクエスト中に地図が破棄された or リクエスト対象から外れたものはキャッシュしない
        return;
      }

      const mapIconMainInfo = this.convertToMapIconMainInfo(info);
      if (isMapIconMainInfo(mapIconMainInfo)) {
        this.mapIconMainCache.add(tile, mapIconMainInfo);
      }

      this.mapIconMainRequestingTile.delete(tile.getCacheKey());
      this.onFinishMapIconMainRequest();
    });
  }

  /**
   * 難読化されているMainInfoをJsonに変換
   * @param str 難読化されたMainInfo
   * @returns Json
   */
  private convertToMainInfo(str: string): JsonObject {
    const reversed = str.split('').reverse().join('');
    const decoded = decodeURIComponent(escape(atob(reversed)));
    return JSON.parse(decoded);
  }

  /**
   * 地図アイコンのMainInfoをJsonに変換
   * @param str 地図アイコンのMainInfo
   * @returns Json
   */
  private convertToMapIconMainInfo(str: string): JsonObject {
    try {
      const reversed = str.split('').reverse().join('');
      const decoded = decodeURIComponent(escape(atob(reversed)));
      return JSON.parse(decoded);
    } catch {
      return {};
    }
  }

  /**
   * 地図アイコンのタグリストを設定
   * @param tags タグのリスト
   * @returns {void}
   */
  setMapIconTags(tags: string[]): void {
    this.tags = tags;
  }

  /**
   * メインデータのキャッシュサイズ上限を拡張
   * @param size 変更後サイズ
   * @returns {void}
   */
  jumpUpMainCacheSize(size: number): void {
    this.mainCache.jumpUpSize(size);
  }

  /**
   * 地図アイコンのメインデータのキャッシュサイズ上限を拡張
   * @param size 変更後サイズ
   * @returns {void}
   */
  jumpUpMapIconMainCacheSize(size: number): void {
    this.mapIconMainCache.jumpUpSize(size);
  }

  /**
   * フレームレート維持のためあとにまわしていたタスクを実行する
   * @param tileList タイル番号のリスト
   * @returns {void}
   */
  processAnnotationData(): void {
    this.processAnnotationDataTasks.forEach((task, _tile) => {
      task();
    });
    this.processAnnotationDataTasks.clear();
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.mainCache.clear();
    this.mainRequestQueue.clear();
    this.requestingTile.clear();
    this.requester.destroy();
    this.db?.destroy();
    this.setState('destroyed');
  }
}

export {AnnotationLoader};
