import {GaIAEvent, GaIAEventListenerOptions} from '../../../gaia/types';
import {EventListenerFunctionReturnType} from '../../common/types';

type EventMap = {[key: string]: GaIAEvent};
type EventListenerFunction = (ev: GaIAEvent) => EventListenerFunctionReturnType;

/**
 * イベントリスナの登録・削除処理を行うためのヘルパークラス
 */
class GaIAEventListenerHelper<M extends EventMap> {
  private readonly handlers: Map<keyof M, EventListenerFunction[]>;
  private readonly onceHandlers: Map<keyof M, EventListenerFunction[]>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.handlers = new Map<keyof M, EventListenerFunction[]>();
    this.onceHandlers = new Map<keyof M, EventListenerFunction[]>();
  }

  /**
   * イベントリスナーの追加
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  addListener<K extends keyof M>(
    eventName: K,
    func: (ev: M[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    const handlerMap = options?.once ? this.onceHandlers : this.handlers;
    if (!handlerMap.has(eventName)) {
      handlerMap.set(eventName, []);
    }

    const listeners = handlerMap.get(eventName) ?? [];
    if (listeners.includes(func as EventListenerFunction)) {
      return;
    } else {
      listeners.push(func as EventListenerFunction);
    }
  }

  /**
   * イベントリスナーの削除
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  removeListener<K extends keyof M>(
    eventName: K,
    func: (ev: M[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    const handlerList = options?.once ? this.onceHandlers.get(eventName) : this.handlers.get(eventName);
    if (!handlerList) {
      return;
    }
    const idx = handlerList.indexOf(func as EventListenerFunction);
    if (idx >= 0) {
      handlerList.splice(idx, 1);
    }
  }

  /**
   * イベントハンドラの実行
   * @param eventName イベント名
   * @param event イベントオブジェクト
   * @returns {void}
   */
  trigger<K extends keyof M>(eventName: K, event: M[K]): void {
    const handlerList = this.handlers.get(eventName) ?? [];
    for (const func of handlerList) {
      func(event as UIEvent);
    }

    const onceHandlerList = this.onceHandlers.get(eventName) ?? [];
    while (onceHandlerList.length > 0) {
      onceHandlerList.shift()?.(event as UIEvent);
    }
  }

  /**
   * 登録されているイベントリスナの数を取得
   * @param eventName イベント名
   * @returns イベントリスナの数
   */
  getRegisteredListenerCount<K extends keyof M>(eventName: K): number {
    const handlerList = this.handlers.get(eventName) ?? [];
    const onceHandlerList = this.onceHandlers.get(eventName) ?? [];
    return handlerList.length + onceHandlerList.length;
  }
}

export {GaIAEventListenerHelper};
