import {TrainRouteInitOptions, TrainRouteCallback} from '../types';
import {ZoomRange} from './ZoomRange';

/**
 * 鉄道路線図コンディション
 */
class TrainRouteCondition {
  /** 表示ズームレベル範囲 */
  readonly zoomRange: ZoomRange;
  /** 見た目設定用コールバック */
  readonly callback: TrainRouteCallback;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param callback 見た目設定用コールバック
   */
  constructor(options: TrainRouteInitOptions, callback: TrainRouteCallback) {
    this.zoomRange = options.zoomRange ?? new ZoomRange();
    this.callback = callback;
  }
}

export {TrainRouteCondition};
