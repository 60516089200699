import {mat4} from 'gl-matrix';
import {RenderTarget} from '../../common/types';
import {Geometry} from '../geometry/Geometry';
import {Object3D} from '../object/Object3D';
import {DepthProgram} from '../program/DepthProgram';
import {Material} from './Material';

/**
 * 深度用のマテリアル
 */
class DepthMaterial implements Material {
  private readonly context: WebGLRenderingContext;
  readonly program: DepthProgram;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry) {
    this.context = context;
    this.program = new DepthProgram(context, geometry);
  }

  /**
   * 更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    this.program.update(object3D, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @param renderTarget 描画対象の設定
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    this.program.draw(renderTarget);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.program.setGeometry(geometry);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.program.destroy();
  }
}

export {DepthMaterial};
