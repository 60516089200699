/**
 * glTFのアクセサ
 */
class GltfAccessor {
  readonly bufferView: number;
  readonly byteOffset: number;
  readonly componentType: number;
  readonly normalized: boolean;
  readonly count: number;
  readonly max: number[];
  readonly min: number[];
  readonly type: string;

  /**
   * コンストラクタ
   * @param bufferView バッファービューのキー
   * @param byteOffset バイトオフセット
   * @param componentType 型
   * @param normalized 正規化済みかどうか
   * @param count 要素数
   * @param max 最大値のベクトル
   * @param min 最小値のベクトル
   * @param type タイプ
   */
  constructor(
    bufferView: number,
    byteOffset: number,
    componentType: number,
    normalized: boolean,
    count: number,
    max: number[],
    min: number[],
    type: string
  ) {
    this.bufferView = bufferView;
    this.byteOffset = byteOffset;
    this.componentType = componentType;
    this.normalized = normalized;
    this.count = count;
    this.max = max;
    this.min = min;
    this.type = type;
  }
}

export {GltfAccessor};
