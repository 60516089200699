import {AdditionTileConditionInitOptions} from '../types';
import {ZoomRange} from '.';

/**
 * 任意タイル地図情報コンディション
 */
class AdditionTileCondition {
  /** 表示ズームレベル範囲 */
  readonly zoomRange: ZoomRange;
  /** レイヤー不透明度 */
  readonly transparency: number;
  /** 注記レイヤー非表示フラグ */
  readonly hideAnnotation: boolean;
  /** 通常地図タイル非表示フラグ */
  readonly hideDefaultMapTile?: boolean;
  /** レイヤー描画優先度 */
  readonly priority: number;
  /** フェードイン有効フラグ */
  readonly fadeIn: boolean;

  /**
   * コンストラクタ
   * @param options 表示オプション
   */
  constructor(options: AdditionTileConditionInitOptions) {
    const {zoomRange, transparency, hideAnnotation, hideDefaultMapTile, priority, fadeIn} = options;
    this.zoomRange = zoomRange;
    this.transparency = transparency ?? 1;
    this.hideAnnotation = hideAnnotation ?? false;
    this.hideDefaultMapTile = hideDefaultMapTile;
    this.priority = priority ?? 0;
    this.fadeIn = fadeIn ?? true;
  }
}

export {AdditionTileCondition};
