import {Layer} from '../../engine/layer/Layer';
import {GaiaContext} from '../GaiaContext';
import {calculateWorldCoordinate} from '../utils/MapUtil';
import {Camera} from '../../engine/camera/Camera';
import {AnnotationObject} from '../render/objects/AnnotationObject';
import {AnnotationObjectRenderKit} from '../render/kit/object/AnnotationObjectRenderKit';
import {MapIconClickListener, MapIconClickListenerOptions, MapIconMouseEnterListener} from '../../../gaia/types';
import {Ray3} from '../../common/math/Ray3';
import {Size} from '../../../gaia/value';
import {Collision} from '../../engine/collision/Collision';
import {MapIconObjectRenderKit, NoteMapIconData} from '../render/kit/object/MapIconObjectRenderKit';
import {AbstractNoteAnnotationLayer} from './AbstractNoteAnnotationLayer';
import {MapIconData} from '../models/annotation/MapIconData';
import {MapIconMainParameter} from '../loader/param/MapIconMainParameter';
import {Vector2} from '../../common/math/Vector2';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * NoteAnnotationDataのType Guard
 * @param item 判定対象
 * @returns NoteAnnotationData型か
 */
const isNoteMapIconData = (item: any): item is NoteMapIconData => {
  return item instanceof MapIconData;
};
/* eslint-enable */

const LAYER_NAME_MAP_ICON = 'mapIcon';

/**
 * 地図アイコンレイヤー
 */
class MapIconLayer extends AbstractNoteAnnotationLayer<MapIconMainParameter, MapIconData> implements Layer {
  private onMapIconClick?: MapIconClickListener;
  private onMapIconMouseEnter?: MapIconMouseEnterListener;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKit AnnotationObjectRenderKit
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKit: AnnotationObjectRenderKit | MapIconObjectRenderKit, camera: Camera) {
    super(context, renderKit, camera);
  }

  /**
   * 地図アイコンクリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setMapIconClickListener(listener: MapIconClickListener, options?: MapIconClickListenerOptions): void {
    this.onMapIconClick = listener;
    this.isIconClickable = options?.isIconClickable ?? true;
    this.isTextClickable = false;
    this.isNodeIconOnly = false;
    this.isNodeTextOnly = false;
    this.onceClick = options?.once ?? false;
  }

  /**
   * 地図アイコンクリックリスナーの削除
   * @returns {void}
   */
  removeMapIconClickListener(): void {
    this.onMapIconClick = undefined;
  }

  /**
   * 地図アイコンマウスエンターリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapIconMouseEnterListener(listener: MapIconMouseEnterListener): void {
    this.onMapIconMouseEnter = listener;
  }

  /**
   * 地図アイコンマウスエンターリスナーの削除
   * @returns {void}
   */
  removeMapIconMouseEnterListener(): void {
    this.onMapIconMouseEnter = undefined;
  }

  /**
   * アイコンの当たり判定のマージンを設定する
   * @param margin マージン
   * @returns {void}
   */
  setMapIconCollisionMargin(margin: Vector2): void {
    this.iconGroupObject.setCollisionMargin(margin);
  }

  /** @override */
  createAnnotationObjectMap(dataList: MapIconData[]): Map<string, AnnotationObject<MapIconData>> {
    const iconObjectList: Map<string, AnnotationObject<MapIconData>> = new Map();
    for (const iconData of dataList) {
      const position = calculateWorldCoordinate(iconData.latlng);
      const uv = iconData.uv;
      const pixelRatio = iconData.tileSize / 256;
      const clientSize = new Size(iconData.size.height / pixelRatio, iconData.size.width / pixelRatio);
      const object = new AnnotationObject(iconData, position, clientSize, uv);
      iconObjectList.set(iconData.getCacheKey(), object);
    }
    return iconObjectList;
  }

  /** @override */
  getCollisions(ray: Ray3): Collision[] {
    if (!this.visible) {
      return [];
    }

    const collisions: Collision[] = [];

    if (this.isIconClickable) {
      const collidedIcon = this.iconGroupObject.getCollidedAnnotation(ray);
      if (collidedIcon) {
        const collision = new Collision(this.iconGroupObject, collidedIcon);
        collisions.push(collision);
      }
    }

    if (this.isTextClickable) {
      for (const group of this.inDisplayTextObjects.values()) {
        const collidedText = group.getCollidedAnnotation(ray);
        if (collidedText && (!this.isNodeTextOnly || collidedText.data.nodeId)) {
          const collision = new Collision(group, collidedText);
          collisions.push(collision);
        }
      }
    }

    return collisions;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_MAP_ICON;
  }

  /** @override */
  setupHover(): void {
    this.mouse.addEventListener('mousemove', (ev: MouseEvent) => {
      if (!this.isOnMap(ev) || this.inDisplayIcon.size() === 0) {
        return;
      }
      const ray = this.clientPositionToRay(ev.offsetX, ev.offsetY);
      const allCollisions = this.getAllCollisions(ray);

      for (const [layerName, collisions] of allCollisions.entries()) {
        if (layerName === LAYER_NAME_MAP_ICON) {
          // 自分のレイヤーで当たり判定があるときは処理を行う
          let collidedAnnotationObject: AnnotationObject<MapIconData> | undefined;
          if (collisions.length > 0) {
            const collision = collisions[0];
            const additional = collision.getAdditional();
            if (additional) {
              collidedAnnotationObject = additional as AnnotationObject<MapIconData>;
            }
          }

          if (collidedAnnotationObject) {
            if (!this.hoveredObject) {
              this.fireMouseEnterListener(collidedAnnotationObject.data);
            }
            this.hoveredObject = collidedAnnotationObject;
            if (this.onMapIconClick) {
              this.context.getBaseElement().style.cursor = 'pointer';
            }
          } else {
            this.hoveredObject = undefined;
            this.context.getBaseElement().style.cursor = 'auto';
          }

          break;
        }

        // 自分のレイヤーより前に当たり判定があるときは何もしない
        if (collisions.length > 0) {
          return;
        }
      }
    });
  }

  /**
   * クリック時の設定
   * @returns {void}
   */
  setupClick(): void {
    this.mouse.addEventListener('click', () => {
      if (
        !this.onMapIconClick ||
        (!this.isTextClickable && !this.isIconClickable) ||
        !this.hoveredObject ||
        !isNoteMapIconData(this.hoveredObject.data)
      ) {
        return;
      }

      const data = this.hoveredObject.data;
      const {latlng} = data;
      let name = undefined;
      let spotId = undefined;
      let address = undefined;
      let postalCode = undefined;
      let phone = undefined;
      let providerId = undefined;

      if (data instanceof MapIconData) {
        name = data.name;
        spotId = data.spotId;
        address = data.address;
        postalCode = data.postalCode;
        phone = data.phone;
        providerId = data.providerId;
      } else {
        return;
      }

      this.onMapIconClick?.({name, latlng, spotId, address, postalCode, phone, providerId});
      if (this.onceClick) {
        this.onMapIconClick = undefined;
        this.context.getBaseElement().style.cursor = 'auto';
      }
    });
  }

  /**
   * マウスエンターリスナーを発火する
   * @param data 地図アイコンデータ
   * @returns {void}
   */
  private fireMouseEnterListener(data: MapIconData): void {
    const {latlng} = data;
    let name = undefined;
    let spotId = undefined;
    let address = undefined;
    let postalCode = undefined;
    let phone = undefined;
    let providerId = undefined;

    if (data instanceof MapIconData) {
      name = data.name;
      spotId = data.spotId;
      address = data.address;
      postalCode = data.postalCode;
      phone = data.phone;
      providerId = data.providerId;
    } else {
      return;
    }
    this.onMapIconMouseEnter?.({name, latlng, spotId, address, postalCode, phone, providerId});
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {MapIconLayer};
