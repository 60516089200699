import {AbstractUIEventObserver} from './AbstractUIEventObserver';
import {WheelEventMap} from '../../../gaia/types';

/**
 * ホイール操作の監視と通知
 */
class WheelEventObserver extends AbstractUIEventObserver<WheelEventMap> {
  private preventDefault = true;

  /**
   * コンストラクタ
   * @param element HTMLElement
   */
  constructor(element: HTMLElement) {
    super(element);
  }

  /** @override */
  setupObserve(): void {
    this.baseElement.addEventListener('wheel', (ev) => this.handleWheelEvent(ev));
  }

  /**
   * ホイールイベントのハンドリング
   * @param ev WheelEvent
   * @returns {void}
   */
  handleWheelEvent(ev: WheelEvent): void {
    if (this.preventDefault) {
      ev.preventDefault();
    }
    this.trigger('wheel', ev);

    const {deltaX, deltaY} = ev;
    if (Math.abs(deltaX) > 0) {
      if (deltaX > 0) {
        this.trigger('wheelright', ev);
      } else if (deltaX < 0) {
        this.trigger('wheelleft', ev);
      }
    }
    if (deltaY > 0) {
      this.trigger('wheelup', ev);
    } else if (deltaY < 0) {
      this.trigger('wheeldown', ev);
    }
  }

  /**
   * デフォルトイベント中断の有無を指定する
   * @param prevent デフォルトイベントを中断するか
   * @returns {void}
   */
  setPreventDefault(prevent: boolean): void {
    this.preventDefault = prevent;
  }
}

export {WheelEventObserver};
