import {GltfId} from '../../GltfReader';
import {GltfPrimitive} from './GltfPrimitive';

/**
 * glTFのメッシュ
 */
class GltfMesh {
  readonly primitives: Map<GltfId, GltfPrimitive>;
  readonly name?: string;

  /**
   * コンストラクタ
   * @param primitives プリミティブ
   * @param name 名前
   */
  constructor(primitives: Map<GltfId, GltfPrimitive>, name?: string) {
    this.primitives = primitives;
    this.name = name;
  }
}

export {GltfMesh};
