import {JsonObject, Language, TileSize} from '../../../../gaia/types';
import {LatLng, Size} from '../../../../gaia/value';
import {ValueObject} from '../../../../gaia/value/interface/ValueObject';
import {CacheKey} from '../../../common/types';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {AbstractAnnotationData} from './AbstractAnnotationData';

/**
 * アイコン描画用データ抽象クラス
 */
abstract class AbstractIconAnnotationData extends AbstractAnnotationData implements CacheKey {
  readonly isForce: boolean;
  readonly size: Size;
  readonly uv: TexturePlaneUVCoordinate;
  readonly angle?: number;

  /**
   * コンストラクタ
   * @param lang 言語
   * @param tileSize タイルサイズ
   * @param priority 描画優先度
   * @param ntjCode NTJコード
   * @param latlng 緯度経度
   * @param isForce 強制描画フラグ
   * @param size 表示サイズ
   * @param uv UV座標
   * @param angle 角度
   */
  protected constructor(
    lang: Language,
    tileSize: TileSize,
    priority: number,
    ntjCode: number,
    latlng: LatLng,
    isForce: boolean,
    size: Size,
    uv: TexturePlaneUVCoordinate,
    angle?: number
  ) {
    super(lang, tileSize, priority, ntjCode, latlng);
    this.isForce = isForce;
    this.size = size;
    this.uv = uv;
    this.angle = angle;
  }

  /** @override */
  abstract getCacheKey(): string;

  /** @override */
  abstract equals(obj: unknown): boolean;

  /** @override */
  abstract clone(): ValueObject;

  /** @override */
  abstract toObject(): JsonObject;
}

export {AbstractIconAnnotationData};
