import {JsonObject, Language, TileSize} from '../../../../gaia/types';
import {transMillisecToDegree} from '../../../../gaia/util';
import {LatLng, Size} from '../../../../gaia/value';
import {ValueObject} from '../../../../gaia/value/interface/ValueObject';
import {IconAnnotationInfo, IconPaletteConfig} from '../../../common/infra/response/AnnotationInfo';
import {CacheKey, Optional} from '../../../common/types';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {AbstractIconAnnotationData} from './AbstractIconAnnotationData';

/**
 * 地図アイコン描画用データ
 */
class MapIconData extends AbstractIconAnnotationData implements CacheKey {
  readonly isForce: boolean;
  readonly size: Size;
  readonly uv: TexturePlaneUVCoordinate;
  readonly spotId: string;
  readonly name?: string;
  readonly address?: string;
  readonly postalCode?: string;
  readonly phone?: string;
  readonly providerId?: string;

  private readonly cacheKey: string;

  /**
   * コンストラクタ
   * @param lang 言語
   * @param tileSize タイルサイズ
   * @param priority 描画の優先度
   * @param ntjCode NTJコード
   * @param latlng 緯度経度
   * @param isForce 強制描画フラグ
   * @param size 表示サイズ
   * @param uv UV座標
   * @param spotId スポットID
   * @param name 名前
   * @param address 住所
   * @param postalCode 郵便番号
   * @param phone 電話番号
   * @param providerId 提供者ID
   */
  private constructor(
    lang: Language,
    tileSize: TileSize,
    priority: number,
    ntjCode: number,
    latlng: LatLng,
    isForce: boolean,
    size: Size,
    uv: TexturePlaneUVCoordinate,
    spotId: string,
    name?: string,
    address?: string,
    postalCode?: string,
    phone?: string,
    providerId?: string
  ) {
    super(lang, tileSize, priority, ntjCode, latlng, isForce, size, uv, 0);
    this.isForce = isForce;
    this.size = size;
    this.uv = uv;
    this.name = name;
    this.spotId = spotId;
    this.address = address;
    this.postalCode = postalCode;
    this.phone = phone;
    this.providerId = providerId;

    this.cacheKey = `MID(${latlng.lat},${latlng.lng},${ntjCode})`;
  }

  /**
   * MapIconDataを作成
   * @param lang 言語
   * @param tileSize タイルサイズ
   * @param info IconAnnotationInfo
   * @param palette IconPaletteConfig
   * @param imageSize テクスチャの切り取り後の画像サイズ
   * @param uv UV座標
   * @param spotId スポットID
   * @param name 名前
   * @param address 住所
   * @param postalCode 郵便番号
   * @param phone 電話番号
   * @param providerId 提供者ID
   * @returns MapIconData
   */
  static createMapIconData(
    lang: Language,
    tileSize: TileSize,
    info: IconAnnotationInfo,
    palette: IconPaletteConfig,
    imageSize: Size,
    uv: TexturePlaneUVCoordinate,
    spotId?: string,
    name?: string,
    address?: string,
    postalCode?: string,
    phone?: string,
    providerId?: string
  ): Optional<MapIconData> {
    if (!spotId) {
      return undefined;
    }
    return new MapIconData(
      lang,
      tileSize,
      info.priority,
      info.ntjCode,
      new LatLng(transMillisecToDegree(info.lat), transMillisecToDegree(info.lon)),
      palette.force,
      new Size(palette.size * imageSize.width * 0.01, palette.size * imageSize.height * 0.01),
      uv,
      spotId,
      name,
      address,
      postalCode,
      phone,
      providerId
    );
  }

  /** @override */
  getCacheKey(): string {
    return this.cacheKey;
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof MapIconData)) {
      return false;
    }
    const other = obj as MapIconData;
    return (
      other.lang === this.lang &&
      other.tileSize === this.tileSize &&
      other.priority === this.priority &&
      other.ntjCode === this.ntjCode &&
      other.latlng.equals(this.latlng) &&
      other.isForce === this.isForce &&
      other.size.equals(this.size) &&
      other.uv.equals(this.uv) &&
      other.name === this.name &&
      other.spotId === this.spotId &&
      other.address === this.address &&
      other.postalCode === this.postalCode &&
      other.phone === this.phone &&
      other.providerId === this.providerId
    );
  }

  /** @override */
  clone(): ValueObject {
    return new MapIconData(
      this.lang,
      this.tileSize,
      this.priority,
      this.ntjCode,
      this.latlng,
      this.isForce,
      this.size,
      this.uv,
      this.spotId,
      this.name,
      this.address,
      this.postalCode,
      this.phone,
      this.providerId
    );
  }

  /** @override */
  toObject(): JsonObject {
    return {
      lang: this.lang,
      tileSize: this.tileSize,
      priority: this.priority,
      ntjCode: this.ntjCode,
      latlng: this.latlng.toObject(),
      isForce: this.isForce,
      clientSize: this.size.toObject(),
      uv: {
        topLeft: this.uv.topLeft.toObject(),
        bottomLeft: this.uv.bottomLeft.toObject(),
        topRight: this.uv.topRight.toObject(),
        bottomRight: this.uv.bottomRight.toObject(),
      },
      name: this.name,
      spotId: this.spotId,
      address: this.address,
      postalCode: this.postalCode,
      phone: this.phone,
      providerId: this.providerId,
    };
  }
}

export {MapIconData};
