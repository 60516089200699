import {JsonObject} from '../../../../gaia/types';
import {Queue} from '../../../common/collection/Queue';
import {createWorkerWithMethods} from '../../../common/util/InlineWorker';
import {
  dbAllDeleteRequest,
  dbGetRequest,
  dbInsertRequest,
  dbRequest,
  DBRequest,
  DBResponse,
  deleteRecords,
  idbClear,
  idbGet,
  idbIndexOpenCursor,
  idbOpen,
  idbPut,
  idbStoreCount,
  MainDBData,
} from './AnnotationDBUtil';

type StoreState = 'initialized' | 'ready' | 'unavailable';

type OnInsertData<T> = (isSucceeded: boolean, key: T) => void;
type OnGetData<T, U> = (isSucceeded: boolean, key: T, data?: U) => void;
type OnAllDeleteData = (isSucceeded: boolean) => void;

const DB_HANDLER_WORKER_MAX = 3;

/**
 * 注記用IndexedDBオブジェクトストアハンドラ
 */
abstract class AbstractStoreHandler<T, U extends MainDBData> {
  private state: StoreState = 'initialized';
  private dbName: string;
  private storeName: string;
  private readonly recordMax: number;

  private paramMap: Map<string, T>;

  private readonly waitingQueue: Queue<DBRequest>;

  private readonly insertCallbackMap: Map<string, OnInsertData<T>>;
  private readonly getCallbackMap: Map<string, OnGetData<T, U>>;
  private readonly allDeleteCallbackMap: Map<string, OnAllDeleteData>;

  private readonly workerPool: Queue<Worker> = new Queue<Worker>();
  private readonly runnningWorkers: Worker[] = [];

  /**
   * コンストラクタ
   * @param dbName DB名
   * @param storeName オブジェクトストア名
   * @param recordMax レコード上限
   */
  constructor(dbName: string, storeName: string, recordMax = 3000) {
    this.dbName = dbName;
    this.storeName = storeName;
    this.recordMax = recordMax;
    this.paramMap = new Map();

    this.waitingQueue = new Queue();

    this.insertCallbackMap = new Map();
    this.getCallbackMap = new Map();
    this.allDeleteCallbackMap = new Map();

    this.workerPool = new Queue();
  }

  /**
   * オブジェクトストア初期化
   * @returns {void}
   */
  async setupStore(): Promise<void> {
    if (this.state === 'unavailable') {
      return;
    }

    const db = await idbOpen(this.dbName);
    if (!db) {
      return;
    }

    try {
      db.transaction(this.storeName, 'readwrite').objectStore(this.storeName);
    } catch (error) {
      this.setStateUnavailable();
      return;
    }

    this.state = 'ready';
    this.executeWaitingQueue();
  }

  /**
   * ステータスをIndexedDB使用不可状態にする
   * @returns {void}
   */
  setStateUnavailable(): void {
    this.state = 'unavailable';

    // 登録済みコールバックを全て処理
    while (this.waitingQueue.size > 0) {
      const queue = this.waitingQueue.dequeue();
      if (!queue) {
        break;
      }

      if (queue.actionType === 'insert' && queue.key) {
        const param = this.paramMap.get(queue.key);
        if (param) {
          this.insertCallbackMap.get(queue.id)?.(false, param);
        }
        break;
      }

      if (queue.actionType === 'get' && queue.key) {
        const param = this.paramMap.get(queue.key);
        if (param) {
          this.getCallbackMap.get(queue.id)?.(false, param);
        }
        break;
      }

      if (queue.actionType === 'all_delete') {
        this.allDeleteCallbackMap.get(queue.id)?.(false);
      }
    }
    this.insertCallbackMap.clear();
    this.getCallbackMap.clear();
    this.allDeleteCallbackMap.clear();
  }

  /**
   * リクエストキューをクリア
   * @returns {void}
   */
  clearRequestQueue(): void {
    this.waitingQueue.clear();
    this.insertCallbackMap.clear();
    this.getCallbackMap.clear();
    this.allDeleteCallbackMap.clear();
  }

  /**
   * データの保存
   * @param parameter keyとなるAnnotationParameter
   * @param data データ
   * @param callback 保存操作完了通知
   * @returns {void}
   */
  insertData(parameter: T, data: JsonObject, callback?: OnInsertData<T>): void {
    if (this.state === 'unavailable') {
      callback?.(false, parameter);
      return;
    }

    // const key = parameter.getCacheKey();
    const key = this.createCacheKey(parameter);
    const requestId = `${key},${Date.now()}`;
    if (callback) {
      this.insertCallbackMap.set(requestId, callback);
    }

    const workerRequest: DBRequest = {
      id: requestId,
      actionType: 'insert',
      dbName: this.dbName,
      storeName: this.storeName,
      recordMax: this.recordMax,
      key,
      data,
    };
    this.paramMap.set(key, parameter);
    this.requestToWorker(workerRequest);
  }

  /**
   * データの取得
   * @param parameter keyとなるAnnotationParameter
   * @param callback 取得操作完了通知
   * @returns {void}
   */
  findData(parameter: T, callback: OnGetData<T, U>): void {
    if (this.state === 'unavailable') {
      callback?.(false, parameter);
      return;
    }

    // const key = parameter.getCacheKey();
    const key = this.createCacheKey(parameter);
    const requestId = `${key},${Date.now()}`;
    if (this.getCallbackMap.has(requestId)) {
      return;
    }
    this.getCallbackMap.set(requestId, callback);

    const workerRequest: DBRequest = {
      id: requestId,
      actionType: 'get',
      dbName: this.dbName,
      storeName: this.storeName,
      key,
    };
    this.paramMap.set(key, parameter);
    this.requestToWorker(workerRequest);
  }

  /**
   * 全データ削除
   * @param callback 削除操作完了通知
   * @returns {void}
   */
  deleteAllData(callback?: OnAllDeleteData): void {
    if (this.state === 'unavailable') {
      callback?.(false);
      return;
    }

    const requestId = `${Date.now()}`;
    if (callback) {
      this.allDeleteCallbackMap.set(requestId, callback);
    }

    const workerRequest: DBRequest = {
      id: requestId,
      actionType: 'all_delete',
      dbName: this.dbName,
      storeName: this.storeName,
    };
    this.requestToWorker(workerRequest);
  }

  /**
   * workerへのIndexedDB操作リクエスト
   * @param request DBRequest
   * @returns {void}
   */
  private requestToWorker(request: DBRequest): void {
    let worker = this.workerPool.dequeue();
    if (!worker) {
      if (this.runnningWorkers.length >= DB_HANDLER_WORKER_MAX) {
        this.waitingQueue.enqueue(request);
        return;
      }

      worker = createWorkerWithMethods(dbRequest, [
        dbInsertRequest,
        dbGetRequest,
        dbAllDeleteRequest,
        idbOpen,
        idbPut,
        idbGet,
        idbClear,
        idbStoreCount,
        idbIndexOpenCursor,
        deleteRecords,
      ]);
    }
    this.runnningWorkers.push(worker);

    worker.onmessage = (ev: MessageEvent): void => {
      if (!worker) return;
      // workerをpoolにもどす
      const index = this.runnningWorkers.indexOf(worker);
      if (index > -1) {
        this.runnningWorkers.splice(index, 1);
      }
      this.workerPool.enqueue(worker);

      const {actionType, id, isSucceeded, key, data} = ev.data as DBResponse;
      switch (actionType) {
        case 'insert':
          this.executeInsertCallback(id, isSucceeded, key);
          return;
        case 'get':
          this.executeGetCallback(id, isSucceeded, key, data);
          return;
        case 'all_delete':
          this.executeAllDeleteCallback(id, isSucceeded);
          return;
        default:
          return;
      }
    };
    worker.postMessage(request);
  }

  /**
   * データ保存リクエストの処理完了通知
   * @param id callback管理用id
   * @param isSucceeded リクエスト成否
   * @param key 注記パラメータのcacheKey
   * @returns {void}
   */
  private executeInsertCallback(id: string, isSucceeded: boolean, key?: string): void {
    const callback = this.insertCallbackMap.get(id);
    if (callback && key) {
      const param = this.paramMap.get(key);
      if (param) {
        callback(isSucceeded, param);
      }

      this.insertCallbackMap.delete(id);
      this.paramMap.delete(key);
    }

    this.executeWaitingQueue();
  }

  /**
   * データ取得リクエストの処理完了通知
   * @param id callback管理用id
   * @param isSucceeded リクエスト成否
   * @param key 注記パラメータのcacheKey
   * @param data 取得結果
   * @returns {void}
   */
  private executeGetCallback(id: string, isSucceeded: boolean, key?: string, data?: U): void {
    const callback = this.getCallbackMap.get(id);
    if (callback && key) {
      const param = this.paramMap.get(key);
      if (param) {
        if (data) {
          // callback(isSucceeded, param, data.mainInfo);
          callback(isSucceeded, param, data);
        } else {
          callback(isSucceeded, param);
        }
      }

      this.getCallbackMap.delete(id);
      this.paramMap.delete(key);
    }

    this.executeWaitingQueue();
  }

  /**
   * 全データ削除リクエストの処理完了通知
   * @param id callback管理用id
   * @param isSucceeded リクエスト成否
   * @returns {void}
   */
  private executeAllDeleteCallback(id: string, isSucceeded: boolean): void {
    const callback = this.allDeleteCallbackMap.get(id);
    callback?.(isSucceeded);
    this.allDeleteCallbackMap.delete(id);

    this.executeWaitingQueue();
  }

  /**
   * リクエスト待ちキューの消化
   * @returns {void}
   */
  private executeWaitingQueue(): void {
    const queue = this.waitingQueue.dequeue();
    if (queue) {
      this.requestToWorker(queue);
    }
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    for (const worker of this.runnningWorkers) {
      worker.terminate();
    }
    while (this.workerPool.size > 0) {
      const worker = this.workerPool.dequeue();
      worker?.terminate();
    }
  }

  abstract createCacheKey(parameter: T): string;
}

export {StoreState, AbstractStoreHandler};
