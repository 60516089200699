/* eslint-disable @typescript-eslint/no-explicit-any */

import {Language} from 'gaia/types';
import {Color} from 'gaia/value';

/** 緯度経度(レスポンス用) */
type RoadShapeOpenedLocation = {
  /** 緯度 */
  lat: number;
  /** 軽度 */
  lon: number;
};

/** 新規開通道路メタ情報 */
export type RoadShapeOpenedMetaInfo = {
  /** シリアル */
  serial: RoadShapeOpenedSerial;
  /** 有効データを含むタイルの連想配列 */
  availability: AvailableTileMap;
};

/** シリアル */
type RoadShapeOpenedSerial = {
  /** 新規開通道路データのシリアル */
  data: number;
  /** パレットのシリアル */
  palette: number;
};

/** 有効データを含むタイルの連想配列 */
type AvailableTileMap = {[z: string]: {[y: string]: number[]}};

/** 新規開通道路情報 */
export type RoadShapeOpenedInfo = {
  /** 言語 */
  lang: Language;
  /** 道路情報リスト */
  features: RoadShapeOpenedFeature[];
  /** パレット */
  palette: PaletteInfo;
};

/** 形状の線分1本あたりの情報 */
export type RoadShapeOpenedFeature = {
  /** ジオメトリ */
  geometry: RoadShapeOpenedGeometry;
  /** プロパティ */
  properties: RoadShapeOpenedProperty;
};

/** ジオメトリ */
export type RoadShapeOpenedGeometry = {
  /** 種別 */
  type: string;
  /** 緯度経度 */
  locations: RoadShapeOpenedLocation[];
};

/** プロパティ */
export type RoadShapeOpenedProperty = {
  /** メッシュID */
  readonly mesh?: string;
  /** 道路ID */
  readonly roadId?: string;
  /** 道路名称 */
  readonly roadName?: string;
  /** 開通日時 */
  readonly startDate?: string;
  /** 閉鎖日時 */
  readonly endDate?: string;
  /** 整理番号 */
  readonly ordinalNo?: string;
  /** 属性ID */
  readonly attr?: string;
  /** 属性ID (10進数) */
  readonly ntjCode?: string;
};

/** パレット情報 */
export type PaletteInfo = {
  [ntjCode: string]: PaletteInfoElement;
};

export type PaletteInfoElement = {
  geometryFill?: GeometryPaletteConfig;
  geometryOutline?: GeometryPaletteConfig;
};

/** 文字注記パレット情報 */
export type GeometryPaletteConfig = {
  size: number;
  visible: boolean;
  force: boolean;
  color: Color;
  lineType: string;
};

/**
 * RoadShapeOpenedLocationのType Guard
 * @param item 判定対象
 * @returns RoadShapeOpenedLocationか否か
 */
export const isRoadShapeOpenedLocation = (item: any): item is RoadShapeOpenedLocation => {
  return item.lat !== undefined && item.lon !== undefined;
};

/**
 * RoadShapeOpenedGeometryのType Guard
 * @param item 判定対象
 * @returns RoadShapeOpenedGeometryか否か
 */
export const isRoadShapeOpenedGeometry = (item: any): item is RoadShapeOpenedGeometry => {
  return item.type !== undefined && item.locations !== undefined;
};

/**
 * RoadShapeOpenedFeatureのType Guard
 * @param item 判定対象
 * @returns RoadShapeOpenedFeatureか否か
 */
export const isRoadShapeOpenedFeature = (item: any): item is RoadShapeOpenedFeature => {
  return isRoadShapeOpenedGeometry(item.geometry);
};

/**
 * RoadShapeOpenedInfoのType Guard
 * @param item 判定対象
 * @returns RoadShapeOpenedInfoか否か
 */
export const isRoadShapeOpenedInfo = (item: any): item is RoadShapeOpenedInfo => {
  return item.lang !== undefined && item.features !== undefined && item.palette !== undefined;
};
