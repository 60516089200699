import {MarkerGravity} from '../types';
import {Size} from './Size';
import {CacheKey} from '../../_private/common/types';

import {GLMarkerInfoInitOptions} from '../types';

/**
 * マーカー画像の情報
 */
class GLMarkerIconInfo implements CacheKey {
  /** 画像パス */
  readonly icon: string;
  /** 基準位置 */
  readonly gravity: MarkerGravity;
  /** サイズ */
  readonly size: Size;
  /** キャッシュキー */
  private readonly cacheKey: string;

  /**
   * コンストラクタ
   * @param options オプション
   */
  constructor(options: GLMarkerInfoInitOptions) {
    this.icon = options.icon;
    this.gravity = options.gravity ?? 'bottom';
    this.size = options.size ?? new Size(50, 50);
    this.cacheKey = `GLMII(${this.icon}, ${this.gravity}, ${this.size.height}, ${this.size.width})`;
  }

  /** @override */
  getCacheKey(): string {
    return this.cacheKey;
  }
}

export {GLMarkerIconInfo};
