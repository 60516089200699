import {Vector2} from '../../common/math/Vector2';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {PolylinePolygonBuilder} from './PolylinePolygonBuilder';
import {DEGREE_TO_RADIAN} from '../../common/math/MathConstants';
import {
  calculateExternalAngle,
  calculateInternalEquinox,
  calculateIntersectionTwoLines,
} from '../../common/math/MathUtil';
import {Optional} from '../../common/types';

const NUM_VERTICES_OF_CIRCLE = 6;
const EPSILON_ANGLE = 0.001;

/**
 * ポリラインに太さをもたせて、ポリゴンに変換するクラス
 */
class PolylineTriangulator {
  private readonly width: number;
  private dashArray: number[];
  private polyline: Vector2[][];

  private isDash: boolean;
  private dashArrayIndex: number;
  private leftover: number;

  private builder: PolylinePolygonBuilder;

  /**
   * コンストラクタ
   * @param width 太さ
   * @param dashArray 塗りと休みの長さのパターン
   */
  constructor(width: number, dashArray: number[]) {
    this.width = width;
    this.dashArray = dashArray;
    this.polyline = [];

    this.isDash = true;
    this.dashArrayIndex = 0;
    this.leftover = this.dashArray[this.dashArrayIndex];

    this.builder = new PolylinePolygonBuilder();
  }

  /**
   * 一筆でかける線を追加する
   * @param stroke 一筆の線
   * @returns {void}
   */
  addStroke(stroke: Vector2[]): void {
    for (const dividedStroke of this.divideStroke(stroke)) {
      this.polyline.push(dividedStroke);
    }
  }

  /**
   * 必要であれば受け取ったストロークを分割して返す
   * 既に描画された範囲内に、ポリゴンの頂点が入る場合は分割する必要がある
   * @param stroke ストローク
   * @returns 分割した新しいストローク
   */
  private divideStroke(stroke: Vector2[]): Vector2[][] {
    if (stroke.length <= 2) {
      return [stroke];
    }

    const dividedStrokes: Vector2[][] = [];
    let previous = stroke[0];
    let current = stroke[1];
    let dividedStroke = [previous];
    for (let nextIndex = 2; nextIndex < stroke.length; nextIndex++) {
      const next = stroke[nextIndex];
      const currentExternalAngle = calculateExternalAngle(previous, current, next);

      if (!currentExternalAngle && currentExternalAngle !== 0) {
        continue;
      }

      const currentInternalRadian = DEGREE_TO_RADIAN * (180 - Math.abs(currentExternalAngle));
      const currentToNextMagnitude = next._subtract(current).magnitude();
      const currentToPreviousMagnitude = previous._subtract(current).magnitude();
      const baseHeight = this.width * Math.sin((Math.PI - currentInternalRadian) / 2);
      const nextHeight = currentToNextMagnitude * Math.sin(currentInternalRadian / 2);
      const previousHeight = currentToPreviousMagnitude * Math.sin(currentInternalRadian / 2);
      if (nextHeight <= baseHeight) {
        dividedStroke.push(current);
        dividedStrokes.push(dividedStroke);
        dividedStroke = [];
      } else if (previousHeight <= baseHeight) {
        dividedStroke.push(current);
        dividedStrokes.push(dividedStroke);
        dividedStroke = [];
      }

      dividedStroke.push(current);
      previous = current;
      current = next;
    }

    // 必要であれば最後の点も返却結果に含める
    dividedStroke.push(current);
    if (dividedStroke.length >= 2) {
      dividedStrokes.push(dividedStroke);
    } else if (dividedStroke.length === 1) {
      dividedStrokes[dividedStrokes.length - 1].push(dividedStroke[0]);
    }

    return dividedStrokes;
  }

  /**
   * ポリラインを参加型に分割し、ポリゴンに変換する
   * @returns ポリゴン
   */
  triangulate(): CustomGeometry {
    const halfWidth = this.width / 2;
    for (const stroke of this.polyline) {
      this.triangulateStroke(stroke, halfWidth);
    }

    const geometry = this.builder.build();
    return geometry;
  }

  /**
   * 一筆書き可能なストロークを受け取って、ポリゴンを組み立てる
   * @param stroke ストローク
   * @param halfWidth 太さの半分
   * @returns {void}
   */
  private triangulateStroke(stroke: Vector2[], halfWidth: number): void {
    this.isDash = true;
    this.dashArrayIndex = 0;
    this.leftover = this.dashArray[this.dashArrayIndex];

    if (stroke.length <= 1) {
      return;
    }

    if (stroke.length === 2) {
      this.headCapRound(stroke[0], stroke[1], halfWidth);
      this.growLineSegment(stroke[1], stroke[1], stroke[0], stroke[0], halfWidth, false);
      this.tailCapRound(stroke[1], stroke[0], halfWidth);
      this.builder.enclose();
      return;
    }

    if (this.isDash) {
      this.headCapRound(stroke[0], stroke[1], halfWidth);
    }

    this.growLineSegment(stroke[2], stroke[1], stroke[0], stroke[0], halfWidth, true);

    const strokeLength = stroke.length;
    let current = stroke[2];
    let previous = stroke[1];
    let twoPrevious = stroke[0];
    for (let pointIndex = 2; pointIndex <= strokeLength - 2; pointIndex++) {
      const next = stroke[pointIndex + 1];
      this.growLineSegment(next, current, previous, twoPrevious, halfWidth);
      twoPrevious = previous;
      previous = current;
      current = next;
    }

    this.growLineSegment(
      stroke[strokeLength - 1],
      stroke[strokeLength - 1],
      stroke[strokeLength - 2],
      stroke[strokeLength - 3],
      halfWidth,
      false
    );

    if (this.isDash) {
      this.tailCapRound(stroke[strokeLength - 1], stroke[strokeLength - 2], halfWidth);
    }

    this.builder.enclose();
  }

  /**
   * 点線の塗りの部分、または線分を計算し、ポリラインポリゴンビルダーに追加する
   * @param start 始点
   * @param end 終点
   * @param halfWidth 太さの半分
   * @returns {void}
   */
  private growDash(start: Vector2, end: Vector2, halfWidth: number): void {
    const norm: Vector2 = end._subtract(start).normalize().multiply(halfWidth);
    this.builder.growLeft(end.x - norm.y, end.y + norm.x);
    this.builder.growRight(end.x + norm.y, end.y - norm.x);
  }

  /**
   * 線の先頭のキャップを計算し、ビルダーに追加する
   * @param first 線の最初の点
   * @param second 線の二番目の点
   * @param halfWidth 太さの半分
   * @returns {void}
   */
  private headCapRound(first: Vector2, second: Vector2, halfWidth: number): void {
    const toCapHead = first._subtract(second).normalize().multiply(halfWidth);
    this.builder.growLeft(toCapHead.x + first.x, toCapHead.y + first.y);
    this.builder.growRight(toCapHead.x + first.x, toCapHead.y + first.y);

    const capHeadRadian = Math.PI / 2.0 - Math.atan2(toCapHead.x, toCapHead.y);
    for (let angle = 360 / NUM_VERTICES_OF_CIRCLE; angle <= 90; angle += 360 / NUM_VERTICES_OF_CIRCLE) {
      let angleRadian: number;

      angleRadian = capHeadRadian - angle * DEGREE_TO_RADIAN;
      this.builder.growLeft(first.x + halfWidth * Math.cos(angleRadian), first.y + halfWidth * Math.sin(angleRadian));

      angleRadian = capHeadRadian + angle * DEGREE_TO_RADIAN;
      this.builder.growRight(first.x + halfWidth * Math.cos(angleRadian), first.y + halfWidth * Math.sin(angleRadian));
    }
  }

  /**
   * 線の最後のキャップを計算し、ビルダーに追加する
   * @param last 線の最後の点
   * @param secondLast 線の最後から二番目の点
   * @param halfWidth 太さの半分
   * @returns {void}
   */
  private tailCapRound(last: Vector2, secondLast: Vector2, halfWidth: number): void {
    const toCapTail = last._subtract(secondLast).normalize().multiply(halfWidth);
    const capTailRadian = Math.atan2(toCapTail.y, toCapTail.x);
    for (let angle = 90; angle >= 0; angle -= 360 / NUM_VERTICES_OF_CIRCLE) {
      let angleRadian: number;

      angleRadian = capTailRadian + angle * DEGREE_TO_RADIAN;
      this.builder.growLeft(last.x + halfWidth * Math.cos(angleRadian), last.y + halfWidth * Math.sin(angleRadian));

      angleRadian = capTailRadian - angle * DEGREE_TO_RADIAN;
      this.builder.growRight(last.x + halfWidth * Math.cos(angleRadian), last.y + halfWidth * Math.sin(angleRadian));
    }
  }

  /**
   * 左に曲がる際の、曲がり角の外側を円形に計算しビルダーに追加
   * @param cornerAngle 左に曲がる角度
   * @param current 現在の点
   * @param scaledPreviousToCurrent 線の太さの半分の長さに正規化された、前の点から現在の点へのベクトル
   * @param halfWidth 太さの半分
   * @returns {void}
   */
  private growCornerOutlineLeft(
    cornerAngle: number,
    current: Vector2,
    scaledPreviousToCurrent: Vector2,
    halfWidth: number
  ): void {
    const startRadian = Math.atan2(-scaledPreviousToCurrent.x, scaledPreviousToCurrent.y);
    for (let angle = 0; angle < cornerAngle; angle += 360 / NUM_VERTICES_OF_CIRCLE) {
      const radian = startRadian + angle * DEGREE_TO_RADIAN;
      this.builder.growRight(current.x + halfWidth * Math.cos(radian), current.y + halfWidth * Math.sin(radian));
    }
  }

  /**
   * 右に曲がる際の、曲がり角の外側を円形に計算しビルダーに追加
   * @param cornerAngle 左に曲がる角度
   * @param current 現在の点
   * @param scaledPreviousToCurrent 線の太さの半分の長さに正規化された、前の点から現在の点へのベクトル
   * @param halfWidth 太さの半分
   * @returns {void}
   */
  private growCornerOutlineRight(
    cornerAngle: number,
    current: Vector2,
    scaledPreviousToCurrent: Vector2,
    halfWidth: number
  ): void {
    const startRadian = Math.atan2(scaledPreviousToCurrent.x, -scaledPreviousToCurrent.y);
    for (let angle = 0; angle > cornerAngle; angle -= 360 / NUM_VERTICES_OF_CIRCLE) {
      const radian = startRadian + angle * DEGREE_TO_RADIAN;
      this.builder.growLeft(current.x + halfWidth * Math.cos(radian), current.y + halfWidth * Math.sin(radian));
    }
  }

  /**
   * 現在の点の角の部分を計算し、ビルダーに追加する
   * @param next 次の点
   * @param current 現在の点
   * @param previous 前の点
   * @param scaledPreviousToCurrent 線の太さの半分で正規化された、前の点から現在の点へのベクトル
   * @param halfWidth 太さの半分
   * @returns {void}
   */
  private growCorner(
    next: Vector2,
    current: Vector2,
    previous: Vector2,
    scaledPreviousToCurrent: Vector2,
    halfWidth: number
  ): void {
    if (!this.isDash) {
      return;
    }

    const angle = calculateExternalAngle(previous, current, next);
    if (!angle) {
      return;
    }
    if (Math.abs(angle) < EPSILON_ANGLE) {
      this.growStraight(current, previous, scaledPreviousToCurrent);
    } else if (angle > 0) {
      // 時計回りに曲がる
      this.growCornerOutlineLeft(angle, current, scaledPreviousToCurrent, halfWidth);
    } else {
      // 反時計回りに曲がる
      this.growCornerOutlineRight(angle, current, scaledPreviousToCurrent, halfWidth);
    }
  }

  /**
   * 直進する際の、previousからcurrentの直線部分を計算し、ビルダーに追加
   * @param current 現在の点
   * @param previous 前の点
   * @param scaledPreviousToCurrent 先の太さの半分で正規化された、前の点から現在の点へのベクトル
   * @returns {void}
   */
  private growStraight(current: Vector2, previous: Vector2, scaledPreviousToCurrent: Vector2): void {
    this.builder.growLeft(current.x - scaledPreviousToCurrent.y, current.y + scaledPreviousToCurrent.x);
    this.builder.growRight(current.x + scaledPreviousToCurrent.y, current.y + scaledPreviousToCurrent.x);
  }

  /**
   * 前の点から現在の点の直線部分と、現在の点の曲がり角を計算し、ビルダーに追加
   * @param next 次の点
   * @param current 現在の点
   * @param previous 前の点
   * @param twoPrevious 2つ前の点
   * @param halfWidth 太さの半分
   * @param joint 曲がり角の外側の蛇腹部分を計算するかどうか
   * @returns {void}
   */
  private growLineSegment(
    next: Vector2,
    current: Vector2,
    previous: Vector2,
    twoPrevious: Vector2,
    halfWidth: number,
    joint = true
  ): void {
    const previousToCurrent = current._subtract(previous);
    const scaledPreviousToCurrent = previousToCurrent._normalize().multiply(halfWidth);
    const lineLength = previousToCurrent.magnitude();
    const externalAnglePrevious = calculateExternalAngle(twoPrevious, previous, current);
    const anglePrevious = !externalAnglePrevious ? 90 : (180 - Math.abs(externalAnglePrevious)) / 2;
    const externalAngleCurrent = calculateExternalAngle(previous, current, next) ?? 0;
    const angleCurrent = (180 - Math.abs(externalAngleCurrent)) / 2;

    if (anglePrevious > 90 || anglePrevious <= 0 || angleCurrent > 90 || angleCurrent <= 0) {
      return;
    }

    let progress = anglePrevious === 90 ? 0 : halfWidth / Math.tan(anglePrevious * DEGREE_TO_RADIAN) / lineLength;
    const maxProgress =
      angleCurrent === 90 ? 1 : 1 - halfWidth / Math.tan(angleCurrent * DEGREE_TO_RADIAN) / lineLength;

    let inside: Optional<Vector2> = null;
    if (progress > maxProgress) {
      const signedAnglePrevious = calculateExternalAngle(twoPrevious, previous, current);
      const point = previous._add(previousToCurrent._multiply(maxProgress));
      if (!signedAnglePrevious) {
        return;
      }
      if (externalAnglePrevious && externalAnglePrevious * externalAngleCurrent > 0) {
        const scaledTwoPreviousToPrevious = previous._subtract(twoPrevious).normalize().multiply(halfWidth);
        const scaledCurrentToNext = next._subtract(current).normalize().multiply(halfWidth);
        let toInsidePrevious: Vector2;
        let toInsideCurrent: Vector2;
        if (externalAnglePrevious < 0) {
          toInsidePrevious = new Vector2(scaledTwoPreviousToPrevious.y, -scaledTwoPreviousToPrevious.x);
          toInsideCurrent = new Vector2(scaledCurrentToNext.y, -scaledCurrentToNext.x);
        } else {
          toInsidePrevious = new Vector2(-scaledTwoPreviousToPrevious.y, scaledTwoPreviousToPrevious.x);
          toInsideCurrent = new Vector2(-scaledCurrentToNext.y, scaledCurrentToNext.x);
        }
        const insideTwoPrevious = twoPrevious._add(toInsidePrevious);
        const insidePrevious = previous._add(toInsidePrevious);
        const insideCurrent = current._add(toInsideCurrent);
        const insideNext = next._add(toInsideCurrent);
        inside = calculateIntersectionTwoLines(insideTwoPrevious, insidePrevious, insideCurrent, insideNext);
      } else if (signedAnglePrevious > 0) {
        this.builder.growRight(point.x + scaledPreviousToCurrent.y, point.y - scaledPreviousToCurrent.x);
      } else {
        this.builder.growLeft(point.x - scaledPreviousToCurrent.y, point.y + scaledPreviousToCurrent.x);
      }
    } else {
      // while文で前の点から現在の点の線分の部分を組み立てる
      while (progress < maxProgress) {
        if (lineLength * (maxProgress - progress) < this.leftover) {
          const tmpStart = calculateInternalEquinox(previous, current, progress);
          const tmpEnd = calculateInternalEquinox(previous, current, maxProgress);
          this.growDash(tmpStart, tmpEnd, halfWidth);
          break;
        }

        const s = calculateInternalEquinox(previous, current, progress);
        progress += this.leftover / lineLength;
        const e = calculateInternalEquinox(previous, current, progress);
        this.growDash(s, e, halfWidth);

        if (this.isDash && this.dashArray.length > 1) {
          this.builder.enclose();
        }

        this.isDash = !this.isDash;
        if (this.dashArray.length <= 1) {
          this.isDash = true;
        }

        this.dashArrayIndex = (this.dashArrayIndex + 1) % this.dashArray.length;
        this.leftover = this.dashArray[this.dashArrayIndex];
      }
    }

    // 必要であれば、現在の点の回りの曲がり角のポリゴンを組み立てる
    const dLeftover = lineLength * (maxProgress - progress);
    this.leftover -= dLeftover;
    if (joint) {
      this.growCorner(next, current, previous, scaledPreviousToCurrent, halfWidth);

      if (inside) {
        if (externalAnglePrevious && externalAnglePrevious > 0) {
          this.builder.growLeft(inside.x, inside.y);
        } else {
          this.builder.growRight(inside.x, inside.y);
        }
      }
    }
  }
}

export {PolylineTriangulator};
