import {Easing} from '../../../../../gaia/value/animation';

/**
 * 等速
 */
class LinearEasing implements Easing {
  /** @override */
  calculateAnimationProgress(progress: number): number {
    return progress;
  }
}

export {LinearEasing};
