import {ValueObject} from './interface/ValueObject';
import {Point} from './Point';
import {JsonObject} from '../types';

/**
 * 混雑度クラス
 */
class Congestion implements ValueObject {
  readonly position: Point;
  readonly level: number;

  /**
   * コンストラクタ
   * @param position x - 経度, y - 緯度 (グリッドサイズ乗算前)
   * @param level 混雑度
   */
  constructor(position: Point, level: number) {
    this.position = position;
    this.level = level;
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof Congestion)) {
      return false;
    }
    const other = obj as Congestion;
    return other.position === this.position && other.level === this.level;
  }

  /** @override */
  clone(): ValueObject {
    return new Congestion(this.position, this.level);
  }

  /** @override */
  toObject(): JsonObject {
    return {
      position: {
        x: this.position.x,
        y: this.position.y,
      },
      level: this.level,
    };
  }
}

export {Congestion};
