import {mat4} from 'gl-matrix';
import {Program} from './Program';
import {Geometry} from '../geometry/Geometry';
import {Optional} from '../../common/types';
import {Object3D} from '../object/Object3D';
import {TextureMapping} from './TextureMapping';
import {Color, LatLng} from '../../../gaia/value';
import {Vector2} from '../../common/math/Vector2';
import {TileNumber} from '../../map/models/TileNumber';
import {calculatePixelCoordinate} from '../../map/utils/MapUtil';

const MAX_ALPHA = 1.0;
const MAX_COLOR_SIZE = 20;
const DATA_TYPE_RAIN_SNOW = 3;

const vertexShaderSource = `
attribute vec3 vertexPosition;
attribute vec2 texCoord;

uniform mat4 model;
uniform mat4 view;
uniform mat4 projection;

varying vec2 textureCoord;

void main() {
  textureCoord = texCoord;
  gl_Position = projection * view * model * vec4(vertexPosition, 1.0);
}
`;

const fragmentShaderSource = `
precision highp float;

uniform sampler2D tex;
uniform float textureAlpha;
uniform bool isPremultipliedAlpha;

uniform int colorSize;
uniform float thresholdArray[${MAX_COLOR_SIZE}];
uniform vec4 colorArray[${MAX_COLOR_SIZE}];

uniform vec2 dataSplit;
uniform vec2 texSplit;

uniform vec2 dataRatio;
uniform vec2 dataOffset;

uniform vec2 pixelOffset;
uniform float ptu;
uniform float textureSize;
uniform bool isSnow;

varying vec2 textureCoord;

const int headerPixels = 36;

// hyper parameter for mitchell filter
const float B = 1.0 / 3.0;
const float C = 1.0 / 3.0;

float weightNearest(in float d) {
  if (d < 0.0) {
    return 0.0;
  } else if (d < 0.5) {
    return 1.0;
  } else {
    return 0.0;
  }
}

float weightMitchell(in float d) {
  if (d < 0.0) {
    return 0.0;
  } else if (d < 1.0) {
    return ((12.0 - 9.0 * B - 6.0 * C) * pow(d, 3.0) + (-18.0 + 12.0 * B + 6.0 * C) * pow(d, 2.0) + (6.0 - 2.0 * B)) / 6.0;
  } else if (d < 2.0) {
    return ((-B - 6.0 * C) * pow(d, 3.0) + (6.0 * B + 30.0 * C) * pow(d, 2.0) + (-12.0 * B - 48.0 * C) * d + (8.0 * B + 24.0 * C)) / 6.0;
  } else {
    return 0.0;
  }
}

float getGridRawValue(in int dataIndex, in vec2 texCell) {
  int pixelIndex = dataIndex + headerPixels;
  int yPixelIndex = int(float(pixelIndex) / texSplit.x);
  int xPixelIndex = int(mod(float(pixelIndex), texSplit.x));
  vec2 texCoord = vec2(float(xPixelIndex) + 0.5, float(yPixelIndex) + 0.5) * texCell;
  vec4 pixelColor = texture2D(tex, texCoord);
  float rawValue = (pixelColor.r + pixelColor.g + pixelColor.b) / 3.0 * 256.0;
  return rawValue;
}

float getSnowRawValue(in int dataIndex, in vec2 texCell) {
  int snowPixelIndex = headerPixels + int(dataSplit.x * dataSplit.y) + dataIndex / 4;
  int snowYPixelIndex = int(float(snowPixelIndex) / texSplit.x);
  int snowXPixelIndex = int(mod(float(snowPixelIndex), texSplit.y));
  vec2 snowTexCoord = vec2(float(snowXPixelIndex) + 0.5, float(snowYPixelIndex) + 0.5) * texCell;
  vec4 snowPixelColor = texture2D(tex, snowTexCoord);
  float snowRawValue4bit = (snowPixelColor.r + snowPixelColor.g + snowPixelColor.b) / 3.0 * 256.0;
  float power = 6.0 - (mod(float(dataIndex), 4.0) * 2.0);
  float snowRawValue = mod(snowRawValue4bit / pow(2.0, power), 4.0) / 2.0;
  return snowRawValue;
}

vec2 calculateValue(in vec2 coord) {
  vec2 dataCell = vec2(1.0, 1.0) / dataSplit;
  vec2 texCell = vec2(1.0, 1.0) / texSplit;
  vec2 dataNormCoord = dataOffset + (coord / dataRatio);
  
  vec2 dataSplitCoord = dataNormCoord * dataSplit;

  vec4 weightedValueList = vec4(0.0, 0.0, 0.0, 0.0);

  vec4 snowWeightedValueList = vec4(0.0, 0.0, 0.0, 0.0);

  for (int yIndex = 0; yIndex < 4; yIndex++) {

    // 横方向の重みを格納するリストを準備
    vec4 xWeightedValueList = vec4(0.0, 0.0, 0.0, 0.0);
    vec4 snowXWeightedValueList = vec4(0.0, 0.0, 0.0, 0.0);

    int yDataIndex = int(dataSplitCoord.y) + yIndex - 1;
    float dy = abs(dataSplitCoord.y - float(yDataIndex));
    for (int xIndex = 0; xIndex < 4; xIndex++) {
      int xDataIndex = int(dataSplitCoord.x) + xIndex - 1;
      int dataIndex = yDataIndex * int(dataSplit.x) + xDataIndex;
      float dx = abs(dataSplitCoord.x - float(xDataIndex));

      float rawValue = getGridRawValue(dataIndex, texCell);
      float snowRawValue = isSnow ? getSnowRawValue(dataIndex, texCell) : 0.0;

      // 横方向でどの補完アルゴリズムで重みを計算するか選択
      // float xWeight = weightNearest(dx);
      float xWeight = weightMitchell(dx);
      xWeightedValueList[xIndex] = xWeight * rawValue;
      snowXWeightedValueList[xIndex] = xWeight * snowRawValue;
    }

    // 縦方向でどの補完アルゴリズムで重みを計算するか選択
    // float yWeight = weightNearest(dy);
    float yWeight = weightMitchell(dy);
    weightedValueList[yIndex] = yWeight * dot(xWeightedValueList, vec4(1.0));
    snowWeightedValueList[yIndex] = yWeight * dot(snowXWeightedValueList, vec4(1.0));
  }
  float snowValue = dot(snowWeightedValueList, vec4(1.0));
  float value = dot(weightedValueList, vec4(1.0)) * 1.0;
  return vec2(value, snowValue);
}

void main() {
  vec2 texCoord = vec2(textureCoord.x, 1.0 - textureCoord.y);
  vec2 valuePair = calculateValue(textureCoord);
  float ratio = valuePair.x;
  float ext = valuePair.y;

  bool isX = mod(texCoord.x * textureSize / ptu + pixelOffset.x, 7.0) <= 1.0;
  bool isY = mod(texCoord.y * textureSize / ptu + pixelOffset.y, 7.0) <= 1.0;

  // 降雪で白に決まるピクセルはそれ以降の処理を省略する
  if (ext > 0.2 && (isX || isY)) {
    gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
    return;
  }

  // カラーマップを参照して色を決定する
  for (int i=1; i < ${MAX_COLOR_SIZE}; i++) {
    if (ratio <= thresholdArray[i] || i == colorSize || i == ${MAX_COLOR_SIZE} - 1) {
      float downThreshold = thresholdArray[i-1];
      float upThreshold = thresholdArray[i];
      float width = upThreshold - downThreshold;
      float downRatio = (upThreshold - ratio) / width;
      float upRatio = (ratio - downThreshold) / width;
      gl_FragColor = colorArray[i] * upRatio + colorArray[i-1] * downRatio;
      break;
    }
  }

  if (isPremultipliedAlpha) {
    gl_FragColor.r /= gl_FragColor.a;
    gl_FragColor.g /= gl_FragColor.a;
    gl_FragColor.b /= gl_FragColor.a;
  }

  gl_FragColor.a *= textureAlpha;
}
`;

let VERTEX_SHADER: Optional<WebGLShader>;
let FRAGMENT_SHADER: Optional<WebGLShader>;
let PROGRAM: Optional<WebGLProgram>;
const DEFAULT_TEX_PARAMETER_MAP = new Map([
  [WebGLRenderingContext.TEXTURE_WRAP_S, WebGLRenderingContext.CLAMP_TO_EDGE],
  [WebGLRenderingContext.TEXTURE_WRAP_T, WebGLRenderingContext.CLAMP_TO_EDGE],
  [WebGLRenderingContext.TEXTURE_MAG_FILTER, WebGLRenderingContext.LINEAR],
  [WebGLRenderingContext.TEXTURE_MIN_FILTER, WebGLRenderingContext.LINEAR],
]);

/**
 * テクスチャ用のプログラム
 */
class GradationProgram implements Program {
  context: WebGLRenderingContext;
  geometry: Geometry;

  vertexShader: Optional<WebGLShader>;
  fragmentShader: Optional<WebGLShader>;
  program: Optional<WebGLProgram>;

  image: Optional<TexImageSource>;
  imageAlpha: number;
  isPremultipliedAlphaImage: boolean;
  texture: WebGLTexture | null;

  private static initialTexture: Optional<TexImageSource> = null;

  private modelLocation: Optional<WebGLUniformLocation>;
  private viewLocation: Optional<WebGLUniformLocation>;
  private projectionLocation: Optional<WebGLUniformLocation>;
  private textureAlphaLocation: Optional<WebGLUniformLocation>;
  private isPremultipliedAlphaLocation: Optional<WebGLUniformLocation>;

  private colorSize: number;
  private colorWeightList: number[];
  private colorList: Color[];
  private colorSizeLocation: Optional<WebGLUniformLocation>;
  private thresholdLocationList: Array<Optional<WebGLUniformLocation>>;
  private colorLocationList: Array<Optional<WebGLUniformLocation>>;

  private dataSplit: Vector2;
  private dataSplitLocation: Optional<WebGLUniformLocation>;

  private texSplit: Vector2;
  private texSplitLocation: Optional<WebGLUniformLocation>;

  private dataOffset: Vector2;
  private dataOffsetLocation: Optional<WebGLUniformLocation>;

  private dataRatio: Vector2;
  private dataRatioLocation: Optional<WebGLUniformLocation>;

  private pixelOffset: Vector2;
  private pixelOffsetLocation: Optional<WebGLUniformLocation>;

  private ptu: number;
  private ptuLocation: Optional<WebGLUniformLocation>;

  private textureSize: number;
  private textureSizeLocation: Optional<WebGLUniformLocation>;

  private isSnow: boolean;
  private isSnowLocation: Optional<WebGLUniformLocation>;

  private modelMatrix: mat4;
  private translationMatrix: mat4;
  private rotationMatrix: mat4;
  private scaleMatrix: mat4;

  private vertexBuffer: Optional<WebGLBuffer>;
  private indexBuffer: Optional<WebGLBuffer>;

  private typedVertices: Float32Array;
  private typedIndices: Uint16Array;

  private tileNumber: Optional<TileNumber>;

  private readHeaderCanvas: HTMLCanvasElement;
  private readHeaderContext: Optional<CanvasRenderingContext2D>;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param isPremultipliedAlpha アルファモードの指定
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry, isPremultipliedAlpha: boolean) {
    this.context = context;

    this.image = null;
    this.imageAlpha = MAX_ALPHA;
    this.isPremultipliedAlphaImage = isPremultipliedAlpha;
    this.texture = context.createTexture();

    this.modelMatrix = mat4.create();
    this.translationMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.scaleMatrix = mat4.create();

    this.typedVertices = new Float32Array();
    this.typedIndices = new Uint16Array();

    this.colorSize = 0;
    this.colorWeightList = [];
    this.colorList = [];
    this.thresholdLocationList = [];
    this.colorLocationList = [];
    for (let colorIndex = 0; colorIndex < MAX_COLOR_SIZE; colorIndex++) {
      this.colorWeightList.push(0);
      this.colorList.push(new Color(0, 0, 0, 0));
      this.thresholdLocationList.push(0);
      this.colorLocationList.push(null);
    }

    this.dataSplit = Vector2.one();
    this.texSplit = Vector2.one();

    this.dataOffset = Vector2.zero();
    this.dataRatio = Vector2.one();

    this.pixelOffset = Vector2.zero();
    this.ptu = 1.0;
    this.textureSize = 1.0;
    this.isSnow = false;

    this.setupProgram();
    this.setGeometry(geometry);
    this.setupGeometry(geometry);
    this.initTexture();

    this.geometry = geometry;

    this.readHeaderCanvas = document.createElement('canvas');
    this.readHeaderContext = this.readHeaderCanvas.getContext('2d');
  }

  /**
   * プログラムを準備
   * @returns {void}
   */
  setupProgram(): void {
    if (!VERTEX_SHADER) {
      const mayBeVertexShader: WebGLShader | null = this.context.createShader(this.context.VERTEX_SHADER);
      if (mayBeVertexShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] Rainfall.setupProgram() could not create vertex shader');
        return;
      }
      VERTEX_SHADER = mayBeVertexShader;
      this.context.shaderSource(VERTEX_SHADER, vertexShaderSource);
      this.context.compileShader(VERTEX_SHADER);

      const vertexShaderCompileStatus = this.context.getShaderParameter(VERTEX_SHADER, this.context.COMPILE_STATUS);
      if (!vertexShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(VERTEX_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
        return;
      }
    }
    this.vertexShader = VERTEX_SHADER;

    if (!FRAGMENT_SHADER) {
      const mayBeFragmentShader: WebGLShader | null = this.context.createShader(this.context.FRAGMENT_SHADER);
      if (mayBeFragmentShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] Rainfall.setupProgram() could not create fragment shader');
        return;
      }
      FRAGMENT_SHADER = mayBeFragmentShader;
      this.context.shaderSource(FRAGMENT_SHADER, fragmentShaderSource);
      this.context.compileShader(FRAGMENT_SHADER);

      const fragmentShaderCompileStatus = this.context.getShaderParameter(FRAGMENT_SHADER, this.context.COMPILE_STATUS);
      if (!fragmentShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(FRAGMENT_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.fragmentShader = FRAGMENT_SHADER;

    if (!PROGRAM) {
      const mayBeProgram = this.context.createProgram();
      if (mayBeProgram === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] Rainfall.setupProgram() could not create program');
        return;
      }
      PROGRAM = mayBeProgram;
      this.context.attachShader(PROGRAM, this.vertexShader);
      this.context.attachShader(PROGRAM, this.fragmentShader);
      this.context.linkProgram(PROGRAM);

      const linkStatus = this.context.getProgramParameter(PROGRAM, this.context.LINK_STATUS);
      if (!linkStatus) {
        const info = this.context.getProgramInfoLog(PROGRAM);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.program = PROGRAM;

    this.context.useProgram(this.program);
  }

  /**
   * ジオメトリを準備
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setupGeometry(geometry: Geometry): void {
    if (!this.program) {
      return;
    }

    this.geometry = geometry;

    this.context.useProgram(this.program);

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertexAttribLocation = this.context.getAttribLocation(this.program, 'vertexPosition');
    const textureAttribLocation = this.context.getAttribLocation(this.program, 'texCoord');

    const VERTEX_SIZE = 3;
    const TEXTURE_SIZE = 2;
    const STRIDE = (VERTEX_SIZE + TEXTURE_SIZE) * Float32Array.BYTES_PER_ELEMENT;
    const VERTEX_OFFSET = 0;
    const TEXTURE_OFFSET = 3 * Float32Array.BYTES_PER_ELEMENT;

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);

    this.context.enableVertexAttribArray(vertexAttribLocation);
    this.context.enableVertexAttribArray(textureAttribLocation);

    this.context.vertexAttribPointer(
      vertexAttribLocation,
      VERTEX_SIZE,
      this.context.FLOAT,
      false,
      STRIDE,
      VERTEX_OFFSET
    );
    this.context.vertexAttribPointer(
      textureAttribLocation,
      TEXTURE_SIZE,
      this.context.FLOAT,
      false,
      STRIDE,
      TEXTURE_OFFSET
    );

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
  }

  /**
   * タイル番号を設定
   * @param tileNumber タイル番号
   * @returns {void}
   */
  setTileNumber(tileNumber: TileNumber): void {
    this.tileNumber = tileNumber;
  }

  /**
   * ピクセルのオフセットを設定
   * @param pixelOffset ピクセルのオフセット
   * @returns {void}
   */
  setPixelOffset(pixelOffset: Vector2): void {
    this.pixelOffset = pixelOffset;
  }

  /**
   * ピクセルをGL空間の単位に変換する変数を設定
   * @param pixelToUnit ピクセルをGL空間の単位に変換する変数っｆ
   * @returns {void}
   */
  setPixelToUnit(pixelToUnit: number): void {
    this.ptu = pixelToUnit;
  }

  /**
   * テクスチャの1辺の長さ（単位はGL空間の単位）を設定する
   * @param textureSize テクスチャの1辺の長さ
   * @returns {void}
   */
  setTextureSize(textureSize: number): void {
    this.textureSize = textureSize;
  }

  /**
   * テクスチャを初期化する
   * @returns {void}
   */
  initTexture(): void {
    if (!GradationProgram.initialTexture) {
      const tileSize = 2;
      const canvas = document.createElement('canvas');
      canvas.width = tileSize;
      canvas.height = tileSize;
      const context = canvas.getContext('2d');
      if (!context) {
        return;
      }
      context.fillStyle = '#00000000';
      context.fillRect(0, 0, tileSize, tileSize);

      GradationProgram.initialTexture = canvas;
    }
    this.setTexture(GradationProgram.initialTexture);
  }

  /**
   * テクスチャを更新する
   * @param image テクスチャ画像ソース
   * @param texParameterMap テクスチャパラメータ
   * @returns {void}
   */
  setTexture(image: TexImageSource, texParameterMap: Map<GLenum, GLenum> = DEFAULT_TEX_PARAMETER_MAP): void {
    if (!this.program) {
      return;
    }

    if (this.image === image) {
      return;
    }

    this.image = image;

    if (image && this.tileNumber) {
      this.readHeaderCanvas.height = image.height;
      this.readHeaderCanvas.width = image.width;
      if (!this.readHeaderContext) {
        this.readHeaderContext = this.readHeaderCanvas.getContext('2d');
      }
      if (this.readHeaderContext) {
        this.readHeaderContext.drawImage(image as HTMLImageElement, 0, 0);
        const imageBytes = this.readHeaderContext.getImageData(0, 0, 36, 1).data;
        const dataType = imageBytes[4 * 4];
        this.isSnow = dataType === DATA_TYPE_RAIN_SNOW;
        const xSplit = imageBytes[12 * 4] + imageBytes[13 * 4] * 256;
        const ySplit = imageBytes[14 * 4] + imageBytes[15 * 4] * 256;
        this.dataSplit = new Vector2(xSplit, ySplit);
        const width = imageBytes[16 * 4] + imageBytes[17 * 4] * 256;
        const height = imageBytes[18 * 4] + imageBytes[19 * 4] * 256;
        this.texSplit = new Vector2(width, height);
        let minLat = 0;
        let minLon = 0;
        let maxLat = 0;
        let maxLon = 0;
        for (let index = 23; index >= 20; index--) {
          minLat = minLat * 256 + imageBytes[index * 4];
        }
        minLat /= 3600000;
        for (let index = 27; index >= 24; index--) {
          minLon = minLon * 256 + imageBytes[index * 4];
        }
        minLon /= 3600000;
        for (let index = 31; index >= 28; index--) {
          maxLat = maxLat * 256 + imageBytes[index * 4];
        }
        maxLat /= 3600000;
        for (let index = 35; index >= 32; index--) {
          maxLon = maxLon * 256 + imageBytes[index * 4];
        }
        maxLon /= 3600000;
        const min = new LatLng(minLat, minLon);
        const max = new LatLng(maxLat, maxLon);
        const northEast = this.tileNumber.northEastLocation;
        const southWest = this.tileNumber.southWestLocation;
        const maxZoomLevel = 24;
        const minPixel = calculatePixelCoordinate(min, maxZoomLevel);
        const maxPixel = calculatePixelCoordinate(max, maxZoomLevel);
        const northEastPixel = calculatePixelCoordinate(northEast, maxZoomLevel);
        const southWestPixel = calculatePixelCoordinate(southWest, maxZoomLevel);
        const dataAreaSize = maxPixel._subtract(minPixel);
        const tileAreaSize = northEastPixel._subtract(southWestPixel);
        const offsetX = dataAreaSize.x === 0 ? 0.0 : (southWestPixel.x - minPixel.x) / dataAreaSize.x;
        const offsetY = dataAreaSize.y === 0 ? 0.0 : (southWestPixel.y - minPixel.y) / dataAreaSize.y;
        const offset = new Vector2(offsetX, offsetY);
        const ratioX = tileAreaSize.x === 0 ? 1.0 : dataAreaSize.x / tileAreaSize.x;
        const ratioY = tileAreaSize.y === 0 ? 1.0 : dataAreaSize.y / tileAreaSize.y;
        this.dataRatio = new Vector2(ratioX, ratioY);
        this.dataOffset = offset;
      }
    }

    this.context.useProgram(this.program);

    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
    this.context.texImage2D(
      this.context.TEXTURE_2D,
      0,
      this.context.RGBA,
      this.context.RGBA,
      this.context.UNSIGNED_BYTE,
      image
    );
    texParameterMap.forEach((value, key) => {
      this.context.texParameteri(this.context.TEXTURE_2D, key, value);
    });
    this.context.bindTexture(this.context.TEXTURE_2D, null);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.geometry = geometry;

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertices = this.geometry.getVertices();
    const verticesLength = vertices.length;
    if (this.typedVertices.length !== verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    const indices = this.geometry.getIndices();
    const indicesLength = indices.length;
    if (this.typedIndices.length !== indicesLength) {
      this.typedIndices = new Uint16Array(indicesLength);
    }
    this.typedIndices.set(indices);

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);
    this.context.bufferData(this.context.ARRAY_BUFFER, this.typedVertices, this.context.STATIC_DRAW);

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    this.context.bufferData(this.context.ELEMENT_ARRAY_BUFFER, this.typedIndices, this.context.STATIC_DRAW);
  }

  /**
   * テクスチャ画像とUV画像を更新する
   * @param textureMapping テクスチャ情報
   * @returns {void}
   */
  setTextureMapping(textureMapping: TextureMapping): void {
    this.setGeometry(textureMapping.geometry);
    this.setTexture(textureMapping.imageSource);
  }

  /**
   * テクスチャが少しでも透明かどうか
   * @returns 透明性（少しでも透明であればtrue）
   */
  isTransparent(): boolean {
    return this.imageAlpha < MAX_ALPHA;
  }

  /**
   * テクスチャの透明度を取得
   * @returns 透明度
   */
  getTextureTransparency(): number {
    return this.imageAlpha;
  }

  /**
   * テクスチャの透明度を更新する
   * @param alpha 透明度
   * @returns {void}
   */
  setTextureTransparency(alpha: number): void {
    this.imageAlpha = alpha;
  }

  /**
   * テクスチャ更新
   * @returns {void}
   */
  updateTexture(): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
  }

  /**
   * 重みと色の対応付けを設定
   * @param colorMap 重みと色の対応付け
   * @returns {void}
   */
  setColorMap(colorMap: Map<number, Color>): void {
    const sortedWeights = Array.from(colorMap.keys()).sort((a: number, b: number) => a - b);
    this.colorSize = sortedWeights.length;
    for (let index = 0; index < sortedWeights.length; index++) {
      const weight = sortedWeights[index];
      this.colorWeightList[index] = weight;
      const color = colorMap.get(weight);
      if (color) {
        this.colorList[index] = color;
      }
    }
  }

  /**
   * 行列情報を更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    if (!this.program) {
      return;
    }

    // model
    mat4.identity(this.scaleMatrix);
    mat4.identity(this.rotationMatrix);
    mat4.identity(this.translationMatrix);
    mat4.identity(this.modelMatrix);

    mat4.scale(this.scaleMatrix, this.scaleMatrix, object3D.scale.toFloat32Array());
    mat4.multiply(this.rotationMatrix, this.rotationMatrix, object3D.rotation.toMat4());
    mat4.translate(this.translationMatrix, this.translationMatrix, object3D.position.toFloat32Array());

    mat4.multiply(this.modelMatrix, this.modelMatrix, this.translationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.rotationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.scaleMatrix);

    this.context.useProgram(this.program);
    if (!this.modelLocation) {
      this.modelLocation = this.context.getUniformLocation(this.program, 'model');
    }
    if (!this.viewLocation) {
      this.viewLocation = this.context.getUniformLocation(this.program, 'view');
    }
    if (!this.projectionLocation) {
      this.projectionLocation = this.context.getUniformLocation(this.program, 'projection');
    }
    if (!this.textureAlphaLocation) {
      this.textureAlphaLocation = this.context.getUniformLocation(this.program, 'textureAlpha');
    }
    if (!this.isPremultipliedAlphaLocation) {
      this.isPremultipliedAlphaLocation = this.context.getUniformLocation(this.program, 'isPremultipliedAlpha');
    }
    this.context.uniformMatrix4fv(this.modelLocation, false, this.modelMatrix);
    this.context.uniformMatrix4fv(this.viewLocation, false, viewMatrix);
    this.context.uniformMatrix4fv(this.projectionLocation, false, projectionMatrix);
    this.context.uniform1f(this.textureAlphaLocation, this.imageAlpha);
    this.context.uniform1i(this.isPremultipliedAlphaLocation, this.isPremultipliedAlphaImage ? 1 : 0);

    if (!this.colorSizeLocation) {
      this.colorSizeLocation = this.context.getUniformLocation(this.program, 'colorSize');
    }
    this.context.uniform1i(this.colorSizeLocation, this.colorSize);

    for (let index = 0; index < MAX_COLOR_SIZE; index++) {
      if (!this.colorLocationList[index]) {
        this.colorLocationList[index] = this.context.getUniformLocation(this.program, `colorArray[${index}]`);
      }
      if (!this.thresholdLocationList[index]) {
        this.thresholdLocationList[index] = this.context.getUniformLocation(this.program, `thresholdArray[${index}]`);
      }

      const color = this.colorList[index];
      const threshold = this.colorWeightList[index];
      const colorLocation = this.colorLocationList[index];
      const thresholdLocation = this.thresholdLocationList[index];
      if (color && colorLocation && thresholdLocation) {
        this.context.uniform4f(colorLocation, color.r, color.g, color.b, color.a);
        this.context.uniform1f(thresholdLocation, threshold);
      }
    }

    if (!this.dataSplitLocation) {
      this.dataSplitLocation = this.context.getUniformLocation(this.program, 'dataSplit');
    }
    this.context.uniform2f(this.dataSplitLocation, this.dataSplit.x, this.dataSplit.y);

    if (!this.texSplitLocation) {
      this.texSplitLocation = this.context.getUniformLocation(this.program, 'texSplit');
    }
    this.context.uniform2f(this.texSplitLocation, this.texSplit.x, this.texSplit.y);

    if (!this.dataOffsetLocation) {
      this.dataOffsetLocation = this.context.getUniformLocation(this.program, 'dataOffset');
    }
    this.context.uniform2f(this.dataOffsetLocation, this.dataOffset.x, this.dataOffset.y);

    if (!this.dataRatioLocation) {
      this.dataRatioLocation = this.context.getUniformLocation(this.program, 'dataRatio');
    }
    this.context.uniform2f(this.dataRatioLocation, this.dataRatio.x, this.dataRatio.y);

    if (!this.pixelOffsetLocation) {
      this.pixelOffsetLocation = this.context.getUniformLocation(this.program, 'pixelOffset');
    }
    this.context.uniform2f(this.pixelOffsetLocation, this.pixelOffset.x, this.pixelOffset.y);

    if (!this.ptuLocation) {
      this.ptuLocation = this.context.getUniformLocation(this.program, 'ptu');
    }
    this.context.uniform1f(this.ptuLocation, this.ptu);

    if (!this.textureSizeLocation) {
      this.textureSizeLocation = this.context.getUniformLocation(this.program, 'textureSize');
    }
    this.context.uniform1f(this.textureSizeLocation, this.textureSize);

    if (!this.isSnowLocation) {
      this.isSnowLocation = this.context.getUniformLocation(this.program, 'isSnow');
    }
    this.context.uniform1i(this.isSnowLocation, this.isSnow ? 1 : 0);
  }

  /**
   * 描画
   * @returns {void}
   */
  draw(): void {
    this.setupGeometry(this.geometry);
    this.updateTexture();

    this.context.blendFuncSeparate(
      this.context.SRC_ALPHA,
      this.context.ONE_MINUS_SRC_ALPHA,
      this.context.ONE,
      this.context.ONE
    );
    this.context.enable(this.context.BLEND);

    this.context.drawElements(
      this.context.TRIANGLES,
      this.geometry.getIndices().length,
      this.context.UNSIGNED_SHORT,
      0
    );

    this.context.disable(this.context.BLEND);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const gl = this.context;
    if (PROGRAM && VERTEX_SHADER && FRAGMENT_SHADER) {
      gl.detachShader(PROGRAM, VERTEX_SHADER);
      gl.detachShader(PROGRAM, FRAGMENT_SHADER);
      gl.deleteProgram(PROGRAM);
      PROGRAM = undefined;
    }
    if (VERTEX_SHADER) {
      gl.deleteShader(VERTEX_SHADER);
      VERTEX_SHADER = undefined;
    }
    if (FRAGMENT_SHADER) {
      gl.deleteShader(FRAGMENT_SHADER);
      FRAGMENT_SHADER = undefined;
    }
    if (this.indexBuffer) {
      gl.deleteBuffer(this.indexBuffer);
      this.indexBuffer = undefined;
    }
    if (this.vertexBuffer) {
      gl.deleteBuffer(this.vertexBuffer);
      this.vertexBuffer = undefined;
    }
    this.viewLocation = undefined;
    this.modelLocation = undefined;
    this.projectionLocation = undefined;
    this.textureAlphaLocation = undefined;
  }
}

export {GradationProgram};
