import {AbstractTileRenderKit} from './AbstractTileRenderKit';
import {TileNumber} from '../../../models/TileNumber';
import {GaiaContext} from '../../../GaiaContext';
import {ArrayList} from '../../../../common/collection/ArrayList';
import {MapStatus} from '../../../models/MapStatus';
import {TextureMapping} from '../../../../engine/program/TextureMapping';
import {TexturePlaneGeometry} from '../../../../engine/geometry/TexturePlaneGeometry';
import {getDevicePixelRatio} from '../../../../common/util/Device';
import {LRUCache} from '../../../../common/collection/LRUCache';
import {TexturePlaneUVCoordinate} from '../../../../engine/geometry/TexturePlaneUVCoordinate';
import {RainfallTileParameter} from '../../../loader/param/RainfallTileParameter';
import {RainfallTileLoader} from '../../../loader/RainfallTileLoader';
import {TileRenderKitMapping} from '../MapRenderKitController';
import {RainfallCondition} from '../../../../../gaia/value/RainfallCondition';
import {Camera} from '../../../../engine/camera/Camera';
import {calculateWorldCoordinate} from '../../../utils/MapUtil';
import {aspectToFogDistanceRatio} from '../../../layer/FogObjectLayer';

const TILE_LAYER_NAME_RAINFALL = 'rainfall';

/**
 * 降雨・降雪情報タイル制御マネージャー
 */
class RainfallTileRenderKit extends AbstractTileRenderKit {
  /** タイル画像ローダー */
  private loader: RainfallTileLoader;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private readonly tileParameterPool: LRUCache<TileNumber, RainfallTileParameter>;

  private condition?: RainfallCondition;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param camera Camera
   */
  constructor(context: GaiaContext, camera: Camera) {
    super(context, camera, false, TILE_LAYER_NAME_RAINFALL);

    this.loader = new RainfallTileLoader(context, () => {
      if (this.status && this.tileList) {
        this.updateDrawObjects(this.status, this.tileList);
      }
    });

    this.tileParameterPool = new LRUCache<TileNumber, RainfallTileParameter>(100);

    context.getGaIAConfiguration().then((config) => {
      this.isEnable = config.features.rainfall;
      if (this.isEnable) {
        this.executeLoader();
      } else {
        this.clearCache();
      }
    });
  }

  /** @override */
  get identicalName(): keyof TileRenderKitMapping {
    return TILE_LAYER_NAME_RAINFALL;
  }

  /**
   * 降雨・降雪情報取得条件の設定
   * @param condition RainfallCondition
   * @returns {void}
   */
  setRainfallCondition(condition?: RainfallCondition): void {
    this.condition = condition;
    this.layer.updateTiles(new Map());
    this.loader.clear();
    this.tileParameterPool.clear();
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.isEnable) {
      return;
    }

    if (!this.condition) {
      return;
    }
    // offset範囲判定(タイルの取得が可能なのは-119分~419分)
    const MIN_OFFSET = -119;
    const MAX_OFFSET = 419;
    if (this.condition.offset < MIN_OFFSET || this.condition.offset > MAX_OFFSET) {
      return;
    }

    this.status = mapStatus;
    this.tileList = tileList;
    this.loader.jumpUpCacheSize(tileList.size() * 2);
    this.tileParameterPool.jumpUpSize(tileList.size() * 2);

    const requestTiles: RainfallTileParameter[] = [];

    // 生成済みテクスチャからLayerに描画物を渡す
    const tileTextureMap: Map<TileNumber, TextureMapping> = new Map();
    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    for (const tileNumber of tileList) {
      const latLng = tileNumber.centerLocation;
      const position = calculateWorldCoordinate(latLng);
      const distance = position._subtract(centerPosition).magnitude();
      const distanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
      if (distance > cameraDistance * distanceRatio) {
        continue;
      }

      const tileParam = this.getTileParameter(tileNumber, this.condition);
      const tex = this.loader.getTile(tileParam);
      if (tex) {
        const geometry = TexturePlaneGeometry.create(1, 1, TexturePlaneUVCoordinate.defaultUVCoordinate());
        tileTextureMap.set(tileNumber, new TextureMapping(geometry, tex.image));
      } else {
        requestTiles.push(tileParam);
      }
    }

    this.layer.updateTiles(tileTextureMap);

    // この時点ですべての必要なテクスチャがLayerに追加されていれば終了
    if (requestTiles.length === 0) {
      return;
    }
    // loaderにリクエストキュー追加
    this.loader.addRequestQueue(requestTiles);
  }

  /** @override */
  executeLoader(): void {
    this.loader.executeRequest();
  }

  /**
   * RainfallTileParameterを生成
   * @param tileNumber TileNumber
   * @param condition RainfallCondition
   * @returns RainfallTileParameter
   */
  private getTileParameter(tileNumber: TileNumber, condition: RainfallCondition): RainfallTileParameter {
    if (this.tileParameterPool.has(tileNumber)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.tileParameterPool.get(tileNumber)!;
    }
    const {x, y, z} = tileNumber;
    const tileSize = getDevicePixelRatio() > 1 ? 512 : 256;

    const param = new RainfallTileParameter(x, y, z, condition, tileSize);
    this.tileParameterPool.add(tileNumber, param);
    return param;
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  clearCache(): void {
    this.layer.updateTiles(new Map());
    this.tileParameterPool.clear();
    this.loader.clear();
  }

  /** @override */
  onDestroy(): void {
    this.loader.destroy();
  }
}

export {RainfallTileRenderKit};
