import {LatLng} from './LatLng';

/**
 * 自位置情報のクラス
 */
class UserLocationData {
  /** 緯度経度 */
  private latlng: LatLng;
  /** 方位 */
  private heading: number;

  /**
   * コンストラクタ
   * @param latlng 緯度経度
   * @param heading 方位(度数法で表現された角度) <br> ※ 0を真北・時計回りを正とする
   */
  constructor(latlng: LatLng, heading: number) {
    this.latlng = latlng;
    this.heading = heading;
  }

  /**
   * 緯度経度の設定
   * @param latlng 緯度経度
   * @returns {void}
   */
  setLatlng(latlng: LatLng): void {
    this.latlng = latlng;
  }

  /**
   * 緯度経度の取得
   * @returns 緯度経度
   */
  getLatlng(): LatLng {
    return this.latlng;
  }

  /**
   * 方位の設定
   * @param heading 方位(度数法で表現された角度) <br> ※ 0を真北・時計回りを正とする
   * @returns {void}
   */
  setHeading(heading: number): void {
    this.heading = heading;
  }

  /**
   * 方位の取得
   * @returns 方位(度数法)
   */
  getHeading(): number {
    return this.heading;
  }
}

export {UserLocationData};
