import {JsonObject} from '../../../../../gaia/types';
import {Optional} from '../../../../common/types';
import {createMap, GltfId} from '../../GltfReader';
import {GltfAccessor} from './GltfAccessor';

/**
 * JsonObjectからアクセサを作成する
 * @param accessorObject オブジェクト
 * @returns アクセサ、オブジェクトの情報が不十分な場合は `undefined`
 */
const createAccessorFromJsonObject = (accessorObject: JsonObject): Optional<GltfAccessor> => {
  const bufferView = accessorObject.bufferView;
  const byteOffset = accessorObject.byteOffset ?? 0;
  const componentType = accessorObject.componentType;
  const normalized = accessorObject.normalized;
  const count = accessorObject.count;
  const max = accessorObject.max;
  const min = accessorObject.min;
  const type = accessorObject.type;
  if (bufferView === undefined) {
    return undefined;
  }
  const accessor = new GltfAccessor(bufferView, byteOffset, componentType, normalized, count, max, min, type);
  return accessor;
};

/**
 * GLTFのaccessors
 */
class GltfAccessorsReader {
  private accessors: Map<GltfId, GltfAccessor>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.accessors = new Map();
  }

  /**
   * パース
   * @param json JsonObject
   * @returns 成功すれば `true` , そうでなければ `false`
   */
  parse(json: JsonObject): boolean {
    const accessors = createMap(json, createAccessorFromJsonObject);
    if (!accessors) {
      return false;
    }
    this.accessors = accessors;
    return true;
  }

  /**
   * バッファを取得
   * @param key キー
   * @returns キーに対応するバッファ
   */
  get(key: number | string): GltfAccessor | undefined {
    return this.accessors.get(key);
  }
}

export {GltfAccessorsReader};
