import {Vector3} from '../../../common/math/Vector3';
import {Quaternion} from '../../../common/math/Quaternion';
import {SingleColorMaterial} from '../../../engine/material/SingleColorMaterial';
import {FigureObject} from './FigureObject';
import {MapStatus} from '../../models/MapStatus';

/**
 * ポリゴンを表示するオブジェクト
 */
class PolygonObject extends FigureObject {
  /**
   * コンストラクタ
   * @param position 位置
   * @param rotation 回転
   * @param scale 拡縮
   * @param material マテリアル
   * @param basePosition ベース位置
   * @param visible 表示フラグ
   * @param zIndex 重なり順
   */
  constructor(
    position: Vector3,
    rotation: Quaternion,
    scale: Vector3,
    material: SingleColorMaterial,
    basePosition: Vector3,
    visible: boolean,
    zIndex: number
  ) {
    super(position, rotation, scale, material, basePosition, visible, zIndex);
  }

  /** @override */
  updateFigure(cameraTargetPosition: Vector3, _mapStatus: MapStatus): void {
    this.setPositionValues(
      this.basePosition.x - cameraTargetPosition.x,
      this.basePosition.y - cameraTargetPosition.y,
      this.basePosition.z - cameraTargetPosition.z
    );
  }
}

export {PolygonObject};
