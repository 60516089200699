import {LatLng, Size} from '../../../../gaia/value';
import {PaletteAnchor} from '../../../common/infra/response/AnnotationInfo';
import {PolarCoordinate3} from '../../../common/math/PolarCoordinate3';
import {Quaternion, QUATERNION_IDENTITY} from '../../../common/math/Quaternion';
import {Ray3} from '../../../common/math/Ray3';
import {Rect3} from '../../../common/math/Rect3';
import {Vector3, VECTOR_ONES} from '../../../common/math/Vector3';
import {Optional} from '../../../common/types';
import {Camera} from '../../../engine/camera/Camera';
import {CustomGeometry} from '../../../engine/geometry/CustomGeometry';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {TexturePlaneMaterial} from '../../../engine/material/TexturePlaneMaterial';
import {Object3D} from '../../../engine/object/Object3D';
import {AbstractAnnotationData} from '../../models/annotation/AbstractAnnotationData';
import {calculatePixelToUnit, worldToLatLng} from '../../utils/MapUtil';
import {AnnotationCullHelper} from '../helper/AnnotationCullHelper';
import {AnnotationObject} from './AnnotationObject';

/**
 * 注記をまとめて表示するオブジェクトの抽象クラス
 */
abstract class AbstractAnnotationGroupObject<T extends AbstractAnnotationData> extends Object3D {
  readonly material: TexturePlaneMaterial;
  readonly basePosition: Vector3;

  protected geometry: CustomGeometry;
  protected currentZoomLevel: number;
  protected _isSetTexture: boolean;

  protected cullHelper: AnnotationCullHelper;

  protected getAltitudeCallback: (latLng: LatLng) => number;

  /**
   * コンストラクタ
   * @param material TexturePlaneMaterial
   * @param geometry CustomGeometry
   * @param position 位置
   */
  constructor(material: TexturePlaneMaterial, geometry: CustomGeometry, position: Vector3) {
    super(position, QUATERNION_IDENTITY, VECTOR_ONES, material);
    this.material = material;
    this.geometry = geometry;
    this.basePosition = position;
    this.currentZoomLevel = 0;
    this._isSetTexture = false;
    this.cullHelper = new AnnotationCullHelper();
    this.getAltitudeCallback = (_latLng: LatLng): number => 0;
  }

  /**
   * 標高取得コールバックを設定
   * @param callback コールバック
   * @returns {void}
   */
  setAltitudeCallback(callback: (latLng: LatLng) => number): void {
    this.getAltitudeCallback = callback;
  }

  /**
   * テクスチャの設定
   * @param texture テクスチャ
   * @returns {void}
   */
  setTexture(texture: TexImageSource): void {
    this.material.setTexture(texture);
    this._isSetTexture = true;
  }

  /**
   * テクスチャをクリア
   * @returns {void}
   */
  clearTexture(): void {
    this.material.initTexture();
    this._isSetTexture = false;
  }

  /**
   * テクスチャを設定済みか
   */
  get isSetTexture(): boolean {
    return this._isSetTexture;
  }

  /**
   * 表示中のAnnotationObjectリスト取得
   */
  abstract getDisplayingObjectList(): Array<AnnotationObject<T>>;

  /**
   * 全AnnotationObjectのColliderを更新
   * @param zoom ズームレベル
   * @param polar 極座標
   * @returns {void}
   */
  abstract updateColliders(zoom: number, polar: PolarCoordinate3): void;

  /**
   * 描画領域の計算
   * @param object 対象AnnotationObject
   * @param rotation Quaternion
   * @param zoom ズームレベル
   * @returns 描画領域
   */
  protected calculateColliderArea(object: AnnotationObject<T>, rotation: Quaternion, zoom: number): Rect3 {
    const ptu = calculatePixelToUnit(zoom);
    const centerX =
      object.position.x + this.calculateCenterOffsetX(object.anchor, object.worldSize) + object.offset.x * ptu;
    const centerY =
      object.position.y + this.calculateCenterOffsetY(object.anchor, object.worldSize) + object.offset.y * ptu;
    const {width, height} = object.worldSize;

    const latLng = worldToLatLng(object.position, zoom);
    const altitude = this.getAltitudeCallback(latLng);
    const topLeft = new Vector3(centerX - width / 2, centerY + height / 2, altitude);
    const floatPosition = object.position.clone();
    floatPosition.setValues(object.position.x, object.position.y, altitude);
    const rotatedTopLeft = rotation.product(topLeft.subtract(floatPosition)).add(floatPosition);
    const rotatedRight = rotation.product(new Vector3(width, 0, 0));
    const rotatedDown = rotation.product(new Vector3(0, -height, 0));

    return new Rect3(rotatedTopLeft, rotatedRight, rotatedDown);
  }

  /**
   * 中心座標のオフセットを計算(X成分)
   * @param anchor PaletteAnchor
   * @param worldSize GL空間上でのサイズ
   * @returns X成分のオフセット
   */
  private calculateCenterOffsetX(anchor: PaletteAnchor, worldSize: Size): number {
    switch (anchor) {
      case 'left':
      case 'bottom-left':
      case 'top-left':
        return worldSize.width / 2;
      case 'right':
      case 'bottom-right':
      case 'top-right':
        return -worldSize.width / 2;
      default:
        return 0;
    }
  }

  /**
   * 中心座標のオフセットを計算(Y成分)
   * @param anchor PaletteAnchor
   * @param worldSize GL空間上でのサイズ
   * @returns Y成分のオフセット
   */
  private calculateCenterOffsetY(anchor: PaletteAnchor, worldSize: Size): number {
    switch (anchor) {
      case 'top':
      case 'top-left':
      case 'top-right':
        return -worldSize.height / 2;
      case 'bottom':
      case 'bottom-left':
      case 'bottom-right':
        return worldSize.height / 2;
      default:
        return 0;
    }
  }

  /**
   * グループ内での間引き
   * @param camera Camera
   * @param cameraTargetPosition カメラの注視点
   * @returns {void}
   */
  abstract cullInGroup(camera: Camera, cameraTargetPosition: Vector3): void;

  /**
   * 描画更新
   * @param cameraTargetPosition カメラの注視点
   * @returns {void}
   */
  abstract updateAnnotationGroup(cameraTargetPosition: Vector3): void;

  /**
   * 描画領域に対応する頂点座標を算出
   * @param drawArea 描画領域
   * @param uv UV座標
   * @param visible 表示状態
   * @returns 頂点座標配列
   */
  protected calculateVertices(drawArea: Rect3, uv: TexturePlaneUVCoordinate, visible: boolean): number[] {
    if (!visible) {
      return Array(20).fill(0);
    }

    const {topLeft, bottomLeft, topRight, bottomRight} = drawArea;
    const baseX = this.basePosition.x;
    const baseY = this.basePosition.y;
    const baseZ = this.basePosition.z;

    return [
      topLeft.x - baseX,
      topLeft.y - baseY,
      topLeft.z - baseZ,
      uv.topLeft.x,
      uv.topLeft.y,
      bottomLeft.x - baseX,
      bottomLeft.y - baseY,
      bottomLeft.z - baseZ,
      uv.bottomLeft.x,
      uv.bottomLeft.y,
      topRight.x - baseX,
      topRight.y - baseY,
      topRight.z - baseZ,
      uv.topRight.x,
      uv.topRight.y,
      bottomRight.x - baseX,
      bottomRight.y - baseY,
      bottomRight.z - baseZ,
      uv.bottomRight.x,
      uv.bottomRight.y,
    ];
  }

  /**
   * インデックス座標算出
   * @param planeCount オフセット
   * @returns インデックス座標配列
   */
  protected calculateIndices(planeCount: number): number[] {
    return [
      0 + planeCount * 4,
      1 + planeCount * 4,
      2 + planeCount * 4,
      1 + planeCount * 4,
      3 + planeCount * 4,
      2 + planeCount * 4,
    ];
  }

  /**
   * rayと衝突するAnnotationObjectを取得
   * @param ray Ray3
   * @returns AnnotationObject
   */
  abstract getCollidedAnnotation(ray: Ray3): Optional<AnnotationObject<T>>;
}

export {AbstractAnnotationGroupObject};
