import {mat4} from 'gl-matrix';
import {JsonObject} from '../../../gaia/types';
import {Color, LatLng} from '../../../gaia/value';
import {HeatMapCondition} from '../../../gaia/value/HeatMapCondition';
import {clamp} from '../../common/math/MathUtil';
import {Quaternion} from '../../common/math/Quaternion';
import {Ray3} from '../../common/math/Ray3';
import {Vector2} from '../../common/math/Vector2';
import {Vector3} from '../../common/math/Vector3';
import {Optional} from '../../common/types';
import {getDevicePixelRatio} from '../../common/util/Device';
import {Collision} from '../../engine/collision/Collision';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {TexturePlaneGeometry} from '../../engine/geometry/TexturePlaneGeometry';
import {TexturePlaneUVCoordinate} from '../../engine/geometry/TexturePlaneUVCoordinate';
import {Layer, LayerUpdateNotifierFunc} from '../../engine/layer/Layer';
import {HeatMapMaterial} from '../../engine/material/HeatMapMaterial';
import {HeatSpotMaterial} from '../../engine/material/HeatSpotMaterial';
import {Object3D} from '../../engine/object/Object3D';
import {FRAME_BUFFER_SIDE} from '../../engine/program/HeatSpotProgram';
import {GaiaContext} from '../GaiaContext';
import {HeatSpotData} from '../models/heatMap/HeatSpotData';
import {MapStatus} from '../models/MapStatus';
import {calculatePixelToUnit, calculateWorldCoordinate} from '../utils/MapUtil';

const LAYER_NAME_HEAT_MAP = 'heatMap';

/**
 * ヒートマップ描画レイヤー
 */
class HeatMapObjectLayer implements Layer {
  private readonly context: GaiaContext;

  private heatMapMaterialMap: Map<HeatMapCondition, HeatMapMaterial> = new Map();
  private heatMapObjectMap: Map<HeatMapCondition, Object3D> = new Map();

  private heatSpotGeometryMap: Map<HeatMapCondition, CustomGeometry> = new Map();
  private heatSpotMaterialMap: Map<HeatMapCondition, Optional<HeatSpotMaterial>> = new Map();
  private heatSpotObjectMap: Map<HeatMapCondition, Object3D> = new Map();
  private heatSpotDataListMap: Map<HeatMapCondition, HeatSpotData[]> = new Map();

  private notifyUpdate?: LayerUpdateNotifierFunc;

  private colorMaps: Map<HeatMapCondition, Map<number, Color>> = new Map();
  private dataJsonMap: Map<HeatMapCondition, JsonObject> = new Map();
  private maxWeightMap: Map<HeatMapCondition, number> = new Map();

  private conditions: HeatMapCondition[] = [];

  private lastDeletedHeatMapObject?: Object3D;
  private lastDeletedHeatSpotObject?: Object3D;

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.context = context;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_HEAT_MAP;
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    const center = calculateWorldCoordinate(mapStatus.centerLocation);
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const uv = TexturePlaneUVCoordinate.defaultUVCoordinate();
    for (const condition of this.conditions) {
      const heatSpotObject = this.heatSpotObjectMap.get(condition);
      if (!heatSpotObject) {
        continue;
      }
      heatSpotObject.setPosition(center);

      const heatSpotDataList = this.heatSpotDataListMap.get(condition);
      const maxWeight = this.maxWeightMap.get(condition);
      const heatSpotGeometry = this.heatSpotGeometryMap.get(condition);
      const heatSpotMaterial = this.heatSpotMaterialMap.get(condition);
      if (
        !heatSpotDataList ||
        maxWeight === undefined ||
        maxWeight === null ||
        !heatSpotGeometry ||
        !heatSpotMaterial
      ) {
        continue;
      }
      const vertices: number[] = [];
      const indices: number[] = [];
      const colors: number[] = [];
      heatSpotDataList.forEach((heatSpotData: HeatSpotData, index: number): void => {
        const latLng = heatSpotData.latLng;
        const position = calculateWorldCoordinate(latLng).subtract(center);
        const halfSide = (condition.radiusCallback(heatSpotData.weight) * ptu) / 2.0;
        /* eslint-disable prettier/prettier */
        vertices.push(
          position.x - center.x - halfSide, position.y - center.y + halfSide, 0.0,
          uv.topLeft.x, uv.topLeft.y,
          position.x - center.x - halfSide, position.y - center.y - halfSide, 0.0,
          uv.bottomLeft.x, uv.bottomLeft.y,
          position.x - center.x + halfSide, position.y - center.y + halfSide, 0.0,
          uv.topRight.x, uv.topRight.y,
          position.x - center.x + halfSide, position.y - center.y - halfSide, 0.0,
          uv.bottomRight.x, uv.bottomRight.y,
        );
        indices.push(
          index * 4 + 0, index * 4 + 1, index * 4 + 2,
          index * 4 + 1, index * 4 + 3, index * 4 + 2,
        );
        const value = clamp(heatSpotData.weight / maxWeight, 0.0, 1.0);
        colors.push(
          value, value, value, value,
          value, value, value, value,
          value, value, value, value,
          value, value, value, value,
        )
        /* eslint-enable */
      });
      heatSpotGeometry.setVertices(vertices);
      heatSpotGeometry.setIndices(indices);
      heatSpotGeometry.setColors(colors);
      heatSpotMaterial.setGeometry(heatSpotGeometry);

      // ヒートマップを画面いっぱいのビルボードにする
      const heatMapObject = this.heatMapObjectMap.get(condition);
      const heatMapMaterial = this.heatMapMaterialMap.get(condition);
      const colorMap = this.colorMaps.get(condition);
      if (!heatMapObject || !heatMapMaterial || !colorMap) {
        continue;
      }
      const width = mapStatus.clientWidth;
      const height = mapStatus.clientHeight;
      const coefficient = 1.0;
      heatMapObject.scale.setValues(width * ptu * coefficient, height * ptu * coefficient, 1);
      const rotation = Quaternion.identity()
        .rotateZ(Math.PI / 2 + mapStatus.polar.phi)
        .rotateX(mapStatus.polar.theta);
      heatMapObject.setRotation(rotation);

      const opacity = condition.opacityCallback(mapStatus.zoomLevel);
      heatMapMaterial.setTextureTransparency(opacity);
      heatMapMaterial.setColorMap(colorMap);
    }
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    for (const condition of this.conditions) {
      const heatSpotMaterial = this.heatSpotMaterialMap.get(condition);
      const heatSpotObject = this.heatSpotObjectMap.get(condition);
      const heatMapMaterial = this.heatMapMaterialMap.get(condition);
      const heatMapObject = this.heatMapObjectMap.get(condition);
      if (!heatSpotMaterial || !heatSpotObject || !heatMapMaterial || !heatMapObject) {
        continue;
      }
      heatSpotMaterial.viewport(FRAME_BUFFER_SIDE, FRAME_BUFFER_SIDE);
      heatSpotObject.update(viewMatrix, projectionMatrix);
      heatSpotObject.draw();

      const mapStatus = this.context.getMapStatus();
      heatSpotMaterial.viewport(
        mapStatus.clientWidth * getDevicePixelRatio(),
        mapStatus.clientHeight * getDevicePixelRatio()
      );
      const texture = heatSpotMaterial.getTargetTexture();
      if (!heatMapObject || !heatMapMaterial || !texture) {
        return true;
      }
      heatMapMaterial.setGLTexture(texture);
      heatMapObject.update(viewMatrix, projectionMatrix);
      heatMapObject.draw();

      heatSpotMaterial.clearFrameBuffer();
    }

    return true;
  }

  /** @override */
  destroy(): void {
    this.heatMapObjectMap.forEach((heatMapObject: Object3D) => {
      heatMapObject.destroy();
    });
    this.heatSpotObjectMap.forEach((heatSpotObject: Object3D) => {
      heatSpotObject.destroy();
    });
    this.lastDeletedHeatMapObject?.destroy();
    this.lastDeletedHeatSpotObject?.destroy();
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /**
   * コンディションを追加
   * @param condition HeatMapCondition
   * @returns {void}
   */
  addCondition(condition: HeatMapCondition): void {
    const halfWidth = 0.5;
    const halfHeight = 0.5;
    const uv = TexturePlaneUVCoordinate.defaultUVCoordinate();

    /* eslint-disable prettier/prettier */
    const heatSpotGeometry = new CustomGeometry(
      [
        -halfWidth, halfHeight, 0.0,
        uv.topLeft.x, uv.topLeft.y,
        -halfWidth, -halfHeight, 0.0,
        uv.bottomLeft.x, uv.bottomLeft.y,
        halfWidth, halfHeight, 0.0,
        uv.topRight.x, uv.topRight.y,
        halfWidth, -halfHeight, 0.0,
        uv.bottomRight.x, uv.bottomRight.y,
      ],
      [0, 1, 2, 1, 3, 2],
      undefined,
      [
        0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0,
      ]
    );
    /* eslint-enable */

    const heatSpotMaterial = new HeatSpotMaterial(this.context.getGLContext(), heatSpotGeometry);
    const heatSpotObject = new Object3D(Vector3.zero(), Quaternion.identity(), Vector3.one(), heatSpotMaterial);
    const heatSpotDataList = [];

    let maxWeight = 0.0;
    let first = true;
    for (const feature of condition.geoJson.features) {
      const lat = feature.geometry.coordinates[1];
      const lon = feature.geometry.coordinates[0];
      const latLng = new LatLng(lat, lon);
      const weight = condition.weightCallback(feature);
      const data = new HeatSpotData(latLng, weight);
      heatSpotDataList.push(data);

      if (first) {
        maxWeight = weight;
        first = false;
        continue;
      }
      if (maxWeight < weight) {
        maxWeight = weight;
      }
    }

    const topLeft = new Vector2(0, 0);
    const bottomLeft = new Vector2(0, 1);
    const topRight = new Vector2(1, 0);
    const bottomRight = new Vector2(1, 1);
    const heatMapUV = new TexturePlaneUVCoordinate(bottomLeft, topLeft, bottomRight, topRight);
    const heatMapGeometry = TexturePlaneGeometry.create(1, 1, heatMapUV);
    const heatMapMaterial = new HeatMapMaterial(this.context.getGLContext(), heatMapGeometry, true);
    const heatMapObject = new Object3D(Vector3.zero(), Quaternion.identity(), Vector3.one(), heatMapMaterial);

    const mapStatus = this.context.getMapStatus();
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const width = mapStatus.clientWidth;
    const height = mapStatus.clientHeight;
    const coefficient = 1.0;
    heatMapObject.scale.setValues(width * ptu * coefficient, height * ptu * coefficient, 1);
    const rotation = Quaternion.identity()
      .rotateZ(Math.PI / 2 + mapStatus.polar.phi)
      .rotateX(mapStatus.polar.theta);
    heatMapObject.setRotation(rotation);

    const colorMap = condition.colorMap;

    this.heatMapMaterialMap.set(condition, heatMapMaterial);
    this.heatMapObjectMap.set(condition, heatMapObject);
    this.heatSpotGeometryMap.set(condition, heatSpotGeometry);
    this.heatSpotMaterialMap.set(condition, heatSpotMaterial);
    this.heatSpotObjectMap.set(condition, heatSpotObject);
    this.heatSpotDataListMap.set(condition, heatSpotDataList);
    this.colorMaps.set(condition, colorMap);
    this.dataJsonMap.set(condition, condition.geoJson);
    this.maxWeightMap.set(condition, maxWeight);
    this.conditions.push(condition);
  }

  /**
   * コンディションを削除
   * @param condition HeatMapCondition
   * @returns {void}
   */
  removeCondition(condition: HeatMapCondition): void {
    const index = this.conditions.indexOf(condition);
    if (index === -1) {
      return;
    }

    const heatMapMaterial = this.heatMapMaterialMap.get(condition);
    const heatMapObject = this.heatMapObjectMap.get(condition);
    const heatSpotGeometry = this.heatSpotGeometryMap.get(condition);
    const heatSpotMaterial = this.heatSpotMaterialMap.get(condition);
    const heatSpotObject = this.heatSpotObjectMap.get(condition);
    const heatSpotDataList = this.heatSpotDataListMap.get(condition);
    const colorMap = this.colorMaps.get(condition);
    const dataJson = this.dataJsonMap.get(condition);
    const maxWeight = this.maxWeightMap.get(condition);
    if (
      !heatMapMaterial ||
      !heatMapObject ||
      !heatSpotGeometry ||
      !heatSpotMaterial ||
      !heatSpotObject ||
      !heatSpotDataList ||
      !colorMap ||
      !dataJson ||
      maxWeight === undefined ||
      maxWeight === null
    ) {
      return;
    }

    this.lastDeletedHeatMapObject = heatMapObject;
    this.lastDeletedHeatSpotObject = heatSpotObject;

    this.heatMapMaterialMap.delete(condition);
    this.heatMapObjectMap.delete(condition);
    this.heatSpotGeometryMap.delete(condition);
    this.heatSpotMaterialMap.delete(condition);
    this.heatSpotObjectMap.delete(condition);
    this.heatSpotDataListMap.delete(condition);
    this.colorMaps.delete(condition);
    this.dataJsonMap.delete(condition.geoJson);
    this.maxWeightMap.delete(condition);
    this.conditions.splice(index, 1);
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {HeatMapObjectLayer, LAYER_NAME_HEAT_MAP};
