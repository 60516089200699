import {GaIAEventEmitter} from '../../../gaia/value/interface/GaIAEventEmitter';
import {GaIAEvent, GaIAEventListenerOptions} from '../../../gaia/types';
import {GaIAEventListenerHelper} from './GaIAEventListenerHelper';
import {EventListenerFunctionReturnType} from '../../common/types';

/** GaIA内でトリガーされるイベントと、それに対応するイベントオブジェクトのMap */
type EventMap = {[key: string]: GaIAEvent};

/** イベントリスナー関数 */
type EventListenerFunction = (ev: UIEvent) => EventListenerFunctionReturnType;

/**
 * DOMの操作イベントをハンドリングする基底クラス
 */
abstract class AbstractUIEventObserver<M extends EventMap> implements GaIAEventEmitter {
  protected readonly baseElement: HTMLElement;
  private enable = true;

  protected readonly helper: GaIAEventListenerHelper<M>;

  /**
   * コンストラクタ
   * @param element 対象要素
   */
  constructor(element: HTMLElement) {
    this.baseElement = element;
    this.helper = new GaIAEventListenerHelper<M>();

    this.setupObserve();
  }

  /**
   * DOMイベントのハンドリング処理を実装
   */
  abstract setupObserve(): void;

  /**
   * 有効/無効を切り替え
   * @param enable 有効化フラグ
   * @returns {void}
   */
  setEnable(enable: boolean): void {
    if (this.enable === enable) {
      return;
    }
    this.enable = enable;
  }

  /** @override */
  addEventListener<K extends keyof M>(
    eventName: K,
    func: (ev: M[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.addListener(eventName, func, options);
  }

  /** @override */
  removeEventListener<K extends keyof M>(
    eventName: K,
    func: (ev: M[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.removeListener(eventName, func, options);
  }

  /**
   * イベントハンドラの実行
   * @param eventName イベント名
   * @param event イベントオブジェクト
   * @returns {void}
   */
  trigger<K extends keyof M>(eventName: K, event: M[K]): void {
    if (!this.enable) {
      return;
    }
    this.helper.trigger(eventName, event);
  }
}

export {AbstractUIEventObserver};
