import {Geometry} from './Geometry';
import {TexturePlaneUVCoordinate} from './TexturePlaneUVCoordinate';

/**
 * テクスチャのジオメトリ
 */
class TexturePlaneGeometry implements Geometry {
  private readonly vertices: number[];
  private readonly indices: number[];
  uv: TexturePlaneUVCoordinate;

  /**
   * コンストラクタ
   * @param width 幅
   * @param height 高さ
   * @param uv UV座標
   */
  private constructor(width: number, height: number, uv: TexturePlaneUVCoordinate) {
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    /* eslint-disable prettier/prettier */
    this.vertices = [
      -halfWidth, halfHeight, 0.0,
      uv.topLeft.x, uv.topLeft.y,
      -halfWidth, -halfHeight, 0.0,
      uv.bottomLeft.x, uv.bottomLeft.y,
      halfWidth, halfHeight, 0.0,
      uv.topRight.x, uv.topRight.y,
      halfWidth, -halfHeight, 0.0,
      uv.bottomRight.x, uv.bottomRight.y,
    ];
    this.indices = [
      0, 1, 2,
      1, 3, 2,
    ];
    /* eslint-enable */

    this.uv = uv;
  }

  /** @override */
  getVertices(): number[] {
    return this.vertices;
  }

  /** @override */
  getIndices(): number[] {
    return this.indices;
  }

  /** @override */
  getNormals(): undefined {
    return undefined;
  }

  /**
   * テクスチャ画像の全体を使うようなジオメトリを作成する
   * @param width 幅
   * @param height 高さ
   * @param uv UV座標
   * @returns ジオメトリ
   */
  static create(
    width: number,
    height: number,
    uv: TexturePlaneUVCoordinate = new TexturePlaneUVCoordinate()
  ): TexturePlaneGeometry {
    return new TexturePlaneGeometry(width, height, uv);
  }

  /**
   * 全てデフォルトのUV座標を使っているかを判定する
   * @returns 全てデフォルトのUV座標を使っているか
   */
  isDefaultUV(): boolean {
    return (
      this.uv.topLeft.x === 0 &&
      this.uv.topLeft.y === 0 &&
      this.uv.bottomLeft.x === 0 &&
      this.uv.bottomLeft.y === 1 &&
      this.uv.topRight.x === 1 &&
      this.uv.topRight.y === 0 &&
      this.uv.bottomRight.x === 1 &&
      this.uv.bottomRight.y === 1
    );
  }

  /**
   * 比較
   * @param other 比較対象
   * @returns 同じかどうか
   */
  equals(other: TexturePlaneGeometry): boolean {
    for (const index in this.vertices) {
      if (this.vertices[index] !== other.vertices[index]) {
        return false;
      }
    }
    return true;
  }
}

export {TexturePlaneGeometry};
