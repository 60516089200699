import {GltfId} from '../../GltfReader';

type GltfPrimitiveRenderMode =
  | 'POINTS'
  | 'LINES'
  | 'LINE_LOOP'
  | 'LINE_STRIP'
  | 'TRIANGLES'
  | 'TRIANGLE_STRIP'
  | 'TRIANGLE_FAN';

/**
 * 描画モードの読み込み
 * @param mode 描画モードのコード
 * @returns 描画モードの名前
 */
const readPrimitiveRenderMode = (mode: number): GltfPrimitiveRenderMode | undefined => {
  switch (mode) {
    case 0:
      return 'POINTS';
    case 1:
      return 'LINES';
    case 2:
      return 'LINE_LOOP';
    case 3:
      return 'LINE_STRIP';
    case 4:
      return 'TRIANGLES';
    case 5:
      return 'TRIANGLE_STRIP';
    case 6:
      return 'TRIANGLE_FAN';
    default:
      return undefined;
  }
};

/**
 * glTFのプリミティブ
 */
class GltfPrimitive {
  readonly attributes: {[key: string]: GltfId};
  readonly mode: GltfPrimitiveRenderMode;
  readonly indices?: GltfId;
  readonly material?: GltfId;

  /**
   * コンストラクタ
   * @param attributes 属性
   * @param mode 描画モード
   * @param indices インデックス
   * @param material マテリアル
   */
  constructor(
    attributes: {[key: string]: GltfId},
    mode?: GltfPrimitiveRenderMode,
    indices?: GltfId,
    material?: GltfId
  ) {
    this.attributes = attributes;
    this.mode = mode ?? 'TRIANGLES';
    this.indices = indices;
    this.material = material;
  }
}

export {GltfPrimitive, readPrimitiveRenderMode};
