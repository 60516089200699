import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';
import {GaiaContext} from '../../../GaiaContext';
import {MapStatus} from '../../../models/MapStatus';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {GLMarkerIconInfo} from '../../../../../gaia/value/GLMarkerIconInfo';
import {LayerUpdateNotifierFunc} from '../../../../engine/layer/Layer';
import {TexturePlaneMaterial} from '../../../../engine/material/TexturePlaneMaterial';
import {calculatePixelToUnit, calculateWorldCoordinate, worldToLatLng} from '../../../utils/MapUtil';
import {CustomGeometry} from '../../../../engine/geometry/CustomGeometry';
import {GLMarkerLayer, LAYER_NAME_GL_MARKER} from '../../../layer/GLMarkerLayer';
import {GLMarker} from '../../../../../gaia/object/GLMarker';
import {GLMarkerGroupObject} from '../../objects/GLMarkerGroupObject';
import {GLMarkerObject} from '../../objects/GLMarkerObject';
import {VECTOR_ONES} from '../../../../common/math/Vector3';
import {QUATERNION_IDENTITY} from '../../../../common/math/Quaternion';
import {SimpleCache} from '../../../../common/collection/SimpleCache';
import {MouseEventObserver} from '../../../event/MouseEventObserver';
import {Ray3} from '../../../../common/math/Ray3';
import {Camera} from '../../../../engine/camera/Camera';
import {ExtendedMouseEvent, GLMarkerEvent, GLMarkerEventMap, GLMarkerLabelOptions} from '../../../../../gaia/types';
import {Optional} from '../../../../common/types';
import {GLMarkerTextureHelper} from '../../helper/GLMarkerTextureHelper';
import {PerspectiveCamera} from '../../../../engine/camera/PerspectiveCamera';
import {TouchEventObserver} from '../../../event/TouchEventObserver';

const LAYER_NAME_MARKER = 'glMarker';

/**
 * GLマーカーを扱う描画キット
 */
class GLMarkerObjectRenderKit extends AbstractObjectRenderKit {
  private markerLayer: GLMarkerLayer;
  private camera: Camera;

  /** 生成済みのGLMarkerGroupObject */
  private markerGroupObjects: SimpleCache<GLMarkerIconInfo, GLMarkerGroupObject>;
  /** 生成済みのGLMarkerObject */
  private markerObjectMap: Map<GLMarkerObject, GLMarker>;

  private draggableMarkerObjects: GLMarkerObject[];

  private notifyUpdate?: LayerUpdateNotifierFunc;
  private status?: MapStatus;

  private readonly mouse: MouseEventObserver;
  private readonly touch: TouchEventObserver;
  private hoveredMarkerObject?: GLMarkerObject;
  private hoveredMarker?: GLMarker;
  private draggingMarkerObject?: GLMarkerObject;

  private readonly textureHelper: GLMarkerTextureHelper;

  static touched = false;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKitCtl MapRenderKitController
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    const markerLayer = new GLMarkerLayer(context);
    super(context, markerLayer, renderKitCtl);

    this.markerLayer = markerLayer;
    this.camera = camera;
    this.markerGroupObjects = new SimpleCache();
    this.markerObjectMap = new Map();
    this.draggableMarkerObjects = [];

    this.mouse = new MouseEventObserver(context.getBaseElement());
    this.touch = new TouchEventObserver(context.getBaseElement());
    this.setupHover();
    this.setupClick();
    this.setupDrag();

    this.textureHelper = new GLMarkerTextureHelper();
  }

  /**
   * ホバー時の処理
   * @param ev UIEvent
   * @param clientPositionX クライアントのX座標
   * @param clientPositionY クライアントのY座標
   * @returns {void}
   */
  private onHover(ev: UIEvent, clientPositionX: number, clientPositionY: number): void {
    if (this.markerGroupObjects.size() === 0 || !this.isOnMap(ev)) {
      return;
    }

    if (this.draggingMarkerObject) {
      this.context.getBaseElement().style.cursor = 'pointer';
      return;
    }

    const ray: Ray3 = this.clientPositionToRay(clientPositionX, clientPositionY);
    const allCollisions = this.getAllCollisions(ray);

    for (const [layerName, collisions] of allCollisions.entries()) {
      if (layerName === LAYER_NAME_GL_MARKER) {
        // GL Markerのレイヤーで当たり判定があった場合はカーソルを変更する
        let glMarkerObject: GLMarkerObject | undefined;
        if (collisions.length > 0) {
          const collision = collisions[0];
          const additional = collision.getAdditional();
          if (additional) {
            glMarkerObject = additional as GLMarkerObject;
          }
        }

        if (glMarkerObject) {
          this.context.getBaseElement().style.cursor = 'pointer';
          const marker = this.markerObjectMap.get(glMarkerObject);

          if (marker && glMarkerObject !== this.hoveredMarkerObject) {
            if (this.hoveredMarkerObject) {
              const hoveredMarker = this.markerObjectMap.get(this.hoveredMarkerObject);
              if (hoveredMarker) {
                // 1つ前のフレームで別のマーカー上をホバーしていた場合は
                // 前のマーカーに対してmouseoutを発行
                this.hoveredMarkerObject.helper.trigger('mouseout', {
                  sourceObject: hoveredMarker,
                  native: ev,
                });
              }
            }
            // ホバーしたマーカーに対してmouseoverを発行
            glMarkerObject.helper.trigger('mouseover', {
              sourceObject: marker,
              native: ev,
            });
          }

          this.hoveredMarkerObject = glMarkerObject;
          const markerName = marker?.getName();
          if (markerName) {
            this.context.getBaseElement().title = markerName;
          }
          this.hoveredMarker = marker;
        } else {
          if (this.hoveredMarkerObject) {
            const marker = this.markerObjectMap.get(this.hoveredMarkerObject);
            if (marker) {
              // 1つ前のフレームで別のマーカー上をホバーしていた場合はmouseoutを発行
              this.hoveredMarkerObject.helper.trigger('mouseout', {
                sourceObject: marker,
                native: ev,
              });
            }
          }
          this.hoveredMarkerObject = undefined;
          this.hoveredMarker = undefined;
          this.context.getBaseElement().style.cursor = 'auto';
          this.context.getBaseElement().title = '';
        }
        break;
      }

      if (collisions.length > 0) {
        // GL Markerのレイヤーより手前で当たり判定があった場合は何もしない
        return;
      }
    }
  }

  /**
   * ホバー時の設定
   * @returns {void}
   */
  private setupHover(): void {
    this.mouse.addEventListener('mousemove', (ev: MouseEvent) => {
      this.onHover(ev, ev.offsetX, ev.offsetY);
    });

    this.touch.addEventListener('touchstart', (ev: TouchEvent) => {
      if (!ev.touches || ev.touches.length !== 1) {
        return;
      }

      const rect = this.context.getBaseElement().getBoundingClientRect();

      const touch = ev.touches[0];
      const clientPositionX = touch.clientX - rect.left;
      const clientPositionY = touch.clientY - rect.top;
      this.onHover(ev, clientPositionX, clientPositionY);
    });
  }

  /**
   * 地図に対するマウスイベントかを判定
   * @param ev MouseEvent
   * @returns 地図に対するマウスイベントか
   */
  private isOnMap(ev: UIEvent): boolean {
    for (const value of ev.composedPath()) {
      const classList = (value as Element).classList;
      if (classList && classList.contains('gia-base-element')) {
        return true;
      }
    }
    return false;
  }

  /**
   * クリック時の設定
   * @returns {void}
   */
  private setupClick(): void {
    this.mouse.addEventListener('click', (ev: MouseEvent) => {
      if (!this.hoveredMarkerObject || !this.hoveredMarker) {
        return;
      }

      this.hoveredMarkerObject.helper.trigger('click', {
        sourceObject: this.hoveredMarker,
        native: ev,
      });
    });
  }

  /**
   * ドラッグ開始時の処理
   * @param ev UIEvent
   * @returns {void}
   */
  private onDragStart(ev: UIEvent): void {
    if (!this.hoveredMarkerObject) {
      return;
    }

    const marker = this.markerObjectMap.get(this.hoveredMarkerObject);
    if (marker?.getDraggable()) {
      this.hoveredMarkerObject.helper.trigger('dragging', {
        sourceObject: marker,
        native: ev,
      });
      this.draggingMarkerObject = this.hoveredMarkerObject;
    }
  }

  /**
   * ドラッグ中の処理
   * @param ev UIEvent
   * @param clientPositionX ポインタのX座標
   * @param clientPositionY ポインタのY座標
   * @returns {void}
   */
  private onDragging(ev: UIEvent, clientPositionX: number, clientPositionY: number): void {
    if (!this.draggingMarkerObject) {
      return;
    }

    const marker = this.markerObjectMap.get(this.draggingMarkerObject);
    if (!marker) {
      return;
    }

    // イベント発行
    this.draggingMarkerObject.helper.trigger('dragging', {
      sourceObject: marker,
      native: ev,
    });

    // 描画位置更新
    // TODO: イベントのoffsetを使わない、地図中心オフセットに対応する
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const mapStatus = this.context.getMapStatus();

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const halfWidth = (mapStatus.clientWidth * ptu) / 2;
    const halfHeight = (mapStatus.clientHeight * ptu) / 2;
    const upVector = mapStatus.polar.toUpVector3();
    const rightVector = mapStatus.polar.toRightVector3();
    const toTopVector = upVector._normalize()._multiply(halfHeight * y);
    const toRightVector = rightVector._normalize()._multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);
    const worldCenter = calculateWorldCoordinate(mapStatus.centerLocation);
    const start = worldCenter._add(this.camera.position);

    const cameraTarget = (this.camera as PerspectiveCamera).target;
    const direction = cameraTarget._subtract(this.camera.position)._add(toTopRightVector);

    const ray = new Ray3(start, direction);

    const mouseWorldPosition = ray.intersectsWithPlaneZEqualsParameter(0);
    if (!mouseWorldPosition) {
      return;
    }

    this.draggingMarkerObject.setPosition(mouseWorldPosition);
    const group = this.markerGroupObjects.get(marker.info);
    group?.updateVertices(this.draggingMarkerObject);
    this.draggingMarkerObject.helper.trigger('topleft_changed', {
      sourceObject: marker,
    });
    this.notifyUpdate?.();
  }

  /**
   * ドラッグ終了時の処理
   * @param ev UIEvent
   * @returns {void}
   */
  private onDragEnd(ev: UIEvent): void {
    if (!this.draggingMarkerObject) {
      return;
    }

    const marker = this.markerObjectMap.get(this.draggingMarkerObject);
    if (marker) {
      marker.setPosition(worldToLatLng(this.draggingMarkerObject.position, this.context.getMapStatus().zoomLevel));
      this.draggingMarkerObject.helper.trigger('dragend', {
        sourceObject: marker,
        native: ev,
      });
    }
    this.draggingMarkerObject = undefined;
  }

  /**
   * ドラッグ時の設定
   * @returns {void}
   */
  private setupDrag(): void {
    this.mouse.addEventListener('dragstart', (ev: MouseEvent) => {
      this.onDragStart(ev);
    });

    this.mouse.addEventListener('drag', (ev: ExtendedMouseEvent) => {
      this.onDragging(ev, ev.offsetX, ev.offsetY);
    });

    this.mouse.addEventListener('dragend', (ev: MouseEvent) => {
      this.onDragEnd(ev);
    });

    this.touch.addEventListener('touchstart', (ev: TouchEvent): void => {
      this.onDragStart(ev);
    });

    this.touch.addEventListener('touchmove', (ev: TouchEvent): void => {
      if (!ev.touches || ev.touches.length !== 1) {
        return;
      }
      const touch = ev.touches[0];
      const clientPositionX = touch.clientX;
      const clientPositionY = touch.clientY;
      this.onDragging(ev, clientPositionX, clientPositionY);
    });

    this.touch.addEventListener('touchend', (ev: TouchEvent): void => {
      if (this.draggingMarkerObject) {
        GLMarkerObjectRenderKit.touched = true;
      }
      this.onDragEnd(ev);
    });
  }

  /**
   * 画面上での座標を3次元空間のレイキャストに変換する
   * @param clientPositionX x座標
   * @param clientPositionY y座標
   * @returns レイキャスト
   */
  private clientPositionToRay(clientPositionX: number, clientPositionY: number): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const mapStatus = this.context.getMapStatus();

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const halfWidth = (mapStatus.clientWidth * ptu) / 2;
    const halfHeight = (mapStatus.clientHeight * ptu) / 2;
    const upVector = mapStatus.polar.toUpVector3();
    const rightVector = mapStatus.polar.toRightVector3();
    const toTopVector = upVector._normalize()._multiply(halfHeight * y);
    const toRightVector = rightVector._normalize()._multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);
    const worldCenter = calculateWorldCoordinate(mapStatus.centerLocation);
    const start = worldCenter._add(this.camera.position);

    const cameraTarget = (this.camera as PerspectiveCamera).target;
    const direction = cameraTarget._subtract(this.camera.position)._add(toTopRightVector);

    const ray = new Ray3(start, direction);
    return ray;
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return LAYER_NAME_MARKER;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.status = mapStatus;
    this.markerLayer.update(mapStatus);
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * マーカーを追加
   * @param markers GLMarkerの配列
   * @returns {void}
   */
  addMarkers(markers: GLMarker[]): void {
    const markersMap = this.groupMarkersByIcon(markers);

    for (const [info, markerList] of markersMap.entries()) {
      // groupの作成
      let markerGroupObject = this.markerGroupObjects.get(info);
      if (!markerGroupObject) {
        markerGroupObject = this.createMarkerGroupObject(info, (): void => {
          if (!markerGroupObject) {
            return;
          }
          this.updateGroupTexture(markerGroupObject);
        });
        this.markerGroupObjects.add(info, markerGroupObject);
        this.markerLayer.addMarkerGroupObject(markerGroupObject);
      }

      // GLMarkerObjectの作成
      let requireTextureUpdate = false;
      for (const marker of markerList) {
        const labelOptions = marker.getLabel() ?? {content: ''};
        const labelOptionsKey = this.createLabelOpotionsKey(labelOptions);
        requireTextureUpdate = markerGroupObject.addLabelOptions(labelOptionsKey, labelOptions);

        const markerObject = this.createMarkerObject(marker, labelOptionsKey, markerGroupObject);
        markerGroupObject.addMarkerObject(markerObject);
        this.setupMarkerStatusUpdateListener(marker, markerGroupObject, markerObject);
      }

      // image loadまで完了していたら即テクスチャ更新
      // 追加された全Markerのラベルが既存のテクスチャで賄える場合は、テクスチャ再生成は行わない
      if (markerGroupObject.markerImage && requireTextureUpdate) {
        this.updateGroupTexture(markerGroupObject);
      }
    }
  }

  /**
   * GLMarkerの配列を、GLMarkerIconInfo別の配列にする
   * @param markers GLMarkerの配列
   * @returns GLMarkerIconInfoと、それに対応するGLMarker配列のMap
   */
  private groupMarkersByIcon(markers: GLMarker[]): Map<GLMarkerIconInfo, GLMarker[]> {
    const markerMap = new Map<GLMarkerIconInfo, GLMarker[]>();
    for (const marker of markers) {
      const markerList = markerMap.get(marker.info) ?? [];
      markerList.push(marker);
      markerMap.set(marker.info, markerList);
    }
    return markerMap;
  }

  /**
   * GLMarkerLabelOptionsからキーを生成
   * @param labelOptions GLMarkerLabelOptions
   * @returns 生成したキー
   */
  private createLabelOpotionsKey(labelOptions: GLMarkerLabelOptions): string {
    let labelOptionsKey = 'no label';
    if (labelOptions.content && labelOptions.content.length > 0) {
      const {content, offset, style} = labelOptions;
      labelOptionsKey = `${content} ${offset?.x ?? 0},${offset?.y ?? 0}`;
      if (style) {
        labelOptionsKey += ` ${style.fontWeight ?? ''} ${style.color ?? ''} ${style.fontSize ?? ''}`;
      }
    }
    return labelOptionsKey;
  }

  /**
   * GLMarkerGroupObjectのテクスチャ再生成・更新
   * @param group 対象のGLMarkerGroupObject
   * @returns {void}
   */
  private updateGroupTexture(group: GLMarkerGroupObject): void {
    const image = group.markerImage;
    if (!image) {
      return;
    }

    const textureMapping = this.textureHelper.createMarkerTexture(group.labelOptionsMap, image, group.markerInfo);
    group.setTexture(textureMapping);
    if (this.status) {
      this.updateDrawObjects(this.status);
    }
  }

  /**
   * マーカーを削除
   * @param markers 削除対象のGLMarker配列
   * @returns {void}
   */
  removeMarkers(markers: GLMarker[]): void {
    const markersMap = this.groupMarkersByIcon(markers);

    for (const [info, markerList] of markersMap.entries()) {
      const markerGroupObject = this.markerGroupObjects.get(info);
      if (!markerGroupObject) {
        continue;
      }

      for (const marker of markerList) {
        const markerObject = this.findMarkerObjectByMarker(marker);
        if (!markerObject) {
          continue;
        }

        markerGroupObject.removeMarkerObject(markerObject);
        this.markerObjectMap.delete(markerObject);
      }

      if (markerGroupObject.isEmpty()) {
        this.markerGroupObjects.remove(info);
        this.markerLayer.removeMarkerGroupObject(markerGroupObject);
      }
    }
  }

  /**
   * GLMarkerGroupObjectを生成
   * @param markerInfo GLMarkerIconInfo
   * @param onImageLoaded マーカー画像読み込み完了通知
   * @returns GLMarkerGroupObject
   */
  private createMarkerGroupObject(markerInfo: GLMarkerIconInfo, onImageLoaded: () => void): GLMarkerGroupObject {
    const material = new TexturePlaneMaterial(this.context.getGLContext(), new CustomGeometry([], []), true);
    const markerGroupObject = new GLMarkerGroupObject(
      calculateWorldCoordinate(this.context.getMapStatus().centerLocation),
      QUATERNION_IDENTITY,
      VECTOR_ONES,
      material,
      markerInfo,
      onImageLoaded,
      this.camera
    );

    return markerGroupObject;
  }

  /**
   * GLMarkerObjectを生成
   * @param marker GLMarker
   * @param labelOptionsKey GLMarkerLabelOptionsのKey
   * @param group 追加先のGLMarkerGroupObject
   * @returns GLMarkerObject
   */
  private createMarkerObject(marker: GLMarker, labelOptionsKey: string, group: GLMarkerGroupObject): GLMarkerObject {
    const markerPosition = calculateWorldCoordinate(marker.getPosition());
    const markerObject = new GLMarkerObject(markerPosition, labelOptionsKey, marker.getZIndex());
    this.markerObjectMap.set(markerObject, marker);
    if (marker.getDraggable()) {
      this.draggableMarkerObjects.push(markerObject);
    }
    for (const [eventName, listener] of Object.entries(marker.listeners)) {
      markerObject.addEventListener(eventName as keyof GLMarkerEventMap, listener);
    }

    marker.setOnEventUpdatedListener(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (eventName: keyof GLMarkerEventMap, func: (ev: GLMarkerEvent) => any) => {
        markerObject.addEventListener(eventName, func);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (eventName: keyof GLMarkerEventMap, func: (ev: GLMarkerEvent) => any) => {
        markerObject.removeEventListener(eventName, func);
      }
    );

    marker.setOnLabelUpdateListener((marker) => {
      const labelOptions = marker.getLabel() ?? {content: ''};
      const labelOptionsKey = this.createLabelOpotionsKey(labelOptions);
      markerObject.setLabelOptionsKey(labelOptionsKey);
      group.addLabelOptions(labelOptionsKey, labelOptions);
      this.updateGroupTexture(group);
    });

    return markerObject;
  }

  /**
   * GLMarkerの状態更新時の処理設定
   * @param marker GLMarker
   * @param group 対応するGLMarkerGroupObject
   * @param markerObject 対応するGLMarkerObject
   * @returns {void}
   */
  private setupMarkerStatusUpdateListener(
    marker: GLMarker,
    group: GLMarkerGroupObject,
    markerObject: GLMarkerObject
  ): void {
    marker.setOnMarkerVisibleUpdateListener((m: GLMarker) => {
      if (markerObject.isVisible !== m.isVisible()) {
        markerObject.setVisible(m.isVisible());
        const eventName = m.isVisible() ? 'appear' : 'disappear';
        markerObject.helper.trigger(eventName, {
          sourceObject: m,
        });
      }
    });

    marker.setOnMarkerStatusUpdateListener((m: GLMarker) => {
      // visible
      if (markerObject.isVisible !== m.isVisible()) {
        markerObject.setVisible(m.isVisible());
        group.updateVertices(markerObject);

        const eventName = m.isVisible() ? 'appear' : 'disappear';
        markerObject.helper.trigger(eventName, {
          sourceObject: m,
        });
      }

      // positon
      const worldPosition = calculateWorldCoordinate(m.getPosition());
      if (!markerObject.position.equals(worldPosition)) {
        markerObject.setPosition(worldPosition);
        group.updateVertices(markerObject);

        markerObject.helper.trigger('position_changed', {
          sourceObject: m,
        });
      }

      // draggable
      if (m.getDraggable() && !this.draggableMarkerObjects.includes(markerObject)) {
        this.draggableMarkerObjects.push(markerObject);
      } else if (!m.getDraggable() && this.draggableMarkerObjects.includes(markerObject)) {
        const index = this.draggableMarkerObjects.indexOf(markerObject);
        if (index >= 0) {
          this.draggableMarkerObjects.splice(index, 1);
        }
      }

      this.notifyUpdate?.();
    });
  }

  /**
   * markerObjectMapからGLMarkerに対応するGLMarkerObjectを取得
   * @param targetMarker 対象GLMarker
   * @returns 対応するGLMarkerObject
   */
  private findMarkerObjectByMarker(targetMarker: GLMarker): Optional<GLMarkerObject> {
    for (const [markerObject, marker] of this.markerObjectMap.entries()) {
      if (marker === targetMarker) {
        return markerObject;
      }
    }
    return null;
  }

  /** @override */
  onDestroy(): void {
    this.markerLayer.destroy();
    for (const group of this.markerGroupObjects.values()) {
      group.destroy();
    }
    this.markerGroupObjects.clear();
    this.notifyUpdate = undefined;
  }
}

export {GLMarkerObjectRenderKit};
