import {ValueObject} from '../../../gaia/value/interface/ValueObject';

/**
 * ArrayList
 */
class ArrayList<T extends ValueObject> implements Iterable<T> {
  private array: T[];

  /**
   * イテレータ
   * @returns イテレータ
   */
  *[Symbol.iterator](): Iterator<T> {
    for (let index = 0; index < this.array.length; index++) {
      yield this.array[index];
    }
  }

  /**
   * プライベートコンストラクタ
   * @param array 最初に保持する要素の配列
   */
  private constructor(array: T[]) {
    this.array = [...array];
  }

  /**
   * 空のArrayListを作成する名前付きコンストラクタ
   * @returns 空のArrayList
   */
  static empty<T extends ValueObject>(): ArrayList<T> {
    return new ArrayList([]);
  }

  /**
   * 要素を配列で指定する名前付きコンストラクタ
   * @param elements 最初に保持する要素の配列
   * @returns 作成したArrayList
   */
  static from<T extends ValueObject>(elements: T[]): ArrayList<T> {
    return new ArrayList(elements);
  }

  /**
   * 要素を取得
   * @param index 取得したい要素の添字
   * @returns 指定した添字の要素
   * @throws 指定した添字がリストの範囲外であるときに例外をスローする
   */
  get(index: number): T {
    if (index < 0 || index >= this.size()) {
      throw new Error(`ArrayList.get(index: number) throws error out of bounds. 'index': ${index}`);
    }

    return this.array[index];
  }

  /**
   * 要素の追加
   * @param elements 追加する要素（複数指定可能）
   * @returns {void}
   */
  push(...elements: T[]): void {
    this.array.push(...elements);
  }

  /**
   * 要素数
   * @returns 要素数
   */
  size(): number {
    return this.array.length;
  }

  /**
   * 要素のクリア
   * @returns {void}
   */
  clear(): void {
    this.array = [];
  }

  /**
   * 指定した要素が何番目に存在するかを探索
   * @param element 探索したい要素
   * @returns 要素が存在する場合はその添字、そうでない場合は-1
   */
  indexOf(element: T): number {
    for (let index = 0; index < this.array.length; index++) {
      if (this.array[index].equals(element)) {
        return index;
      }
    }
    return -1;
  }

  /**
   * 指定した要素が含まれるかを確認
   * @param element 要素
   * @returns 指定した要素が含まれていればtrue, そうでなければfalse
   */
  contains(element: T): boolean {
    return this.indexOf(element) !== -1;
  }

  /**
   * 最初に見つかった要素を削除
   * @param element 要素
   * @returns {void}
   */
  remove(element: T): void {
    for (let index = 0; index < this.array.length; index++) {
      if (this.array[index].equals(element)) {
        this.array.splice(index, 1);
        return;
      }
    }
  }

  /**
   * 指定した要素を全て削除
   * @param element 要素
   * @returns {void}
   */
  removeAll(element: T): void {
    for (let index = 0; index < this.array.length; index++) {
      if (this.array[index].equals(element)) {
        this.array.splice(index, 1);
        index -= 1;
      }
    }
  }

  /**
   * Array.map()のArrayList版
   * @param mapping 要素を変換する関数
   * @returns 変換したArrayList
   */
  map<U extends ValueObject>(mapping: (element: T, index?: number, arrayList?: ArrayList<T>) => U): ArrayList<U> {
    return ArrayList.from(this.mapToArray(mapping));
  }

  /**
   * map()でArrayを返す関数
   * @param mapping 要素を変換する関数
   * @returns 変換したArray
   */
  mapToArray<U>(mapping: (element: T, index?: number, arrayList?: ArrayList<T>) => U): U[] {
    return this.array.map((element: T, index: number) => mapping(element, index, this));
  }

  /**
   * Array.forEach()のArrayList版
   * @param callback コールバック
   * @returns {void}
   */
  forEach(callback: (currentValue: T, index: number, array: ArrayList<T>) => void): void {
    for (let i = 0, len = this.array.length; i < len; i++) {
      callback(this.array[i], i, this);
    }
  }

  /**
   * Array.filter()のArrayList版
   * @param callback コールバック
   * @returns filterした結果
   */
  filter(callback: (currentValue: T, index: number, array: ArrayList<T>) => boolean): ArrayList<T> {
    const result = ArrayList.empty<T>();
    this.forEach((value, idx, arr) => {
      if (callback(value, idx, arr)) {
        result.push(value);
      }
    });
    return result;
  }

  /**
   * Arrayに変換
   * @returns 配列
   */
  toArray(): T[] {
    return this.array;
  }

  /**
   * 他のArrayListをマージ
   * @param other マージするArrayList
   * @returns {void}
   */
  merge(other: ArrayList<T>): void {
    for (let index = 0; index < other.size(); index++) {
      const element = other.get(index);
      if (!this.contains(element)) {
        this.push(element);
      }
    }
  }

  /**
   * ソート
   * @param compareFn 比較関数
   * @returns {void}
   */
  sort(compareFn?: (a: T, b: T) => number): void {
    this.array.sort(compareFn);
  }
}

export {ArrayList};
