import {JsonObject} from '../../../../gaia/types';
import {TileNumber} from '../../models/TileNumber';

/**
 * 標高パラメータ
 */
class AltitudeParameter extends TileNumber {
  private _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   */
  constructor(x: number, y: number, z: number) {
    super(x, y, z);
    this._cacheKey = `Altitude(${this.x},${this.y},${this.z})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }

  /** @override */
  clone(): AltitudeParameter {
    return new AltitudeParameter(this.x, this.y, this.z);
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof AltitudeParameter)) {
      return false;
    }
    const other = obj as AltitudeParameter;
    return other.x === this.x && other.y === this.y && other.z === this.z;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      x: this.x,
      y: this.y,
      z: this.z,
    };
  }
}

export {AltitudeParameter};
