const BYTE_UPPER_BOUND = 256;

/**
 * ushort取り出し
 * @param src 取り出し元配列
 * @param start 最初の添字
 * @returns 取り出した値
 */
const getUShort = (src: Uint8Array, start: number): number => {
  return src[start + 1] * BYTE_UPPER_BOUND + src[start];
};

/**
 * uint取り出し
 * @param src 取り出し元配列
 * @param start 最初の添字
 * @returns 取り出した値
 */
const getUInt = (src: Uint8Array, start: number): number => {
  let uint = 0;
  for (let index = 4; index > 0; index--) {
    uint = uint * BYTE_UPPER_BOUND + src[index + start - 1];
  }
  return uint;
};

/**
 * ulong取り出し
 * @param src 取り出し元バイト配列
 * @param start 最初の添字
 * @returns 取り出した値
 */
const getULong = (src: Uint8Array, start: number): number => {
  let ulong = 0;
  for (let index = 8; index > 0; index--) {
    ulong = ulong * BYTE_UPPER_BOUND + src[index + start - 1];
  }
  return ulong;
};

export {getUShort, getUInt, getULong};
