import {AnimationOption} from '../../../../gaia/value/animation';
import {Optional} from '../../../common/types';

/**
 * アニメーション管理クラス
 */
class AnimationController {
  private currentFrame = 0;
  private requestAnimationId: Optional<number> = null;
  private onProgress: (progress: number) => void;

  // 計測が完了するまではデフォルト値の60を使う
  // 計測に数秒かかるので、地図の起動後の数秒だけこのデフォルト値をつかいます
  private fps = 60;

  /**
   * コンストラクタ
   */
  constructor() {
    // 地図初期化60フレーム後から120フレーム後までに何秒進むかを計測し、fpsを算出する
    // 地図初期化から60フレーム程度はfpsが安定しないため、計測対象外にする必要がある
    const startFrame = 60;
    const endFrame = 120;
    let frameCount = 0;
    let startTime = 0;
    this.onProgress = (): void => undefined;

    /**
     * fps計測用のコールバック
     * @returns {void}
     */
    const loopFunction = (): void => {
      if (frameCount === startFrame) {
        // 計測開始時の時間をミリ秒の制度で計測
        startTime = performance.now();
      }
      frameCount++;

      if (frameCount >= endFrame) {
        const endTime = performance.now();
        this.fps = Math.round((1000 * (endFrame - startFrame)) / (endTime - startTime));
        return;
      }
      requestAnimationFrame(loopFunction);
    };
    loopFunction();
  }

  /**
   * アニメーションを開始する
   * @param onProgress アニメーションの進捗が変化した際に実行されるコールバック
   * @param option アニメーションの設定
   * @returns {void}
   */
  startAnimation(onProgress: (progress: number) => void, option: AnimationOption): void {
    this.cancelAnimation();

    this.currentFrame = 0;
    this.onProgress = onProgress;

    /**
     * アニメーション中に毎フレーム実行される関数
     * @returns {void}
     */
    const loopFunction = (): void => {
      this.currentFrame++;
      const elapsedSeconds = this.currentFrame / this.fps;

      if (elapsedSeconds >= option.seconds) {
        // 最後のフレームのときは、通知を行い終了
        onProgress(1);
        this.requestAnimationId = null;
        return;
      }

      const timeProgress = elapsedSeconds / option.seconds;
      const animationProgress = option.easing.calculateAnimationProgress(timeProgress);
      onProgress(animationProgress);

      // 次のフレームでの実行を要求
      this.requestAnimationId = requestAnimationFrame(loopFunction);
    };

    // アニメーション開始
    loopFunction();
  }

  /**
   * 現在進行中のアニメーションがあれば、それを中止する
   * @returns {void}
   */
  cancelAnimation(): void {
    if (this.requestAnimationId) {
      cancelAnimationFrame(this.requestAnimationId);
      this.requestAnimationId = null;
      this.currentFrame = 0;
    }
  }

  /**
   * 現在進行中のアニメーションがあれば、それを即座に完了させる
   * @returns {void}
   */
  completeAnimation(): void {
    if (this.requestAnimationId) {
      this.onProgress(1);
      this.cancelAnimation();
    }
  }
}

export {AnimationController};
