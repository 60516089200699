import {TileSize} from '../../../../gaia/types';
import {TileNumber} from '../../models/TileNumber';
import {IndoorCondition} from '../../../../gaia/value/IndoorCondition';
import {PaletteParameter} from '../../models/PaletteParameter';

/**
 * 屋内地図タイルのパラメータクラス
 */
class IndoorTileParameter extends TileNumber {
  /** タイルサイズ */
  readonly tileSize: TileSize;
  /** パレット情報 */
  readonly palette: PaletteParameter;
  /** 屋内情報コンディション */
  readonly condition: IndoorCondition;
  /** フロア */
  readonly floor: string;

  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   * @param palette パレット情報
   * @param condition リクエストオプション
   * @param floor フロア
   * @param tileSize タイル画像サイズ
   */
  constructor(
    x: number,
    y: number,
    z: number,
    palette: PaletteParameter,
    condition: IndoorCondition,
    floor: string,
    tileSize: TileSize = 256
  ) {
    super(x, y, z);
    this.tileSize = tileSize;
    this.palette = palette;
    this.condition = condition;
    this.floor = floor;

    this._cacheKey = `I(${this.x},${this.y},${this.z},${this.floor},${this.palette.language},${this.palette.name})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {IndoorTileParameter};
