import {mat4} from 'gl-matrix';
import {Color} from '../../../gaia/value';
import {Quaternion} from '../../common/math/Quaternion';
import {Ray3} from '../../common/math/Ray3';
import {Vector2} from '../../common/math/Vector2';
import {Vector3} from '../../common/math/Vector3';
import {Collision} from '../../engine/collision/Collision';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {Layer} from '../../engine/layer/Layer';
import {SingleColorMaterial} from '../../engine/material/SingleColorMaterial';
import {Object3D} from '../../engine/object/Object3D';
import {GaiaContext} from '../GaiaContext';
import {MapStatus} from '../models/MapStatus';
import {calculatePixelToUnit} from '../utils/MapUtil';

const LAYER_NAME_INDOOR_BACKGROUND = 'indoorBackground';

/**
 * 屋内モード時に通常地図全体を覆う暗い色のレイヤー
 */
class IndoorBackgroundPlaneLayer implements Layer {
  private readonly context: GaiaContext;

  private backgroundObject?: Object3D;

  private position: Vector3;
  private visible = false;

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.context = context;

    this.position = Vector3.zero();
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_INDOOR_BACKGROUND;
  }

  /**
   * 背景色を設定
   * @param backgroundColor 背景色
   * @returns {void}
   */
  setBackgroundColor(backgroundColor: Color): void {
    this.updateBackgroundObject(backgroundColor);
  }

  /**
   * テクスチャの繰り返し回数を計算
   * @param mapStatus 地図状態
   * @returns 繰り返し回数
   */
  private calculateSize(mapStatus: MapStatus): Vector2 {
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const width = mapStatus.clientWidth * ptu;
    const height = mapStatus.clientHeight * ptu;
    // TODO: 大きめに2倍するのではなく、厳密に大きさを求める
    const side = width > height ? width * 2 : height * 2;
    return new Vector2(side, side);
  }

  /**
   * 背景のオブジェクトを更新する
   * @param backgroundColor 背景色
   * @returns {void}
   */
  private updateBackgroundObject(backgroundColor: Color): void {
    const halfSide = 0.5;
    const geometry = new CustomGeometry(
      // eslint-disable-next-line prettier/prettier
      [
        -halfSide, halfSide, 0.0,
        -halfSide, -halfSide, 0.0,
        halfSide, halfSide, 0.0,
        halfSide, -halfSide, 0.0
      ],
      [0, 1, 2, 1, 3, 2]
    );
    const material = new SingleColorMaterial(this.context.getGLContext(), geometry, backgroundColor);
    this.backgroundObject = new Object3D(this.position, Quaternion.identity(), Vector3.one(), material, this.visible);
  }

  /**
   * 描画位置の更新
   * @param status MapStatus
   * @returns {void}
   */
  private calculatePosition(status: MapStatus): Vector3 {
    const ptu = calculatePixelToUnit(status.zoomLevel);
    return new Vector3(-status.centerOffset.x * ptu, status.centerOffset.y * ptu, 0);
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    if (!this.backgroundObject) {
      return;
    }

    const size = this.calculateSize(mapStatus);
    const scale = new Vector3(size.x, size.y, 1);

    const position = this.calculatePosition(mapStatus);
    this.position = position;

    this.backgroundObject.setScale(scale);
    this.backgroundObject.setPosition(position);
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    this.backgroundObject?.update(viewMatrix, projectionMatrix);

    if (!this.backgroundObject || !this.backgroundObject.isVisible()) {
      return true;
    }

    this.backgroundObject.draw();
    return true;
  }

  /**
   * 屋内背景レイヤの可視状態を設定
   * @param visible 可視状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    if (visible === this.visible) {
      return;
    }
    this.visible = visible;
    this.backgroundObject?.setVisible(visible);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.backgroundObject?.destroy();
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {IndoorBackgroundPlaneLayer};
