import {OnFinishRequestFunc, AbstractTileLoader} from './AbstractTileLoader';
import {AdditionTileParameter} from './param/AdditionTileParameter';
import {GaiaContext} from '../GaiaContext';
import {QueryParameter} from '../../common/types';
import {GaIAError} from '../../../gaia/value/GaIAError';
import {AdditionTileServerInfo} from '../../../gaia/types';
import {buildQueryString} from '../../common/util/URL';

const PATTERN_TILE_X = '{X}';
const PATTERN_TILE_Y = '{Y}';
const PATTERN_TILE_Z = '{Z}';

/**
 * 任意タイル地図ローダー
 */
class AdditionTileLoader extends AbstractTileLoader<AdditionTileParameter> {
  private serverInfo: AdditionTileServerInfo;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   * @param serverInfo 任意タイル地図サーバ情報
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc, serverInfo: AdditionTileServerInfo) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );
    this.serverInfo = serverInfo;
    const url = this.serverInfo.requestUrl;
    if (!url.includes(PATTERN_TILE_X) || !url.includes(PATTERN_TILE_Y) || !url.includes(PATTERN_TILE_Z)) {
      throw new GaIAError('AdditionTileUrl is invalid. Url need to contains {X}, {Y}, {Z} pattern.');
    }
    this.setState('ready');
  }

  /**
   * タイルのURLを生成
   * @param tile AdditionTileParameter
   * @returns リクエストURL
   */
  private createUrl(tile: AdditionTileParameter): string {
    const {x, y, z} = tile;
    const {requestUrl, queryParam} = this.serverInfo;
    let url = requestUrl
      .replace(PATTERN_TILE_X, x.toString())
      .replace(PATTERN_TILE_Y, y.toString())
      .replace(PATTERN_TILE_Z, z.toString());

    const baseQuery: QueryParameter = {};
    if (queryParam) {
      for (const key in queryParam) {
        baseQuery[key] = queryParam[key];
      }
    }
    const query = buildQueryString(baseQuery);
    if (query !== '') {
      url += `?`;
    }

    return `${url}${query}`;
  }
}

export {AdditionTileLoader};
