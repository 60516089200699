import {TileSize, TileType} from '../../../gaia/types';
import {TileNumber} from './TileNumber';
import {PaletteParameter} from './PaletteParameter';

/**
 * 地図タイルのパラメータクラス
 */
class TileParameter extends TileNumber {
  /** タイルサイズ */
  readonly tileSize: TileSize;
  /** タイル種別 */
  readonly tileType: TileType;
  /** パレット情報 */
  readonly palette: PaletteParameter;
  /** 注記表示フラグ */
  readonly annotation: boolean;
  /** 3Dビル表示フラグ */
  readonly building3D: boolean;

  private readonly _cacheKey: string;
  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   * @param tileSize タイル画像サイズ
   * @param tileType タイル種別
   * @param palette パレット情報
   * @param annotation 注記の有効フラグ
   * @param building3D 3Dビル表示フラグ
   */
  constructor(
    x: number,
    y: number,
    z: number,
    tileSize: TileSize = 256,
    tileType: TileType = 'tile',
    palette: PaletteParameter,
    annotation = true,
    building3D = true
  ) {
    super(x, y, z);
    this.tileSize = tileSize;
    this.tileType = tileType;
    this.palette = palette;
    this.annotation = annotation;
    this.building3D = building3D;

    this._cacheKey = `TP(${this.x},${this.y},${this.z},${this.tileType},${this.palette.language},${this.palette.name},a=${this.annotation},b=${building3D})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {TileParameter};
