import {Layer, LayerUpdateNotifierFunc} from '../../engine/layer/Layer';
import {GaiaContext} from '../GaiaContext';
import {TexturePlaneMaterial} from '../../engine/material/TexturePlaneMaterial';
import {Vector3} from '../../common/math/Vector3';
import {TexturePlaneGeometry} from '../../engine/geometry/TexturePlaneGeometry';
import {Object3D} from '../../engine/object/Object3D';
import {Quaternion} from '../../common/math/Quaternion';
import {TexturePlaneUVCoordinate} from '../../engine/geometry/TexturePlaneUVCoordinate';
import {Vector2} from '../../common/math/Vector2';
import {calculatePixelCoordinate, calculatePixelToUnit, calculateWorldCoordinate} from '../utils/MapUtil';
import {MapStatus} from '../models/MapStatus';
import {Optional} from '../../common/types';
import {mat4} from 'gl-matrix';
import {Collision} from '../../engine/collision/Collision';
import {Ray3} from '../../common/math/Ray3';
import {TileNumber} from '../models/TileNumber';
import {DepthProgram} from '../../engine/program/DepthProgram';
import {DepthMaterial} from '../../engine/material/DepthMaterial';

const DEFAULT_BLANK_TILE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEABAMAAACuXLVVAAAAMFBMVEXp6d/s7OLy8ujv7+Xw8Obp6eD09Orz8+nu7uTr6+Ht7eTq6uHy8uns7OPx8ef19euO+l7FAAAEVUlEQVR4nO3bX2hbdRQH8HPblK1pZ3u7yIYotx0OZVKSGmQMkWSMPQwniajdi9AqVpQ9dDIqQx/SFga+NSoIPrWdgr41bOCbdOi7yRB8XV988GnLvdebNO369fxu2lVJEcZ+pSjfAyE3v/w5n9yc+yPw+x3BAYcQQAABBBBAAAEEEEAAAQR0jLx/MICm65Yw91QJyOIT1z2N19zn5tzjz7omvFfcZ3JjbgpouO78KTP0tG0A8rKI4Ac9eAtRxQG2EmjJYpD4SQZGB7bkCYTSBwTSC1S6N//osg64L0n4HjRPQTElBJ5m84KcLwPRRCRd8Iv6zEZNbYPdiG5aB6yXpWAAG3ompmQBG2kDCKsKQAEG4IqHC5kYsJmbtw6oz0i/AQxrrqZM4Kw53/rYABADUtKPVBsQ5qyV6i7Al553NWGqkkRDEkh1ALxKsjHRBnyas5X/bwD95b/w0Bg3BVDr9Vc7AZmeDxZigMi+AFrS66G1YApgWE4VOgGTcr7UPgMn9wUQlTXh5DdnZFwr8cnpTkBLjmCnBhrT9gGY0YRj2awWQCjd6ARs6mzQBjRyD6wD3gE2xYveBmp9iIqH9gBgcHUboBeLpfy7gN/1lvcCzbamE87sij5sGkBoAL4CwkXkCxhUQPkwwtdtA5pFve5a3pKT9mvyJq5Udd6flWS68ZI4BZyRvtx1J70+/bzIjVHROGIb0I7qzsGmrc9/RMABBAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAgH1A9GjradYAc+4xNN3vgCDXWHaPlsI7Q9dcd/5svAEhuOP+GrhuAfjIPX5i2QztrA5ZA4RmZ8HL+u3/nMBls9y2VsBSLzIXh8WvYU3uIS+rQEYWsSG5X8pp2wCIrGBE7/Nm3fMQMAazyJjFsGAKk5LGSUkAP6qtLjlctg/4Wj/fACq9iIqHgZsx4KIBhLivgJFBB+GXbcDPTfuAY0Wpjpg1Ts0124PQiwFmQwLQBuSlWj/XBpx7WKr2AN6wjCug/qr+FFekUC90ADZkZSaIAadvPXybRYCWoQJm3tMCeCCLL6ADEMj4Z21AvDZuHYAl+Rz43hSAL13fdgIiSVxqA158Y18AH4qDKJkt90yj4ox3AlR4N9iuAXy8D4BG0UFwNJuJNwLl9gDclnvbgKv4zTYg8oDbDqaqWgCrmJLSHoB6H7YB8AdsA7YmdDZ0kNEjnRCaSTM2awB5iRVpXECY0OQe1mUB5z3bgOuOXnfLLRnCqPQUGv06dE3kBq4WJYWgLLeaxSF85c9K8kTNbEC4axsQx+62g+q/vOof8T/8P0AAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEPDaA7f9s/2f7P9v/2f7P9n+2/8fB9n8CCCCAAAIIIIAAAggggAACCCCAAAIIIIAAAggggIDHCLb/s/2f7f9s/2f7P9v/2f7fDrb/E0AAAQQQQAABBBBAAAH/HcBfMNbvGU12JWQAAAAASUVORK5CYII=';

const LAYER_NAME_BLANK_PLANE = 'blank';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isBlankPlaneLayer(argument: any): argument is BlankPlaneLayer {
  return argument.getIdenticalLayerName && argument.getIdenticalLayerName() === LAYER_NAME_BLANK_PLANE;
}

/**
 * ブランクタイル画像描画レイヤー
 */
class BlankPlaneLayer implements Layer {
  private readonly context: GaiaContext;

  private backgroundObject: Optional<Object3D> = null;
  private drawPosition: Vector3 = Vector3.zero();
  private centerTile: TileNumber;

  private depthObject: Optional<Object3D> = null;

  private customBlankTileImage: string;

  private notifyUpdate?: LayerUpdateNotifierFunc;

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.context = context;
    this.customBlankTileImage = context.getMapInitOptions().blankTileImage || DEFAULT_BLANK_TILE;

    const mapStatus = context.getMapStatus();
    this.centerTile = TileNumber.fromLatLng(mapStatus.centerLocation, mapStatus.zoomLevel, 256);
    this.updateDrawPosition(context.getMapStatus());
    this.drawBlankTile();
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_BLANK_PLANE;
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * 背景描画
   * @returns {void}
   */
  private drawBlankTile(): void {
    if (this.customBlankTileImage === DEFAULT_BLANK_TILE) {
      this.createBackGroundObject(this.customBlankTileImage);
    }

    this.resizeImage(this.customBlankTileImage, (resizedImage) => {
      this.createBackGroundObject(resizedImage);
    });
  }

  /**
   * 画像を256x256にリサイズ
   * @param resourse ブランクタイル画像パス(base64も可)
   * @param callback リサイズ完了通知
   * @returns {void}
   */
  private resizeImage(resourse: string, callback?: (base64: string) => void): void {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.src = resourse;
    image.onload = (): void => {
      ctx?.drawImage(image, 0, 0, 256, 256);
      if (callback) {
        callback?.(canvas.toDataURL());
      }
    };
  }

  /**
   * 背景オブジェクト生成
   * @param resource ブランクタイル画像パス(base64も可)
   * @returns {void}
   */
  private createBackGroundObject(resource: string): void {
    const image = new Image(256, 256);
    image.src = resource;
    image.onload = (): void => {
      const mapStatus = this.context.getMapStatus();
      const repeatsNum = this.calculateRepeatsNum(mapStatus);

      // uv
      const topLeft = new Vector2(0, 0);
      const bottomLeft = new Vector2(0, repeatsNum);
      const topRight = new Vector2(repeatsNum, 0);
      const bottomRight = new Vector2(repeatsNum, repeatsNum);
      const uv = new TexturePlaneUVCoordinate(topLeft, bottomLeft, topRight, bottomRight);

      const gl = this.context.getGLContext();
      const geometry = TexturePlaneGeometry.create(1, 1, uv);
      const material = new TexturePlaneMaterial(gl, geometry);
      const rotation = Quaternion.fromRadianAndAxis(0, new Vector3(0, 1, 0));
      const scaleBase = calculatePixelToUnit(Math.ceil(mapStatus.zoomLevel)) * 256;
      const scale = Vector3.one().multiply(scaleBase * repeatsNum);
      this.backgroundObject = new Object3D(this.drawPosition, rotation, scale, material);

      const depthMaterial = new DepthMaterial(gl, geometry);
      this.depthObject = new Object3D(this.drawPosition, rotation, scale, depthMaterial);

      const texParameterMap = new Map([
        [gl.TEXTURE_WRAP_S, gl.REPEAT],
        [gl.TEXTURE_WRAP_T, gl.REPEAT],
      ]);
      material.setTexture(image, texParameterMap);
      this.notifyUpdate?.();
    };
  }

  /**
   * テクスチャの繰り返し回数を計算
   * @param mapStatus 地図状態
   * @returns 繰り返し回数
   */
  private calculateRepeatsNum(mapStatus: MapStatus): number {
    let objectLength = mapStatus.clientWidth;
    if (objectLength < mapStatus.clientHeight) {
      objectLength = mapStatus.clientHeight;
    }
    return (objectLength / 256) * 4;
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    if (!this.backgroundObject || !this.depthObject) {
      return;
    }

    const scaleBase = calculatePixelToUnit(Math.floor(mapStatus.zoomLevel)) * 256;
    const scale = Vector3.one().multiply(scaleBase * this.calculateRepeatsNum(mapStatus));
    this.updateDrawPosition(mapStatus);

    this.backgroundObject.setScale(scale);
    this.depthObject.setScale(scale);
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    this.backgroundObject?.setPosition(this.drawPosition);
    this.backgroundObject?.update(viewMatrix, projectionMatrix);
    this.backgroundObject?.draw();

    if (DepthProgram.renderTarget) {
      this.depthObject?.setPosition(this.drawPosition);
      this.depthObject?.update(viewMatrix, projectionMatrix);
      this.depthObject?.draw(DepthProgram.renderTarget);
    }

    return true;
  }

  /**
   * 描画位置の更新
   * @param status MapStatus
   * @returns {void}
   */
  private updateDrawPosition(status: MapStatus): void {
    const z = Math.floor(status.zoomLevel);
    const pixelCoordinate: Vector2 = calculatePixelCoordinate(status.centerLocation, z);
    const x = Math.floor(pixelCoordinate.x / 256);
    const y = Math.floor(pixelCoordinate.y / 256);
    this.centerTile.setValues(x, y, z);

    const latLng = this.centerTile.centerLocation;
    const centerPosition = calculateWorldCoordinate(latLng);
    const cameraTargetPosition = calculateWorldCoordinate(status.centerLocation);
    this.drawPosition.setValues(
      centerPosition.x - cameraTargetPosition.x,
      centerPosition.y - cameraTargetPosition.y,
      centerPosition.z - cameraTargetPosition.z
    );
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.backgroundObject?.destroy();
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {BlankPlaneLayer, isBlankPlaneLayer, LAYER_NAME_BLANK_PLANE, DEFAULT_BLANK_TILE};
