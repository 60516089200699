import {LatLng} from '../../../../gaia/value';

/**
 * ヒートマップのスポット情報クラス
 */
class HeatSpotData {
  latLng: LatLng;
  weight: number;

  /**
   * コンストラクタ
   * @param latLng 緯度経度
   * @param weight 重み
   */
  constructor(latLng: LatLng, weight: number) {
    this.latLng = latLng;
    this.weight = weight;
  }
}

export {HeatSpotData};
