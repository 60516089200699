import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {GaiaContext} from '../../../GaiaContext';
import {BlankPlaneLayer, LAYER_NAME_BLANK_PLANE} from '../../../layer/BlankPlaneLayer';
import {MapStatus} from '../../../models/MapStatus';

/**
 * ブランク平面を扱う描画キット
 */
class BlankPlaneRenderKit extends AbstractObjectRenderKit {
  private readonly blankPlaneLayer: BlankPlaneLayer;

  /**
   * コンストラクタ
   * @param context Context
   * @param blankPlaneLayer BlankPlaneLayer
   * @param renderKitCtl MapRenderKitController
   */
  constructor(context: GaiaContext, blankPlaneLayer: BlankPlaneLayer, renderKitCtl: MapRenderKitController) {
    super(context, blankPlaneLayer, renderKitCtl);
    this.blankPlaneLayer = blankPlaneLayer;
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return LAYER_NAME_BLANK_PLANE;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.blankPlaneLayer.update(mapStatus);
  }

  /** @override */
  onDestroy(): void {
    this.blankPlaneLayer.destroy();
  }
}

export {BlankPlaneRenderKit};
