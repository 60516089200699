import {JsonObject, Language, TileSize} from '../../../../gaia/types';
import {transMillisecToDegree} from '../../../../gaia/util';
import {LatLng, Point} from '../../../../gaia/value';
import {ValueObject} from '../../../../gaia/value/interface/ValueObject';
import {
  convertToPaletteAnchor,
  convertToPaletteNoteType,
  NotePaletteConfig,
  PaletteAnchor,
  TextAnnotationInfo,
} from '../../../common/infra/response/AnnotationInfo';
import {AbstractAnnotationData} from './AbstractAnnotationData';
import {TextAnnotationTextureData} from './TextAnnotationTextureData';

/**
 * テキスト注記描画用データ
 */
class TextAnnotationData extends AbstractAnnotationData {
  readonly isForce: boolean;
  readonly offset: Point;
  readonly anchor: PaletteAnchor;
  readonly angle: number;
  readonly nodeId?: string;
  readonly textureData: TextAnnotationTextureData;
  readonly dot: boolean;

  /**
   * コンストラクタ
   * @param lang 言語
   * @param tileSize タイルサイズ
   * @param priority 描画優先度
   * @param ntjCode NTJコード
   * @param latlng 緯度経度
   * @param isForce 強制描画フラグ
   * @param offset 描画位置オフセット
   * @param anchor アンカー
   * @param angle 角度
   * @param textureData TextAnnotationTextureData
   * @param nodeId ノードID
   * @param dot ドット
   */
  private constructor(
    lang: Language,
    tileSize: TileSize,
    priority: number,
    ntjCode: number,
    latlng: LatLng,
    isForce: boolean,
    offset: Point,
    anchor: PaletteAnchor,
    angle: number,
    textureData: TextAnnotationTextureData,
    nodeId?: string,
    dot?: boolean
  ) {
    super(lang, tileSize, priority, ntjCode, latlng);
    this.isForce = isForce;
    this.offset = offset;
    this.anchor = anchor;
    this.angle = angle;
    this.nodeId = nodeId;
    this.textureData = textureData;
    this.dot = dot ?? false;
  }

  /**
   * TextAnnotationDataを作成
   * @param lang 言語
   * @param zoom ズームレベル
   * @param tileSize タイルサイズ
   * @param info TextAnnotationInfo
   * @param palette NotePaletteConfig
   * @returns TextAnnotationData
   */
  static createAnnotationData(
    lang: Language,
    zoom: number,
    tileSize: TileSize,
    info: TextAnnotationInfo,
    palette: NotePaletteConfig
  ): TextAnnotationData {
    const paletteAnchor = convertToPaletteAnchor(palette.anchor);
    const noteType = convertToPaletteNoteType(palette.noteType.id);
    const {appearance, isHorizon, ntjCode, priority, angle, lat, lon, nodeId} = info;
    const {size, color, backgroundColor, font, force, offset} = palette;
    const textureData = new TextAnnotationTextureData(
      appearance,
      isHorizon,
      size,
      color,
      backgroundColor,
      font,
      noteType,
      ntjCode,
      zoom
    );
    return new TextAnnotationData(
      lang,
      tileSize,
      priority,
      ntjCode,
      new LatLng(transMillisecToDegree(lat), transMillisecToDegree(lon)),
      force,
      new Point(offset.x, offset.y),
      paletteAnchor,
      angle,
      textureData,
      nodeId,
      palette.noteType.dot
    );
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof TextAnnotationData)) {
      return false;
    }
    const other = obj as TextAnnotationData;
    return (
      other.lang === this.lang &&
      other.tileSize === this.tileSize &&
      other.priority === this.priority &&
      other.ntjCode === this.ntjCode &&
      other.latlng.equals(this.latlng) &&
      other.isForce === this.isForce &&
      other.offset.equals(this.offset) &&
      other.anchor === this.anchor &&
      other.angle === this.angle &&
      other.nodeId === this.nodeId &&
      other.textureData.equals(this.textureData)
    );
  }

  /** @override */
  clone(): ValueObject {
    return new TextAnnotationData(
      this.lang,
      this.tileSize,
      this.priority,
      this.ntjCode,
      this.latlng,
      this.isForce,
      this.offset,
      this.anchor,
      this.angle,
      this.textureData,
      this.nodeId
    );
  }

  /** @override */
  toObject(): JsonObject {
    return {
      lang: this.lang,
      tileSize: this.tileSize,
      priority: this.priority,
      ntjCode: this.ntjCode,
      latlng: this.latlng.toObject(),
      isFotce: this.isForce,
      offset: this.offset,
      anchor: this.anchor,
      angle: this.angle,
      nodeId: this.nodeId,
      textureData: this.textureData,
    };
  }
}

export {TextAnnotationData};
