type LinkedListNode<T> = {
  next: LinkedListNode<T> | null;
  value: T;
};

/**
 * 片方向連結リスト
 */
class LinkedList<T> implements Iterable<T> {
  private first: LinkedListNode<T> | null;
  private last: LinkedListNode<T> | null;
  private _size: number;

  /**
   * イテレータ
   * @returns イテレータ
   */
  *[Symbol.iterator](): Iterator<T> {
    let currentNode = this.first;
    while (currentNode !== null) {
      yield currentNode.value;
      currentNode = currentNode.next;
    }
  }

  /**
   * コンストラクタ
   */
  constructor() {
    this.first = null;
    this.last = null;
    this._size = 0;
  }

  /**
   * 要素数
   * @returns 要素数
   */
  size(): number {
    return this._size;
  }

  /**
   * a
   * @param element a
   * @returns {void}
   */
  private insertIfEmpty(element: T): void {
    if (this._size !== 0) {
      return;
    }

    const node: LinkedListNode<T> = {
      next: null,
      value: element,
    };
    this.first = node;
    this.last = node;
    this._size = 1;
    return;
  }

  /**
   * a
   * @param element a
   * @returns {void}
   */
  insertFirst(element: T): void {
    if (this._size === 0) {
      this.insertIfEmpty(element);
      return;
    }
    this._size++;
    const node: LinkedListNode<T> = {
      next: this.first,
      value: element,
    };
    this.first = node;
  }

  /**
   * a
   * @param element z
   * @returns {void}
   */
  insertLast(element: T): void {
    if (this._size === 0) {
      this.insertIfEmpty(element);
      return;
    }
    if (!this.last) {
      return;
    }
    this._size++;
    const node: LinkedListNode<T> = {
      next: null,
      value: element,
    };
    this.last.next = node;
    this.last = node;
  }

  /**
   * 最初の要素を削除し、その要素を返す
   * @returns 削除した要素、削除する要素がなかった場合はnull
   */
  deleteFirst(): T | null {
    if (this._size === 0 || this.first === null || this.last === null) {
      return null;
    }
    const firstElement = this.first.value;
    this.first = this.first.next;
    this._size--;
    return firstElement;
  }

  /**
   * a
   * @param index a
   * @returns a
   */
  get(index: number): T {
    if (index < 0 || index >= this._size) {
      throw new Error(
        `LinkedList.get(index: number) throws error out of bounds. 'index': ${index}, 'size': ${this._size}`
      );
    }

    if (this._size === 0 || this.first === null || this.last === null) {
      throw new Error(`LinkedList.get(index: number) throws error out of bounds. this is empty.`);
    }

    let currentIndex = 0;
    let currentNode: LinkedListNode<T> | null = this.first;
    while (currentIndex !== index && currentNode !== null) {
      currentIndex++;
      currentNode = currentNode.next;
    }

    if (currentNode === null) {
      throw new Error(`LinkedList.get(index: number) throws error invalid status. null node detected.`);
    }

    return currentNode.value;
  }

  /**
   * クリア
   * @returns {void}
   */
  clear(): void {
    let currentNode = this.first;
    while (currentNode !== null) {
      const nextNode = currentNode.next;
      currentNode.next = null;
      currentNode = nextNode;
    }
    this.first = null;
    this.last = null;
    this._size = 0;
  }
}

export {LinkedList};
