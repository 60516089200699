import {AltitudeParameter} from '../../../map/loader/param/AltitudeParameter';
import {TileRequestUrlBuilderFunc} from './AbstractTileRequester';

/**
 * 標高情報を取得するリクエストクラス
 */
class AltitudeRequester {
  private readonly urlBuilder: TileRequestUrlBuilderFunc<AltitudeParameter>;

  /**
   * コンストラクタ
   * @param urlBuilder URL生成Builder関数
   */
  constructor(urlBuilder: TileRequestUrlBuilderFunc<AltitudeParameter>) {
    this.urlBuilder = urlBuilder;
  }

  /**
   * 通信を実行
   * @param param パラメータ
   * @param onLoad 読み込み通知
   * @returns {void}
   */
  request(param: AltitudeParameter, onLoad: (param: AltitudeParameter, data: Uint8Array) => void): void {
    const url = this.urlBuilder(param);
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => new Uint8Array(arrayBuffer))
      .then((byteArray) => {
        onLoad(param, byteArray);
      })
      .catch(() => {
        // do nothing
      });
  }
}

export {AltitudeRequester};
