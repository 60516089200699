import {AdditionTileLoader} from '../loader/AdditionTileLoader';
import {AdditionTileCondition} from '../../../gaia/value/AdditionTileCondition';
import {AdditionTileLayer} from '../layer/AdditionTileLayer';

/**
 * 任意タイル地図で扱うリソース群
 */
class AdditionTileResource {
  private _condition: AdditionTileCondition;
  readonly loader: AdditionTileLoader;
  readonly layer: AdditionTileLayer;

  /**
   * コンストラクタ
   * @param condition AdditionTileCondition
   * @param loader ローダー
   * @param layer レイヤー
   */
  constructor(condition: AdditionTileCondition, loader: AdditionTileLoader, layer: AdditionTileLayer) {
    this._condition = condition;
    this.loader = loader;
    this.layer = layer;
  }

  /**
   * AdditionTileCondition
   */
  get condition(): AdditionTileCondition {
    return this._condition;
  }

  /**
   * AdditionTileConditionを更新
   * @param condition AdditionTileCondition
   * @returns {void}
   */
  updateCondition(condition: AdditionTileCondition): void {
    this._condition = condition;
  }
}

export {AdditionTileResource};
