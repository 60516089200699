import {RainfallGradationCondition} from '../../../../gaia/value/RainfallGradationCondition';
import {TileNumber} from '../../models/TileNumber';

/**
 * グラデーション版降雨・降雪情報タイルのパラメータクラス
 */
class RainfallGradationTileParameter extends TileNumber {
  /** コンディション */
  readonly condition: RainfallGradationCondition;
  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   * @param condition リクエストオプション
   */
  constructor(x: number, y: number, z: number, condition: RainfallGradationCondition) {
    super(x, y, z);
    this.condition = condition;
    this._cacheKey = `RG(${this.x},${this.y},${this.z},${this.condition.offset})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {RainfallGradationTileParameter};
