import {TileNumber} from '../../../map/models/TileNumber';

/**
 * タイル画像データ保持クラス
 */
class TileImage {
  /** タイル番号 */
  readonly tileNumber: TileNumber;
  /** タイル画像データ */
  readonly image: TexImageSource;

  /**
   * コンストラクタ
   * @param tileNumber タイル画像のパラメータ
   * @param image 画像バイナリ
   */
  constructor(tileNumber: TileNumber, image: TexImageSource) {
    this.tileNumber = tileNumber;
    this.image = image;
  }
}

export {TileImage};
