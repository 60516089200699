import {ThunderConditionInitOptions} from '../types';

/**
 * 雷ナウキャスト情報コンディション
 */
class ThunderCondition {
  readonly offset: number;
  readonly server?: string;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param server タイル取得向き先URL
   */
  constructor(options: ThunderConditionInitOptions, server?: string) {
    this.offset = this.calcOffset(options);
    this.server = server;
  }

  /**
   * offsetを算出し取得する
   * @param options ThunderConditionInitOptions
   * @returns thunderOffset number
   */
  private calcOffset(options: ThunderConditionInitOptions): number {
    if (options.offset) {
      return options.offset;
    } else {
      // 現在時刻取得
      const currentDate = new Date();
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);
      // 指定時刻取得
      const time = options.time ?? new Date();
      time.setSeconds(0);
      time.setMilliseconds(0);
      // 現在時刻と指定時刻とoffset取得
      const diffTime = time.getTime() - currentDate.getTime();
      return Math.floor(diffTime / (1000 * 60));
    }
  }
}

export {ThunderCondition};
