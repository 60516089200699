import {JsonObject, TileSize} from '../../../../gaia/types';
import {PaletteParameter} from '../../models/PaletteParameter';
import {TileNumber} from '../../models/TileNumber';

/**
 * 地図アイコンのメインパラメータ
 */
class MapIconMainParameter extends TileNumber {
  readonly tileSize: TileSize;
  readonly palette: PaletteParameter;
  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   * @param tileSize タイル画像サイズ
   * @param palette パレット情報
   */
  constructor(x: number, y: number, z: number, tileSize: TileSize, palette: PaletteParameter) {
    super(x, y, z);
    this.tileSize = tileSize;
    this.palette = palette;
    this._cacheKey = `MIM(${this.x},${this.y},${this.z},${this.tileSize},${this.palette.language},${palette.name})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }

  /** @override */
  clone(): MapIconMainParameter {
    return new MapIconMainParameter(this.x, this.y, this.z, this.tileSize, this.palette);
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof MapIconMainParameter)) {
      return false;
    }
    const other = obj as MapIconMainParameter;
    return (
      other.x === this.x &&
      other.y === this.y &&
      other.z === this.z &&
      other.tileSize === this.tileSize &&
      other.palette === this.palette
    );
  }

  /** @override */
  toObject(): JsonObject {
    return {
      x: this.x,
      y: this.y,
      z: this.z,
      tileSize: this.tileSize,
      palette: {
        language: this.palette.language,
        name: this.palette.name,
      },
    };
  }
}

export {MapIconMainParameter};
