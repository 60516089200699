import {Object3D} from '../../../engine/object/Object3D';
import {Vector3} from '../../../common/math/Vector3';
import {Quaternion, QUATERNION_IDENTITY} from '../../../common/math/Quaternion';
import {MapStatus} from '../../models/MapStatus';
import {CustomGeometry} from '../../../engine/geometry/CustomGeometry';
import {Size} from '../../../../gaia/value';
import {calculatePixelToUnit} from '../../utils/MapUtil';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {TexturePlaneMaterial} from '../../../engine/material/TexturePlaneMaterial';
import {MarkerGravity, UserLocationProjectionMode} from '../../../../gaia/types';
import {Rect3} from '../../../common/math/Rect3';

/**
 * 自位置マーカーオブジェクトのクラス
 */
class UserLocationObject extends Object3D {
  private basePosition: Vector3;
  private angle: number;
  private projectionMode: UserLocationProjectionMode;
  private readonly material: TexturePlaneMaterial;

  private geometry: CustomGeometry;
  private uv: TexturePlaneUVCoordinate;

  private clientMarkerSize: Size;
  private worldMarkerSize: Size;

  private gravity: MarkerGravity;

  private currentZoomLevel: number;
  private mapStatus?: MapStatus;

  /**
   * コンストラクタ
   * @param position 位置
   * @param rotation 回転
   * @param scale 拡縮
   * @param material マテリアル
   * @param markerSize 表示サイズ
   * @param gravity 基準位置
   * @param projectionMode 投影モード
   * @param isVisible 表示状態
   */
  constructor(
    position: Vector3,
    rotation: Quaternion,
    scale: Vector3,
    material: TexturePlaneMaterial,
    markerSize: Size,
    gravity: MarkerGravity,
    projectionMode: UserLocationProjectionMode,
    isVisible: boolean
  ) {
    super(position, rotation, scale, material);
    this.basePosition = position;
    this.angle = 0;
    this.projectionMode = projectionMode;
    this.material = material;
    this.setVisible(isVisible);

    this.geometry = new CustomGeometry([], [0, 1, 2, 1, 3, 2]);
    this.uv = TexturePlaneUVCoordinate.defaultUVCoordinate();

    this.clientMarkerSize = markerSize;
    this.worldMarkerSize = new Size(0, 0);

    this.gravity = gravity;

    this.currentZoomLevel = 0;
  }

  /**
   * テクスチャの設定
   * @param texture テクスチャ
   * @returns {void}
   */
  setTexture(texture: TexImageSource): void {
    this.material.setTexture(texture);
  }

  /**
   * 位置の設定
   * @param position 位置
   * @returns {void}
   */
  setPosition(position: Vector3): void {
    this.basePosition = position;
  }

  /**
   * 位置を取得
   * @returns 位置
   */
  getPosition(): Vector3 {
    return this.basePosition;
  }

  /**
   * 角度の設定
   * @param angle ラジアン表現の角度
   * @returns {void}
   */
  setAngle(angle: number): void {
    this.angle = angle;
  }

  /**
   * 角度の取得
   * @returns ラジアン表現の角度
   */
  getAngle(): number {
    return this.angle;
  }

  /**
   * 投影モードの設定
   * @param mode UserLocationProjectionMode
   * @returns {void}
   */
  setProjectionMode(mode: UserLocationProjectionMode): void {
    this.projectionMode = mode;
  }

  /**
   * 表示サイズをの設定
   * @param size 表示サイズ
   * @returns {void}
   */
  setClientSize(size: Size): void {
    this.clientMarkerSize = size;
    if (this.mapStatus) {
      const ptu = calculatePixelToUnit(this.mapStatus.zoomLevel);
      this.worldMarkerSize = new Size(ptu * this.clientMarkerSize.height, ptu * this.clientMarkerSize.width);
    }
  }

  /**
   * 基準位置の設定
   * @param gravity 基準位置
   * @returns {void}
   */
  setGravity(gravity: MarkerGravity): void {
    this.gravity = gravity;
  }

  /**
   * 描画更新
   * @param cameraTargetPosition カメラの注視点
   * @param mapStatus MapStatus
   * @returns {void}
   */
  updateUserLocationObject(cameraTargetPosition: Vector3, mapStatus: MapStatus): void {
    this.mapStatus = mapStatus;
    this.setPositionValues(
      this.basePosition.x - cameraTargetPosition.x,
      this.basePosition.y - cameraTargetPosition.y,
      this.basePosition.z - cameraTargetPosition.z
    );

    const zoomLevel = mapStatus.zoomLevel;
    if (zoomLevel !== this.currentZoomLevel) {
      const ptu = calculatePixelToUnit(zoomLevel);
      this.worldMarkerSize = new Size(ptu * this.clientMarkerSize.height, ptu * this.clientMarkerSize.width);
    }
    this.currentZoomLevel = zoomLevel;

    this.updateVertices();
  }

  /**
   * 頂点座標更新
   * @returns {void}
   */
  private updateVertices(): void {
    if (!this.isVisible()) {
      const vertices = Array(20).fill(0);
      this.geometry.setVertices(vertices);
      this.material.setGeometry(this.geometry);
      return;
    }

    const rect =
      this.projectionMode === 'perspective'
        ? this.calculateDrawAreaOfPerspective()
        : this.calculateDrawAreaOfOrthographic();
    const {topLeft, bottomLeft, topRight, bottomRight} = rect;

    const vertices: number[] = [
      topLeft.x,
      topLeft.y,
      topLeft.z,
      this.uv.topLeft.x,
      this.uv.topLeft.y,
      bottomLeft.x,
      bottomLeft.y,
      bottomLeft.z,
      this.uv.bottomLeft.x,
      this.uv.bottomLeft.y,
      topRight.x,
      topRight.y,
      topRight.z,
      this.uv.topRight.x,
      this.uv.topRight.y,
      bottomRight.x,
      bottomRight.y,
      bottomRight.z,
      this.uv.bottomRight.x,
      this.uv.bottomRight.y,
    ];

    this.geometry.setVertices(vertices);
    this.material.setGeometry(this.geometry);
  }

  /**
   * 透視投影の場合の描画領域を算出
   * @returns 描画領域
   */
  private calculateDrawAreaOfPerspective(): Rect3 {
    const {width, height} = this.worldMarkerSize;
    const offsetX = this.calculateCenterOffsetX(this.gravity, this.worldMarkerSize);
    const offsetY = this.calculateCenterOffsetY(this.gravity, this.worldMarkerSize);

    const rotation = QUATERNION_IDENTITY._rotateZ(this.angle);

    const topLeft = rotation._product(new Vector3(-width / 2 + offsetX, height / 2 + offsetY, 0));
    const right = rotation._product(new Vector3(width, 0, 0));
    const bottom = rotation._product(new Vector3(0, -height, 0));

    return new Rect3(topLeft, right, bottom);
  }

  /**
   * 平行投影の場合の描画領域を算出
   * @returns 描画領域
   */
  private calculateDrawAreaOfOrthographic(): Rect3 {
    let rotation = QUATERNION_IDENTITY;
    if (this.mapStatus) {
      const rotationZ = QUATERNION_IDENTITY._rotateZ(this.mapStatus.getPolarPhi() + Math.PI / 2);
      const axis = rotationZ._product(new Vector3(1, 0, 0));
      const rotationX = Quaternion.fromRadianAndAxis(this.mapStatus.polar.theta, axis);

      rotation = QUATERNION_IDENTITY._multiply(rotationX)._rotateZ(this.angle);
    }

    const {width, height} = this.worldMarkerSize;
    const offsetX = this.calculateCenterOffsetX(this.gravity, this.worldMarkerSize);
    const offsetY = this.calculateCenterOffsetY(this.gravity, this.worldMarkerSize);

    const topLeft = rotation._product(new Vector3(-width / 2 + offsetX, height / 2 + offsetY, 0));
    const rotatedRight = rotation._product(new Vector3(width, 0, 0));
    const rotatedBottom = rotation._product(new Vector3(0, -height, 0));

    return new Rect3(topLeft, rotatedRight, rotatedBottom);
  }

  /**
   * マーカーの中心座標のオフセットを算出する(x座標)
   * @param gravity MarkerGravity
   * @param markerSize GL空間内におけるマーカーサイズ
   * @returns x成分のオフセット
   */
  private calculateCenterOffsetX(gravity: MarkerGravity, markerSize: Size): number {
    switch (gravity) {
      case 'left':
      case 'bottom-left':
      case 'top-left':
        return markerSize.width / 2;
      case 'right':
      case 'bottom-right':
      case 'top-right':
        return -markerSize.width / 2;
      default:
        return 0;
    }
  }

  /**
   * マーカーの中心座標のオフセットを算出する(y座標)
   * @param gravity MarkerGravity
   * @param markerSize GL空間内におけるマーカーサイズ
   * @returns y成分のオフセット
   */
  private calculateCenterOffsetY(gravity: MarkerGravity, markerSize: Size): number {
    switch (gravity) {
      case 'top':
      case 'top-left':
      case 'top-right':
        return -markerSize.height / 2;
      case 'bottom':
      case 'bottom-left':
      case 'bottom-right':
        return markerSize.height / 2;
      default:
        return 0;
    }
  }
}

export {UserLocationObject};
