/**
 * 数学の関数を集めたクラス
 * @packageDocumentation
 */

import {Vector2} from './Vector2';
import {Optional} from '../types';
import {RADIAN_TO_DEGREE} from './MathConstants';

/**
 * 最小値と最大値の間の値を返す
 * @param value 値
 * @param min 最小値
 * @param max 最大値
 * @returns 最小値と最大値の間の値
 */
const clamp = (value: number, min: number, max: number): number => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};

/**
 * 配列内の最大値を取得
 * @param array 対象の配列
 * @returns 最大値
 */
const findMaxValue = (array: number[]): number => {
  if (array.length === 0) {
    return NaN;
  }

  return array.reduce((a: number, b: number) => {
    if (isNaN(a) && isNaN(b)) {
      return NaN;
    }

    if (!isNaN(a) && !isNaN(b)) {
      return Math.max(a, b);
    }

    return !isNaN(a) ? a : b;
  });
};

/**
 * 配列内の最小値を取得
 * @param array 対象の配列
 * @returns 最小値
 */
const findMinValue = (array: number[]): number => {
  if (array.length === 0) {
    return NaN;
  }

  return array.reduce((a: number, b: number) => {
    if (isNaN(a) && isNaN(b)) {
      return NaN;
    }

    if (!isNaN(a) && !isNaN(b)) {
      return Math.min(a, b);
    }

    return !isNaN(a) ? a : b;
  });
};

/**
 * 線分をratio:(1-ratio)に内分する点を計算する
 * @param start 線分の始点
 * @param end 線分の終点
 * @param ratio 内分比
 * @returns 内分点
 */
const calculateInternalEquinox = (start: Vector2, end: Vector2, ratio: number): Vector2 => {
  return start._add(end._subtract(start)._multiply(ratio));
};

/**
 * 3点がp2においてなす外角を度数表記で求める
 * @param p1 点1
 * @param p2 点2
 * @param p3 点3
 * @returns 点2においてなす度数表記の外角、同じ点が複数与えられた時はnullを返す
 */
const calculateExternalAngle = (p1: Vector2, p2: Vector2, p3: Vector2): Optional<number> => {
  if (p1.equals(p2) || p2.equals(p3)) {
    return null;
  }

  const v12 = p2._subtract(p1);
  const v32 = p2._subtract(p3);
  const radian = Math.acos(v12.innerProduct(v32) / (v12.magnitude() * v32.magnitude()));
  const angle = radian * RADIAN_TO_DEGREE;
  if (v12.outerProduct(v32) > 0) {
    return angle - 180;
  } else {
    return 180 - angle;
  }
};

/**
 * 2次元空間での2直線の交点を求める
 * @param s1 直線1の始点
 * @param t1 直線1の終点
 * @param s2 直線2の始点
 * @param t2 直線2の終点
 * @returns 交点、存在しない場合はnull
 */
const calculateIntersectionTwoLines = (s1: Vector2, t1: Vector2, s2: Vector2, t2: Vector2): Optional<Vector2> => {
  if (s1.equals(t1) || s2.equals(t2)) {
    // どちらかの直線が同じ頂点で与えられていて意味を持たない場合はnull
    return null;
  }

  if (s1.x === t1.x && s2.x === t2.x) {
    // 2直線がともにy軸に平行な場合はnull
    return null;
  }

  if (s1.x === t1.x) {
    // 直線1のみがy軸に平行な場合は特殊処理
    const slope2 = (t2.y - s2.y) / (t2.x - s2.x);
    const intercept2 = s2.y - slope2 * s2.x;
    const intersectionY = slope2 * s1.x + intercept2;
    return new Vector2(s1.x, intersectionY);
  }

  if (s2.x === t2.x) {
    // 直線2のみがy軸に平行な場合は特殊処理
    const slope1 = (t1.y - s1.y) / (t1.x - s1.x);
    const intercept1 = s1.y - slope1 * s1.x;
    const intersectionY = slope1 * s2.x + intercept1;
    return new Vector2(s2.x, intersectionY);
  }

  const slope1 = (t1.y - s1.y) / (t1.x - s1.x);
  const slope2 = (t2.y - s2.y) / (t2.x - s2.x);
  if (slope1 === slope2) {
    // 2直線が平行な場合はnull
    return null;
  }

  const intercept1 = s1.y - slope1 * s1.x;
  const intercept2 = s2.y - slope2 * s2.x;

  const intersectionX = (intercept2 - intercept1) / (slope1 - slope2);
  const intersectionY = (slope1 * intercept2 - intercept1 * slope2) / (slope1 - slope2);

  return new Vector2(intersectionX, intersectionY);
};

/**
 * targetから見て、firstとlastを結んだ直線への足の長さを計算する
 * @param target 距離を求めたい頂点
 * @param first 線の始点
 * @param last 線の終点
 * @returns 足の長さ
 */
const orthogonalDistance = (target: Vector2, first: Vector2, last: Vector2): number => {
  const area =
    Math.abs(
      1.0 * first.y * last.x +
        1.0 * last.y * target.x +
        1.0 * target.y * first.x -
        1.0 * last.y * first.x -
        1.0 * target.y * last.x -
        1.0 * first.y * target.x
    ) / 2.0;

  const bottom = Math.hypot(first.y - last.y, first.x - last.x);

  if (bottom === 0) {
    return 0;
  }

  return (area / bottom) * 2.0;
};

/**
 * ダグラスポーカーの再帰関数。このファイル以外では直接呼び出さない想定
 * @param path 間引く対象の点列
 * @param tolerance 許容距離
 * @param startIndex 対象範囲の最初の頂点番号
 * @param lastIndex 対象範囲の最後の頂点番号
 * @param uses 間引いた結果採用することになった頂点の集合
 * @returns {void}
 */
const douglasPeuckerRecursive = (
  path: Vector2[],
  tolerance: number,
  startIndex: number,
  lastIndex: number,
  uses: boolean[]
): void => {
  if (lastIndex <= startIndex + 1) {
    return;
  }

  const startPoint: Vector2 = path[startIndex];
  const lastPoint: Vector2 = path[lastIndex];

  let farthestPointIndex = 0;
  let maxDistance = 0;

  for (let index = startIndex + 1; index < lastIndex; index++) {
    const point = path[index];
    const distance = orthogonalDistance(point, startPoint, lastPoint);
    if (distance > maxDistance) {
      farthestPointIndex = index;
      maxDistance = distance;
    }
  }

  if (maxDistance > tolerance) {
    uses[farthestPointIndex] = true;
    douglasPeuckerRecursive(path, tolerance, startIndex, farthestPointIndex, uses);
    douglasPeuckerRecursive(path, tolerance, farthestPointIndex, lastIndex, uses);
  }
};

/**
 * ダグラスポーカーのアルゴリズムで頂点列を間引く
 * @param path 間引く対象の点列
 * @param tolerance 許容距離
 * @returns 間引いた頂点列
 */
const douglasPeucker = (path: Vector2[], tolerance: number): Vector2[] => {
  const uses: boolean[] = path.map(() => false);
  const defaultStartIndex = 0;
  const defaultLastIndex = path.length - 1;
  uses[defaultStartIndex] = true;
  uses[defaultLastIndex] = true;
  douglasPeuckerRecursive(path, tolerance, defaultStartIndex, defaultLastIndex, uses);

  return path.filter((point, index) => uses[index]);
};

export {
  clamp,
  findMaxValue,
  findMinValue,
  calculateInternalEquinox,
  calculateExternalAngle,
  calculateIntersectionTwoLines,
  douglasPeucker,
};
