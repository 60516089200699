import {MapParts} from './MapParts';
import {MapStatus} from '../models/MapStatus';
import {Language, TileType, CopyrightTable} from '../../../gaia/types';
import {Point} from '../../../gaia/value';
import {GaiaContext} from '../GaiaContext';
import {getZoomLevelFixFunc} from '../utils/MapUtil';
import {Camera} from '../../engine/camera/Camera';
import {DEFAULT_ZOOM_RANGE} from '../../../gaia/value/ZoomRange';

const DEFAULT_COPYRIGHT_TABLE = {
  5: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  6: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  7: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  8: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  9: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  10: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  11: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  12: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  13: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  14: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  15: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  16: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  17: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  18: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
  19: ['© NAVITIME JAPAN. All Rights Reserved. 地図 ©ゼンリン'],
};

const SATELLITE_COPYRIGHT_TABLE = {
  0: ['(c)PlanetObserver', '(c)SHASHIN KAGAKU'],
  1: ['(c)PlanetObserver', '(c)SHASHIN KAGAKU'],
  2: ['(c)PlanetObserver', '(c)SHASHIN KAGAKU'],
  3: ['(c)PlanetObserver', '(c)SHASHIN KAGAKU'],
  4: ['(c)PlanetObserver', '(c)SHASHIN KAGAKU'],
  5: ['(c)PlanetObserver', '(c)SHASHIN KAGAKU'],
  6: ['(c)SHASHIN KAGAKU'],
  7: ['(c)SHASHIN KAGAKU'],
  8: ['(c)SHASHIN KAGAKU'],
  9: ['(c)SHASHIN KAGAKU'],
  10: ['(c)SHASHIN KAGAKU'],
  11: ['(c)SHASHIN KAGAKU'],
  12: ['(c)SHASHIN KAGAKU'],
  13: ['(c)SHASHIN KAGAKU'],
  14: ['(c)SHASHIN KAGAKU', 'NTT Data', 'RESTEC included (c)JAXA', '(c)ＮＴＴインフラネット'],
  15: ['(c)SHASHIN KAGAKU', 'NTT Data', 'RESTEC included (c)JAXA', '(c)ＮＴＴインフラネット'],
  16: ['(c)SHASHIN KAGAKU', 'NTT Data', 'RESTEC included (c)JAXA', '(c)ＮＴＴインフラネット'],
  17: ['(c)ＮＴＴインフラネット'],
  18: ['(c)ＮＴＴインフラネット'],
  19: ['(c)ＮＴＴインフラネット'],
  20: ['(c)ＮＴＴインフラネット'],
  21: ['(c)ＮＴＴインフラネット'],
  22: ['(c)ＮＴＴインフラネット'],
  23: ['(c)ＮＴＴインフラネット'],
  24: ['(c)ＮＴＴインフラネット'],
};

/**
 * Copyright制御クラス
 */
class MapCopyrightHandler implements MapParts {
  private readonly context: GaiaContext;
  private readonly copyrightElement: HTMLDivElement;
  private copyrightSet: {[zoom: number]: Set<string>};

  private configCopyrightTable?: CopyrightTable;
  private additionCopyrightTable?: CopyrightTable;

  private currentZoom = 0;
  private currentTileType: TileType = 'tile';
  private currentAdditionTileSet: Set<string> = new Set();
  private currentLanguage: Language = 'ja';

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param options コピーライト指定オプション
   */
  constructor(context: GaiaContext) {
    this.context = context;
    this.copyrightElement = document.createElement('div');
    this.copyrightSet = {};

    this.copyrightElement.oncontextmenu = (): boolean => false;
    this.copyrightElement.classList.add('gia-parts-copyright-text');

    const options = context.getMapInitOptions().copyright;

    if (options?.offset) {
      this.copyrightElement.style.right = `${options.offset.x}px`;
      this.copyrightElement.style.bottom = `${options.offset.y}px`;
    }
    if (options?.backgroundColor) {
      this.copyrightElement.style.background = options?.backgroundColor;
    }
    if (options?.textColor) {
      this.copyrightElement.style.color = options?.textColor;
    }

    this.updateCopyrightTable();
  }

  /** @override */
  getParts(): HTMLElement {
    return this.copyrightElement;
  }

  /** @override */
  updatePosition(position: Point): void {
    this.copyrightElement.style.right = `${position.x}px`;
    this.copyrightElement.style.bottom = `${position.y}px`;
  }

  /** @override */
  onUpdateStatus(status: MapStatus, _: Camera): void {
    const zoom = getZoomLevelFixFunc(this.context)(status.zoomLevel);
    const {tileType, palette, additionTileSet} = status;
    const language = palette.language;

    if (
      this.currentTileType !== tileType ||
      this.currentLanguage !== language ||
      this.changedAdditionTileSet(additionTileSet)
    ) {
      this.currentZoom = zoom;
      this.currentTileType = tileType;
      this.currentLanguage = language;
      this.currentAdditionTileSet = additionTileSet;
      this.updateCopyrightTable();
      return;
    }

    if (this.currentZoom === zoom) {
      return;
    }
    this.currentZoom = zoom;
    this.currentTileType = tileType;
    this.currentLanguage = language;
    this.currentAdditionTileSet = additionTileSet;
    this.updateCopyright();
  }

  /**
   * 任意タイル地図リストが更新されたかどうか
   * @param newSet 最新の任意タイル地図キー名リスト
   * @returns 更新されたかどうか
   */
  private changedAdditionTileSet(newSet: Set<string>): boolean {
    if (this.currentAdditionTileSet.size !== newSet.size) {
      return true;
    }

    for (const name of this.currentAdditionTileSet) {
      if (!newSet.has(name)) {
        return true;
      }
    }
    return false;
  }

  /**
   * コピーライト文字列の更新
   * @returns {void}
   */
  private updateCopyright(): void {
    const copyright = this.copyrightSet[this.currentZoom];
    if (!copyright) {
      return;
    }
    this.copyrightElement.textContent = [...copyright.values()].join(', ');
  }

  /**
   * コピーライトテーブルの更新
   * @returns {void}
   */
  private async updateCopyrightTable(): Promise<void> {
    this.configCopyrightTable = await this.fetchCopyright();
    this.additionCopyrightTable = this.createAdditionCopyrightTable();

    this.copyrightSet = {};
    const {min: minZoom, max: maxZoom} = DEFAULT_ZOOM_RANGE;
    for (let zoom = minZoom, length = maxZoom; zoom <= length; zoom++) {
      const configCopyright = this.configCopyrightTable[zoom] ?? [];
      const additionCopyright = this.additionCopyrightTable[zoom] ?? [];

      this.copyrightSet[zoom] = new Set([...configCopyright, ...additionCopyright]);
    }

    this.updateCopyright();
  }

  /**
   * 通信でコピーライト文字列を取得
   * @returns CopyrightTable
   */
  private async fetchCopyright(): Promise<CopyrightTable> {
    const conf = await this.context.getGaIAConfiguration();

    if (this.currentTileType === 'satellite') {
      return conf.copyright.satellite[this.currentLanguage] ?? SATELLITE_COPYRIGHT_TABLE;
    }

    return conf.copyright.map[this.currentLanguage] ?? DEFAULT_COPYRIGHT_TABLE;
  }

  /**
   * 任意タイル地図のコピーライト文字列を取得
   * @returns CopyrightTable
   */
  private createAdditionCopyrightTable(): CopyrightTable {
    const table: CopyrightTable = {};
    const serverInfoList = this.context.getMapInitOptions().additionTileOptions?.serverInfoList;
    if (!serverInfoList) {
      return table;
    }

    const {min: minZoom, max: maxZoom} = DEFAULT_ZOOM_RANGE;
    for (const key of this.currentAdditionTileSet.values()) {
      const copyright = serverInfoList[key]?.copyright?.[this.currentLanguage];
      if (!copyright) {
        continue;
      }

      for (let zoom = minZoom, length = maxZoom; zoom <= length; zoom++) {
        const copyrightList = table[zoom] ?? [];
        const copyrightByZoom = copyright[zoom] ?? [];
        table[zoom] = [...copyrightList, ...copyrightByZoom];
      }
    }

    return table;
  }
}

export {MapCopyrightHandler, DEFAULT_COPYRIGHT_TABLE, SATELLITE_COPYRIGHT_TABLE};
