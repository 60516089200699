import {AbstractUIEventObserver} from './AbstractUIEventObserver';
import {Vector2} from '../../common/math/Vector2';
import {KeyboardEventMap} from '../../../gaia/types';

const KEYCODE_LEFT = 37;
const KEYCODE_UP = 38;
const KEYCODE_RIGHT = 39;
const KEYCODE_DOWN = 40;
const KEYCODES_ARROW = [KEYCODE_LEFT, KEYCODE_UP, KEYCODE_RIGHT, KEYCODE_DOWN];

const KEYCODE_PLUS = 187;
const KEYCODE_MINUS = 189;
const KEYCODES_ZOOM = [KEYCODE_PLUS, KEYCODE_MINUS];

/**
 * キーコードから上下左右に移動するベクトルを計算する
 * @param keyCode キーコード
 * @returns 移動ベクトル
 */
const keyCodeToArrowMotion = (keyCode: number): Vector2 => {
  switch (keyCode) {
    case KEYCODE_UP:
      return new Vector2(0, 1);
    case KEYCODE_DOWN:
      return new Vector2(0, -1);
    case KEYCODE_LEFT:
      return new Vector2(-1, 0);
    case KEYCODE_RIGHT:
      return new Vector2(1, 0);
    default:
      return Vector2.zero();
  }
};

/**
 * キーコードからズームレベルの変化量を計算する
 * @param keyCode キーコード
 * @returns ズームレベルの変化量
 */
const keyCodeToDeltaZoomLevel = (keyCode: number): number => {
  switch (keyCode) {
    case KEYCODE_PLUS:
      return 1;
    case KEYCODE_MINUS:
      return -1;
    default:
      return 0;
  }
};

/**
 * キーボードイベントの監視と通知
 */
class KeyboardEventObserver extends AbstractUIEventObserver<KeyboardEventMap> {
  private _isShiftDown: boolean;

  /**
   * コンストラクタ
   * @param element HTMLElement
   */
  constructor(element: HTMLElement) {
    super(element);
    this._isShiftDown = false;
  }

  /**
   * シフトボタンが押されているか
   */
  get isShiftDown(): boolean {
    return this._isShiftDown;
  }

  /** @override */
  setupObserve(): void {
    document.addEventListener('keydown', (ev: KeyboardEvent) => this.handleKeyDown(ev));
    document.addEventListener('keyup', (ev: KeyboardEvent) => this.handleKeyUp(ev));
  }

  /**
   * キーボードのボタンが押されたときの処理
   * @param ev KeyboardEvent
   * @returns {void}
   */
  private handleKeyDown(ev: KeyboardEvent): void {
    if (ev.shiftKey) {
      this._isShiftDown = true;
    }
    this.trigger('keydown', ev);

    if (KEYCODES_ARROW.includes(ev.keyCode)) {
      const motion = keyCodeToArrowMotion(ev.keyCode);
      this.trigger('arrowkeydown', Object.assign(ev, {deltaX: motion.x, deltaY: motion.y}));
    }

    if (KEYCODES_ZOOM.includes(ev.keyCode)) {
      const deltaZoomLevel = keyCodeToDeltaZoomLevel(ev.keyCode);
      this.trigger('zoomkeydown', Object.assign(ev, {deltaZoomLevel}));
    }
  }

  /**
   * キーボードのボタンが離されたときの処理
   * @param ev KeyboardEvent
   * @returns {void}
   */
  private handleKeyUp(ev: KeyboardEvent): void {
    if (ev.shiftKey) {
      this._isShiftDown = false;
    }
    this.trigger('keyup', ev);
  }
}

export {KeyboardEventObserver};
