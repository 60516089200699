import {AccessInformation} from '../../gaia/AccessInformation';
import {GaIAError} from '../../gaia/value/GaIAError';
import {MapInitSettingOptions, MapInitSettings, MapServerURL, UserLocationTrackingMode} from '../../gaia/types';
import {MapStatus, OnUpdateMapStatusListener} from './models/MapStatus';
import {getDevicePixelRatio} from '../common/util/Device';
import {ZoomRange, Point, LatLng} from '../../gaia/value';
import {LatLngRect} from '../../gaia/object';
import {GaIAConfiguration} from './models/GaIAConfiguration';
import config from '../../gaia/Config';
import {DEFAULT_COPYRIGHT_TABLE, SATELLITE_COPYRIGHT_TABLE} from './parts/MapCopyrightHandler';
import {UserLocationData} from '../../gaia/value/UserLocationData';

type GaIAContextInitOptions = {
  base: HTMLElement;
  settings: MapInitSettings;
  productId: string;
};

/**
 * 地図の処理全般に関わる要素を保持するコンテキストクラス
 */
class GaiaContext {
  /** ベース要素 */
  private readonly base: HTMLElement;

  /** GaIA利用時の各種設定値 */
  private readonly configuration: Promise<GaIAConfiguration>;

  // GL関連
  /** Canvas要素 */
  private readonly canvas: HTMLCanvasElement;
  /** GL Context */
  private readonly glctx: WebGLRenderingContext;

  /** 地図設定オプション */
  private readonly options: MapInitSettingOptions;

  // 地図ステータス
  /** MapStatus */
  private readonly mapStatus: MapStatus;
  /** MapStatus更新通知 */
  private readonly mapStatusListenerList: OnUpdateMapStatusListener[] = [];

  /** 自位置情報 */
  private userLocationData: UserLocationData;
  /** トラッキングモード */
  private trackingMode: UserLocationTrackingMode;
  /** 自位置情報の更新間隔(秒) */
  private userLocationDataInterval: number;

  private isDestroyed = false;

  /**
   * コンストラクタ
   * @param options 初期化オプション
   * @param access 地図サーバー情報
   */
  constructor(options: GaIAContextInitOptions, access?: AccessInformation) {
    const {base, settings, productId} = options;
    this.base = base;

    if (access) {
      this.configuration = this.createTemporalConfiguration(access);
    } else {
      this.configuration = this.initConfiguration(productId, settings.options?.server);
    }

    const canvas = document.createElement('canvas');
    canvas.oncontextmenu = (): boolean => false;

    const glctx = canvas.getContext('webgl');
    if (!glctx) {
      throw new GaIAError('Could not get GLContext');
    }
    const height = this.base.clientHeight;
    const width = this.base.clientWidth;
    canvas.style.height = `${height}px`;
    canvas.style.width = `${width}px`;

    const devicePixelRatio = getDevicePixelRatio();
    const contentsHeight = height * devicePixelRatio;
    const contentsWidth = width * devicePixelRatio;
    canvas.height = contentsHeight;
    canvas.width = contentsWidth;
    glctx.viewport(0, 0, contentsWidth, contentsHeight);

    this.base.appendChild(canvas);
    this.canvas = canvas;
    this.glctx = glctx;

    const {center, zoomLevel} = settings;
    this.options = settings.options ?? {};
    const tileType = this.options?.tileType ?? 'tile';
    const viewRange = this.options?.viewRange ?? new LatLngRect(new LatLng(46, 123), new LatLng(23, 147));
    const zoomRange = this.options?.zoomRange ?? new ZoomRange();
    const centerOffset = this.options?.centerOffset ?? new Point(0, 0);
    const language = this.options?.language ?? 'ja';
    this.mapStatus = new MapStatus(
      center,
      zoomLevel,
      width,
      height,
      centerOffset,
      zoomRange,
      tileType,
      language,
      viewRange,
      (s) => this.triggerListeners(s)
    );

    this.userLocationData = new UserLocationData(center, 0);
    this.trackingMode = 'none';
    this.userLocationDataInterval = 1;
  }

  /**
   * 設定値をconfigサーバーから取得して初期化する
   * @private
   * @param productId プロダクトID(sid)
   * @param serverOverride サーバー向き先の上書き
   * @returns 設定値情報
   */
  private async initConfiguration(productId: string, serverOverride?: MapServerURL): Promise<GaIAConfiguration> {
    const server = config.endpoint;
    try {
      const res = await fetch(`${server}config/${productId}`);
      if (res.status !== 200) {
        const error = new GaIAError('Could not get configuration. Please contact administrator.');

        // eslint-disable-next-line no-console
        console.error(error.message);
        throw error;
      }
      const config = (await res.json()) as GaIAConfiguration;
      if (serverOverride?.tile) {
        config.server.tile = serverOverride.tile;
      }
      if (serverOverride?.satellite) {
        config.server.satellite = serverOverride.satellite;
      }
      if (serverOverride?.data) {
        config.server.data = serverOverride.data;
      }
      return config;
    } catch (e) {
      throw new GaIAError('Configuration Error.');
    }
  }

  /**
   * 設定値情報を内製する(旧I/F向け)
   * @deprecated
   * @private
   * @param access AccessInformation
   * @returns 設定値情報
   */
  private createTemporalConfiguration(access: AccessInformation): Promise<GaIAConfiguration> {
    return Promise.resolve<GaIAConfiguration>({
      productId: access.getInformation().productId,
      server: {
        tile: access.getInformation().server,
        satellite: access.getSatelliteServerUrl() ?? 'http://mars.navitime.co.jp/',
        data: access.getDataServerUrl(),
      },
      copyright: {
        map: {
          ja: DEFAULT_COPYRIGHT_TABLE,
          en: DEFAULT_COPYRIGHT_TABLE,
          ko: DEFAULT_COPYRIGHT_TABLE,
          'zh-CN': DEFAULT_COPYRIGHT_TABLE,
          'zh-TW': DEFAULT_COPYRIGHT_TABLE,
          th: DEFAULT_COPYRIGHT_TABLE,
        },
        satellite: {
          ja: SATELLITE_COPYRIGHT_TABLE,
          en: SATELLITE_COPYRIGHT_TABLE,
          ko: SATELLITE_COPYRIGHT_TABLE,
          'zh-CN': SATELLITE_COPYRIGHT_TABLE,
          'zh-TW': SATELLITE_COPYRIGHT_TABLE,
          th: SATELLITE_COPYRIGHT_TABLE,
        },
      },
      features: {
        congestion: true,
        indoor: true,
        rainfall: true,
        traffic: true,
        typhoon: true,
        thunder: true,
        orbit: true,
        snowfall: true,
        pollen: true,
        trainroute: true,
        roadshapeopened: true,
        externalannotation: true,
      },
    });
  }

  /**
   * ベースDOM要素を取得
   * @returns ベースDOM要素
   */
  getBaseElement(): HTMLElement {
    return this.base;
  }

  /**
   * CanvasのDOM要素を取得を取得
   * @returns CanvasのDOM要素を取得
   */
  getCanvasElement(): HTMLCanvasElement {
    return this.canvas;
  }

  /**
   * GL Contextを取得を取得
   * @returns GL Contextを取得
   */
  getGLContext(): WebGLRenderingContext {
    return this.glctx;
  }

  /**
   * GaIA設定値を取得
   * @returns GaIAConfiguration
   */
  getGaIAConfiguration(): Promise<GaIAConfiguration> {
    return this.configuration;
  }

  /**
   * 地図初期化オプションを取得
   * @returns 初期化オプション
   */
  getMapInitOptions(): MapInitSettingOptions {
    return this.options;
  }

  /**
   * 地図ステータスを取得
   * @returns 地図ステータス
   */
  getMapStatus(): MapStatus {
    return this.mapStatus;
  }

  /**
   * 自位置を設定
   * @param userLocationData 位置情報
   * @returns {void}
   */
  setUserLocationData(userLocationData: UserLocationData): void {
    this.userLocationData = userLocationData;
  }

  /**
   * 自位置を取得
   * @returns 位置情報
   */
  getUserLocationData(): UserLocationData {
    return this.userLocationData;
  }

  /**
   * トラッキングモードを設定
   * @param mode トラッキングモード
   * @returns {void}
   */
  setTrackingMode(mode: UserLocationTrackingMode): void {
    this.trackingMode = mode;
  }

  /**
   * トラッキングモードを取得
   * @returns {void}
   */
  getTrackingMode(): UserLocationTrackingMode {
    return this.trackingMode;
  }

  /**
   * 自位置情報の更新間隔を設定
   * @param interval 更新間隔(秒)
   * @returns {void}
   */
  setUserLocationDataInterval(interval: number): void {
    this.userLocationDataInterval = interval;
  }

  /**
   * 自位置情報の更新間隔を取得
   * @returns 更新間隔
   */
  getUserLocationDataInterval(): number {
    return this.userLocationDataInterval;
  }

  /**
   * 地図ステータス更新リスナーの登録
   * @param listener リスナー
   * @returns {void}
   */
  addOnMapStatusUpdateListener(listener: OnUpdateMapStatusListener): void {
    this.mapStatusListenerList.push(listener);
  }

  /**
   * 登録されている地図ステータス更新リスナーの実行
   * @param mapStatus 地図ステータス
   * @returns {void}
   */
  private triggerListeners(mapStatus: MapStatus): void {
    if (this.isDestroyed) {
      return;
    }
    for (const listener of this.mapStatusListenerList) {
      listener(mapStatus);
    }
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.isDestroyed = true;

    if (this.base.childElementCount > 0) {
      this.base.innerHTML = '';
      this.base.textContent = '';
    }

    const gl = this.getGLContext();
    const extension = gl.getExtension('WEBGL_lose_context');
    if (!extension) {
      return;
    }
    extension.loseContext();
  }
}

export {GaiaContext};
