import {Vector2} from './Vector2';
import {ValueObject} from '../../../gaia/value/interface/ValueObject';
import {JsonObject} from '../../../gaia/types';

/**
 * 3次元ベクトルのクラス
 */
class Vector3 implements ValueObject {
  /**
   * x座標の値
   */
  private _x: number;

  /**
   * y座標の値
   */
  private _y: number;

  /**
   * z座標の値
   */
  private _z: number;

  /**
   * 3次元ベクトルを作成する
   * @param x x座標の値
   * @param y y座標の値
   * @param z z座標の値
   */
  constructor(x: number, y: number, z: number) {
    this._x = x;
    this._y = y;
    this._z = z;
  }

  /**
   * x座標の値
   */
  get x(): number {
    return this._x;
  }

  /**
   * y座標の値
   */
  get y(): number {
    return this._y;
  }

  /**
   * z座標の値
   */
  get z(): number {
    return this._z;
  }

  /**
   * 座標を変更する
   * @param x x座標の値
   * @param y y座標の値
   * @param z z座標の値
   * @returns {void}
   */
  setValues(x: number, y: number, z: number): void {
    this._x = x;
    this._y = y;
    this._z = z;
  }

  /** @override */
  clone(): Vector3 {
    return new Vector3(this.x, this.y, this.z);
  }

  /** @override */
  equals(other: unknown): boolean {
    if (other instanceof Vector3 === false) {
      return false;
    }

    const otherVector3: Vector3 = other as Vector3;
    return this.x === otherVector3.x && this.y === otherVector3.y && this.z === otherVector3.z;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      x: this.x,
      y: this.y,
      z: this.z,
    };
  }

  /**
   * 引数に与えられたベクトルを足した新しいベクトルを返す
   * @param other 足すベクトル
   * @returns 足した結果のベクトル
   */
  _add(other: Vector3): Vector3 {
    return new Vector3(this._x + other._x, this._y + other._y, this._z + other._z);
  }

  /**
   * 引数で与えられたベクトルを足した新しいベクトルを返す（破壊的）
   * @param other 足すベクトル
   * @returns 足した結果のベクトル
   */
  add(other: Vector3): Vector3 {
    this._x += other.x;
    this._y += other.y;
    this._z += other.z;
    return this;
  }

  /**
   * 引数に与えられたベクトルを引いた新しいベクトルを返す
   * @param other 引くベクトル
   * @returns 引いた結果のベクトル
   */
  _subtract(other: Vector3): Vector3 {
    return new Vector3(this._x - other._x, this._y - other._y, this._z - other._z);
  }

  /**
   * 引数で与えられたベクトルを自身から引く（破壊的）
   * @param other 引くベクトル
   * @returns 自身を返す
   */
  subtract(other: Vector3): Vector3 {
    this._x -= other.x;
    this._y -= other.y;
    this._z -= other.z;
    return this;
  }

  /**
   * スカラー倍した新たなベクトルを返す
   * @param scalar スカラー係数
   * @returns スカラー倍した結果のベクトル
   */
  _multiply(scalar: number): Vector3 {
    return new Vector3(this._x * scalar, this._y * scalar, this._z * scalar);
  }

  /**
   * スカラー倍した自身を返す（破壊的）
   * @param scalar スカラー係数
   * @returns 自身を返す
   */
  multiply(scalar: number): Vector3 {
    this._x *= scalar;
    this._y *= scalar;
    this._z *= scalar;
    return this;
  }

  /**
   * スカラー除算した新たなベクトルを返す
   * @param scalar スカラー係数
   * @returns スカラー除算した結果のベクトル
   */
  _divide(scalar: number): Vector3 {
    return new Vector3(this._x / scalar, this._y / scalar, this._z / scalar);
  }

  /**
   * スカラー除算した自身を返す（破壊的）
   * @param scalar スカラー係数
   * @returns 自身を返す
   */
  divide(scalar: number): Vector3 {
    this._x /= scalar;
    this._y /= scalar;
    this._z /= scalar;
    return this;
  }

  /**
   * ベクトルの長さを返す
   * @returns ベクトルの長さ
   */
  magnitude(): number {
    return Math.sqrt(this.squaredMagnitude());
  }

  /**
   * ベクトルの長さの2乗を返す
   * @returns ベクトルの長さの2乗
   */
  squaredMagnitude(): number {
    return this._x ** 2 + this._y ** 2 + this._z ** 2;
  }

  /**
   * 長さを1に正規化した新しいベクトルを返す
   * @returns 正規化したベクトル
   */
  _normalize(): Vector3 {
    return this._divide(this.magnitude());
  }

  /**
   * 長さを1に正規化した自身を返す（破壊的）
   * @returns 自身を返す
   */
  normalize(): Vector3 {
    const magnitude = this.magnitude();
    this._x /= magnitude;
    this._y /= magnitude;
    this._z /= magnitude;
    return this;
  }

  /**
   * ベクトルの長さがゼロかどうかを判定する
   * @returns 長さがゼロであればtrue, そうでなければfalse
   */
  isZero(): boolean {
    return this._x === 0 && this._y === 0 && this._z === 0;
  }

  /**
   * 引数の値をx座標に足した新たなベクトルを返す
   * @param x x座標に足す値
   * @returns 足した結果のベクトル
   */
  _addX(x: number): Vector3 {
    return new Vector3(this._x + x, this._y, this._z);
  }

  /**
   * 引数の値をx座標に足した自身を返す（破壊的）
   * @param x x座標に足す値
   * @returns 自身を返す
   */
  addX(x: number): Vector3 {
    this._x += x;
    return this;
  }

  /**
   * 引数の値をy座標に足した新たなベクトルを返す
   * @param y y座標に足す値
   * @returns 足した結果のベクトル
   */
  _addY(y: number): Vector3 {
    return new Vector3(this._x, this._y + y, this._z);
  }

  /**
   * 引数の値をy座標に足した自身を返す（破壊的）
   * @param y y座標に足す値
   * @returns 自身を返す
   */
  addY(y: number): Vector3 {
    this._y += y;
    return this;
  }

  /**
   * 引数の値をz座標に足した新たなベクトルを返す
   * @param z z座標に足す値
   * @returns 足した結果のベクトル
   */
  _addZ(z: number): Vector3 {
    return new Vector3(this._x, this._y, this._z + z);
  }

  /**
   * 引数の値をz座標に足した自身を返す（破壊的）
   * @param z z座標に足す値
   * @returns 自身を返す
   */
  addZ(z: number): Vector3 {
    this._z += z;
    return this;
  }

  /**
   * 2次元ベクトルに変換
   * @returns 2次元ベクトル
   */
  toVector2(): Vector2 {
    return new Vector2(this._x, this._y);
  }

  /**
   * Arrayに変換する
   * @returns 配列
   */
  toArray(): number[] {
    return [this._x, this._y, this._z];
  }

  /**
   * Float32Arrayに変換する
   * @returns Float32Array
   */
  toFloat32Array(): Float32Array {
    return new Float32Array(this.toArray());
  }

  /**
   * ゼロベクトルを生成する
   * @returns ゼロベクトル
   */
  static zero(): Vector3 {
    return new Vector3(0, 0, 0);
  }

  /**
   * x, y, z座標が全て1のベクトルを生成する
   * @returns x, y, z座標が全て1のベクトル
   */
  static one(): Vector3 {
    return new Vector3(1, 1, 1);
  }
}

const ZERO_VECTOR = Vector3.zero();
const VECTOR_ONES = Vector3.one();

export {Vector3, ZERO_VECTOR, VECTOR_ONES};
