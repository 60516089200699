import {JsonObject} from '../../../../../gaia/types';
import {Quaternion} from '../../../../common/math/Quaternion';
import {Vector3} from '../../../../common/math/Vector3';
import {Optional} from '../../../../common/types';
import {createMap} from '../../GltfReader';
import {GltfId, GltfNode} from './GltfNode';

/**
 * JsonObjectからノードを作成する
 * @param nodeObject オブジェクト
 * @returns ノード、オブジェクトの情報が不十分な場合は `undefined`
 */
const createNodeFromJsonObject = (nodeObject: JsonObject): Optional<GltfNode> => {
  const name = nodeObject.name;
  const children = nodeObject.children;
  const matrix = nodeObject.matrix;
  const meshId = nodeObject.mesh;

  const translationObject = nodeObject.translation;
  const scaleObject = nodeObject.scale;
  const rotationObject = nodeObject.rotation;

  let translation = Vector3.zero();
  let scale = Vector3.one();
  let rotation = Quaternion.identity();
  if (Array.isArray(translationObject) && translationObject.length === 3) {
    translation = new Vector3(translationObject[0], translationObject[1], translationObject[2]);
  }
  if (Array.isArray(scaleObject) && scaleObject.length === 3) {
    scale = new Vector3(scaleObject[0], scaleObject[1], scaleObject[2]);
  }
  if (Array.isArray(rotationObject) && rotationObject.length === 4) {
    rotation = new Quaternion(rotationObject[3], rotationObject[0], rotationObject[1], rotationObject[2]);
  }

  return new GltfNode(rotation, scale, translation, name, children, matrix, meshId);
};

/**
 * glTFのノード
 */
class GltfNodesReader {
  private nodes: Map<GltfId, GltfNode>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.nodes = new Map();
  }

  /**
   * パース
   * @param json glTFのnodes部分
   * @returns パースが成功すれば `true` , そうでなければ `false`
   */
  parse(json: JsonObject): boolean {
    const nodes = createMap(json, createNodeFromJsonObject);
    if (!nodes) {
      return false;
    }
    this.nodes = nodes;
    return true;
  }

  /**
   * メッシュを取得
   * @param key キー
   * @returns キーに対応するメッシュ
   */
  get(key: GltfId): Optional<GltfNode> {
    return this.nodes.get(key);
  }
}

export {GltfNodesReader};
