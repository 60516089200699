import {mat4} from 'gl-matrix';
import {Quaternion} from '../../../../common/math/Quaternion';
import {Vector3} from '../../../../common/math/Vector3';
import {GltfId} from '../../GltfReader';

/**
 * glTFのノード
 */
class GltfNode {
  readonly rotation: Quaternion;
  readonly scale: Vector3;
  readonly translation: Vector3;

  readonly name?: string;
  readonly children?: GltfId[];
  readonly matrix?: mat4;
  readonly meshId?: GltfId;

  /**
   * コンストラクタ
   * @param rotation 回転
   * @param scale 拡縮
   * @param translation 位置
   * @param name 名前
   * @param children 子要素
   * @param matrix 行列
   * @param meshId メッシュのID
   */
  constructor(
    rotation: Quaternion,
    scale: Vector3,
    translation: Vector3,
    name?: string,
    children?: GltfId[],
    matrix?: mat4,
    meshId?: GltfId
  ) {
    this.rotation = rotation;
    this.scale = scale;
    this.translation = translation;
    this.name = name;
    this.children = children;
    this.matrix = matrix;
    this.meshId = meshId;
  }
}

export {GltfNode, GltfId};
