/**
 * 数学の定数
 * @packageDocumentation
 */

/** 度（度数法）で表現された角度をラジアン（弧度法）にする係数 */
const DEGREE_TO_RADIAN = Math.PI / 180.0;

/** ラジアン（弧度法）で表現された角度を度（度数法）にする係数 */
const RADIAN_TO_DEGREE = 180.0 / Math.PI;

export {DEGREE_TO_RADIAN, RADIAN_TO_DEGREE};
