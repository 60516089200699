import {ZoomRange} from '.';
import {LandmarkConditionInitOptions} from '../types';

const DEFAULT_VISIBLE_ZOOM_RANGE = new ZoomRange(15);
const DEFAULT_MAX_ZOOM_LEVEL_MINIATURE = 16;

/**
 * 3Dランドマークコンディション
 */
class LandmarkCondition {
  readonly visibleZoomRange: ZoomRange;
  readonly maxZoomLevelMiniature: number;

  /**
   * コンストラクタ
   * @param options 表示オプション
   */
  constructor(options: LandmarkConditionInitOptions) {
    this.visibleZoomRange = options.visibleZoomRange ?? DEFAULT_VISIBLE_ZOOM_RANGE;
    this.maxZoomLevelMiniature = options.maxZoomLevelMiniature ?? DEFAULT_MAX_ZOOM_LEVEL_MINIATURE;
  }
}

export {LandmarkCondition};
