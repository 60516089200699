import {QueryParameter} from '../../common/types';
import {buildQueryString} from '../../common/util/URL';
import {GaiaContext} from '../GaiaContext';
import {AbstractTileLoader, OnFinishRequestFunc} from './AbstractTileLoader';
import {IndoorTileParameter} from './param/IndoorTileParameter';

/**
 * 屋内地図タイルローダー
 */
class IndoorTileLoader extends AbstractTileLoader<IndoorTileParameter> {
  private serverUrl = '';
  private productId = '';
  private visibleAreaIdList: number[] = [];

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );

    context
      .getGaIAConfiguration()
      .then((res) => {
        this.serverUrl = `${res.server.tile}map/${res.productId}`;
        this.productId = res.productId;
        this.setState('ready');
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * 表示するエリアIDのリストを設定する
   * @param visibleAreaIdList 表示するエリアIDのリスト
   * @returns {void}
   */
  setVisibleAreaIdList(visibleAreaIdList: number[]): void {
    this.visibleAreaIdList = visibleAreaIdList;
  }

  /**
   * 表示するエリアIDのリストをクリアする（すべてのエリアを表示するようにする）
   * @returns {void}
   */
  clearVisibleAreaIdList(): void {
    this.visibleAreaIdList = [];
  }

  /**
   * タイルのURLを生成
   * @param tile IndoorTileParameter
   * @returns リクエストURL
   */
  private createUrl(tile: IndoorTileParameter): string {
    const {x, y, z, tileSize, palette, condition, floor} = tile;

    const query: QueryParameter = {
      product: 'web',
      lang: palette.language,
    };
    if (palette.name) {
      query['palette-name'] = palette.name;
    }
    if (this.visibleAreaIdList.length > 0) {
      query['visible-area-id-list'] = this.visibleAreaIdList.join(',');
    }

    const urlBase = condition.server ? `${condition.server}map/${this.productId}` : this.serverUrl;
    return `${urlBase}/tile/indoor/${tileSize}/${z}/${y}/${x}/${floor}?${buildQueryString(query)}`;
  }
}

export {IndoorTileLoader};
