import {Layer} from '../../engine/layer/Layer';
import {Ray3} from '../../common/math/Ray3';
import {mat4} from 'gl-matrix';
import {Collision} from '../../engine/collision/Collision';
import {GaiaContext} from '../GaiaContext';
import {PolylineObject} from '../render/objects/PolylineObject';
import {MapStatus} from '../models/MapStatus';
import {calculateWorldCoordinate, calculatePixelToUnit} from '../utils/MapUtil';
import {
  isRoadShapeOpenedLocation,
  RoadShapeOpenedFeature,
  RoadShapeOpenedProperty,
} from '../../common/infra/response/RoadShapeOpenedInfo';
import {Vector3, VECTOR_ONES} from '../../common/math/Vector3';
import {LatLng} from '../../../gaia/value';
import {SingleColorMaterial} from '../../engine/material/SingleColorMaterial';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {QUATERNION_IDENTITY} from '../../common/math/Quaternion';
import {DASH_ARRAY_SOLID} from '../../../gaia/object/shape/Polyline';
import {RoadShapeOpenedAppearance} from '../../../gaia/value/RoadShapeOpenedAppearance';
import {RoadShapeOpenedObject} from '../render/objects/RoadShapeOpenedObject';
import {
  RoadShapeOpenedCallback,
  RoadShapeOpenedClickListener,
  RoadShapeOpenedLineStyleOption,
} from '../../../gaia/types';
import {MouseEventObserver} from '../event/MouseEventObserver';
import {Camera} from '../../engine/camera/Camera';
import {PerspectiveCamera} from '../../engine/camera/PerspectiveCamera';
import {RoadShapeOpenedObjectRenderKit} from '../render/kit/object/RoadShapeOpenedObjectRenderKit';
import {ArrayList} from '../../common/collection/ArrayList';
import {Vector2} from '../../common/math/Vector2';
import {douglasPeucker} from '../../common/math/MathUtil';
import {TileNumber} from '../models/TileNumber';
import {RoadShapeOpenedData} from '../models/annotation/RoadShapeOpenedData';

const LAYER_NAME_ROADSHAPEOPENED = 'roadshapeopened';

type RoadShapeOpenedSegmentType = 'inline' | 'outline';

/** ズームレベルごとのダグラスポーカー間引きの許容距離 */
const DOUGLAS_PEUCKER_TOLERANCE_TABLE: {[key: number]: number} = {
  6: 1600,
  7: 800,
  8: 100,
  9: 100,
  10: 80,
  11: 40,
  12: 10,
  13: 10,
  14: 10,
  15: 0,
  16: 0,
  17: 0,
  18: 0,
  19: 0,
  20: 0,
};

/**
 * 新規開通道路レイヤー
 */
class RoadShapeOpenedLayer implements Layer {
  private readonly context: GaiaContext;
  private readonly camera: Camera;
  private visible: boolean;
  private baseDate: Date;

  private currentTile: ArrayList<TileNumber>;
  private inDisplayRoadShapeOpenedObjects: Map<string, RoadShapeOpenedObject>;
  private dataMap: Map<RoadShapeOpenedAppearance, RoadShapeOpenedProperty>;

  private onRoadShapeOpenedClick?: RoadShapeOpenedClickListener;
  private mouse: MouseEventObserver;
  private hoveredObject?: RoadShapeOpenedObject;

  private callback?: RoadShapeOpenedCallback;

  private isDestroyed = false;

  readonly getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKit RoadShapeOpenedObjectRenderKit
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKit: RoadShapeOpenedObjectRenderKit, camera: Camera) {
    this.context = context;
    this.camera = camera;
    this.visible = false;
    this.baseDate = new Date();

    this.currentTile = ArrayList.empty();
    this.inDisplayRoadShapeOpenedObjects = new Map();
    this.dataMap = new Map();

    this.onRoadShapeOpenedClick = undefined;
    this.mouse = new MouseEventObserver(context.getBaseElement());
    this.setupHover();
    this.setupClick();

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKit.getAllCollisions(ray);
  }

  /**
   * ホバー時の設定
   * @returns {void}
   */
  private setupHover(): void {
    this.mouse.addEventListener('mousemove', (ev: MouseEvent) => {
      if (!this.onRoadShapeOpenedClick || this.inDisplayRoadShapeOpenedObjects.size === 0 || !this.isOnMap(ev)) {
        return;
      }
      const ray = this.clientPositionToRay(ev.offsetX, ev.offsetY);
      const allCollisions = this.getAllCollisions(ray);

      for (const [layerName, collisions] of allCollisions.entries()) {
        if (layerName === LAYER_NAME_ROADSHAPEOPENED) {
          // 自分のレイヤーで当たり判定があるときは処理を行う
          let collidedRoadShapeOpenedObject: RoadShapeOpenedObject | undefined;
          if (collisions.length > 0) {
            const collision = collisions[0];
            const additional = collision.getAdditional();
            if (additional) {
              collidedRoadShapeOpenedObject = additional as RoadShapeOpenedObject;
            }
          }

          if (collidedRoadShapeOpenedObject) {
            this.hoveredObject = collidedRoadShapeOpenedObject;
            this.context.getBaseElement().style.cursor = 'pointer';
          } else {
            this.hoveredObject = undefined;
            this.context.getBaseElement().style.cursor = 'auto';
          }

          break;
        }

        // 自分のレイヤーより前に当たり判定があるときは何もしない
        if (collisions.length > 0) {
          this.hoveredObject = undefined;
          return;
        }
      }
    });
  }

  /**
   * クリック時の設定
   * @returns {void}
   */
  private setupClick(): void {
    this.mouse.addEventListener('click', () => {
      if (!this.onRoadShapeOpenedClick || this.inDisplayRoadShapeOpenedObjects.size === 0 || !this.hoveredObject) {
        return;
      }

      const data = this.hoveredObject.property;
      this.onRoadShapeOpenedClick?.(data);
    });
  }

  /**
   * 地図に対するマウスイベントかを判定
   * @param ev MouseEvent
   * @returns 地図に対するマウスイベントか
   */
  private isOnMap(ev: MouseEvent): boolean {
    for (const value of ev.composedPath()) {
      const classList = (value as Element).classList;
      if (classList && classList.contains('gia-base-element')) {
        return true;
      }
    }
    return false;
  }

  // TODO クライアント座標→Ray処理をUtilにうつす
  /**
   * クライアント座標をレイキャストに変換する
   * @param clientPositionX x座標
   * @param clientPositionY y座標
   * @returns レイキャスト
   */
  private clientPositionToRay(clientPositionX: number, clientPositionY: number): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const mapStatus = this.context.getMapStatus();

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const halfWidth = (mapStatus.clientWidth * ptu) / 2;
    const halfHeight = (mapStatus.clientHeight * ptu) / 2;
    const toTopVector = mapStatus.polar
      .toUpVector3()
      .normalize()
      .multiply(halfHeight * y);
    const toRightVector = mapStatus.polar
      .toRightVector3()
      .normalize()
      .multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);
    const start = calculateWorldCoordinate(mapStatus.centerLocation).add(this.camera.position);

    const direction = (this.camera as PerspectiveCamera).target._subtract(this.camera.position).add(toTopRightVector);

    const ray = new Ray3(start, direction);

    return ray;
  }

  /**
   * 表示状態の設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  /**
   * 基準日時の設定
   * @param baseDate 基準日時
   * @returns {void}
   */
  setBaseDate(baseDate: Date): void {
    this.baseDate = baseDate;
  }

  /**
   * 新規開通道路用コールバックの設定
   * @param callback コールバック関数
   * @returns {void}
   */
  setRoadShapeOpenedCallback(callback?: RoadShapeOpenedCallback): void {
    this.callback = callback;
  }

  /**
   * 新規開通道路クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setRoadShapeOpenedClickListener(listener: RoadShapeOpenedClickListener): void {
    this.onRoadShapeOpenedClick = listener;
  }

  /**
   * クリア処理
   * @returns {void}
   */
  clear(): void {
    this.currentTile = ArrayList.empty();
    this.inDisplayRoadShapeOpenedObjects.clear();
    this.dataMap.clear();
    this.hoveredObject = undefined;
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @param requiredTileMap 描画する新規開通道路データ
   * @returns {void}
   */
  update(mapStatus: MapStatus, requiredTileMap: Map<TileNumber, RoadShapeOpenedData[]>): void {
    if (this.isDestroyed) {
      return;
    }

    // 不要になったPolylineObjectを削除する
    const requiredTileList: ArrayList<TileNumber> = ArrayList.from<TileNumber>(Array.from(requiredTileMap.keys()));
    for (const tile of this.currentTile) {
      const index = requiredTileList.indexOf(tile);
      if (index < 0) {
        for (const [rellineId, roadShapeOpened] of this.inDisplayRoadShapeOpenedObjects.entries()) {
          roadShapeOpened.removePolylineObjects(tile);

          if (roadShapeOpened.inlineSegmentMap.size === 0 && roadShapeOpened.outlineSegmentMap.size === 0) {
            this.inDisplayRoadShapeOpenedObjects.delete(rellineId);
          }
        }
      }
    }

    for (const [tile, dataList] of requiredTileMap.entries()) {
      if (this.currentTile.indexOf(tile) >= 0) {
        continue;
      }

      for (const data of dataList) {
        const feature = data.feature;
        const properties = feature.properties;

        if (!this.validateRoadOpenDate(properties)) {
          continue;
        }

        const roadId = properties.roadId;
        if (!roadId) {
          // 道路IDがないものは不正データとする
          continue;
        }

        const appearance = this.switchAppearance(data);

        if (!appearance || !appearance.visible) {
          continue;
        }

        let roadShapeOpenedObject = this.inDisplayRoadShapeOpenedObjects.get(roadId);
        if (!roadShapeOpenedObject) {
          roadShapeOpenedObject = new RoadShapeOpenedObject(properties);
          this.inDisplayRoadShapeOpenedObjects.set(roadId, roadShapeOpenedObject);
        }
        const {inline: inlineStyle, outline: outlineStyle} = appearance.style;
        if (inlineStyle && inlineStyle.visible && inlineStyle.weight > 0) {
          const polyline = this.createPolylineObject(feature, inlineStyle, tile.z);
          roadShapeOpenedObject.addPolylineObjects(tile, polyline, 'inline');
        }
        if (outlineStyle && outlineStyle.visible && outlineStyle.weight > 0) {
          const polyline = this.createPolylineObject(feature, outlineStyle, tile.z);
          roadShapeOpenedObject.addPolylineObjects(tile, polyline, 'outline');
        }
      }
    }

    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    for (const roadShapeOpenedObject of this.inDisplayRoadShapeOpenedObjects.values()) {
      roadShapeOpenedObject.updateRoadShapeOpenedObject(cameraTargetPosition, mapStatus);
      roadShapeOpenedObject.updateColliders(mapStatus.zoomLevel);
    }

    this.currentTile = requiredTileList;
  }

  /**
   * 見た目を設定するcallback関数の設定有無に応じて、appearanceを返却
   * @param data RoadShapeOpenedData
   * @returns RoadShapeOpenedAppearance | undefined
   */
  private switchAppearance(data: RoadShapeOpenedData): RoadShapeOpenedAppearance | undefined {
    if (this.callback) {
      return this.callback?.(data.feature.properties);
    }
    return data.appearance;
  }

  /**
   * 受け取ったpropertyとbaseDateを照合して、新規開通道路の通行可否を返却
   * @param property RoadShapeOpenedProperty
   * @returns boolean
   */
  private validateRoadOpenDate(property: RoadShapeOpenedProperty): boolean {
    const startDateString = property.startDate;
    const endDateString = property.endDate;

    if (!startDateString || !endDateString) {
      return false;
    }

    const startDateObject = new Date(startDateString);
    const endDateObject = new Date(endDateString);

    return this.baseDate >= startDateObject && this.baseDate <= endDateObject;
  }

  /**
   * 新規開通道路情報および見た目情報をもとにPolylineObjectを作成
   * @param feature RoadShapeOpenedFeature
   * @param style RoadShapeOpenedLineStyleOption
   * @param zoom 表示中ズームレベル
   * @returns PolylineObject
   */
  private createPolylineObject(
    feature: RoadShapeOpenedFeature,
    style: RoadShapeOpenedLineStyleOption,
    zoom: number
  ): PolylineObject {
    const vec2Path: Vector2[] = [];
    const locationList = feature.geometry.locations;
    const {lat: baseLat, lon: baseLng} = locationList[0];
    const basePosition = calculateWorldCoordinate(new LatLng(baseLat, baseLng));
    for (const location of locationList) {
      if (!isRoadShapeOpenedLocation(location)) {
        continue;
      }

      const lat = location.lat;
      const lng = location.lon;
      const latlng = new LatLng(lat, lng);
      const position = calculateWorldCoordinate(latlng).subtract(basePosition);
      vec2Path.push(position.toVector2());
    }

    // 間引きを行う
    const tolerance = DOUGLAS_PEUCKER_TOLERANCE_TABLE[zoom] ?? 100;
    const thinnedStroke = douglasPeucker(vec2Path, tolerance);
    const path: Vector3[] = [];
    for (const vec2 of thinnedStroke) {
      path.push(vec2.toVector3());
    }

    const material = new SingleColorMaterial(this.context.getGLContext(), new CustomGeometry([], []), style.color);
    const polyline = new PolylineObject(
      basePosition,
      QUATERNION_IDENTITY,
      VECTOR_ONES,
      material,
      basePosition,
      path,
      style.weight,
      true,
      0,
      style.dashArray ?? DASH_ARRAY_SOLID
    );

    return polyline;
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (!this.visible) {
      return true;
    }

    for (const roadShapeOpenedObject of this.inDisplayRoadShapeOpenedObjects.values()) {
      roadShapeOpenedObject.updateDraw(viewMatrix, projectionMatrix);
    }
    return true;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_ROADSHAPEOPENED;
  }

  /** @override */
  getCollisions(ray: Ray3): Collision[] {
    if (!this.onRoadShapeOpenedClick) {
      return [];
    }

    const collisions: Collision[] = [];
    const roadShapeOpeneds = Array.from(this.inDisplayRoadShapeOpenedObjects.values());
    for (const roadShapeOpened of roadShapeOpeneds) {
      const collidedPolyline = roadShapeOpened.getCollidedPolyline(ray);
      if (collidedPolyline) {
        collisions.push(new Collision(collidedPolyline, roadShapeOpened));
      }
    }

    return collisions;
  }

  /** @override */
  destroy(): void {
    for (const obj of this.inDisplayRoadShapeOpenedObjects.values()) {
      obj.destroy();
    }
    this.inDisplayRoadShapeOpenedObjects.clear();
    this.dataMap.clear();
    this.hoveredObject?.destroy();
    this.hoveredObject = undefined;
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {RoadShapeOpenedLayer, LAYER_NAME_ROADSHAPEOPENED, RoadShapeOpenedSegmentType};
