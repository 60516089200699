import {Vector2} from '../../common/math/Vector2';

/**
 * テクスチャのUV座標を格納するクラス
 */
class TexturePlaneUVCoordinate {
  /** 左上の点のUV座標 */
  readonly topLeft: Vector2;
  /** 左下の点のUV座標 */
  readonly bottomLeft: Vector2;
  /** 右上の点のUV座標 */
  readonly topRight: Vector2;
  /** 右下の点のUV座標 */
  readonly bottomRight: Vector2;

  private static DEFAULT: TexturePlaneUVCoordinate = new TexturePlaneUVCoordinate();

  /**
   * コンストラクタ
   * @param topLeft 左上の点のUV座標
   * @param bottomLeft 左下の点のUV座標
   * @param topRight 右上の点のUV座標
   * @param bottomRight 右下の点のUV座標
   */
  constructor(
    topLeft: Vector2 = new Vector2(0, 0),
    bottomLeft: Vector2 = new Vector2(0, 1),
    topRight: Vector2 = new Vector2(1, 0),
    bottomRight: Vector2 = new Vector2(1, 1)
  ) {
    this.topLeft = topLeft.clone();
    this.bottomLeft = bottomLeft.clone();
    this.topRight = topRight.clone();
    this.bottomRight = bottomRight.clone();
  }

  /**
   * デフォルトの TexturePlaneUVCoordinate を取得
   * @returns TexturePlaneUVCoordinate
   */
  static defaultUVCoordinate(): TexturePlaneUVCoordinate {
    return TexturePlaneUVCoordinate.DEFAULT;
  }

  /**
   * 同一判定
   * @param other TexturePlaneUVCoordinate
   * @returns `true` なら同一
   */
  equals(other: TexturePlaneUVCoordinate): boolean {
    return (
      this.topLeft.equals(other.topLeft) &&
      this.bottomLeft.equals(other.bottomLeft) &&
      this.topRight.equals(other.topRight) &&
      this.bottomRight.equals(other.bottomRight)
    );
  }
}

export {TexturePlaneUVCoordinate};
