import {mat4} from 'gl-matrix';
import {Layer} from '../../engine/layer/Layer';
import {MapStatus} from '../models/MapStatus';
import {calculateWorldCoordinate} from '../utils/MapUtil';
import {UserLocationObject} from '../render/objects/UserLocationObject';
import {Vector3} from '../../common/math/Vector3';
import {AnimationController} from '../models/animation/AnimationController';
import {Optional} from '../../common/types';
import {Ray3} from '../../common/math/Ray3';
import {Collision} from '../../engine/collision/Collision';
import {AnimationOption} from '../../../gaia/value/animation';

/**
 * 自位置マーカー用レイヤー
 */
class UserLocationLayer implements Layer {
  private userLocationObject?: UserLocationObject;
  private animationCtl: AnimationController;

  /**
   * コンストラクタ
   */
  constructor() {
    this.animationCtl = new AnimationController();
  }

  /**
   * UserLocationObjectを追加
   * @param userLocationObject UserLocationObject
   * @returns {void}
   */
  setUserLocationObject(userLocationObject: UserLocationObject): void {
    this.userLocationObject = userLocationObject;
  }

  /**
   * UserLocationObjectを取得
   * @returns UserLocationObject
   */
  getUserLocationObject(): Optional<UserLocationObject> {
    return this.userLocationObject;
  }

  /**
   * UserLocationObjectの描画位置・角度の更新
   * @param mapStatus MapStatus
   * @param position 位置
   * @param angle ラジアン表現の角度
   * @param animationOption アニメーション設定
   * @returns {void}
   */
  updateUserLocationObjectPosition(
    mapStatus: MapStatus,
    position: Vector3,
    angle: number,
    animationOption?: AnimationOption
  ): void {
    if (!this.userLocationObject) {
      return;
    }

    this.animationCtl.cancelAnimation();

    if (!animationOption) {
      this.userLocationObject.setPosition(position);
      this.userLocationObject.setAngle(angle);
      this.update(mapStatus);
      return;
    }

    const startPosition = this.userLocationObject.getPosition();
    const startAngle = this.userLocationObject.getAngle();
    this.animationCtl.startAnimation((progress: number) => {
      if (!this.userLocationObject) {
        return;
      }

      const x = startPosition.x + (position.x - startPosition.x) * progress;
      const y = startPosition.y + (position.y - startPosition.y) * progress;
      const z = startPosition.z + (position.z - startPosition.z) * progress;
      this.userLocationObject.setPosition(new Vector3(x, y, z));
      this.userLocationObject.setAngle(startAngle + (angle - startAngle) * progress);

      this.update(mapStatus);
    }, animationOption);
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    this.userLocationObject?.updateUserLocationObject(cameraTargetPosition, mapStatus);
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (this.userLocationObject) {
      this.userLocationObject.update(viewMatrix, projectionMatrix);
      this.userLocationObject.draw();
    }

    return true;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return 'userLocation';
  }

  /** @override */
  destroy(): void {
    this.userLocationObject?.destroy();
    this.userLocationObject = undefined;
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {UserLocationLayer};
