import {TileNumber} from '../../models/TileNumber';
import {TileSize, Language} from '../../../../gaia/types';
import {TyphoonCondition} from '../../../../gaia/value/TyphoonCondition';

/**
 * 台風情報タイルのパラメータクラス
 */
class TyphoonTileParameter extends TileNumber {
  /** タイルサイズ */
  readonly tileSize: TileSize;
  /** 台風コンディション */
  readonly condition: TyphoonCondition;
  /** 言語 */
  readonly language: Language;

  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   * @param condition リクエストオプション
   * @param language 言語
   * @param tileSize タイル画像サイズ
   */
  constructor(
    x: number,
    y: number,
    z: number,
    condition: TyphoonCondition,
    language: Language,
    tileSize: TileSize = 256
  ) {
    super(x, y, z);
    this.condition = condition;
    this.language = language;
    this.tileSize = tileSize;

    this._cacheKey = `Ty(${this.x},${this.y},${this.z},${this.language})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {TyphoonTileParameter};
