import {QueryParameter} from '../types';

/**
 * クエリパラメータ文字列を生成
 * @param query クエリパラメータのオブジェクト
 * @returns URLクエリ文字列
 */
const buildQueryString = (query: QueryParameter): string =>
  Object.entries(query)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

export {buildQueryString};
