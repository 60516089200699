import {TileNumber} from '../../../map/models/TileNumber';

/** タイルリクエストのURL生成関数 */
type TileRequestUrlBuilderFunc<T extends TileNumber> = (tile: T) => string;
/** タイル画像読み込み通知 */
type OnLoadTileFunc<T extends TileNumber> = (tile: T, tex?: TexImageSource) => void;

/**
 * タイル画像リクエスト処理の抽象クラス
 */
abstract class AbstractTileRequester<T extends TileNumber> {
  protected readonly urlBuilder: TileRequestUrlBuilderFunc<T>;

  /**
   * コンストラクタ
   * @param builder リクエストURL生成Builder関数
   */
  constructor(builder: TileRequestUrlBuilderFunc<T>) {
    this.urlBuilder = builder;
  }

  /**
   * タイルのリクエストを実行
   * @param tiles タイル一覧
   * @param onLoadTile タイルの読み込み通知
   * @returns {void}
   */
  abstract request(tiles: T[], onLoadTile: OnLoadTileFunc<T>): void;

  /**
   * リクエストのクリア
   * @returns {void}
   */
  abstract clear(): void;

  /**
   * 破棄処理
   * @returns {void}
   */
  abstract destroy(): void;
}

export {AbstractTileRequester, TileRequestUrlBuilderFunc, OnLoadTileFunc};
