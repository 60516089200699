import {mat4} from 'gl-matrix';
import {Size} from '../../../gaia/value';
import {ArrayList} from '../../common/collection/ArrayList';
import {LRUCache} from '../../common/collection/LRUCache';
import {Queue} from '../../common/collection/Queue';
import {SimpleCache} from '../../common/collection/SimpleCache';
import {Ray3} from '../../common/math/Ray3';
import {Vector2} from '../../common/math/Vector2';
import {Vector3} from '../../common/math/Vector3';
import {Optional} from '../../common/types';
import {Camera} from '../../engine/camera/Camera';
import {Collision} from '../../engine/collision/Collision';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {Layer, LayerUpdateNotifierFunc} from '../../engine/layer/Layer';
import {TexturePlaneMaterial} from '../../engine/material/TexturePlaneMaterial';
import {MouseEventObserver} from '../event/MouseEventObserver';
import {GaiaContext} from '../GaiaContext';
import {AnnotationMainParameter} from '../loader/param/AnnotationMainParameter';
import {MarkAnnotationData} from '../models/annotation/MarkAnnotationData';
import {MarkAnnotationTextureData} from '../models/annotation/MarkAnnotationTextureData';
import {MapStatus} from '../models/MapStatus';
import {AnnotationCullHelper} from '../render/helper/AnnotationCullHelper';
import {AnnotationTextureHelper, AnnotationTextureMapping} from '../render/helper/AnnotationTextureHelper';
import {AnnotationObject} from '../render/objects/AnnotationObject';
import {MarkAnnotationGroupObject} from '../render/objects/MarkAnnotationGroupObject';
import {calculateWorldCoordinate} from '../utils/MapUtil';

const ANNOTATION_LAYER_NAME_MARK = 'markAnnotation';
const MAX_SET_TEXTURE_COUNT = 2;
const SQ_CULLING_LENGTH = 0.3;

/**
 * 国道アイコン注記レイヤー
 */
class MarkAnnotationLayer implements Layer {
  private readonly context: GaiaContext;
  private readonly camera: Camera;
  private visible: boolean;

  /** 描画中国道アイコングループオブジェクト */
  private inDisplayMarkObjects: SimpleCache<AnnotationMainParameter, MarkAnnotationGroupObject>;
  /** 座布団テクスチャ */
  private matTextureCache?: CanvasImageSource;
  /** 生成済み国道アイコンテクスチャ */
  private markTextureCache: LRUCache<AnnotationMainParameter, AnnotationTextureMapping>;
  /** 再利用可能なMaterial */
  private reuseableMaterials: Queue<TexturePlaneMaterial>;

  private _isSetMatTexture = false;
  private cullHelper: AnnotationCullHelper;
  private textureHelper: AnnotationTextureHelper;

  private notifyUpdate?: LayerUpdateNotifierFunc;

  /** 間引き実行抑制フラグ */
  private suspendCull = false;
  /** 間引き実行debounceID */
  private debounceId = 0;
  private currentZoom = 0;

  private mouse: MouseEventObserver;

  private isDestroyed = false;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param camera Camera
   */
  constructor(context: GaiaContext, camera: Camera) {
    this.context = context;
    this.camera = camera;
    this.visible = false;

    this.inDisplayMarkObjects = new SimpleCache();
    this.markTextureCache = new LRUCache();
    this.reuseableMaterials = new Queue();

    this.cullHelper = new AnnotationCullHelper();
    this.textureHelper = new AnnotationTextureHelper();

    this.mouse = new MouseEventObserver(context.getBaseElement());
    this.mouse.addEventListener('mousedown', () => {
      this.suspendCull = true;
    });
    this.mouse.addEventListener('mouseup', () => {
      for (const group of this.inDisplayMarkObjects.values()) {
        group.cullInGroup(this.camera, calculateWorldCoordinate(context.getMapStatus().centerLocation));
      }
      this.cullAllAnnotationObjects(this.camera);
      this.suspendCull = false;
    });
    context.addOnMapStatusUpdateListener((status) => {
      if (Math.abs(this.currentZoom - status.zoomLevel) > 0.0001) {
        this.suspendCull = true;
        if (this.debounceId !== 0) {
          window.clearTimeout(this.debounceId);
          this.debounceId = 0;
        }
        this.debounceId = window.setTimeout(() => {
          const cameraTargetPosition = calculateWorldCoordinate(context.getMapStatus().centerLocation);
          for (const group of this.inDisplayMarkObjects.values()) {
            group.cullInGroup(this.camera, cameraTargetPosition);
          }
          this.cullAllAnnotationObjects(this.camera);
          this.suspendCull = false;
        }, 50);
      }
      this.currentZoom = status.zoomLevel;
    });
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * 座布団テクスチャの設定
   * @param image テクスチャ
   * @returns {void}
   */
  setMatTexture(image: CanvasImageSource): void {
    this.matTextureCache = image;
    this._isSetMatTexture = true;
  }

  /**
   * 座布団テクスチャを設定済みか
   */
  get isSetMatTexture(): boolean {
    return this._isSetMatTexture;
  }

  /**
   * 表示状態の設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @param requiredAnnotationMap 描画する注記のデータ
   * @returns {void}
   */
  update(mapStatus: MapStatus, requiredAnnotationMap: Map<AnnotationMainParameter, MarkAnnotationData[]>): void {
    if (this.isDestroyed) {
      return;
    }

    this.markTextureCache.jumpUpSize(requiredAnnotationMap.size * 2);

    const requiredTileList: ArrayList<AnnotationMainParameter> = ArrayList.from<AnnotationMainParameter>(
      Array.from(requiredAnnotationMap.keys())
    );

    // 不要になった注記を削除する
    const currentTiles = Array.from(this.inDisplayMarkObjects.keys());
    for (const param of currentTiles) {
      if (requiredTileList.contains(param)) {
        continue;
      }

      const markGroup = this.inDisplayMarkObjects.get(param);
      if (markGroup) {
        const material = markGroup.material;
        material.initTexture();
        this.reuseableMaterials.enqueue(material);
        this.inDisplayMarkObjects.remove(param);
      }
    }

    // 新しく描画する必要がある注記を追加する
    for (const [param, dataList] of requiredAnnotationMap.entries()) {
      if (this.inDisplayMarkObjects.has(param)) {
        continue;
      }

      const textureMapping = this.createTextureMapping(param, dataList);
      if (!textureMapping) {
        continue;
      }
      const markObjectList = this.createMarkAnnotationObjectList(dataList, textureMapping);
      if (markObjectList.length > 0) {
        const geometry = new CustomGeometry([], []);
        const material =
          this.reuseableMaterials.dequeue() ?? new TexturePlaneMaterial(this.context.getGLContext(), geometry);
        this.inDisplayMarkObjects.add(param, new MarkAnnotationGroupObject(material, geometry, markObjectList));
      }
    }

    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    for (const group of this.inDisplayMarkObjects.values()) {
      group.updateColliders(mapStatus.zoomLevel, mapStatus.polar);
    }

    if (!this.suspendCull) {
      for (const group of this.inDisplayMarkObjects.values()) {
        group.cullInGroup(this.camera, cameraTargetPosition);
      }
      this.cullAllAnnotationObjects(this.camera);
    }

    this.updateAllObjects(cameraTargetPosition);
  }

  /**
   * AnnotationTextureMapping作成
   * @param param AnnotationMainParameter
   * @param markDataList 国道アイコンデータリスト
   * @returns AnnotationTextureMapping
   */
  private createTextureMapping(
    param: AnnotationMainParameter,
    markDataList: MarkAnnotationData[]
  ): Optional<AnnotationTextureMapping> {
    const cache = this.markTextureCache.get(param);
    if (cache) {
      return cache;
    }

    const markTextureDataList: ArrayList<MarkAnnotationTextureData> = ArrayList.empty();
    for (const data of markDataList) {
      if (!markTextureDataList.contains(data.textureData)) {
        markTextureDataList.push(data.textureData);
      }
    }

    if (markTextureDataList.size() > 0 && this.matTextureCache) {
      const createdMapping = this.textureHelper.createMarkTextureByTile(markTextureDataList, this.matTextureCache);
      this.markTextureCache.add(param, createdMapping);
      return createdMapping;
    }

    return undefined;
  }

  /**
   * 国道アイコンのAnnotationObject作成
   * @param dataList 国道アイコンデータリスト
   * @param textureMapping AnnotationTextureMapping
   * @returns AnnotationObjectリスト
   */
  private createMarkAnnotationObjectList(
    dataList: MarkAnnotationData[],
    textureMapping: AnnotationTextureMapping
  ): Array<AnnotationObject<MarkAnnotationData>> {
    const markObjectList: Array<AnnotationObject<MarkAnnotationData>> = [];
    for (const data of dataList) {
      if (!(data.textureData.getCacheKey() in textureMapping.details)) {
        continue;
      }
      const detail = textureMapping.details[data.textureData.getCacheKey()];
      const position = calculateWorldCoordinate(data.latlng);
      const pixelRatio = data.tileSize / 256;
      const clientSize = new Size(data.size.height / pixelRatio, data.size.width / pixelRatio);
      markObjectList.push(new AnnotationObject(data, position, clientSize, detail.uv));
    }
    return markObjectList;
  }

  /**
   * 全Groupの描画更新
   * @param worldCenter 地図中心
   * @returns {void}
   */
  private updateAllObjects(worldCenter: Vector3): void {
    for (const groupObject of this.inDisplayMarkObjects.values()) {
      groupObject.updateAnnotationGroup(worldCenter);
    }
    this.notifyUpdate?.();
  }

  /**
   * 表示中の全国道アイコン注記での間引き
   * @param camera Camera
   * @returns {void}
   */
  private cullAllAnnotationObjects(camera: Camera): void {
    const worldCenter = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);
    const allObjects: Array<AnnotationObject<MarkAnnotationData>> = [];
    for (const group of this.inDisplayMarkObjects.values()) {
      const displayObjects = group.getDisplayingObjectList();
      allObjects.push(...displayObjects);
    }

    this.checkOverlap(allObjects, camera, worldCenter);
    this.cullHelper.clear();
    this.updateAllObjects(worldCenter);

    this.suspendCull = false;
    window.clearTimeout(this.debounceId);
  }

  /**
   * 重なり判定
   * @param objects 重なり判定する全てのAnnotationObject
   * @param camera Camera
   * @param worldCenter 地図中心
   * @returns {void}
   */
  private checkOverlap(
    objects: Array<AnnotationObject<MarkAnnotationData>>,
    camera: Camera,
    worldCenter: Vector3
  ): void {
    for (const object of objects) {
      const topLeft = camera.worldToClient(object.collider.rect.topLeft._subtract(worldCenter));
      const topRight = camera.worldToClient(object.collider.rect.topRight._subtract(worldCenter));
      const bottomLeft = camera.worldToClient(object.collider.rect.bottomLeft._subtract(worldCenter));
      const bottomRight = camera.worldToClient(object.collider.rect.bottomRight._subtract(worldCenter));
      if (!topLeft || !topRight || !bottomLeft || !bottomRight) {
        continue;
      }
      if (this.checkCullSameMark(topLeft, object.data)) {
        object.setVisible(false);
        continue;
      }
      object.setVisible(this.cullHelper.canProvideSpace(topLeft, topRight, bottomLeft, bottomRight, object.data));
    }
  }

  /**
   * 同一国道アイコンの間引き判定
   * @param targetPos 判定対象の位置
   * @param targetData 判定対象のデータ
   * @returns `true`: 間引く, `false`: 間引かない
   */
  private checkCullSameMark(targetPos: Vector2, targetData: MarkAnnotationData): boolean {
    const rbushTree = this.cullHelper.tree.all();
    for (const box of rbushTree) {
      if (!targetData.equals(box.data)) {
        continue;
      }

      // 判定対象と比較対象の直線距離(二乗)
      const length =
        (targetPos.x - box.minX) * (targetPos.x - box.minX) + (targetPos.y - box.maxY) * (targetPos.y - box.maxY);
      if (length < SQ_CULLING_LENGTH) {
        return true;
      }
    }
    return false;
  }

  /**
   * クリア処理
   * @returns {void}
   */
  clear(): void {
    this.matTextureCache = undefined;
    this._isSetMatTexture = false;
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (!this.visible || this.markTextureCache.size() === 0) {
      // 描く情報がなければ更新済み扱い
      return true;
    }

    let setTextureCount = 0;
    for (const [param, group] of this.inDisplayMarkObjects.entries()) {
      if (setTextureCount < MAX_SET_TEXTURE_COUNT && !group.isSetTexture) {
        const textureMapping = this.markTextureCache.get(param);
        if (!textureMapping) {
          continue;
        }
        group.setTexture(textureMapping.texture);
        setTextureCount++;
      }
    }

    let existsUnsetTextureTile = false;
    for (const group of this.inDisplayMarkObjects.values()) {
      if (!group.isSetTexture) {
        existsUnsetTextureTile = true;
        continue;
      }
      group.update(viewMatrix, projectionMatrix);
      group.draw();
    }
    return setTextureCount === 0 && !existsUnsetTextureTile;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return ANNOTATION_LAYER_NAME_MARK;
  }

  /** @override */
  destroy(): void {
    while (this.reuseableMaterials.size > 0) {
      const material = this.reuseableMaterials.dequeue();
      material?.destroy();
    }
    for (const group of this.inDisplayMarkObjects.values()) {
      group.destroy();
    }
    this.inDisplayMarkObjects.clear();
    this.isDestroyed = true;
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {MarkAnnotationLayer, SQ_CULLING_LENGTH};
