import {AltitudeRequester} from '../../common/infra/request/AltitudeRequester';
import {GaiaContext} from '../GaiaContext';
import {AltitudeParameter} from './param/AltitudeParameter';
import {LoaderState, OnFinishRequestFunc} from './AbstractTileLoader';
import {Queue} from '../../common/collection/Queue';
import {LRUCache} from '../../common/collection/LRUCache';
import {AltitudeInfo} from '../../common/infra/response/AltitudeInfo';
import {getUInt, getULong, getUShort} from '../utils/ByteArrayUtil';
import {Optional} from '../../common/types';
import {AltitudeDBHandler} from './db/AltitudeDBHandler';

const PATH_ALTITUDE = 'data/altitude';

const INDEX_DATA_TYPE = 0;
const INDEX_CELL_TYPE = 2;
const INDEX_BASE = 4;
const INDEX_GRADATION = 6;
const INDEX_NUMBER_X = 8;
const INDEX_NUMBER_Y = 10;
const INDEX_MIN_LAT = 12;
const INDEX_MIN_LNG = 20;
const INDEX_MAX_LAT = 28;
const INDEX_MAX_LNG = 36;
const INDEX_GRID_BODY_LENGTH = 44;
const INDEX_GRID_BODY = 48;

/**
 * 標高ローダー
 */
class AltitudeLoader {
  private serverUrl = '';
  private requester: AltitudeRequester;
  private db?: AltitudeDBHandler;

  private requestQueue: Queue<AltitudeParameter>;
  private onFinishRequest: OnFinishRequestFunc;

  private state: LoaderState = 'initialized';

  private requestingTiles: Set<string>;

  private cache: LRUCache<AltitudeParameter, AltitudeInfo>;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest データ読み込み完了通知
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    context
      .getGaIAConfiguration()
      .then((res) => {
        if (!res.server.data) {
          return;
        }
        this.serverUrl = `${res.server.data}v1/${res.productId}`;
        this.setState('ready');
        this.executeRequest();
      })
      .catch(() => {
        // do nothing
      });

    this.requester = new AltitudeRequester((param) => {
      return this.createUrl(param);
    });

    this.db = new AltitudeDBHandler();

    this.requestQueue = new Queue<AltitudeParameter>(150);
    this.onFinishRequest = onFinishRequest;

    this.requestingTiles = new Set();
    this.cache = new LRUCache<AltitudeParameter, AltitudeInfo>(100);
  }

  /**
   * メインリクエストのURLを生成
   * @param param AltitudeParameter
   * @returns リクエストURL
   */
  private createUrl(param: AltitudeParameter): string {
    const {x, y, z} = param;
    return `${this.serverUrl}/${PATH_ALTITUDE}/${z}/${y}/${x}`;
  }

  /**
   * Loaderの状態を判定
   * @param state LoaderState
   * @returns 指定された状態のときにtrue
   */
  private isState(state: LoaderState): boolean {
    return this.state === state;
  }

  /**
   * Loaderの状態を設定
   * @param state LoaderState
   * @returns {void}
   */
  private setState(state: LoaderState): void {
    // TODO: 状態遷移を制御するかどうか
    this.state = state;
  }

  /**
   * キャッシュとリクエストを全てクリアする
   * @returns {void}
   */
  clear(): void {
    this.cache.clear();
    this.requestQueue.clear();
    this.requestingTiles.clear();
  }

  /**
   * リクエストキューをクリア
   * @returns {void}
   */
  clearRequestQueue(): void {
    this.requestQueue.clear();
    this.requestingTiles.clear();
    this.db?.clearMainRequestQueue();
  }

  /**
   * 標高データを取得
   * @param param AltitudeParameter
   * @returns AltitudeInfo
   */
  getAltitudeInfo(param: AltitudeParameter): Optional<AltitudeInfo> {
    return this.cache.get(param);
  }

  /**
   * 描画に必要なタイルをリクエストキューに追加
   * @param paramList AltitudeParameter
   * @returns {void}
   */
  addRequestQueue(paramList: AltitudeParameter[]): void {
    for (const param of paramList) {
      if (this.cache.has(param)) {
        continue;
      }
      this.requestQueue.enqueue(param);
    }
  }

  /**
   * リクエストを実行
   * @returns {void}
   */
  executeRequest(): void {
    if (!this.isState('ready')) {
      return;
    }

    if (this.requestQueue.size === 0) {
      return;
    }

    const dbRequestTiles: AltitudeParameter[] = [];
    while (this.requestQueue.size > 0) {
      const parameter = this.requestQueue.dequeue();
      if (!parameter) {
        break;
      }

      if (this.cache.has(parameter) || this.requestingTiles.has(parameter.getCacheKey())) {
        continue;
      }
      dbRequestTiles.push(parameter);
      this.requestingTiles.add(parameter.getCacheKey());
    }

    for (const requestTile of dbRequestTiles) {
      if (!this.db) {
        this.request(requestTile);
        continue;
      }

      this.db.findMainData(requestTile, (_, tile: AltitudeParameter, data?: Uint8Array) => {
        if (this.isState('destroyed') || !this.requestingTiles.has(tile.getCacheKey())) {
          // リクエスト中に地図が破棄された or リクエスト対象から外れたものはキャッシュしない
          return;
        }

        if (data) {
          const info = this.convertToAltitudeInfo(data);
          if (info) {
            this.cache.add(tile, info);
            this.requestingTiles.delete(tile.getCacheKey());
            this.onFinishRequest();
          }
          return;
        }

        // データがない or ローカルデータが不正の場合は通信でリクエスト
        this.request(requestTile);
      });
    }
  }

  /**
   * 通信でのリクエスト
   * @param param AltitudeParameter
   * @returns {void}
   */
  private request(param: AltitudeParameter): void {
    this.requester.request(param, (tile, data): void => {
      if (this.isState('destroyed') || !this.requestingTiles.has(tile.getCacheKey())) {
        // リクエスト中に地図が破棄された or リクエスト対象から外れたものはキャッシュしない
        return;
      }

      this.db?.insertMainData(tile, data);
      const info = this.convertToAltitudeInfo(data);
      // TODO データ不正の場合無限リクエストの恐れがあるため修正
      if (info) {
        this.cache.add(tile, info);
      }

      this.requestingTiles.delete(tile.getCacheKey());
      this.onFinishRequest();
    });
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.cache.clear();
    this.requestingTiles.clear();
    this.db?.destroy();
    this.setState('destroyed');
  }

  /**
   * AltitudeInfoへの変換
   * @param data バイナリ配列
   * @returns AltitudeInfo
   */
  private convertToAltitudeInfo(data: Uint8Array): AltitudeInfo | null {
    if (data.length < INDEX_GRID_BODY) {
      return null;
    }

    const dataType = getUShort(data, INDEX_DATA_TYPE);
    const cellType = getUShort(data, INDEX_CELL_TYPE);
    const base = getUShort(data, INDEX_BASE);
    const gradation = getUShort(data, INDEX_GRADATION);
    const numberX = getUShort(data, INDEX_NUMBER_X);
    const numberY = getUShort(data, INDEX_NUMBER_Y);
    const minLat = getULong(data, INDEX_MIN_LAT) / 3600000;
    const minLng = getULong(data, INDEX_MIN_LNG) / 3600000;
    const maxLat = getULong(data, INDEX_MAX_LAT) / 3600000;
    const maxLng = getULong(data, INDEX_MAX_LNG) / 3600000;
    const gridBodyLength = getUInt(data, INDEX_GRID_BODY_LENGTH);

    const tail = INDEX_GRID_BODY + gridBodyLength;

    if (data.length < tail) {
      return null;
    }

    const gridBody = data.subarray(INDEX_GRID_BODY, tail);

    return {
      dataType,
      cellType,
      base,
      gradation,
      numberX,
      numberY,
      minLat,
      minLng,
      maxLat,
      maxLng,
      gridBodyLength,
      gridBody,
    };
  }
}

export {AltitudeLoader};
