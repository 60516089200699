import {JsonObject} from '../../../gaia/types';
import {transMillisecToDegree} from '../../../gaia/util';
import {LatLng} from '../../../gaia/value';
import {LRUCache} from '../../common/collection/LRUCache';
import {CacheKey, Optional} from '../../common/types';
import {GaiaContext} from '../GaiaContext';
import {GaIAConfiguration} from '../models/GaIAConfiguration';

type LandmarkMetadataLocation = {
  lat: number;
  lon: number;
};

type LandmarkMetadataBbox = {
  min: LandmarkMetadataLocation;
  max: LandmarkMetadataLocation;
};

type LandmarkMetadataPlacement = {
  location: LandmarkMetadataLocation;
  scale: number;
  rotation: number;
  available: string[];
  bbox: LandmarkMetadataBbox;
};

type LandmarkPlacement = {
  latLng: LatLng;
  scale: number;
  rotation: number;
  available: string[];
  corners: {
    topLeft: LatLng;
    topRight: LatLng;
    bottomLeft: LatLng;
    bottomRight: LatLng;
  };
};

type LandmarkMetadata = {
  serial: string;
  placement: Map<string, LandmarkPlacement>;
};

type OnFinishRequestFunc = () => void;

const METADATA_PATH = 'v1/landmark/meta';
const LANDMARK_PATH = 'v1/landmark/gltf';

/**
 * ランドマーク名
 */
class LandmarkName implements CacheKey {
  private readonly name: string;

  /**
   * コンストラクタ
   * @param name 名前
   */
  constructor(name: string) {
    this.name = name;
  }

  /** @override */
  getCacheKey(): string {
    return this.name;
  }
}

/**
 * 3Dランドマーク用のローダー
 */
class LandmarkLoader {
  private server: string | undefined;

  private metadata: LandmarkMetadata | undefined;
  private requestingMetadata: boolean;

  private readonly cache: LRUCache<LandmarkName, JsonObject>;
  private requestingLandmark: boolean;

  private onFinishRequestFunc: OnFinishRequestFunc;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequestFunc 通信成功時の処理
   */
  constructor(context: GaiaContext, onFinishRequestFunc: OnFinishRequestFunc) {
    this.server = undefined;
    this.metadata = undefined;
    this.requestingMetadata = false;
    this.cache = new LRUCache<LandmarkName, JsonObject>(100);
    this.requestingLandmark = false;
    this.onFinishRequestFunc = onFinishRequestFunc;

    context.getGaIAConfiguration().then((config: GaIAConfiguration) => {
      const dataServer = config.server.data;
      if (!dataServer) {
        return;
      }
      this.server = dataServer;
      this.onFinishRequestFunc();
    });
  }

  /**
   * メタデータを取得
   * @returns メタデータ
   */
  getMetadata(): Optional<LandmarkMetadata> {
    return this.metadata;
  }

  /**
   * ランドマークのデータを取得
   * @param name ランドマーク名
   * @returns ランドマークのglTF(Embedded)
   */
  getLandmark(name: string): Optional<JsonObject> {
    const landmarkName = new LandmarkName(name);
    return this.cache.get(landmarkName);
  }

  /**
   * メタデータを通信で取得する
   * @returns {void}
   */
  requestMetadata(): void {
    // メタデータがすでに取得済みであれば何もしない
    if (this.metadata) {
      return;
    }

    // サーバの向き先が設定されていなければ何もしない
    if (!this.server) {
      return;
    }

    // メタデータを取得するために通信中であればなにもしない
    if (this.requestingMetadata) {
      return;
    }

    // TODO: 無限ダウンロード対策
    this.requestingMetadata = true;
    const url = `${this.server}${METADATA_PATH}`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        this.requestingMetadata = false;
        this.metadata = {
          serial: json.serial,
          placement: new Map(),
        };
        for (const name of Object.keys(json.placement)) {
          const placement: LandmarkMetadataPlacement = json.placement[name];
          const lat = transMillisecToDegree(placement.location.lat);
          const lon = transMillisecToDegree(placement.location.lon);
          const latLng = new LatLng(lat, lon);
          const minLat = transMillisecToDegree(placement.bbox.min.lat);
          const minLon = transMillisecToDegree(placement.bbox.min.lon);
          const maxLat = transMillisecToDegree(placement.bbox.max.lat);
          const maxLon = transMillisecToDegree(placement.bbox.max.lon);
          const topLeft = new LatLng(maxLat, minLon);
          const topRight = new LatLng(maxLat, maxLon);
          const bottomLeft = new LatLng(minLat, minLon);
          const bottomRight = new LatLng(minLat, maxLon);
          const landmarkPlacement: LandmarkPlacement = {
            latLng,
            scale: placement.scale,
            rotation: placement.rotation,
            available: placement.available,
            corners: {
              topLeft,
              topRight,
              bottomLeft,
              bottomRight,
            },
          };
          this.metadata.placement.set(name, landmarkPlacement);
          this.onFinishRequestFunc();
        }
      })
      .catch((_) => {
        this.requestingMetadata = false;
      });
  }

  /**
   * ランドマークのデータを取得する
   * @param name ランドマークの名前
   * @returns {void}
   */
  requestLandmark(name: string): void {
    // メタデータが未取得であれば何もしない
    if (!this.metadata) {
      return;
    }

    // サーバの向き先が設定されていなければ何もしない
    if (!this.server) {
      return;
    }

    // ランドマーク取得中は何もしない
    if (this.requestingLandmark) {
      return;
    }

    // 要求されたランドマークがすでに取得済みの場合は何もしない
    const landmarkName = new LandmarkName(name);
    if (this.cache.has(landmarkName)) {
      return;
    }

    const url = `${this.server}${LANDMARK_PATH}/${name}`;
    this.requestingLandmark = true;
    // TODO: 無限ダウンロード対策
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        this.requestingLandmark = false;
        this.cache.add(landmarkName, json);
        this.onFinishRequestFunc();
      })
      .catch((_) => {
        this.requestingLandmark = false;
      });
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.cache.clear();
  }
}

export {LandmarkLoader, LandmarkMetadata, LandmarkPlacement};
