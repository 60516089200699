import {DEGREE_TO_RADIAN, RADIAN_TO_DEGREE} from '../../../common/math/MathConstants';
import {PolarCoordinate3} from '../../../common/math/PolarCoordinate3';
import {Quaternion} from '../../../common/math/Quaternion';
import {Ray3} from '../../../common/math/Ray3';
import {Vector3} from '../../../common/math/Vector3';
import {Optional} from '../../../common/types';
import {Camera} from '../../../engine/camera/Camera';
import {CustomGeometry} from '../../../engine/geometry/CustomGeometry';
import {TexturePlaneMaterial} from '../../../engine/material/TexturePlaneMaterial';
import {TextAnnotationData} from '../../models/annotation/TextAnnotationData';
import {AbstractAnnotationGroupObject} from './AbstractAnnotationGroupObject';
import {AnnotationObject} from './AnnotationObject';

/**
 * テキスト注記をまとめて描画するオブジェクト
 */
class TextAnnotationGroupObject extends AbstractAnnotationGroupObject<TextAnnotationData> {
  private annotationObjectList: Array<AnnotationObject<TextAnnotationData>>;
  private currentTheta = 0;

  /**
   * コンストラクタ
   * @param material TexturePlaneMaterial
   * @param geometry CustomGeometry
   * @param annotationObjectList AnnotationObjectリスト
   */
  constructor(
    material: TexturePlaneMaterial,
    geometry: CustomGeometry,
    annotationObjectList: Array<AnnotationObject<TextAnnotationData>>
  ) {
    super(material, geometry, annotationObjectList[0].position);
    this.annotationObjectList = annotationObjectList;
  }

  /** @override */
  setTexture(texture: TexImageSource): void {
    this.material.setTexture(
      texture,
      new Map([
        [WebGLRenderingContext.TEXTURE_MIN_FILTER, WebGLRenderingContext.LINEAR],
        [WebGLRenderingContext.TEXTURE_MAG_FILTER, WebGLRenderingContext.LINEAR],
      ])
    );
    this._isSetTexture = true;
  }

  /** @override */
  getDisplayingObjectList(): Array<AnnotationObject<TextAnnotationData>> {
    const displaying: Array<AnnotationObject<TextAnnotationData>> = [];
    for (const obj of this.annotationObjectList) {
      if (obj.isVisible) {
        displaying.push(obj);
      }
    }
    return displaying;
  }

  /** @override */
  updateColliders(zoom: number, polar: PolarCoordinate3): void {
    for (const obj of this.annotationObjectList) {
      if (zoom !== this.currentZoomLevel) {
        obj.updateSize(zoom);
      }

      let rotateZ = 90 * DEGREE_TO_RADIAN + polar.phi;
      if (obj.angle > 0) {
        const textRotation = obj.angle;
        rotateZ = textRotation * DEGREE_TO_RADIAN;

        const mapRotate = 90 + polar.phi * RADIAN_TO_DEGREE;
        const displayAngle = (textRotation - mapRotate) % 360;
        if (displayAngle > 90 && displayAngle < 270) {
          rotateZ = (textRotation - 180) * DEGREE_TO_RADIAN;
        }
      }
      const rotation = Quaternion.identity()._rotateZ(rotateZ)._rotateX(polar.theta);

      const drawArea = this.calculateColliderArea(obj, rotation, zoom);
      obj.collider.setTopLeft(drawArea.topLeft);
      obj.collider.setRight(drawArea.right);
      obj.collider.setDown(drawArea.down);
    }
    this.currentZoomLevel = zoom;
    this.currentTheta = polar.theta;
  }

  /** @override */
  cullInGroup(camera: Camera, cameraTargetPosition: Vector3): void {
    for (const obj of this.annotationObjectList) {
      if (obj.angle > 0 && this.currentTheta * RADIAN_TO_DEGREE >= 1) {
        obj.setVisible(false);
        continue;
      }
      const topLeft = camera.worldToClient(obj.collider.rect.topLeft._subtract(cameraTargetPosition));
      const topRight = camera.worldToClient(obj.collider.rect.topRight._subtract(cameraTargetPosition));
      const bottomLeft = camera.worldToClient(obj.collider.rect.bottomLeft._subtract(cameraTargetPosition));
      const bottomRight = camera.worldToClient(obj.collider.rect.bottomRight._subtract(cameraTargetPosition));
      if (!topLeft || !topRight || !bottomLeft || !bottomRight) {
        continue;
      }
      const isSpace = this.cullHelper.canProvideSpace(topLeft, topRight, bottomLeft, bottomRight, obj.data);
      obj.setVisible(isSpace);
    }
    this.cullHelper.clear();
  }

  /** @override */
  updateAnnotationGroup(cameraTargetPosition: Vector3): void {
    this.setPositionValues(
      this.basePosition.x - cameraTargetPosition.x,
      this.basePosition.y - cameraTargetPosition.y,
      this.basePosition.z - cameraTargetPosition.z
    );

    const vertices: number[] = [];
    const indices: number[] = [];
    for (const obj of this.annotationObjectList) {
      if (!obj.isVisible) {
        continue;
      }
      for (const v of this.calculateVertices(obj.collider.rect, obj.uv, obj.isVisible)) {
        vertices.push(v);
      }
      for (const i of this.calculateIndices(indices.length / 6)) {
        indices.push(i);
      }
      // vertices.push(...this.calculateVertices(obj.collider.rect, obj.uv, obj.isVisible));
      // indices.push(...this.calculateIndices(indices.length / 6));
    }
    this.geometry.setVertices(vertices);
    this.geometry.setIndices(indices);
    this.material.setGeometry(this.geometry);
  }

  /** @override */
  getCollidedAnnotation(ray: Ray3): Optional<AnnotationObject<TextAnnotationData>> {
    for (const obj of this.annotationObjectList) {
      if (obj.isCollided(ray)) {
        return obj;
      }
    }
    return undefined;
  }
}

export {TextAnnotationGroupObject};
