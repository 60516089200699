import {Figure} from './Figure';
import {LatLng, Color} from '../../value';
import {DASH_ARRAY_SOLID} from './Polyline';
import {CircleOptions} from '../../types';

/**
 * 円の表現クラス
 */
class Circle extends Figure {
  /** 中心緯度経度 */
  public readonly center: LatLng;
  /** 半径 */
  public readonly radius: number;
  /** 塗りつぶし色 */
  public readonly fillColor: Color;
  /** 縁線の色 */
  public readonly strokeColor: Color;
  /** 縁線の太さ */
  public readonly strokeWeight: number;
  /** 点線のパターン */
  public readonly strokeDashArray: number[];

  /**
   * コンストラクタ
   * @param options オプション
   */
  constructor(options: CircleOptions) {
    super(options);

    this.center = options.center;
    this.radius = options.radius;

    this.fillColor = options.fillColor ?? Color.black();
    this.strokeDashArray = options.strokeDashArray ?? DASH_ARRAY_SOLID;

    if (!options.strokeColor && !options.strokeWeight) {
      // 縁線は何も指定がなければ表示しない
      this.strokeColor = Color.clear();
      this.strokeWeight = 0;
    } else {
      // 色と太さのどちらかが指定された場合は、何かが表示されるようにデフォルト値を設定する
      this.strokeColor = options.strokeColor ?? Color.black();
      this.strokeWeight = options.strokeWeight ?? 1;
    }
  }
}

export {Circle};
