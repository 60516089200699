import {Material} from './Material';
import {TexturePlaneProgram} from '../program/TexturePlaneProgram';
import {Geometry} from '../geometry/Geometry';
import {Object3D} from '../object/Object3D';
import {mat4} from 'gl-matrix';
import {TextureMapping} from '../program/TextureMapping';
import {RenderTarget} from '../../common/types';

const DEFAULT_TEX_PARAMETER_MAP = new Map([
  [WebGLRenderingContext.TEXTURE_WRAP_S, WebGLRenderingContext.CLAMP_TO_EDGE],
  [WebGLRenderingContext.TEXTURE_WRAP_T, WebGLRenderingContext.CLAMP_TO_EDGE],
]);

/**
 * テクスチャ用のマテリアル
 */
class TexturePlaneMaterial implements Material {
  private readonly context: WebGLRenderingContext;
  readonly program: TexturePlaneProgram;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param isPremultipliedAlpha アルファモードの指定
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry, isPremultipliedAlpha = false) {
    this.context = context;
    this.program = new TexturePlaneProgram(context, geometry, isPremultipliedAlpha);
  }

  /**
   * 更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    this.program.update(object3D, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @param renderTarget 描画対象の設定
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    this.program.draw(renderTarget);
  }

  /**
   * テクスチャを更新
   * @param texture テクスチャ
   * @param texParameterMap テクスチャパラメータ
   * @returns {void}
   */
  setTexture(texture: TexImageSource, texParameterMap: Map<GLenum, GLenum> = DEFAULT_TEX_PARAMETER_MAP): void {
    this.program.setTexture(texture, texParameterMap);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.program.setGeometry(geometry);
  }

  /**
   * テクスチャ情報を更新
   * @param textureMapping テクスチャ情報
   * @returns {void}
   */
  setTextureMapping(textureMapping: TextureMapping): void {
    this.program.setTextureMapping(textureMapping);
  }

  /**
   * テクスチャが少しでも透明かどうか
   * @returns 透明性（少しでも透明であればtrue）
   */
  isTransparent(): boolean {
    return this.program.isTransparent();
  }

  /**
   * テクスチャの透明度を取得
   * @returns テクスチャの透明度
   */
  getTextureTransparency(): number {
    return this.program.getTextureTransparency();
  }

  /**
   * テクスチャの透明度を更新
   * @param alpha 透明度
   * @returns {void}
   */
  setTextureTransparency(alpha: number): void {
    this.program.setTextureTransparency(alpha);
  }

  /**
   * テクスチャを初期化
   * @returns {void}
   */
  initTexture(): void {
    this.program.initTexture();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.program.destroy();
  }
}

export {TexturePlaneMaterial};
