import {Optional} from '../../_private/common/types';
import {GaIAClassName} from '../../_private/common/util/Styles';
import {GaIAEventListenerHelper} from '../../_private/map/event/GaIAEventListenerHelper';
import {MouseEventObserver} from '../../_private/map/event/MouseEventObserver';
import {TouchEventObserver} from '../../_private/map/event/TouchEventObserver';
import {
  GaIAEventListenerOptions,
  MarkerAnimationType,
  MarkerEventMap,
  MarkerGravity,
  MarkerInitOptions,
  MarkerLabelOptions,
  MarkerProperties,
} from '../types';
import {LatLng, Size} from '../value';
import {GaIAError} from '../value/GaIAError';
import {GaIAEventEmitter} from '../value/interface/GaIAEventEmitter';
import {DOMObject} from './DOMObject';

/**
 * Markerクラス
 *
 * ```javascript
 * const marker = new GIA.object.Marker({
 *   icon: '/path/to/image',
 *   position: new GIA.value.LatLng(35.681109, 139.767165)
 * });
 *
 * map.addMarker(marker);
 * ```
 */
class Marker extends DOMObject implements GaIAEventEmitter {
  private readonly name?: string;
  private readonly userOffsetX: number;
  private readonly userOffsetY: number;

  private markerSize?: Size;

  private gravity: MarkerGravity;
  private draggable: boolean;

  private readonly animation?: MarkerAnimationType;

  private readonly helper: GaIAEventListenerHelper<MarkerEventMap>;
  private readonly mouseObserver: MouseEventObserver;
  private readonly touchObserver: TouchEventObserver;
  private isTouchDragging: boolean;

  private onMarkerDraggingListener?: (ev: MouseEvent) => void;
  private onMarkerDragEndListener?: (ev: MouseEvent) => void;

  private onMarkerTouchDraggingListener?: (ev: TouchEvent) => void;
  private onMarkerTouchDragEndListener?: (ev: TouchEvent) => void;

  private iconPath: string;
  private label?: HTMLElement;

  private properties: MarkerProperties | undefined;

  static touched = false;

  /**
   * コンストラクタ
   * @param options 初期化オプション
   */
  constructor(options: MarkerInitOptions) {
    const optionsClassList = (options.className ?? '').split(' ');
    super(options.position, {
      classList: [GaIAClassName.Object.Marker.Base, ...optionsClassList],
    });

    if (options.zIndex !== null && options.zIndex !== undefined) {
      super.setZIndex(options.zIndex);
    }
    this.setContent(this.createIconImgElement(options));
    this.name = options.name;
    this.iconPath = options.icon;
    this.userOffsetX = options.offset?.x ?? 0;
    this.userOffsetY = options.offset?.y ?? 0;
    this.gravity = options.gravity ?? 'bottom';
    this.draggable = options.draggable ?? false;
    this.animation = options.animation;

    this.helper = new GaIAEventListenerHelper<MarkerEventMap>();
    super.addEventListenerToWrapper('click', (ev) => {
      this.helper.trigger('click', {
        sourceObject: this,
        native: ev,
      });
    });

    if (options.label) {
      this.setupLabel(options.label);
    }

    this.mouseObserver = this.initMouseObserver();
    this.touchObserver = this.initTouchObserver();
    this.isTouchDragging = false;

    this.properties = undefined;
  }

  /**
   * アイコン画像Img要素の生成
   * @param options 初期化オプション
   * @returns IMG要素
   */
  private createIconImgElement(options: MarkerInitOptions): HTMLImageElement {
    const img = document.createElement('img');
    img.style.visibility = 'hidden';
    img.draggable = false;
    img.alt = options.name ?? '';
    img.title = options.name ?? '';
    img.addEventListener(
      'load',
      () => {
        this.markerSize = options.size ?? new Size(img.height, img.width);
        const visible = super.isVisible();
        super.setVisible(true);
        super.updateDrawPosition();
        this.hideAnimation();
        this.showAnimation();
        if (!visible) {
          super.setVisible(false);
        }
        if (options.size) {
          img.style.height = `${options.size.height}px`;
          img.style.width = `${options.size.width}px`;
          super.setWrapperStyle({
            height: `${options.size.height}px`,
            width: `${options.size.width}px`,
          });
        }
        img.style.visibility = '';
      },
      {once: true}
    );
    img.src = options.icon;
    return img;
  }

  /**
   * マーカー上に表示するラベルの設定
   * @param labelOptions ラベルオプション
   * @returns {void}
   */
  private setupLabel(labelOptions: MarkerLabelOptions): void {
    const label = document.createElement('span');
    label.classList.add(GaIAClassName.Object.Marker.Label);
    label.textContent = labelOptions.content;

    if (labelOptions.offset) {
      label.style.transform = `translate(${labelOptions.offset.x}px, ${labelOptions.offset.y}px)`;
    }
    const style = labelOptions.style ?? {};
    const {fontSize, fontWeight, color, backgroundColor, border, padding, textShadow} = style;
    label.style.fontSize = fontSize ?? '';
    label.style.fontWeight = fontWeight ?? '';
    label.style.color = color ?? '';
    label.style.backgroundColor = backgroundColor ?? '';
    label.style.border = border ?? '';
    label.style.padding = padding ?? '';
    label.style.textShadow = textShadow ?? '';
    this.label = label;
    super.addElement(label);
  }

  /**
   * イベントオブザーバー初期化
   * @returns MouseEventObserver
   */
  private initMouseObserver(): MouseEventObserver {
    const observer = new MouseEventObserver(this.getWrapElement());
    observer.addEventListener('drag', (ev) => this.handleDragging(ev));
    observer.addEventListener('dragend', (ev) => this.handleDragEnd(ev));
    return observer;
  }

  /**
   * タッチイベントオブザーバー初期化
   * @returns TouchEventObserver
   */
  private initTouchObserver(): TouchEventObserver {
    const observer = new TouchEventObserver(this.getWrapElement());
    observer.addEventListener('touchmove', (ev) => this.handleTouchDragging(ev));
    observer.addEventListener('touchend', (ev) => this.handleTouchDragEnd(ev));
    return observer;
  }

  /** @override */
  protected getOffsetX(): number {
    const width = this.markerSize?.width ?? 0;
    let offset = 0;
    switch (this.gravity) {
      case 'top-left':
      case 'left':
      case 'bottom-left':
        break;
      case 'top':
      case 'center':
      case 'bottom':
        offset = -(width / 2);
        break;
      case 'top-right':
      case 'right':
      case 'bottom-right':
        offset = -width;
        break;
      default:
        throw new GaIAError('Illegal value: MarkerInitOptions#tail');
    }
    return offset + this.userOffsetX;
  }

  /** @override */
  protected getOffsetY(): number {
    const height = this.markerSize?.height ?? 0;
    let offset = 0;
    switch (this.gravity) {
      case 'top-left':
      case 'top':
      case 'top-right':
        break;
      case 'left':
      case 'center':
      case 'right':
        offset = -(height / 2);
        break;
      case 'bottom-left':
      case 'bottom':
      case 'bottom-right':
        offset = -height;
        break;
      default:
        throw new GaIAError('Illegal value: MarkerInitOptions#tail');
    }
    return offset + this.userOffsetY;
  }

  /**
   * プロパティを取得
   * @returns プロパティ
   */
  getProperties(): MarkerProperties | undefined {
    return this.properties;
  }

  /**
   * プロパティを設定
   * @param properties プロパティ
   * @returns {void}
   */
  setProperties(properties: MarkerProperties | undefined): void {
    this.properties = properties;
  }

  /** @override */
  setPosition(position: LatLng): void {
    super.setPosition(position);
    this.helper.trigger('position_changed', {
      sourceObject: this,
    });
  }

  /**
   * ドラッグ操作に関するイベントリスナーを設定
   * @ignore
   * @param dragging ドラッグ操作中リスナー
   * @param dragend ドラッグ操作終了リスナー
   * @returns {void}
   */
  setOnDragListener(dragging?: (ev: MouseEvent) => void, dragend?: (ev: MouseEvent) => void): void {
    this.onMarkerDraggingListener = dragging;
    this.onMarkerDragEndListener = dragend;
  }

  /**
   * モバイル端末用のドラッグ操作に関するイベントリスナーを設定
   * @param dragging ドラッグ操作中リスナー
   * @param dragend ドラッグ操作終了リスナー
   * @returns {void}
   */
  setOnTouchDragListener(dragging?: (ev: TouchEvent) => void, dragend?: (ev: TouchEvent) => void): void {
    this.onMarkerTouchDraggingListener = dragging;
    this.onMarkerTouchDragEndListener = dragend;
  }

  /**
   * マーカーのドラッグ有効化設定
   * @param draggable `true` : 有効, `false` : 無効
   * @returns {void}
   */
  setDraggable(draggable: boolean): void {
    this.draggable = draggable;
  }

  /**
   * 新しくラベルを設定する
   * @param label ラベルのオプション
   * @returns {void}
   */
  setLabel(label: MarkerLabelOptions): void {
    if (this.label) {
      super.removeElement(this.label);
    }
    this.setupLabel(label);
  }

  /**
   * アイコン画像をセットする<br>
   * @param path アイコン画像パス(base64文字列でも可)
   * @param size サイズ指定
   * @returns {void}
   */
  setIconImage(path: string, size?: Size): void {
    if (!path || this.iconPath === path) {
      return;
    }
    const img = document.createElement('img');
    img.style.visibility = 'hidden';
    img.draggable = false;
    img.alt = this.name ?? '';
    img.title = this.name ?? '';
    img.addEventListener(
      'load',
      () => {
        this.markerSize = size ?? new Size(img.height, img.width);
        const visible = super.isVisible();
        super.setVisible(true);
        super.updateDrawPosition();
        super.setVisible(visible);
        if (size) {
          img.style.height = `${size.height}px`;
          img.style.width = `${size.width}px`;
          super.setWrapperStyle({
            height: `${size.height}px`,
            width: `${size.width}px`,
          });
        } else {
          super.setWrapperStyle({height: '', width: ''});
        }
        img.style.visibility = '';
      },
      {once: true}
    );
    img.src = path;
    this.iconPath = path;
    super.setContent(img);
    if (this.label) {
      super.addElement(this.label);
    }
  }

  /**
   * Gravityを設定
   * @param gravity MarkerGravity
   * @returns {void}
   */
  setGravity(gravity: MarkerGravity): void {
    if (this.gravity === gravity) {
      return;
    }
    this.gravity = gravity;
    this.updateDrawPosition();
  }

  /**
   * 識別用名称を取得
   * @returns 識別用名称
   */
  getName(): Optional<string> {
    return this.name;
  }

  /**
   * Markerを表示
   * @returns {void}
   */
  show(): void {
    super.setVisible(true);
    this.showAnimation();
    this.helper.trigger('appear', {
      sourceObject: this,
    });
  }

  /**
   * Markerを非表示
   * @returns {void}
   */
  hide(): void {
    super.setVisible(false);
    this.hideAnimation();
    this.helper.trigger('disappear', {
      sourceObject: this,
    });
  }

  /**
   * マーカーの表示状態を取得
   * @returns `true`:表示, `false`: 非表示
   */
  isVisible(): boolean {
    return super.isVisible();
  }

  /** @override */
  addEventListener<K extends keyof MarkerEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: MarkerEventMap[K]) => any,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.addListener(eventName, func, options);
    if (this.helper.getRegisteredListenerCount('click') > 0) {
      super.setWrapperStyle({cursor: 'pointer'});
    }
  }

  /** @override */
  removeEventListener<K extends keyof MarkerEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: MarkerEventMap[K]) => any,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.removeListener(eventName, func, options);
    if (this.helper.getRegisteredListenerCount('click') === 0) {
      super.setWrapperStyle({cursor: 'inherit'});
    }
  }

  /**
   * ドラッグ操作中の処理
   * @param ev MouseEvent
   * @returns {void}
   */
  private handleDragging(ev: MouseEvent): void {
    if (!this.draggable) {
      return;
    }
    this.onMarkerDraggingListener?.(ev);
  }

  /**
   * ドラッグ操作終了時の処理
   * @param ev MouseEvent
   * @returns {void}
   */
  private handleDragEnd(ev: MouseEvent): void {
    if (!this.draggable) {
      return;
    }
    this.onMarkerDragEndListener?.(ev);
  }

  /**
   * モバイル端末用のドラッグ操作中の処理
   * @param ev TouchEvent
   * @returns {void}
   */
  private handleTouchDragging(ev: TouchEvent): void {
    if (!this.draggable) {
      return;
    }
    this.onMarkerTouchDraggingListener?.(ev);
  }

  /**
   * モバイル端末用のドラッグ操作終了時の処理
   * @param ev TouchEvent
   * @returns {void}
   */
  private handleTouchDragEnd(ev: TouchEvent): void {
    if (!this.draggable) {
      return;
    }
    this.onMarkerTouchDragEndListener?.(ev);
    Marker.touched = true;
  }

  /**
   * マーカー表示時のアニメーション設定
   * @returns {void}
   */
  private showAnimation(): void {
    if (!this.animation) {
      return;
    }

    const SET_ANIMATION_DURATION = 20;
    const DELETE_ANIMATION_DURATION = 400;
    if (this.animation === 'fadeIn') {
      setTimeout(() => {
        this.setWrapperStyle({
          transition: 'opacity .4s',
          opacity: '1',
        });
        setTimeout(() => {
          this.setWrapperStyle({transition: ''});
        }, DELETE_ANIMATION_DURATION);
      }, SET_ANIMATION_DURATION);
    } else if (this.animation === 'drop') {
      const drawPosition = this.getDrawPosition();
      const x = drawPosition.x + this.getOffsetX();
      const y = drawPosition.y + this.getOffsetY();
      setTimeout(() => {
        this.setWrapperStyle({
          transition: 'transform .4s 0s linear',
          transform: `translate(${x}px, ${y}px)`,
        });
        setTimeout(() => {
          this.setWrapperStyle({transition: ''});
        }, DELETE_ANIMATION_DURATION);
      }, SET_ANIMATION_DURATION);
    }
  }

  /**
   * マーカー非表示時のアニメーション設定
   * @returns {void}
   */
  private hideAnimation(): void {
    if (!this.animation) {
      return;
    }

    if (this.animation === 'fadeIn') {
      this.setWrapperStyle({opacity: '0'});
    } else if (this.animation === 'drop') {
      const drawPosition = this.getDrawPosition();
      const x = drawPosition.x + this.getOffsetX();
      const y = drawPosition.y + this.getOffsetY();
      this.setWrapperStyle({transform: `translate(${x}px, ${y - 50}px)`});
    }
  }
}

export {Marker};
