import {TileNumber} from '../../models/TileNumber';

/**
 * 任意タイルのパラメータクラス
 */
class AdditionTileParameter extends TileNumber {
  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   */
  constructor(x: number, y: number, z: number) {
    super(x, y, z);

    this._cacheKey = `ADD(${this.x},${this.y},${this.z})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {AdditionTileParameter};
