import {JsonObject} from '../../../../gaia/types';
import {Point, Size} from '../../../../gaia/value';
import {ValueObject} from '../../../../gaia/value/interface/ValueObject';
import {PaletteColorInfo, PaletteFontInfo} from '../../../common/infra/response/AnnotationInfo';
import {TextAnnotationTextureData} from './TextAnnotationTextureData';

/**
 * 国道アイコンテクスチャ作成用データ
 */
class MarkAnnotationTextureData extends TextAnnotationTextureData implements ValueObject {
  readonly cropStart: Point;
  readonly cropEnd: Point;
  readonly imageSize: Size;

  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param appearance 表示テキスト
   * @param size テキストサイズ
   * @param color テキストの色
   * @param font フォント
   * @param ntjCode NTJコード
   * @param zoom ズームレベル
   * @param cropStart 座布団テクスチャ切り取り開始位置(左上)
   * @param cropEnd 座布団テクスチャ切り取り終了位置(右下)
   * @param imageSize テクスチャ切り取り後の画像サイズ
   */
  constructor(
    appearance: string,
    size: number,
    color: PaletteColorInfo,
    font: PaletteFontInfo,
    ntjCode: number,
    zoom: number,
    cropStart: Point,
    cropEnd: Point,
    imageSize: Size
  ) {
    super(appearance, true, size, color, {r: 255, g: 255, b: 255, a: 0}, font, 'bordered-text', ntjCode, zoom);
    this.cropStart = cropStart;
    this.cropEnd = cropEnd;
    this.imageSize = imageSize;

    this._cacheKey = `MT${appearance}, ${ntjCode}`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof MarkAnnotationTextureData)) {
      return false;
    }
    const other = obj as MarkAnnotationTextureData;
    // 国道アイコンの場合は道路番号とNTJコードが一致すれば同じとみなす
    return other.appearance === this.appearance && other.ntjCode === this.ntjCode;
  }

  /** @override */
  clone(): MarkAnnotationTextureData {
    return new MarkAnnotationTextureData(
      this.appearance,
      this.size,
      this.color,
      this.font,
      this.ntjCode,
      this.zoom,
      this.cropStart,
      this.cropEnd,
      this.imageSize
    );
  }

  /** @override */
  toObject(): JsonObject {
    return {
      appearance: this.appearance,
      size: this.size,
      color: this.color,
      font: this.font,
      ntjCode: this.ntjCode,
      zoom: this.zoom,
      cropStart: this.cropStart.toObject(),
      cropEnd: this.cropEnd.toObject(),
      imageSize: this.imageSize.toObject(),
    };
  }
}

export {MarkAnnotationTextureData};
