import {JsonObject, Language, TileSize} from '../../../../gaia/types';
import {LatLng} from '../../../../gaia/value';
import {ValueObject} from '../../../../gaia/value/interface/ValueObject';

type LocationObject = {
  latMillisec: number;
  lngMillisec: number;
};

type RemoteIslandObject = {
  min: LocationObject;
  max: LocationObject;
  offset: LocationObject;
};

const REMOTE_ISLANDS: RemoteIslandObject[] = [
  // 硫黄島
  {
    min: {
      latMillisec: 88784041,
      lngMillisec: 508510199,
    },
    max: {
      latMillisec: 89384113,
      lngMillisec: 508960208,
    },
    offset: {
      latMillisec: 25254,
      lngMillisec: -5660,
    },
  },
  // 北大東島
  {
    min: {
      latMillisec: 93285151,
      lngMillisec: 472507492,
    },
    max: {
      latMillisec: 93585191,
      lngMillisec: 472957426,
    },
    offset: {
      latMillisec: 12077,
      lngMillisec: -18830,
    },
  },
  // 南大東島
  {
    min: {
      latMillisec: 92685087,
      lngMillisec: 472057351,
    },
    max: {
      latMillisec: 93285166,
      lngMillisec: 472957398,
    },
    offset: {
      latMillisec: 12077,
      lngMillisec: -18830,
    },
  },
  // 多良間島・水納島
  {
    min: {
      latMillisec: 88485054,
      lngMillisec: 448655228,
    },
    max: {
      latMillisec: 89385158,
      lngMillisec: 449105224,
    },
    offset: {
      latMillisec: 9152,
      lngMillisec: -17206,
    },
  },
  // 与那国島
  {
    min: {
      latMillisec: 87885092,
      lngMillisec: 442354650,
    },
    max: {
      latMillisec: 88185140,
      lngMillisec: 443254711,
    },
    offset: {
      latMillisec: -4788,
      lngMillisec: -7088,
    },
  },
  // 青ヶ島
  {
    min: {
      latMillisec: 116687119,
      lngMillisec: 503111021,
    },
    max: {
      latMillisec: 116987159,
      lngMillisec: 503561045,
    },
    offset: {
      latMillisec: -2440,
      lngMillisec: -139,
    },
  },
  // 宮古諸島(北部)
  {
    min: {
      latMillisec: 89385111,
      lngMillisec: 450455405,
    },
    max: {
      latMillisec: 89985191,
      lngMillisec: 451355453,
    },
    offset: {
      latMillisec: 1839,
      lngMillisec: -2410,
    },
  },
  // 宮古諸島(南部)
  {
    min: {
      latMillisec: 88785039,
      lngMillisec: 450455378,
    },
    max: {
      latMillisec: 89385127,
      lngMillisec: 451805462,
    },
    offset: {
      latMillisec: 1839,
      lngMillisec: -2410,
    },
  },
  // 石垣島・竹富島①
  {
    min: {
      latMillisec: 88485077,
      lngMillisec: 447305089,
    },
    max: {
      latMillisec: 88785117,
      lngMillisec: 447755112,
    },
    offset: {
      latMillisec: -4700,
      lngMillisec: -7300,
    },
  },
  // 石垣島・竹富島②
  {
    min: {
      latMillisec: 88185045,
      lngMillisec: 446855037,
    },
    max: {
      latMillisec: 88485093,
      lngMillisec: 447755098,
    },
    offset: {
      latMillisec: -4700,
      lngMillisec: -7300,
    },
  },
  // 石垣島・竹富島③
  {
    min: {
      latMillisec: 87885013,
      lngMillisec: 446404986,
    },
    max: {
      latMillisec: 88185069,
      lngMillisec: 447755084,
    },
    offset: {
      latMillisec: -4700,
      lngMillisec: -7300,
    },
  },
  // 石垣島・竹富島④
  {
    min: {
      latMillisec: 87584981,
      lngMillisec: 446625000,
    },
    max: {
      latMillisec: 87885037,
      lngMillisec: 447755071,
    },
    offset: {
      latMillisec: -4700,
      lngMillisec: -7300,
    },
  },
  // 石垣島・竹富島⑤
  {
    min: {
      latMillisec: 87284957,
      lngMillisec: 446625000,
    },
    max: {
      latMillisec: 87585005,
      lngMillisec: 447305019,
    },
    offset: {
      latMillisec: -4700,
      lngMillisec: -7300,
    },
  },
];

/**
 * 離島補正対象の緯度経度の場合のみ、離島補正を行う
 * @param latLng 緯度経度
 * @returns 離島補正済みの緯度経度
 */
const toCorrectLatLngIfNeeded = (latLng: LatLng): LatLng => {
  for (let index = 0; index < REMOTE_ISLANDS.length; index++) {
    const remoteIsland = REMOTE_ISLANDS[index];
    if (
      remoteIsland.min.latMillisec <= latLng.latMillisec &&
      latLng.latMillisec <= remoteIsland.max.latMillisec &&
      remoteIsland.min.lngMillisec <= latLng.lngMillisec &&
      latLng.lngMillisec <= remoteIsland.max.lngMillisec
    ) {
      return new LatLng(
        latLng.lat + remoteIsland.offset.latMillisec / 3600000,
        latLng.lng + remoteIsland.offset.lngMillisec / 3600000
      );
    }
  }

  // 補正の必要がない場合は、受け取った緯度経度をそのまま返す
  return latLng.clone();
};

/**
 * 注記描画用データの抽象クラス
 */
abstract class AbstractAnnotationData implements ValueObject {
  readonly lang: Language;
  readonly tileSize: TileSize;
  readonly priority: number;
  readonly ntjCode: number;
  readonly latlng: LatLng;

  /**
   * コンストラクタ
   * @param lang 言語
   * @param tileSize タイルサイズ
   * @param priority 描画優先度
   * @param ntjCode NTJコード
   * @param latlng 緯度経度
   */
  protected constructor(lang: Language, tileSize: TileSize, priority: number, ntjCode: number, latlng: LatLng) {
    this.lang = lang;
    this.tileSize = tileSize;
    this.priority = priority;
    this.ntjCode = ntjCode;
    this.latlng = toCorrectLatLngIfNeeded(latlng);
  }

  /** @override */
  abstract equals(obj: unknown): boolean;
  /** @override */
  abstract clone(): ValueObject;
  /** @override */
  abstract toObject(): JsonObject;
}

export {AbstractAnnotationData};
