import {QueryParameter} from '../../common/types';
import {buildQueryString} from '../../common/util/URL';
import {GaiaContext} from '../GaiaContext';
import {AbstractTileLoader, OnFinishRequestFunc} from './AbstractTileLoader';
import {OrbitTileParameter} from './param/OrbitTileParameter';

/**
 * 日付のクエリパラメータに変換
 * @param date Dateオブジェクト
 * @returns 日付文字列(yyyy-MM-dd'T'HH:mm:ss)
 */
const toTimeParamString = (date: Date): string => {
  const year = `${date.getFullYear()}`;
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const hour = `0${date.getHours()}`.slice(-2);
  const minute = `0${date.getMinutes()}`.slice(-2);
  const second = `0${date.getSeconds()}`.slice(-2);

  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

/**
 * 軌跡(moon)情報タイルローダー
 */
class OrbitTileLoader extends AbstractTileLoader<OrbitTileParameter> {
  private serverUrl = '';
  private productId = '';

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );

    context
      .getGaIAConfiguration()
      .then((res) => {
        this.serverUrl = `${res.server.tile}map/${res.productId}`;
        this.productId = res.productId;
        this.setState('ready');
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * タイルのURLを生成
   * @param tile OrbitTileParameter
   * @returns リクエストURL
   */
  private createUrl(tile: OrbitTileParameter): string {
    const {x, y, z, tileSize, palette, condition, annotation} = tile;

    const query: QueryParameter = {
      product: 'web',
      lang: palette.language,
    };
    if (!annotation) {
      query.annotation = 'false';
    }
    if (palette.name) {
      query['palette-name'] = palette.name;
    }
    if (condition.openedRoad !== undefined) {
      query['open-road'] = `${condition.openedRoad}`;
    }
    if (condition.baseDate) {
      query['base-date'] = toTimeParamString(condition.baseDate);
    }

    const urlBase = tile.condition.server ? `${tile.condition.server}map/${this.productId}` : this.serverUrl;
    return `${urlBase}/tile/orbit/${tileSize}/${z}/${y}/${x}?${buildQueryString(query)}`;
  }
}

export {OrbitTileLoader};
