import {Size} from '../value';
import {DOMObject} from './DOMObject';
import {calculateElementSize} from '../../_private/common/util/DOM';
import {GaIAError} from '../value/GaIAError';
import {GaIAEventEmitter} from '../value/interface/GaIAEventEmitter';
import {GaIAEventListenerOptions} from '../types';
import {GaIAEventListenerHelper} from '../../_private/map/event/GaIAEventListenerHelper';
import {Optional} from '../../_private/common/types';
import {GaIAClassName, INFO_WINDOW_TAIL_SIZE} from '../../_private/common/util/Styles';

import {InfoWindowInitOptions, InfoWindowTailPosition, InfoWindowEventMap} from '../types';

/**
 * InfoWindowクラス
 *
 * ```javascript
 * const infoWindow = new GIA.object.InfoWindow({
 *   content: 'Info Window',
 *   position: GIA.value.LatLng(35.681109, 139.767165)
 * });
 *
 * map.addInfoWindow(infoWindow);
 * ```
 */
class InfoWindow extends DOMObject implements GaIAEventEmitter {
  private infoWindowSize: Size = new Size(0, 0);

  private readonly name?: string;
  private readonly tailPosition: InfoWindowTailPosition;
  private readonly userOffsetX: number;
  private readonly userOffsetY: number;

  private readonly helper: GaIAEventListenerHelper<InfoWindowEventMap>;

  /**
   * コンストラクタ
   * @param options 初期化オプション
   */
  constructor(options: InfoWindowInitOptions) {
    super(options.position, {
      content: options.content,
      classList: [GaIAClassName.Object.InfoWindow.Base, ...(options.className ?? '').split(' ')],
    });

    // 画像要素読み込み後の要素サイズ変化に対応する
    const images = this.getWrapElement().querySelectorAll('img');
    images.forEach((img) => {
      img.onload = (): void => {
        super.setWrapperStyle({
          height: '',
          width: '',
        });
        this.updateInfoWindowSize();
        this.updateDrawPosition();
      };
    });

    this.updateInfoWindowSize();

    if (options.zIndex !== null && options.zIndex !== undefined) {
      super.setZIndex(options.zIndex);
    }
    this.name = options.name;
    this.tailPosition = options.tail ?? 'bottom';
    this.userOffsetX = options.offset?.x ?? 0;
    this.userOffsetY = options.offset?.y ?? 0;

    this.setStyle(options);

    this.createTail();

    const showClose = options.showClose ?? true;
    if (showClose) {
      this.createCloseBtn();
    }

    this.setVisible(options.visible ?? true);

    this.helper = new GaIAEventListenerHelper<InfoWindowEventMap>();
    super.addEventListenerToWrapper('click', (ev) => {
      this.helper.trigger('click', {
        sourceObject: this,
        native: ev,
      });
    });
  }

  /**
   * InfoWindowのスタイル適用
   * @param options 初期化オプション
   * @returns {void}
   */
  private setStyle(options?: InfoWindowInitOptions): void {
    if (options?.maxWidth) {
      const maxWidth = typeof options.maxWidth === 'number' ? `${options.maxWidth}px` : options.maxWidth;
      this.setWrapperStyle({maxWidth});
    }
  }

  /**
   * DOMサイズを再計算してInfoWindowの大きさを決定する
   * @returns {void}
   */
  private updateInfoWindowSize(): void {
    const content = this.getWrapElement();
    if (!content) {
      return;
    }
    this.infoWindowSize = calculateElementSize(content);

    // 要素のpaddingを確認する
    const visibility = content.style.visibility;
    content.style.visibility = 'hidden';
    const alreadyExists = document.body.contains(content);
    if (!alreadyExists) {
      document.body.appendChild(content);
    }
    const computed = window.getComputedStyle(content);
    const paddingTop = Math.ceil(parseFloat(computed.getPropertyValue('padding-top') || '0'));
    const paddingBottom = Math.ceil(parseFloat(computed.getPropertyValue('padding-bottom') || '0'));
    const paddingLeft = Math.ceil(parseFloat(computed.getPropertyValue('padding-left') || '0'));
    const paddingRight = Math.ceil(parseFloat(computed.getPropertyValue('padding-right') || '0'));
    if (!alreadyExists) {
      document.body.removeChild(content);
    }
    content.style.visibility = visibility;

    super.setWrapperStyle({
      // padding分引く
      height: `${this.infoWindowSize.height - paddingTop - paddingBottom}px`,
      width: `${this.infoWindowSize.width - paddingLeft - paddingRight}px`,
    });
  }

  /**
   * 吹き出しを生成
   * @returns {void}
   */
  private createTail(): void {
    if (this.tailPosition === 'none') {
      return;
    }

    const tail = document.createElement('div');
    tail.classList.add(GaIAClassName.Object.InfoWindow.Tail);
    tail.classList.add(`${GaIAClassName.Object.InfoWindow.Tail}--${this.tailPosition}`);

    super.addElement(tail);
  }

  /**
   * 「閉じる」ボタンを生成
   * @returns {void}
   */
  private createCloseBtn(): void {
    const button = document.createElement('button');
    button.classList.add(GaIAClassName.Object.InfoWindow.Close);

    button.textContent = '×';
    button.setAttribute('aria-label', 'close');

    button.addEventListener('click', () => this.close());

    super.addElement(button);
  }

  /** @override */
  protected getOffsetX(): number {
    let offset = 0;
    switch (this.tailPosition) {
      case 'top':
      case 'bottom':
      case 'none':
        offset = -(this.infoWindowSize.width / 2);
        break;
      case 'left':
        offset = INFO_WINDOW_TAIL_SIZE * 2;
        break;
      case 'right':
        offset = -(this.infoWindowSize.width + INFO_WINDOW_TAIL_SIZE * 2);
        break;
      default:
        throw new GaIAError('Illegal value: InfoWindowInitOptions#tail');
    }
    return offset + this.userOffsetX;
  }

  /** @override */
  protected getOffsetY(): number {
    let offset = 0;
    switch (this.tailPosition) {
      case 'left':
      case 'right':
      case 'none':
        offset = -(this.infoWindowSize.height / 2);
        break;
      case 'top':
        offset = INFO_WINDOW_TAIL_SIZE * 2;
        break;
      case 'bottom':
        offset = -(this.infoWindowSize.height + INFO_WINDOW_TAIL_SIZE * 2);
        break;
      default:
        throw new GaIAError('Illegal value: InfoWindowInitOptions#tail');
    }
    return offset + this.userOffsetY;
  }

  /**
   * 識別用名称を取得
   * @returns 識別用名称
   */
  getName(): Optional<string> {
    return this.name;
  }

  /**
   * InfoWindowを表示
   * @returns {void}
   */
  open(): void {
    super.setVisible(true);
    this.helper.trigger('open', {
      sourceObject: this,
    });
  }

  /**
   * InfoWindowを非表示
   * @returns {void}
   */
  close(): void {
    super.setVisible(false);
    this.helper.trigger('close', {
      sourceObject: this,
    });
  }

  /**
   * 表示/非表示を切り替え
   * @returns {void}
   */
  toggle(): void {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * InfoWindowの表示状態を取得
   * @returns `true`:表示, `false`: 非表示
   */
  isOpen(): boolean {
    return this.isVisible();
  }

  /** @override */
  addEventListener<K extends keyof InfoWindowEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: InfoWindowEventMap[K]) => any,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.addListener(eventName, func, options);
  }

  /** @override */
  removeEventListener<K extends keyof InfoWindowEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: InfoWindowEventMap[K]) => any,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.removeListener(eventName, func, options);
  }
}

export {InfoWindow};
