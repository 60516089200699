/* eslint-disable @typescript-eslint/no-explicit-any */

/** 緯度経度(レスポンス用) */
type TrainRouteLocation = {
  /** 緯度 */
  lat: number;
  /** 軽度 */
  lon: number;
};

/** 鉄道路線図情報 */
export type TrainRouteInfo = {
  /** 路線情報リスト */
  features: TrainRouteFeature[];
};

/** 形状の線分1本あたりの情報 */
export type TrainRouteFeature = {
  /** ジオメトリ */
  geometry: TrainRouteGeometry;
  /** プロパティ */
  properties: TrainRouteProperty;
};

/** ジオメトリ */
export type TrainRouteGeometry = {
  /** 種別 */
  type: string;
  /** 緯度経度 */
  locations: TrainRouteLocation[];
};

/** プロパティ */
export type TrainRouteProperty = {
  /** 会社ID */
  companyId?: string;
  /** 会社名 */
  companyName?: string;
  /** 実路線ID */
  rellineId?: string;
  /** 実路線名 */
  rellineName?: string;
  /** 実路線色 */
  rellineColor?: string;
  /** 発ノードID */
  depatureNodeId?: string;
  /** 着ノードID */
  arrivalNodeId?: string;
  /** 路線分割番号 */
  courseSeqNo?: string;
};

/**
 * TrainRouteLocationのType Guard
 * @param item 判定対象
 * @returns TrainRouteLocationか否か
 */
export const isTrainRouteLocation = (item: any): item is TrainRouteLocation => {
  return item.lat !== undefined && item.lon !== undefined;
};

/**
 * TrainRouteGeometryのType Guard
 * @param item 判定対象
 * @returns TrainRouteGeometryか否か
 */
export const isTrainRouteGeometry = (item: any): item is TrainRouteGeometry => {
  return item.type !== undefined && item.locations !== undefined;
};

/**
 * TrainRouteFeatureのType Guard
 * @param item 判定対象
 * @returns TrainRouteFeatureか否か
 */
export const isTrainRouteFeature = (item: any): item is TrainRouteFeature => {
  return isTrainRouteGeometry(item.geometry);
};

/**
 * TrainRouteInfoのType Guard
 * @param item 判定対象
 * @returns TrainRouteInfoか否か
 */
export const isTrainRouteInfo = (item: any): item is TrainRouteInfo => {
  return item.features !== undefined;
};
