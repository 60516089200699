import {CenterMarkerConditionInitOptions} from '../types';
import {Color} from './Color';

const DEFAULT_WIDTH = 2;
const DEFAULT_LENGTH = 10;

/**
 * 十字マーカーコンディション
 */
class CenterMarkerCondition {
  readonly color: Color;
  readonly width: number;
  readonly length: number;

  /**
   * コンストラクタ
   * @param options 表示オプション
   */
  constructor(options: CenterMarkerConditionInitOptions) {
    this.color = options.color ?? Color.red();
    this.width = options.width ?? DEFAULT_WIDTH;
    this.length = options.length ?? DEFAULT_LENGTH;
  }
}

export {CenterMarkerCondition};
