import {TileNumber} from '../../models/TileNumber';
import {TileSize} from '../../../../gaia/types';
import {TrafficCondition} from '../../../../gaia/value/TrafficCondition';
import {PaletteParameter} from '../../models/PaletteParameter';

/**
 * 交通情報タイルのパラメータクラス
 */
class TrafficTileParameter extends TileNumber {
  /** タイルサイズ */
  readonly tileSize: TileSize;
  /** 交通情報コンディション */
  readonly condition: TrafficCondition;
  /** パレット情報 */
  readonly palette: PaletteParameter;
  /** 注記表示フラグ */
  readonly annotation: boolean;

  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   * @param palette パレット情報
   * @param condition リクエストオプション
   * @param tileSize タイル画像サイズ
   * @param annotation 注記の有効フラグ
   */
  constructor(
    x: number,
    y: number,
    z: number,
    palette: PaletteParameter,
    condition: TrafficCondition,
    tileSize: TileSize = 256,
    annotation = true
  ) {
    super(x, y, z);
    this.tileSize = tileSize;
    this.palette = palette;
    this.condition = condition;
    this.annotation = annotation;

    this._cacheKey = `T(${this.x},${this.y},${this.z},${this.palette.language},${
      this.palette.name
    },${this.condition.trafficTime.getTime()}${
      this.condition.roadRegulation || this.condition.definedRegulation
        ? ',' + this.condition.regulationTime.getTime()
        : ''
    },a=${this.annotation}`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {TrafficTileParameter};
