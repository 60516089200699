import {Language} from '../../../gaia/types';

/**
 * パレットに関するパラメーター
 */
class PaletteParameter {
  readonly language: Language;
  readonly name: string;

  /**
   * コンストラクタ
   * @param language 言語
   * @param name パレット名
   */
  constructor(language: Language = 'ja', name = '') {
    this.language = language;
    this.name = name;
  }

  /**
   * 同一判定
   * @param other PaletteParameter
   * @returns `true` なら同一
   */
  equals(other: PaletteParameter): boolean {
    return this.language === other.language && this.name === other.name;
  }
}

export {PaletteParameter};
