import {Camera} from '../../../../engine/camera/Camera';
import {GaiaContext} from '../../../GaiaContext';
import {MapStatus} from '../../../models/MapStatus';
import {MapRenderKitController, TileRenderKitMapping} from '../MapRenderKitController';

import {
  ExternalAnnotationCallback,
  ExternalAnnotationClickListener,
  ExternalAnnotationFeature,
  ExternalAnnotationPluginMap,
  GLMarkerEvent,
  GLMarkerEventMap,
  GLMarkerLabelOptions,
  TileSize,
} from '../../../../../gaia/types';
import {ExternalAnnotationLayer} from '../../../layer/ExternalAnnotationLayer';
import {ExternalAnnotationLoader} from '../../../loader/ExternalAnnotationLoader';
import {ExternalAnnotationCondition} from 'gaia/value/ExternalAnnotationCondition';

import {calculateWorldCoordinate} from '../../../utils/MapUtil';

import {GLMarkerIconInfo, LatLng} from '../../../../../gaia/value';
import {GLMarker} from '../../../../../gaia/object';
import {GLMarkerObject} from '../../objects/GLMarkerObject';
import {GLMarkerGroupObject} from '../../objects/GLMarkerGroupObject';
import {GLMarkerTextureHelper} from '../../helper/GLMarkerTextureHelper';

import {QUATERNION_IDENTITY} from '../../../../common/math/Quaternion';
import {VECTOR_ONES} from '../../../../common/math/Vector3';
import {Ray3} from '../../../../common/math/Ray3';
import {getDevicePixelRatio} from '../../../../common/util/Device';
import {Collision} from '../../../../engine/collision/Collision';
import {CustomGeometry} from '../../../../engine/geometry/CustomGeometry';
import {TexturePlaneMaterial} from '../../../../engine/material/TexturePlaneMaterial';

const LAYER_NAME_EXTERNAL_ANNOTATION = 'externalAnnotation';

/**
 * 社外由来注記オブジェクトを扱う描画キット
 */
class ExternalAnnotationObjectRenderKit {
  private context: GaiaContext;
  private camera: Camera;

  /** 機能の利用可否 */
  private isEnable = true;

  private layer: ExternalAnnotationLayer;
  private loader: ExternalAnnotationLoader;

  private condition?: ExternalAnnotationCondition;
  private callback?: ExternalAnnotationCallback;
  private pluginMap?: ExternalAnnotationPluginMap;

  private visible: boolean;
  private status?: MapStatus;

  private readonly tileSize: TileSize;

  private markerHash: {[id: string]: GLMarker} = {};

  private readonly textureHelper: GLMarkerTextureHelper;

  getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKitCtl MapRenderKitController
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    this.context = context;
    this.camera = camera;
    this.visible = true;

    this.tileSize = getDevicePixelRatio() > 1 ? 512 : 256;

    this.layer = new ExternalAnnotationLayer(context, this, camera);
    this.loader = new ExternalAnnotationLoader(context);

    this.layer.setVisible(this.visible);

    this.textureHelper = new GLMarkerTextureHelper();

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKitCtl.getAllCollisions(ray);

    context.getGaIAConfiguration().then((config) => {
      this.isEnable = config.features.externalannotation;
      if (this.isEnable) {
        this.executeLoader();
      } else {
        this.clear();
      }
    });
  }

  /**
   * 社外由来注記コンディションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setExternalAnnotationCondition(condition?: ExternalAnnotationCondition): void {
    this.condition = condition;
    if (condition) {
      this.layer.setVisible(true);
      this.layer.setColliderMarginForAllIcon(condition.collisionMarginForAllIcon);
      this.layer.setColliderMarginForSameIcon(condition.collisionMarginForSameIcon);

      this.callback = condition.callback;
      if (this.callback) {
        this.pluginMap = this.callback(this.tileSize);
        this.layer.setPluginMap(this.pluginMap);
        this.loader.setPluginMap(this.pluginMap);
      }
    } else {
      this.layer.setVisible(false);
      this.layer.clear();
      this.loader.clear();
    }
  }

  /**
   * 社外由来注記クリックリスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setExternalAnnotationClickListener(listener: ExternalAnnotationClickListener): void {
    this.layer.setExternalAnnotationClickListener(listener);
  }

  /**
   * RenderKit特定用キー
   */
  get identicalName(): keyof TileRenderKitMapping {
    return LAYER_NAME_EXTERNAL_ANNOTATION;
  }

  /**
   * テキスト・アイコン注記レイヤーを取得
   * @returns テキスト・アイコン注記レイヤー
   */
  getLayer(): ExternalAnnotationLayer {
    return this.layer;
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  private clear(): void {
    Object.keys(this.markerHash).forEach((key) => {
      delete this.markerHash[key];
    });

    this.layer.clear();
    this.loader.clear();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.layer.destroy();
    this.loader.destroy();
  }

  /**
   * 表示状態を設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
    this.layer.setVisible(visible);
  }

  /**
   * ローダーのリクエストを実行
   * @returns {void}
   */
  executeLoader(): void {
    this.loader.executeRequest();
  }

  /**
   * 描画物を更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.status = mapStatus;

    if (!this.visible) {
      return;
    }
    if (!this.condition) {
      return;
    }

    const isActive = this.condition.zoomRange.isInRange(mapStatus.zoomLevel);
    this.layer.setVisible(isActive);
    if (!isActive) {
      this.layer.clear();
      return;
    }

    const drawingFeatures: {[id: string]: ExternalAnnotationFeature} = {};
    const erasingFeatures: {[id: string]: ExternalAnnotationFeature} = {};

    // eslint-disable-next-line
    while (true) {
      const feature = this.loader.popDrawingStack();
      if (!feature) {
        break;
      }
      const featureId = feature.properties.feature_id;
      drawingFeatures[featureId] = feature;
    }

    // eslint-disable-next-line
    while (true) {
      const feature = this.loader.popErasingStack();
      if (!feature) {
        break;
      }
      const featureId = feature.properties.feature_id;
      erasingFeatures[featureId] = feature;
    }

    const erasingMarkers = this.collectErasingMarkers(drawingFeatures, erasingFeatures);
    this.removeMarkers(erasingMarkers);

    const drawingMarkers = this.collectDrawingMarkers(drawingFeatures, erasingFeatures);
    this.addMarkers(drawingMarkers);

    this.layer.update(mapStatus);
  }

  /**
   * 追加するマーカーを用意
   * @param drawingFeatures 追加対象のFeature群
   * @param erasingFeatures 削除対象のFeature群
   * @returns {void}
   */
  collectDrawingMarkers(
    drawingFeatures: {[id: string]: ExternalAnnotationFeature},
    erasingFeatures: {[id: string]: ExternalAnnotationFeature}
  ): GLMarker[] {
    const drawingMarkers: GLMarker[] = [];

    for (const [id, feature] of Object.entries(drawingFeatures)) {
      if (erasingFeatures[id]) {
        continue;
      }

      const {geometry, properties} = feature;
      const {feature_id: featureId, icon} = properties;
      const {coordinates} = geometry;

      const position = new LatLng(coordinates[1], coordinates[0]);
      const info = new GLMarkerIconInfo({
        icon,
        size: this.condition?.iconSize,
        gravity: 'center',
      });
      const label = {style: {fontSize: '0'}, content: ''};
      const glMarker = new GLMarker({
        position,
        info,
        label,
      });
      glMarker.setProperties(feature);

      const ev = this.loader.getMopraClickHandler(feature);
      if (!ev) {
        continue;
      }
      glMarker.addEventListener('click', ev);

      drawingMarkers.push(glMarker);
      this.markerHash[featureId] = glMarker;
    }
    return drawingMarkers;
  }

  /**
   * 削除するマーカーを用意
   * @param drawingFeatures 追加対象のFeature群
   * @param erasingFeatures 削除対象のFeature群
   * @returns {void}
   */
  collectErasingMarkers(
    drawingFeatures: {[id: string]: ExternalAnnotationFeature},
    erasingFeatures: {[id: string]: ExternalAnnotationFeature}
  ): GLMarker[] {
    const erasingMarkers: GLMarker[] = [];
    for (const [id, feature] of Object.entries(erasingFeatures)) {
      if (drawingFeatures[id]) {
        continue;
      }

      const {properties} = feature;
      const {feature_id: featureId} = properties;

      const glMarker = this.markerHash[featureId];
      if (glMarker) {
        erasingMarkers.push(glMarker);
        delete this.markerHash[featureId];
      }
    }
    return erasingMarkers;
  }

  /**
   * マーカーを追加
   * @param markers 追加対象のGLMarker群
   * @returns {void}
   */
  addMarkers(markers: GLMarker[]): void {
    const markersMap = this.groupMarkersByIcon(markers);

    for (const [info, markerList] of markersMap.entries()) {
      // groupの作成
      let markerGroupObject = this.layer.fetchMarkerGroupObject(info);
      if (!markerGroupObject) {
        markerGroupObject = this.createMarkerGroupObject(info, (): void => {
          if (!markerGroupObject) {
            return;
          }
          this.updateGroupTexture(markerGroupObject);
        });
        this.layer.addMarkerGroupObject(info, markerGroupObject);
      }

      // GLMarkerObjectの作成
      let requireTextureUpdate = false;
      for (const marker of markerList) {
        const labelOptions = marker.getLabel() ?? {content: ''};
        const labelOptionsKey = this.createLabelOpotionsKey(labelOptions);
        requireTextureUpdate = markerGroupObject.addLabelOptions(labelOptionsKey, labelOptions);

        const markerObject = this.createMarkerObject(marker, labelOptionsKey, markerGroupObject);
        markerGroupObject.addMarkerObject(markerObject);
      }

      // image loadまで完了していたら即テクスチャ更新
      // 追加された全Markerのラベルが既存のテクスチャで賄える場合は、テクスチャ再生成は行わない
      if (markerGroupObject.markerImage && requireTextureUpdate) {
        this.updateGroupTexture(markerGroupObject);
      }
    }
  }

  /**
   * マーカーを削除
   * @param markers 削除対象のGLMarker群
   * @returns {void}
   */
  removeMarkers(markers: GLMarker[]): void {
    const markersMap = this.groupMarkersByIcon(markers);

    for (const [info, markerList] of markersMap.entries()) {
      const markerGroupObject = this.layer.fetchMarkerGroupObject(info);
      if (!markerGroupObject) {
        continue;
      }

      for (const marker of markerList) {
        const markerObject = this.layer.findMarkerObject(marker);
        if (!markerObject) {
          continue;
        }

        markerGroupObject.removeMarkerObject(markerObject);
        this.layer.removeMarkerObject(markerObject);
      }

      if (markerGroupObject.isEmpty()) {
        this.layer.removeMarkerGroupObject(info);
      }
    }
  }

  /**
   * GLMarkerの配列を、GLMarkerIconInfo別の配列にする
   * @param markers GLMarker群
   * @returns GLMarkerIconInfoと、それに対応するGLMarker配列のMap
   */
  private groupMarkersByIcon(markers: GLMarker[]): Map<GLMarkerIconInfo, GLMarker[]> {
    const markerMap = new Map<GLMarkerIconInfo, GLMarker[]>();
    for (const marker of markers) {
      const markerList = markerMap.get(marker.info) ?? [];
      markerList.push(marker);
      markerMap.set(marker.info, markerList);
    }
    return markerMap;
  }

  /**
   * GLMarkerLabelOptionsからキーを生成
   * @param labelOptions GLMarkerLabelOptions
   * @returns 生成したキー
   */
  private createLabelOpotionsKey(labelOptions: GLMarkerLabelOptions): string {
    let labelOptionsKey = 'no label';
    if (labelOptions.content && labelOptions.content.length > 0) {
      const {content, offset, style} = labelOptions;
      labelOptionsKey = `${content} ${offset?.x ?? 0},${offset?.y ?? 0}`;
      if (style) {
        labelOptionsKey += ` ${style.fontWeight ?? ''} ${style.color ?? ''} ${style.fontSize ?? ''}`;
      }
    }
    return labelOptionsKey;
  }

  /**
   * GLMarkerGroupObjectのテクスチャを再生成・更新
   * @param group 対象のGLMarkerGroupObject
   * @returns {void}
   */
  private updateGroupTexture(group: GLMarkerGroupObject): void {
    const image = group.markerImage;
    if (!image) {
      return;
    }

    const textureMapping = this.textureHelper.createMarkerTexture(group.labelOptionsMap, image, group.markerInfo);
    group.setTexture(textureMapping);
    if (this.status) {
      this.updateDrawObjects(this.status);
    }
  }

  /**
   * GLMarkerGroupObjectを生成
   * @param info GLMarkerIconInfo
   * @param onImageLoaded マーカー画像読み込み完了通知
   * @returns GLMarkerGroupObject
   */
  private createMarkerGroupObject(info: GLMarkerIconInfo, onImageLoaded: () => void): GLMarkerGroupObject {
    const material = new TexturePlaneMaterial(this.context.getGLContext(), new CustomGeometry([], []), true);
    const markerGroupObject = new GLMarkerGroupObject(
      calculateWorldCoordinate(this.context.getMapStatus().centerLocation),
      QUATERNION_IDENTITY,
      VECTOR_ONES,
      material,
      info,
      onImageLoaded,
      this.camera
    );

    return markerGroupObject;
  }

  /**
   * GLMarkerObjectを生成
   * @param marker GLMarker
   * @param labelOptionsKey GLMarkerLabelOptionsのKey
   * @param group 追加先のGLMarkerGroupObject
   * @returns GLMarkerObject
   */
  private createMarkerObject(marker: GLMarker, labelOptionsKey: string, group: GLMarkerGroupObject): GLMarkerObject {
    const markerPosition = calculateWorldCoordinate(marker.getPosition());
    const markerObject = new GLMarkerObject(markerPosition, labelOptionsKey, marker.getZIndex());
    this.layer.addMarkerObject(markerObject, marker);

    for (const [eventName, listener] of Object.entries(marker.listeners)) {
      markerObject.addEventListener(eventName as keyof GLMarkerEventMap, listener);
    }

    marker.setOnEventUpdatedListener(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (eventName: keyof GLMarkerEventMap, func: (ev: GLMarkerEvent) => any) => {
        markerObject.addEventListener(eventName, func);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (eventName: keyof GLMarkerEventMap, func: (ev: GLMarkerEvent) => any) => {
        markerObject.removeEventListener(eventName, func);
      }
    );

    marker.setOnLabelUpdateListener((marker) => {
      const labelOptions = marker.getLabel() ?? {content: ''};
      const labelOptionsKey = this.createLabelOpotionsKey(labelOptions);
      markerObject.setLabelOptionsKey(labelOptionsKey);
      group.addLabelOptions(labelOptionsKey, labelOptions);
      this.updateGroupTexture(group);
    });

    return markerObject;
  }
}

export {ExternalAnnotationObjectRenderKit};
