import {TileRequestUrlBuilderFunc} from './AbstractTileRequester';
import {Queue} from '../../collection/Queue';
import {JsonObject} from '../../../../gaia/types';
import {createWorker} from '../../util/InlineWorker';
import {RoadShapeOpenedMainParameter} from '../../../map/loader/param/RoadShapeOpenedMainParameter';

type OnResponse = (param: RoadShapeOpenedMainParameter, info: JsonObject) => void;
type WorkerRequestData = {[key: string]: string};
type WorkerResponseData = {key: string; data?: string};

/**
 * 新規開通道路情報を取得するリクエストクラス
 */
class RoadShapeOpenedInfoRequester {
  private readonly urlBuilder: TileRequestUrlBuilderFunc<RoadShapeOpenedMainParameter>;
  private readonly waitingQueue: Queue<WorkerRequestData> = new Queue<WorkerRequestData>();

  private readonly mainParameterMap: Map<string, RoadShapeOpenedMainParameter> = new Map();
  private readonly callbackMap: Map<string, OnResponse> = new Map();

  private readonly workerNum: number;
  // TODO 通信で使うworkerを機能間で共有するか検討
  private readonly workerPool: Queue<Worker> = new Queue<Worker>();
  private readonly runningWorkers: Worker[] = [];

  /**
   * コンストラクタ
   * @param urlBuilder リクエストURL生成Builder関数
   * @param workerNum 利用するWorker数
   */
  constructor(urlBuilder: TileRequestUrlBuilderFunc<RoadShapeOpenedMainParameter>, workerNum = 2) {
    this.urlBuilder = urlBuilder;
    this.workerNum = workerNum;
  }

  /**
   * リクエストのクリア
   * @returns {void}
   */
  clear(): void {
    this.waitingQueue.clear();
    this.mainParameterMap.clear();
  }

  /**
   * リクエスト実行
   * @param param リクエストパラメータ
   * @param onLoadTile 新規開通道路情報読み込み通知
   * @returns {void}
   */
  requestRoadShapeOpened(param: RoadShapeOpenedMainParameter, onLoadTile: OnResponse): void {
    const key = param.getCacheKey();
    const message: WorkerRequestData = {};
    message[key] = this.urlBuilder(param);
    this.mainParameterMap.set(key, param);
    this.callbackMap.set(key, onLoadTile);
    this.requestToWorker(message);
  }

  /**
   * workerへの通信処理リクエスト
   * @param request WorkerRequestData
   * @returns {void}
   */
  private requestToWorker(request: WorkerRequestData): void {
    let worker = this.workerPool.dequeue();
    if (!worker) {
      if (this.runningWorkers.length >= this.workerNum) {
        this.waitingQueue.enqueue(request);
        return;
      }
      worker = createWorker(this.sendRequest);
    }
    this.runningWorkers.push(worker);

    worker.onmessage = (ev: MessageEvent): void => {
      if (!worker) return;
      // workerをPoolにもどす
      const index = this.runningWorkers.indexOf(worker);
      if (index > -1) {
        this.runningWorkers.splice(index, 1);
      }
      this.workerPool.enqueue(worker);

      const {key, data} = ev.data as WorkerResponseData;
      const callback = this.callbackMap.get(key);
      const param = this.mainParameterMap.get(key);
      if (param && data) {
        callback?.(param, data);
        this.callbackMap.delete(key);
        this.mainParameterMap.delete(key);
      }

      this.executeWaitingQueue();
    };
    worker.postMessage(request);
  }

  /**
   * リクエスト送信処理
   * @param ev メインスレッドから受け取ったMessageEvent
   * @returns {void}
   */
  private sendRequest(ev: MessageEvent): void {
    const requests = ev.data as WorkerRequestData;
    for (const key in requests) {
      fetch(requests[key])
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          const resMessage: WorkerResponseData = {key, data};
          ((self as unknown) as Worker).postMessage(resMessage);
        })
        .catch(() => {
          ((self as unknown) as Worker).postMessage({key, data: undefined});
        });
    }
  }

  /**
   * リクエスト待ちキューの消化
   * @returns {void}
   */
  private executeWaitingQueue(): void {
    const queue = this.waitingQueue.dequeue();
    if (queue) {
      this.requestToWorker(queue);
    }
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    for (const worker of this.runningWorkers) {
      worker.terminate();
    }
    while (this.workerPool.size > 0) {
      const worker = this.workerPool.dequeue();
      worker?.terminate();
    }
  }
}

export {RoadShapeOpenedInfoRequester};
