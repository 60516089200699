import {IndoorMetadata} from '../../_private/map/render/helper/IndoorAreaHelper';
import {IndoorConditionInitOptions} from '../types';
import {Color} from './Color';

const DefaultIndoorBackgroundColor = new Color(0, 0, 0, 0.8);

/**
 * 屋内地図のコンディション
 */
class IndoorCondition {
  readonly onFloorListChangedCallback: (floorList: string[]) => void;
  readonly metadata: IndoorMetadata;
  readonly backgroundColor: Color;
  readonly server?: string;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param server タイル取得向き先URL
   */
  constructor(options: IndoorConditionInitOptions, server?: string) {
    this.onFloorListChangedCallback = options.onFloorListChangedCallback;
    this.metadata = options.metadata;
    this.backgroundColor = options.backgroundColor ?? DefaultIndoorBackgroundColor;
    this.server = server;
  }
}

export {IndoorCondition};
