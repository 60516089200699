import {AnnotationMainParameter} from '../param/AnnotationMainParameter';
import {AnnotationMetaParameter} from '../param/AnnotationMetaParameter';
import {AbstractStoreHandler} from './AbstractStoreHandler';
import {MainDBData} from './AnnotationDBUtil';

type AnnotationParameter = AnnotationMetaParameter | AnnotationMainParameter;

/**
 * 注記用IndexedDBオブジェクトストアハンドラ
 */
class AnnotationStoreHandler<T extends AnnotationParameter, U extends MainDBData> extends AbstractStoreHandler<T, U> {
  /**
   * コンストラクタ
   * @param dbName DB名
   * @param storeName オブジェクトストア名
   * @param recordMax レコード上限
   */
  constructor(dbName: string, storeName: string, recordMax = 3000) {
    super(dbName, storeName, recordMax);
  }

  /** @override */
  createCacheKey(parameter: T): string {
    return parameter.getCacheKey();
  }
}

export {AnnotationStoreHandler};
