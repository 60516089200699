import {RoadShapeOpenedInitOptions, RoadShapeOpenedCallback} from '../types';
import {ZoomRange} from './ZoomRange';

/**
 * 新規開通道路コンディション
 */
class RoadShapeOpenedCondition {
  /** 表示ズームレベル範囲 */
  readonly zoomRange: ZoomRange;
  /** 基準日時 */
  readonly baseDate: Date;
  /** 見た目設定用コールバック */
  readonly callback?: RoadShapeOpenedCallback;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param callback 見た目設定用コールバック
   */
  constructor(options: RoadShapeOpenedInitOptions, callback?: RoadShapeOpenedCallback) {
    this.zoomRange = options.zoomRange ?? new ZoomRange();
    this.baseDate = options.baseDate ?? new Date();
    this.callback = callback;
  }
}

export {RoadShapeOpenedCondition};
