import {mat4} from 'gl-matrix';
import {
  AnnotationDisplayOptions,
  AnnotationFontFamilyMap,
  AnnotationOptions,
  GenericFontFamily,
} from '../../../gaia/types';
import {LatLng, Point, Size} from '../../../gaia/value';
import {ArrayList} from '../../common/collection/ArrayList';
import {LRUCache} from '../../common/collection/LRUCache';
import {Queue} from '../../common/collection/Queue';
import {SimpleCache} from '../../common/collection/SimpleCache';
import {Quaternion} from '../../common/math/Quaternion';
import {Ray3} from '../../common/math/Ray3';
import {Vector3} from '../../common/math/Vector3';
import {Optional} from '../../common/types';
import {getDevicePixelRatio} from '../../common/util/Device';
import {Camera} from '../../engine/camera/Camera';
import {PerspectiveCamera} from '../../engine/camera/PerspectiveCamera';
import {Collision} from '../../engine/collision/Collision';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {Layer, LayerUpdateNotifierFunc} from '../../engine/layer/Layer';
import {PointSpriteMaterial} from '../../engine/material/PointSpriteMaterial';
import {TexturePlaneMaterial} from '../../engine/material/TexturePlaneMaterial';
import {Object3D} from '../../engine/object/Object3D';
import {DEFAULT_POINT_SIZE} from '../../engine/program/PointSpriteProgram';
import {MouseEventObserver} from '../event/MouseEventObserver';
import {GaiaContext} from '../GaiaContext';
import {AbstractIconAnnotationData} from '../models/annotation/AbstractIconAnnotationData';
import {IconAnnotationData} from '../models/annotation/IconAnnotationData';
import {TextAnnotationData} from '../models/annotation/TextAnnotationData';
import {TextAnnotationTextureData} from '../models/annotation/TextAnnotationTextureData';
import {GaIAConfiguration} from '../models/GaIAConfiguration';
import {MapStatus} from '../models/MapStatus';
import {TileNumber} from '../models/TileNumber';
import {AnnotationCullHelper} from '../render/helper/AnnotationCullHelper';
import {
  AnnotationTextureHelper,
  AnnotationTextureMapping,
  DrawTileFontInfo,
} from '../render/helper/AnnotationTextureHelper';
import {AnnotationObjectRenderKit, NoteAnnotationData} from '../render/kit/object/AnnotationObjectRenderKit';
import {MapIconObjectRenderKit} from '../render/kit/object/MapIconObjectRenderKit';
import {AnnotationObject} from '../render/objects/AnnotationObject';
import {IconAnnotationGroupObject} from '../render/objects/IconAnnotationGroupObject';
import {TextAnnotationGroupObject} from '../render/objects/TextAnnotationGroupObject';
import {calculatePixelToUnit, calculateWorldCoordinate} from '../utils/MapUtil';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * NoteAnnotationDataのType Guard
 * @param item 判定対象
 * @returns NoteAnnotationData型か
 */
const isNoteAnnotationData = (item: any): item is NoteAnnotationData => {
  return item instanceof TextAnnotationData || item instanceof IconAnnotationData;
};
/* eslint-enable */

const MAX_SET_TEXTURE_COUNT = 2;

const DEFAULT_SWAP_INTERVAL = 1000;
const DEFAULT_DISPLAY_OPTIONS: AnnotationDisplayOptions = {
  mode: 'swap',
  swapOptions: {
    swapInterval: DEFAULT_SWAP_INTERVAL,
  },
};
const DEFAULT_FONT_FAMILY_MAP: AnnotationFontFamilyMap = {
  sansSerif: [GenericFontFamily.SansSerif],
  serif: [GenericFontFamily.Serif],
  serifBold: [GenericFontFamily.Serif],
  sansSerifBold: [GenericFontFamily.SansSerif],
};

/**
 * 注記や地図アイコンで、文字やアイコンを大量に描画するレイヤ
 */
abstract class AbstractNoteAnnotationLayer<P extends TileNumber, D extends AbstractIconAnnotationData>
  implements Layer {
  protected readonly context: GaiaContext;
  protected readonly camera: Camera;
  protected visible: boolean;

  /** 描画中テキスト注記グループオブジェクト */
  protected inDisplayTextObjects: SimpleCache<P, TextAnnotationGroupObject>;
  /** 生成済み文字テクスチャ */
  protected textTextureCache: LRUCache<P, AnnotationTextureMapping>;
  /** 生成済み文字フォント情報 */
  protected textFontInfo: Map<P, DrawTileFontInfo>;
  protected textDataListMap: Map<P, TextAnnotationData[]>;
  /** 再利用可能なMaterial */
  protected reuseableMaterials: Queue<TexturePlaneMaterial>;

  /** アイコングループオブジェクト */
  protected iconGroupObject: IconAnnotationGroupObject<D>;
  /** 描画中アイコン注記 */
  protected inDisplayIcon: SimpleCache<P, string[]>;
  /** アイコンテクスチャ */
  protected iconTextureCache?: TexImageSource;

  protected _isSetIconTexture = false;
  protected textCullHelper: AnnotationCullHelper;
  protected textureHelper: AnnotationTextureHelper;

  protected notifyUpdate?: LayerUpdateNotifierFunc;

  /** 間引き実行抑制フラグ */
  protected suspendCull = false;
  /** 間引き実行debounceID */
  protected debounceId = 0;
  protected currentZoom: number;

  protected mouse: MouseEventObserver;
  protected hoveredObject?: AnnotationObject<D>;
  protected isTextClickable = true;
  protected isIconClickable = true;
  protected isNodeTextOnly = false;
  protected isNodeIconOnly = false;
  protected onceClick = false;

  protected isDestroyed = false;

  protected annotationOptions: AnnotationOptions;
  protected displayOptions: AnnotationDisplayOptions;
  protected fontFamilyMap: AnnotationFontFamilyMap;
  protected updateIntervalId: NodeJS.Timeout | undefined;

  protected mapStatus: MapStatus | undefined;
  protected requiredAnnotationMap: Map<P, Array<D | TextAnnotationData>> | undefined;

  protected getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  protected getAltitudeCallback: (latLng: LatLng) => number;

  private pointLatLngList: LatLng[];
  private pointGeometry?: CustomGeometry;
  private pointMaterial?: PointSpriteMaterial;
  private pointObject3D?: Object3D;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKit AnnotationObjectRenderKit
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKit: AnnotationObjectRenderKit | MapIconObjectRenderKit, camera: Camera) {
    this.context = context;
    this.camera = camera;
    this.visible = false;

    this.getAltitudeCallback = (_: LatLng): number => 0;

    const annotationOptions: AnnotationOptions | undefined = context.getMapInitOptions().annotationOptions;
    if (annotationOptions) {
      this.annotationOptions = annotationOptions;
    } else {
      this.annotationOptions = {
        display: {
          mode: 'swap',
          swapOptions: {
            swapInterval: DEFAULT_SWAP_INTERVAL,
          },
        },
        fontFamily: {
          sansSerif: [GenericFontFamily.SansSerif],
          serif: [GenericFontFamily.Serif],
          serifBold: [GenericFontFamily.Serif],
          sansSerifBold: [GenericFontFamily.SansSerif],
        },
      };
    }

    if (this.annotationOptions.display) {
      this.displayOptions = this.annotationOptions.display;
    } else {
      this.displayOptions = DEFAULT_DISPLAY_OPTIONS;
    }

    if (this.annotationOptions.fontFamily) {
      this.fontFamilyMap = this.annotationOptions.fontFamily;
    } else {
      this.fontFamilyMap = DEFAULT_FONT_FAMILY_MAP;
    }

    if (this.displayOptions.mode === 'swap') {
      this.updateIntervalId = setInterval(() => {
        if (!this.mapStatus || !this.requiredAnnotationMap) {
          return;
        }
        this.update(this.mapStatus, this.requiredAnnotationMap);
        this.notifyUpdate?.();
      }, this.displayOptions.swapOptions.swapInterval);
    }

    context.getGaIAConfiguration().then((config: GaIAConfiguration) => {
      const seedUrl = config.server.data;
      if (!seedUrl) {
        return;
      }
      const productId = config.productId;
      const styleElement = document.getElementById('gaia-stylesheet');
      if (!styleElement) {
        return;
      }

      styleElement.innerHTML += `
      @font-face {
        font-family: 'AdobeBlank';
        src: url(${seedUrl}v1/${productId}/web-font/font/AdobeBlank.otf.woff) format('woff');
      }
      `;
    });

    this.inDisplayTextObjects = new SimpleCache();
    this.textTextureCache = new LRUCache(100);
    this.textFontInfo = new Map();
    this.textDataListMap = new Map();
    this.reuseableMaterials = new Queue<TexturePlaneMaterial>();

    const geometry = new CustomGeometry([], []);
    this.iconGroupObject = new IconAnnotationGroupObject(
      new TexturePlaneMaterial(context.getGLContext(), geometry),
      geometry,
      calculateWorldCoordinate(context.getMapStatus().centerLocation)
    );
    this.iconGroupObject.setAltitudeCallback(this.getAltitudeCallback);
    this.inDisplayIcon = new SimpleCache();

    this.textCullHelper = new AnnotationCullHelper();
    this.textureHelper = new AnnotationTextureHelper(annotationOptions);

    this.mouse = new MouseEventObserver(context.getBaseElement());
    this.setupHover();
    this.setupClick();
    this.mouse.addEventListener('mousedown', () => {
      this.suspendCull = true;
    });
    this.mouse.addEventListener('mouseup', () => {
      this.suspendCull = false;
    });

    this.currentZoom = context.getMapStatus().zoomLevel;
    context.addOnMapStatusUpdateListener((status) => {
      if (Math.floor(this.currentZoom) !== Math.floor(status.zoomLevel)) {
        this.hoveredObject = undefined;
        this.context.getBaseElement().style.cursor = 'auto';
        this.pointLatLngList = [];
      }

      if (Math.abs(this.currentZoom - status.zoomLevel) > 0.0001) {
        this.suspendCull = true;
        if (this.debounceId !== 0) {
          window.clearTimeout(this.debounceId);
          this.debounceId = 0;
        }
        this.debounceId = window.setTimeout(() => {
          const cameraTargetPosition = calculateWorldCoordinate(context.getMapStatus().centerLocation);
          this.cullObjects(cameraTargetPosition);
          this.updateAllObjects(cameraTargetPosition);
          this.suspendCull = false;
        }, 50);
      }
      this.currentZoom = status.zoomLevel;
    });

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKit.getAllCollisions(ray);

    this.pointLatLngList = [];
  }

  /**
   * ドットを表示する描画物を初期化する
   * @returns {void}
   */
  private initializePointObject3D(): void {
    if (this.pointGeometry || this.pointMaterial || this.pointObject3D) {
      return;
    }

    this.pointGeometry = new CustomGeometry([], []);
    this.pointMaterial = new PointSpriteMaterial(this.context.getGLContext(), this.pointGeometry);

    const canvas = document.createElement('canvas');
    const side = 32;
    canvas.width = side;
    canvas.height = side;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.beginPath();
      ctx.arc(side / 2, side / 2, side / 2, 0, 2.0 * Math.PI);
      ctx.fillStyle = 'black';
      ctx.fill();
      this.pointMaterial.setTexture(canvas);
    }

    const dpr = getDevicePixelRatio();
    this.pointMaterial.setPointSize(dpr * DEFAULT_POINT_SIZE);

    this.pointObject3D = new Object3D(Vector3.zero(), Quaternion.identity(), Vector3.one(), this.pointMaterial);
  }

  /**
   * ホバー時の設定
   * @returns {void}
   */
  protected abstract setupHover(): void;

  /**
   * クリック時の設定
   * @returns {void}
   */
  protected abstract setupClick(): void;

  /**
   * 標高取得コールバックを設定
   * @param callback コールバック
   * @returns {void}
   */
  setAltitudeCallback(callback: (latLng: LatLng) => number): void {
    this.getAltitudeCallback = callback;
    this.iconGroupObject.setAltitudeCallback(callback);
    for (const textObject of this.inDisplayTextObjects.values()) {
      textObject.setAltitudeCallback(callback);
    }
  }

  /**
   * 地図に対するマウスイベントかを判定
   * @param ev MouseEvent
   * @returns 地図に対するマウスイベントか
   */
  protected isOnMap(ev: MouseEvent): boolean {
    for (const value of ev.composedPath()) {
      const classList = (value as Element).classList;
      if (classList && classList.contains('gia-base-element')) {
        return true;
      }
    }
    return false;
  }

  /**
   * クライアント座標をレイキャストに変換する
   * @param clientPositionX x座標
   * @param clientPositionY y座標
   * @returns レイキャスト
   */
  protected clientPositionToRay(clientPositionX: number, clientPositionY: number): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const mapStatus = this.context.getMapStatus();

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const halfWidth = (mapStatus.clientWidth * ptu) / 2;
    const halfHeight = (mapStatus.clientHeight * ptu) / 2;
    const toTopVector = mapStatus.polar
      .toUpVector3()
      .normalize()
      .multiply(halfHeight * y);
    const toRightVector = mapStatus.polar
      .toRightVector3()
      .normalize()
      .multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);
    const start = calculateWorldCoordinate(mapStatus.centerLocation).add(this.camera.position);

    const direction = (this.camera as PerspectiveCamera).target._subtract(this.camera.position).add(toTopRightVector);

    const ray = new Ray3(start, direction);

    return ray;
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * アイコンテクスチャの設定
   * @param image テクスチャ
   * @returns {void}
   */
  setIconTexture(image: TexImageSource): void {
    this.iconTextureCache = image;
    this._isSetIconTexture = true;
  }

  /**
   * アイコンテクスチャを設定済みか
   */
  get isSetIconTexture(): boolean {
    return this._isSetIconTexture;
  }

  /**
   * 表示状態の設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  /**
   * フォント情報を設定
   * @param fontFamilyMap フォント情報
   * @returns {void}
   */
  setFontFamilyMap(fontFamilyMap: AnnotationFontFamilyMap): void {
    this.fontFamilyMap = fontFamilyMap;
  }

  /**
   * クリア処理
   * @returns {void}
   */
  clear(): void {
    this.iconGroupObject.clearTexture();
    this.iconTextureCache = undefined;
    this._isSetIconTexture = false;
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @param requiredAnnotationMap 描画する注記のデータ
   * @returns {void}
   */
  update(mapStatus: MapStatus, requiredAnnotationMap: Map<P, Array<D | TextAnnotationData>>): void {
    if (this.isDestroyed) {
      return;
    }

    this.mapStatus = mapStatus;
    this.requiredAnnotationMap = requiredAnnotationMap;

    this.textTextureCache.jumpUpSize(requiredAnnotationMap.size * 2);

    const requiredTileList: ArrayList<P> = ArrayList.from<P>(Array.from(requiredAnnotationMap.keys()));

    // 不要になった注記を削除する
    const currentTiles = new Set([
      ...Array.from(this.inDisplayTextObjects.keys()),
      ...Array.from(this.inDisplayIcon.keys()),
    ]);
    for (const param of currentTiles) {
      if (requiredTileList.contains(param)) {
        continue;
      }

      // icon
      const iconList = this.inDisplayIcon.get(param);
      if (iconList) {
        this.iconGroupObject?.removeAnnotationObjects(iconList);
        this.inDisplayIcon.remove(param);
      }

      // text
      const textGroup = this.inDisplayTextObjects.get(param);
      if (textGroup) {
        const material = textGroup.material;
        material.initTexture();
        this.reuseableMaterials.enqueue(material);
        this.inDisplayTextObjects.remove(param);
      }
    }

    // 新しく描画する必要がある注記を追加する
    let createTextCount = 0;
    for (const [param, dataList] of requiredAnnotationMap.entries()) {
      if (createTextCount >= 1) {
        break;
      }

      const textDataList: TextAnnotationData[] = [];
      const iconDataList: D[] = [];
      for (const data of dataList) {
        if (data instanceof TextAnnotationData) {
          textDataList.push(data);
        } else {
          iconDataList.push(data);
        }
      }

      // オブジェクト生成
      // icon
      if (!this.inDisplayIcon.has(param)) {
        const iconObjectMap = this.createAnnotationObjectMap(iconDataList);
        this.iconGroupObject?.addAnnotationObjects(iconObjectMap);
        if (iconObjectMap.size > 0) {
          this.inDisplayIcon.add(param, Array.from(iconObjectMap.keys()));
        }
      }

      // text
      if (!this.inDisplayTextObjects.has(param)) {
        this.textDataListMap.set(param, textDataList);
        const textureMapping = this.createTextureMapping(param, textDataList);
        if (!textureMapping) {
          continue;
        }
        const textObjectList = this.createTextAnnotationObjectList(textDataList, textureMapping);
        if (textObjectList.length > 0) {
          const geometry = new CustomGeometry([], []);
          const material =
            this.reuseableMaterials.dequeue() ?? new TexturePlaneMaterial(this.context.getGLContext(), geometry, true);
          textObjectList.sort(this.compare.bind(this));
          const textObject = new TextAnnotationGroupObject(material, geometry, textObjectList);
          textObject.setAltitudeCallback(this.getAltitudeCallback);
          this.inDisplayTextObjects.add(param, textObject);
          createTextCount++;

          const drawTileFontInfo = this.textureHelper.createDrawTileFontInfo(textDataList, this.fontFamilyMap);
          if (drawTileFontInfo) {
            this.textFontInfo.set(param, drawTileFontInfo);
          }
        }
      }
    }

    // 今回描画するべきものはここからの処理で更新
    // swap
    if (this.displayOptions.mode === 'swap') {
      let swapCount = 0;
      for (const mainParam of this.inDisplayTextObjects.keys()) {
        if (createTextCount + swapCount >= 1) {
          break;
        }
        const drawTileFontInfo = this.textFontInfo.get(mainParam);
        if (!drawTileFontInfo) {
          continue;
        }
        const newDrawTileFontInfo = this.textureHelper.recreateDrawTileFontInfo(
          drawTileFontInfo,
          this.fontFamilyMap,
          this.displayOptions.swapOptions.swapInterval
        );
        if (!newDrawTileFontInfo) {
          continue;
        }
        this.textTextureCache.remove(mainParam);
        const textDataList = this.textDataListMap.get(mainParam);
        if (!textDataList || textDataList.length === 0) {
          continue;
        }
        const textureMapping = this.createTextureMapping(mainParam, textDataList);
        if (!textureMapping) {
          continue;
        }
        const textObjectList = this.createTextAnnotationObjectList(textDataList, textureMapping);
        if (textObjectList.length <= 0) {
          continue;
        }
        const geometry = new CustomGeometry([], []);
        const material =
          this.reuseableMaterials.dequeue() ?? new TexturePlaneMaterial(this.context.getGLContext(), geometry, true);
        textObjectList.sort(this.compare.bind(this));
        this.inDisplayTextObjects.remove(mainParam);
        this.inDisplayTextObjects.add(mainParam, new TextAnnotationGroupObject(material, geometry, textObjectList));
        swapCount++;
      }
    }

    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    for (const group of this.inDisplayTextObjects.values()) {
      group.updateColliders(mapStatus.zoomLevel, mapStatus.polar);
    }
    this.iconGroupObject.updateColliders(mapStatus.zoomLevel, mapStatus.polar);

    if (!this.suspendCull) {
      this.pointLatLngList = [];
      this.cullObjects(cameraTargetPosition);
    }

    // 各注記の頂点座標更新
    this.updateAllObjects(cameraTargetPosition);
  }

  /**
   * 重なり間引きを行う
   * @param cameraTargetPosition 中心位置
   * @returns {void}
   */
  private cullObjects(cameraTargetPosition: Vector3): void {
    // text
    this.textCullHelper.clear();
    const allObjects: Array<AnnotationObject<TextAnnotationData>> = [];
    for (const group of this.inDisplayTextObjects.values()) {
      group.cullInGroup(this.camera, cameraTargetPosition);
      allObjects.push(...group.getDisplayingObjectList());
    }
    allObjects.sort((a: AnnotationObject<TextAnnotationData>, b: AnnotationObject<TextAnnotationData>) => {
      return b.data.priority - a.data.priority;
    });
    for (const object of allObjects) {
      const topLeft = this.camera.worldToClient(object.collider.rect.topLeft._subtract(cameraTargetPosition));
      const topRight = this.camera.worldToClient(object.collider.rect.topRight._subtract(cameraTargetPosition));
      const bottomLeft = this.camera.worldToClient(object.collider.rect.bottomLeft._subtract(cameraTargetPosition));
      const bottomRight = this.camera.worldToClient(object.collider.rect.bottomRight._subtract(cameraTargetPosition));
      if (!topLeft || !topRight || !bottomLeft || !bottomRight) {
        continue;
      }
      const visible = this.textCullHelper.canProvideSpace(topLeft, topRight, bottomLeft, bottomRight, object.data);
      object.setVisible(visible);

      if (visible && object.data.dot) {
        this.pointLatLngList.push(object.data.latlng);
      }
    }

    // icon
    if (!this.suspendCull) {
      this.iconGroupObject.cullInGroup(this.camera, cameraTargetPosition);
    }
  }

  /**
   * テキスト注記のAnnotationObject作成
   * @param dataList テキスト注記データリスト
   * @param textureMapping AnnotationTextureMapping
   * @returns AnnotationObjectリスト
   */
  private createTextAnnotationObjectList(
    dataList: TextAnnotationData[],
    textureMapping: AnnotationTextureMapping
  ): Array<AnnotationObject<TextAnnotationData>> {
    const textObjectList: Array<AnnotationObject<TextAnnotationData>> = [];
    for (const textData of dataList) {
      if (!(textData.textureData.getCacheKey() in textureMapping.details)) {
        continue;
      }
      const {latlng, textureData, tileSize, offset, anchor, angle} = textData;
      const position = calculateWorldCoordinate(latlng);
      const detail = textureMapping.details[textureData.getCacheKey()];
      const pixelRatio = tileSize / 256;
      const clientSize = new Size(detail.size.height / pixelRatio, detail.size.width / pixelRatio);
      const offsetPoint = new Point(offset.x / pixelRatio, offset.y / pixelRatio);
      const object = new AnnotationObject(textData, position, clientSize, detail.uv, anchor, offsetPoint, angle);
      textObjectList.push(object);
    }
    return textObjectList;
  }

  abstract createAnnotationObjectMap(dataList: D[]): Map<string, AnnotationObject<D>>;

  /**
   * 全ての注記の点の位置を更新する
   * @param cameraTargetPosition カメラのターゲット位置
   * @returns {void}
   */
  private updatePointObject(cameraTargetPosition: Vector3): void {
    if (!this.pointGeometry || !this.pointMaterial || !this.pointObject3D) {
      this.initializePointObject3D();
      return;
    }

    if (this.pointLatLngList.length <= 0) {
      this.pointGeometry.setVertices([]);
      this.pointMaterial.setGeometry(this.pointGeometry);
      return;
    }

    const basePosition = calculateWorldCoordinate(this.pointLatLngList[0]);

    const pointPosition = basePosition._subtract(cameraTargetPosition);
    this.pointObject3D?.setPosition(pointPosition);

    const vertices: number[] = this.pointLatLngList.flatMap((latLng: LatLng) => {
      const position = calculateWorldCoordinate(latLng).subtract(basePosition);
      return position.toArray();
    });
    this.pointGeometry.setVertices(vertices);
    this.pointMaterial.setGeometry(this.pointGeometry);
  }

  /**
   * 全Groupの描画更新
   * @param worldCenter 地図中心
   * @returns {void}
   */
  private updateAllObjects(worldCenter: Vector3): void {
    for (const groupObject of this.inDisplayTextObjects.values()) {
      groupObject.updateAnnotationGroup(worldCenter);
    }
    this.iconGroupObject.updateAnnotationGroup(worldCenter);
    this.updatePointObject(worldCenter);
    this.notifyUpdate?.();
  }

  /**
   * AnnotationTextureMapping作成
   * @param param AnnotationMainParameter
   * @param textDataList テキスト注記データリスト
   * @returns AnnotationTextureMapping
   */
  private createTextureMapping(param: P, textDataList: TextAnnotationData[]): Optional<AnnotationTextureMapping> {
    const cache = this.textTextureCache.get(param);
    if (cache) {
      return cache;
    }

    const textTextureDataList: TextAnnotationTextureData[] = [];
    textDataList.map((data) => textTextureDataList.push(data.textureData));
    if (textTextureDataList.length > 0) {
      const createdMapping = this.textureHelper.createTextTextureByTile(
        textTextureDataList,
        textDataList[0].tileSize / 256,
        this.fontFamilyMap
      );
      if (!createdMapping) {
        return undefined;
      }
      this.textTextureCache.add(param, createdMapping);
      return createdMapping;
    }

    return undefined;
  }

  /**
   * 比較関数
   * @param a AnnotationObject
   * @param b AnnotationObject
   * @returns 判定結果
   */
  private compare(a: AnnotationObject<NoteAnnotationData>, b: AnnotationObject<NoteAnnotationData>): number {
    if (!isNoteAnnotationData(a.data) || !isNoteAnnotationData(b.data)) {
      return 0;
    }

    // テキストよりアイコンを優先
    if (a.data instanceof IconAnnotationData && b.data instanceof TextAnnotationData) {
      return -1;
    } else if (a.data instanceof TextAnnotationData && b.data instanceof IconAnnotationData) {
      return 1;
    }

    // 強制フラグチェック
    if (a.data.isForce && b.data.isForce) {
      // isForce同士の場合のプライオリティチェック
      if (!(a.data.priority === b.data.priority)) {
        return b.data.priority - a.data.priority;
      }

      // プライオリティが同じ場合はNTJコードが低いものを優先
      if (!(a.data.ntjCode === b.data.ntjCode)) {
        return a.data.ntjCode - b.data.ntjCode;
      }

      return 0;
    }

    if (a.data.isForce && !b.data.isForce) {
      return -1;
    } else if (!a.data.isForce && b.data.isForce) {
      return 1;
    }

    // プライオリティチェック
    if (a.data.priority === b.data.priority && a.data.angle === 0 && b.data.angle === 0) {
      // プライオリティが同じ場合はNTJコードが低いものを優先
      if (!(a.data.ntjCode === b.data.ntjCode)) {
        return a.data.ntjCode - b.data.ntjCode;
      }

      return 0;
    }

    if (!(a.data.priority === b.data.priority)) {
      return b.data.priority - a.data.priority;
    }

    // プライオリティが同じ場合はNTJコードが低いものを優先
    if (!(a.data.ntjCode === b.data.ntjCode)) {
      return a.data.ntjCode - b.data.ntjCode;
    }

    if (a.data.angle === 0 && b.data.angle !== 0) {
      return -1;
    } else if (a.data.angle !== 0 && b.data.angle === 0) {
      return 1;
    }

    return 0;
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (!this.visible || (this.textTextureCache.size() === 0 && !this.iconTextureCache)) {
      // 描く情報がなければ更新済み扱い
      return true;
    }

    let setTextureCount = 0;
    if (!this.iconGroupObject.isSetTexture && this.iconTextureCache) {
      this.iconGroupObject.setTexture(this.iconTextureCache);
      setTextureCount++;
    }

    for (const [param, group] of this.inDisplayTextObjects.entries()) {
      if (setTextureCount < MAX_SET_TEXTURE_COUNT && !group.isSetTexture) {
        const textureMapping = this.textTextureCache.get(param);
        if (!textureMapping) {
          continue;
        }
        group.setTexture(textureMapping.texture);
        setTextureCount++;
      }
    }

    let existsUnsetTextureTile = false;
    this.iconGroupObject.update(viewMatrix, projectionMatrix);
    this.iconGroupObject.draw();
    for (const group of this.inDisplayTextObjects.values()) {
      if (!group.isSetTexture) {
        existsUnsetTextureTile = true;
        continue;
      }
      group.update(viewMatrix, projectionMatrix);
      group.draw();
    }

    this.pointObject3D?.update(viewMatrix, projectionMatrix);
    this.pointObject3D?.draw();

    return setTextureCount === 0 && !existsUnsetTextureTile;
  }

  /** @override */
  abstract getIdenticalLayerName(): string;

  /** @override */
  destroy(): void {
    if (this.updateIntervalId) {
      clearInterval(this.updateIntervalId);
    }
    while (this.reuseableMaterials.size > 0) {
      const material = this.reuseableMaterials.dequeue();
      material?.destroy();
    }
    for (const textGroup of this.inDisplayTextObjects.values()) {
      textGroup.destroy();
    }
    this.inDisplayTextObjects.clear();
    this.iconGroupObject.destroy();
    this.pointObject3D?.destroy();
    this.isDestroyed = true;
  }

  abstract getCollisions(ray: Ray3): Collision[];

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {AbstractNoteAnnotationLayer};
