import {CenterMarkerCondition} from '../../../../../gaia/value/CenterMarkerCondition';
import {GaiaContext} from '../../../GaiaContext';
import {CenterMarkerObjectLayer, LAYER_NAME_CENTER_MARKER} from '../../../layer/CenterMarkerObjectLayer';
import {MapStatus} from '../../../models/MapStatus';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';

/**
 * 十字マーカーを扱う描画キット
 */
class CenterMarkerObjectRenderKit extends AbstractObjectRenderKit {
  private readonly centerMarkerObjectLayer: CenterMarkerObjectLayer;

  /**
   * コンストラクタ
   * @param context Context
   * @param centerMarkerObjectLayer CenterMarkerObjectLayer
   * @param renderKitCtl MapRenderKitController
   */
  constructor(
    context: GaiaContext,
    centerMarkerObjectLayer: CenterMarkerObjectLayer,
    renderKitCtl: MapRenderKitController
  ) {
    super(context, centerMarkerObjectLayer, renderKitCtl);
    this.centerMarkerObjectLayer = centerMarkerObjectLayer;
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return LAYER_NAME_CENTER_MARKER;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.centerMarkerObjectLayer.update(mapStatus);
  }

  /** @override */
  onDestroy(): void {
    this.centerMarkerObjectLayer.destroy();
  }

  /**
   * コンディションを設定
   * @param condition コンディション
   * @returns {void}
   */
  setCondition(condition?: CenterMarkerCondition): void {
    this.centerMarkerObjectLayer.setCondition(condition);
  }
}

export {CenterMarkerObjectRenderKit};
