import {
  Color,
  GLMarkerIconInfo,
  LatLng,
  Point,
  Size,
  ZoomRange,
  GeoJsonFigureCondition,
  TrainRouteAppearance,
} from './value';
import {GLMarker, InfoWindow, LatLngRect, Marker, UserLocation, Polyline} from './object';
import {MultiPolygon} from './value/GeoJson';
import {RoadShapeOpenedAppearance} from './value/RoadShapeOpenedAppearance';

/** Jsonオブジェクトの型表現 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type JsonObject = any;

/** プロパティ・値がともに文字列となるオブジェクトの型表現 */
type ParameterKeyValue = {[key: string]: string};

/** 現在見えている地図の状態 */
type MapLocationStatus = {
  /** 中心緯度経度 */
  center: LatLng;
  /** 現在のズームレベル */
  zoom: number;
  /** 回転角 */
  heading: number;
  /** 傾き */
  tilt: number;
};

/** GaIAで扱うイベントオブジェクト */
type GaIAEvent =
  | UIEvent
  | MapLocationStatus
  | InfoWindowEvent
  | MarkerEvent
  | GLMarkerEvent
  | UserLocationEvent
  | ContextMenuEvent
  | PolylineEvent
  | GeoJsonFigureEvent;

/** イベントリスナーに設定するオプション */
type GaIAEventListenerOptions = {
  /** イベントリスナーの適用を1度だけにするか */
  once?: boolean;
};

/** GaIA内で扱うイベント一覧 */
type GaIAEventMap = {
  /* MapEvent */
  zoomchanged: MapLocationStatus;
  centermoved: MapLocationStatus;

  /* MouseEvent */
  // default browser events
  mousedown: MouseEvent;
  mouseup: MouseEvent;
  mousemove: MouseEvent;
  click: ExtendedMouseEvent;
  dblclick: MouseEvent;
  mouseenter: MouseEvent;
  mouseover: MouseEvent;
  mouseleave: MouseEvent;
  contextmenu: MouseEvent;
  // custom events
  dragstart: MouseEvent;
  drag: MouseEvent;
  dragend: MouseEvent;
  inertia_mousemove: ExtendedMouseEvent;

  /* WheelEvent */
  // default browser events
  wheel: WheelEvent;
  // custom events
  wheelup: WheelEvent;
  wheeldown: WheelEvent;
  wheelleft: WheelEvent;
  wheelright: WheelEvent;

  /* TouchEvent */
  // default browser events
  touchstart: TouchEvent;
  touchend: TouchEvent;
  touchmove: TouchEvent;
  // custom events
  singletap: ExtendedTouchEvent;
  singlescroll: ExtendedTouchEvent;
  doubletap: ExtendedTouchEvent;
  pinch: ExtendedTouchEvent;
  rotation: ExtendedTouchEvent;
  tilt: ExtendedTouchEvent;
  singletap_2f: ExtendedTouchEvent;
  flick: ExtendedTouchEvent;
  longtapdetect: TouchEvent;
  longtap: TouchEvent;

  /* KeyboardEvent */
  // default browser events
  keydown: KeyboardEvent;
  keyup: KeyboardEvent;
  // custom events
  arrowkeydown: ExtendedKeyboardEvent;
  zoomkeydown: ExtendedKeyboardEvent;
};

/** 地図イベント一覧 */
type MapEventMap = Pick<GaIAEventMap, 'zoomchanged' | 'centermoved'>;

/** ブラウザイベント一覧 */
type BrowserEventMap = Pick<
  GaIAEventMap,
  | 'mousedown'
  | 'mouseup'
  | 'mousemove'
  | 'click'
  | 'dblclick'
  | 'mouseenter'
  | 'mouseleave'
  | 'contextmenu'
  | 'dragstart'
  | 'drag'
  | 'dragend'
  | 'inertia_mousemove'
  | 'wheel'
  | 'wheelup'
  | 'wheeldown'
  | 'wheelleft'
  | 'wheelright'
  | 'touchstart'
  | 'touchend'
  | 'touchmove'
  | 'singletap'
  | 'singletap_2f'
  | 'flick'
  | 'longtap'
  | 'longtapdetect'
  | 'singlescroll'
  | 'doubletap'
  | 'pinch'
  | 'rotation'
  | 'tilt'
  | 'keydown'
  | 'keyup'
  | 'arrowkeydown'
  | 'zoomkeydown'
>;

/** マウスイベント一覧 */
type MouseEventMap = Pick<
  BrowserEventMap,
  | 'mousedown'
  | 'mouseup'
  | 'mousemove'
  | 'click'
  | 'dblclick'
  | 'mouseenter'
  | 'mouseleave'
  | 'contextmenu'
  | 'dragstart'
  | 'drag'
  | 'dragend'
  | 'inertia_mousemove'
>;

/** ホイールイベント一覧 */
type WheelEventMap = Pick<BrowserEventMap, 'wheel' | 'wheelup' | 'wheeldown' | 'wheelleft' | 'wheelright'>;

/** タッチイベント一覧 */
type TouchEventMap = Pick<
  BrowserEventMap,
  | 'touchstart'
  | 'touchend'
  | 'touchmove'
  | 'singletap'
  | 'singlescroll'
  | 'doubletap'
  | 'pinch'
  | 'rotation'
  | 'tilt'
  | 'singletap_2f'
  | 'flick'
  | 'longtapdetect'
  | 'longtap'
>;

/** キーボードイベント一覧 */
type KeyboardEventMap = Pick<BrowserEventMap, 'keydown' | 'keyup' | 'arrowkeydown' | 'zoomkeydown'>;

/**
 * ブラウザのMouseEventを拡張したもの
 */
type ExtendedMouseEvent = MouseEvent & {
  deltaX?: number;
  deltaY?: number;
  /** カーソル位置の緯度経度 */
  position?: LatLng;
};

/**
 * ブラウザのTouchEventを拡張したもの
 */
type ExtendedTouchEvent = TouchEvent & {
  deltaX?: number[];
  deltaY?: number[];
  pinchDelta?: number;
  deltaRotation?: number;
  deltaTilt?: number;
  multiTouchCenter?: Point;
};
/**
 * ブラウザのKeyboardEventを拡張したもの
 */
type ExtendedKeyboardEvent = KeyboardEvent & {
  deltaX?: number;
  deltaY?: number;
  deltaZoomLevel?: number;
};

/**
 * 言語
 * - ja: 日本語
 * - en: 英語
 * - ko: 韓国語
 * - zh-TW: 繁体字
 * - zh-CN: 簡体字
 * - th: タイ語
 */
type Language = 'ja' | 'en' | 'ko' | 'zh-TW' | 'zh-CN' | 'th';

/** ズームレベルに対応するコピーライトのテーブル */
type CopyrightTable = {[zoom: number]: string[]};
/** 言語ごとのコピーライト情報 */
type LangCopyright = {[lang in Language]?: CopyrightTable};

/**
 * 地図データを取得するサーバー情報
 */
type ServerInformation = {
  /**プロダクトID (mochaのSID) */
  productId: string;
  /** 名称 (カスタムパレット名) */
  name?: string;
  /** サーバー向き先 */
  server: string;
};

/**
 * 地図初期化設定
 */
type MapInitSettings = {
  /** 地図表示をする要素 */
  target: string | HTMLElement;
  /** ズームレベル(整数値) */
  zoomLevel: number;
  /** 中心座標 */
  center: LatLng;
  /** オプション */
  options?: MapInitSettingOptions;
};

/**
 * コピーライト表示指定オプション
 */
type CopyrightOptions = {
  /** 表示状態設定(デフォルトはtrue) */
  visible?: boolean;
  /** 規定位置(右下)からのずらし幅 */
  offset?: Point;
  /** 文字色 */
  textColor?: string;
  /** 背景色 */
  backgroundColor?: string;
};

/**
 * 縮尺表示指定オプション
 */
type ScaleOptions = {
  /** 表示状態設定(デフォルトはtrue) */
  visible?: boolean;
  /** 規定位置(左下)からのずらし幅 */
  offset?: Point;
  /** スケールの色 */
  scaleColor?: string;
  /** 背景色 */
  backgroundColor?: string;
};

/**
 * 地図サーバーURLの設定
 */
type MapServerURL = {
  /** タイル地図の取得先URL */
  tile?: string;
  /** 航空衛星写真の取得先URL */
  satellite?: string;
  /** データの取得先URL */
  data?: string;
};

/**
 * タイル画像取得に関する設定
 */
type TileLoaderOptions = {
  /**
   * タイル画像取得にWebWorkerを使う(デフォルトはtrue, v1.29.0以降は参照されなくなりました)
   * @deprecated
   */
  withWebWorker?: boolean;
};

/**
 * Webフォント表示に関する設定（swapモード）
 */
type AnnotationDisplaySwapOptions = {
  mode: 'swap';
  swapOptions: {
    /** Webフォント更新間隔（単位はミリ秒、デフォルトは1000ms=1秒） */
    swapInterval: number;
  };
};

// type AnnotationDisplayOptionalOptions = {
//   mode: 'optional';
//   optionalOptions: {
//     blockLength: number;
//   };
// };

/**
 * Webフォント表示に関する設定
 */
type AnnotationDisplayOptions = AnnotationDisplaySwapOptions;

/**
 * 総称フォントファミリー
 */
enum GenericFontFamily {
  Serif = 0,
  SansSerif = 1,
  Monospace = 2,
  Cursive = 3,
  Fantasy = 4,
  SystemUi = 5,
  UiSerif = 6,
  UiSansSerif = 7,
  UiMonospace = 8,
  UiRounded = 9,
  Emoji = 10,
  Math = 11,
  Fangsong = 12,
}

/**
 * フォントファミリー
 */
type FontFamily = string | GenericFontFamily;

/**
 * フォントIDに対応するフォントファミリー
 */
type AnnotationFontFamilyMap = {
  sansSerif: FontFamily[];
  serif: FontFamily[];
  serifBold: FontFamily[];
  sansSerifBold: FontFamily[];
};

/**
 * 注記DBのオプション
 */
type AnnotationOptions = {
  /** 縁取り有り文字注記の縁取りの太さ（単位はピクセル） */
  textBorder?: number;
  /** Webフォントのfont-displayの指定 */
  display?: AnnotationDisplayOptions;
  /** Webフォントのフォントファミリーの設定 */
  fontFamily?: AnnotationFontFamilyMap;
};

/**
 * 地図初期化の追加設定オプション
 */
type MapInitSettingOptions = {
  /** 地図データ取得URL */
  server?: MapServerURL;
  /**
   * タイル種別
   * @default `tile`
   */
  tileType?: TileType;
  /**
   * 地図の言語
   * @default `ja`
   */
  language?: Language;
  /** コピーライト指定オプション */
  copyright?: CopyrightOptions;
  /** 縮尺表示オプション */
  scale?: ScaleOptions;
  /** ブランクタイル画像パス(Base64文字列も可) */
  blankTileImage?: string;
  /** ズームレンジ */
  zoomRange?: ZoomRange;
  /** 地図を表示できる範囲 */
  viewRange?: LatLngRect;
  /** 中心地点の基準位置オフセット */
  centerOffset?: Point;
  /**
   * 少数ズームレベルで上位・下位のどちらのタイルを描画するか
   * @default `lower`
   */
  tileZoomLevelFix?: TileZoomLevelFixValue;
  /**
   * タイル画像取得の設定(v1.29.0以降は参照されなくなりました)
   * @deprecated
   */
  tileLoader?: TileLoaderOptions;
  /**
   * ホイール操作による拡縮の有効・無効指定
   * @default `true`
   */
  wheelZoomEnable?: boolean;
  /**
   * 注記DB有効フラグ
   * @default `false`
   */
  isAnnotationEnabled?: boolean;
  /**
   * 注記DBオプション
   */
  annotationOptions?: AnnotationOptions;
  /**
   * 注記IndexedDBオプション
   */
  annotationIndexedDB?: AnnotationIndexedDBOptions;
  /**
   * 地図アイコンオプション
   */
  mapIconOptions?: MapIconConditionInitOptions;
  /**
   * 3Dビル表示フラグ
   * @default `true`
   */
  tileBuilding3D?: boolean;
  /**
   * 霧の色
   * @default 白
   */
  fogColor?: Color;
  /**
   * 空の色
   * @default シアン
   */
  skyColor?: Color;
  /**
   * 任意タイル地図オプション
   */
  additionTileOptions?: AdditionTileOptions;
};

/** タイル画像サイズ */
type TileSize = 256 | 512;

/**
 * タイル画像種別
 * - `tile` 通常タイル
 * - `satellite` 航空衛星写真タイル
 */
type TileType = 'tile' | 'satellite';

/**
 * 少数ズームレベルで上位・下位のどちらのタイルを描画するか
 * - `upper` 上位タイルを優先
 * - `lower` 下位タイルを優先
 * - `round` 四捨五入したもの
 */
type TileZoomLevelFixValue = 'upper' | 'lower' | 'round';

/** 混雑レベル */
type CongestionLevel = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

/**
 * 緯度経度の単位
 * - `degree` 度表記
 * - `millisec` ミリ秒表記
 */
type CoordUnit = 'degree' | 'millisec';

/* ContextMenu */
/** コンテキストメニューの項目 */
type ContextMenuItem = {
  /** 表示するテキスト */
  text: string;
  /** メニュークリック時の処理 */
  onselect: (e: ContextMenuEvent) => void;
};

/** コンテキストメニューのオプション */
type ContextMenuOptions = {
  /**
   * ズームレベルが切り替わったときにメニューを非表示にするかどうか
   * @default true
   */
  hideWhenZoomChange: boolean;
};

/** コンテキストメニュークリック時のイベント */
type ContextMenuEvent = {
  /** その地点の緯度経度 */
  position: LatLng;
  /** 元のマウスイベント */
  native: MouseEvent;
};

/* Marker */
/**
 * マーカー基準位置<br>
 * (画像のどの位置に対して指定緯度経度地点を置くか)
 * - `top-left`: 左上
 * - `top`: 上
 * - `top-right`: 右上
 * - `left`: 左
 * - `center`: 中央
 * - `right`: 右
 * - `bottom-left`: 左下
 * - `bottom`: 下
 * - `bottom-right`: 右下
 */
type MarkerGravity =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'left'
  | 'center'
  | 'right'
  | 'bottom-left'
  | 'bottom'
  | 'bottom-right';

/**
 * マーカーに設定できるアニメーション<br>
 * - `fadeIn`: フェードイン
 * - `drop`: 落下
 */
type MarkerAnimationType = 'fadeIn' | 'drop';

/**
 * マーカー上に表示する文字ラベルのCSS設定<br>
 * ※ CSSStyleDeclaration に定義されているものの一部を利用可能
 */
type MarkerLabelStyle = {
  /** 文字サイズ */
  fontSize?: string;
  /** 文字のウェイト */
  fontWeight?: string;
  /** 文字色 */
  color?: string;
  /** 背景色 */
  backgroundColor?: string;
  /** 境界線 */
  border?: string;
  /** 余白 */
  padding?: string;
  /** テキストの影 */
  textShadow?: string;
};

/**
 * マーカー上に表示する文字ラベルの設定
 */
type MarkerLabelOptions = {
  /** ラベル文字列 */
  content: string;
  /** オフセット */
  offset?: Point;
  /** ラベルのスタイル */
  style?: MarkerLabelStyle;
};

/**
 * マーカー初期化オプション
 */
type MarkerInitOptions = {
  /** アイコン画像パス (Base64文字列も可) */
  icon: string;
  /** 表示位置の緯度経度 */
  position: LatLng;
  /** マーカー名 */
  name?: string;
  /**
   * マーカー基準位置
   * @default `bottom`
   */
  gravity?: MarkerGravity;
  /** マーカーの表示サイズ */
  size?: Size;
  /** オフセット */
  offset?: Point;
  /** 文字ラベル */
  label?: MarkerLabelOptions;
  /**
   * ドラッグ操作可否
   * @default `false`
   */
  draggable?: boolean;
  /** アニメーション */
  animation?: MarkerAnimationType;
  /** カスタムクラス名（半角スペース区切りで複数指定可能） */
  className?: string;
  /** 重なり順 */
  zIndex?: number;
};

/** Markerで発火するイベント */
type MarkerEvent = {
  /** 発火元インスタンス */
  sourceObject: Marker;
  /** UIイベント */
  native?: UIEvent;
};

/** Markerが発行するイベント一覧 */
type MarkerEventMap = {
  /** マーカーがクリックされたとき */
  click: MarkerEvent;
  /** マーカーの緯度経度が変化したとき */
  position_changed: MarkerEvent;
  /** マーカーの表示 */
  appear: MarkerEvent;
  /** マーカーの非表示 */
  disappear: MarkerEvent;
};

/**
 * Markerのプロパティ
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MarkerProperties = Record<string, any>;

/* GL Marker */
/** GLマーカー初期化オプション */
type GLMarkerInitOptions = {
  /** 表示位置の緯度経度 */
  position: LatLng;
  /** マーカー画像情報 */
  info: GLMarkerIconInfo;
  /**
   * 表示状態
   * @default true
   */
  isVisible?: boolean;
  /**
   * ドラッグ操作可否
   * @default false
   */
  draggable?: boolean;
  /** 文字ラベル */
  label?: GLMarkerLabelOptions;
  /** マーカー名 */
  name?: string;
  /** z-index（デフォルトは0） */
  zIndex?: number;
};

/**
 * GLマーカー上に表示する文字ラベルの設定
 */
type GLMarkerLabelOptions = {
  /** ラベル文字列 */
  content: string;
  /** オフセット */
  offset?: Point;
  /** ラベルのスタイル */
  style?: GLMarkerLabelStyle;
};

/**
 * GLマーカー上に表示する文字ラベルのスタイル設定
 */
type GLMarkerLabelStyle = {
  /** 文字サイズ */
  fontSize?: string;
  /** 文字のウェイト */
  fontWeight?: string;
  /** 文字色 */
  color?: string;
  /** 背景色 */
  backgroundColor?: string;
  /** 境界線の色 */
  borderColor?: string;
  /** 境界線の太さ */
  borderWidth?: number;
  /**
   * 余白の上下左右一括指定
   * ※ top, bottom, left, right指定時はそちらを優先
   */
  padding?: number;
  /** 上側の余白幅 */
  paddingTop?: number;
  /** 下側の余白幅 */
  paddingBottom?: number;
  /** 左側の余白幅 */
  paddingLeft?: number;
  /** 右側の余白幅 */
  paddingRight?: number;
  /** ラベルの水平方向の位置 */
  align?: 'left' | 'center';
  /** 文字の縁取りの色 */
  outline?: string;
};

/** GLMarkerで発火するイベント */
type GLMarkerEvent = {
  /** 発火元インスタンス */
  sourceObject: GLMarker;
  /** UIイベント */
  native?: UIEvent;
};

/** GLMarkerが発行するイベント一覧 */
type GLMarkerEventMap = {
  /** マーカーがクリックされたとき */
  click: GLMarkerEvent;
  /** マーカーの緯度経度が変化したとき */
  position_changed: GLMarkerEvent;
  /** マーカーの表示 */
  appear: GLMarkerEvent;
  /** マーカーの非表示 */
  disappear: GLMarkerEvent;
  /** ドラッグ操作中 */
  dragging: GLMarkerEvent;
  /** ドラッグ操作終了時 */
  dragend: GLMarkerEvent;
  /** マーカーにマウスポインタが乗ったとき */
  mouseover: GLMarkerEvent;
  /** マーカーからマウスポインタが降りたとき */
  mouseout: GLMarkerEvent;
};

/** マーカー画像情報初期化オプション */
type GLMarkerIconInfoInitOptions = {
  /** 画像パス(Base64文字列も可) */
  icon: string;
  /**
   * 表示サイズ
   * @default 50px × 50px
   */
  size?: Size;
  /**
   * 基準位置
   * @default bottom
   */
  gravity?: MarkerGravity;
};

/** User Location Marker */
/** 自位置マーカー初期化オプション */
type UserLocationInitOptions = {
  /** 画像情報 */
  info: GLMarkerIconInfo;
  /**
   * 投影モード
   * @default orthographic
   */
  projectionMode?: UserLocationProjectionMode;
};

/** UserLocationで発火するイベント */
type UserLocationEvent = {
  /** 発火元インスタンス */
  sourceObject: UserLocation;
  /** UIイベント */
  native?: UIEvent;
};

/** UserLocationが発行するイベント一覧 */
type UserLocationEventMap = {
  /** マーカーの表示 */
  appear: UserLocationEvent;
  /** マーカーの非表示 */
  disappear: UserLocationEvent;
};

/** トラッキングモード */
type UserLocationTrackingMode = 'none' | 'follow' | 'heading_up';

/** 自位置マーカーの投影モード */
type UserLocationProjectionMode = 'perspective' | 'orthographic';

/* InfoWindow */
/** 吹き出しの表示方向 */
type InfoWindowTailPosition = 'none' | 'left' | 'right' | 'top' | 'bottom';

/** InfoWindow初期化オプション */
type InfoWindowInitOptions = {
  /** 表示要素(文字列 or HTML文字列 or DOM要素) */
  content: string | HTMLElement;
  /** 表示位置の緯度経度 */
  position: LatLng;
  /** 識別用名称 */
  name?: string;
  /**
   * 吹き出しの表示方向
   * @default `bottom`
   */
  tail?: InfoWindowTailPosition;
  /** 表示位置のずらし幅 */
  offset?: Point;
  /**
   * 閉じるボタンの表示有無
   * @default `true`
   */
  showClose?: boolean;
  /** 任意のクラス名設定（半角スペース区切りで複数指定可能） */
  className?: string;
  /** 吹き出しの最大幅 */
  maxWidth?: number | string;
  /** 重なり順 */
  zIndex?: number;
  /**
   * 表示設定
   * @default `true`
   */
  visible?: boolean;
};

/** InfoWindowで発火するイベント */
type InfoWindowEvent = {
  /** 発火元インスタンス */
  sourceObject: InfoWindow;
  /** UIイベント */
  native?: UIEvent;
};

/** InfoWindowが発行するイベント一覧 */
type InfoWindowEventMap = {
  /** InfoWindowのオープン */
  open: InfoWindowEvent;
  /** InfoWindowのクローズ */
  close: InfoWindowEvent;
  /** InfoWindowがクリックされたとき */
  click: InfoWindowEvent;
};

/* Figure系 */
/** 円を作成するときのオプション */
type CircleOptions = {
  /**
   * 表示設定
   * @default `true`
   */
  visible?: boolean;
  /** 重なり順（大きければ大きいほど手前に表示される、デフォルト値は一番手前） */
  zIndex?: number;
  /** 円の中心緯度経度 */
  center: LatLng;
  /** 円の半径（単位はメートル） */
  radius: number;
  /**
   * 円の内側を塗りつぶす色
   * @default `#000000`
   */
  fillColor?: Color;
  /**
   * 円の縁線の色
   * @default `#000000`
   */
  strokeColor?: Color;
  /**
   * 円の縁線の太さ(px)
   * @default 1
   */
  strokeWeight?: number;
  /** 点線のパターンを塗りと休みで交互に指定する（単位はピクセル、デフォルトは実線） */
  strokeDashArray?: number[];
};

/**
 * ポリゴン生成オプション
 */
type PolygonOptions = {
  /**
   * 表示設定
   * @default `true`
   */
  visible?: boolean;
  /** 重なり順（大きければ大きいほど手前に表示される、デフォルト値は一番手前） */
  zIndex?: number;
  /** ポリゴンの形状 */
  paths: LatLng[];
  /**
   * ポリゴンの内側を塗りつぶす色
   * @default `#000000`
   */
  fillColor?: Color;
  /**
   * ポリゴンの縁線の色
   * @default `#000000`
   */
  strokeColor?: Color;
  /**
   * ポリゴンの縁線の太さ(px)
   * @default 1
   */
  strokeWeight?: number;
  /** 点線のパターンを塗りと休みで交互に指定する（単位はピクセル、デフォルトは実線） */
  strokeDashArray?: number[];
};

/**
 * ポリライン生成オプション
 */
type PolylineOptions = {
  /**
   * 表示設定
   * @default `true`
   */
  visible?: boolean;
  /** 重なり順（大きければ大きいほど手前に表示される、デフォルト値は一番手前） */
  zIndex?: number;
  /** ポリラインの形状 */
  path: LatLng[];
  /**
   * ポリラインの色（デフォルト値は黒）
   * @default `#000000`
   */
  strokeColor?: Color;
  /**
   * ポリラインの太さ(px)
   * @default 1
   */
  strokeWeight?: number;
  /** 点線のパターンを塗りと休みで交互に指定する（単位はピクセル、デフォルトは実線） */
  strokeDashArray?: number[];
};

/** ポリラインで発火するイベント */
type PolylineEvent = {
  /** 発火元インスタンス */
  sourceObject: Polyline;
  /** イベントが発生した緯度経度 */
  position?: LatLng;
};

/** ポリラインが発行するイベント一覧 */
type PolylineEventMap = {
  /** ポリラインがクリックされたとき */
  click: PolylineEvent;
  /** ポリラインにマウスオーバーしたとき */
  mouseover: PolylineEvent;
};

/* GeoJSON */
/**
 * GeoJSON形状オプション
 */
type GeoJsonFigureConditionInitOptions = {
  /** ポリゴンのオプション */
  polygon?: {
    /**
     * 色
     * @default #00FF00
     */
    color: Color;
  };

  /** ポリラインのオプション */
  polyline?: {
    /** 内線のオプション */
    inline?: {
      /**
       * 色
       * @default #00FF00
       */
      color?: Color;
      /**
       * 幅
       * @default 5
       */
      weight?: number;
      /**
       * 点線のパターン
       * 数値の配列で点線のパターンを指定する
       * 例えば [2, 1] と指定すると次のようなパターンで点線を描画する
       * 2*weightピクセル塗り、1*weightピクセル休み、2*weightピクセル塗り、...
       * @default 実線
       */
      dashArray?: number[];
    };

    /** 縁線のオプション */
    outline?: {
      /**
       * 色
       * @default #008000
       */
      color?: Color;
      /**
       * 幅(※inline.weightよりも大きい値にすること)
       * @default 10
       */
      weight?: number;
      /**
       * 点線のパターン
       * 数値の配列で点線のパターンを指定する
       * 例えば [2, 1] と指定すると次のようなパターンで点線を描画する
       * 2*weightピクセル塗り、1*weightピクセル休み、2*weightピクセル塗り、...
       * @default 実線
       */
      dashArray?: number[];
    };
  };

  /**
   * mochaの/route/shapeから取得したGeoJSONか<br>
   * ※true: color, weight未設定時、properties値をもとに描画
   * @default `false`
   */
  isRouteShape?: boolean;

  /**
   * ルート線描画時の矢印を表示するかどうか
   * @default `false`
   */
  showRouteArrow?: boolean;

  /**
   * 重なり順(大きいほど手前に表示)
   * @default 最も手前
   */
  zIndex?: number;

  /**
   * 緯度経度の単位
   * @default `degree`
   */
  coordUnit?: CoordUnit;
};

/** GeoJSON形状で発火するイベント */
type GeoJsonFigureEvent = {
  /** 発火元インスタンス */
  sourceObject: GeoJsonFigureCondition;
  /** イベントが発生した緯度経度 */
  position?: LatLng;
};

/** GeoJSON形状が発行するイベント一覧 */
type GeoJsonFigureEventMap = {
  /** GeoJSON形状がクリックされたとき */
  click: GeoJsonFigureEvent;
  /** GeoJSON形状にマウスオーバーしたとき */
  mouseover: GeoJsonFigureEvent;
};

/**
 * 地図アイコンの見た目の設定
 */
type MapIconConditionPaletteConfig = {
  /**
   * 表示するズームレベルの範囲
   */
  zoomRange: ZoomRange;
  /**
   * アイコンの大きさ（デフォルト値は100）
   */
  size?: number;
  /**
   * アイコン画像の名前（デフォルトは属性の16進数表記）
   */
  name?: string;
};

/**
 * 地図アイコンのアイコンに関する設定
 */
type MapIconConditionInitOptionsIcon = {
  /** アイコン同士の衝突判定に持たせるマージン（単位はピクセル） */
  collisionMargin?: Point;
};

/**
 * 地図アイコンオプション
 */
type MapIconConditionInitOptions = {
  /**
   * カテゴリタグとその見た目の設定
   */
  tags: {[tag: string]: MapIconConditionPaletteConfig[]};
  /**
   * ライセンス照合に使うプロダクトID(デフォルトは"tile")
   */
  product?: string;
  /**
   * アイコンに関する設定
   */
  icon?: MapIconConditionInitOptionsIcon;
};

/* traffic */
/**
 * 渋滞情報提供元
 * * `vics` : VICS
 * * `probe` : プローブ
 * * `both` : VICS + プローブ
 * * `none` : 非表示
 */
type TrafficJamSourceType = 'vics' | 'probe' | 'both' | 'none';

/**
 * 大型車・危険物積載車規制情報オプション
 */
type RoadRegulationOptions = {
  /**
   * 規制時刻
   * @default 現在時刻
   */
  regulationTime?: Date;
  /**
   * 車種
   *  @see http://ntj-map-spec.s3-website-ap-northeast-1.amazonaws.com/mars/latest/roadregulation/index.html#car-type-code
   */
  carType: number;
  /** 車高(cm) */
  height: number;
  /** 車幅(cm) */
  width: number;
  /** 車体長(cm) */
  length: number;
  /** 車両重量(kg) */
  weight: number;
  /** 最大積載量(kg) */
  maxLoad: number;
  /** 危険物積載有無 */
  dangerousGoods?: boolean;
};

/**
 * 定義規制種別
 * * `winter` : 冬季通行止め
 * * `disaster` : 災害通行止め
 * * `bike` : 二輪車規制
 */
type DefinedRegulationCategory = 'winter' | 'disaster' | 'bike';

/**
 * 定義規制表示オプション
 */
type DefinedRegulationOptions = {
  /** 規制種別(複数指定可) */
  category: DefinedRegulationCategory[];
  /** (二輪車のみ)タンデム規制 */
  tandem?: boolean;
  /**
   * 排気量指定
   * * `all` : 全排気量
   * * 数字配列 : 対象の排気量
   */
  displacement?: 'all' | number[];
};

/**
 * 交通情報表示
 */
type TrafficConditionInitOptions = {
  /** 交通情報提供元 */
  source: TrafficJamSourceType;
  /** 集計用UID(VICS表示で必須) */
  uid?: string;
  /**
   * 交通情報/大型車・定義規制情報を取得する情報の対象時刻<br>
   * ※個別の時間を指定したい場合は `trafficTime` および `regulationTime` を利用してください
   * @default 現在時刻
   */
  time?: Date;
  /**
   * 交通情報の対象時刻<br>
   * ※ `time` と両方指定されている場合はこちらが優先されます
   * @default 現在時刻
   */
  trafficTime?: Date;
  /** 渋滞線の矢印表示 */
  showArrow?: boolean;
  /** 大型車・危険物積載車規制情報オプション */
  roadRegulation?: RoadRegulationOptions;
  /** 定義規制情報オプション */
  definedRegulation?: DefinedRegulationOptions;
  /**
   * 大型車・危険物積載車規制・定義規制情報の対象時刻
   * ※ `time` と両方指定されている場合はこちらが優先されます
   * @default 現在時刻
   */
  regulationTime?: Date;
};

/**
 * レベル値の粒度
 * - `normal` 通常
 * - `detail` 詳細
 */
type LevelType = 'normal' | 'detail';

/* rainfall */
/**
 * 降雨・降雪情報表示オプション
 */
type RainfallConditionInitOptions = {
  /** 取得する情報の対象分 */
  offset?: number;
  /** 取得する情報の対象日時 */
  time?: Date;
  /**
   * レベル値の粒度
   * @default `normal`
   */
  levelType?: LevelType;
};

/* rainfall gradation */
/**
 * 降雨・降雪情報グラデーション表示オプション
 */
type RainfallGradationConditionInitOptions = {
  /** 取得する情報の対象分 */
  offset?: number;
  /** 取得する情報の対象日時 */
  time?: Date;
  /** 色の設定 */
  colorMap?: Map<number, Color>;
};

/* thunder */
/**
 * 雷ナウキャスト情報表示オプション
 */
type ThunderConditionInitOptions = {
  /** 取得する情報の対象分 */
  offset?: number;
  /** 取得する情報の対象日時 */
  time?: Date;
};

/* snowfall */
/**
 * 積雪深情報表示オプション
 */
type SnowfallConditionInitOptions = {
  /** 取得する情報の対象分 */
  offset?: number;
  /** 取得する情報の対象日時 */
  time?: Date;
};

/* pollen */
/**
 * 花粉情報表示オプション
 */
type PollenConditionInitOptions = {
  /** 取得する情報の対象分 */
  offset?: number;
  /** 取得する情報の対象日時 */
  time?: Date;
};

/* congestion */
/**
 * 混雑度情報表示オプション
 */
type CongestionConditionInitOptions = {};

/* typhoon */
/**
 * 台風情報表示オプション
 */
type TyphoonConditionInitOptions = {
  /** 日付フォーマット */
  dateFormat?: string;
};

/* indoor */
/**
 * 屋内地図表示オプション
 */
type IndoorConditionInitOptions = {
  /** フロアのリストが変化したときに実行させるコールバック関数 */
  onFloorListChangedCallback: (floorList: string[]) => void;
  /** 屋内地図メタデータ */
  metadata: IndoorMetadata;
  /** 背景色（デフォルトは半透明の黒） */
  backgroundColor?: Color;
};

/* landmark */
/**
 * 3Dランドマーク表示オプション
 */
type LandmarkConditionInitOptions = {
  /** 3Dランドマークを表示するズームレンジ（デフォルトは15~20） */
  visibleZoomRange?: ZoomRange;
  /** 3Dランドマークをミニチュア表示する最大ズームレベル（デフォルトは16） */
  maxZoomLevelMiniature?: number;
};

/**
 * 屋内メタデータのエリア
 */
type IndoorMetadataArea = {
  /** フロアのリスト */
  floors: string[];
  /** エリアの名前 */
  name: string;
  /** エリアのID */
  area_id: number;
  /** エリアの形状（GeoJSONのMultiPolygon） */
  geometry: MultiPolygon;
};

/**
 * 屋内メタデータ
 */
type IndoorMetadata = {
  areas: IndoorMetadataArea[];
};

/* Annotation */
/** 注記タイプ */
type AnnotationType = 'text' | 'icon' | 'mapIcon';

/** 注記データ */
type AnnotationData = {
  /** タイプ */
  type: AnnotationType;
  /** 緯度経度 */
  latlng: LatLng;
  /** 名称 */
  appearance?: string;
  /** 乗換検索用ノードID */
  nodeId?: string;
};

/** 注記クリックリスナー */
type AnnotationClickListener = (data: AnnotationData) => void;

/** 注記クリックリスナー設定オプション */
type AnnotationClickListenerOptions = {
  /**
   * アイコンクリックを有効にする
   * @default `true`
   */
  isIconClickable?: boolean;
  /**
   * テキストクリックを有効にする
   * @default `true`
   */
  isTextClickable?: boolean;
  /**
   * クリック対象をノードIDがあるもののみに絞るか <br>
   * ※ `isIconClickable` および `isTextClickable` の値を優先
   * @default `false`
   */
  isNodeOnly?: {
    /** アイコン */
    icon: boolean;
    /** テキスト */
    text: boolean;
  };
  /** イベントリスナーの適用を1度だけにするか */
  once?: boolean;
};

type MapIconData = {
  /**
   * スポットの名前
   */
  name?: string;
  /**
   * 緯度経度
   */
  latlng: LatLng;
  /**
   * スポットID
   */
  spotId?: string;
  /**
   * 住所文字列
   */
  address?: string;
  /**
   * 郵便番号の文字列
   */
  postalCode?: string;
  /**
   * 電話番号の文字列
   */
  phone?: string;
  /**
   * 提供者ID
   */
  providerId?: string;
};

type MapIconClickListener = (data: MapIconData) => void;

type MapIconMouseEnterListener = (data: MapIconData) => void;

type MapIconClickListenerOptions = {
  /**
   * アイコンクリックを有効にする
   * @default `true`
   */
  isIconClickable?: boolean;
  /** イベントリスナーの適用を1度だけにするか */
  once?: boolean;
};

/** external annotation */
/** 社外由来注記オプション */
type ExternalAnnotationInitOptions = {
  /** 表示するズームレベル範囲 */
  zoomRange?: ZoomRange;
  iconSize?: Size;
  collisionMarginForAllIcon?: number;
  collisionMarginForSameIcon?: number;
};

/** 社外由来注記フィーチャー */
type ExternalAnnotationFeature = {
  geometry: {coordinates: number[]; type: string};
  properties: ExternalAnnotationProperty;
  type: string;
};

/** 社外由来注記プロパティ */
type ExternalAnnotationProperty = {
  address_ja: string;
  address_remark: string;
  auction_id: string;
  business_hours: string;
  business_hours_remark: string;
  category: string;
  cps: string;
  feature_id: string;
  icon: string;
  min_zoom: number;
  name_ja: string;
  phone_number: string;
  profile: {
    media: boolean;
    new: boolean;
    product: boolean;
  };
  promotion_banner: string;
  promotion_url: string;
  summary: string;
  title: string;
};

/** 社外由来注記のコールバック */
type ExternalAnnotationCallback = (tileSize: TileSize) => ExternalAnnotationPluginMap;

/** 社外由来注記クリックリスナー */
type ExternalAnnotationClickListener = (
  feature: ExternalAnnotationFeature,
  pluginMap: ExternalAnnotationPluginMap
) => void;

/** 社外由来注記プラグイン */
// eslint-disable-next-line
type ExternalAnnotationPluginMap = {mopra: any};

type LoadingProgressListener = (progress: number) => void;

/** 注記用IndexedDBオプション */
type AnnotationIndexedDBOptions = {
  /**
   * indexedDB利用フラグ
   * @default `true`
   */
  useIndexedDB?: boolean;
  /**
   * レコード数上限
   * @default `3000`
   */
  recordMax?: number;
};

/** 軌跡(moon)表示オプション */
type OrbitConditionInitOptions = {
  /** 新規開通道路表示フラグ */
  openedRoad?: boolean;
  /** 基準日時 */
  baseDate?: Date;
};

/** ヒートマップ表示オプション */
type HeatMapConditionInitOptions = {
  /** GeoJSON */
  geoJson: JsonObject;
  /** 色の指定 */
  colorMap: Map<number, Color>;
  /** 各スポットの重みを返すコールバック関数 */
  weightCallback: (feature: JsonObject) => number;
  /** レイヤー全体の不透明度を返すコールバック関数、デフォルトは常に不透明（1.0） */
  opacityCallback?: (zoomLevel: number) => number;
  /** スポットの円の半径（px）を返すコールバック、デフォルトは常に40px */
  radiusCallback?: (weight: number) => number;
};

/** 十字マーカー表示オプション */
type CenterMarkerConditionInitOptions = {
  /** 色、デフォルトは赤 */
  color?: Color;
  /** 線の太さ、デフォルトは2px */
  width?: number;
  /** 線の長さ、デフォルトは10px */
  length?: number;
};

/** train route */
/**
 * 鉄道路線図表示オプション
 */
type TrainRouteInitOptions = {
  /** 表示するズームレベル範囲 */
  zoomRange?: ZoomRange;
};

/** 鉄道路線の見た目情報設定用コールバック */
type TrainRouteCallback = (data: TrainRouteData) => TrainRouteAppearance;

/** 鉄道路線クリックリスナー */
type TrainRouteClickListener = (data: TrainRouteData) => void;

/** 鉄道路線データ */
type TrainRouteData = {
  /** 会社ID */
  companyId?: string;
  /** 会社名 */
  companyName?: string;
  /** 実路線ID */
  rellineId?: string;
  /** 実路線名 */
  rellineName?: string;
  /** 実路線色 */
  rellineColor?: string;
  /** 発ノードID */
  depatureNodeId?: string;
  /** 着ノードID */
  arrivalNodeId?: string;
  /** 路線分割番号 */
  courseSeqNo?: string;
};

/** 鉄道路線の描画スタイル */
type TrainRouteLineStyle = {
  /** 内線のオプション */
  inline?: TrainRouteLineStyleOption;
  /** 縁線のオプション */
  outline?: TrainRouteLineStyleOption;
};

/** 鉄道路線の描画スタイルオプション */
type TrainRouteLineStyleOption = {
  /**
   * 色
   */
  color: Color;
  /**
   * 幅
   */
  weight: number;
  /**
   * 点線のパターン
   * 数値の配列で点線のパターンを指定する
   * 例えば [2, 1] と指定すると次のようなパターンで点線を描画する
   * 2*weightピクセル塗り、1*weightピクセル休み、2*weightピクセル塗り、...
   * @default 実線
   */
  dashArray?: number[];
};

/** road shape opened */
/**
 * 新規開通道路表示オプション
 */
type RoadShapeOpenedInitOptions = {
  /** 表示するズームレベル範囲 */
  zoomRange?: ZoomRange;
  /** 基準日時 */
  baseDate?: Date;
};

/** 新規開通道路の見た目情報設定用コールバック */
type RoadShapeOpenedCallback = (data: RoadShapeOpenedData) => RoadShapeOpenedAppearance;

/** 新規開通道路クリックリスナー */
type RoadShapeOpenedClickListener = (data: RoadShapeOpenedData) => void;

/** 新規開通道路データ */
type RoadShapeOpenedData = {
  /** メッシュID */
  readonly mesh?: string;
  /** 道路ID */
  readonly roadId?: string;
  /** 道路名称 */
  readonly roadName?: string;
  /** 開通日時 */
  readonly startDate?: string;
  /** 閉鎖日時 */
  readonly endDate?: string;
  /** 整理番号 */
  readonly ordinalNo?: string;
  /** 属性ID */
  readonly attr?: string;
};

/** 新規開通道路の描画スタイル */
type RoadShapeOpenedLineStyle = {
  /** 内線のオプション */
  inline?: RoadShapeOpenedLineStyleOption;
  /** 縁線のオプション */
  outline?: RoadShapeOpenedLineStyleOption;
};

/** 新規開通道路の描画スタイルオプション */
type RoadShapeOpenedLineStyleOption = {
  /**
   * 表示フラグ
   */
  visible: boolean;
  /**
   * 色
   */
  color: Color;
  /**
   * 幅
   */
  weight: number;
  /**
   * 点線のパターン
   * 数値の配列で点線のパターンを指定する
   * 例えば [2, 1] と指定すると次のようなパターンで点線を描画する
   * 2*weightピクセル塗り、1*weightピクセル休み、2*weightピクセル塗り、...
   * @default 実線
   */
  dashArray?: number[];
};

/* addition tile */
/** 任意タイル地図オプション */
type AdditionTileOptions = {
  /** サーバ情報 */
  serverInfoList: {
    /**
     * key: 任意キー名
     * value: サーバ情報
     */
    [key: string]: AdditionTileServerInfo;
  };
};

/** 任意タイル地図サーバ情報 */
type AdditionTileServerInfo = {
  /**
   * タイル画像リクエストURL
   * ※ タイル番号にあたる部分を{X}, {Y}, {Z}とした文字列を設定する
   * e.g. https://www.example.com/tile/{Z}/{X}/{Y}.png
   */
  requestUrl: string;
  /** コピーライト文字列リスト */
  copyright?: LangCopyright;
  /**
   * リクエストに付加するクエリパラメータ
   * key: プロパティ名
   * value: プロパティ値
   */
  queryParam?: ParameterKeyValue;
};

/** 任意タイル地図表示設定オプション */
type AdditionTileConditionInitOptions = {
  /** 表示ズームレベル範囲 */
  zoomRange: ZoomRange;
  /**
   * レイヤー不透明度(0以上1以下)
   * @default 1
   */
  transparency?: number;
  /**
   * 注記レイヤー非表示フラグ
   * @default false
   */
  hideAnnotation?: boolean;
  /**
   * 通常地図タイル非表示フラグ
   * ※ 未指定の場合、transparencyが1ならtrue、1未満ならfalseとみなす
   * ※ 任意タイルレイヤー内で1枚でもtrueのレイヤーがあれば、通常地図タイルは非表示となる
   */
  hideDefaultMapTile?: boolean;
  /**
   * レイヤー描画優先度
   * @default 0
   */
  priority?: number;
  /**
   * タイル表示時のフェードイン有効フラグ
   * @default true
   */
  fadeIn?: boolean;
};

/** 標高地図設定オプション */
type AltitudeConditionInitOptions = {};

export {
  JsonObject,
  ParameterKeyValue,
  GaIAEvent,
  GaIAEventListenerOptions,
  MapLocationStatus,
  ContextMenuEvent,
  GaIAEventMap,
  MapEventMap,
  MouseEventMap,
  WheelEventMap,
  TouchEventMap,
  KeyboardEventMap,
  ExtendedMouseEvent,
  ExtendedTouchEvent,
  ExtendedKeyboardEvent,
  BrowserEventMap,
  Language,
  LangCopyright,
  CopyrightTable,
  ServerInformation,
  MapServerURL,
  CopyrightOptions,
  ScaleOptions,
  TileLoaderOptions,
  MapInitSettings,
  MapInitSettingOptions,
  TileSize,
  TileType,
  TileZoomLevelFixValue,
  CongestionLevel,
  CoordUnit,
  ContextMenuItem,
  ContextMenuOptions,
  MarkerInitOptions,
  MarkerLabelOptions,
  MarkerLabelStyle,
  MarkerGravity,
  MarkerAnimationType,
  MarkerEvent,
  MarkerEventMap,
  MarkerProperties,
  GLMarkerInitOptions,
  GLMarkerLabelOptions,
  GLMarkerLabelStyle,
  GLMarkerEvent,
  GLMarkerEventMap,
  GLMarkerIconInfoInitOptions as GLMarkerInfoInitOptions,
  UserLocationInitOptions,
  UserLocationEvent,
  UserLocationEventMap,
  UserLocationTrackingMode,
  UserLocationProjectionMode,
  InfoWindowInitOptions,
  InfoWindowTailPosition,
  InfoWindowEvent,
  InfoWindowEventMap,
  CircleOptions,
  PolygonOptions,
  PolylineOptions,
  PolylineEvent,
  PolylineEventMap,
  GeoJsonFigureConditionInitOptions,
  GeoJsonFigureEvent,
  GeoJsonFigureEventMap,
  MapIconConditionPaletteConfig,
  MapIconConditionInitOptionsIcon,
  MapIconConditionInitOptions,
  TrafficConditionInitOptions,
  TrafficJamSourceType,
  RoadRegulationOptions,
  DefinedRegulationOptions,
  DefinedRegulationCategory,
  RainfallConditionInitOptions,
  RainfallGradationConditionInitOptions,
  ThunderConditionInitOptions,
  SnowfallConditionInitOptions,
  PollenConditionInitOptions,
  CongestionConditionInitOptions,
  TyphoonConditionInitOptions,
  IndoorConditionInitOptions,
  IndoorMetadataArea,
  IndoorMetadata,
  LandmarkConditionInitOptions,
  AnnotationType,
  AnnotationData,
  AnnotationClickListener,
  AnnotationClickListenerOptions,
  LoadingProgressListener,
  AnnotationIndexedDBOptions,
  AnnotationOptions,
  AnnotationDisplayOptions,
  AnnotationFontFamilyMap,
  GenericFontFamily,
  FontFamily,
  MapIconData,
  MapIconClickListener,
  MapIconMouseEnterListener,
  MapIconClickListenerOptions,
  ExternalAnnotationInitOptions,
  ExternalAnnotationFeature,
  ExternalAnnotationProperty,
  ExternalAnnotationCallback,
  ExternalAnnotationClickListener,
  ExternalAnnotationPluginMap,
  OrbitConditionInitOptions,
  HeatMapConditionInitOptions,
  CenterMarkerConditionInitOptions,
  TrainRouteInitOptions,
  TrainRouteCallback,
  TrainRouteClickListener,
  TrainRouteData,
  TrainRouteLineStyle,
  TrainRouteLineStyleOption,
  RoadShapeOpenedInitOptions,
  RoadShapeOpenedCallback,
  RoadShapeOpenedClickListener,
  RoadShapeOpenedData,
  RoadShapeOpenedLineStyle,
  RoadShapeOpenedLineStyleOption,
  AdditionTileConditionInitOptions,
  AdditionTileServerInfo,
  AltitudeConditionInitOptions,
};
