import {JsonObject} from '../../../gaia/types';
import {Color} from '../../../gaia/value';
import {Optional} from '../../common/types';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {GltfAccessor} from './model/accessor/GltfAccessor';
import {GltfAccessorsReader} from './model/accessor/GltfAccessorsReader';
import {GltfBuffersReader} from './model/buffer/GltfBuffersReader';
import {GltfBufferViewsReader} from './model/bufferView/GltfBufferViewsReader';
import {GltfMaterialsReader} from './model/material/GltfMaterialsReader';
import {GltfMeshesReader} from './model/mesh/GltfMeshesReader';
import {GltfNode} from './model/node/GltfNode';
import {GltfNodesReader} from './model/node/GltfNodesReader';
import {GltfScenesReader} from './model/scene/GltfScenesReader';
import {WebGLConstantsByNumber} from './WebGLConstants';

const NumberOfElementsMap: {[key: string]: number} = {
  SCALAR: 1,
  VEC2: 2,
  VEC3: 3,
  VEC4: 4,
};

type GltfId = number | string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataStructure = any;

type BuiltGltfNode = {
  geometry: CustomGeometry;
  color: Color;
  node: GltfNode;
};

/**
 * 配列もしくはオブジェクトに対して、すべての要素に対してcallback関数を実行してMapを作成する
 * @param structure 配列もしくはオブジェクトのデータ構造
 * @param callback 各要素に対して実行するコールバック関数
 * @returns 作成したMap
 */
const createMap = <T>(
  structure: DataStructure,
  callback: (jsonObject: JsonObject) => Optional<T>
): Optional<Map<GltfId, T>> => {
  // 配列の場合
  if (Array.isArray(structure)) {
    const map: Map<GltfId, T> = new Map();
    structure.forEach((obj: JsonObject, index: number) => {
      const value = callback(obj);
      if (!value) {
        return;
      }
      map.set(index, value);
    });
    return map;
  }
  // オブジェクトの場合
  if (typeof structure === 'object') {
    const map: Map<GltfId, T> = new Map();
    for (const [key, obj] of Object.entries(structure)) {
      const value = callback(obj);
      if (!value) {
        continue;
      }
      map.set(key, value);
    }
    return map;
  }
  // それ以外の場合
  return null;
};

/**
 * GLTF
 */
class GltfReader {
  private buffers?: GltfBuffersReader;
  private bufferViews?: GltfBufferViewsReader;
  private accessors?: GltfAccessorsReader;

  private meshes?: GltfMeshesReader;
  private materials?: GltfMaterialsReader;
  private nodes?: GltfNodesReader;
  private scenes?: GltfScenesReader;
  private scene?: GltfId;

  private parsed = false;

  /**
   * 入力のJSONをパースする
   * @param json JsonObject
   * @returns パースに成功すれば `true` そうでなければ `false`
   */
  parse(json: JsonObject): boolean {
    try {
      const buffers: JsonObject = json.buffers;
      if (!buffers) {
        return false;
      }
      this.buffers = new GltfBuffersReader();
      if (!this.buffers.parse(buffers)) {
        return false;
      }

      const bufferViews: JsonObject = json.bufferViews;
      if (!bufferViews) {
        return false;
      }
      this.bufferViews = new GltfBufferViewsReader();
      if (!this.bufferViews.parse(bufferViews)) {
        return false;
      }

      const accessors: JsonObject = json.accessors;
      if (!accessors) {
        return false;
      }
      this.accessors = new GltfAccessorsReader();
      if (!this.accessors.parse(accessors)) {
        return false;
      }

      const meshes: JsonObject = json.meshes;
      if (!meshes) {
        return false;
      }
      this.meshes = new GltfMeshesReader();
      if (!this.meshes.parse(meshes)) {
        return false;
      }

      const materials: JsonObject = json.materials;
      if (!materials) {
        return false;
      }
      this.materials = new GltfMaterialsReader();
      if (!this.materials.parse(materials)) {
        return false;
      }

      const nodes: JsonObject = json.nodes;
      if (!nodes) {
        return false;
      }
      this.nodes = new GltfNodesReader();
      if (!this.nodes.parse(nodes)) {
        return false;
      }

      const scenes: JsonObject = json.scenes;
      if (!scenes) {
        return false;
      }
      this.scenes = new GltfScenesReader();
      if (!this.scenes.parse(scenes)) {
        return false;
      }

      this.scene = json.scene;

      this.parsed = true;

      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * glTFの内容からObject3Dを作成する
   * @param context コンテキスト
   * @returns 作成したObject3Dの配列
   */
  build(context: WebGLRenderingContext): BuiltGltfNode[] | undefined {
    if (!this.parsed) {
      return undefined;
    }

    if ((!this.scene && this.scene !== 0) || !this.scenes || !this.nodes || !this.meshes || !this.accessors) {
      return undefined;
    }
    const scene = this.scenes.get(this.scene);
    if (!scene) {
      return undefined;
    }

    const builtGltfNodes: BuiltGltfNode[] = [];
    for (const nodeId of scene.nodes) {
      const builtGltfNodesFromNode = this.buildWithNodeId(context, nodeId);
      if (!builtGltfNodesFromNode) {
        continue;
      }
      builtGltfNodes.push(...builtGltfNodesFromNode);
    }
    return builtGltfNodes;
  }

  /**
   * GltfAccessorに従ってglTFから読み込んだ数値の配列を返す
   * @param accessor アクセサ
   * @returns アクセサに従ってglTFから読み込んだ数値の配列
   */
  private accessorToArray(accessor: GltfAccessor): number[] | undefined {
    if (!this.bufferViews || !this.buffers) {
      return undefined;
    }

    const componentType = WebGLConstantsByNumber[accessor.componentType];
    const numberOfElements = NumberOfElementsMap[accessor.type];

    const bufferView = this.bufferViews.get(accessor.bufferView);
    if (!bufferView) {
      return undefined;
    }

    const arrayBuffer = this.buffers.get(bufferView.buffer);
    if (!arrayBuffer) {
      return undefined;
    }

    let numBytes = 0;
    let typedArray: Float32Array | Uint16Array;

    if (componentType === 'FLOAT') {
      numBytes = 4;
      typedArray = new Float32Array(arrayBuffer, bufferView.byteOffset, bufferView.byteLength / numBytes);
    } else if (componentType === 'UNSIGNED_SHORT') {
      numBytes = 2;
      typedArray = new Uint16Array(arrayBuffer, bufferView.byteOffset, bufferView.byteLength / numBytes);
    } else {
      return undefined;
    }

    const extracted = [];
    const skipElements = bufferView.byteStride ? bufferView.byteStride / numBytes : numberOfElements;
    const start = (accessor.byteOffset ?? 0) / numBytes;
    for (let index = start; extracted.length < accessor.count * numberOfElements; ) {
      for (let i = 0; i < numberOfElements; i++) {
        extracted.push(typedArray[index + i]);
      }
      index += skipElements;
    }
    return extracted;
  }

  /**
   * nodeIdに従ってglTFから作成したObject3Dの配列を返す
   * @param context WebGLRenderingContext
   * @param nodeId glTFファイル内で有効なノードID
   * @returns nodeIdに従ってglTFから作成したObject3Dの配列、必要な情報が不足していれば `undefined`
   */
  private buildWithNodeId(context: WebGLRenderingContext, nodeId: GltfId): BuiltGltfNode[] | undefined {
    if (!this.nodes || !this.meshes || !this.accessors) {
      return undefined;
    }

    const node = this.nodes.get(nodeId);
    if (!node) {
      return undefined;
    }

    const builtGltfNodes: BuiltGltfNode[] = [];
    const children = node.children ?? [];
    for (const childNodeId of children) {
      const childObjects = this.buildWithNodeId(context, childNodeId);
      if (!childObjects) {
        continue;
      }
      builtGltfNodes.push(...childObjects);
    }

    const meshId = node.meshId;
    if (meshId === undefined) {
      return builtGltfNodes;
    }
    const mesh = this.meshes.get(meshId);
    if (!mesh) {
      return builtGltfNodes;
    }
    for (const primitive of mesh.primitives.values()) {
      const verticesAccessorId = primitive.attributes.POSITION;
      const verticesAccessor = this.accessors.get(verticesAccessorId);
      if (!verticesAccessor) {
        continue;
      }
      const vertices = this.accessorToArray(verticesAccessor);
      if (!vertices) {
        continue;
      }

      const indicesAccessorId = primitive.indices;
      if (indicesAccessorId === undefined) {
        continue;
      }
      const indicesAccessor = this.accessors.get(indicesAccessorId);
      if (!indicesAccessor) {
        continue;
      }
      const indices = this.accessorToArray(indicesAccessor);
      if (!indices) {
        continue;
      }

      const normalsAccessorId = primitive.attributes.NORMAL;
      const normalsAccessor = this.accessors.get(normalsAccessorId);
      if (!normalsAccessor) {
        continue;
      }
      const normals = this.accessorToArray(normalsAccessor);
      if (!normals) {
        continue;
      }

      const materialId = primitive.material;
      let color: Color;
      if (materialId === undefined || !this.materials) {
        color = Color.white();
      } else {
        const material = this.materials.get(materialId);
        if (!material) {
          color = Color.white();
        } else {
          color = material.pbrMetallicRoughness.baseColor;
        }
      }
      const geometry = new CustomGeometry(vertices, indices, normals);
      const builtGltfNode = {
        geometry,
        color,
        node,
      };
      builtGltfNodes.push(builtGltfNode);
    }
    return builtGltfNodes;
  }
}

export {GltfReader, GltfId, BuiltGltfNode, createMap};
