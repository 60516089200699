import {Size} from './Size';
import {Congestion} from './Congestion';
import {ValueObject} from './interface/ValueObject';
import {JsonObject} from '../types';

/**
 * 混雑度データ
 * @deprecated
 */
class CongestionInfo implements ValueObject {
  /** 混雑度リスト */
  readonly congestionArray: Congestion[];
  /** グリッドサイズ */
  readonly gridSize: Size;

  /**
   * コンストラクタ
   * @param congestionArray 混雑度リスト
   * @param gridSize グリッドサイズ
   */
  constructor(congestionArray: Congestion[], gridSize: Size) {
    this.congestionArray = congestionArray;
    this.gridSize = gridSize;
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof CongestionInfo)) {
      return false;
    }
    const other = obj as CongestionInfo;
    return (
      JSON.stringify(other.congestionArray) === JSON.stringify(this.congestionArray) &&
      other.gridSize.equals(this.gridSize)
    );
  }

  /** @override */
  clone(): ValueObject {
    return new CongestionInfo(this.congestionArray, this.gridSize);
  }

  /** @override */
  toObject(): JsonObject {
    const congestionArrayObject = [];
    for (const congestion of this.congestionArray) {
      congestionArrayObject.push(congestion.toObject());
    }
    return {
      congestionArray: congestionArrayObject,
      gridSize: {
        width: this.gridSize.width,
        height: this.gridSize.height,
      },
    };
  }
}

export {CongestionInfo};
