import {AbstractTileRenderKit, calculateTexturePlaneUVCoordinate} from './AbstractTileRenderKit';
import {TileNumber} from '../../../models/TileNumber';
import {GaiaContext} from '../../../GaiaContext';
import {ArrayList} from '../../../../common/collection/ArrayList';
import {MapStatus} from '../../../models/MapStatus';
import {Optional} from '../../../../common/types';
import {TextureMapping} from '../../../../engine/program/TextureMapping';
import {TexturePlaneGeometry} from '../../../../engine/geometry/TexturePlaneGeometry';
import {MapTileLoader} from '../../../loader/MapTileLoader';
import {TileParameter} from '../../../models/TileParameter';
import {getDevicePixelRatio} from '../../../../common/util/Device';
import {LRUCache} from '../../../../common/collection/LRUCache';
import {LoadingProgressListener, TileSize, TileType} from '../../../../../gaia/types';

import {SatelliteLoader} from '../../../loader/SatelliteLoader';
import {TileImage} from '../../../../common/infra/response/TileImage';
import {TileRenderKitMapping} from '../MapRenderKitController';
import {TileObjectLayer} from '../../../layer/TileObjectLayer';
import {PaletteParameter} from '../../../models/PaletteParameter';
import {Camera} from '../../../../engine/camera/Camera';
import {calculateWorldCoordinate} from '../../../utils/MapUtil';
import {LatLng} from '../../../../../gaia/value';
import {aspectToFogDistanceRatio} from '../../../layer/FogObjectLayer';

const TILE_LAYER_NAME_MAP = 'map';
const TILE_LAYER_NAME_MAP_SUB = 'mapSub';
const TILE_LAYER_NAME_MAP_SPARE = 'mapSpare';

const SPARE_LAYER_TILES = ArrayList.from([
  new TileNumber(58, 22, 6),
  new TileNumber(57, 22, 6),
  new TileNumber(58, 23, 6),
  new TileNumber(57, 23, 6),
  new TileNumber(56, 23, 6),
  new TileNumber(57, 24, 6),
  new TileNumber(56, 24, 6),
  new TileNumber(57, 25, 6),
  new TileNumber(56, 25, 6),
  new TileNumber(55, 25, 6),
  new TileNumber(54, 25, 6),
  new TileNumber(55, 26, 6),
  new TileNumber(54, 27, 6),
]);

const LOAD_FINISH_THRESHOULD = 30;

/**
 * 地図タイル制御マネージャー
 */
class MapTileRenderKit extends AbstractTileRenderKit {
  /** タイル画像ローダ */
  private mapTileLoader: MapTileLoader;
  /** 航空衛星写真ローダ */
  private satelliteLoader: SatelliteLoader;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private readonly tileParameterPool: LRUCache<TileNumber, TileParameter>;

  private subLayer: TileObjectLayer;
  private spareLayer: TileObjectLayer;

  private subTileTextureMap: Map<TileNumber, TextureMapping>;
  private previousZoomLevel: number;
  private previousCenter: LatLng;
  private previousZoomLevelChangedTime: number;
  private subLayerUpdateDebounceId = 0;

  private tileType: TileType;

  private palette: PaletteParameter;

  private isDestroyed = false;

  private loadAlmostFinished = true;
  private onChangeRequestStatus?: (isFinished: boolean) => void;

  private tileBuilding3D: boolean;

  private previousLoadingProgress: number;
  private onUpdateLoadingProgress?: LoadingProgressListener;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param camera Camera
   */
  constructor(context: GaiaContext, camera: Camera) {
    super(context, camera, true, TILE_LAYER_NAME_MAP);

    this.mapTileLoader = new MapTileLoader(context, () => {
      if (this.status && this.tileList) {
        this.updateDrawObjects(this.status, this.tileList);
      }

      if (this.mapTileLoader.getRequestingTileSize() < LOAD_FINISH_THRESHOULD && !this.loadAlmostFinished) {
        this.loadAlmostFinished = true;
        this.onChangeRequestStatus?.(this.loadAlmostFinished);
      }
    });
    this.satelliteLoader = new SatelliteLoader(context, () => {
      if (this.status && this.tileList) {
        this.updateDrawObjects(this.status, this.tileList);
      }
    });

    this.tileParameterPool = new LRUCache<TileNumber, TileParameter>(100);

    this.subLayer = new TileObjectLayer(context, true, false, TILE_LAYER_NAME_MAP_SUB);
    this.spareLayer = new TileObjectLayer(context, true, false, TILE_LAYER_NAME_MAP_SPARE);
    this.subTileTextureMap = new Map();
    this.previousZoomLevel = context.getMapStatus().zoomLevel;
    this.previousCenter = context.getMapStatus().centerLocation;
    this.previousZoomLevelChangedTime = new Date().getTime();

    this.tileType = context.getMapStatus().tileType;
    this.palette = context.getMapStatus().palette;
    this.tileBuilding3D = context.getMapInitOptions().tileBuilding3D ?? true;

    this.previousLoadingProgress = 0;
    this.onUpdateLoadingProgress = undefined;
  }

  /**
   * リクエスト状況通知リスナーの設定
   * @param notifier リスナー関数
   * @returns {void}
   */
  setRequestFinishedNotifier(notifier?: (isFinished: boolean) => void): void {
    this.onChangeRequestStatus = notifier;
  }

  /** @override */
  get identicalName(): keyof TileRenderKitMapping {
    return TILE_LAYER_NAME_MAP;
  }

  /**
   * 標高モードの状態を設定
   * @param altitudeMode 標高モードの状態
   * @returns {void}
   */
  setAltitudeMode(altitudeMode: boolean): void {
    this.subLayer.setAltitudeMode(altitudeMode);
    this.spareLayer.setAltitudeMode(altitudeMode);
    super.setAltitudeMode(altitudeMode);
  }

  /**
   * タイルの3Dビル表示設定を取得
   * @returns 3Dビルの表示フラグ
   */
  isTileBuilding3DEnabled(): boolean {
    return this.tileBuilding3D;
  }

  /**
   * タイルの3Dビル表示設定
   * @param enable 表示フラグ
   * @returns {void}
   */
  setTileBuilding3DEnabled(enable: boolean): void {
    if (this.tileBuilding3D === enable) {
      return;
    }

    this.tileBuilding3D = enable;
    this.clearCache();
  }

  /**
   * 地図タイルの描画進捗更新リスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapTileLoadingProgressListener(listener?: LoadingProgressListener): void {
    this.onUpdateLoadingProgress = listener;
    this.onUpdateLoadingProgress?.(this.previousLoadingProgress);
  }

  /**
   * サブレイヤを取得
   * @returns サブレイヤ
   */
  getSubLayer(): TileObjectLayer {
    return this.subLayer;
  }

  /**
   * 予備のレイヤを取得
   * @returns 予備のレイヤ
   */
  getSpareLayer(): TileObjectLayer {
    return this.spareLayer;
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  clearCache(): void {
    this.layer.updateTiles(new Map());
    this.subLayer.updateTiles(new Map());
    this.subTileTextureMap = new Map();
    this.tileParameterPool.clear();

    this.mapTileLoader.clear();
    this.satelliteLoader.clear();
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.layer.getVisible()) {
      this.clearCache();
      return;
    }
    if (this.tileType !== mapStatus.tileType) {
      this.clearCache();
      this.tileType = mapStatus.tileType;
    }
    if (!this.palette.equals(mapStatus.palette)) {
      this.clearCache();
      this.palette = mapStatus.palette;
    }

    this.status = mapStatus;
    this.tileList = tileList;
    this.mapTileLoader.jumpUpCacheSize(tileList.size() * 2);
    this.satelliteLoader.jumpUpCacheSize(tileList.size() * 2);
    this.tileParameterPool.jumpUpSize(tileList.size() * 2);

    const tileType = mapStatus.tileType;

    this.spareLayer.setVisible(mapStatus.zoomLevel < 10);

    const spareTileTextureMap: Map<TileNumber, TextureMapping> = new Map();
    const spareRequestTiles: TileParameter[] = [];
    for (const tileNumber of SPARE_LAYER_TILES) {
      const {x, y, z} = tileNumber;
      let tileSize: TileSize = getDevicePixelRatio() > 1 ? 512 : 256;
      if (tileType === 'satellite') {
        tileSize = 256;
      }
      const tileParam = new TileParameter(
        x,
        y,
        z,
        tileSize,
        tileType,
        this.palette,
        !this.context.getMapInitOptions().isAnnotationEnabled
      );
      const tex = this.getLoaderCache(tileParam);
      if (tex) {
        const uv = calculateTexturePlaneUVCoordinate(tileNumber, tileNumber);
        if (!uv) {
          continue;
        }
        const geometry = TexturePlaneGeometry.create(1, 1, uv);
        spareTileTextureMap.set(tileNumber, new TextureMapping(geometry, tex.image));
      } else {
        spareRequestTiles.push(tileParam);
      }
    }
    this.spareLayer.updateTiles(spareTileTextureMap);
    this.requestToLoader(spareRequestTiles, tileType);

    const requestTiles: TileParameter[] = [];

    // ズームレベル整数値が変化した場合や中心緯度経度が大きく変化した場合はリクエストキュークリア
    const isChangeZoomLevel =
      this.fixIntZoomLevel(mapStatus.zoomLevel) !== this.fixIntZoomLevel(this.previousZoomLevel);
    const isChangeSignificantlyCenterLat = Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) > 0.1;
    const isChangeSignificantlyCenterLng = Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) > 0.1;

    if (isChangeZoomLevel || isChangeSignificantlyCenterLat || isChangeSignificantlyCenterLng) {
      this.mapTileLoader.clearRequestQueue();
      this.satelliteLoader.clearRequestQueue();
    }

    // 生成済みテクスチャからLayerに描画物を渡す
    const tileTextureMap: Map<TileNumber, TextureMapping> = new Map();
    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    let loadedTileCount = 0;
    for (const tileNumber of tileList) {
      const latLng = tileNumber.centerLocation;
      const position = calculateWorldCoordinate(latLng);
      const distance = position._subtract(centerPosition).magnitude();
      const distanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
      if (distance > cameraDistance * distanceRatio) {
        loadedTileCount++;
        continue;
      }

      const tileParam = this.getTileParameter(tileNumber, tileType);
      let tex = this.getLoaderCache(tileParam);
      if (tex) {
        const uv = calculateTexturePlaneUVCoordinate(tileNumber, tileNumber);
        if (!uv) {
          continue;
        }
        const geometry = TexturePlaneGeometry.create(1, 1, uv);
        tileTextureMap.set(tileNumber, new TextureMapping(geometry, tex.image));
        loadedTileCount++;
      } else {
        if (tileType === 'satellite' && tileParam.z >= 20) {
          // 航空衛星写真はz>=20の画像がないので、z=19で取得する
          let upper: Optional<TileNumber> = tileNumber.clone();
          while (upper && upper.z > 19) {
            upper = upper.upper();
          }
          if (upper) {
            const param = this.getTileParameter(upper, tileType);
            requestTiles.push(param);
          }
        } else {
          requestTiles.push(tileParam);
        }

        // 上位タイルがある場合、描画にはそれを利用しつつloaderへのリクエスト対象とする
        let upperTileNumber: Optional<TileNumber> = null;
        upperTileNumber = tileNumber.clone();
        if (!upperTileNumber) {
          continue;
        }
        for (let plusZoomLevel = 1; plusZoomLevel <= 2; plusZoomLevel++) {
          if (!upperTileNumber) {
            break;
          }
          upperTileNumber = upperTileNumber.upper();
          if (!upperTileNumber) {
            break;
          }
          tex = this.getLoaderCache(this.getTileParameter(upperTileNumber, tileType));
          if (tex) {
            const uv = calculateTexturePlaneUVCoordinate(tileNumber, upperTileNumber);
            if (!uv) {
              continue;
            }
            const geometry = TexturePlaneGeometry.create(1, 1, uv);
            tileTextureMap.set(tileNumber, new TextureMapping(geometry, tex.image));
          }
        }
      }
    }

    this.layer.updateTiles(tileTextureMap);
    const loadingProgress = loadedTileCount / tileList.size();
    if (loadingProgress !== this.previousLoadingProgress) {
      this.previousLoadingProgress = loadingProgress;
      this.onUpdateLoadingProgress?.(loadingProgress);
    }

    if (isChangeZoomLevel) {
      this.previousZoomLevelChangedTime = new Date().getTime();
    }

    if (Math.abs(this.previousZoomLevel - mapStatus.zoomLevel) < 0.001 && tileTextureMap.size > tileList.size() * 0.9) {
      this.subTileTextureMap.clear();
      for (const [tileNumber, textureMapping] of tileTextureMap) {
        if (textureMapping.geometry.isDefaultUV()) {
          this.subTileTextureMap.set(tileNumber, textureMapping);
        }
      }
      this.updateSubLayerDebounce();
    }
    this.previousZoomLevel = mapStatus.zoomLevel;
    this.previousCenter = mapStatus.centerLocation;

    // loaderにリクエストキュー追加
    this.requestToLoader(requestTiles, tileType);
  }

  /** @override */
  executeLoader(): void {
    if (this.tileType === 'satellite') {
      this.satelliteLoader.executeRequest();
      return;
    }

    this.mapTileLoader.executeRequest();

    if (this.mapTileLoader.getRequestingTileSize() > LOAD_FINISH_THRESHOULD && this.loadAlmostFinished) {
      this.loadAlmostFinished = false;
      this.onChangeRequestStatus?.(this.loadAlmostFinished);
    }
  }

  /**
   * サブレイヤーを遅延更新
   * @returns {void}
   */
  private updateSubLayerDebounce(): void {
    if (this.subLayerUpdateDebounceId !== 0) {
      window.clearTimeout(this.subLayerUpdateDebounceId);
    }
    this.subLayerUpdateDebounceId = window.setTimeout(() => {
      this.subLayer.updateTiles(this.subTileTextureMap);
      this.subLayerUpdateDebounceId = 0;
    }, 500);
  }

  /**
   * TileNumber → TileParameterを生成
   * @param tileNumber TileNumber
   * @param tileType TileType
   * @returns TileParameter
   */
  private getTileParameter(tileNumber: TileNumber, tileType: TileType): TileParameter {
    if (this.tileParameterPool.has(tileNumber)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.tileParameterPool.get(tileNumber)!;
    }
    const {x, y, z} = tileNumber;
    let tileSize: TileSize = getDevicePixelRatio() > 1 ? 512 : 256;
    if (tileType === 'satellite') {
      tileSize = 256;
    }
    const param = new TileParameter(
      x,
      y,
      z,
      tileSize,
      tileType,
      this.palette,
      !this.context.getMapInitOptions().isAnnotationEnabled,
      this.tileBuilding3D
    );
    this.tileParameterPool.add(tileNumber, param);
    return param;
  }

  /**
   * Loaderからキャッシュを取得
   * @param tileParameter TileParameter
   * @returns Loaderのキャッシュ
   */
  private getLoaderCache(tileParameter: TileParameter): Optional<TileImage> {
    if (tileParameter.tileType === 'satellite') {
      return this.satelliteLoader.getTile(tileParameter);
    }

    return this.mapTileLoader.getTile(tileParameter);
  }

  /**
   * Loaderにタイルのリクエストキューを追加
   * @param tileList リクエスト対象タイルリスト
   * @param tileType TileType
   * @returns {void}
   */
  private requestToLoader(tileList: TileParameter[], tileType: TileType): void {
    if (tileType === 'satellite') {
      this.satelliteLoader.addRequestQueue(tileList);
      return;
    }

    this.mapTileLoader.addRequestQueue(tileList);
  }

  /** @override */
  onDestroy(): void {
    this.mapTileLoader.destroy();
    this.satelliteLoader.destroy();
    this.subLayer.destroy();
    this.spareLayer.destroy();
  }
}

export {MapTileRenderKit, TILE_LAYER_NAME_MAP, TILE_LAYER_NAME_MAP_SUB};
