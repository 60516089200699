import {mat3, mat4} from 'gl-matrix';
import {Object3D} from '../object/Object3D';
import {Color} from '../../../gaia/value/Color';
import {Optional} from '../../common/types';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {Program} from './Program';
import {Vector3} from '../../common/math/Vector3';

const vertexShaderSource = `
precision mediump float;

attribute vec3 vertexPosition;
attribute vec3 vertexNormal;

uniform mat4 model;
uniform mat4 view;
uniform mat4 projection;
uniform vec4 color;
uniform mat4 normalMat;
uniform vec3 light;

varying vec3 vNormal;
varying vec4 diffuseColor;

void main() {
  mat4 mv = view * model;
  mat4 mvp = projection * mv;
  mat4 vp = projection * view;
  mat3 nMat = mat3(normalMat);

  vec3 invLight = normalize(normalMat * vec4(light, 0.0)).xyz;

  gl_Position = mvp * vec4(vertexPosition, 1.0);
  vec4 position = mv * vec4(vertexPosition, 1.0);
  vNormal = vec3(nMat * vertexNormal);

  vec3 s = normalize(light - vec3(position));
  vec3 norm = vec3(normalize(nMat * vNormal));
  float sDotN = max(dot(vertexNormal, invLight), 0.3);

  diffuseColor = color * sDotN;
}
`;

const fragmentShaderSource = `
precision mediump float;

varying vec3 vNormal;
varying vec4 diffuseColor;

uniform vec4 color;

void main() {
  gl_FragColor = diffuseColor;
  
  vec4 ambientColor = vec4(0.15, 0.15, 0.15, 1.0);
  gl_FragColor += ambientColor;
}
`;

let VERTEX_SHADER: Optional<WebGLShader>;
let FRAGMENT_SHADER: Optional<WebGLShader>;
let PROGRAM: Optional<WebGLProgram>;

/**
 * 単色で描画するプログラム
 */
class PbrMetallicRoughnessProgram implements Program {
  context: WebGLRenderingContext;

  geometry: CustomGeometry;

  color: Color;

  vertexShader: Optional<WebGLShader>;
  fragmentShader: Optional<WebGLShader>;
  program: Optional<WebGLProgram>;

  private mvpMatrixLocation: Optional<WebGLUniformLocation>;
  private mvpMatrixInverseTransposeLocation: Optional<WebGLUniformLocation>;
  private lightLocation: Optional<WebGLUniformLocation>;
  private modelLocation: Optional<WebGLUniformLocation>;
  private viewLocation: Optional<WebGLUniformLocation>;
  private projectionLocation: Optional<WebGLUniformLocation>;

  private colorLocation: Optional<WebGLUniformLocation>;

  private light: Vector3;
  private modelMatrix: mat4;
  private translationMatrix: mat4;
  private rotationMatrix: mat4;
  private scaleMatrix: mat4;
  private translationRotationMatrix: mat4;
  private translationRotationInverseMatrix: mat3;
  private normalMatrix: mat4;
  private vertexBuffer: Optional<WebGLBuffer>;
  private indexBuffer: Optional<WebGLBuffer>;
  private normalBuffer: Optional<WebGLBuffer>;

  private typedVertices: Float32Array;
  private typedIndices: Uint16Array;
  private typedNormals: Float32Array;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param color 色
   */
  constructor(context: WebGLRenderingContext, geometry: CustomGeometry, color: Color) {
    this.context = context;
    this.geometry = geometry;
    this.color = color.clone();

    this.vertexShader = null;
    this.fragmentShader = null;
    this.program = null;

    this.light = Vector3.zero();
    this.modelMatrix = mat4.create();
    this.translationMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.scaleMatrix = mat4.create();
    this.translationRotationMatrix = mat4.create();
    this.translationRotationInverseMatrix = mat3.create();
    this.normalMatrix = mat4.create();

    this.typedVertices = new Float32Array();
    this.typedIndices = new Uint16Array();
    this.typedNormals = new Float32Array();

    this.setupProgram();
    this.setGeometry(geometry);
    this.setupGeometry();
    this.setColor(color);
  }

  /**
   * シェーダをコンパイルしてプログラムを準備する
   * @returns {void}
   */
  setupProgram(): void {
    if (!VERTEX_SHADER) {
      const mayBeVertexShader: Optional<WebGLShader> = this.context.createShader(this.context.VERTEX_SHADER);
      if (mayBeVertexShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] SingleColorProgram.setupProgram() could not create vertex shader');
        return;
      }
      VERTEX_SHADER = mayBeVertexShader;
      this.context.shaderSource(VERTEX_SHADER, vertexShaderSource);
      this.context.compileShader(VERTEX_SHADER);

      const vertexShaderCompileStatus = this.context.getShaderParameter(VERTEX_SHADER, this.context.COMPILE_STATUS);
      if (!vertexShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(VERTEX_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
        return;
      }
    }
    this.vertexShader = VERTEX_SHADER;

    if (!FRAGMENT_SHADER) {
      const mayBeFragmentShader: Optional<WebGLShader> = this.context.createShader(this.context.FRAGMENT_SHADER);
      if (mayBeFragmentShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] SingleColorProgram.setupProgram() could not create fragment shader');
        return;
      }
      FRAGMENT_SHADER = mayBeFragmentShader;
      this.context.shaderSource(FRAGMENT_SHADER, fragmentShaderSource);
      this.context.compileShader(FRAGMENT_SHADER);

      const fragmentShaderCompileStatus = this.context.getShaderParameter(FRAGMENT_SHADER, this.context.COMPILE_STATUS);
      if (!fragmentShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(FRAGMENT_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.fragmentShader = FRAGMENT_SHADER;

    if (!PROGRAM) {
      const mayBeProgram = this.context.createProgram();
      if (mayBeProgram === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] SingleColorProgram.setupProgram() could not create program');
        return;
      }
      PROGRAM = mayBeProgram;
      this.context.attachShader(PROGRAM, this.vertexShader);
      this.context.attachShader(PROGRAM, this.fragmentShader);
      this.context.linkProgram(PROGRAM);

      const linkStatus = this.context.getProgramParameter(PROGRAM, this.context.LINK_STATUS);
      if (!linkStatus) {
        const info = this.context.getProgramInfoLog(PROGRAM);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.program = PROGRAM;

    this.context.useProgram(this.program);
  }

  /**
   * ジオメトリを読み込む
   * @returns {void}
   */
  setupGeometry(): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    if (!this.normalBuffer) {
      this.normalBuffer = this.context.createBuffer();
    }

    const vertexAttribLocation = this.context.getAttribLocation(this.program, 'vertexPosition');
    const VERTEX_SIZE = 3;
    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);
    this.context.enableVertexAttribArray(vertexAttribLocation);
    this.context.vertexAttribPointer(vertexAttribLocation, VERTEX_SIZE, this.context.FLOAT, false, 0, 0);

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);

    const normalAttribLocation = this.context.getAttribLocation(this.program, 'vertexNormal');
    const NORMAL_SIZE = 3;
    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.normalBuffer);
    this.context.enableVertexAttribArray(normalAttribLocation);
    this.context.vertexAttribPointer(normalAttribLocation, NORMAL_SIZE, this.context.FLOAT, false, 0, 0);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: CustomGeometry): void {
    this.geometry = geometry;

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    if (!this.normalBuffer) {
      this.normalBuffer = this.context.createBuffer();
    }

    const vertices = this.geometry.getVertices();
    const verticesLength = vertices.length;
    if (this.typedVertices.length < verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    const indices = this.geometry.getIndices();
    const indicesLength = indices.length;
    if (this.typedIndices.length < indicesLength) {
      this.typedIndices = new Uint16Array(indicesLength);
    }
    this.typedIndices.set(indices);

    const normals = this.geometry.getNormals();
    if (normals) {
      const normalsLength = normals.length;
      if (this.typedNormals.length < normalsLength) {
        this.typedNormals = new Float32Array(normalsLength);
      }
      this.typedNormals.set(normals);

      this.context.bindBuffer(this.context.ARRAY_BUFFER, this.normalBuffer);
      this.context.bufferData(this.context.ARRAY_BUFFER, this.typedNormals, this.context.STATIC_DRAW);
    }

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);
    this.context.bufferData(this.context.ARRAY_BUFFER, this.typedVertices, this.context.STATIC_DRAW);

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    this.context.bufferData(this.context.ELEMENT_ARRAY_BUFFER, this.typedIndices, this.context.STATIC_DRAW);
  }

  /**
   * 頂点情報のみを更新（indicesを更新しないので、気をつけて利用する）
   * @param vertices 頂点情報
   * @returns {void}
   */
  setVertices(vertices: number[]): void {
    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    this.geometry.setVertices(vertices);

    const verticesLength = vertices.length;
    if (this.typedVertices.length < verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);
    this.context.bufferData(this.context.ARRAY_BUFFER, this.typedVertices, this.context.STATIC_DRAW);
  }

  /**
   * 色を設定する
   * @param color 設定したい色
   * @returns {void}
   */
  setColor(color: Color): void {
    this.color = color;
  }

  /**
   * 光源位置を設定する
   * @param light 光源位置
   * @returns {void}
   */
  setLight(light: Vector3): void {
    this.light.setValues(light.x, light.y, light.z);
  }

  /**
   * 現在のカメラの状況に合わせて行列を更新する
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    if (!this.program) {
      return;
    }

    // model
    mat4.identity(this.scaleMatrix);
    mat4.identity(this.rotationMatrix);
    mat4.identity(this.translationMatrix);
    mat4.identity(this.modelMatrix);
    mat4.identity(this.translationRotationMatrix);
    mat3.identity(this.translationRotationInverseMatrix);

    // Object3Dの親子関係を実装するならここを修正
    mat4.scale(this.scaleMatrix, this.scaleMatrix, object3D.scale.toFloat32Array());
    mat4.multiply(this.rotationMatrix, this.rotationMatrix, object3D.rotation.toMat4());
    const localTranslation = mat4.create();
    mat4.translate(localTranslation, localTranslation, object3D.position.toFloat32Array());
    mat4.multiply(this.translationMatrix, this.translationMatrix, localTranslation);

    mat4.multiply(this.translationRotationMatrix, this.translationRotationMatrix, this.translationMatrix);
    mat4.multiply(this.translationRotationMatrix, this.translationRotationMatrix, this.rotationMatrix);

    mat4.multiply(this.modelMatrix, this.translationRotationMatrix, this.scaleMatrix);

    const mvpMatrix = mat4.create();
    mat4.multiply(mvpMatrix, projectionMatrix, viewMatrix);
    mat4.multiply(mvpMatrix, mvpMatrix, this.modelMatrix);

    /* eslint-disable prettier/prettier */
    mat3.set(
      this.translationRotationInverseMatrix,
      this.translationRotationMatrix[0], this.translationRotationMatrix[1], this.translationRotationMatrix[2],
      this.translationRotationMatrix[4], this.translationRotationMatrix[5], this.translationRotationMatrix[6],
      this.translationRotationMatrix[8], this.translationRotationMatrix[9], this.translationRotationMatrix[10]
    );
    mat3.invert(this.translationRotationInverseMatrix, this.translationRotationInverseMatrix);
    mat4.set(
      this.normalMatrix,
      this.translationRotationInverseMatrix[0], this.translationRotationInverseMatrix[1], this.translationRotationInverseMatrix[2], 0,
      this.translationRotationInverseMatrix[3], this.translationRotationInverseMatrix[4], this.translationRotationInverseMatrix[5], 0,
      this.translationRotationInverseMatrix[6], this.translationRotationInverseMatrix[7], this.translationRotationInverseMatrix[8], 0,
      0, 0, 0, 0
    );
    /* eslint-enable */

    this.context.useProgram(this.program);
    if (!this.mvpMatrixLocation) {
      this.mvpMatrixLocation = this.context.getUniformLocation(this.program, 'mvpMatrix');
    }
    if (!this.mvpMatrixInverseTransposeLocation) {
      this.mvpMatrixInverseTransposeLocation = this.context.getUniformLocation(this.program, 'normalMat');
    }
    this.context.uniformMatrix4fv(this.mvpMatrixLocation, false, mvpMatrix);
    this.context.uniformMatrix4fv(this.mvpMatrixInverseTransposeLocation, false, this.normalMatrix);

    if (!this.lightLocation) {
      this.lightLocation = this.context.getUniformLocation(this.program, 'light');
    }
    if (!this.modelLocation) {
      this.modelLocation = this.context.getUniformLocation(this.program, 'model');
    }
    if (!this.viewLocation) {
      this.viewLocation = this.context.getUniformLocation(this.program, 'view');
    }
    if (!this.projectionLocation) {
      this.projectionLocation = this.context.getUniformLocation(this.program, 'projection');
    }
    this.context.uniform3f(this.lightLocation, this.light.x, this.light.y, this.light.z);
    this.context.uniformMatrix4fv(this.modelLocation, false, this.modelMatrix);
    this.context.uniformMatrix4fv(this.viewLocation, false, viewMatrix);
    this.context.uniformMatrix4fv(this.projectionLocation, false, projectionMatrix);

    if (this.color) {
      if (!this.colorLocation) {
        this.colorLocation = this.context.getUniformLocation(this.program, 'color');
      }
      this.context.uniform4f(this.colorLocation, this.color.r, this.color.g, this.color.b, this.color.a);
    }
  }

  /**
   * 描画する
   * @returns {void}
   */
  draw(): void {
    this.setColor(this.color);
    this.setupGeometry();

    const gl = this.context;

    gl.enable(gl.BLEND);
    gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE);
    gl.enable(gl.DEPTH_TEST);
    gl.enable(gl.CULL_FACE);

    gl.drawElements(gl.TRIANGLES, this.geometry.getIndices().length, gl.UNSIGNED_SHORT, 0);

    gl.disable(gl.CULL_FACE);
    gl.disable(gl.DEPTH_TEST);
    gl.disable(gl.BLEND);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const gl = this.context;
    if (PROGRAM && VERTEX_SHADER && FRAGMENT_SHADER) {
      gl.detachShader(PROGRAM, VERTEX_SHADER);
      gl.detachShader(PROGRAM, FRAGMENT_SHADER);
      gl.deleteProgram(PROGRAM);
      PROGRAM = undefined;
    }
    if (VERTEX_SHADER) {
      gl.deleteShader(VERTEX_SHADER);
      VERTEX_SHADER = undefined;
    }
    if (FRAGMENT_SHADER) {
      gl.deleteShader(FRAGMENT_SHADER);
      FRAGMENT_SHADER = undefined;
    }
    if (this.indexBuffer) {
      gl.deleteBuffer(this.indexBuffer);
      this.indexBuffer = undefined;
    }
    if (this.vertexBuffer) {
      gl.deleteBuffer(this.vertexBuffer);
      this.vertexBuffer = undefined;
    }
    this.mvpMatrixLocation = undefined;
    this.mvpMatrixInverseTransposeLocation = undefined;
    this.colorLocation = undefined;
  }
}

export {PbrMetallicRoughnessProgram};
