import {Vector3} from './Vector3';
import {ValueObject} from '../../../gaia/value/interface/ValueObject';
import {JsonObject} from '../../../gaia/types';

/**
 * 3次元極座標のクラス
 */
class PolarCoordinate3 implements ValueObject {
  /**
   * 半径
   */
  private _radius: number;

  /**
   * ラジアン表現の方位角
   */
  private _phi: number;

  /**
   * ラジアン表現の仰角
   */
  private _theta: number;

  /**
   * コンストラクタ
   * @param radius 半径
   * @param phi ラジアン表現の方位角
   * @param theta ラジアン表現の仰角
   */
  constructor(radius: number, phi: number, theta: number) {
    this._radius = radius;
    this._phi = phi;
    this._theta = theta;
  }

  /**
   * 半径
   */
  get radius(): number {
    return this._radius;
  }

  /**
   * ラジアン表現の方位角
   */
  get phi(): number {
    return this._phi;
  }

  /**
   * ラジアン表現の仰角
   */
  get theta(): number {
    return this._theta;
  }

  /**
   * 半径を設定
   * @param radius 半径
   * @returns {void}
   */
  setRadius(radius: number): void {
    this._radius = radius;
  }

  /**
   * ラジアン表現の方位角を設定
   * @param phi ラジアン表現の方位角
   * @returns {void}
   */
  setPhi(phi: number): void {
    this._phi = phi;
  }

  /**
   * ラジアン表現の仰角を設定
   * @param theta ラジアン表現の仰角
   * @returns {void}
   */
  setTheta(theta: number): void {
    this._theta = theta;
  }

  /** @override */
  clone(): PolarCoordinate3 {
    return new PolarCoordinate3(this._radius, this._phi, this._theta);
  }

  /** @override */
  equals(other: unknown): boolean {
    if (other instanceof PolarCoordinate3 === false) {
      return false;
    }

    const otherPolar = other as PolarCoordinate3;
    return this._radius === otherPolar._radius && this._phi === otherPolar._phi && this._theta === otherPolar._theta;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      radius: this._radius,
      phi: this._phi,
      theta: this._theta,
    };
  }

  /**
   * 3次元直行座標(x, y, z)への変換
   * @returns 3次元直交座標
   */
  toVector3(): Vector3 {
    const x = this._radius * Math.sin(this._theta) * Math.cos(this._phi);
    const y = this._radius * Math.sin(this._theta) * Math.sin(this._phi);
    const z = this._radius * Math.cos(this._theta);
    return new Vector3(x, y, z);
  }

  /**
   * 正規化された上方向のベクトル (0 < theta < pi/2)
   * @returns 上方向のベクトル
   */
  toUpVector3(): Vector3 {
    const x = -Math.cos(this._theta) * Math.cos(this._phi);
    const y = -Math.cos(this._theta) * Math.sin(this._phi);
    const z = Math.sin(this._theta);
    return new Vector3(x, y, z).normalize();
  }

  /**
   * 正規化された右方向のベクトル (0 < theta < pi/2)
   * @returns 右方向のベクトル
   */
  toRightVector3(): Vector3 {
    return new Vector3(-Math.sin(this._phi), Math.cos(this._phi), 0);
  }
}

export {PolarCoordinate3};
