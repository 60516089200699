type FigureOptions = {
  visible?: boolean;
  zIndex?: number;
};

/**
 * 図形のクラス
 */
class Figure {
  private visible: boolean;
  readonly zIndex: number;
  private onUpdateVisibleListener?: (figure: Figure) => void;

  /**
   * コンストラクタ
   * @param options オプション
   */
  constructor(options: FigureOptions) {
    this.visible = options.visible ?? true;
    this.zIndex = options.zIndex ?? 0;
  }

  /**
   * 表示状態更新通知リスナーの設定
   * @ignore
   * @param listener リスナー関数
   * @returns {void}
   */
  setOnVisibleUpdateListener(listener: (figure: Figure) => void): void {
    this.onUpdateVisibleListener = listener;
  }

  /**
   * 図形の表示状態を取得
   * @returns `true`:表示, `false`: 非表示
   */
  isVisible(): boolean {
    return this.visible;
  }

  /**
   * ポリゴンを表示
   * @returns {void}
   */
  show(): void {
    if (this.visible) {
      return;
    }

    this.visible = true;
    this.onUpdateVisibleListener?.(this);
  }

  /**
   * ポリゴンを非表示
   * @returns {void}
   */
  hide(): void {
    if (!this.visible) {
      return;
    }

    this.visible = false;
    this.onUpdateVisibleListener?.(this);
  }
}

export {Figure};
