import {Plane3} from './Plane3';
import {Vector3} from './Vector3';
import {Optional} from '../types';
import {Ray3} from './Ray3';
import {mat3, vec3} from 'gl-matrix';

/**
 * 三角形のクラス
 *
 * ・点とベクトル2本で表現する
 * ・三角形上の任意の点は point + a * v1 + b * v2 (0 <= a,b <= 1, a + b <= 1)で表す
 */
class Triangle3 extends Plane3 {
  /**
   * calculateIntersection() で利用するフィールド
   */
  /** 係数行列 */
  private static coefficient: mat3 = mat3.create();
  private static inverse: mat3 = mat3.create();
  /** 係数行列の逆行列 */
  private static invertResult: mat3 = mat3.create();
  private static vecX: vec3 = vec3.create();
  private static vecY: vec3 = vec3.create();
  private static vecZ: vec3 = vec3.create();
  /** 右辺 */
  private static right: vec3 = vec3.create();
  /** 計算結果の交差する点 */
  private static result: Vector3 = Vector3.zero();

  /**
   * コンストラクタ
   * @param point 始点
   * @param v1 1つ目のベクトル
   * @param v2 2つ目のベクトル
   */
  constructor(point: Vector3, v1: Vector3, v2: Vector3) {
    super(point, v1, v2);
  }

  /**
   * レイと交差する点を返す
   * @param ray Ray3
   * @returns 交差する点
   */
  calculateIntersection(ray: Ray3): Optional<Vector3> {
    // coefficient * [a, b, c] = right

    // 係数行列
    mat3.set(
      Triangle3.coefficient,
      this.v1.x,
      this.v2.x,
      -ray.direction.x,
      this.v1.y,
      this.v2.y,
      -ray.direction.y,
      this.v1.z,
      this.v2.z,
      -ray.direction.z
    );
    Triangle3.invertResult = mat3.invert(Triangle3.inverse, Triangle3.coefficient);
    if (!Triangle3.invertResult) {
      // 逆行列が存在しない
      return null;
    }

    vec3.set(Triangle3.vecX, Triangle3.inverse[0], Triangle3.inverse[1], Triangle3.inverse[2]);
    vec3.set(Triangle3.vecY, Triangle3.inverse[3], Triangle3.inverse[4], Triangle3.inverse[5]);
    vec3.set(Triangle3.vecZ, Triangle3.inverse[6], Triangle3.inverse[7], Triangle3.inverse[8]);

    // 右辺
    vec3.set(Triangle3.right, ray.start.x - this.point.x, ray.start.y - this.point.y, ray.start.z - this.point.z);

    const a = vec3.dot(Triangle3.vecX, Triangle3.right); // v1の係数
    const b = vec3.dot(Triangle3.vecY, Triangle3.right); // v2の係数
    const c = vec3.dot(Triangle3.vecZ, Triangle3.right); // レイの方向ベクトルの係数

    if (!this.isContain(a, b, c)) {
      return null;
    }

    return new Vector3(
      ray.start.x + c * ray.direction.x,
      ray.start.y + c * ray.direction.y,
      ray.start.z + c * ray.direction.z
    );
  }

  /**
   * 三角形上の点の条件を満たすか判定
   * @param a v1の係数
   * @param b v2の係数
   * @param c レイの方向ベクトルの係数
   * @returns 三角形上の点であるか
   */
  private isContain(a: number, b: number, c: number): boolean {
    if (a < 0 || b < 0 || c < 0) {
      return false;
    }

    if (a + b > 1) {
      return false;
    }

    return true;
  }
}

export {Triangle3};
