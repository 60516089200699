import {Vector2} from '../../common/math/Vector2';
import {Vector3} from '../../common/math/Vector3';
import {MAX_LAT, MAX_LNG, MAX_ZOOM_LEVEL, MIN_LAT, MIN_LNG} from '../models/TileNumber';
import {DEGREE_TO_RADIAN} from '../../common/math/MathConstants';
import {LatLngRect} from '../../../gaia/object';
import {Size, ZoomRange, LatLng} from '../../../gaia/value';
import {clamp} from '../../common/math/MathUtil';
import {GaiaContext} from '../GaiaContext';
import {ZoomLevelFixFunc} from '../../common/types';

/**
 * 地図に関する計算を行う
 * @packageDocumentation
 */

/** カメラまでの距離 */
const CAMERA_DISTANCE: number = 1000 * 1000;

/**
 * 緯度経度をピクセル座標に変換する（参考：https://www.trail-note.net/tech/coordinate/）
 * @param latLng 緯度経度
 * @param zoomLevel ズームレベル
 * @returns ピクセル座標
 */
const calculatePixelCoordinate = (latLng: LatLng, zoomLevel: number): Vector2 => {
  const allPixels = 2 ** (zoomLevel + 7);
  const L = 85.05112878;
  const deg2Rad = DEGREE_TO_RADIAN;
  const pixelX = allPixels * (latLng.lng / 180.0 + 1);
  const pixelY =
    (allPixels / Math.PI) * (-Math.atanh(Math.sin(latLng.lat * deg2Rad)) + Math.atanh(Math.sin(L * deg2Rad)));
  return new Vector2(Math.round(pixelX) || 0, Math.round(pixelY) || 0);
};

/**
 * ピクセル座標を緯度経度に変換する
 * @param pixelCoordinate ピクセル座標
 * @param zoomLevel ズームレベル
 * @returns 緯度経度
 */
const pixelToLatLng = (pixelCoordinate: Vector2, zoomLevel: number): LatLng => {
  const allPixels = 2 ** (zoomLevel + 8);
  let lng = 180 * 2 * (pixelCoordinate.x / allPixels - 0.5);

  const tilesAtZoom = 2 ** zoomLevel;
  const latHeight = -2.0 / tilesAtZoom;
  const tileY = pixelCoordinate.y / 256.0;
  let lat = 1.0 + tileY * latHeight;
  lat = 2.0 * Math.atan(Math.E ** (Math.PI * lat)) - Math.PI / 2.0;
  lat *= 180.0 / Math.PI;

  lat = clamp(lat, MIN_LAT, MAX_LAT);
  lng = clamp(lng, MIN_LNG, MAX_LNG);

  return new LatLng(lat, lng);
};

/**
 * GL空間の座標をピクセル座標に変換する
 * @param worldCoordinate GL空間座標
 * @param zoomLevel ズームレベル
 * @returns ピクセル座標
 */
const worldToPixel = (worldCoordinate: Vector3, zoomLevel: number): Vector2 => {
  const worldX: number = worldCoordinate.x;
  const worldY: number = worldCoordinate.y;
  const maxWorld: number = 2 ** 24;
  const maxPixel: number = 2 ** (zoomLevel + 8);
  const pixelX: number = (worldX / maxWorld + 0.5) * maxPixel;
  const pixelY: number = (-worldY / maxWorld + 0.5) * maxPixel;
  return new Vector2(pixelX, pixelY);
};

/**
 * ピクセル座標をGL空間座標に変換する
 * @param pixelCoordinate ピクセル座標
 * @param zoomLevel ズームレベル
 * @returns GL空間座標
 */
const pixelToWorld = (pixelCoordinate: Vector2, zoomLevel: number): Vector3 => {
  const pixelX: number = pixelCoordinate.x;
  const pixelY: number = pixelCoordinate.y;
  const maxWorld: number = 2 ** 23;
  const maxPixel: number = 2 ** (zoomLevel + 8);
  const worldX: number = ((2 * pixelX) / maxPixel - 1) * maxWorld;
  const worldY: number = ((-2 * pixelY) / maxPixel + 1) * maxWorld;
  return new Vector3(worldX, worldY, 0);
};

/**
 * Gl空間の座標を緯度経度に変換する
 * @param worldCoordinate GL空間座標
 * @param zoomLevel ズームレベル
 * @returns 緯度経度
 */
const worldToLatLng = (worldCoordinate: Vector3, zoomLevel: number): LatLng => {
  const pixel = worldToPixel(worldCoordinate, zoomLevel);
  return pixelToLatLng(pixel, zoomLevel);
};

/**
 * 緯度経度をGL空間の座標に変換する
 * @param latLng 緯度経度
 * @returns GL空間の座標
 */
const calculateWorldCoordinate = (latLng: LatLng): Vector3 => {
  const pixel = calculatePixelCoordinate(latLng, MAX_ZOOM_LEVEL);
  const maxAbs = 2 ** 23;
  return new Vector3(pixel.x / 256 - maxAbs, -pixel.y / 256 + maxAbs, 0);
};

/**
 * ピクセルをGL空間の長さに変換する係数を計算
 * @param zoomLevel ズームレベル
 * @returns ピクセルをGL空間の長さに変換する係数
 */
const calculatePixelToUnit = (zoomLevel: number): number => {
  return 2 ** (MAX_ZOOM_LEVEL - zoomLevel - 8);
};

/**
 * GL空間の長さをピクセルに変換する係数を計算
 * @param zoomLevel ズームレベル
 * @returns GL空間の長さをピクセルに変換する係数
 */
const calculateUnitToPixel = (zoomLevel: number): number => {
  return 1 / calculatePixelToUnit(zoomLevel);
};

/**
 * 指定された範囲が画面内に収まる最大ズームレベルを計算
 * @param rect LatLngRect
 * @param clientSize クライアントの大きさ
 * @param zoomRange ズームレベル範囲
 * @returns ズームレベル
 */
const calculateZoomAdjustedToRect = (rect: LatLngRect, clientSize: Size, zoomRange: ZoomRange): number => {
  const clientWidth = clientSize.width;
  const clientHeight = clientSize.height;

  const maxZoomLevel = zoomRange.max;
  const minZoomLevel = zoomRange.min;
  let fitZoomLevel = minZoomLevel;

  for (let zoomLevel = maxZoomLevel; zoomLevel >= minZoomLevel; zoomLevel--) {
    const worldTopLeft = calculateWorldCoordinate(rect.topLeft);
    const worldBottomRight = calculateWorldCoordinate(rect.bottomRight);
    const worldRectWidth = Math.abs(worldBottomRight.x - worldTopLeft.x);
    const worldRectHeight = Math.abs(worldTopLeft.y - worldBottomRight.y);

    const worldClientWidth = clientWidth * calculatePixelToUnit(zoomLevel);
    const worldClientHeight = clientHeight * calculatePixelToUnit(zoomLevel);

    if (worldRectWidth < worldClientWidth && worldRectHeight < worldClientHeight) {
      fitZoomLevel = zoomLevel;
      break;
    }
  }

  return fitZoomLevel;
};

/**
 * ズームレベルを整数値に丸め込んで返す関数を取得
 * @param context GaiaContext
 * @returns 変換する関数
 */
const getZoomLevelFixFunc: (context: GaiaContext) => ZoomLevelFixFunc = (context: GaiaContext) => {
  const type = context.getMapInitOptions().tileZoomLevelFix ?? 'lower';
  switch (type) {
    case 'lower':
      return Math.floor;
    case 'upper':
      return Math.ceil;
    case 'round':
      return Math.round;
  }
};

/**
 * ズームレベルに対応するメッシュスケールを取得
 * @param zoomLevel ズームレベル
 * @returns メッシュスケール
 */
const getMeshScale = (zoomLevel: number): number => {
  const zoomInt = Math.floor(zoomLevel);

  // GaIAおよびalt-tileが対応している最小ズームレベルが6のため、これより小さい値は丸める
  if (zoomInt < 6) {
    return -1;
  }

  switch (zoomInt) {
    case 6:
    case 7:
      return -1;
    case 8:
    case 9:
      return 0;
    case 10:
    case 11:
      return 1;
    case 12:
    case 13:
      return 2;
    case 14:
    case 15:
    case 16:
      return 3;
    case 17:
    case 18:
    case 19:
    case 20:
      return 4;
  }

  return 4;
};

export {
  CAMERA_DISTANCE,
  calculatePixelCoordinate,
  pixelToLatLng,
  worldToPixel,
  pixelToWorld,
  worldToLatLng,
  calculateWorldCoordinate,
  calculatePixelToUnit,
  calculateUnitToPixel,
  calculateZoomAdjustedToRect,
  getZoomLevelFixFunc,
  getMeshScale,
};
