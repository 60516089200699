import potpack from 'potpack';
import {GLMarkerLabelOptions, GLMarkerLabelStyle} from '../../../../gaia/types';
import {GLMarkerIconInfo, Point, Size} from '../../../../gaia/value';
import {Vector2} from '../../../common/math/Vector2';
import {Vector3} from '../../../common/math/Vector3';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';

export type GLMarkerTextureMapping = {
  texture: TexImageSource;
  details: {
    [key: string]: GLMarkerMappingDetail;
  };
};
export type GLMarkerMappingDetail = {
  size: Size;
  uv: TexturePlaneUVCoordinate;
  image: {
    topLeft: Vector3;
    right: Vector3;
    down: Vector3;
  };
};
type PotPackBox = {
  w: number;
  h: number;
  x: number;
  y: number;
  key: string;
  imageWidth: number;
  imageHeight: number;
};

/**
 * ラベルが中央寄せの場合のGLMarkerの幅を計算する
 * @param measure TextMetrics
 * @param displaySize 画像のサイズ
 * @param labelOptions ラベルのオプション
 * @returns 実際の幅
 */
const calculateWidthWithCenterAlign = (
  measure: TextMetrics,
  displaySize: Size,
  labelOptions: GLMarkerLabelOptions
): number => {
  const offset = labelOptions.offset ?? new Point(0, 0);
  const style = labelOptions.style ?? {};
  const paddingLeft = style.paddingLeft ?? style.padding ?? 0;
  const paddingRight = style.paddingRight ?? style.padding ?? 0;
  const borderHalf = (style.borderWidth ?? 0) / 2;

  // 右端が切れて見切れる問題を解消するため下駄を履かせる
  const margin = 4;

  const startX = -(measure.width / 2.0) + offset.x;
  const endX = measure.width / 2.0 + offset.x;
  const iconWidthHalf = displaySize.width / 2.0;

  if (startX - paddingLeft - borderHalf < -iconWidthHalf && endX + paddingRight + borderHalf > iconWidthHalf) {
    // ラベルが画像から左右両方に飛び出ている場合は、ラベルの幅を採用
    return measure.width + paddingLeft + paddingRight + borderHalf * 2 + margin;
  } else if (startX - paddingLeft - borderHalf >= -iconWidthHalf && endX + paddingRight + borderHalf <= iconWidthHalf) {
    // ラベルが画像の中におさまる場合は、画像の幅を採用
    return displaySize.width;
  } else if (startX - paddingLeft - borderHalf < -iconWidthHalf) {
    // ラベルが画像の左側だけ飛び出ている場合は、画像の幅の右半分と、ラベルの幅とオフセットの左半分を足す
    return iconWidthHalf - startX + paddingLeft + borderHalf + margin;
  } else {
    // ラベルが画像の右側だけ飛び出ている場合は、画像の幅の左半分と、ラベルの幅とオフセットの右半分を足す
    return iconWidthHalf + endX + paddingRight + borderHalf + margin;
  }
};

/**
 * ラベルが左寄せの場合のGLMarkerの幅を計算する
 * @param measure TextMetrics
 * @param displaySize 画像のサイズ
 * @param labelOptions ラベルのオプション
 * @returns 実際の幅
 */
const calculateWidthWithLeftAlign = (
  measure: TextMetrics,
  displaySize: Size,
  labelOptions: GLMarkerLabelOptions
): number => {
  const offset = labelOptions.offset ?? new Point(0, 0);
  const style = labelOptions.style ?? {};
  const paddingLeft = style.paddingLeft ?? style.padding ?? 0;
  const paddingRight = style.paddingRight ?? style.padding ?? 0;
  const borderHalf = (style.borderWidth ?? 0) / 2;

  // 右端が切れて見切れる問題を解消するため下駄を履かせる
  const margin = 4;

  if (offset.x - paddingLeft - borderHalf < 0 && measure.width + offset.x > displaySize.width) {
    // ラベルが画像から左右両方に飛び出ている場合は、ラベルの幅を採用
    return measure.width + paddingLeft + paddingRight + borderHalf * 2 + margin;
  } else if (
    offset.x - paddingLeft - borderHalf >= 0 &&
    measure.width + offset.x + paddingRight + borderHalf <= displaySize.width
  ) {
    // ラベルが画像の中におさまる場合は、画像の幅を採用
    return displaySize.width + margin;
  } else if (offset.x - paddingLeft - borderHalf < 0) {
    // ラベルが画像の左側だけ飛び出ている場合は、画像の幅と飛び出ている部分を計算
    return displaySize.width - offset.x + paddingLeft + borderHalf + margin;
  } else {
    // ラベルが画像の右側だけ飛び出ている場合は、ラベルの幅とオフセットを足す
    return measure.width + offset.x + paddingRight + borderHalf + margin;
  }
};

/**
 * a
 * @param measure a
 * @param displaySize a
 * @param labelOptions a
 * @returns 実際の幅
 */
const calculateWidth = (measure: TextMetrics, displaySize: Size, labelOptions: GLMarkerLabelOptions): number => {
  const style = labelOptions.style ?? {};
  const align = style.align ?? 'left';
  if (align === 'left') {
    return calculateWidthWithLeftAlign(measure, displaySize, labelOptions);
  } else if (align === 'center') {
    return calculateWidthWithCenterAlign(measure, displaySize, labelOptions);
  } else {
    return 0;
  }
};

/**
 * GLMarkerの高さを計算する
 * @param measure TextMetrics
 * @param displaySize 画像のサイズ
 * @param labelOptions ラベルのオプション
 * @returns 実際の高さ
 */
const calculateHeight = (measure: TextMetrics, displaySize: Size, labelOptions: GLMarkerLabelOptions): number => {
  const offset = labelOptions.offset ?? new Point(0, 0);
  const style = labelOptions.style ?? {};
  const paddingTop = style.paddingTop ?? style.padding ?? 0;
  const paddingBottom = style.paddingBottom ?? style.padding ?? 0;
  const borderHalf = (style.borderWidth ?? 0) / 2;

  // 下端が切れて見れる問題を解消するため下駄を履かせる
  const margin = 4;

  if (offset.y < Math.abs(measure.actualBoundingBoxAscent) + paddingTop + borderHalf) {
    // ラベルが画像の上から飛び出ている場合は、画像の高さと飛び出ている部分を計算
    return displaySize.height + Math.abs(measure.actualBoundingBoxAscent) - offset.y + paddingTop + borderHalf + margin;
  } else if (offset.y + measure.actualBoundingBoxDescent + paddingBottom + borderHalf > displaySize.height) {
    // ラベルが画像の下から飛び出ている場合は、オフセットとフォントから計算
    return offset.y + measure.actualBoundingBoxDescent + paddingTop + paddingBottom + borderHalf * 2 + margin;
  } else {
    // ラベルが画像の中におさまる場合は、画像の高さを採用
    return displaySize.height + margin;
  }
};

/**
 * GLマーカー用テクスチャ生成ヘルパークラス
 */
class GLMarkerTextureHelper {
  private labelOptionsKeyMap: Map<string, GLMarkerLabelOptions> = new Map();
  private readonly margin = 2;

  /**
   * マーカーのテクスチャを生成
   * @param labelOptionsMap GLMarkerLabelOptionsとそのKey
   * @param markerTexture マーカー単体の画像
   * @param markerIconInfo マーカーアイコン情報
   * @returns GLMarkerTextureMapping
   */
  createMarkerTexture(
    labelOptionsMap: Map<string, GLMarkerLabelOptions>,
    markerTexture: HTMLImageElement,
    markerIconInfo: GLMarkerIconInfo
  ): GLMarkerTextureMapping {
    this.labelOptionsKeyMap.clear();

    const details: {[key: string]: GLMarkerMappingDetail} = {};
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return {texture: canvas, details};
    }

    // 各マーカーテクスチャを正方形に詰める
    const boxes: Array<{w: number; h: number; key: string; imageWidth: number; imageHeight: number}> = [];
    for (const [key, labelOptions] of labelOptionsMap) {
      ctx.textBaseline = 'top';
      ctx.font = this.createFontSettingsString(ctx, labelOptions.style ?? {});
      const measure = ctx.measureText(labelOptions.content);
      const width = calculateWidth(measure, markerIconInfo.size, labelOptions);
      const height = calculateHeight(measure, markerIconInfo.size, labelOptions);
      boxes.push({
        w: width + this.margin * 2,
        h: height + this.margin * 2,
        key,
        imageWidth: markerIconInfo.size.width,
        imageHeight: markerIconInfo.size.height,
      });
      this.labelOptionsKeyMap.set(key, labelOptions);
    }
    const {w: canvasWidth, h: canvasHeight} = potpack(boxes);
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const convertedBoxes: PotPackBox[] = [];
    boxes.map((box) => {
      convertedBoxes.push(box as PotPackBox);
    });

    // テクスチャを生成
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    for (const box of convertedBoxes) {
      const {w, h, x, y, key, imageWidth, imageHeight} = box;
      const labelOptions = this.labelOptionsKeyMap.get(key);
      if (!labelOptions) {
        continue;
      }

      const offset = labelOptions.offset ?? new Point(0, 0);

      const style = labelOptions.style ?? {};
      ctx.textBaseline = 'top';
      ctx.font = this.createFontSettingsString(ctx, style);

      const measure = ctx.measureText(labelOptions.content);

      const width = w - this.margin * 2;
      const height = h - this.margin * 2;
      const align = style.align ?? 'left';
      let imageStartX = x + this.margin;
      if (align === 'left' && offset.x < 0) {
        imageStartX += Math.abs(offset.x);
      }
      if (align === 'center' && (imageWidth - measure.width) / 2 + offset.x < 0) {
        imageStartX += Math.abs((imageWidth - measure.width) / 2 + offset.x);
      }
      const imageStartY =
        offset.y >= Math.abs(measure.actualBoundingBoxAscent)
          ? y + this.margin
          : y + this.margin + Math.abs(measure.actualBoundingBoxAscent) - offset.y;

      ctx.drawImage(
        markerTexture,
        imageStartX,
        imageStartY,
        imageWidth - this.margin * 2,
        imageHeight - this.margin * 2
      );

      const paddingLeft = style.paddingLeft ?? style.padding ?? 0;
      const paddingRight = style.paddingRight ?? style.padding ?? 0;
      const paddingTop = style.paddingTop ?? style.padding ?? 0;
      const paddingBottom = style.paddingBottom ?? style.padding ?? 0;
      const borderHalf = (style.borderWidth ?? 0) / 2;
      let labelStartX = x + this.margin + borderHalf;
      if (align === 'left' && offset.x > 0) {
        labelStartX += offset.x;
      }
      if (align === 'center' && (imageWidth - measure.width) / 2 + offset.x > 0) {
        labelStartX += (imageWidth - measure.width) / 2 + offset.x;
      }
      let labelStartY = y + this.margin + borderHalf;
      if (offset.y > Math.abs(measure.actualBoundingBoxAscent)) {
        labelStartY += offset.y;
      }

      const textHeight =
        Math.abs(measure.actualBoundingBoxDescent) +
        Math.abs(measure.actualBoundingBoxAscent) +
        paddingTop +
        paddingBottom;
      const textWidth = measure.width + paddingLeft + paddingRight;
      if (style.backgroundColor) {
        ctx.fillStyle = style.backgroundColor;
        ctx.fillRect(labelStartX - paddingLeft, labelStartY - paddingTop, textWidth, textHeight);
      }
      if (style.borderColor || style.borderWidth) {
        ctx.strokeStyle = style.borderColor ?? '#000';
        ctx.lineWidth = style.borderWidth ?? 1;
        ctx.strokeRect(labelStartX - paddingLeft, labelStartY - paddingTop, textWidth, textHeight);
      }

      // 文字の縁取りをテクスチャに書き込む
      if (style.outline) {
        ctx.fillStyle = style.outline;
        for (let y = -1; y <= 1; y++) {
          for (let x = -1; x <= 1; x++) {
            ctx.fillText(labelOptions.content, labelStartX + x, labelStartY + y);
          }
        }
      }

      // 文字をテクスチャに書き込む
      if (style.color) {
        ctx.fillStyle = style.color;
      } else {
        ctx.fillStyle = '#000'; // デフォルト値
      }
      ctx.fillText(labelOptions.content, labelStartX, labelStartY);

      const uv = new TexturePlaneUVCoordinate(
        new Vector2(x / canvasWidth, y / canvasHeight),
        new Vector2(x / canvasWidth, (y + height) / canvasHeight),
        new Vector2((x + width) / canvasWidth, y / canvasHeight),
        new Vector2((x + width) / canvasWidth, (y + height) / canvasHeight)
      );

      const imageTopLeft = Vector3.zero();
      if (align === 'left' && offset.x < 0) {
        imageTopLeft.add(new Vector3(-offset.x, 0, 0));
      }
      if (align === 'center' && (imageWidth - measure.width) / 2 + offset.x < 0) {
        imageTopLeft.add(new Vector3(Math.abs((imageWidth - measure.width) / 2 + offset.x), 0, 0));
      }
      if (offset.y < 0) {
        imageTopLeft.add(new Vector3(0, Math.abs(offset.y), 0));
      }

      details[key] = {
        size: new Size(height, width),
        uv,
        image: {
          topLeft: imageTopLeft,
          right: new Vector3(imageWidth, 0, 0),
          down: new Vector3(0, imageHeight, 0),
        },
      };
    }

    return {texture: canvas, details};
  }

  /**
   * フォント設定用の文字列作成
   * @param ctx CanvasRenderingContext2D
   * @param labelStyle GLMarkerLabelStyle
   * @returns フォント設定
   */
  private createFontSettingsString(ctx: CanvasRenderingContext2D, labelStyle: GLMarkerLabelStyle): string {
    const textWeight = labelStyle.fontWeight ? `${labelStyle.fontWeight}` : 'normal';
    const textSize = labelStyle.fontSize ? `${labelStyle.fontSize}` : `10px`;
    const textFontFamily = 'sans-serif';
    return `${textWeight} ${textSize} ${textFontFamily}`;
  }
}

export {GLMarkerTextureHelper};
