import {OrbitCondition} from '../../../../gaia/value/OrbitCondition';
import {TileSize} from '../../../../gaia/types';
import {TileNumber} from '../../models/TileNumber';
import {PaletteParameter} from '../../models/PaletteParameter';

/**
 * 軌跡(moon)情報タイルのパラメータクラス
 */
class OrbitTileParameter extends TileNumber {
  /** タイルサイズ */
  readonly tileSize: TileSize;
  /** パレット情報 */
  readonly palette: PaletteParameter;
  /** コンディション */
  readonly condition: OrbitCondition;
  /** 注記表示フラグ */
  readonly annotation: boolean;

  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   * @param palette パレット情報
   * @param condition リクエストオプション
   * @param tileSize タイル画像サイズ
   * @param annotation 注記の有効フラグ
   */
  constructor(
    x: number,
    y: number,
    z: number,
    palette: PaletteParameter,
    condition: OrbitCondition,
    tileSize: TileSize = 256,
    annotation = true
  ) {
    super(x, y, z);
    this.tileSize = tileSize;
    this.palette = palette;
    this.condition = condition;
    this.annotation = annotation;

    this._cacheKey = `O(${this.x},${this.y},${this.z},${this.palette.language},${this.palette.name}${
      this.condition.openedRoad ? ',' + this.condition.openedRoad : ''
    }${this.condition.baseDate ? ',' + this.condition.baseDate : ''}, a=${this.annotation})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {OrbitTileParameter};
