import {JsonObject, Language, TileSize} from '../../../../gaia/types';
import {transMillisecToDegree} from '../../../../gaia/util';
import {LatLng, Point, Size} from '../../../../gaia/value';
import {ValueObject} from '../../../../gaia/value/interface/ValueObject';
import {MarkAnnotationInfo, MarkPaletteConfig} from '../../../common/infra/response/AnnotationInfo';
import {AbstractAnnotationData} from './AbstractAnnotationData';
import {MarkAnnotationTextureData} from './MarkAnnotationTextureData';

/**
 * 国道アイコン注記描画用データ
 */
class MarkAnnotationData extends AbstractAnnotationData implements ValueObject {
  readonly size: Size;
  readonly textureData: MarkAnnotationTextureData;

  /**
   * コンストラクタ
   * @param lang 言語
   * @param tileSize タイルサイズ
   * @param priority 描画優先度
   * @param ntjCode NTJコード
   * @param latlng 緯度経度
   * @param size 表示サイズ
   * @param textureData MarkAnnotationTextureData
   */
  private constructor(
    lang: Language,
    tileSize: TileSize,
    priority: number,
    ntjCode: number,
    latlng: LatLng,
    size: Size,
    textureData: MarkAnnotationTextureData
  ) {
    super(lang, tileSize, priority, ntjCode, latlng);
    this.size = size;
    this.textureData = textureData;
  }

  /**
   * MarkAnnotationDataを作成
   * @param lang 言語
   * @param zoom ズームレベル
   * @param tileSize タイルサイズ
   * @param info MarkAnnotationInfo
   * @param palette MarkPaletteConfig
   * @param size 表示サイズ
   * @param cropStart テクスチャ切り取り開始位置(左上)
   * @param cropEnd テクスチャ切り取り終了位置(右下)
   * @param imageSize テクスチャ切り取り後の画像サイズ
   * @returns MarkAnnotationData
   */
  static createAnnotationData(
    lang: Language,
    zoom: number,
    tileSize: TileSize,
    info: MarkAnnotationInfo,
    palette: MarkPaletteConfig,
    size: Size,
    cropStart: Point,
    cropEnd: Point,
    imageSize: Size
  ): MarkAnnotationData {
    const {appearance, ntjCode} = info;
    const {color, width, font} = palette;
    const textureData = new MarkAnnotationTextureData(
      appearance,
      width,
      color,
      font,
      ntjCode,
      zoom,
      cropStart,
      cropEnd,
      imageSize
    );
    return new MarkAnnotationData(
      lang,
      tileSize,
      info.priority,
      info.ntjCode,
      new LatLng(transMillisecToDegree(info.lat), transMillisecToDegree(info.lon)),
      size,
      textureData
    );
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof MarkAnnotationData)) {
      return false;
    }
    const other = obj as MarkAnnotationData;
    // 国道注記の場合はtileSizeとテクスチャが同じ(=道路番号とNTJコードが一致)なら同じとみなす
    return other.tileSize === this.tileSize && other.textureData.equals(this.textureData);
  }

  /** @override */
  clone(): MarkAnnotationData {
    return new MarkAnnotationData(
      this.lang,
      this.tileSize,
      this.priority,
      this.ntjCode,
      this.latlng,
      this.size,
      this.textureData
    );
  }

  /** @override */
  toObject(): JsonObject {
    return {
      lang: this.lang,
      tileSize: this.tileSize,
      priority: this.priority,
      ntjCode: this.ntjCode,
      latlng: this.latlng.toObject(),
      size: this.size.toObject(),
      textureData: this.textureData.toObject(),
    };
  }
}

export {MarkAnnotationData};
