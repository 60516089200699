import {Plane3} from './Plane3';
import {Vector3} from './Vector3';

/**
 * 3次元空間上の長方形を表すクラス
 *
 * ・点とベクトル2本で表現する
 * ・長方形上の任意の点は point + a * v1 + b * v2 (0 <= a,b <= 1)で表す
 */
class Rect3 extends Plane3 {
  private _topRight: Vector3;
  private _bottomLeft: Vector3;
  private _bottomRight: Vector3;

  /**
   * コンストラクタ
   * @param point 起点
   * @param v1 1つ目のベクトル
   * @param v2 2つ目のベクトル
   */
  constructor(point: Vector3, v1: Vector3, v2: Vector3) {
    super(point, v1, v2);
    this._topRight = Vector3.zero();
    this._bottomLeft = Vector3.zero();
    this._bottomRight = Vector3.zero();
    this.update();
  }

  /**
   * 値を設定する
   * @param point 始点
   * @param v1 1つ目の方向ベクトル
   * @param v2 2つ目の方向ベクトル
   * @returns {void}
   */
  set(point: Vector3, v1: Vector3, v2: Vector3): void {
    super.set(point, v1, v2);
    this.update();
  }

  /**
   * 四隅の座標を更新する
   * @returns {void}
   */
  private update(): void {
    this._topRight.setValues(this.point.x + this.right.x, this.point.y + this.right.y, this.point.z + this.right.z);
    this._bottomLeft.setValues(this.point.x + this.down.x, this.point.y + this.down.y, this.point.z + this.down.z);
    this._bottomRight.setValues(
      this.point.x + this.right.x + this.down.x,
      this.point.y + this.right.y + this.down.y,
      this.point.z + this.right.z + this.down.z
    );
  }

  /**
   * 左上の点を設定する
   * @param topLeft 起点
   * @returns {void}
   */
  setTopLeft(topLeft: Vector3): void {
    this.point = topLeft;
    this.update();
  }

  /**
   * 右方向のベクトルを設定する
   * @param right 右方向のベクトル
   * @returns {void}
   */
  setRight(right: Vector3): void {
    this.v1 = right;
    this.update();
  }

  /**
   * 右方向のベクトル
   */
  get right(): Vector3 {
    return this.v1;
  }

  /**
   * 下方向のベクトルを設定する
   * @param down 下方向のベクトル
   * @returns {void}
   */
  setDown(down: Vector3): void {
    this.v2 = down;
    this.update();
  }

  /**
   * 下方向のベクトル
   */
  get down(): Vector3 {
    return this.v2;
  }

  /**
   * 左上の点
   */
  get topLeft(): Vector3 {
    return this.point;
  }

  /**
   * 右上の点
   */
  get topRight(): Vector3 {
    return this._topRight;
  }

  /**
   * 左下の点
   */
  get bottomLeft(): Vector3 {
    return this._bottomLeft;
  }

  /**
   * 右下の点
   */
  get bottomRight(): Vector3 {
    return this._bottomRight;
  }
}

export {Rect3};
