import {Object3D} from '../../../engine/object/Object3D';
import {TexturePlaneMaterial} from '../../../engine/material/TexturePlaneMaterial';
import {Vector3} from '../../../common/math/Vector3';
import {Quaternion} from '../../../common/math/Quaternion';
import {MapStatus} from '../../models/MapStatus';
import {TileNumber} from '../../models/TileNumber';
import {TextureMapping} from '../../../engine/program/TextureMapping';
import {TexturePlaneGeometry} from '../../../engine/geometry/TexturePlaneGeometry';
import {calculatePixelToUnit, calculateWorldCoordinate} from '../../utils/MapUtil';
import {ZoomLevelFixFunc} from '_private/common/types';

/**
 * タイルテクスチャのステータス
 * unset: まだテクスチャがセットされていない
 * set: 理想的なテクスチャがセットされており、これ以上更新が不要
 * completion: 補完描画中であり、将来的に理想的なタイルに置き換えられるべき
 */
type TileTextureStatus = 'unset' | 'completion' | 'set';

/**
 * タイル画像を描画するオブジェクト
 */
class TileObject3D extends Object3D {
  material: TexturePlaneMaterial;
  tileNumber: TileNumber;

  private tileTextureStatus: TileTextureStatus;

  isSub: boolean;

  private readonly fixIntZoomLevel: (zoom: number) => number;

  /**
   * コンストラクタ
   * @param position 位置
   * @param material マテリアル
   * @param tileNumber タイルパラメータ
   * @param zoomLevelFixFunc ズームレベル整数補正関数
   * @param isSub サブタイルかどうか
   */
  constructor(
    position: Vector3,
    material: TexturePlaneMaterial,
    tileNumber: TileNumber,
    zoomLevelFixFunc: ZoomLevelFixFunc,
    isSub = false
  ) {
    const rotation = Quaternion.fromRadianAndAxis(0, new Vector3(0, 1, 0));
    const scale = Vector3.one()._multiply(0.5);
    super(position, rotation, scale, material);

    this.material = material;
    this.tileNumber = tileNumber;
    this.tileTextureStatus = 'unset';
    this.isSub = isSub;

    this.fixIntZoomLevel = zoomLevelFixFunc;
  }

  /**
   * 地図更新
   * @param mapStatus 地図状態
   * @param cameraTargetPosition カメラが注視している点の座標
   * @returns {void}
   */
  mapUpdate(mapStatus: MapStatus, cameraTargetPosition: Vector3): void {
    const latLng = this.tileNumber.centerLocation;
    const centerPosition = calculateWorldCoordinate(latLng);
    this.setPositionValues(
      centerPosition.x - cameraTargetPosition.x,
      centerPosition.y - cameraTargetPosition.y,
      centerPosition.z - cameraTargetPosition.z
    );

    const targetZoomLevel = this.isSub
      ? this.fixIntZoomLevel(this.tileNumber.z)
      : this.fixIntZoomLevel(mapStatus.zoomLevel);
    const scaleBase = calculatePixelToUnit(targetZoomLevel) * 256;
    this.setScaleValues(scaleBase, scaleBase, scaleBase);
  }

  /**
   * タイルテクスチャのステータスを取得
   * @returns TileTextureStatus
   */
  getTileTextureStatus(): TileTextureStatus {
    return this.tileTextureStatus;
  }

  /**
   * タイルテクスチャのステータスを設定
   * @param tileTextureStatus TileTextureStatus
   * @returns {void}
   */
  setTileTextureStatus(tileTextureStatus: TileTextureStatus): void {
    this.tileTextureStatus = tileTextureStatus;
  }

  /**
   * テクスチャをセットする
   * @param image テクスチャ
   * @returns {void}
   */
  setTexture(image: TexImageSource): void {
    if (this.tileTextureStatus === 'set') {
      return;
    }

    const geometry = TexturePlaneGeometry.create(1, 1);
    this.material.setGeometry(geometry);

    this.material.setTexture(image);
  }

  /**
   * テクスチャ情報をセット
   * @param textureMapping テクスチャ情報
   * @returns {void}
   */
  setTextureMapping(textureMapping: TextureMapping): void {
    if (this.tileTextureStatus !== 'unset') {
      return;
    }

    this.material.setTextureMapping(textureMapping);
  }

  /**
   * 透明かどうか
   * @returns 少しでも透明な場合はtrue、そうでない場合はfalse
   */
  isTransparent(): boolean {
    return this.material.isTransparent();
  }

  /**
   * 透明度を取得
   * @returns 透明度
   */
  getTransparency(): number {
    return this.material.getTextureTransparency();
  }

  /**
   * タイルテクスチャの透明度を設定
   * @param alpha 透明度
   * @returns {void}
   */
  setTransparency(alpha: number): void {
    this.material.setTextureTransparency(alpha);
  }
}

export {TileObject3D};
