import {GaiaContext} from '../GaiaContext';
import {AbstractTileLoader, OnFinishRequestFunc} from './AbstractTileLoader';
import {TyphoonTileParameter} from './param/TyphoonTileParameter';
import {Language} from '../../../gaia/types';
import {QueryParameter} from '../../common/types';
import {TyphoonCondition} from '../../../gaia/value';

/**
 * 台風情報タイルローダー
 */
class TyphoonTileLoader extends AbstractTileLoader<TyphoonTileParameter> {
  private serverUrl = '';
  private productId = '';

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );

    context
      .getGaIAConfiguration()
      .then((res) => {
        this.serverUrl = `${res.server.tile}map/${res.productId}`;
        this.productId = res.productId;
        this.setState('ready');
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * タイルのURLを生成
   * @param tile TyphoonTileParameter
   * @returns リクエストURL
   */
  private createUrl(tile: TyphoonTileParameter): string {
    const {x, y, z, tileSize} = tile;
    const query = this.createQueryString(tile.condition, tile.language);

    const urlBase = tile.condition.server ? `${tile.condition.server}map/${this.productId}` : this.serverUrl;
    return `${urlBase}/tile/typhoon/${tileSize}/${z}/${y}/${x}?product=web&${query}`;
  }

  /**
   * リクエストのクエリパラメータ生成
   * @param condition TyphoonCondition
   * @param language Language
   * @returns クエリパラメータ文字列
   */
  private createQueryString(condition: TyphoonCondition, language: Language): string {
    const query: QueryParameter = {};
    if (condition.dateFormat) {
      query['date-format'] = `${encodeURIComponent(condition.dateFormat)}`;
    } else {
      switch (language) {
        case 'ja':
          query['date-format'] = `${encodeURIComponent('M月d日H時')}`;
          break;
        case 'en':
          query['date-format'] = `${encodeURIComponent('MM/dd/yyyy HH:mm')}`;
          break;
        case 'ko':
        case 'zh-TW':
        case 'zh-CN':
          query['date-format'] = `${encodeURIComponent('yyyy/MM/dd HH:mm')}`;
          break;
        case 'th':
          query['date-format'] = `${encodeURIComponent('dd/MM/yyyy HH:mm')}`;
          break;
      }
    }

    return Object.entries(query)
      .map(([k, v]) => `${k}=${v}`)
      .join('&');
  }
}

export {TyphoonTileLoader};
