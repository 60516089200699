import {TileNumber} from '../../../map/models/TileNumber';
import {AbstractTileRequester, TileRequestUrlBuilderFunc, OnLoadTileFunc} from './AbstractTileRequester';

/**
 * HTMLImageElementを利用したタイル画像リクエストクラス
 */
class HTMLImageElementTileRequester<T extends TileNumber> extends AbstractTileRequester<T> {
  private readonly requestingTile: Set<string>;

  /**
   * コンストラクタ
   * @param builder リクエストURL生成Builder関数
   */
  constructor(builder: TileRequestUrlBuilderFunc<T>) {
    super(builder);
    this.requestingTile = new Set<string>();
  }

  /** @override */
  request(tiles: T[], onLoadTile: OnLoadTileFunc<T>): void {
    for (const tile of tiles) {
      if (this.requestingTile.has(tile.getCacheKey())) {
        continue;
      }
      const url = this.urlBuilder(tile);
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.decoding = 'async';
      this.requestingTile.add(tile.getCacheKey());

      // decode()が使えない場合の分岐
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((img as any).decode) {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        img.onerror = () => {
          onLoadTile(tile, undefined);
          this.requestingTile.delete(tile.getCacheKey());
        };
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        img.onload = () => {
          if (this.requestingTile.has(tile.getCacheKey())) {
            img.decode().then(() => {
              onLoadTile(tile, img);
            });
          }
          this.requestingTile.delete(tile.getCacheKey());
        };
        img.src = url;
      } else {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        img.onerror = () => {
          onLoadTile(tile, undefined);
          this.requestingTile.delete(tile.getCacheKey());
        };
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        img.onload = () => {
          if (this.requestingTile.has(tile.getCacheKey())) {
            onLoadTile(tile, img);
          }
          this.requestingTile.delete(tile.getCacheKey());
        };
        img.src = url;
      }
    }
  }

  /** @override */
  clear(): void {
    this.requestingTile.clear();
  }

  /** @override */
  destroy(): void {
    // do nothing
  }
}

export {HTMLImageElementTileRequester};
