import {LatLng} from '../../gaia/value/LatLng';
import {PolarCoordinate3} from '../common/math/PolarCoordinate3';
import {MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL} from './models/TileNumber';
import {GaiaContext} from './GaiaContext';
import {UserInputHandler} from './UserInputHandler';
import {
  CongestionLevel,
  GaIAEvent,
  GaIAEventListenerOptions,
  GaIAEventMap,
  KeyboardEventMap,
  Language,
  MapEventMap,
  MouseEventMap,
  AnnotationClickListener,
  TileType,
  TouchEventMap,
  WheelEventMap,
  AnnotationClickListenerOptions,
  UserLocationTrackingMode,
  MapIconClickListener,
  MapIconClickListenerOptions,
  MapIconMouseEnterListener,
  AnnotationFontFamilyMap,
  LoadingProgressListener,
  TrainRouteClickListener,
  RoadShapeOpenedClickListener,
  ExternalAnnotationClickListener,
} from '../../gaia/types';
import {MapEventObserver} from './event/MapEventObserver';
import {InfoWindow} from '../../gaia/object/InfoWindow';
import {Camera} from '../engine/camera/Camera';
import {PerspectiveCamera} from '../engine/camera/PerspectiveCamera';
import {Vector3} from '../common/math/Vector3';
import {Color} from '../../gaia/value/Color';
import {DOMObjectLayer} from './layer/DOMObjectLayer';
import {EventListenerFunctionReturnType, FireFoxMouseEvent, Optional} from '../common/types';
import {Marker} from '../../gaia/object/Marker';
import {MarkerHelper} from './render/helper/MarkerHelper';
import {MapPartsController} from './parts/MapPartsController';
import {MapCopyrightHandler} from './parts/MapCopyrightHandler';
import {
  calculatePixelToUnit,
  calculateWorldCoordinate,
  calculateZoomAdjustedToRect,
  CAMERA_DISTANCE,
  pixelToLatLng,
  worldToLatLng,
  worldToPixel,
} from './utils/MapUtil';
import {RADIAN_TO_DEGREE, DEGREE_TO_RADIAN} from '../common/math/MathConstants';
import {clamp} from '../common/math/MathUtil';
import {
  GeoJsonFigureCondition,
  HeatMapCondition,
  Point,
  Size,
  ZoomRange,
  AdditionTileCondition,
  ExternalAnnotationCondition,
} from '../../gaia/value';
import {EaseOutCubic} from './models/animation/easing/EaseOutCubic';
import {getDevicePixelRatio} from '../common/util/Device';
import {CongestionInfo} from '../../gaia/value/CongestionInfo';
import {Scale} from './parts/Scale';
import {Polygon} from '../../gaia/object/shape/Polygon';
import {Polyline, GLMarker} from '../../gaia/object';
import {TrafficCondition} from '../../gaia/value/TrafficCondition';
import {Vector2} from '../common/math/Vector2';
import {viewPointToIntersectionForPerspectiveCamera} from './render/MapTileScanner';
import {ContextMenu} from '../../gaia/value/ContextMenu';
import {ContextMenuObject} from './render/objects/ContextMenuObject';
import {Circle} from '../../gaia/object/shape/Circle';
import {MapRenderKitController} from './render/kit/MapRenderKitController';
import {RainfallCondition} from '../../gaia/value/RainfallCondition';
import {ThunderCondition} from '../../gaia/value/ThunderCondition';
import {SnowfallCondition} from '../../gaia/value/SnowfallCondition';
import {PollenCondition} from '../../gaia/value/PollenCondition';
import {LatLngRect} from '../../gaia/object/LatLngRect';
import {TyphoonCondition} from '../../gaia/value/TyphoonCondition';
import {IndoorCondition} from '../../gaia/value/IndoorCondition';
import {CongestionCondition} from '../../gaia/value/CongestionCondition';
import {LandmarkCondition} from '../../gaia/value/LandmarkCondition';
import {UserLocation} from '../../gaia/object/UserLocation';
import {UserLocationData} from '../../gaia/value/UserLocationData';
import {LinearEasing} from './models/animation/easing/LinearEasing';
import {MapIconCondition} from '../../gaia/value/MapIconCondition';
import {OrbitCondition} from '../../gaia/value/OrbitCondition';
import {AnimationController} from './models/animation/AnimationController';
import {TrainRouteCondition} from '../../gaia/value/TrainRouteCondition';
import {RoadShapeOpenedCondition} from 'gaia/value/RoadShapeOpenedCondition';
import {Ray3} from '../common/math/Ray3';
import {RectCollider} from '../common/math/RectCollider';
import {CenterMarkerCondition} from '../../gaia/value/CenterMarkerCondition';
import {AnimationOption} from '../../gaia/value/animation';
import {RainfallGradationCondition} from '../../gaia/value/RainfallGradationCondition';
import {AltitudeCondition} from '../../gaia/value/AltitudeCondition';
import {FRAME_BUFFER_SIDE} from '../engine/program/AltitudeProgram';

/**
 * カメラのnear値を決める際に使われる係数
 * カメラからターゲットまでの距離にこの係数をかけたものがnearになる
 */
const NEAR_RATIO = 0.2;

/**
 * カメラのfar値を決める際に使われる係数
 * カメラからターゲットまでの距離にこの係数をかけたものがfarになる
 */
const FAR_RATIO = 1.8;

/**
 * 地図の制御クラス
 */
class MapCoreController {
  /** コンテキスト */
  private readonly context: GaiaContext;

  private readonly uiHandler: UserInputHandler;

  private readonly camera: Camera;

  private readonly mapEventObserver: MapEventObserver;

  /** RenderKitController */
  private readonly renderKitCtl: MapRenderKitController;

  private readonly partsCtl: MapPartsController;
  private scale?: Scale;
  private copyright?: MapCopyrightHandler;

  private readonly domLayer: DOMObjectLayer;

  private readonly markerHelper: MarkerHelper;

  private contextMenu?: ContextMenuObject;

  private animationCtl: AnimationController;

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.context = context;
    this.context.addOnMapStatusUpdateListener((_) => this.mapUpdate());

    this.camera = this.initializeCamera(this.context);
    this.updateCamera();
    // 初回のみ描画前にcameraの内部状態を更新
    this.camera.update();

    this.mapEventObserver = new MapEventObserver(this.context);

    this.domLayer = new DOMObjectLayer(this.context);
    this.renderKitCtl = new MapRenderKitController(this.context, this.camera, this.domLayer);

    this.uiHandler = new UserInputHandler(this.context, this.camera, this.renderKitCtl);
    this.uiHandler.setWheelZoomEnable(this.context.getMapInitOptions().wheelZoomEnable ?? true);

    this.partsCtl = new MapPartsController(this.context, this.camera);
    this.initializeMapParts();

    this.markerHelper = new MarkerHelper(this.context, this.domLayer, this.camera);

    this.animationCtl = new AnimationController();

    this.addEventListener('touchmove', () => this.setTrackingMode('none'));
    this.addEventListener('wheel', () => this.setTrackingMode('none'));
    this.addEventListener('drag', () => this.setTrackingMode('none'));
    this.addEventListener('dblclick', () => this.setTrackingMode('none'));
  }

  /**
   * Cameraの初期化
   * @param context コンテキスト
   * @returns Cameraのインスタンス
   */
  private initializeCamera(context: GaiaContext): Camera {
    const {height, width} = context.getCanvasElement();
    const aspect = width / height;
    const camera = new PerspectiveCamera(
      this.context.getGLContext(),
      Vector3.zero(),
      Vector3.zero(),
      new Vector3(0, 1, 0),
      Color.black(),
      90,
      aspect,
      1,
      CAMERA_DISTANCE * 1.5
    );
    return camera;
  }

  /**
   * 地図パーツの初期化処理
   * @returns {void}
   */
  private initializeMapParts(): void {
    const options = this.context.getMapInitOptions();
    if (options?.copyright?.visible ?? true) {
      this.copyright = new MapCopyrightHandler(this.context);
      this.partsCtl.addParts(this.copyright);
    }
    if (options?.scale?.visible ?? true) {
      this.scale = new Scale(this.context.getMapInitOptions().scale);
      this.partsCtl.addParts(this.scale);
    }
  }

  /**
   * 地図更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  mapUpdate(): void {
    this.clampZoomLevel();
    this.updateCamera();
    this.renderKitCtl.update(this.context.getMapStatus());
  }

  /**
   * 中心緯度経度を取得
   * @returns 中心緯度経度
   */
  getCenterLocation(): LatLng {
    return this.context.getMapStatus().centerLocation.clone();
  }

  /**
   * 中心緯度経度を設定
   * @param centerLocation 中心緯度経度
   * @param animationConfiguration アニメーション設定(デフォルト: false)
   * @returns {void}
   */
  setCenterLocation(centerLocation: LatLng, animationConfiguration: AnimationOption | boolean = false): void {
    this.setTrackingMode('none');

    if (!animationConfiguration) {
      this.context.getMapStatus().setCenterLocation(centerLocation);
      return;
    }

    let animationOption: AnimationOption;
    if (animationConfiguration === true) {
      animationOption = new AnimationOption(0.5, new EaseOutCubic());
    } else {
      animationOption = animationConfiguration;
    }
    this.context.getMapStatus().setCenterLocation(centerLocation, animationOption);
  }

  /**
   * 画面座標→緯度経度へ変換
   * @deprecated 3D操作有効時は正しい値が取れないため `calculateClientToLatlng` を利用する
   * @param point 画面座標(地図領域の左上を基点)
   * @returns 緯度経度
   */
  getLatLngFromPixel(point: Point): LatLng {
    return this.clientToLatLng(point);
  }

  /**
   * 画面座標→緯度経度へ変換
   * @param clientCoord 画面座標(地図領域の左上を基点)
   * @returns 緯度経度(緯度経度が算出できない場合はundefined)
   */
  getLatLngFromClientCoord(clientCoord: Point): Optional<LatLng> {
    return this.calculateClientToLatlng(clientCoord);
  }

  /**
   * ズームレベルを取得
   * @returns ズームレベル
   */
  getZoomLevel(): number {
    return this.context.getMapStatus().zoomLevel;
  }

  /**
   * ズームレベルを設定
   * @param zoomLevel ズームレベル
   * @param animationConfiguration アニメーション設定(デフォルト: false)
   * @returns {void}
   */
  setZoomLevel(zoomLevel: number, animationConfiguration: AnimationOption | boolean = false): void {
    zoomLevel = clamp(zoomLevel, MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL);

    if (!animationConfiguration) {
      this.context.getMapStatus().setZoomLevel(zoomLevel);
      return;
    }

    let animationOption: AnimationOption;
    if (animationConfiguration === true) {
      animationOption = new AnimationOption(0.5, new EaseOutCubic());
    } else {
      animationOption = animationConfiguration;
    }
    this.context.getMapStatus().setZoomLevel(zoomLevel, animationOption);
  }

  /**
   * 指定された緯度経度とズームレベルに移動する
   * @param latLng 緯度経度
   * @param zoomLevel ズームレベル
   * @param animationConfiguration アニメーションの設定(デフォルト: false)
   * @returns {void}
   */
  moveTo(latLng: LatLng, zoomLevel: number, animationConfiguration: AnimationOption | boolean = false): void {
    if (!animationConfiguration) {
      this.context.getMapStatus().moveTo(latLng, zoomLevel);
      return;
    }

    let animationOption: AnimationOption;
    if (animationConfiguration === true) {
      animationOption = new AnimationOption(0.5, new EaseOutCubic());
    } else {
      animationOption = animationConfiguration;
    }
    this.context.getMapStatus().moveTo(latLng, zoomLevel, animationOption);
  }

  /**
   * 極座標を取得
   * @returns 極座標
   */
  getPolar(): PolarCoordinate3 {
    return this.context.getMapStatus().polar;
  }

  /**
   * 極座標を設定
   * @param polar 極座標
   * @returns {void}
   */
  setPolar(polar: PolarCoordinate3): void {
    this.context.getMapStatus().setPolar(polar);
  }

  /**
   * 地図の傾きを取得
   * @returns 地図の傾き（単位は度）
   */
  getTilt(): number {
    return this.context.getMapStatus().getPolarTheta() * RADIAN_TO_DEGREE;
  }

  /**
   * 地図の傾きを設定
   * @param tilt 地図の傾き（単位は度）
   * @returns {void}
   */
  setTilt(tilt: number): void {
    const theta = tilt * DEGREE_TO_RADIAN;
    this.context.getMapStatus().setPolarTheta(theta);
  }

  /**
   * 地図の傾きの最大値を設定
   * @param maxTilt 地図の傾きの最大値（単位は度、60度以上には設定できません）
   * @returns {void}
   */
  setMaxTilt(maxTilt: number): void {
    const maxTheta = maxTilt * DEGREE_TO_RADIAN;
    this.context.getMapStatus().setPolarMaxTheta(maxTheta);
  }

  /**
   * 地図の回転角を取得
   * @returns 地図の回転角（単位は度, 範囲は0~359）
   */
  getDirection(): number {
    let direction = (-this.context.getMapStatus().getPolarPhi() - Math.PI / 2) % (2 * Math.PI);
    if (direction < 0) {
      direction += 2 * Math.PI;
    }
    return direction * RADIAN_TO_DEGREE;
  }

  /**
   * 地図の回転角を設定
   * @param direction 地図の回転角（単位は度）
   * @returns {void}
   */
  setDirection(direction: number): void {
    const phi = -(direction * DEGREE_TO_RADIAN + Math.PI / 2);
    this.context.getMapStatus().setPolarPhi(phi);
  }

  /**
   * クライアントの大きさを取得
   * @returns クライアントの大きさ
   */
  getClientSize(): Size {
    return new Size(this.context.getMapStatus().clientHeight, this.context.getMapStatus().clientWidth);
  }

  /**
   * 地図要素のサイズを変更する
   * @param size サイズ（ピクセル）
   * @returns {void}
   */
  setClientSize(size: Size): void {
    // キャンバスのサイズを変更
    const canvas = this.context.getCanvasElement();
    canvas.style.height = `${size.height}px`;
    canvas.style.width = `${size.width}px`;

    const devicePixelRatio = getDevicePixelRatio();
    const contentsHeight = size.height * devicePixelRatio;
    const contentsWidth = size.width * devicePixelRatio;
    canvas.height = contentsHeight;
    canvas.width = contentsWidth;

    // カメラにクライアントサイズの変更を通知
    (this.camera as PerspectiveCamera).setClientSize(contentsWidth, contentsHeight);

    // 地図を再更新
    const mapStatus = this.context.getMapStatus();
    mapStatus.setClientSize(size);
    this.mapUpdate();
  }

  /**
   * 中心地点の基準位置ずらしを設定
   * @param offset offset量
   * @returns {void}
   */
  setCenterOffset(offset: Point): void {
    this.context.getMapStatus().setCenterOffset(offset);
    this.updateCamera();
    this.camera.update();
    this.mapUpdate();
  }

  /**
   * ズームレンジを設定
   * @param zoomRange ズームレンジ
   * @returns {void}
   */
  setZoomRange(zoomRange: ZoomRange): void {
    return this.context.getMapStatus().setZoomRange(zoomRange);
  }

  /**
   * ズームレンジを取得
   * @returns ズームレンジ
   */
  getZoomRange(): ZoomRange {
    return this.context.getMapStatus().zoomRange;
  }

  /**
   * 縮尺の表示位置を調整
   * @param position 位置
   * @returns {void}
   */
  setScalePosition(position: Point): void {
    this.scale?.updatePosition(position);
  }

  /**
   * コピーライトの表示位置を調整
   * @param position 位置
   * @returns {void}
   */
  setCopyrightPosition(position: Point): void {
    this.copyright?.updatePosition(position);
  }

  /**
   * 地図の言語を設定
   * @param language 地図の言語
   * @returns {void}
   */
  setLanguage(language: Language): void {
    this.context.getMapStatus().setLanguage(language);
  }

  /**
   * タイル種別を設定
   * @param tileType タイル種別
   * @returns {void}
   */
  setTileType(tileType: TileType): void {
    this.context.getMapStatus().setTileType(tileType);
  }

  /**
   * カスタムパレット名を設定
   * @param name パレット名 (空の場合はデフォルトに戻ります)
   * @returns {void}
   */
  setPaletteName(name?: string): void {
    this.context.getMapStatus().setPaletteName(name);
  }

  /**
   * 地図の回転操作が有効になっているかを取得
   * @returns 地図の回転操作が有効であれば `true` 無効であれば `false`
   */
  isMapRotationEnabled(): boolean {
    return this.uiHandler.isMapRotationEnabled();
  }

  /**
   * 地図の傾き操作が有効になっているかを取得
   * @returns 地図の傾き操作が有効であれば `true` 無効であれば `false`
   */
  isMapTiltEnabled(): boolean {
    return this.uiHandler.isMapTiltEnabled();
  }

  /**
   * 地図の回転操作の有効/無効を設定
   * 有効にすると、下記のいずれかの操作を行った際に地図が傾いたり回転するようになる
   * ・PC
   *   ・コントロールキーを押しながらドラッグアンドドロップ
   *   ・右クリックでドラッグアンドドロップ
   * ・モバイル
   *   ・2本指で上下にスワイプ、もしくは2本指で回転
   * @param flag 有効にする場合はtrue, 無効にする場合はfalse
   * @returns {void}
   */
  setMapRotationEnabled(flag: boolean): void {
    if (flag) {
      this.uiHandler.enableMapRotation();
    } else {
      this.uiHandler.disableMapRotation();
    }
  }

  /**
   * 地図の傾き操作の有効/無効を設定
   * 有効にすると、下記の操作を行った際に地図が傾くようになる
   * ・PC
   *   ・コントロールキーを押しながらドラッグアンドドロップ
   *   ・右クリックでドラッグアンドドロップ
   * ・モバイル
   *   ・2本指で上下にスワイプ
   * @param flag 有効にする場合はtrue, 無効にする場合はfalse
   * @returns {void}
   */
  setMapTiltEnabled(flag: boolean): void {
    if (flag) {
      this.uiHandler.enableMapTilt();
    } else {
      this.uiHandler.disableMapTilt();
    }
  }

  /**
   * ホイール操作の有効/無効を設定
   * @param enable  有効にする場合はtrue, 無効にする場合はfalse
   * @returns {void}
   */
  setWheelZoomEnable(enable: boolean): void {
    this.uiHandler.setWheelZoomEnable(enable);
  }

  /**
   * タイルの3Dビル表示設定を取得
   * @returns 3Dビルの表示フラグ
   */
  isTileBuilding3DEnabled(): boolean {
    return this.renderKitCtl.isTileBuilding3DEnabled();
  }

  /**
   * タイルの3Dビル表示設定
   * @param enable 表示フラグ
   * @returns {void}
   */
  setTileBuilding3DEnabled(enable: boolean): void {
    this.renderKitCtl.setTileBuilding3DEnabled(enable);
  }

  /**
   * LatLngRectが画面内に収まる位置に移動
   * @param rect LatLngRect
   * @param isAnimation アニメーション有効フラグ
   * @param maxZoomLevel 最大ズームレベル
   * @returns {void}
   */
  moveBasedOnLatLngRect(rect: LatLngRect, isAnimation: boolean, maxZoomLevel?: number): void {
    const zoomRangeMax = maxZoomLevel ?? this.getZoomRange().max;
    const zoomRange = new ZoomRange(this.getZoomRange().min, zoomRangeMax);
    const fitZoomLevel = calculateZoomAdjustedToRect(rect, this.getClientSize(), zoomRange);
    this.moveTo(rect.center, fitZoomLevel, isAnimation);
  }

  /**
   * イベントリスナーの追加
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  addEventListener<K extends keyof GaIAEventMap>(
    eventName: K,
    func: (ev: GaIAEvent) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    if (this.isMouseEvent(eventName)) {
      this.uiHandler.addMouseEventListener(eventName, func, options);
    } else if (this.isWheelEvent(eventName)) {
      this.uiHandler.addWheelEventListener(eventName, func, options);
    } else if (this.isTouchEvent(eventName)) {
      this.uiHandler.addTouchEventListener(eventName, func, options);
    } else if (this.isKeyboardEvent(eventName)) {
      this.uiHandler.addKeyboardEventListener(eventName, func, options);
    } else if (this.isMapEvent(eventName)) {
      this.mapEventObserver.addListener(eventName, func, options);
    }
  }

  /**
   * イベントリスナーの削除
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  removeEventListener<K extends keyof GaIAEventMap>(
    eventName: K,
    func: (ev: GaIAEvent) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    if (this.isMouseEvent(eventName)) {
      this.uiHandler.removeMouseEventListener(eventName, func, options);
    } else if (this.isWheelEvent(eventName)) {
      this.uiHandler.removeWheelEventListener(eventName, func, options);
    } else if (this.isTouchEvent(eventName)) {
      this.uiHandler.removeTouchEventListener(eventName, func, options);
    } else if (this.isKeyboardEvent(eventName)) {
      this.uiHandler.removeKeyboardEventListener(eventName, func, options);
    } else if (this.isMapEvent(eventName)) {
      this.mapEventObserver.removeListener(eventName, func, options);
    }
  }

  /**
   * InfoWindowの追加
   * @param infoWindow {@link InfoWindow}
   * @returns {void}
   */
  addInfoWindow(infoWindow: InfoWindow): void {
    this.domLayer.add(infoWindow);
    this.mapUpdate();
  }

  /**
   * InfoWindowの削除
   * @param infoWindow {@link InfoWindow}
   * @returns {void}
   */
  removeInfoWindow(infoWindow: InfoWindow): void {
    this.domLayer.remove(infoWindow);
    this.mapUpdate();
  }

  /**
   * Markerの追加
   * @param marker {@link Marker}
   * @returns {void}
   */
  addMarker(marker: Marker): void {
    this.markerHelper.addMarker(marker);
    marker.setOnPositionUpdateListener((_) => this.mapUpdate());
    this.mapUpdate();
  }

  /**
   * Markerの削除
   * @param marker {@link Marker}
   * @returns {void}
   */
  removeMarker(marker: Marker): void {
    this.markerHelper.removeMarker(marker);
    marker.setOnPositionUpdateListener();
    this.mapUpdate();
  }

  /**
   * GLMarkerの追加
   * @param markers GLMarkerの配列
   * @returns {void}
   */
  addGLMarkers(markers: GLMarker[]): void {
    this.renderKitCtl.addGLMarkers(markers);
    for (const marker of markers) {
      marker.addEventListener('dragging', (_) => this.uiHandler.stopMapMove());
    }
    this.mapUpdate();
  }

  /**
   * GLMarkerの削除
   * @param markers 削除対象のGLMarker
   * @returns {void}
   */
  removeGLMarkers(markers: GLMarker[]): void {
    this.renderKitCtl.removeGLMarkers(markers);
    this.mapUpdate();
  }

  /**
   * 混雑度情報を設定
   * @param congestionInfo 混雑度情報
   * @param colorTable 配色
   * @returns {void}
   */
  setCongestionInfo(congestionInfo: CongestionInfo, colorTable?: {[key in CongestionLevel]: Color}): void {
    this.renderKitCtl.setCongestionInfo(congestionInfo, colorTable);
    this.mapUpdate();
  }

  /**
   * 混雑度情報をクリア
   * @returns {void}
   */
  clearCongestionInfo(): void {
    this.renderKitCtl.clearCongestionInfo();
    this.mapUpdate();
  }

  /**
   * ポリゴンを追加
   * @param polygon ポリゴン
   * @returns {void}
   */
  addPolygon(polygon: Polygon): void {
    this.renderKitCtl.addPolygon(polygon);
    this.mapUpdate();
  }

  /**
   * ポリゴンを削除
   * @param polygon ポリゴン
   * @returns {void}
   */
  removePolygon(polygon: Polygon): void {
    this.renderKitCtl.removePolygon(polygon);
    this.mapUpdate();
  }

  /**
   * ポリラインを追加
   * @param polyline ポリライン
   * @returns {void}
   */
  addPolyline(polyline: Polyline): void {
    this.renderKitCtl.addPolyline(polyline);
    this.mapUpdate();
  }

  /**
   * ポリラインを削除
   * @param polyline ポリライン
   * @returns {void}
   */
  removePolyline(polyline: Polyline): void {
    this.renderKitCtl.removePolyline(polyline);
    this.mapUpdate();
  }

  /**
   * 円を追加
   * @param circle 円
   * @returns {void}
   */
  addCircle(circle: Circle): void {
    this.renderKitCtl.addCircle(circle);
    this.mapUpdate();
  }

  /**
   * 円を削除
   * @param circle 円
   * @returns {void}
   */
  removeCircle(circle: Circle): void {
    this.renderKitCtl.removeCircle(circle);
    this.mapUpdate();
  }

  /**
   * GeoJSON形状追加
   * @param condition GeoJsonFigureCondition
   * @returns {void}
   */
  addGeoJsonFigure(condition: GeoJsonFigureCondition): void {
    this.renderKitCtl.addGeoJsonFigure(condition);
    this.mapUpdate();
  }

  /**
   * GeoJSON形状削除
   * @param condition 削除対象のGeoJsonFigureCondition
   * @returns {void}
   */
  removeGeoJsonFigure(condition: GeoJsonFigureCondition): void {
    this.renderKitCtl.removeGeoJsonFigure(condition);
    this.mapUpdate();
  }

  /**
   * ヒートマップを追加
   * @param condition HeatMapCondition
   * @returns {void}
   */
  addHeatMap(condition: HeatMapCondition): void {
    this.renderKitCtl.addHeatMap(condition);
    this.mapUpdate();
  }

  /**
   * ヒートマップを削除
   * @param condition HeatMapCondition
   * @returns {void}
   */
  removeHeatMap(condition: HeatMapCondition): void {
    this.renderKitCtl.removeHeatMap(condition);
    this.mapUpdate();
  }

  /**
   * 渋滞情報Conditionの設定
   * @param condition TrafficCondition
   * @returns {void}
   */
  setTrafficCondition(condition: TrafficCondition): void {
    this.renderKitCtl.getTileRenderKit('traffic').setTrafficCondition(condition);
    this.mapUpdate();
  }

  /**
   * 渋滞情報Conditionのクリア
   * @returns {void}
   */
  clearTrafficCondition(): void {
    this.renderKitCtl.getTileRenderKit('traffic').setTrafficCondition();
    this.mapUpdate();
  }

  /**
   * 降雨・降雪情報Conditionの設定
   * @param condition RainfallCondition
   * @returns {void}
   */
  setRainfallCondition(condition: RainfallCondition): void {
    this.renderKitCtl.getTileRenderKit('rainfall').setRainfallCondition(condition);
    this.mapUpdate();
  }

  /**
   * 降雨・降雪情報Conditionのクリア
   * @returns {void}
   */
  clearRainfallCondition(): void {
    this.renderKitCtl.getTileRenderKit('rainfall').setRainfallCondition();
    this.mapUpdate();
  }

  /**
   * 降雨・降雪情報グラデーションConditionの設定
   * @param condition RainfallGradationCondition
   * @returns {void}
   */
  setRainfallGradationCondition(condition: RainfallGradationCondition): void {
    this.renderKitCtl.getTileRenderKit('rainfallGradation').setRainfallGradationCondition(condition);
    this.mapUpdate();
  }

  /**
   * 降雨・降雪情報グラデーションConditionのクリア
   * @returns {void}
   */
  clearRainfallGradationCondition(): void {
    this.renderKitCtl.getTileRenderKit('rainfallGradation').setRainfallGradationCondition();
    this.mapUpdate();
  }

  /**
   * 雷ナウキャスト情報Conditionの設定
   * @param condition ThunderCondition
   * @returns {void}
   */
  setThunderCondition(condition: ThunderCondition): void {
    this.renderKitCtl.getTileRenderKit('thunder').setThunderCondition(condition);
    this.mapUpdate();
  }

  /**
   * 雷ナウキャスト情報Conditionのクリア
   * @returns {void}
   */
  clearThunderCondition(): void {
    this.renderKitCtl.getTileRenderKit('thunder').setThunderCondition();
    this.mapUpdate();
  }

  /**
   * 積雪深情報Conditionの設定
   * @param condition SnowfallCondition
   * @returns {void}
   */
  setSnowfallCondition(condition: SnowfallCondition): void {
    this.renderKitCtl.getTileRenderKit('snowfall').setSnowfallCondition(condition);
    this.mapUpdate();
  }

  /**
   * 積雪深情報Conditionのクリア
   * @returns {void}
   */
  clearSnowfallCondition(): void {
    this.renderKitCtl.getTileRenderKit('snowfall').setSnowfallCondition();
    this.mapUpdate();
  }

  /**
   * 花粉情報Conditionの設定
   * @param condition PollenCondition
   * @returns {void}
   */
  setPollenCondition(condition: PollenCondition): void {
    this.renderKitCtl.getTileRenderKit('pollen').setPollenCondition(condition);
    this.mapUpdate();
  }

  /**
   * 花粉情報Conditionのクリア
   * @returns {void}
   */
  clearPollenCondition(): void {
    this.renderKitCtl.getTileRenderKit('pollen').setPollenCondition();
    this.mapUpdate();
  }

  /**
   * 混雑度情報Condition設定
   * @param condition CongestionCondition
   * @returns {void}
   */
  setCongestionCondition(condition: CongestionCondition): void {
    this.renderKitCtl.getTileRenderKit('congestion').setCongestionCondition(condition);
    this.mapUpdate();
  }

  /**
   * 混雑度情報Conditionのクリア
   * @returns {void}
   */
  clearCongestionCondition(): void {
    this.renderKitCtl.getTileRenderKit('congestion').setCongestionCondition();
    this.mapUpdate();
  }

  /**
   * 台風情報Conditionの設定
   * @param condition TyphoonCondition
   * @returns {void}
   */
  setTyphoonCondition(condition: TyphoonCondition): void {
    this.renderKitCtl.getTileRenderKit('typhoon').setTyphoonCondition(condition);
  }

  /**
   * 台風情報Conditionのクリア
   * @returns {void}
   */
  clearTyphoonCondition(): void {
    this.renderKitCtl.getTileRenderKit('typhoon').setTyphoonCondition();
  }

  /**
   * 航空衛星写真の注記表示状態を設定
   * @param isVisible 表示状態
   * @returns {void}
   */
  setSatelliteAnnotationVisible(isVisible: boolean): void {
    if (this.context.getMapStatus().tileType !== 'satellite') {
      this.renderKitCtl.getTileRenderKit('satelliteAnnotation').setVisible(false);
      return;
    }

    this.renderKitCtl.getTileRenderKit('satelliteAnnotation').setVisible(isVisible);
    this.mapUpdate();
  }

  /**
   * 航空衛星写真の注記表示状態を取得
   * @returns 表示状態
   */
  isSatelliteAnnotationVisible(): boolean {
    return this.renderKitCtl.getTileRenderKit('satelliteAnnotation').isVisible();
  }

  /**
   * フロアリストが変化したときに実行されるコールバック関数を設定
   * @param condition コールバック関数
   * @returns {void}
   */
  setIndoorCondition(condition: IndoorCondition): void {
    this.renderKitCtl.getTileRenderKit('indoor').setIndoorCondition(condition);
    this.mapUpdate();
  }

  /**
   * フロアリストが変化したときに実行されるコールバック関数をクリア
   * @returns {void}
   */
  clearIndoorCondition(): void {
    this.renderKitCtl.getTileRenderKit('indoor').setIndoorCondition();
    this.mapUpdate();
  }

  /**
   * 屋内地図のフロアを設定
   * @param floor フロア
   * @returns {void}
   */
  setIndoorFloor(floor: string): void {
    this.renderKitCtl.getTileRenderKit('indoor').setIndoorFloor(floor);
    this.mapUpdate();
  }

  /**
   * 表示するエリアIDのリストを設定する
   * @param visibleAreaIdList 表示するエリアIDのリスト
   * @returns {void}
   */
  setIndoorVisibleAreaIdList(visibleAreaIdList: number[]): void {
    this.renderKitCtl.getTileRenderKit('indoor').setVisibleAreaIdList(visibleAreaIdList);
    this.mapUpdate();
  }

  /**
   * 表示するエリアIDのリストをクリアする（すべてのエリアを表示するようにする）
   * @returns {void}
   */
  clearIndoorVisibleAreaIdList(): void {
    this.renderKitCtl.getTileRenderKit('indoor').clearVisibleAreaIdList();
    this.mapUpdate();
  }

  /**
   * 任意タイル地図のオプションを設定
   * @param key 設定対象のキー名
   * @param condition 表示設定
   * @returns {void}
   */
  setAdditionTileCondition(key: string, condition: AdditionTileCondition): void {
    this.renderKitCtl.setAdditionTileCondition(key, condition);
    this.mapUpdate();
  }

  /**
   * 任意タイル地図のオプションをクリア
   * @param key クリア対象のキー名
   * @returns {void}
   */
  clearAdditionTileCondition(key: string): void {
    this.renderKitCtl.setAdditionTileCondition(key);
    this.mapUpdate();
  }

  /**
   * 任意タイル地図のオプション登録済みキー名リストを取得
   * @returns 登録済みキー名リスト
   */
  getAdditionTileKeyNameList(): string[] {
    return this.renderKitCtl.getAdditionTileKeyNameList();
  }

  /**
   * 指定したキーが任意タイル地図のオプション登録済みかどうか
   * @param key キー名
   * @returns 登録済みか
   */
  hasAdditionTileKeyName(key: string): boolean {
    return this.renderKitCtl.hasAdditionTileKeyName(key);
  }

  /**
   * 3Dランドマークのオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setLandmarkCondition(condition: LandmarkCondition): void {
    this.renderKitCtl.getObjectRenderKit('landmark').setLandmarkConditeon(condition);
    this.mapUpdate();
  }

  /**
   * 3Dランドマークのオプションをクリア
   * @returns {void}
   */
  clearLandmarkCondition(): void {
    this.renderKitCtl.getObjectRenderKit('landmark').setLandmarkConditeon();
    this.mapUpdate();
  }

  /**
   * 軌跡(moon)のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setOrbitCondition(condition: OrbitCondition): void {
    this.renderKitCtl.getTileRenderKit('orbit').setOrbitCondition(condition);
    this.mapUpdate();
  }

  /**
   * 軌跡(moon)のオプションをクリア
   * @returns {void}
   */
  clearOrbitCondition(): void {
    this.renderKitCtl.getTileRenderKit('orbit').setOrbitCondition();
    this.mapUpdate();
  }

  /**
   * 鉄道路線図のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setTrainRouteCondition(condition: TrainRouteCondition): void {
    this.renderKitCtl.setTrainRouteCondition(condition);
    this.mapUpdate();
  }

  /**
   * 鉄道路線図のオプションをクリア
   * @returns {void}
   */
  clearTrainRouteCondition(): void {
    this.renderKitCtl.setTrainRouteCondition(undefined);
    this.mapUpdate();
  }

  /**
   * 鉄道路線図クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setTrainRouteClickListener(listener: TrainRouteClickListener): void {
    this.renderKitCtl.setTrainRouteClickListener(listener);
  }

  /**
   * 新規開通道路のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setRoadShapeOpenedCondition(condition: RoadShapeOpenedCondition): void {
    this.renderKitCtl.setRoadShapeOpenedCondition(condition);
    this.mapUpdate();
  }

  /**
   * 新規開通道路のオプションをクリア
   * @returns {void}
   */
  clearRoadShapeOpenedCondition(): void {
    this.renderKitCtl.setRoadShapeOpenedCondition(undefined);
    this.mapUpdate();
  }

  /**
   * 新規開通道路クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setRoadShapeOpenedClickListener(listener: RoadShapeOpenedClickListener): void {
    this.renderKitCtl.setRoadShapeOpenedClickListener(listener);
  }

  /**
   * 地図アイコン取得条件の設定
   * @param condition 条件
   * @returns {void}
   */
  setMapIconCondition(condition: MapIconCondition): void {
    this.renderKitCtl.setMapIconCondition(condition);
    this.mapUpdate();
  }

  /**
   * 地図アイコン取得条件のクリア
   * @returns {void}
   */
  clearMapIconCondition(): void {
    this.renderKitCtl.setMapIconCondition(undefined);
    this.mapUpdate();
  }

  /**
   * 社外由来注記コンディションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setExternalAnnotationCondition(condition: ExternalAnnotationCondition): void {
    this.renderKitCtl.setExternalAnnotationCondition(condition);
    this.mapUpdate();
  }

  /**
   * 社外由来注記コンディションをクリア
   * @returns {void}
   */
  clearExternalAnnotationCondition(): void {
    this.renderKitCtl.setExternalAnnotationCondition(undefined);
    this.mapUpdate();
  }

  /**
   * 社外由来注記クリックリスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setExternalAnnotationClickListener(listener: ExternalAnnotationClickListener): void {
    this.renderKitCtl.setExternalAnnotationClickListener(listener);
  }

  /**
   * 十字マーカー表示条件の設定
   * @param condition 条件
   * @returns {void}
   */
  setCenterMarkerCondition(condition: CenterMarkerCondition): void {
    this.renderKitCtl.setCenterMarkerCondition(condition);
    this.mapUpdate();
  }

  /**
   * 十字マーカー表示条件のクリア
   * @returns {void}
   */
  clearCenterMarkerCondition(): void {
    this.renderKitCtl.setCenterMarkerCondition(undefined);
    this.mapUpdate();
  }

  /**
   * 標高表示条件の設定
   * @param condition 条件
   * @returns {void}
   */
  setAltitudeCondition(condition: AltitudeCondition): void {
    this.renderKitCtl.setAltitudeCondition(condition);
    this.mapUpdate();
  }

  /**
   * 標高表示条件のクリア
   * @returns {void}
   */
  clearAltitudeCondition(): void {
    this.renderKitCtl.setAltitudeCondition(undefined);
    this.mapUpdate();
  }

  /**
   * コンテキストメニューを設定
   * @param menu コンテキストメニュー
   * @returns {void}
   */
  setContextMenu(menu: ContextMenu): void {
    if (this.contextMenu) {
      this.clearContextMenu();
    }

    this.contextMenu = new ContextMenuObject(menu.items);
    this.contextMenu.hide();
    this.domLayer.add(this.contextMenu);

    this.uiHandler.addMouseEventListener('contextmenu', (e) => {
      if (
        e.button !== 2 &&
        this.contextMenu?.getWrapElement() &&
        !e.composedPath().includes(this.contextMenu?.getWrapElement())
      ) {
        this.contextMenu.hide();
        return;
      }

      const offsetX = e.offsetX || ((e as unknown) as FireFoxMouseEvent).layerX;
      const offsetY = e.offsetY || ((e as unknown) as FireFoxMouseEvent).layerY;
      const position = this.calculateClientToLatlng(new Point(offsetX, offsetY));
      if (!position) {
        return;
      }

      this.contextMenu?.setPosition(position);
      this.mapUpdate();
      this.contextMenu?.show();
      // コンテキストメニュー表示後最初の地図クリックではクリックイベントを発行しない
      this.uiHandler.stopClickEventTemporarily();
    });

    this.uiHandler.addMouseEventListener('mousedown', (e) => {
      if (
        e.button !== 2 &&
        this.contextMenu?.getWrapElement() &&
        !e.composedPath().includes(this.contextMenu?.getWrapElement())
      ) {
        this.contextMenu.hide();
      }
    });

    if (menu.options?.hideWhenZoomChange ?? true) {
      this.mapEventObserver.addListener('zoomchanged', () => {
        this.contextMenu?.hide();
      });
    }
  }

  /**
   * コンテキストメニューを削除
   * @returns {void}
   */
  clearContextMenu(): void {
    if (!this.contextMenu) {
      return;
    }
    this.domLayer.remove(this.contextMenu);
    this.contextMenu = undefined;
  }

  /**
   * 注記クリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setAnnotationClickListener(listener: AnnotationClickListener, options?: AnnotationClickListenerOptions): void {
    this.renderKitCtl.setAnnotationClickListener(listener, options);
  }

  /**
   * 注記クリックリスナーの削除
   * @returns {void}
   */
  removeAnnotationClickListener(): void {
    this.renderKitCtl.removeAnnotationClickListener();
  }

  /**
   * 注記のフォント情報を設定
   * @param fontFamilyMap フォント情報
   * @returns {void}
   */
  setAnnotationFontFamilyMap(fontFamilyMap: AnnotationFontFamilyMap): void {
    this.renderKitCtl.setAnnotationFontFamilyMap(fontFamilyMap);
  }

  /**
   * 自位置マーカーを設定
   * @param userLocation UserLocation
   * @returns {void}
   */
  setUserLocation(userLocation: UserLocation): void {
    this.renderKitCtl.setUserLocation(userLocation);
  }

  /**
   * 自位置情報を設定
   * @param userLocationData UserLocationData
   * @param isAnimation 移動時のアニメーション有無
   * @returns {void}
   */
  setUserLocationData(userLocationData: UserLocationData, isAnimation: boolean): void {
    let animationOption = undefined;
    if (isAnimation) {
      animationOption = new AnimationOption(this.context.getUserLocationDataInterval(), new LinearEasing());
    }

    this.context.setUserLocationData(userLocationData);
    this.renderKitCtl.updateUserLocationObjectPosition(userLocationData, animationOption);

    if (this.context.getTrackingMode() === 'none') {
      return;
    }

    if (this.context.getTrackingMode() === 'follow') {
      this.context.getMapStatus().setCenterLocation(userLocationData.getLatlng(), animationOption);
      return;
    }

    if (this.context.getTrackingMode() === 'heading_up') {
      const mapDirection = -(userLocationData.getHeading() * DEGREE_TO_RADIAN + Math.PI / 2);
      const zoomLevel = this.context.getMapStatus().zoomLevel;
      const polar = this.context.getMapStatus().polar.clone();
      polar.setPhi(mapDirection);
      this.context.getMapStatus().moveTo(userLocationData.getLatlng(), zoomLevel, animationOption, polar);
    }
  }

  /**
   * 現在のオフセット位置を任意の角度回転したときの座標を算出する
   * @param delta 回転する角度
   * @param currentOffset 現在のオフセット
   * @returns 回転後のオフセット座標
   */
  private calculateOffset(delta: number, currentOffset: Point): Point {
    const {x: offsetX, y: offsetY} = currentOffset;
    const newOffsetX = offsetX * Math.cos(delta) - offsetY * Math.sin(delta);
    const newOffsetY = offsetX * Math.sin(delta) + offsetY * Math.cos(delta);
    return new Point(newOffsetX, newOffsetY);
  }

  /**
   * 自位置情報の取得
   * @returns 自位置情報
   */
  getUserLocationData(): UserLocationData {
    return this.context.getUserLocationData();
  }

  /**
   * トラッキングモードを設定
   * @param mode トラッキングモード
   * @returns {void}
   */
  setTrackingMode(mode: UserLocationTrackingMode): void {
    this.context.setTrackingMode(mode);
  }

  /**
   * トラッキングモードの取得
   * @returns {void}
   */
  getTrackingMode(): UserLocationTrackingMode {
    return this.context.getTrackingMode();
  }

  /**
   * 自位置情報の更新間隔を設定
   * @param interval 更新間隔(秒)
   * @returns {void}
   */
  setUserLocationDataInterval(interval: number): void {
    this.context.setUserLocationDataInterval(interval);
  }

  /**
   * 地図アイコンクリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setMapIconClickListener(listener: MapIconClickListener, options?: MapIconClickListenerOptions): void {
    this.renderKitCtl.setMapIconClickListener(listener, options);
  }

  /**
   * 地図アイコンクリックリスナーの削除
   * @returns {void}
   */
  removeMapIconClickListener(): void {
    this.renderKitCtl.removeMapIconClickListener();
  }

  /**
   * 地図アイコンマウスエンターリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapIconMouseEnterListener(listener: MapIconMouseEnterListener): void {
    this.renderKitCtl.setMapIconMouseEnterListener(listener);
  }

  /**
   * 地図アイコンマウスエンターリスナーの削除
   * @returns {void}
   */
  removeMapIconMouseEnterListener(): void {
    this.renderKitCtl.removeMapIconMouseEnterListener();
  }

  /**
   * 地図タイルの描画進捗更新リスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapTileLoadingProgressListener(listener?: LoadingProgressListener): void {
    this.renderKitCtl.setMapTileLoadingProgressListener(listener);
  }

  /**
   * 注記の描画進捗更新リスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setAnnotationLoadingProgressListener(listener?: LoadingProgressListener): void {
    this.renderKitCtl.setAnnotationLoadingProgressListener(listener);
  }

  /**
   * 地図上のロングタップと見なす時間（ミリ秒）を設定
   * @param milliseconds ミリ秒
   * @returns {void}
   */
  setMapLongTapMilliseconds(milliseconds: number): void {
    this.uiHandler.setMapLongTapMilliseconds(milliseconds);
  }

  /**
   * カメラの更新
   * @returns {void}
   */
  private updateCamera(): void {
    const camera: Camera = this.camera;
    const mapStatus = this.context.getMapStatus();
    if (camera instanceof PerspectiveCamera === false) {
      return;
    }

    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const direction = -this.context.getMapStatus().getPolarPhi() - Math.PI / 2;
    const {x: offsetX, y: offsetY} = mapStatus.centerOffset;
    const rotatedOffset = this.calculateOffset(-direction, new Point(-offsetX, offsetY));
    const offsetVec = new Vector3(rotatedOffset.x * ptu, rotatedOffset.y * ptu, 0);

    const halfVerticalFovRadian = Math.PI / 8;

    const cameraDistance = (mapStatus.clientHeight * ptu * 0.5) / Math.tan(halfVerticalFovRadian);
    mapStatus.polar.setRadius(cameraDistance);

    const perspectiveCamera: PerspectiveCamera = camera as PerspectiveCamera;
    perspectiveCamera.setNear(cameraDistance * NEAR_RATIO);
    perspectiveCamera.setFar(cameraDistance * FAR_RATIO);

    perspectiveCamera.setPosition(mapStatus.polar.toVector3()._add(offsetVec));
    perspectiveCamera.setUpVector(mapStatus.polar.toUpVector3());
    perspectiveCamera.setTarget(offsetVec);

    const verticalFov = halfVerticalFovRadian * 2 * RADIAN_TO_DEGREE;
    perspectiveCamera.setVerticalFov(verticalFov);

    const distanceForNoRotation = cameraDistance * (FRAME_BUFFER_SIDE / mapStatus.clientHeight);
    perspectiveCamera.setPositionWithNoRotation(new Vector3(0, 0, distanceForNoRotation));
    perspectiveCamera.setNearForNoRotation(distanceForNoRotation * NEAR_RATIO);
    perspectiveCamera.setFarForNoRotation(distanceForNoRotation * FAR_RATIO);
  }

  /**
   * マウス操作イベントかどうか判定
   * @param eventName イベント名
   * @returns eventNameがマウスイベント名かどうか
   */
  private isMouseEvent(eventName: string): eventName is keyof MouseEventMap {
    return [
      'mousedown',
      'mouseup',
      'mousemove',
      'click',
      'dblclick',
      'dragstart',
      'drag',
      'dragend',
      'contextmenu',
    ].includes(eventName);
  }

  /**
   * マウスホイール操作イベントかどうか判定
   * @param eventName イベント名
   * @returns eventNameがマウスホイールイベント名かどうか
   */
  private isWheelEvent(eventName: string): eventName is keyof WheelEventMap {
    return ['wheel', 'wheelup', 'wheeldown', 'wheelleft', 'wheelright'].includes(eventName);
  }

  /**
   * タッチ操作イベントかどうか判定
   * @param eventName イベント名
   * @returns eventNameがタッチイベント名かどうか
   */
  private isTouchEvent(eventName: string): eventName is keyof TouchEventMap {
    return [
      'touchstart',
      'touchend',
      'touchmove',
      'singletap',
      'singlescroll',
      'doubletap',
      'pinch',
      'singletap_2f',
      'longtapdetect',
      'longtap',
    ].includes(eventName);
  }

  /**
   * キーボード操作イベントかどうか判定
   * @param eventName イベント名
   * @returns eventNameがキーボードイベント名かどうか
   */
  private isKeyboardEvent(eventName: string): eventName is keyof KeyboardEventMap {
    return ['keydown', 'keyup', 'arrowkeydown', 'zoomkeydown'].includes(eventName);
  }

  /**
   * 地図変更イベントかどうか判定
   * @param eventName イベント名
   * @returns eventNameが地図変更イベントかどうか
   */
  private isMapEvent(eventName: string): eventName is keyof MapEventMap {
    return ['zoomchanged', 'centermoved'].includes(eventName);
  }

  /**
   * ズームレベルを正常な範囲に丸め込む
   * @returns {void}
   */
  private clampZoomLevel(): void {
    const mapStatus = this.context.getMapStatus();

    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const direction = -this.context.getMapStatus().getPolarPhi() - Math.PI / 2;
    const {x: offsetX, y: offsetY} = mapStatus.centerOffset;
    const rotatedOffset = this.calculateOffset(-direction, new Point(-offsetX, offsetY));
    const offsetVec = new Vector3(rotatedOffset.x * ptu, rotatedOffset.y * ptu, 0);

    const cameraPosition = mapStatus.polar
      .toVector3()
      ._multiply(1.0 - NEAR_RATIO)
      .add(offsetVec);
    const cameraGlobalPosition = calculateWorldCoordinate(mapStatus.centerLocation).add(cameraPosition);

    const pixel = worldToPixel(cameraGlobalPosition, mapStatus.zoomLevel);
    const latLng = pixelToLatLng(pixel, mapStatus.zoomLevel);
    const altitude = this.renderKitCtl.getAltitude(latLng);

    // カメラが埋まっているときはズームレベルを調整する必要がある
    if (cameraPosition.z < altitude) {
      const newZ = mapStatus.zoomLevel - 1.0;
      this.context.getMapStatus().setZoomLevelSilently(newZ);
    }
  }

  /**
   * 画面座標→緯度経度に変換
   * TODO: MapUtil内の処理含めて実装場所を見直す
   * @deprecated 3D操作有効時は正しい値が取れないため `calculateClientToLatlng` を利用する
   * @param point 画面座標(地図要素の左上を起点)
   * @returns 緯度経度
   */
  private clientToLatLng(point: Point): LatLng {
    const {clientHeight: height, clientWidth: width} = this.context.getBaseElement();
    const offset = this.context.getMapStatus().centerOffset;

    // [-1, 1] の区間に正規化
    const x = ((point.x - offset.x) / width) * 2 - 1;
    const y = -(((point.y - offset.y) / height) * 2 - 1);
    const vec2 = new Vector2(x, y);

    const upVector: Vector3 = this.context.getMapStatus().polar.toUpVector3();
    const rightVector: Vector3 = this.context.getMapStatus().polar.toRightVector3();
    const centerWorld: Vector3 = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);
    const vec3 = viewPointToIntersectionForPerspectiveCamera(
      this.context.getMapStatus(),
      this.camera as PerspectiveCamera,
      vec2,
      upVector,
      rightVector
    )._add(centerWorld);

    const latlng = worldToLatLng(vec3, this.context.getMapStatus().zoomLevel);
    return latlng;
  }

  /**
   * クライアント座標を緯度経度に変換する
   * @param point ピクセルで表現されたクライアント座標
   * @param worldCenter 地図中心
   * @returns 緯度経度
   */
  private calculateClientToLatlng(point: Point): Optional<LatLng> {
    const {zoomLevel, polar, centerOffset} = this.context.getMapStatus();
    const ptu = calculatePixelToUnit(zoomLevel);
    const upVector = polar.toUpVector3();
    const rightVector = polar.toRightVector3();
    const worldCenter = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);

    const cursorRay = this.clientPixelToRay(new Vector2(point.x, point.y), worldCenter, upVector, rightVector, ptu);
    const mapSurface = this.createMapSurface(worldCenter, upVector, rightVector, ptu, centerOffset);
    const intersection = mapSurface.calculateIntersection(cursorRay);
    if (intersection) {
      return worldToLatLng(intersection, zoomLevel);
    }
  }

  /**
   * カメラからクライアント座標までのレイを作成
   * @param clientPixelPosition ピクセルで表現されたクライアント座標
   * @param worldCenter 地図中心
   * @param upVector 極座標上方向のベクトル
   * @param rightVector 極座標右方向のベクトル
   * @param ptu ピクセルからGL空間上の長さに変換する係数
   * @returns カメラからクライアント座標までのレイ
   */
  private clientPixelToRay(
    clientPixelPosition: Vector2,
    worldCenter: Vector3,
    upVector: Vector3,
    rightVector: Vector3,
    ptu: number
  ): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const clientPositionX = clientPixelPosition.x;
    const clientPositionY = clientPixelPosition.y;

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const halfWidth = (clientWidth * ptu) / 2;
    const halfHeight = (clientHeight * ptu) / 2;

    const toTopVector = upVector._normalize()._multiply(halfHeight * y);
    const toRightVector = rightVector._normalize()._multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);

    const start = worldCenter._add(this.camera.position);

    const cameraTarget = (this.camera as PerspectiveCamera).target;
    const direction = cameraTarget._subtract(this.camera.position)._add(toTopRightVector);

    return new Ray3(start, direction);
  }

  /**
   * 画面全体を覆うコライダを作成
   * @param worldCenter 地図中心
   * @param upVector 極座標上方向のベクトル
   * @param rightVector 極座標右方向のベクトル
   * @param ptu ピクセルからGL空間上の長さに変換する係数
   * @param centerOffset 地図中心オフセット
   * @returns 画面全体を覆うコライダ
   */
  private createMapSurface(
    worldCenter: Vector3,
    upVector: Vector3,
    rightVector: Vector3,
    ptu: number,
    centerOffset: Point
  ): RectCollider {
    const mapStatus = this.context.getMapStatus();
    const outerX = (256 * Math.SQRT2 + Math.abs(centerOffset.x)) * ptu;
    const rotationBaseRadian = mapStatus.polar.phi + Math.PI / 2;

    const radianTopLeft = rotationBaseRadian + (Math.PI / 4) * 3;
    const topLeft: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(-1, 1),
      upVector,
      rightVector
    )
      ._add(worldCenter)
      ._add(new Vector3(outerX * Math.cos(radianTopLeft), outerX * Math.sin(radianTopLeft), 0));

    const radianTopRight = rotationBaseRadian + Math.PI / 4;
    const topRight: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(1, 1),
      upVector,
      rightVector
    )
      ._add(new Vector3(outerX * Math.cos(radianTopRight), outerX * Math.sin(radianTopRight), 0))
      ._add(worldCenter);

    const radianBottomLeft = rotationBaseRadian - (Math.PI / 4) * 3;
    const bottomLeft: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(-1, -1),
      upVector,
      rightVector
    )
      ._add(new Vector3(outerX * Math.cos(radianBottomLeft), outerX * Math.sin(radianBottomLeft), 0))
      ._add(worldCenter);

    return new RectCollider(topLeft, topRight, bottomLeft);
  }

  /**
   * 地図インスタンスが作成されてからの描画回数を取得
   * @returns 描画回数
   */
  getDrawCount(): number {
    return this.renderKitCtl.getDrawCount();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.renderKitCtl.destroy();
    this.context.destroy();
  }
}

export {MapCoreController};
