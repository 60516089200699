/* eslint-disable @typescript-eslint/no-explicit-any */
import {ServerInformation} from './types';

/**
 * 地図情報取得のための設定値を反映するクラスのI/F
 * @deprecated
 */
interface AccessInformation {
  /**
   * サーバー情報の取得
   * @returns サーバー情報クラス
   */
  getInformation(): ServerInformation;

  /**
   * 航空衛星写真用サーバURLの取得
   * @returns 航空衛星写真用サーバURL
   */
  getSatelliteServerUrl(): string;

  /**
   * 描画データサーバURLの取得
   * @returns 描画データサーバURL
   */
  getDataServerUrl(): string | undefined;
}

/**
 * AccessInformationのオブジェクトかどうか判定
 * @param arg オブジェクト
 * @returns AccessInformation のインスタンスであればtrue
 */
export const isAccessInformation = (arg: any): arg is AccessInformation => {
  return (
    arg.getInformation &&
    typeof arg.getInformation === 'function' &&
    arg.getSatelliteServerUrl &&
    typeof arg.getSatelliteServerUrl === 'function'
  );
};

/**
 * (開発用)任意の設定情報
 */
class CustomAccessInformation implements AccessInformation {
  private readonly info: ServerInformation;
  private readonly satelliteServerUrl: string;
  private readonly dataServerUrl?: string;

  /**
   * コンストラクタ
   * @param info サーバー情報
   * @param satelliteServerUrl 航空衛星写真用サーバURL(デフォルト: 'http://mars.navitime.co.jp/')
   * @param dataServerUrl 描画データサーバURL
   */
  constructor(info: ServerInformation, satelliteServerUrl = 'http://mars.navitime.co.jp/', dataServerUrl?: string) {
    this.info = info;
    this.satelliteServerUrl = satelliteServerUrl;
    this.dataServerUrl = dataServerUrl;
  }

  /** @override */
  getInformation(): ServerInformation {
    return this.info;
  }

  /** @override */
  getSatelliteServerUrl(): string {
    return this.satelliteServerUrl;
  }

  /** @override */
  getDataServerUrl(): string | undefined {
    return this.dataServerUrl;
  }
}

export {AccessInformation, CustomAccessInformation};
