import {Triangle3} from '../../../common/math/Triangle3';
import {LatLng} from '../../../../gaia/value';
import {calculateWorldCoordinate} from '../../utils/MapUtil';
import earcut from 'earcut';
import {Vector3} from '../../../common/math/Vector3';
import {ShapeCollider} from '../../../common/math/ShapeCollider';
import {Ray3} from '../../../common/math/Ray3';
import {Position} from '../../../../gaia/value/GeoJson';
import {IndoorMetadata, IndoorMetadataArea} from '../../../../gaia/types';

type AreaInfo = {
  floors: string[];
  name: string;
};

/**
 * 屋内地図のメタデータを保持し、当たり判定などのエリア周りの処理を行う
 */
class IndoorAreaHelper {
  private metadata?: IndoorMetadata;
  private areaInfoMap: Map<number, AreaInfo>;
  private areaColliderMap: Map<number, ShapeCollider>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.metadata = undefined;
    this.areaInfoMap = new Map();
    this.areaColliderMap = new Map();
  }

  /**
   * メタデータをセットする
   * @param metadata 屋内メタデータ
   * @returns {void}
   */
  setMetadata(metadata: IndoorMetadata): void {
    this.metadata = metadata;
    this.areaInfoMap.clear();
    this.areaColliderMap.clear();
    this.setupCollider();
  }

  /**
   * メタデータから、当たり判定に用いるコライダを作成する
   * @param metadata メタデータ
   * @returns {void}
   */
  private setupCollider(): void {
    if (!this.metadata) {
      return;
    }

    this.metadata.areas.forEach((area: IndoorMetadataArea) => {
      const areaInfo: AreaInfo = {
        name: area.name,
        floors: area.floors,
      };
      this.areaInfoMap.set(area.area_id, areaInfo);
    });

    this.metadata.areas.forEach((area: IndoorMetadataArea) => {
      const areaId = area.area_id;
      const triangles: Triangle3[] = [];
      const stroke: number[] = [];

      // 1つ目のポリゴンの最初の形状だけ考える（飛び地も穴もないものとする）
      // TODO: 飛び地の考慮
      area.geometry.coordinates[0][0].forEach((position: Position): void => {
        const lat = position[1];
        const lon = position[0];
        const latLng = new LatLng(lat, lon);
        const worldPosition = calculateWorldCoordinate(latLng);
        stroke.push(worldPosition.x, worldPosition.y, 0);
      });

      const indices = earcut(stroke, [], 3);

      for (let index = 0; index < indices.length; index += 3) {
        const i1 = indices[index];
        const i2 = indices[index + 1];
        const i3 = indices[index + 2];
        const p1 = new Vector3(stroke[i1 * 3], stroke[i1 * 3 + 1], stroke[i1 * 3 + 2]);
        const p2 = new Vector3(stroke[i2 * 3], stroke[i2 * 3 + 1], stroke[i2 * 3 + 2]);
        const p3 = new Vector3(stroke[i3 * 3], stroke[i3 * 3 + 1], stroke[i3 * 3 + 2]);
        const triangle = new Triangle3(p1, p2._subtract(p1), p3._subtract(p1));
        triangles.push(triangle);
      }

      const collider = new ShapeCollider(triangles);
      this.areaColliderMap.set(areaId, collider);
    });
  }

  /**
   * レイに交差する地点に存在する屋内フロアの一覧を計算する
   * @param ray 当たり判定に使うレイ
   * @returns レイに交差する地点に存在する屋内フロアの一覧
   */
  findCollidedFloorList(ray: Ray3): string[] {
    const collidedAreaIdList: number[] = [];
    for (const [areaId, areaCollider] of this.areaColliderMap.entries()) {
      if (areaCollider.isCollided(ray)) {
        collidedAreaIdList.push(areaId);
      }
    }

    const floorListWithDuplication: string[] = [];
    for (const areaId of collidedAreaIdList) {
      const areaInfo = this.areaInfoMap.get(areaId);
      if (!areaInfo) {
        continue;
      }
      floorListWithDuplication.push(...areaInfo.floors);
    }

    // TODO: フロアリストを自然にソートする
    const floorList = floorListWithDuplication.filter((floor, index, list) => list.indexOf(floor) === index).sort();
    return floorList;
  }

  /**
   * 与えられたバウンディングボックスに交差するエリアが少なくとも1つあるか判定する
   * @param boundingBox バウンディングボックス
   * @returns 交差するエリアが1つでもあれば `true` そうでなければ `false`
   */
  isIntersects(boundingBox: [number, number, number, number]): boolean {
    if (!this.metadata) {
      return false;
    }

    let collided = false;
    this.metadata.areas.forEach((area: IndoorMetadataArea) => {
      if (!area.geometry.bbox) {
        return;
      }
      const [minLng, minLat, maxLng, maxLat] = area.geometry.bbox;
      const [tileMinLng, tileMinLat, tileMaxLng, tileMaxLat] = boundingBox;
      if (!(tileMinLng > maxLng || tileMaxLng < minLng || tileMinLat > maxLat || tileMaxLat < minLat)) {
        collided = true;
      }
    });
    return collided;
  }
}

export {IndoorAreaHelper, IndoorMetadata};
