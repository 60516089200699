import {CongestionConditionInitOptions} from '../types';
import {ZoomRange} from './ZoomRange';

/**
 * 混雑度情報コンディション
 */
class CongestionCondition {
  readonly server?: string;
  readonly zoomRange: ZoomRange;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param server タイル取得向き先URL
   */
  constructor(options: CongestionConditionInitOptions, server?: string) {
    this.server = server;
    this.zoomRange = new ZoomRange(8, 20);
  }
}

export {CongestionCondition};
