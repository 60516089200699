import {Layer, LayerUpdateNotifierFunc} from '../../engine/layer/Layer';
import {TexturePlaneGeometry} from '../../engine/geometry/TexturePlaneGeometry';
import {GaiaContext} from '../GaiaContext';
import {Vector3} from '../../common/math/Vector3';
import {TileNumber} from '../models/TileNumber';
import {TextureMapping} from '../../engine/program/TextureMapping';
import {Queue} from '../../common/collection/Queue';
import {mat4} from 'gl-matrix';
import {calculateWorldCoordinate, getZoomLevelFixFunc} from '../utils/MapUtil';
import {SimpleCache} from '../../common/collection/SimpleCache';
import {ArrayList} from '../../common/collection/ArrayList';
import {ZoomLevelFixFunc} from '../../common/types';
import {Object3D} from '../../engine/object/Object3D';
import {Collision} from '../../engine/collision/Collision';
import {Ray3} from '../../common/math/Ray3';
import {GradationMaterial} from '../../engine/material/GradationMaterial';
import {Color} from '../../../gaia/value';
import {GradationTileObject3D} from '../render/objects/GradationTileObject3D';

const MAX_SET_TEXTURE_COUNT = 2;

const TRANSPARENCY_DURATION = 200; // msec

/**
 * タイル画像描画レイヤー
 */
class GradationTileObjectLayer implements Layer {
  private readonly context: GaiaContext;

  /** 実際に描画している情報 */
  private drawTileObjects: SimpleCache<TileNumber, GradationTileObject3D>;
  /** タイル画像情報 */
  private tileTextures?: Map<TileNumber, TextureMapping>;

  /** 再利用可能なMaterial */
  private reusableMaterials: Queue<GradationMaterial>;

  private usesSubTile: boolean;
  private usesFadeInAnimation: boolean;

  private lastUpdateTime = 0;
  private visible = true;

  private notifyUpdate?: LayerUpdateNotifierFunc;

  private layerName: string;

  private readonly fixIntZoomLevel: ZoomLevelFixFunc;

  private isDestroyed = false;
  objects: Object3D[];

  representativeZoomLevels: number[];

  private colorMap: Map<number, Color>;

  private _isAltitudeMode: boolean;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param usesSubTile サブタイルを利用するかどうか
   * @param usesFadeInAnimation 新しいタイル表示時にフェードインのアニメーションを利用するかどうか
   * @param layerName レイヤー名
   * @param representativeZoomLevels 代表ズームレベル
   */
  constructor(
    context: GaiaContext,
    usesSubTile = false,
    usesFadeInAnimation = false,
    layerName = '',
    representativeZoomLevels?: number[]
  ) {
    this.context = context;
    this.objects = [];

    this.drawTileObjects = new SimpleCache<TileNumber, GradationTileObject3D>();
    this.reusableMaterials = new Queue<GradationMaterial>();

    this.usesSubTile = usesSubTile;
    this.usesFadeInAnimation = usesFadeInAnimation;
    this.layerName = layerName;

    this.fixIntZoomLevel = getZoomLevelFixFunc(this.context);

    this.representativeZoomLevels = representativeZoomLevels ?? [];

    this.colorMap = new Map();

    this._isAltitudeMode = false;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return this.layerName;
  }

  /**
   * 色を設定する
   * @param colorMap 色の設定
   * @returns {void}
   */
  setColorMap(colorMap: Map<number, Color>): void {
    this.colorMap = colorMap;

    for (const tileObject of this.drawTileObjects.values()) {
      tileObject.material.setColorMap(this.colorMap);
    }
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * 可視状態を取得
   * @returns 可視状態
   */
  getVisible(): boolean {
    return this.visible;
  }

  /**
   * レイヤーの表示制御
   * @param visible `true` : 表示, `false` : 非表示
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  /**
   * 標高モードが設定されているかを返す
   * @returns 標高モードの状態
   */
  isAltitudeMode(): boolean {
    return this._isAltitudeMode;
  }

  /**
   * 標高モードの設定を設定
   * @param altitudeMode 標高モードの状態
   * @returns {void}
   */
  setAltitudeMode(altitudeMode: boolean): void {
    this._isAltitudeMode = altitudeMode;
  }

  /**
   * 地図更新
   * @param tileTextureMap テクスチャ画像の連想配列
   * @returns {void}
   */
  updateTiles(tileTextureMap: Map<TileNumber, TextureMapping>): void {
    if (this.isDestroyed) {
      return;
    }

    this.tileTextures = tileTextureMap;

    const requiredTileList: ArrayList<TileNumber> = ArrayList.from<TileNumber>(Array.from(tileTextureMap.keys()));

    // 不要になったタイルを削除する
    const currentTiles = Array.from(this.drawTileObjects.keys());
    for (const tile of currentTiles) {
      if (requiredTileList.contains(tile)) {
        continue;
      }
      const tileObject = this.drawTileObjects.get(tile);
      if (!tileObject) {
        continue;
      }

      const material = tileObject.material;
      material.initTexture();
      this.reusableMaterials.enqueue(material);

      this.drawTileObjects.remove(tile);
    }

    // 新しく描画する必要があるタイルを追加する
    requiredTileList.forEach((tile: TileNumber) => {
      if (this.drawTileObjects.has(tile)) {
        return;
      }

      const material =
        this.reusableMaterials.dequeue() ??
        new GradationMaterial(this.context.getGLContext(), TexturePlaneGeometry.create(1, 1));
      material.setColorMap(this.colorMap);

      const tileObject = new GradationTileObject3D(
        Vector3.zero(),
        material,
        tile,
        this.fixIntZoomLevel,
        this.usesSubTile
      );

      const texture = tileTextureMap.get(tile);
      if (texture) {
        tileObject.setTexture(texture.imageSource);
      }

      if (this.usesFadeInAnimation) {
        tileObject.setTransparency(0);
      }
      this.drawTileObjects.add(tile, tileObject);
    });

    this.tileTextures = tileTextureMap;
    this.notifyUpdate?.();
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    const now = new Date().getTime();
    if (!this.tileTextures || !this.visible) {
      // 描く情報がなければ更新済み扱い
      this.lastUpdateTime = now;
      return true;
    }

    let setTextureCount = 0;
    for (const [tile, tileObject] of this.drawTileObjects.entries()) {
      if (setTextureCount < MAX_SET_TEXTURE_COUNT) {
        const textureMapping = this.tileTextures.get(tile);
        if (!textureMapping) {
          continue;
        }

        if (tileObject.isSetTexture) {
          continue;
        }
        tileObject.setTexture(textureMapping.imageSource);

        setTextureCount++;
      }
    }

    let updatedTransparency = false;
    let existsUnsetTextureTile = false;

    const diffMillisec = now - this.lastUpdateTime;
    const alphaDelta = diffMillisec / TRANSPARENCY_DURATION;

    for (const tileObject of this.drawTileObjects.values()) {
      if (!tileObject.isSetTexture) {
        existsUnsetTextureTile = true;
        continue;
      } else if (this.usesFadeInAnimation && tileObject.isTransparent()) {
        const alpha = tileObject.getTransparency();
        tileObject.setTransparency(alpha + alphaDelta > 1 ? 1 : alpha + alphaDelta);
        updatedTransparency = true;
      }
      const cameraTargetPosition = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);
      tileObject.mapUpdate(this.context.getMapStatus(), cameraTargetPosition);

      tileObject.update(viewMatrix, projectionMatrix);
      tileObject.draw();
    }

    for (const object of this.objects) {
      object.update(viewMatrix, projectionMatrix);
      object.draw();
    }

    this.lastUpdateTime = now;
    return setTextureCount === 0 && !updatedTransparency && !existsUnsetTextureTile;
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    while (this.reusableMaterials.size > 0) {
      const material = this.reusableMaterials.dequeue();
      if (!material) {
        break;
      }
      material.destroy();
    }
    for (const tileObject of this.drawTileObjects.values()) {
      tileObject.destroy();
    }
    this.drawTileObjects.clear();
    this.isDestroyed = true;
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {GradationTileObjectLayer};
