import {LatLng} from '../value';

/**
 * WGS84での地球の赤道半径(m)
 */
const GLOBE_SPHERE_RADIUS = 6378137.0;

/**
 * ミリ秒表記から度表記に変換する
 * @param position 緯度または経度
 * @returns 度表記の緯度または経度
 */
const transMillisecToDegree = (position: number): number => {
  return Math.round(position / 3.6) / 1000000;
};

/**
 * 度表記からミリ秒表記に変換する
 * @param position 緯度または経度
 * @returns ミリ秒表記の緯度または経度
 */
const transDegreeToMillisec = (position: number): number => {
  return Math.round(position * 3600000);
};

/**
 * 日本測地系を世界測地系に変換する
 * @param latlng 緯度経度
 * @returns 変換後緯度経度
 */
const transTokyoToWGS = (latlng: LatLng): LatLng => {
  const ALTITUDE = 0; //高度

  //初期化
  const aT = 6377397.155; //赤道半径（東京測地系）
  const bT = 6356078.963; //極半径（東京測地系）
  // var fT = (aT - bT) / aT; //扇平率（東京測地系）
  const eT = (aT * aT - bT * bT) / (aT * aT); //離心率(東京測地系)
  const aW = 6378137.0; //赤道半径（WGS系）
  const bW = 6356752.31424518; //極半径（WGS系）
  // var fW = (aW - bW) / aW; //扇平率（WGS系）
  const eW1 = (aW * aW - bW * bW) / (aW * aW); //離心率(WGS)
  const eW2 = (aW * aW - bW * bW) / (bW * bW); //離心率(WGS)

  const lngT = latlng.lngMillisec / 1000.0;
  const latT = latlng.latMillisec / 1000.0;

  //ラジアン値(Tokyo)
  const RlatT = (latT * Math.PI) / (180 * 3600);
  const RlngT = (lngT * Math.PI) / (180 * 3600);

  //ジオイド(Tokyo)
  const N = aT / Math.sqrt(1 - eT * Math.sin(RlatT) * Math.sin(RlatT));

  const X = (N + ALTITUDE) * Math.cos(RlatT) * Math.cos(RlngT) - 147.54;
  const Y = (N + ALTITUDE) * Math.cos(RlatT) * Math.sin(RlngT) - -507.26;
  const Z = (N * (1 - eT) + ALTITUDE) * Math.sin(RlatT) - -680.47;

  //theta:角度θ
  const P = Math.sqrt(X * X + Y * Y);
  const theta = Math.atan((Z * aW) / (P * bW));

  //ラジアン値(WGS)
  const RlatW = Math.atan2(
    Z + eW2 * bW * Math.sin(theta) * Math.sin(theta) * Math.sin(theta),
    P - eW1 * aW * Math.cos(theta) * Math.cos(theta) * Math.cos(theta)
  );
  const RlngW = Math.atan2(Y, X);

  //WGSへ変換
  const latWGS = RlatW * ((180.0 * 3600.0) / Math.PI) * 1000.0;
  const lngWGS = RlngW * ((180.0 * 3600.0) / Math.PI) * 1000.0;

  return new LatLng(transMillisecToDegree(Math.round(latWGS)), transMillisecToDegree(Math.round(lngWGS)));
};

/**
 * 世界測地系を日本測地系に変換する
 * @param latlng 緯度経度
 * @returns 変換後緯度経度
 */
const transWGSToTokyo = (latlng: LatLng): LatLng => {
  const ALTITUDE = 0.0; //高度

  //初期化
  const aW = 6378137.0; //赤道半径（WGS系）
  const bW = 6356752.31424518; //極半径（WGS系）
  // var fW = (aW - bW) / aW; //扇平率（WGS系）
  const eW = (aW * aW - bW * bW) / (aW * aW); //離心率(WGS)
  const aT = 6377397.155; //赤道半径（東京測地系）
  const bT = 6356078.963; //極半径（東京測地系）
  // var fT = (aT - bT) / aT; //扇平率（東京測地系）
  const eT1 = (aT * aT - bT * bT) / (aT * aT); //離心率(東京測地系)
  const eT2 = (aT * aT - bT * bT) / (bT * bT); //離心率(東京測地系)

  const lngW = latlng.lngMillisec / 1000.0;
  const latW = latlng.latMillisec / 1000.0;

  //ラジアン値(WGS)
  const RlatW = latW * (Math.PI / (180.0 * 3600.0));
  const RlngW = lngW * (Math.PI / (180.0 * 3600.0));

  //ジオイド(WGS)
  const N = aW / Math.sqrt(1 - eW * Math.sin(RlatW) * Math.sin(RlatW));

  const X = (N + ALTITUDE) * Math.cos(RlatW) * Math.cos(RlngW) + 147.54;
  const Y = (N + ALTITUDE) * Math.cos(RlatW) * Math.sin(RlngW) - 507.26;
  const Z = (N * (1 - eW) + ALTITUDE) * Math.sin(RlatW) - 680.47;

  const P = Math.sqrt(X * X + Y * Y);
  const theta = Math.atan((Z * aT) / (P * bT));

  //ラジアン値(Tokyo)
  const RlatT = Math.atan2(
    Z + eT2 * bT * Math.sin(theta) * Math.sin(theta) * Math.sin(theta),
    P - eT1 * aT * Math.cos(theta) * Math.cos(theta) * Math.cos(theta)
  );
  const RlngT = Math.atan2(Y, X);

  //Tokyoへ変換
  const latTokyo = RlatT * ((180.0 * 3600.0) / Math.PI) * 1000.0;
  const lngTokyo = RlngT * ((180.0 * 3600.0) / Math.PI) * 1000.0;

  return new LatLng(transMillisecToDegree(Math.round(latTokyo)), transMillisecToDegree(Math.round(lngTokyo)));
};

/**
 * 2地点間の距離を算出する
 * @param p1 地点1
 * @param p2 地点2
 * @returns 2地点間の距離
 */
const calcDistance = (p1: LatLng, p2: LatLng): number => {
  // https://bitbucket.office.navitime.co.jp/projects/COMP/repos/native-location/browse/GeoCalculator.cpp#164

  // eslint-disable-next-line require-jsdoc, @typescript-eslint/explicit-function-return-type
  const millisec2Rad = (millisec: number) => (millisec / 1000.0) * ((2 * Math.PI) / (360.0 * 60.0 * 60.0));
  const radLat1 = millisec2Rad(p1.latMillisec);
  const radLon1 = millisec2Rad(p1.lngMillisec);
  const radLat2 = millisec2Rad(p2.latMillisec);
  const radLon2 = millisec2Rad(p2.lngMillisec);

  const radCos =
    Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radLon1 - radLon2);
  const distance = GLOBE_SPHERE_RADIUS * Math.acos(radCos);

  return isNaN(distance) ? 0 : distance;
};

export {transMillisecToDegree, transDegreeToMillisec, transTokyoToWGS, transWGSToTokyo, calcDistance};
