import {Point, LatLng, ZoomRange} from '../value';
import {parseElement} from '../../_private/common/util/DOM';
import {CSSValueMap, EventListenerFunctionReturnType, Optional} from '../../_private/common/types';

type DOMObjectOptions = Partial<{
  content: HTMLElement | string;
  classList: string[];
  onPositionUpdate: () => void;
  zoomRange: ZoomRange;
}>;

/**
 * DOMの表示物ベースクラス
 */
abstract class DOMObject {
  private readonly wrapper: HTMLDivElement;
  private content?: HTMLElement;

  private position: LatLng;
  private _drawPosition?: Point;

  private zoomRange?: ZoomRange;

  private _isVisible = true;

  private onUpdatePositionListener?: (srcObject: DOMObject) => void;

  /**
   * コンストラクタ
   * @param position 表示位置緯度経度
   * @param options オプション
   */
  constructor(position: LatLng, options?: DOMObjectOptions) {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('gia-dom-object-wrapper');
    if (options?.classList) {
      for (const str of options.classList) {
        if (!str) {
          continue;
        }
        this.wrapper.classList.add(str);
      }
    }
    this.position = position;

    if (options?.content) {
      this.setContent(options.content);
    }
    if (options?.zoomRange) {
      this.zoomRange = options.zoomRange;
    }

    // mousedownイベントがCanvasまで伝播すると地図もクリックイベントを発行してしまうため止める
    this.wrapper.addEventListener('mousedown', (ev) => {
      ev.stopPropagation();
    });
  }

  /** X方向ずらし幅 */
  protected abstract getOffsetX(): number;

  /** Y方向ずらし幅 */
  protected abstract getOffsetY(): number;

  /**
   * 表示要素の設定
   * @param content 表示要素
   * @returns {void}
   */
  protected setContent(content: HTMLElement | string): void {
    if (this.content) {
      this.wrapper.removeChild(this.content);
    }
    this.content = parseElement(content);
    this.wrapper.appendChild(this.content);

    this.updateDrawPosition();
  }

  /**
   * 要素を追加
   * @param element {@link HTMLElement}
   * @returns {void}
   */
  protected addElement(element: HTMLElement): void {
    this.wrapper.appendChild(element);
  }

  /**
   * 要素を削除
   * @param element {@link HTMLElement}
   * @returns {void}
   */
  protected removeElement(element: HTMLElement): void {
    this.wrapper.removeChild(element);
  }

  /**
   * ラッパーへのCSS Styleの設定
   * @param styles CSS Style
   * @returns {void}
   */
  protected setWrapperStyle(styles: CSSValueMap): void {
    Object.entries(styles).forEach(([prop, value]) => {
      // camel to kebab
      const propNameKebab = prop
        .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
        .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
        .toLowerCase();
      this.wrapper.style.setProperty(propNameKebab, value ?? '');
    });
  }

  /**
   * ラッパーへのイベントリスナーへの設定
   * @param eventName イベント名
   * @param func イベントリスナー
   * @returns {void}
   */
  protected addEventListenerToWrapper<K extends keyof HTMLElementEventMap>(
    eventName: K,
    func: (ev: HTMLElementEventMap[K]) => EventListenerFunctionReturnType
  ): void {
    this.wrapper.addEventListener(eventName, func);
  }

  /**
   * 表示要素の取得
   * @returns 設定されている表示要素
   */
  protected getContent(): Optional<HTMLElement> {
    return this.content;
  }

  /**
   * 表示位置更新通知リスナーの設定
   * @ignore
   * @param listener リスナー関数
   * @returns {void}
   */
  setOnPositionUpdateListener(listener?: (object: DOMObject) => void): void {
    this.onUpdatePositionListener = listener;
  }

  /**
   * 表示地点の緯度経度を設定
   * @param position 緯度経度
   * @returns {void}
   */
  setPosition(position: LatLng): void {
    this.position = position;
    this.onUpdatePositionListener?.(this);
  }

  /**
   * z-indexを設定
   * @param zIndex 重なり順
   * @returns {void}
   */
  setZIndex(zIndex: number): void {
    this.setWrapperStyle({zIndex: zIndex.toString()});
  }

  /**
   * 表示位置の緯度経度を取得
   * @returns 緯度経度
   */
  getPosition(): LatLng {
    return this.position;
  }

  /**
   * 表示物のDOM要素を取得
   * @returns ラップされたDOM要素
   */
  getWrapElement(): HTMLDivElement {
    return this.wrapper;
  }

  /**
   * オブジェクトの表示状態を設定
   * @param visible 表示状態
   * @returns {void}
   */
  protected setVisible(visible: boolean): void {
    if (this._isVisible === visible) {
      return;
    }
    this._isVisible = visible;
    this.wrapper.style.display = visible ? 'block' : 'none';
  }

  /**
   * オブジェクトの表示状態を取得
   * @returns `true`:表示, `false`:非表示
   */
  protected isVisible(): boolean {
    return this._isVisible;
  }

  /**
   * DOMの画面ピクセル座標を取得
   * @returns 画面ピクセル座標
   */
  protected getDrawPosition(): Point {
    return this._drawPosition ?? new Point(0, 0);
  }

  /**
   * DOMの画面ピクセル座標を設定
   * @param position 画面ピクセル座標
   * @returns {void}
   */
  protected setDrawPosition(position: Point): void {
    this._drawPosition = position;
  }

  /**
   * DOMの位置更新
   * @ignore
   * @param point 画面ピクセル座標
   * @param zoom ズームレベル
   * @returns {void}
   */
  updateDrawPosition(point?: Point, zoom?: number): void {
    if (zoom && this.zoomRange) {
      this.setVisible(this.zoomRange.isInRange(zoom));
    }
    if (point) {
      this.wrapper.style.transform = `translate(${point.x + this.getOffsetX()}px, ${point.y + this.getOffsetY()}px)`;
      this._drawPosition = point;
    } else if (this._drawPosition) {
      const x = this._drawPosition.x + this.getOffsetX();
      const y = this._drawPosition.y + this.getOffsetY();
      this.wrapper.style.transform = `translate(${x}px, ${y}px)`;
    }
  }
}

export {DOMObject};
