import {HeatMapCondition} from '../../../../../gaia/value/HeatMapCondition';
import {GaiaContext} from '../../../GaiaContext';
import {HeatMapObjectLayer, LAYER_NAME_HEAT_MAP} from '../../../layer/HeatMapObjectLayer';
import {MapStatus} from '../../../models/MapStatus';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';

/**
 * ヒートマップを扱う描画キット
 */
class HeatMapObjectRenderKit extends AbstractObjectRenderKit {
  private readonly heatMapObjectLayer: HeatMapObjectLayer;

  /**
   * コンストラクタ
   * @param context Context
   * @param heatMapObjectLayer HeatMapObjectLayer
   * @param renderKitCtl MapRenderKitController
   */
  constructor(context: GaiaContext, heatMapObjectLayer: HeatMapObjectLayer, renderKitCtl: MapRenderKitController) {
    super(context, heatMapObjectLayer, renderKitCtl);
    this.heatMapObjectLayer = heatMapObjectLayer;
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return LAYER_NAME_HEAT_MAP;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.heatMapObjectLayer.update(mapStatus);
  }

  /** @override */
  onDestroy(): void {
    this.heatMapObjectLayer.destroy();
  }

  /**
   * ヒートマップを追加
   * @param condition HeatMapCondition
   * @returns {void}
   */
  addHeatMap(condition: HeatMapCondition): void {
    this.heatMapObjectLayer.addCondition(condition);
  }

  /**
   * ヒートマップを削除
   * @param condition HeatMapCondition
   * @returns {void}
   */
  removeHeatMap(condition: HeatMapCondition): void {
    this.heatMapObjectLayer.removeCondition(condition);
  }
}

export {HeatMapObjectRenderKit};
