import {Color} from '../../../../../gaia/value';

/**
 * pbrMetallicRoughness
 */
class GltfPbrMetallicRoughness {
  readonly baseColor: Color;
  readonly metallicFactor: number;
  readonly roughnessFactor: number;

  /**
   * コンストラクタ
   * @param baseColor 基本色
   * @param metallicFactor 金属らしさ
   * @param roughnessFactor 粗さ
   */
  constructor(baseColor?: Color, metallicFactor?: number, roughnessFactor?: number) {
    this.baseColor = baseColor ?? Color.white();
    this.metallicFactor = metallicFactor ?? 1.0;
    this.roughnessFactor = roughnessFactor ?? 1.0;
  }
}

export {GltfPbrMetallicRoughness};
