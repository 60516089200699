import {SimpleCache} from './SimpleCache';
import {CacheKey, Optional} from '../types';

type OnLeaveEntryFunc<K, V> = (key: K, value: V) => void;

/**
 * LRUキャッシュ
 */
class LRUCache<K extends CacheKey, V> extends SimpleCache<K, V> {
  private maxSize: number;
  private readonly onLeaveEntry: OnLeaveEntryFunc<K, V>;

  private readonly keyList: Map<string, K>;

  /**
   * コンストラクタ
   * @param maxSize キャッシュ最大サイズ
   * @param callback 古いエントリの削除通知
   */
  constructor(maxSize = 20, callback: OnLeaveEntryFunc<K, V> = (): void => void 0) {
    super();

    this.maxSize = maxSize;
    this.onLeaveEntry = callback;

    this.keyList = new Map<string, K>();
  }

  /** @override */
  get(key: K): Optional<V> {
    const value = super.get(key);
    if (value) {
      this.updateKey(key);
    }
    return value;
  }

  /** @override */
  add(key: K, value: V): void {
    if (this.has(key)) {
      super.add(key, value);
      this.updateKey(key);
      return;
    }

    super.add(key, value);
    this.updateKey(key);
    if (super.size() > this.maxSize) {
      const [keyStr, keyObj] = this.keyList.entries().next().value as [string, K];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.onLeaveEntry(keyObj, super.get(keyObj)!);
      super.remove(keyObj);
      this.keyList.delete(keyStr);
    }

    return;
  }

  /** @override */
  remove(key: K): boolean {
    const ret = super.remove(key);
    if (ret) {
      this.keyList.delete(key.getCacheKey());
    }
    return ret;
  }

  /** @override */
  clear(): void {
    super.clear();
    this.keyList.clear();
  }

  /** @override */
  keys(): IterableIterator<K> {
    return this.keyList.values();
  }

  /** @override */
  values(): IterableIterator<V> {
    const value = new Set<V>();
    for (const key of this.keys()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value.add(super.get(key)!);
    }
    return value.values();
  }

  /** @override */
  entries(): IterableIterator<[K, V]> {
    const entry = new Map<K, V>();
    for (const key of this.keys()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      entry.set(key, super.get(key)!);
    }
    return entry.entries();
  }

  /**
   * キャッシュの上限サイズを増加させる
   * もし現在の上限サイズの方が大きい場合は何もしない
   * このメソッド呼び出しで最大サイズが小さくなることはない
   * @param maxSize 最大サイズ
   * @returns {void}
   */
  jumpUpSize(maxSize: number): void {
    if (this.maxSize > maxSize) {
      return;
    }
    this.maxSize = maxSize;
  }

  /**
   * キーを最新として更新
   * @param key キー
   * @returns {void}
   */
  private updateKey(key: K): void {
    this.keyList.delete(key.getCacheKey());
    this.keyList.set(key.getCacheKey(), key);
  }
}

export {LRUCache, OnLeaveEntryFunc};
