import {CacheKey, Optional} from '../../common/types';
import {Vector2} from '../../common/math/Vector2';
import {JsonObject, TileSize} from '../../../gaia/types';
import {LatLng} from '../../../gaia/value/LatLng';
import {ValueObject} from '../../../gaia/value/interface/ValueObject';
import {calculatePixelCoordinate, pixelToLatLng} from '../utils/MapUtil';

const MAX_LAT = 90;
const MIN_LAT = -90;
const MAX_LNG = 180;
const MIN_LNG = -180;

const MAX_ZOOM_LEVEL = 24;
const MIN_ZOOM_LEVEL = 0;
type TileNumberCompareFunction = (a: TileNumber, b: TileNumber) => number;

/**
 * 与えられた緯度経度からの距離でタイルを昇順に比較する関数を返す
 * @param baseLocation 基準となる緯度経度
 * @returns 比較関数
 */
const createCompareFunctionWithDistance = (baseLocation: LatLng): TileNumberCompareFunction => {
  return (a: TileNumber, b: TileNumber): number => {
    const aDistance = Math.sqrt(
      (a.centerLocation.lat - baseLocation.lat) ** 2 + (a.centerLocation.lng - baseLocation.lng) ** 2
    );
    const bDistance = Math.sqrt(
      (b.centerLocation.lat - baseLocation.lat) ** 2 + (b.centerLocation.lng - baseLocation.lng) ** 2
    );
    return aDistance - bDistance;
  };
};

/**
 * タイル番号
 */
class TileNumber implements ValueObject, CacheKey {
  /** タイルX番号 */
  private _x: number;
  /** タイルY番号 */
  private _y: number;
  /** タイルZ番号 */
  private _z: number;

  private cacheKey: string;

  private _centerLocation?: LatLng;

  private _southWestLocation?: LatLng;
  private _northEastLocation?: LatLng;

  /**
   * コンストラクタ
   * @param x xの値
   * @param y yの値
   * @param z zの値
   */
  constructor(x: number, y: number, z: number) {
    this._x = x;
    this._y = y;
    this._z = z;
    this._centerLocation = undefined;
    this.cacheKey = `TN(${this._x}, ${this._y}, ${this._z})`;
  }

  /**
   * 値を設定
   * @param x xの値
   * @param y yの値
   * @param z zの値
   * @returns {void}
   */
  setValues(x: number, y: number, z: number): void {
    this._x = x;
    this._y = y;
    this._z = z;
    this._centerLocation = undefined;
    this._southWestLocation = undefined;
    this._northEastLocation = undefined;
    this.cacheKey = `TN(${this._x}, ${this._y}, ${this._z})`;
  }

  /** @override */
  getCacheKey(): string {
    return this.cacheKey;
  }

  /** @override */
  clone(): TileNumber {
    return new TileNumber(this._x, this._y, this._z);
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof TileNumber)) {
      return false;
    }
    const other = obj as TileNumber;
    return other._x === this._x && other._y === this._y && other._z === this._z;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      x: this._x,
      y: this._y,
      z: this._z,
    };
  }

  /**
   * xの値
   */
  get x(): number {
    return this._x;
  }

  /**
   * yの値
   */
  get y(): number {
    return this._y;
  }

  /**
   * zの値
   */
  get z(): number {
    return this._z;
  }

  /**
   * 中心緯度経度
   */
  get centerLocation(): LatLng {
    if (!this._centerLocation) {
      const pixel = this.calculatePixelCoordinate(new Vector2(0.5, 0.5));
      const centerLocation: LatLng = pixelToLatLng(pixel, this._z);
      this._centerLocation = centerLocation;
      return this._centerLocation;
    } else {
      return this._centerLocation;
    }
  }

  /**
   * 南西の緯度経度
   */
  get southWestLocation(): LatLng {
    if (!this._southWestLocation) {
      const pixel = this.calculatePixelCoordinate(new Vector2(0, 1));
      const southWestLocation: LatLng = pixelToLatLng(pixel, this._z);
      this._southWestLocation = southWestLocation;
    }
    return this._southWestLocation;
  }

  /**
   * 北東の緯度経度
   */
  get northEastLocation(): LatLng {
    if (!this._northEastLocation) {
      const pixel = this.calculatePixelCoordinate(new Vector2(1, 0));
      const northEastLocation: LatLng = pixelToLatLng(pixel, this._z);
      this._northEastLocation = northEastLocation;
    }
    return this._northEastLocation;
  }

  /**
   * 緯度経度の度数表記でのバウンディングボックスを計算する
   * @returns バウンディングボックス
   */
  calculateLocationBoundingBox(): [number, number, number, number] {
    return [
      this.southWestLocation.lng,
      this.southWestLocation.lat,
      this.northEastLocation.lng,
      this.northEastLocation.lat,
    ];
  }

  /**
   * ピクセル座標を計算
   * @param offset オフセット(x, y共に0以上1以下)
   * @returns ピクセル座標
   */
  calculatePixelCoordinate(offset: Vector2): Vector2 {
    const pixelX = (this._x + offset.x) * 256;
    const pixelY = (this._y + offset.y) * 256;
    return new Vector2(pixelX, pixelY);
  }

  /**
   * 緯度経度とズームレベルからタイル番号を計算する
   * @param latLng 緯度経度
   * @param zoomLevel ズームレベル
   * @param tileSize タイルサイズ
   * @returns タイル番号
   */
  static fromLatLng(latLng: LatLng, zoomLevel: number, tileSize: TileSize = 256): TileNumber {
    let lat = latLng.lat;
    while (lat < MIN_LAT) {
      lat += 180;
    }
    while (lat > MAX_LAT) {
      lat -= 180;
    }

    let lng = latLng.lng;
    while (lng < MIN_LNG) {
      lng += 360;
    }
    while (lng > MAX_LNG) {
      lng -= 360;
    }

    const pixelCoordinate: Vector2 = calculatePixelCoordinate(latLng, zoomLevel);
    const x = Math.floor(pixelCoordinate.x / tileSize);
    const y = Math.floor(pixelCoordinate.y / tileSize);
    const z = Math.floor(zoomLevel);
    return new TileNumber(x, y, z);
  }

  /**
   * 自分自身のタイルを覆うような、ズームレベルが1小さいタイルを返す
   * 自分がズームレベル0の場合は null を返す
   * @returns 今のタイルを覆うような、ズームレベルが1小さいタイル
   */
  upper(): Optional<TileNumber> {
    if (this._z <= 0) {
      return null;
    }
    return new TileNumber(Math.floor(this._x / 2), Math.floor(this._y / 2), this._z - 1);
  }

  /**
   * 北のタイルを返す
   * @returns 北のタイル
   */
  north(): Optional<TileNumber> {
    if (this._y <= 0) {
      return null;
    }
    return new TileNumber(this._x, this._y - 1, this._z);
  }

  /**
   * 南のタイルを返す
   * @returns 南のタイル
   */
  south(): Optional<TileNumber> {
    if (this._y >= Math.pow(2, this._z) - 1) {
      return null;
    }
    return new TileNumber(this._x, this._y + 1, this._z);
  }

  /**
   * 西のタイルを返す
   * @returns 西のタイル
   */
  west(): Optional<TileNumber> {
    if (this._x <= 0) {
      return null;
    }
    return new TileNumber(this._x - 1, this._y, this._z);
  }

  /**
   * 東のタイルを返す
   * @returns 東のタイル
   */
  east(): Optional<TileNumber> {
    if (this._x >= Math.pow(2, this._z) - 1) {
      return null;
    }
    return new TileNumber(this._x + 1, this._y, this._z);
  }
}

export {
  TileNumber,
  MAX_LAT,
  MIN_LAT,
  MAX_LNG,
  MIN_LNG,
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  createCompareFunctionWithDistance,
};
