import {mat4} from 'gl-matrix';
import {Color} from '../../../gaia/value/Color';
import {Geometry} from '../geometry/Geometry';
import {Object3D} from '../object/Object3D';
import {HeatMapProgram} from '../program/HeatMapProgram';
import {TextureMapping} from '../program/TextureMapping';
import {Material} from './Material';

/**
 * ヒートマップ用のマテリアル
 */
class HeatMapMaterial implements Material {
  private readonly context: WebGLRenderingContext;
  readonly program: HeatMapProgram;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param isPremultipliedAlpha アルファモードの指定
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry, isPremultipliedAlpha = false) {
    this.context = context;
    this.program = new HeatMapProgram(context, geometry, isPremultipliedAlpha);
  }

  /**
   * 更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    this.program.update(object3D, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @returns {void}
   */
  draw(): void {
    this.program.draw();
  }

  /**
   * テクスチャを更新
   * @param image テクスチャ
   * @returns {void}
   */
  setTexture(image: TexImageSource): void {
    this.program.setTexture(image);
  }

  /**
   * GLのテクスチャを設定
   * @param texture GLテクスチャ
   * @returns {void}
   */
  setGLTexture(texture: WebGLTexture): void {
    this.program.setGLTexture(texture);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.program.setGeometry(geometry);
  }

  /**
   * テクスチャ情報を更新
   * @param textureMapping テクスチャ情報
   * @returns {void}
   */
  setTextureMapping(textureMapping: TextureMapping): void {
    this.program.setTextureMapping(textureMapping);
  }

  /**
   * テクスチャが少しでも透明かどうか
   * @returns 透明性（少しでも透明であればtrue）
   */
  isTransparent(): boolean {
    return this.program.isTransparent();
  }

  /**
   * テクスチャの透明度を取得
   * @returns テクスチャの透明度
   */
  getTextureTransparency(): number {
    return this.program.getTextureTransparency();
  }

  /**
   * テクスチャの透明度を更新
   * @param alpha 透明度
   * @returns {void}
   */
  setTextureTransparency(alpha: number): void {
    this.program.setTextureTransparency(alpha);
  }

  /**
   * 重みと色の対応付けを設定
   * @param colorMap 重みと色の対応付け
   * @returns {void}
   */
  setColorMap(colorMap: Map<number, Color>): void {
    this.program.setColorMap(colorMap);
  }

  /**
   * テクスチャを初期化
   * @returns {void}
   */
  initTexture(): void {
    this.program.initTexture();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.program.destroy();
  }
}

export {HeatMapMaterial};
