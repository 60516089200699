import {Vector3} from '../../../common/math/Vector3';
import {Object3D} from '../../../engine/object/Object3D';
import {Material} from '../../../engine/material/Material';
import {Quaternion} from '../../../common/math/Quaternion';
import {MapStatus} from '../../models/MapStatus';

/**
 * 図形オブジェクトのクラス
 */
abstract class FigureObject extends Object3D {
  public readonly basePosition: Vector3;
  public readonly zIndex: number;

  /**
   * コンストラクタ
   * @param position 位置
   * @param rotation 回転
   * @param scale 拡縮
   * @param material マテリアル
   * @param basePosition 基準位置
   * @param visible 表示状態
   * @param zIndex 重なり順
   */
  constructor(
    position: Vector3,
    rotation: Quaternion,
    scale: Vector3,
    material: Material,
    basePosition: Vector3,
    visible: boolean,
    zIndex: number
  ) {
    super(position, rotation, scale, material, visible);
    this.basePosition = basePosition;
    this.zIndex = zIndex;
  }

  abstract updateFigure(cameraTargetPosition: Vector3, mapStatus: MapStatus): void;
}

export {FigureObject};
