import {JsonObject} from '../../../../gaia/types';
import {AnnotationMetaParameter} from '../../../map/loader/param/AnnotationMetaParameter';
import {AnnotationMainParameter} from '../../../map/loader/param/AnnotationMainParameter';
import {Queue} from '../../collection/Queue';
import {createWorker} from '../../util/InlineWorker';
import {TileRequestUrlBuilderFunc} from './AbstractTileRequester';
import {MapIconMainParameter} from '../../../map/loader/param/MapIconMainParameter';

/** メタリクエストのURL生成関数 */
type MetaRequestUrlBuilderFunc = (metaParam: AnnotationMetaParameter) => string;

type OnMetaResponse = (metaParam: AnnotationMetaParameter, info?: JsonObject) => void;
type OnIconResponse = (metaParam: AnnotationMetaParameter, info?: JsonObject) => void;
type OnMainResponse = (mainParam: AnnotationMainParameter, info: JsonObject) => void;
type OnMapIconMainResponse = (mainParam: MapIconMainParameter, info: JsonObject) => void;

type DataType = 'annotationMeta' | 'annotationIcon' | 'annotationMain' | 'mapIconMain';
type WorkerRequestData = {type: DataType; requests: {[key: string]: string}};
type WorkerResponseData = {type: DataType; key: string; data?: string | Blob};

/**
 * 注記関連情報を取得するリクエストクラス
 */
class AnnotationInfoRequester {
  private readonly metaUrlBuilder: MetaRequestUrlBuilderFunc;
  private readonly iconUrlBuilder: MetaRequestUrlBuilderFunc;
  private readonly mainUrlBuilder: TileRequestUrlBuilderFunc<AnnotationMainParameter>;
  private readonly mapIconMainUrlBuilder: TileRequestUrlBuilderFunc<MapIconMainParameter>;

  private readonly waitingQueue: Queue<WorkerRequestData> = new Queue<WorkerRequestData>();

  private readonly metaPrameterMap: Map<string, AnnotationMetaParameter>;
  private readonly mainParameterMap: Map<string, AnnotationMainParameter>;
  private readonly mapIconMainParameterMap: Map<string, MapIconMainParameter>;

  private readonly workerNum: number;
  private readonly workerPool: Queue<Worker> = new Queue<Worker>();
  private readonly runningWorkers: Worker[] = [];

  private readonly metaCallbackMap: Map<string, OnMetaResponse> = new Map();
  private readonly iconCallbackMap: Map<string, OnIconResponse> = new Map();
  private readonly mainCallbackMap: Map<string, OnMainResponse> = new Map();
  private readonly mapIconMainCallbackMap: Map<string, OnMapIconMainResponse> = new Map();

  /**
   * コンストラクタ
   * @param metaUrlBuilder メタリクエストURL生成Builder関数
   * @param iconUrlBuilder アイコンリクエストURL生成Builder関数
   * @param mainUrlBuilder メインリクエストURL生成Builder関数
   * @param mapIconMainUrlBuilder 地図アイコンメインリクエストURL生成Builder関数
   * @param workerNum 利用するWorker数
   */
  constructor(
    metaUrlBuilder: MetaRequestUrlBuilderFunc,
    iconUrlBuilder: MetaRequestUrlBuilderFunc,
    mainUrlBuilder: TileRequestUrlBuilderFunc<AnnotationMainParameter>,
    mapIconMainUrlBuilder: TileRequestUrlBuilderFunc<MapIconMainParameter>,
    workerNum = 2
  ) {
    this.metaUrlBuilder = metaUrlBuilder;
    this.iconUrlBuilder = iconUrlBuilder;
    this.mainUrlBuilder = mainUrlBuilder;
    this.mapIconMainUrlBuilder = mapIconMainUrlBuilder;
    this.metaPrameterMap = new Map<string, AnnotationMetaParameter>();
    this.mainParameterMap = new Map<string, AnnotationMainParameter>();
    this.mapIconMainParameterMap = new Map<string, MapIconMainParameter>();

    this.workerNum = workerNum;
  }

  /**
   * リクエストのクリア
   * @returns {void}
   */
  clear(): void {
    this.waitingQueue.clear();
    this.mainParameterMap.clear();
  }

  /**
   * メタリクエストを実行
   * @param metaParam AnnotationMetaParameter
   * @param onLoadMeta メタ読み込み通知
   * @returns {void}
   */
  metaRequest(metaParam: AnnotationMetaParameter, onLoadMeta: OnIconResponse): void {
    const key = metaParam.getCacheKey();
    const requests: {[key: string]: string} = {};
    requests[key] = this.metaUrlBuilder(metaParam);
    const message: WorkerRequestData = {
      type: 'annotationMeta',
      requests,
    };
    this.metaPrameterMap.set(key, metaParam);
    this.metaCallbackMap.set(key, onLoadMeta);
    this.requestToWorker(message);
  }

  /**
   * アイコンリクエストを実行
   * @param metaParam AnnotationMetaParameter
   * @param onLoadIcon アイコン読み込み通知
   * @returns {void}
   */
  iconRequest(
    metaParam: AnnotationMetaParameter,
    onLoadIcon: (metaParam: AnnotationMetaParameter, zip: Blob) => void
  ): void {
    const key = metaParam.getCacheKey();
    const requests: {[key: string]: string} = {};
    requests[key] = this.iconUrlBuilder(metaParam);
    const message: WorkerRequestData = {
      type: 'annotationIcon',
      requests,
    };
    this.metaPrameterMap.set(key, metaParam);
    this.iconCallbackMap.set(key, onLoadIcon);
    this.requestToWorker(message);
  }

  /**
   * メインリクエストを実行
   * @param mainParam AnnotationMainParameter
   * @param onLoadTile 注記情報読み込み通知
   * @returns {void}
   */
  mainRequest(mainParam: AnnotationMainParameter, onLoadTile: OnMainResponse): void {
    const key = mainParam.getCacheKey();
    const requests: {[key: string]: string} = {};
    requests[key] = this.mainUrlBuilder(mainParam);
    const message: WorkerRequestData = {
      type: 'annotationMain',
      requests,
    };
    this.mainParameterMap.set(key, mainParam);
    this.mainCallbackMap.set(key, onLoadTile);
    this.requestToWorker(message);
  }

  /**
   * 地図アイコンのメインリクエストを実行
   * @param mapIconMainParam MapIconMainParameter
   * @param onLoadTile 地図アイコン情報読み込み通知
   * @returns {void}
   */
  mapIconMainRequest(mapIconMainParam: MapIconMainParameter, onLoadTile: OnMapIconMainResponse): void {
    const key = mapIconMainParam.getCacheKey();
    const requests: {[key: string]: string} = {};
    requests[key] = this.mapIconMainUrlBuilder(mapIconMainParam);
    const message: WorkerRequestData = {
      type: 'mapIconMain',
      requests,
    };
    this.mapIconMainParameterMap.set(key, mapIconMainParam);
    this.mapIconMainCallbackMap.set(key, onLoadTile);
    this.requestToWorker(message);
  }

  /**
   * workerへの通信処理リクエスト
   * @param request WorkerRequestData
   * @returns {void}
   */
  private requestToWorker(request: WorkerRequestData): void {
    let worker = this.workerPool.dequeue();
    if (!worker) {
      if (this.runningWorkers.length >= this.workerNum) {
        this.waitingQueue.enqueue(request);
        return;
      }
      worker = createWorker(this.sendRequest);
    }
    this.runningWorkers.push(worker);

    worker.onmessage = (ev: MessageEvent): void => {
      if (!worker) return;
      // workerをPoolにもどす
      const index = this.runningWorkers.indexOf(worker);
      if (index > -1) {
        this.runningWorkers.splice(index, 1);
      }
      this.workerPool.enqueue(worker);

      const {type, key, data} = ev.data as WorkerResponseData;
      switch (type) {
        case 'annotationMeta':
          this.executeMetaRequestCallback(key, data);
          return;
        case 'annotationIcon':
          this.executeIconRequestCallback(key, data);
          return;
        case 'annotationMain':
          this.executeMainRequestCallback(key, data);
          return;
        case 'mapIconMain':
          this.executeMapIconMainRequestCallback(key, data);
          return;
      }
    };
    worker.postMessage(request);
  }

  /**
   * リクエスト送信処理
   * @param ev メインスレッドから受け取ったMessageEvent
   * @returns {void}
   */
  private sendRequest(ev: MessageEvent): void {
    const {type, requests} = ev.data as WorkerRequestData;
    for (const key in requests) {
      fetch(requests[key])
        .then((response) => {
          switch (type) {
            case 'annotationMeta':
              return response.json();
            case 'annotationIcon':
              return response.blob();
            case 'annotationMain':
              return response.text();
            case 'mapIconMain':
              return response.text();
          }
        })
        .then((data) => {
          const resMessage: WorkerResponseData = {type, key, data};
          ((self as unknown) as Worker).postMessage(resMessage);
        })
        .catch(() => {
          ((self as unknown) as Worker).postMessage({type, key, data: undefined});
        });
    }
  }

  /**
   * メタリクエストの通信完了通知
   * @param key 注記パラメータのcacheKey
   * @param data 通信結果
   * @returns {void}
   */
  private executeMetaRequestCallback(key: string, data?: string | Blob): void {
    const callback = this.metaCallbackMap.get(key);
    const param = this.metaPrameterMap.get(key);
    if (param && data) {
      callback?.(param, data);
      this.metaCallbackMap.delete(key);
      this.metaPrameterMap.delete(key);
    }

    this.executeWaitingQueue();
  }

  /**
   * アイコンリクエストの通信完了通知
   * @param key 注記パラメータのcacheKey
   * @param data 通信結果
   * @returns {void}
   */
  private executeIconRequestCallback(key: string, data?: string | Blob): void {
    const callback = this.iconCallbackMap.get(key);
    const param = this.metaPrameterMap.get(key);
    if (param && data) {
      callback?.(param, data);
      this.iconCallbackMap.delete(key);
      this.metaPrameterMap.delete(key);
    }

    this.executeWaitingQueue();
  }

  /**
   * メインリクエストの通信完了通知
   * @param key 注記パラメータのcacheKey
   * @param data 通信結果
   * @returns {void}
   */
  private executeMainRequestCallback(key: string, data?: string | Blob): void {
    const callback = this.mainCallbackMap.get(key);
    const param = this.mainParameterMap.get(key);
    if (param && data) {
      callback?.(param, data);
      this.mainCallbackMap.delete(key);
      this.mainParameterMap.delete(key);
    }

    this.executeWaitingQueue();
  }

  /**
   * 地図アイコンメインリクエストの通信完了通知
   * @param key 地図アイコンパラメータのcacheKey
   * @param data 通信結果
   * @returns {void}
   */
  private executeMapIconMainRequestCallback(key: string, data?: string | Blob): void {
    const callback = this.mapIconMainCallbackMap.get(key);
    const param = this.mapIconMainParameterMap.get(key);
    if (param && data) {
      callback?.(param, data);
      this.mapIconMainCallbackMap.delete(key);
      this.mapIconMainParameterMap.delete(key);
    }

    this.executeWaitingQueue();
  }

  /**
   * リクエスト待ちキューの消化
   * @returns {void}
   */
  private executeWaitingQueue(): void {
    const queue = this.waitingQueue.dequeue();
    if (queue) {
      this.requestToWorker(queue);
    }
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    for (const worker of this.runningWorkers) {
      worker.terminate();
    }
    while (this.workerPool.size > 0) {
      const worker = this.workerPool.dequeue();
      worker?.terminate();
    }
  }
}

export {AnnotationInfoRequester};
