import {mat4} from 'gl-matrix';
import {Color} from '../../../gaia/value';
import {Vector3} from '../../common/math/Vector3';
import {Geometry} from '../geometry/Geometry';
import {Object3D} from '../object/Object3D';
import {FogProgram} from '../program/FogProgram';
import {Material} from './Material';

/**
 * 霧用のマテリアル
 */
class FogMaterial implements Material {
  private readonly context: WebGLRenderingContext;
  readonly program: FogProgram;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param fogColor 霧の色
   * @param skyColor 空の色
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry, fogColor: Color, skyColor: Color) {
    this.context = context;
    this.program = new FogProgram(context, geometry, fogColor, skyColor);
  }

  /**
   * 更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    this.program.update(object3D, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @returns {void}
   */
  draw(): void {
    this.program.draw();
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.program.setGeometry(geometry);
  }

  /**
   * カメラの位置を設定
   * @param cameraPosition カメラの位置
   * @returns {void}
   */
  setCameraPosition(cameraPosition: Vector3): void {
    this.program.setCameraPosition(cameraPosition);
  }

  /**
   * 霧の距離係数を設定
   * @param fogDistanceRatio 霧の距離係数
   * @returns {void}
   */
  setFogDistanceRatio(fogDistanceRatio: number): void {
    this.program.setFogDistanceRatio(fogDistanceRatio);
  }

  /**
   * テクスチャを設定
   * @param texture テクスチャ
   * @returns {void}
   */
  setGLTexture(texture: WebGLTexture): void {
    this.program.setGLTexture(texture);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.program.destroy();
  }
}

export {FogMaterial};
