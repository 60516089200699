import {GaiaContext} from '../GaiaContext';
import {TileParameter} from '../models/TileParameter';
import {AbstractTileLoader, OnFinishRequestFunc} from './AbstractTileLoader';
import {QueryParameter} from '../../common/types';
import {buildQueryString} from '../../common/util/URL';

const WEBP_IMAGE_BASE64 =
  'data:image/webp;base64,UklGRlYCAABXRUJQVlA4WAoAAAAgAAAAAAAAAAAASUNDUBgCAAAAAAIYAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANlZQOCAYAAAAMAEAnQEqAQABAAFAJiWkAANwAP789AAA';

/**
 * 地図タイルローダー
 */
class MapTileLoader extends AbstractTileLoader<TileParameter> {
  private serverUrl = '';
  private setServerUrl = false;

  private useWebp = false;
  private checkWebp = false;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   * @param lang 言語
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );

    context
      .getGaIAConfiguration()
      .then((res) => {
        this.serverUrl = `${res.server.tile}map/${res.productId}`;
        this.setServerUrl = true;
        this.trySetReady();
        super.executeRequest();
      })
      .catch(() => {
        // do nothing
      });

    const image = new Image();
    image.onload = (): void => {
      this.checkWebp = true;
      this.useWebp = true;
      this.trySetReady();
    };
    image.onerror = (): void => {
      this.checkWebp = true;
      this.useWebp = false;
      this.trySetReady();
    };
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      if (this.checkWebp) {
        return;
      }
      // imageを使ったwebp対応チェックが遅い場合はpngを使用する
      this.checkWebp = true;
      this.useWebp = false;
      this.trySetReady();
    }, 100);
    image.src = WEBP_IMAGE_BASE64;
  }

  /**
   * タイルのURLを生成
   * @param tile TileNumber
   * @returns リクエストURL
   */
  private createUrl(tile: TileParameter): string {
    const {x, y, z, tileType, tileSize, palette, annotation} = tile;
    const query: QueryParameter = {
      product: 'web',
      lang: palette.language,
    };
    if (!annotation) {
      query.annotation = 'false';
    }
    if (palette.name) {
      query['palette-name'] = palette.name;
    }
    if (!tile.building3D) {
      query['building3d'] = 'false';
    }
    if (this.useWebp) {
      query['accept'] = 'image/webp';
    }
    return `${this.serverUrl}/${tileType}/${tileSize}/${z}/${y}/${x}?${buildQueryString(query)}`;
  }

  /**
   * レディ状態に移行できる場合は移行する
   * @returns {void}
   */
  private trySetReady(): void {
    if (!this.setServerUrl) {
      return;
    }
    if (!this.checkWebp) {
      return;
    }
    this.setState('ready');
  }
}
export {MapTileLoader};
