import earcut from 'earcut';

import {ShapeLayer} from '../../../layer/ShapeLayer';
import {GaiaContext} from '../../../GaiaContext';
import {PolygonObject} from '../../objects/PolygonObject';
import {Vector3} from '../../../../common/math/Vector3';
import {Quaternion} from '../../../../common/math/Quaternion';
import {SingleColorMaterial} from '../../../../engine/material/SingleColorMaterial';
import {CustomGeometry} from '../../../../engine/geometry/CustomGeometry';
import {Polygon} from '../../../../../gaia/object/shape/Polygon';
import {calculateWorldCoordinate, worldToLatLng} from '../../../utils/MapUtil';
import {Color, LatLng} from '../../../../../gaia/value';
import {PolylineObject} from '../../objects/PolylineObject';
import {Polyline} from '../../../../../gaia/object';
import {LayerUpdateNotifierFunc} from '../../../../engine/layer/Layer';
import {Figure} from '../../../../../gaia/object/shape/Figure';
import {Circle} from '../../../../../gaia/object/shape/Circle';
import {MapStatus} from '../../../models/MapStatus';
import {Camera} from '../../../../engine/camera/Camera';
import {viewPointToIntersectionForPerspectiveCamera} from '../../MapTileScanner';
import {PerspectiveCamera} from '../../../../engine/camera/PerspectiveCamera';
import {Vector2} from '../../../../common/math/Vector2';
import {calcDistance} from '../../../../../gaia/util';
import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {PolylineEventMap} from '../../../../../gaia/types';
import {Optional} from '../../../../common/types';
import {CollidableFigureEvent} from '../../objects/CollidableFigureObejct';

const NUM_VERTICES_OF_CIRCLE = 128;
const TILE_LAYER_NAME_SHAPE = 'shape';

/**
 * シェイプを扱う描画キット
 */
class ShapeObjectRenderKit extends AbstractObjectRenderKit {
  private readonly shapeLayer: ShapeLayer;
  private readonly camera: Camera;

  /** ポリゴンの内側の描画物を管理 */
  private readonly polygonMap: Map<Figure, PolygonObject>;

  /** ポリゴンの縁線の描画物を管理 */
  private readonly polygonEdgeMap: Map<Figure, PolylineObject>;

  /** ポリラインの描画物を管理 */
  private readonly polylineMap: Map<Figure, PolylineObject>;

  private notifyUpdate?: LayerUpdateNotifierFunc;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param shapeLayer レイヤー
   * @param renderKitCtl MapRenderKitController
   * @param camera カメラ
   */
  constructor(context: GaiaContext, shapeLayer: ShapeLayer, renderKitCtl: MapRenderKitController, camera: Camera) {
    super(context, shapeLayer, renderKitCtl);
    this.shapeLayer = shapeLayer;
    this.camera = camera;

    this.polygonMap = new Map();
    this.polygonEdgeMap = new Map();
    this.polylineMap = new Map();
  }

  /**
   * 標高モードの状態を設定
   * @param altitudeMode 標高モードの状態
   * @returns {void}
   */
  setAltitudeMode(altitudeMode: boolean): void {
    this.shapeLayer.setAltitudeMode(altitudeMode);
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return TILE_LAYER_NAME_SHAPE;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.shapeLayer.update(mapStatus);
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * ポリゴンを追加する
   * @param polygon ポリゴン
   * @returns {void}
   */
  addPolygon(polygon: Polygon): void {
    if (polygon.paths.length <= 0) {
      return;
    }

    if (this.polygonMap.has(polygon)) {
      return;
    }

    const polygonObject = this.createPolygonObject(
      polygon.paths,
      polygon.fillColor,
      polygon.isVisible(),
      polygon.zIndex
    );
    this.addPolygonObject(polygon, polygonObject);

    if (polygon.strokeWeight > 0 && !polygon.strokeColor.isClear()) {
      const path = polygon.paths.slice();
      path.push(path[0].clone());
      const zIndex = polygon.zIndex;
      const polygonEdgeObject = this.createPolylineObject(
        path,
        polygon.strokeWeight,
        polygon.strokeColor,
        polygon.isVisible(),
        zIndex,
        polygon.strokeDashArray
      );
      this.addPolylineObject(polygon, polygonEdgeObject);
    }
  }

  /**
   * PolygonObjectを追加
   * @param figure Figure
   * @param polygonObject PolygonObject
   * @returns {void}
   */
  private addPolygonObject(figure: Figure, polygonObject: PolygonObject): void {
    this.polygonMap.set(figure, polygonObject);
    this.shapeLayer.addFigure(polygonObject);
    figure.setOnVisibleUpdateListener((f: Figure) => {
      const polygonObject = this.polygonMap.get(f);
      if (polygonObject) {
        polygonObject.setVisible(f.isVisible());
      }
      const polygonEdgeObject = this.polygonEdgeMap.get(f);
      if (polygonEdgeObject) {
        polygonEdgeObject.setVisible(f.isVisible());
      }
      this.notifyUpdate?.();
    });
  }

  /**
   * PolylineObjectを追加
   * @param figure Figure
   * @param polylineObject PolylineObject
   *  @returns {void}
   */
  private addPolylineObject(figure: Figure, polylineObject: PolylineObject): void {
    this.polygonEdgeMap.set(figure, polylineObject);
    this.shapeLayer.addFigure(polylineObject);
  }

  /**
   * ポリゴンを削除する
   * @param polygon ポリゴン
   * @returns {void}
   */
  removePolygon(polygon: Polygon): void {
    const polygonObject = this.polygonMap.get(polygon);
    if (!polygonObject) {
      return;
    }

    this.polygonMap.delete(polygon);
    this.shapeLayer.removeFigure(polygonObject);

    const polygonEdgeObject = this.polygonEdgeMap.get(polygon);
    if (polygonEdgeObject) {
      this.polygonEdgeMap.delete(polygon);
      this.shapeLayer.removeFigure(polygonEdgeObject);
    }
  }

  /**
   * ポリラインを追加する
   * @param polyline ポリライン
   * @returns {void}
   */
  addPolyline(polyline: Polyline): void {
    if (this.polylineMap.has(polyline)) {
      return;
    }

    polyline.setOnVisibleUpdateListener((f: Figure) => {
      const polylineObject = this.polylineMap.get(f);
      if (polylineObject) {
        polylineObject.setVisible(f.isVisible());
      }
      this.notifyUpdate?.();
    });

    const polylineObject = this.createPolylineObject(
      polyline.path,
      polyline.strokeWeight,
      polyline.strokeColor,
      polyline.isVisible(),
      polyline.zIndex,
      polyline.strokeDashArray
    );
    this.polylineMap.set(polyline, polylineObject);
    const collidableFigure = this.shapeLayer.addFigure(polylineObject);

    // イベントリスナー設定
    if (Object.keys(polyline.listeners).length > 0) {
      for (const [eventName, listener] of Object.entries(polyline.listeners)) {
        collidableFigure?.addEventListener(eventName as keyof PolylineEventMap, (ev: CollidableFigureEvent): void => {
          const figure = this.getPolylineByPolylineObject(ev.sourceObject);
          if (figure) {
            listener({
              sourceObject: figure,
              position: ev.position,
            });
          }
        });
      }

      collidableFigure?.setCollidable(true);
    }

    let additionalEvent: (ev: CollidableFigureEvent) => void;
    polyline.setOnEventUpdatedListener(
      (eventName, func) => {
        additionalEvent = (ev): void => {
          const figure = this.getPolylineByPolylineObject(ev.sourceObject);
          if (figure) {
            func({
              sourceObject: figure,
              position: ev.position,
            });
          }
        };

        collidableFigure?.addEventListener(eventName, additionalEvent);
        collidableFigure?.setCollidable(true);
      },
      (eventName) => {
        collidableFigure?.removeEventListener(eventName, additionalEvent);

        if (Object.keys(polyline.listeners).length === 0) {
          collidableFigure?.setCollidable(false);
        }
      }
    );
  }

  /**
   * PolylineObjectから対応するPolylineを取得する
   * @param polylineObject PolylineObject
   * @returns 対応するPolyline
   */
  private getPolylineByPolylineObject(polylineObject: PolylineObject): Optional<Polyline> {
    for (const [figure, polylineObj] of this.polylineMap.entries()) {
      if (polylineObj === polylineObject && figure instanceof Polyline) {
        return figure;
      }
    }
  }

  /**
   * ポリラインを削除する
   * @param polyline ポリライン
   * @returns {void}
   */
  removePolyline(polyline: Polyline): void {
    const polylineObject = this.polylineMap.get(polyline);
    if (!polylineObject) {
      return;
    }

    this.polylineMap.delete(polyline);
    this.shapeLayer.removeFigure(polylineObject);
  }

  /**
   * 円を追加
   * @param circle 円
   * @returns {void}
   */
  addCircle(circle: Circle): void {
    if (!circle.isVisible() || circle.radius <= 0) {
      return;
    }

    const meterToUnit = this.calcMeterToUnit(this.context.getMapStatus(), this.camera);
    const vertices = this.createCircleVertices(circle.radius, meterToUnit);

    if (!circle.fillColor.isClear()) {
      const circlePolygonObject = this.createCirclePolygonObject(circle, vertices);
      this.addPolygonObject(circle, circlePolygonObject);
    }

    if (!circle.strokeColor.isClear() && circle.strokeWeight > 0) {
      const circlePolylineObject = this.createCirclePolylineObject(circle, vertices);
      this.addPolylineObject(circle, circlePolylineObject);
    }
  }

  /**
   * 円を削除
   * @param circle 円
   * @returns {void}
   */
  removeCircle(circle: Circle): void {
    const circlePolygon = this.polygonMap.get(circle);
    if (circlePolygon) {
      this.polygonMap.delete(circle);
      this.shapeLayer.removeFigure(circlePolygon);
    }

    const circlePolyline = this.polygonEdgeMap.get(circle);
    if (circlePolyline) {
      this.polygonEdgeMap.delete(circle);
      this.shapeLayer.removeFigure(circlePolyline);
    }
  }

  /**
   * 外部から受け取ったPolygonをPolygonObjectに変換する
   * @param path 形状
   * @param color 色
   * @param visible 表示状態
   * @param zIndex 重なり順
   * @returns ポリゴンオブジェクト
   */
  private createPolygonObject(path: LatLng[], color: Color, visible: boolean, zIndex: number): PolygonObject {
    const basePosition = calculateWorldCoordinate(path[0]);

    const rotation = Quaternion.identity();
    const scale = Vector3.one();
    const vertices: number[] = [];
    for (const latLng of path) {
      const position = calculateWorldCoordinate(latLng)._subtract(basePosition);
      vertices.push(position.x, position.y, position.z);
    }

    const indices = earcut(vertices, [], 3);
    const geometry = new CustomGeometry(vertices, indices);
    const material = new SingleColorMaterial(this.context.getGLContext(), geometry, color);
    const polygonObject = new PolygonObject(basePosition, rotation, scale, material, basePosition, visible, zIndex);
    return polygonObject;
  }

  /**
   * ポリラインオブジェクトを作成する
   * @param path 形状
   * @param thickness 太さ（ピクセル）
   * @param color 色
   * @param visible 表示フラグ
   * @param zIndex 重なり順
   * @param dashArray 点線のパターン
   * @returns ポリラインオブジェクト
   */
  private createPolylineObject(
    path: LatLng[],
    thickness: number,
    color: Color,
    visible: boolean,
    zIndex: number,
    dashArray: number[]
  ): PolylineObject {
    const basePosition = calculateWorldCoordinate(path[0]);

    const rotation = Quaternion.identity();
    const scale = Vector3.one();
    const positionPath: Vector3[] = [];
    for (const latLng of path) {
      const position = calculateWorldCoordinate(latLng)._subtract(basePosition);
      positionPath.push(position);
    }

    const geometry = new CustomGeometry([], []);
    const material = new SingleColorMaterial(this.context.getGLContext(), geometry, color);
    const polylineObject = new PolylineObject(
      basePosition,
      rotation,
      scale,
      material,
      basePosition,
      positionPath,
      thickness,
      visible,
      zIndex,
      dashArray
    );
    return polylineObject;
  }

  /**
   * 円の内側の描画物を作成する
   * @param circle 円
   * @param vertices 形状
   * @returns 円の内側の描画物
   */
  private createCirclePolygonObject(circle: Circle, vertices: number[]): PolygonObject {
    const position = calculateWorldCoordinate(circle.center);
    const rotation = Quaternion.identity();
    const scale = Vector3.one();

    const indices = earcut(vertices, [], 3);

    const geometry = new CustomGeometry(vertices, indices);
    const material = new SingleColorMaterial(this.context.getGLContext(), geometry, circle.fillColor);
    return new PolygonObject(position, rotation, scale, material, position, circle.isVisible(), circle.zIndex);
  }

  /**
   * 円の縁線の描画物を作成する
   * @param circle 円
   * @param vertices 点列（太さをもたない）
   * @returns 円の縁線の描画物
   */
  private createCirclePolylineObject(circle: Circle, vertices: number[]): PolylineObject {
    const position = calculateWorldCoordinate(circle.center);
    const rotation = Quaternion.identity();
    const scale = Vector3.one();

    const positionPath: Vector3[] = [];
    const verticesLength = vertices.length;
    for (let index = 0; index < verticesLength; index += 3) {
      const position = new Vector3(vertices[index], vertices[index + 1], vertices[index + 2]);
      positionPath.push(position);
    }
    const firstPosition = new Vector3(vertices[0], vertices[1], vertices[2]);
    positionPath.push(firstPosition);

    const geometry = new CustomGeometry([], []);
    const material = new SingleColorMaterial(this.context.getGLContext(), geometry, circle.strokeColor);
    return new PolylineObject(
      position,
      rotation,
      scale,
      material,
      position,
      positionPath,
      circle.strokeWeight,
      circle.isVisible(),
      circle.zIndex,
      circle.strokeDashArray
    );
  }

  /**
   * 円の形状を計算する
   * @param radius 半径（メートル）
   * @param meterToUnit メートルをワールド空間の長さに変換する変数
   * @returns 円の形状
   */
  private createCircleVertices(radius: number, meterToUnit: number): number[] {
    const vertices: number[] = [];
    const radiusUnit = radius * meterToUnit;

    const numVertices = NUM_VERTICES_OF_CIRCLE;
    for (let i = 0; i < numVertices; i++) {
      const radian = (i / numVertices) * 2 * Math.PI;
      const x = Math.cos(radian) * radiusUnit;
      const y = Math.sin(radian) * radiusUnit;
      vertices.push(x, y, 0);
    }

    return vertices;
  }

  /**
   * meterToUnitを計算
   * @param status MapStatus
   * @param camera Camera
   * @returns MeterToUnit
   */
  private calcMeterToUnit(status: MapStatus, camera: Camera): number {
    const {centerLocation, zoomLevel} = status;
    const upVector: Vector3 = status.polar.toUpVector3();
    const rightVector: Vector3 = status.polar.toRightVector3();
    const centerWorld: Vector3 = calculateWorldCoordinate(centerLocation);

    const rightWP: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      status,
      camera as PerspectiveCamera,
      new Vector2(1, 0),
      upVector,
      rightVector
    )._add(centerWorld);
    const right = worldToLatLng(rightWP, zoomLevel);

    const distance = calcDistance(centerLocation, right);
    const worldDistance = centerWorld._subtract(rightWP).magnitude();
    return worldDistance / distance;
  }

  /** @override */
  onDestroy(): void {
    this.shapeLayer.destroy();
    for (const polygon of this.polygonMap.values()) {
      polygon.destroy();
    }
    for (const polyline of this.polylineMap.values()) {
      polyline.destroy();
    }
    for (const edge of this.polygonEdgeMap.values()) {
      edge.destroy();
    }
    this.notifyUpdate = undefined;
  }
}

export {ShapeObjectRenderKit, TILE_LAYER_NAME_SHAPE};
