import {AbstractTileLoader, OnFinishRequestFunc} from './AbstractTileLoader';
import {TileParameter} from '../models/TileParameter';
import {GaiaContext} from '../GaiaContext';

/**
 * 航空衛星写真タイルローダー
 */
class SatelliteLoader extends AbstractTileLoader<TileParameter> {
  private serverUrl = '';

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );

    context
      .getGaIAConfiguration()
      .then((res) => {
        this.serverUrl = `${res.server.satellite}mars/tile/v1`;
        this.setState('ready');
        super.executeRequest();
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * タイルのURLを生成
   * @param tile TileNumber
   * @returns リクエストURL
   */
  private createUrl(tile: TileParameter): string {
    const {x, y, z, tileType, tileSize} = tile;
    return `${this.serverUrl}/${tileType}/${tileSize}/${z}/${y}/${x}`;
  }
}

export {SatelliteLoader};
