import {mat4} from 'gl-matrix';
import {Vector2} from '../../common/math/Vector2';
import {FramebufferRenderTarget, Optional, RenderTarget} from '../../common/types';
import {getDevicePixelRatio} from '../../common/util/Device';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {Object3D} from '../object/Object3D';
import {Program} from './Program';

const FRAME_BUFFER_SIDE = 2048;

const vertexShaderSource = `
attribute vec3 vertexPosition;
attribute vec2 texCoord;

uniform mat4 model;
uniform mat4 view;
uniform mat4 projection;

varying vec3 position;
varying vec2 textureCoord;

void main() {
  position = vertexPosition;
  textureCoord = texCoord;
  gl_Position = projection * view * model * vec4(vertexPosition.xyz, 1.0);
}
`;

const fragmentShaderSource = `
precision mediump float;

uniform sampler2D tex;
uniform vec2 uvOffset;
uniform float zoomRemainder;

varying vec3 position;
varying vec2 textureCoord;

void main() {
  float ratio = 1.0 / zoomRemainder;
  float margin = (1.0 - ratio) / 2.0;

  vec2 uv = (textureCoord - vec2(margin)) * zoomRemainder + uvOffset;
  float u = uv.x;
  float v = uv.y;

  bool outOfRange = false;
  if (u <= 0.0) {
    u = 0.0;
    outOfRange = true;
  }
  if (u >= 1.0) {
    u = 1.0;
    outOfRange = true;
  }
  if (v <= 0.0) {
    v = 0.0;
    outOfRange = true;
  }
  if (v >= 1.0) {
    v = 1.0;
    outOfRange = true;
  }

  gl_FragColor = outOfRange ? vec4(0.0, 0.0, 0.0, 0.0) : texture2D(tex, vec2(u, v));
}
`;

let VERTEX_SHADER: Optional<WebGLShader>;
let FRAGMENT_SHADER: Optional<WebGLShader>;
let PROGRAM: Optional<WebGLShader>;

/**
 * 標高の凹凸を描画するプログラム
 */
class AltitudeProgram implements Program {
  context: WebGLRenderingContext;

  geometry: CustomGeometry;

  vertexShader: Optional<WebGLShader>;
  fragmentShader: Optional<WebGLShader>;
  program: Optional<WebGLShader>;

  image: Optional<TexImageSource>;
  private texture: WebGLTexture | null;
  private static initialTexture: Optional<TexImageSource> = null;
  private uvOffset: Vector2;
  private zoomRemainder: number;

  private modelLocation: Optional<WebGLUniformLocation>;
  private viewLocation: Optional<WebGLUniformLocation>;
  private projectionLocation: Optional<WebGLUniformLocation>;
  private uvOffsetLocation: Optional<WebGLUniformLocation>;
  private zoomRemainderLocation: Optional<WebGLUniformLocation>;

  private modelMatrix: mat4;
  private translationMatrix: mat4;
  private rotationMatrix: mat4;
  private scaleMatrix: mat4;

  private vertexBuffer: Optional<WebGLBuffer>;
  private indexBuffer: Optional<WebGLBuffer>;

  private typedVertices: Float32Array;
  private typedIndices: Uint16Array;

  public static frameBuffer: Optional<WebGLFramebuffer> = undefined;
  public static targetTexture: Optional<WebGLTexture> = undefined;
  public static renderTarget: Optional<FramebufferRenderTarget> = undefined;

  /**
   * バッファをクリアする
   * @param gl WebGLRenderingContext
   * @returns {void}
   */
  static clearBuffer(gl: WebGLRenderingContext): void {
    if (!AltitudeProgram.renderTarget) {
      return;
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, AltitudeProgram.renderTarget.target);
    gl.clearColor(1.0, 1.0, 1.0, 0.0);
    gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   */
  constructor(context: WebGLRenderingContext, geometry: CustomGeometry) {
    this.context = context;
    this.geometry = geometry;

    this.vertexShader = null;
    this.fragmentShader = null;
    this.program = null;

    this.texture = context.createTexture();
    this.uvOffset = Vector2.zero();
    this.zoomRemainder = 1.0;

    this.modelMatrix = mat4.create();
    this.translationMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.scaleMatrix = mat4.create();

    this.typedVertices = new Float32Array();
    this.typedIndices = new Uint16Array();

    this.setupProgram();
    this.initializeFrameBuffer();
    this.setGeometry(geometry);
    this.setupGeometry();
  }

  /**
   * シェーダをコンパイルしてプログラムを準備する
   * @returns {void}
   */
  setupProgram(): void {
    if (!VERTEX_SHADER) {
      const mayBeVertexShader: Optional<WebGLShader> = this.context.createShader(this.context.VERTEX_SHADER);
      if (mayBeVertexShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] SingleColorProgram.setupProgram() could not create vertex shader');
        return;
      }
      VERTEX_SHADER = mayBeVertexShader;
      this.context.shaderSource(VERTEX_SHADER, vertexShaderSource);
      this.context.compileShader(VERTEX_SHADER);

      const vertexShaderCompileStatus = this.context.getShaderParameter(VERTEX_SHADER, this.context.COMPILE_STATUS);
      if (!vertexShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(VERTEX_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
        return;
      }
    }
    this.vertexShader = VERTEX_SHADER;

    if (!FRAGMENT_SHADER) {
      const mayBeFragmentShader: Optional<WebGLShader> = this.context.createShader(this.context.FRAGMENT_SHADER);
      if (mayBeFragmentShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] SingleColorProgram.setupProgram() could not create fragment shader');
        return;
      }
      FRAGMENT_SHADER = mayBeFragmentShader;
      this.context.shaderSource(FRAGMENT_SHADER, fragmentShaderSource);
      this.context.compileShader(FRAGMENT_SHADER);

      const fragmentShaderCompileStatus = this.context.getShaderParameter(FRAGMENT_SHADER, this.context.COMPILE_STATUS);
      if (!fragmentShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(FRAGMENT_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.fragmentShader = FRAGMENT_SHADER;

    if (!PROGRAM) {
      const mayBeProgram = this.context.createProgram();
      if (mayBeProgram === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] SingleColorProgram.setupProgram() could not create program');
        return;
      }
      PROGRAM = mayBeProgram;
      this.context.attachShader(PROGRAM, this.vertexShader);
      this.context.attachShader(PROGRAM, this.fragmentShader);
      this.context.linkProgram(PROGRAM);

      const linkStatus = this.context.getProgramParameter(PROGRAM, this.context.LINK_STATUS);
      if (!linkStatus) {
        const info = this.context.getProgramInfoLog(PROGRAM);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.program = PROGRAM;

    this.context.useProgram(this.program);
  }

  /**
   * フレームバッファを初期化
   * @returns {void}
   */
  private initializeFrameBuffer(): void {
    const gl = this.context;
    if (!AltitudeProgram.frameBuffer || !AltitudeProgram.targetTexture) {
      const framebuffer = gl.createFramebuffer();
      if (!framebuffer) {
        return;
      }
      AltitudeProgram.frameBuffer = framebuffer;
      gl.bindFramebuffer(gl.FRAMEBUFFER, AltitudeProgram.frameBuffer);
      gl.clearColor(0.0, 0.0, 0.0, 1.0);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      AltitudeProgram.targetTexture = gl.createTexture();
      gl.bindTexture(gl.TEXTURE_2D, AltitudeProgram.targetTexture);
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        FRAME_BUFFER_SIDE,
        FRAME_BUFFER_SIDE,
        0,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        null
      );
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, AltitudeProgram.targetTexture, 0);
      gl.bindTexture(gl.TEXTURE_2D, null);
      gl.bindRenderbuffer(gl.RENDERBUFFER, null);
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);

      AltitudeProgram.renderTarget = {
        type: 'framebuffer',
        size: {
          width: FRAME_BUFFER_SIDE,
          height: FRAME_BUFFER_SIDE,
        },
        target: framebuffer,
      };
    }
  }

  /**
   * ジオメトリを読み込む
   * @returns {void}
   */
  setupGeometry(): void {
    if (!this.program) {
      return;
    }

    const gl = this.context;
    gl.useProgram(this.program);

    if (!this.vertexBuffer) {
      this.vertexBuffer = gl.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = gl.createBuffer();
    }

    const vertexAttribLocation = gl.getAttribLocation(this.program, 'vertexPosition');
    const textureAttribLocation = gl.getAttribLocation(this.program, 'texCoord');

    const VERTEX_SIZE = 3;
    const TEXTURE_SIZE = 2;
    const STRIDE = (VERTEX_SIZE + TEXTURE_SIZE) * Float32Array.BYTES_PER_ELEMENT;
    const VERTEX_OFFSET = 0;
    const TEXTURE_OFFSET = 3 * Float32Array.BYTES_PER_ELEMENT;

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(vertexAttribLocation);
    gl.enableVertexAttribArray(textureAttribLocation);
    gl.vertexAttribPointer(vertexAttribLocation, VERTEX_SIZE, gl.FLOAT, false, STRIDE, VERTEX_OFFSET);
    gl.vertexAttribPointer(textureAttribLocation, TEXTURE_SIZE, gl.FLOAT, false, STRIDE, TEXTURE_OFFSET);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: CustomGeometry): void {
    this.geometry = geometry;

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertices = this.geometry.getVertices();
    const verticesLength = vertices.length;
    if (this.typedVertices.length < verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    const indices = this.geometry.getIndices();
    const indicesLength = indices.length;
    if (this.typedIndices.length < indicesLength) {
      this.typedIndices = new Uint16Array(indicesLength);
    }
    this.typedIndices.set(indices);

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);
    this.context.bufferData(this.context.ARRAY_BUFFER, this.typedVertices, this.context.STATIC_DRAW);

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    this.context.bufferData(this.context.ELEMENT_ARRAY_BUFFER, this.typedIndices, this.context.STATIC_DRAW);
  }

  /**
   * テクスチャを初期化する
   * @returns {void}
   */
  initTexture(): void {
    if (!AltitudeProgram.initialTexture) {
      const tileSize = 2;
      const canvas = document.createElement('canvas');
      canvas.width = tileSize;
      canvas.height = tileSize;
      const context = canvas.getContext('2d');
      if (!context) {
        return;
      }
      context.fillStyle = '#00000000';
      context.fillRect(0, 0, tileSize, tileSize);

      AltitudeProgram.initialTexture = canvas;
    }
    this.setTexture(AltitudeProgram.initialTexture);
  }

  /**
   * テクスチャを更新する
   * @param image テクスチャ画像ソース
   * @returns {void}
   */
  setTexture(image: TexImageSource): void {
    if (!this.program) {
      return;
    }

    if (this.image === image) {
      return;
    }

    this.image = image;

    this.context.useProgram(this.program);

    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
    this.context.texImage2D(
      this.context.TEXTURE_2D,
      0,
      this.context.RGBA,
      this.context.RGBA,
      this.context.UNSIGNED_BYTE,
      image
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_S,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_T,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MAG_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MIN_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.bindTexture(this.context.TEXTURE_2D, null);
  }

  /**
   * テクスチャを設定
   * @param texture テクスチャ
   * @returns {void}
   */
  setGLTexture(texture: WebGLTexture): void {
    if (!this.program) {
      return;
    }

    this.texture = texture;
    this.context.useProgram(this.program);

    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_S,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_T,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MAG_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MIN_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.bindTexture(this.context.TEXTURE_2D, null);
  }

  /**
   * uv座標のオフセットを設定
   * @param uvOffset uv座標のオフセット
   * @returns {void}
   */
  setUvOffset(uvOffset: Vector2): void {
    this.uvOffset.setValues(uvOffset.x, uvOffset.y);
  }

  /**
   * ズームレベルの小数部に1を足したものを設定する
   * @param zoomRemainder ズームレベルの小数部に1を足した値
   * @returns {void}
   */
  setZoomRemainder(zoomRemainder: number): void {
    this.zoomRemainder = zoomRemainder;
  }

  /**
   * テクスチャ更新
   * @returns {void}
   */
  updateTexture(): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
  }

  /**
   * 現在のカメラの状況に合わせて行列を更新する
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    if (!this.program) {
      return;
    }

    // model
    mat4.identity(this.scaleMatrix);
    mat4.identity(this.rotationMatrix);
    mat4.identity(this.translationMatrix);
    mat4.identity(this.modelMatrix);

    // Object3Dの親子関係を実装するならここを修正
    mat4.scale(this.scaleMatrix, this.scaleMatrix, object3D.scale.toFloat32Array());
    mat4.multiply(this.rotationMatrix, this.rotationMatrix, object3D.rotation.toMat4());
    const localTranslation = mat4.create();
    mat4.translate(localTranslation, localTranslation, object3D.position.toFloat32Array());
    mat4.multiply(this.translationMatrix, this.translationMatrix, localTranslation);

    mat4.multiply(this.modelMatrix, this.modelMatrix, this.translationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.rotationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.scaleMatrix);

    this.context.useProgram(this.program);
    if (!this.modelLocation) {
      this.modelLocation = this.context.getUniformLocation(this.program, 'model');
    }
    if (!this.viewLocation) {
      this.viewLocation = this.context.getUniformLocation(this.program, 'view');
    }
    if (!this.projectionLocation) {
      this.projectionLocation = this.context.getUniformLocation(this.program, 'projection');
    }
    if (!this.uvOffsetLocation) {
      this.uvOffsetLocation = this.context.getUniformLocation(this.program, 'uvOffset');
    }
    if (!this.zoomRemainderLocation) {
      this.zoomRemainderLocation = this.context.getUniformLocation(this.program, 'zoomRemainder');
    }
    this.context.uniformMatrix4fv(this.modelLocation, false, this.modelMatrix);
    this.context.uniformMatrix4fv(this.viewLocation, false, viewMatrix);
    this.context.uniformMatrix4fv(this.projectionLocation, false, projectionMatrix);
    this.context.uniform2f(this.uvOffsetLocation, this.uvOffset.x, this.uvOffset.y);
    this.context.uniform1f(this.zoomRemainderLocation, this.zoomRemainder);
  }

  /**
   * フレームバッファをリセットする
   * @returns {void}
   */
  clearFrameBuffer(): void {
    if (!AltitudeProgram.frameBuffer) {
      return;
    }

    const gl = this.context;

    gl.bindFramebuffer(gl.FRAMEBUFFER, AltitudeProgram.frameBuffer);

    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  /**
   * 描画する
   * @param renderTarget 描画対象の状態
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    const gl = this.context;

    if (!renderTarget || renderTarget.type !== 'default') {
      return;
    }

    this.setupGeometry();
    this.updateTexture();

    gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE);
    gl.enable(gl.BLEND);

    const dpr = getDevicePixelRatio();
    gl.viewport(0, 0, dpr * renderTarget.size.width, dpr * renderTarget.size.height);

    gl.enable(gl.DEPTH_TEST);
    gl.drawElements(gl.TRIANGLES, this.geometry.getIndices().length, gl.UNSIGNED_SHORT, 0);
    gl.disable(gl.DEPTH_TEST);

    gl.disable(gl.BLEND);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const gl = this.context;
    if (PROGRAM && VERTEX_SHADER && FRAGMENT_SHADER) {
      gl.detachShader(PROGRAM, VERTEX_SHADER);
      gl.detachShader(PROGRAM, FRAGMENT_SHADER);
      gl.deleteProgram(PROGRAM);
      PROGRAM = undefined;
    }
    if (VERTEX_SHADER) {
      gl.deleteShader(VERTEX_SHADER);
      VERTEX_SHADER = undefined;
    }
    if (FRAGMENT_SHADER) {
      gl.deleteShader(FRAGMENT_SHADER);
      FRAGMENT_SHADER = undefined;
    }
    if (this.indexBuffer) {
      gl.deleteBuffer(this.indexBuffer);
      this.indexBuffer = undefined;
    }
    if (this.vertexBuffer) {
      gl.deleteBuffer(this.vertexBuffer);
      this.vertexBuffer = undefined;
    }
    if (AltitudeProgram.frameBuffer) {
      gl.deleteFramebuffer(AltitudeProgram.frameBuffer);
      AltitudeProgram.frameBuffer = undefined;
    }
    this.viewLocation = undefined;
    this.modelLocation = undefined;
    this.projectionLocation = undefined;
  }
}

export {AltitudeProgram, FRAME_BUFFER_SIDE};
