import {ValueObject} from './interface/ValueObject';
import {JsonObject} from '../types';

/**
 * 二次元座標を扱うクラス<br>
 * (画面上の左上からの座標)
 */
class Point implements ValueObject {
  /** X座標 */
  readonly x: number;
  /** Y座標 */
  readonly y: number;

  /**
   * コンストラクタ
   * @param x X座標(横方向)
   * @param y Y座標(縦方向)
   */
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  /** @override */
  clone(): Point {
    return new Point(this.x, this.y);
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof Point)) {
      return false;
    }
    const other = obj as Point;
    return other.x === this.x && other.y === this.y;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      x: this.x,
      y: this.y,
    };
  }
}

export {Point};
