import {RoadShapeOpenedAppearance} from 'gaia/value';
import {RoadShapeOpenedFeature} from '../../../common/infra/response/RoadShapeOpenedInfo';

/**
 * 新規開通道路描画用データ
 */
class RoadShapeOpenedData {
  readonly feature: RoadShapeOpenedFeature;
  readonly appearance: RoadShapeOpenedAppearance;

  /**
   * コンストラクタ
   * @param feature 形状情報
   * @param appearance 描画スタイル
   */
  constructor(feature: RoadShapeOpenedFeature, appearance: RoadShapeOpenedAppearance) {
    this.feature = feature;
    this.appearance = appearance;
  }
}

export {RoadShapeOpenedData};
