/**
 * 地図スクリプトで発生した例外情報のクラス
 */
class GaIAError extends Error {
  /**
   * コンストラクタ
   * @param message 例外メッセージ
   */
  constructor(message = 'Unexpected Error occurred!') {
    super(`[GaIA Error] ${message}`);
  }
}

export {GaIAError};
