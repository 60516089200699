import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {GaiaContext} from '../../../GaiaContext';
import {Layer} from '../../../../engine/layer/Layer';
import {MapStatus} from '../../../models/MapStatus';
import {Ray3} from '../../../../common/math/Ray3';
import {Collision} from '../../../../engine/collision/Collision';

/**
 * タイル以外の描画処理のベースクラス
 * TODO: 移せる処理は随時こっちに移す
 */
abstract class AbstractObjectRenderKit {
  /** Context */
  protected readonly context: GaiaContext;

  protected readonly layer: Layer;

  protected readonly getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  /**
   * コンストラクタ
   * @param context Context
   * @param layer Layer
   * @param renderKitCtl MapRenderKitController
   */
  constructor(context: GaiaContext, layer: Layer, renderKitCtl: MapRenderKitController) {
    this.context = context;
    this.layer = layer;
    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKitCtl.getAllCollisions(ray);
  }

  abstract get identicalName(): keyof ObjectRenderKitMapping;

  abstract updateDrawObjects(mapStatus: MapStatus): void;

  abstract onDestroy(): void;

  /**
   * レイヤーを取得
   * @returns レイヤー
   */
  getLayer(): Layer {
    return this.layer;
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.layer.destroy();
    this.onDestroy();
  }
}

export {AbstractObjectRenderKit};
