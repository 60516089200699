import {Color} from '.';
import {
  JsonObject,
  CoordUnit,
  GeoJsonFigureConditionInitOptions,
  GeoJsonFigureEventMap,
  GeoJsonFigureEvent,
} from '../types';
import {DASH_ARRAY_SOLID} from '../object/shape/Polyline';
import {GaIAEventEmitter} from './interface/GaIAEventEmitter';

/** デフォルトの色 */
const DEFAULT_GEOJSON_COLOR = Color.green();
/** デフォルトの線幅 */
const DEFAULT_GEOJSON_LINE_WEIGHT = 5;
/** デフォルトの縁線色 */
const DEFAULT_GEOJSON_OUTLINE_COLOR = new Color(0, 0.5, 0, 1);
/** デフォルトの縁線幅 */
const DEFAULT_GEOJSON_OUTLINE_WEIGHT = 10;

/** 線のオプション */
type GeoJsonLineOption = {
  /** 色 */
  color: Color;
  /** 太さ */
  weight: number;
  /** 点線のパターン */
  dashArray: number[];
};

/**
 * GeoJSON形状コンディション
 */
class GeoJsonFigureCondition implements GaIAEventEmitter {
  /** GeoJson */
  readonly geoJson: JsonObject;
  /** ポリゴンのオプション */
  readonly polygonOption: {color: Color};
  /** ポリライン内線のオプション */
  readonly inlineOption: GeoJsonLineOption;
  /** 縁線のオプション */
  readonly outlineOption: GeoJsonLineOption;
  /** /route/shapeから取得したGeoJsonか */
  readonly isRouteShape: boolean;
  /** ルート線描画時の矢印を表示するかどうか */
  readonly showRouteArrow: boolean;
  /** 重なり順 */
  readonly zIndex?: number;
  /** 緯度経度の単位 */
  readonly coordUnit: CoordUnit;

  readonly listeners: {[key: string]: (ev: GeoJsonFigureEvent) => void};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onAddEventListener?: (eventName: keyof GeoJsonFigureEventMap, func: (ev: GeoJsonFigureEvent) => any) => void;
  private onRemoveEventListener?: (
    eventName: keyof GeoJsonFigureEventMap,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GeoJsonFigureEvent) => any
  ) => void;

  /**
   * コンストラクタ
   * @param geoJson GeoJSON
   * @param options 形状オプション
   */
  constructor(geoJson: JsonObject, options?: GeoJsonFigureConditionInitOptions) {
    this.geoJson = geoJson;
    this.polygonOption = {
      color: options?.polygon?.color ?? DEFAULT_GEOJSON_COLOR,
    };
    this.inlineOption = {
      color: options?.polyline?.inline?.color ?? DEFAULT_GEOJSON_COLOR,
      weight: options?.polyline?.inline?.weight ?? DEFAULT_GEOJSON_LINE_WEIGHT,
      dashArray: options?.polyline?.inline?.dashArray ?? DASH_ARRAY_SOLID,
    };
    this.outlineOption = {
      color: options?.polyline?.outline?.color ?? DEFAULT_GEOJSON_OUTLINE_COLOR,
      weight: options?.polyline?.outline?.weight ?? DEFAULT_GEOJSON_OUTLINE_WEIGHT,
      dashArray: options?.polyline?.outline?.dashArray ?? DASH_ARRAY_SOLID,
    };
    this.isRouteShape = options?.isRouteShape ?? false;
    this.showRouteArrow = options?.showRouteArrow ?? false;
    this.zIndex = options?.zIndex;
    this.coordUnit = options?.coordUnit ?? 'degree';

    this.listeners = {};
  }

  /**
   * イベント追加・削除時のリスナーを設定
   * @ignore
   * @param add イベント追加リスナー
   * @param remove イベント削除リスナー
   * @returns {void}
   */
  setOnEventUpdatedListener(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    add?: (eventName: keyof GeoJsonFigureEventMap, func: (ev: GeoJsonFigureEvent) => any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    remove?: (eventName: keyof GeoJsonFigureEventMap, func: (ev: GeoJsonFigureEvent) => any) => void
  ): void {
    this.onAddEventListener = add;
    this.onRemoveEventListener = remove;
  }

  /**
   * イベントリスナーを設定
   * ※ ポリゴンはイベント発行未対応
   * @param eventName イベント名
   * @param func イベントハンドラ
   * @returns {void}
   */
  addEventListener<K extends keyof GeoJsonFigureEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GeoJsonFigureEventMap[K]) => any
  ): void {
    if (this.onAddEventListener) {
      this.onAddEventListener(eventName, func);
      return;
    }
    this.listeners[eventName] = func;
  }

  /** @override */
  removeEventListener<K extends keyof GeoJsonFigureEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GeoJsonFigureEventMap[K]) => any
  ): void {
    if (this.onRemoveEventListener) {
      this.onRemoveEventListener(eventName, func);
      return;
    }
    delete this.listeners[eventName];
  }
}

export {
  GeoJsonFigureCondition,
  GeoJsonFigureConditionInitOptions,
  DEFAULT_GEOJSON_COLOR,
  DEFAULT_GEOJSON_LINE_WEIGHT,
  DEFAULT_GEOJSON_OUTLINE_COLOR,
  DEFAULT_GEOJSON_OUTLINE_WEIGHT,
};
