import {mat4} from 'gl-matrix';
import {Camera} from '../camera/Camera';
import {Layer} from '../layer/Layer';
import {isBlankPlaneLayer} from '../../map/layer/BlankPlaneLayer';
import {DepthProgram} from '../program/DepthProgram';
import {AltitudeProgram} from '../program/AltitudeProgram';

/**
 * ゲームエンジンの世界クラス
 */
class World {
  private readonly mainCamera: Camera;
  private readonly gl: WebGLRenderingContext;
  private readonly layers: Layer[];

  private drawCount: number;

  /**
   * コンストラクタ
   * @param mainCamera メインカメラ
   * @param gl WebGLRenderingContext
   */
  constructor(mainCamera: Camera, gl: WebGLRenderingContext) {
    this.mainCamera = mainCamera;
    this.gl = gl;
    this.layers = [];
    this.drawCount = 0;
  }

  /**
   * 描画処理
   * @returns `true` : 描画完了, `false` : 描画一部未完了
   */
  draw(): boolean {
    this.mainCamera.update();

    const viewMatrix: mat4 = this.mainCamera.viewMatrix;
    const projectionMatrix: mat4 = this.mainCamera.projectionMatrix;

    const viewMatrixWithNoRotation: mat4 = this.mainCamera.viewMatrixWithNoRotation;
    const projectionMatrixWithNoRotation: mat4 = this.mainCamera.projectionMatrixWithNoRotation;

    DepthProgram.clearBuffer(this.gl);
    AltitudeProgram.clearBuffer(this.gl);

    let updateFinished = true;
    for (const layer of this.layers) {
      const requireNoRotationMatrix = layer.requireNoRotationMatrix();
      const view: mat4 = requireNoRotationMatrix ? viewMatrixWithNoRotation : viewMatrix;
      const projection: mat4 = requireNoRotationMatrix ? projectionMatrixWithNoRotation : projectionMatrix;

      updateFinished = layer.updateLayer(view, projection) && updateFinished;
    }

    this.drawCount++;

    return updateFinished;
  }

  /**
   * レイヤー追加
   * @param newLayer レイヤー
   * @param isBottom 一番下へ配置
   * @returns {void}
   */
  addLayer(newLayer: Layer, isBottom?: boolean): void {
    // TODO: レイヤー追加位置を制御できるようにする

    if (!isBottom) {
      this.layers.push(newLayer);
      return;
    }

    // BlankTileLayerより下には配置しない
    for (let i = 0; i < this.layers.length; i++) {
      if (isBlankPlaneLayer(this.layers[i])) {
        this.layers.splice(i + 1, 0, newLayer);
        return;
      }
    }

    this.layers.unshift(newLayer);
  }

  /**
   * 全てのレイヤーを取得
   * @returns 全てのレイヤー
   */
  getLayers(): Layer[] {
    return this.layers;
  }

  /**
   * 地図インスタンスが作成されてからの描画回数を取得する
   * @returns 描画回数
   */
  getDrawCount(): number {
    return this.drawCount;
  }
}

export {World};
