import {GltfPbrMetallicRoughness} from './GltfPbrMetallicRoughness';

/**
 * GLTFのmaterialの共通インタフェース
 */
class GltfMaterial {
  readonly pbrMetallicRoughness: GltfPbrMetallicRoughness;
  readonly name?: string;

  /**
   * コンストラクタ
   * @param pbrMetallicRoughness 物理マテリアルのパラメータ
   * @param name 名前
   */
  constructor(pbrMetallicRoughness: GltfPbrMetallicRoughness, name?: string) {
    this.pbrMetallicRoughness = pbrMetallicRoughness;
    this.name = name;
  }
}

export {GltfMaterial};
