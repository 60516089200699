import {Color, LatLng} from '../../value';
import {Figure} from './Figure';
import {DASH_ARRAY_SOLID} from './Polyline';
import {PolygonOptions} from '../../types';

/**
 * ポリゴンの表現クラス
 */
class Polygon extends Figure {
  /** ポリゴン形状 */
  public readonly paths: LatLng[];
  /** 塗りつぶし色 */
  public readonly fillColor: Color;
  /** 縁線の色 */
  public readonly strokeColor: Color;
  /** 縁線の太さ */
  public readonly strokeWeight: number;
  /** 点線のパターン */
  public readonly strokeDashArray: number[];

  /**
   * コンストラクタ
   * @param options オプション
   */
  constructor(options: PolygonOptions) {
    super(options);

    this.paths = options.paths;
    this.fillColor = options.fillColor ?? Color.black();
    this.strokeDashArray = options.strokeDashArray ?? DASH_ARRAY_SOLID;

    if (!options.strokeColor && !options.strokeWeight) {
      // 縁線は何も指定がなければ表示しない
      this.strokeColor = Color.clear();
      this.strokeWeight = 0;
    } else {
      // 色と太さのどちらかが指定された場合は、何かが表示されるようにデフォルト値を設定する
      this.strokeColor = options.strokeColor ?? Color.black();
      this.strokeWeight = options.strokeWeight ?? 1;
    }
  }
}

export {Polygon};
