import {AbstractUIEventObserver} from './AbstractUIEventObserver';
import {Point} from '../../../gaia/value/Point';
import {TouchEventMap} from '../../../gaia/types';
import {throttle} from '../../common/util/Culling';
import {Vector2} from '../../common/math/Vector2';
import {getDevicePixelRatio} from '../../common/util/Device';

const PINCH_THRESHOLD = 1;

const FLICK_THRESHOLD = 1;
const INERTIA_SCROLL_DEFAULT_ID = 0;
const ATTENUATION_RATE = 0.95;
const INERTIA_SCROLL_CONTINUE_THRESHOLD = 0.8;

const THROTTLE_DURATION = 10;

// 2本指の操作が始まってから、それが傾き操作であるか確認する時間（フレーム）
const PINCH_WITH_TILT_MODE_CHECK_TIME = 10;

const TILT_MODE_MAX_ABSOLUTE_SLOPE = Math.PI / 6;

const TILT_MODE_MAX_DELTA_SLOPE = Math.PI / 20;

// ロングタップだとみなす時間（ミリ秒）
const DEFAULT_LONG_TAP_MILLISECONDS = 1000;
const DIFF_PIXEL_LONG_TAP_DIFF = 20;

/**
 * タッチイベントの監視と通知
 */
class TouchEventObserver extends AbstractUIEventObserver<TouchEventMap> {
  private touchFingerCount = 0;
  private touchStartTime = 0;

  private lastTouchEndTime = 0;

  private previousTouches: TouchList | undefined = undefined;

  // ピンチ
  private pinchDistance = 0;
  private hasPinchFinished = false;
  private previousPinchSlope: number | null = null;

  // スマホの地図回転に必要なメンバ変数
  private frameCountPinch = 0;
  private isTiltMode = false;
  private pinchFirstSlope: number | null = null;
  private previousCenterY: number | null = null;

  private singleTapTimeoutId = 0;
  private maybeDoubleTap = false;

  // 2本指タップ
  private mayBe2FingerTap = false;

  // 慣性スクロール
  private inertiaScrollDelta = {x: 0, y: 0};
  private inertiaScrollId = INERTIA_SCROLL_DEFAULT_ID;

  // ロングタップ
  private longTapMilliseconds = DEFAULT_LONG_TAP_MILLISECONDS;
  private longTapDetectTimeout: NodeJS.Timeout | undefined = undefined;
  private longTapTotalMove: Vector2 = Vector2.zero();
  private longTapping = false;

  /**
   * コンストラクタ
   * @param element HTMLElement
   */
  constructor(element: HTMLElement) {
    super(element);
  }

  /** @override */
  setupObserve(): void {
    this.baseElement.addEventListener('touchstart', (ev) => this.handleTouchStart(ev), {passive: true});
    this.baseElement.addEventListener('touchmove', throttle(this, this.handleTouchMove, THROTTLE_DURATION));
    this.baseElement.addEventListener('touchend', (ev) => this.handleTouchEnd(ev), {passive: true});
  }

  /**
   * ロングタップとみなす時間（単位はミリ秒）を設定する
   * @param milliseconds ロングタップと見なす時間（ミリ秒）
   * @returns {void}
   */
  setLongTapMilliseconds(milliseconds: number): void {
    this.longTapMilliseconds = milliseconds;
  }

  /**
   * touchstart時の処理
   * @param ev TouchEvent
   * @returns {void}
   */
  private handleTouchStart(ev: TouchEvent): void {
    this.trigger('touchstart', ev);
    if (this.inertiaScrollId !== INERTIA_SCROLL_DEFAULT_ID) {
      this.stopInertiaScroll();
    }
    if (this.singleTapTimeoutId !== 0) {
      window.clearTimeout(this.singleTapTimeoutId);
      this.singleTapTimeoutId = 0;
      this.maybeDoubleTap = true;
    }
    if (this.touchFingerCount === 0) {
      this.touchStartTime = performance.now();
    }

    this.touchFingerCount = ev.touches.length;
    this.previousTouches = ev.touches;

    // タップが始まってから一定時間でロングタップの判定を行う
    this.longTapDetectTimeout = setTimeout(() => {
      const maxDiff = DIFF_PIXEL_LONG_TAP_DIFF * getDevicePixelRatio();
      if (this.longTapTotalMove.x < maxDiff || this.longTapTotalMove.y < maxDiff) {
        this.trigger('longtapdetect', ev);
        this.longTapping = true;
      }
    }, this.longTapMilliseconds);

    if (this.touchFingerCount === 2) {
      this.mayBe2FingerTap = true;
      const {clientX: x1, clientY: y1} = ev.touches[0];
      const {clientX: x2, clientY: y2} = ev.touches[1];

      this.pinchDistance = this.calcDistance(new Point(x1, y1), new Point(x2, y2));
    }
  }

  /**
   * touchmove時の処理
   * @param ev TouchEvent
   * @returns {void}
   */
  private handleTouchMove(ev: TouchEvent): void {
    this.trigger('touchmove', ev);
    ev.preventDefault();

    if (this.touchFingerCount === 1) {
      // 1本指
      this.previousPinchSlope = null;
      this.frameCountPinch = 0;
      this.isTiltMode = false;
      this.previousCenterY = null;

      const deltaX: number[] = [];
      const deltaY: number[] = [];
      if (this.previousTouches) {
        for (let i = 0; i < this.previousTouches.length; i++) {
          const {screenX: previousX, screenY: previousY} = this.previousTouches[i] ?? {screenX: 0, screenY: 0};
          const {screenX: currentX, screenY: currentY} = ev.touches[i] ?? {screenX: 0, screenY: 0};
          deltaX.push(currentX - previousX);
          deltaY.push(currentY - previousY);
        }
      }

      this.inertiaScrollDelta.x = deltaX[0];
      this.inertiaScrollDelta.y = deltaY[0];

      this.longTapTotalMove.add(new Vector2(Math.abs(deltaX[0]), Math.abs(deltaY[0])));

      this.trigger('singlescroll', Object.assign(ev, {deltaX, deltaY}));
    } else if (this.touchFingerCount === 2) {
      // 2本指
      this.mayBe2FingerTap = false;

      const {clientX: x1, clientY: y1} = ev.touches[0];
      const {clientX: x2, clientY: y2} = ev.touches[1];
      let slope: number;
      if (x1 === x2) {
        if (y2 > y1) {
          slope = -Math.PI / 2;
        } else {
          slope = Math.PI / 2;
        }
      } else {
        slope = Math.atan2(y1 - y2, x2 - x1);
      }

      if (this.frameCountPinch === 0) {
        this.pinchFirstSlope = slope;
      }
      if (this.frameCountPinch === PINCH_WITH_TILT_MODE_CHECK_TIME) {
        if (
          this.pinchFirstSlope &&
          (Math.abs(this.pinchFirstSlope) <= TILT_MODE_MAX_ABSOLUTE_SLOPE ||
            Math.PI - Math.abs(this.pinchFirstSlope) <= TILT_MODE_MAX_ABSOLUTE_SLOPE) &&
          (Math.abs(slope) <= TILT_MODE_MAX_ABSOLUTE_SLOPE ||
            Math.PI - Math.abs(slope) <= TILT_MODE_MAX_ABSOLUTE_SLOPE) &&
          Math.abs(this.pinchFirstSlope - slope) < TILT_MODE_MAX_DELTA_SLOPE
        ) {
          this.isTiltMode = true;
        }
      }
      this.frameCountPinch++;

      if (this.isTiltMode) {
        this.previousTouches = ev.touches;
        const centerY = (y1 + y2) / 2;
        if (!this.previousCenterY) {
          this.previousCenterY = centerY;
          return;
        }
        this.trigger('tilt', Object.assign(ev, {deltaTilt: centerY - this.previousCenterY}));
        this.previousCenterY = centerY;
        return;
      }

      const distance = this.calcDistance(new Point(x1, y1), new Point(x2, y2));
      const pinchDelta = distance - this.pinchDistance;

      if (!this.previousPinchSlope) {
        this.previousPinchSlope = slope;
      } else {
        this.trigger('rotation', Object.assign(ev, {deltaRotation: slope - this.previousPinchSlope}));
        this.previousPinchSlope = slope;
      }

      if (pinchDelta > PINCH_THRESHOLD || pinchDelta < -PINCH_THRESHOLD) {
        this.trigger('pinch', Object.assign(ev, {pinchDelta}));
        this.hasPinchFinished = true;
      }
      this.pinchDistance = distance;
    }
    this.previousTouches = ev.touches;
  }

  /**
   * touchend時の処理
   * @param ev TouchEvent
   * @returns {void}
   */
  private handleTouchEnd(ev: TouchEvent): void {
    this.trigger('touchend', ev);
    this.touchFingerCount = ev.touches.length;
    this.previousPinchSlope = null;
    this.frameCountPinch = 0;
    this.isTiltMode = false;
    this.previousCenterY = null;

    if (this.touchFingerCount === 0) {
      const now = performance.now();
      this.lastTouchEndTime = now;
      this.hasPinchFinished = false;

      if (now - this.touchStartTime > this.longTapMilliseconds) {
        if (this.longTapping) {
          this.trigger('longtap', ev);
          this.longTapping = false;
        }
      } else {
        if (this.longTapDetectTimeout) {
          clearTimeout(this.longTapDetectTimeout);
          this.longTapDetectTimeout = undefined;
        }
        if (
          Math.abs(this.inertiaScrollDelta.x) > FLICK_THRESHOLD ||
          Math.abs(this.inertiaScrollDelta.y) > FLICK_THRESHOLD
        ) {
          // eslint-disable-next-line require-jsdoc
          const flickFn = throttle(
            this,
            (): void => {
              this.trigger(
                'flick',
                Object.assign({
                  deltaX: [this.inertiaScrollDelta.x],
                  deltaY: [this.inertiaScrollDelta.y],
                })
              );
              this.inertiaScrollDelta.x *= ATTENUATION_RATE;
              this.inertiaScrollDelta.y *= ATTENUATION_RATE;

              if (
                Math.abs(this.inertiaScrollDelta.x) < INERTIA_SCROLL_CONTINUE_THRESHOLD &&
                Math.abs(this.inertiaScrollDelta.y) < INERTIA_SCROLL_CONTINUE_THRESHOLD
              ) {
                this.stopInertiaScroll();
              } else {
                this.inertiaScrollId = requestAnimationFrame(flickFn);
              }
            },
            THROTTLE_DURATION
          );
          this.inertiaScrollId = requestAnimationFrame(flickFn);
        }

        if (this.mayBe2FingerTap) {
          this.mayBe2FingerTap = false;
          const touchEvent1 = this.previousTouches?.[0];
          const touchEvent2 = this.previousTouches?.[1];
          if (touchEvent1 && touchEvent2) {
            const {top, left} = this.baseElement.getBoundingClientRect();
            const t1 = new Point(
              touchEvent1.pageX - left - window.pageXOffset,
              touchEvent1.pageY - top - window.pageYOffset
            );
            const t2 = new Point(
              touchEvent2.pageX - left - window.pageXOffset,
              touchEvent2.pageY - top - window.pageYOffset
            );

            this.trigger(
              'singletap_2f',
              Object.assign(ev, {
                multiTouchCenter: new Point((t1.x + t2.x) / 2, (t1.y + t2.y) / 2),
              })
            );
          }
        } else if (this.maybeDoubleTap) {
          this.maybeDoubleTap = false;
          this.trigger('doubletap', ev);
        } else {
          this.singleTapTimeoutId = window.setTimeout(() => {
            this.trigger('singletap', ev);
            this.singleTapTimeoutId = 0;
          }, 150);
        }
      }
      this.previousTouches = undefined;
      this.longTapTotalMove.setValues(0, 0);
    } else if (this.touchFingerCount > 0 && !this.hasPinchFinished) {
      this.mayBe2FingerTap = true;
    } else {
      // ピンチ操作直後のtouchendでまだ指が画面に残っている場合、
      // 今までの移動量をリセットすることでワープを防ぐ
      this.previousTouches = undefined;
    }
  }

  /**
   * 2点間の距離を計算
   * @param p1 座標1
   * @param p2 座標2
   * @returns 座標間の距離
   */
  private calcDistance(p1: Point, p2: Point): number {
    return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
  }

  /**
   * 慣性スクロールの破棄
   * @returns {void}
   */
  private stopInertiaScroll(): void {
    this.inertiaScrollDelta = {
      x: 0,
      y: 0,
    };
    cancelAnimationFrame(this.inertiaScrollId);
    this.inertiaScrollId = INERTIA_SCROLL_DEFAULT_ID;
  }
}

export {
  TouchEventObserver,
  FLICK_THRESHOLD,
  ATTENUATION_RATE,
  INERTIA_SCROLL_CONTINUE_THRESHOLD,
  THROTTLE_DURATION,
  INERTIA_SCROLL_DEFAULT_ID,
};
