import {ValueObject} from './interface/ValueObject';
import {GaIAError} from './GaIAError';
import {JsonObject} from '../types';

/**
 * ズームレンジ表現クラス
 */
class ZoomRange implements ValueObject {
  /** 最大 */
  readonly max: number;
  /** 最小 */
  readonly min: number;

  /**
   * コンストラクタ
   * @param min 最小ズームレベル(デフォルトは6)
   * @param max 最大ズームレベル(デフォルトは20)
   */
  constructor(min = 6, max = 20) {
    if (min > max) {
      throw new GaIAError('MinZoomLevel is larger than MaxZoomLevel.');
    }
    this.min = min;
    this.max = max;
  }

  /**
   * ズームレンジの範囲内かどうか判定
   * @param zoom ズームレベル
   * @returns `true`:範囲内, `false`:範囲外
   */
  isInRange(zoom: number): boolean {
    return this.min <= zoom && zoom <= this.max;
  }

  /** @override */
  clone(): ZoomRange {
    return new ZoomRange(this.min, this.max);
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof ZoomRange)) {
      return false;
    }
    const other = obj as ZoomRange;
    return other.max === this.max && other.min === this.min;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      max: this.max,
      min: this.min,
    };
  }
}

const DEFAULT_ZOOM_RANGE = new ZoomRange();

export {ZoomRange, DEFAULT_ZOOM_RANGE};
