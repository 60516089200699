import {AbstractTileRenderKit} from './AbstractTileRenderKit';
import {MapStatus} from '../../../models/MapStatus';
import {ArrayList} from '../../../../common/collection/ArrayList';
import {TileNumber} from '../../../models/TileNumber';
import {TileRenderKitMapping} from '../MapRenderKitController';
import {GaiaContext} from '../../../GaiaContext';
import {Camera} from '../../../../engine/camera/Camera';
import {AdditionTileLoader} from '../../../loader/AdditionTileLoader';
import {AdditionTileCondition} from '../../../../../gaia/value/AdditionTileCondition';
import {LRUCache} from '../../../../common/collection/LRUCache';
import {AdditionTileParameter} from '../../../loader/param/AdditionTileParameter';
import {TextureMapping} from '../../../../engine/program/TextureMapping';
import {calculateWorldCoordinate} from '../../../utils/MapUtil';
import {aspectToFogDistanceRatio} from '../../../layer/FogObjectLayer';
import {TexturePlaneGeometry} from '../../../../engine/geometry/TexturePlaneGeometry';
import {TexturePlaneUVCoordinate} from '../../../../engine/geometry/TexturePlaneUVCoordinate';
import {AdditionTileResource} from '../../../models/AdditonTileResource';
import {AdditionTileGroupLayer} from '../../../layer/AdditionTileGroupLayer';
import {LatLng} from '../../../../../gaia/value';
import {AdditionTileLayer} from '../../../layer/AdditionTileLayer';
import {GaIAError} from '../../../../../gaia/value/GaIAError';
import {AdditionTileServerInfo} from '../../../../../gaia/types';

const TILE_LAYER_NAME_ADDITION = 'addition';

/**
 * 任意タイル制御マネージャー
 */
class AdditionTileRenderKit extends AbstractTileRenderKit {
  private readonly groupLayer: AdditionTileGroupLayer;
  private readonly resourceMap: Map<string, AdditionTileResource>;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private readonly tileParameterPool: LRUCache<TileNumber, AdditionTileParameter>;

  private previousZoomLevel: number;
  private previousCenter: LatLng;

  private _isAltitudeForGroupLayer: boolean;

  /**
   * コンストラクタ
   * @param context コンテスト
   * @param camera Camera
   */
  constructor(context: GaiaContext, camera: Camera) {
    super(context, camera, false, TILE_LAYER_NAME_ADDITION);

    this.groupLayer = new AdditionTileGroupLayer();

    this.resourceMap = new Map();
    this.tileParameterPool = new LRUCache<TileNumber, AdditionTileParameter>(100);

    this.previousZoomLevel = context.getMapStatus().zoomLevel;
    this.previousCenter = context.getMapStatus().centerLocation;

    this._isAltitudeForGroupLayer = false;
  }

  /**
   * 表示オプションを設定
   * @param key 設定対象のキー名
   * @param condition 表示設定
   * @returns {void}
   */
  setAdditionTileCondition(key: string, condition?: AdditionTileCondition): void {
    const serverInfo = this.context.getMapInitOptions().additionTileOptions?.serverInfoList[key];
    if (!serverInfo) {
      throw new GaIAError(`Cannot find server info of "${key}".`);
    }

    const layerName = `${TILE_LAYER_NAME_ADDITION}-${key}`;

    let resource = this.resourceMap.get(key);
    if (!condition) {
      if (resource) {
        resource.layer.updateTiles(new Map());
        this.groupLayer.removeAdditionTileLayer(layerName);
      }
      this.context.getMapStatus().removeAdditionTileName(key);
      this.resourceMap.delete(key);

      if (this.resourceMap.size === 0) {
        this.tileParameterPool.clear();
      }
      return;
    }

    if (resource) {
      resource.layer.updateTiles(new Map());
      resource.loader.clear();

      resource.updateCondition(condition);
    } else {
      resource = this.createAdditionTileResource(serverInfo, condition, layerName);

      this.groupLayer.addAdditionTileLayer(resource.layer);
      this.context.getMapStatus().addAdditionTileName(key);
      this.resourceMap.set(key, resource);
    }

    const inZoomRange = condition.zoomRange.isInRange(this.status?.zoomLevel ?? 0);
    resource.layer.setVisible(inZoomRange);
  }

  /**
   * AdditionTileResource作成
   * @param serverInfo サーバ情報
   * @param condition AdditionTileCondition
   * @param layerName レイヤー名
   * @returns AdditionTileResource
   */
  private createAdditionTileResource(
    serverInfo: AdditionTileServerInfo,
    condition: AdditionTileCondition,
    layerName: string
  ): AdditionTileResource {
    const loader = new AdditionTileLoader(
      this.context,
      () => {
        if (this.status && this.tileList) {
          this.updateDrawObjects(this.status, this.tileList);
        }
      },
      serverInfo
    );

    const {fadeIn, priority, transparency} = condition;
    const layer = new AdditionTileLayer(this.context, fadeIn, priority, layerName, transparency);

    return new AdditionTileResource(condition, loader, layer);
  }

  /**
   * 標高モードの状態を返す
   * @returns 標高モードの状態
   */
  isAltitudeModeForGroupLayer(): boolean {
    return this._isAltitudeForGroupLayer;
  }

  /**
   * 標高モードの状態を設定
   * @param altitudeMode 標高モードの状態
   * @returns {void}
   */
  setAltitudeModeForGroupLayer(altitudeMode: boolean): void {
    this._isAltitudeForGroupLayer = altitudeMode;
    this.groupLayer.setAltitudeMode(altitudeMode);
    for (const layer of this.groupLayer.getLayerList()) {
      layer.setAltitudeMode(altitudeMode);
    }
  }

  /**
   * condition登録済みキー名リストを取得
   * @returns キー名リスト
   */
  getAdditionTileKeyNameList(): string[] {
    return [...this.resourceMap.keys()];
  }

  /**
   * 指定キーがcondition登録済みかどうか
   * @param key キー
   * @returns 登録済みか
   */
  hasAdditionTileKeyName(key: string): boolean {
    return this.resourceMap.has(key);
  }

  /** @override */
  get identicalName(): keyof TileRenderKitMapping {
    return TILE_LAYER_NAME_ADDITION;
  }

  /**
   * グループレイヤーを取得
   * @returns AdditionTileGroupLayer
   */
  getGroupLayer(): AdditionTileGroupLayer {
    return this.groupLayer;
  }

  /**
   * 通常地図タイルを有効にするかどうか
   * @returns 有効にするか
   */
  isMapTileEnabled(): boolean {
    let enabled = true;
    for (const resource of this.resourceMap.values()) {
      const {condition, layer} = resource;

      let hide = condition.hideDefaultMapTile;
      if (hide === undefined) {
        // hideDefaultMapTile指定なしの場合はtransparencyで判定
        hide = condition.transparency >= 1;
      }

      // hide指定 かつ 表示状態有効 のレイヤーがあれば地図タイルを描画しない
      if (hide && layer.getVisible()) {
        enabled = false;
      }
    }
    return enabled;
  }

  /**
   * 注記レイヤーを有効にするかどうか
   * @returns 有効にするか
   */
  isAnnotationEnabled(): boolean {
    let enabled = true;
    for (const resource of this.resourceMap.values()) {
      if (resource.condition.hideAnnotation && resource.layer.getVisible()) {
        enabled = false;
      }
    }
    return enabled;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (this.resourceMap.size === 0) {
      return;
    }

    this.status = mapStatus;
    this.tileList = tileList;
    this.tileParameterPool.jumpUpSize(tileList.size() * 2);

    const isChangeZoomLevel =
      this.fixIntZoomLevel(mapStatus.zoomLevel) !== this.fixIntZoomLevel(this.previousZoomLevel);
    const isChangeSignificantlyCenterLat = Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) > 0.1;
    const isChangeSignificantlyCenterLng = Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) > 0.1;

    for (const resource of this.resourceMap.values()) {
      const zoomRange = resource.condition.zoomRange;
      const inZoomRange = zoomRange.isInRange(mapStatus.zoomLevel);
      resource.layer.setVisible(inZoomRange);
      if (!inZoomRange) {
        continue;
      }

      resource.loader.jumpUpCacheSize(tileList.size() * 2);

      // ズームレベル整数値が変化した場合や中心緯度経度が大きく変化した場合はリクエストキュークリア
      if (isChangeZoomLevel || isChangeSignificantlyCenterLat || isChangeSignificantlyCenterLng) {
        resource.loader.clearRequestQueue();
      }

      const requestTiles: AdditionTileParameter[] = [];

      // 生成済みテクスチャからLayerに描画物を渡す
      const tileTextureMap: Map<TileNumber, TextureMapping> = new Map();
      const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
      const cameraDistance = this.camera.position.magnitude();
      for (const tileNumber of tileList) {
        const latLng = tileNumber.centerLocation;
        const position = calculateWorldCoordinate(latLng);
        const distance = position._subtract(centerPosition).magnitude();
        const distanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
        if (distance > cameraDistance * distanceRatio) {
          continue;
        }

        const tileParam = this.getTileParameter(tileNumber);
        const tex = resource.loader.getTile(tileParam);
        if (tex) {
          const geometry = TexturePlaneGeometry.create(1, 1, TexturePlaneUVCoordinate.defaultUVCoordinate());
          tileTextureMap.set(tileNumber, new TextureMapping(geometry, tex.image));
        } else {
          requestTiles.push(tileParam);
        }
      }

      resource.layer.updateTiles(tileTextureMap);

      // この時点ですべての必要なテクスチャがLayerに追加されていれば終了
      if (requestTiles.length === 0) {
        continue;
      }
      // loaderにリクエストキュー追加
      resource.loader.addRequestQueue(requestTiles);
    }

    this.previousZoomLevel = mapStatus.zoomLevel;
    this.previousCenter = mapStatus.centerLocation;
  }

  /** @override */
  executeLoader(): void {
    for (const resource of this.resourceMap.values()) {
      resource.loader.executeRequest();
    }
  }

  /**
   * AdditionTileParameterを生成
   * @param tileNumber TileNumber
   * @returns AdditionTileParameter
   */
  private getTileParameter(tileNumber: TileNumber): AdditionTileParameter {
    if (this.tileParameterPool.has(tileNumber)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.tileParameterPool.get(tileNumber)!;
    }
    const {x, y, z} = tileNumber;
    const param = new AdditionTileParameter(x, y, z);
    this.tileParameterPool.add(tileNumber, param);
    return param;
  }

  /** @override */
  onDestroy(): void {
    for (const resource of this.resourceMap.values()) {
      resource.loader.destroy();
      resource.layer.destroy();
    }
    this.groupLayer.destroy();
  }
}

export {AdditionTileRenderKit, TILE_LAYER_NAME_ADDITION};
