import {DOMObject} from '../../../../gaia/object/DOMObject';
import {LatLng} from '../../../../gaia/value/LatLng';
import {GaIAClassName} from '../../../common/util/Styles';
import {ContextMenuItem} from '../../../../gaia/types';

/**
 * コンテキストメニューの実体オブジェクト
 */
class ContextMenuObject extends DOMObject {
  private readonly items: ContextMenuItem[];

  /**
   * コンストラクタ
   * @param items メニュー表示アイテム
   */
  constructor(items: ContextMenuItem[]) {
    super(new LatLng(0, 0), {
      classList: [GaIAClassName.Object.ContextMenu.Base],
    });
    this.items = items;

    const menu = this.createMenuElement();
    this.setContent(menu);
  }

  /** @override */
  protected getOffsetX(): number {
    return 0;
  }

  /** @override */
  protected getOffsetY(): number {
    return 0;
  }

  /**
   * メニューのDOM要素を生成
   * @returns DOM要素
   */
  private createMenuElement(): HTMLElement {
    const menuList = document.createElement('ul');
    menuList.classList.add(GaIAClassName.Object.ContextMenu.ListBase);

    for (const item of this.items) {
      const li = document.createElement('li');
      const a = document.createElement('a');
      li.classList.add(GaIAClassName.Object.ContextMenu.ListItem);
      a.classList.add(GaIAClassName.Object.ContextMenu.Link);

      a.href = 'javascript:void(0)';
      // eslint-disable-next-line require-jsdoc, @typescript-eslint/explicit-function-return-type
      a.onclick = (e) => {
        this.hide();
        item.onselect({
          native: e,
          position: this.getPosition(),
        });
      };
      a.textContent = item.text;

      li.appendChild(a);
      menuList.appendChild(li);
    }

    return menuList;
  }

  /**
   * メニューを表示
   * @returns {void}
   */
  show(): void {
    this.setVisible(true);
  }

  /**
   * メニューを非表示
   * @returns {void}
   */
  hide(): void {
    this.setVisible(false);
  }
}

export {ContextMenuObject};
