import {TileObject3D} from '../render/objects/TileObject3D';
import {Layer, LayerUpdateNotifierFunc} from '../../engine/layer/Layer';
import {TexturePlaneMaterial} from '../../engine/material/TexturePlaneMaterial';
import {TexturePlaneGeometry} from '../../engine/geometry/TexturePlaneGeometry';
import {GaiaContext} from '../GaiaContext';
import {Vector3} from '../../common/math/Vector3';
import {TileNumber} from '../models/TileNumber';
import {TextureMapping} from '../../engine/program/TextureMapping';
import {Queue} from '../../common/collection/Queue';
import {mat4} from 'gl-matrix';
import {calculateWorldCoordinate, getZoomLevelFixFunc} from '../utils/MapUtil';
import {SimpleCache} from '../../common/collection/SimpleCache';
import {ArrayList} from '../../common/collection/ArrayList';
import {DefaultRenderTarget, RenderTarget, ZoomLevelFixFunc} from '../../common/types';
import {Object3D} from '../../engine/object/Object3D';
import {Collision} from '../../engine/collision/Collision';
import {Ray3} from '../../common/math/Ray3';
import {AltitudeProgram} from '../../engine/program/AltitudeProgram';

const MAX_SET_TEXTURE_COUNT = 2;

const TRANSPARENCY_DURATION = 200; // msec

/**
 * タイル画像描画レイヤー
 */
class TileObjectLayer implements Layer {
  private readonly context: GaiaContext;

  /** 実際に描画している情報 */
  private drawTileObjects: SimpleCache<TileNumber, TileObject3D>;
  /** タイル画像情報 */
  private tileTextures?: Map<TileNumber, TextureMapping>;

  /** 再利用可能なMaterial */
  private reusableMaterials: Queue<TexturePlaneMaterial>;

  /** 前回描画時のジオメトリ */
  private geometryCache: Map<string, TexturePlaneGeometry>;

  private usesSubTile: boolean;
  private usesFadeInAnimation: boolean;

  private lastUpdateTime = 0;
  private visible = true;

  private notifyUpdate?: LayerUpdateNotifierFunc;

  private layerName: string;
  readonly maxTransparency: number;

  private readonly fixIntZoomLevel: ZoomLevelFixFunc;

  private isDestroyed = false;
  objects: Object3D[];

  private _isAltitudeMode: boolean;
  private defaultRenderTarget: DefaultRenderTarget;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param usesSubTile サブタイルを利用するかどうか
   * @param usesFadeInAnimation 新しいタイル表示時にフェードインのアニメーションを利用するかどうか
   * @param layerName レイヤー名
   * @param transparency レイヤー不透明度
   */
  constructor(
    context: GaiaContext,
    usesSubTile = false,
    usesFadeInAnimation = false,
    layerName = '',
    transparency = 1.0
  ) {
    this.context = context;
    this.objects = [];

    this.drawTileObjects = new SimpleCache<TileNumber, TileObject3D>();
    this.reusableMaterials = new Queue<TexturePlaneMaterial>();
    this.geometryCache = new Map();

    this.usesSubTile = usesSubTile;
    this.usesFadeInAnimation = usesFadeInAnimation;
    this.layerName = layerName;
    this.maxTransparency = transparency;

    this.fixIntZoomLevel = getZoomLevelFixFunc(this.context);

    this._isAltitudeMode = false;

    const status = context.getMapStatus();
    this.defaultRenderTarget = {
      type: 'default',
      size: {
        width: status.clientWidth,
        height: status.clientHeight,
      },
    };
  }

  /** @override */
  getIdenticalLayerName(): string {
    return this.layerName;
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * 表示状態を取得
   * @returns 表示状態
   */
  getVisible(): boolean {
    return this.visible;
  }

  /**
   * レイヤーの表示制御
   * @param visible `true` : 表示, `false` : 非表示
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  /**
   * 標高モードが設定されているかを取得
   * @returns 標高モードの状態
   */
  isAltitudeMode(): boolean {
    return this._isAltitudeMode;
  }

  /**
   * 標高モードの状態を設定
   * @param altitudeMode 標高モードの状態
   * @returns {void}
   */
  setAltitudeMode(altitudeMode: boolean): void {
    this._isAltitudeMode = altitudeMode;
  }

  /**
   * 描画対象の設定を更新
   * @returns {void}
   */
  private updateRenderTarget(): void {
    if (!this._isAltitudeMode) {
      const status = this.context.getMapStatus();
      this.defaultRenderTarget.size.width = status.clientWidth;
      this.defaultRenderTarget.size.height = status.clientHeight;
    }
  }

  /**
   * 描画対象の設定を取得
   * @returns 描画対象の設定
   */
  private getRenderTarget(): RenderTarget {
    if (this._isAltitudeMode && AltitudeProgram.renderTarget) {
      return AltitudeProgram.renderTarget;
    }
    return this.defaultRenderTarget;
  }

  /**
   * 地図更新
   * @param tileTextureMap テクスチャ画像の連想配列
   * @returns {void}
   */
  updateTiles(tileTextureMap: Map<TileNumber, TextureMapping>): void {
    if (this.isDestroyed) {
      return;
    }

    const requiredTileList: ArrayList<TileNumber> = ArrayList.from<TileNumber>(Array.from(tileTextureMap.keys()));

    // 不要になったタイルを削除する
    const currentTiles = Array.from(this.drawTileObjects.keys());
    for (const tile of currentTiles) {
      if (requiredTileList.contains(tile)) {
        continue;
      }
      const tileObject = this.drawTileObjects.get(tile);
      if (!tileObject) {
        continue;
      }
      const material = tileObject.material;
      material.initTexture();
      this.reusableMaterials.enqueue(material);
      this.drawTileObjects.remove(tile);
      this.geometryCache.delete(tile.getCacheKey());
    }

    // 新しく描画する必要があるタイルを追加する
    requiredTileList.forEach((tile: TileNumber) => {
      if (this.drawTileObjects.has(tile)) {
        return;
      }
      const material =
        this.reusableMaterials.dequeue() ??
        new TexturePlaneMaterial(this.context.getGLContext(), TexturePlaneGeometry.create(1, 1));

      const tileObject = new TileObject3D(Vector3.zero(), material, tile, this.fixIntZoomLevel, this.usesSubTile);
      if (this.usesFadeInAnimation) {
        tileObject.setTransparency(0);
      } else {
        tileObject.setTransparency(this.maxTransparency);
      }
      this.drawTileObjects.add(tile, tileObject);
    });

    this.tileTextures = tileTextureMap;
    this.notifyUpdate?.();
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    const now = new Date().getTime();
    if (!this.tileTextures || !this.visible) {
      // 描く情報がなければ更新済み扱い
      this.lastUpdateTime = now;
      return true;
    }

    let setTextureCount = 0;
    for (const [tile, tileObject] of this.drawTileObjects.entries()) {
      if (setTextureCount < MAX_SET_TEXTURE_COUNT) {
        const textureMapping = this.tileTextures.get(tile);
        if (!textureMapping) {
          continue;
        }
        const previousGeometry = this.geometryCache.get(tile.getCacheKey());
        if (previousGeometry && previousGeometry.equals(textureMapping.geometry)) {
          continue;
        }

        if (textureMapping.geometry.isDefaultUV()) {
          tileObject.setTexture(textureMapping.imageSource);
          tileObject.setTileTextureStatus('set');
        } else {
          tileObject.setTextureMapping(textureMapping);
          tileObject.setTileTextureStatus('completion');
        }
        this.geometryCache.set(tile.getCacheKey(), textureMapping.geometry);
        setTextureCount++;
      }
    }

    let updatedTransparency = false;
    let existsUnsetTextureTile = false;

    const diffMillisec = now - this.lastUpdateTime;
    const alphaDelta = diffMillisec / TRANSPARENCY_DURATION;

    this.updateRenderTarget();
    const renderTarget = this.getRenderTarget();

    for (const tileObject of this.drawTileObjects.values()) {
      if (tileObject.getTileTextureStatus() === 'unset') {
        existsUnsetTextureTile = true;
        continue;
      } else if (
        this.usesFadeInAnimation &&
        tileObject.isTransparent() &&
        tileObject.getTransparency() < this.maxTransparency
      ) {
        const alpha = tileObject.getTransparency();
        tileObject.setTransparency(
          alpha + alphaDelta > this.maxTransparency ? this.maxTransparency : alpha + alphaDelta
        );
        updatedTransparency = true;
      }
      const cameraTargetPosition = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);
      tileObject.mapUpdate(this.context.getMapStatus(), cameraTargetPosition);
      tileObject.update(viewMatrix, projectionMatrix);
      tileObject.draw(renderTarget);
    }

    for (const object of this.objects) {
      object.update(viewMatrix, projectionMatrix);
      object.draw(renderTarget);
    }

    this.lastUpdateTime = now;
    return setTextureCount === 0 && !updatedTransparency && !existsUnsetTextureTile;
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    while (this.reusableMaterials.size > 0) {
      const material = this.reusableMaterials.dequeue();
      if (!material) {
        break;
      }
      material.destroy();
    }
    for (const tileObject of this.drawTileObjects.values()) {
      tileObject.destroy();
    }
    this.drawTileObjects.clear();
    this.isDestroyed = true;
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return this._isAltitudeMode;
  }
}

export {TileObjectLayer};
