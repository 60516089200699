import {JsonObject} from '../../../../../gaia/types';
import {Optional} from '../../../../common/types';
import {createMap, GltfId} from '../../GltfReader';
import {GltfScene} from './GltfScene';

/**
 * JsonObjectからシーンを作成する
 * @param sceneObject オブジェクト
 * @returns シーン、オブジェクトの情報が不十分な場合は `undefined`
 */
const createSceneFromJsonObject = (sceneObject: JsonObject): Optional<GltfScene> => {
  const nodes = sceneObject.nodes;
  if (!nodes) {
    return undefined;
  }
  return new GltfScene(nodes);
};

/**
 * glTFのシーン
 */
class GltfScenesReader {
  private scenes: Map<GltfId, GltfScene>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.scenes = new Map();
  }

  /**
   * パース
   * @param json glTFのscenes部分
   * @returns パースが成功すれば `true` , そうでなければ `false`
   */
  parse(json: JsonObject): boolean {
    const scenes = createMap(json, createSceneFromJsonObject);
    if (!scenes) {
      return false;
    }
    this.scenes = scenes;
    return true;
  }

  /**
   * シーンを取得
   * @param key キー
   * @returns キーに対応するシーン
   */
  get(key: GltfId): Optional<GltfScene> {
    return this.scenes.get(key);
  }
}

export {GltfScenesReader};
