import {Triangle3} from './Triangle3';
import {Vector3} from './Vector3';
import {Ray3} from './Ray3';
import {Rect3} from './Rect3';
import {Collider} from './Collider';
import {Optional} from '../types';

/**
 * 矩形のコライダ
 */
class RectCollider implements Collider {
  private surface: Triangle3[];
  private _rect: Rect3;

  /**
   * コンストラクタ
   * @param topLeft 左上の点
   * @param topRight 右上の点
   * @param bottomLeft 左下の点
   */
  constructor(topLeft: Vector3, topRight: Vector3, bottomLeft: Vector3) {
    this.surface = [];
    this._rect = new Rect3(topLeft, topRight.subtract(topLeft), bottomLeft.subtract(topLeft));
    this.updateSurface();
  }

  /**
   * 矩形を取得
   */
  get rect(): Rect3 {
    return this._rect;
  }

  /**
   * 矩形を更新
   * @param rectCollider RectCollider
   * @returns {void}
   */
  set(rectCollider: RectCollider): void {
    this._rect.set(rectCollider.rect.topLeft, rectCollider.rect.right, rectCollider.rect.down);
    this.updateSurface();
  }

  /**
   * 矩形を更新
   * @param topLeft Vector3
   * @param right Vector3
   * @param down Vector3
   * @returns {void}
   */
  setValues(topLeft: Vector3, right: Vector3, down: Vector3): void {
    this._rect.setTopLeft(topLeft);
    this._rect.setRight(right);
    this._rect.setDown(down);
    this.updateSurface();
  }

  /**
   * 左上の点を設定
   * @param topLeft 左上の点
   * @returns {void}
   */
  setTopLeft(topLeft: Vector3): void {
    this._rect.setTopLeft(topLeft);
    this.updateSurface();
  }

  /**
   * 右方向のベクトルを設定
   * @param right 右方向のベクトル
   * @returns {void}
   */
  setRight(right: Vector3): void {
    this._rect.setRight(right);
    this.updateSurface();
  }

  /**
   * 下方向のベクトルを設定
   * @param down 下方向のベクトル
   * @returns {void}
   */
  setDown(down: Vector3): void {
    this._rect.setDown(down);
    this.updateSurface();
  }

  /**
   * 面を更新
   * @returns {void}
   */
  private updateSurface(): void {
    if (this.surface.length === 2) {
      this.surface[0].set(this._rect.topLeft, this._rect.right, this._rect.down);
      this.surface[1].set(this._rect.bottomRight, this._rect.right._multiply(-1), this._rect.down._multiply(-1));
      return;
    }

    this.surface = [];
    this.surface.push(new Triangle3(this._rect.topLeft, this._rect.right, this._rect.down));
    this.surface.push(
      new Triangle3(this._rect.bottomRight, this._rect.right._multiply(-1), this._rect.down._multiply(-1))
    );
  }

  /** @override */
  isCollided(ray: Ray3): boolean {
    if (this.calculateIntersection(ray)) {
      return true;
    }
    return false;
  }

  /**
   * レイと交差する点を返す
   * @param ray Ray3
   * @returns 交差する点
   */
  calculateIntersection(ray: Ray3): Optional<Vector3> {
    for (const triangle of this.surface) {
      const intersection = triangle.calculateIntersection(ray);
      if (intersection) {
        return intersection;
      }
    }

    return undefined;
  }
}

export {RectCollider};
