import {Vector3} from './Vector3';

/**
 * 3次元上の平面のクラス
 *
 * ・点とベクトル2本で表現する
 * ・平面上の任意の点は point + a * v1 + b * v2 で表す
 */
class Plane3 {
  protected point: Vector3;
  protected v1: Vector3;
  protected v2: Vector3;

  /**
   * コンストラクタ
   * @param point 始点
   * @param v1 1つ目の方向ベクトル
   * @param v2 2つ目の方向ベクトル
   */
  constructor(point: Vector3, v1: Vector3, v2: Vector3) {
    this.point = point;
    this.v1 = v1;
    this.v2 = v2;
  }

  /**
   * 値を設定する
   * @param point 始点
   * @param v1 1つ目の方向ベクトル
   * @param v2 2つ目の方向ベクトル
   * @returns {void}
   */
  set(point: Vector3, v1: Vector3, v2: Vector3): void {
    this.point = point;
    this.v1 = v1;
    this.v2 = v2;
  }
}

export {Plane3};
