import {mat4} from 'gl-matrix';

import {Material} from './Material';
import {SingleColorProgram} from '../program/SingleColorProgram';
import {Object3D} from '../object/Object3D';
import {Color} from '../../../gaia/value/Color';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {RenderTarget} from '../../common/types';

/**
 * 単色で描画するマテリアル
 */
class SingleColorMaterial implements Material {
  readonly program: SingleColorProgram;
  readonly color: Color;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param color 色
   */
  constructor(context: WebGLRenderingContext, geometry: CustomGeometry, color: Color) {
    this.color = color.clone();
    this.program = new SingleColorProgram(context, geometry, this.color);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: CustomGeometry): void {
    this.program.setGeometry(geometry);
  }

  /**
   * 頂点情報を更新
   * @param vertices 頂点情報
   * @returns {void}
   */
  setVertices(vertices: number[]): void {
    this.program.setVertices(vertices);
  }

  /**
   * 色を更新
   * @param color 色
   * @returns {void}
   */
  setColor(color: Color): void {
    this.program.setColor(color);
  }

  /**
   * 更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    this.program.update(object3D, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @param renderTarget 描画対象の状態
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    this.program.draw(renderTarget);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.program.destroy();
  }
}

export {SingleColorMaterial};
