import {mat4} from 'gl-matrix';
import {Color} from '../../../gaia/value/Color';
import {Optional} from '../../common/types';
import {Geometry} from '../geometry/Geometry';
import {Object3D} from '../object/Object3D';
import {Program} from './Program';
import {TextureMapping} from './TextureMapping';

const MAX_ALPHA = 1.0;
const MAX_COLOR_SIZE = 10;

const vertexShaderSource = `
attribute vec3 vertexPosition;
attribute vec2 texCoord;

uniform mat4 model;
uniform mat4 view;
uniform mat4 projection;

varying vec2 textureCoord;

void main() {
  textureCoord = texCoord;
  gl_Position = projection * view * model * vec4(vertexPosition, 1.0);
}
`;

const fragmentShaderSource = `
precision highp float;

uniform sampler2D tex;
uniform float textureAlpha;
uniform bool isPremultipliedAlpha;

uniform int colorSize;
uniform float thresholdArray[10];
uniform vec4 colorArray[10];

varying vec2 textureCoord;

void main() {
  float ratio = texture2D(tex, textureCoord).r;

  if (ratio <= 0.0) {
    gl_FragColor = vec4(0.0);
    return;
  }

  for (int i=1; i < ${MAX_COLOR_SIZE}; i++) {
    if (ratio <= thresholdArray[i]) {
      float downThreshold = thresholdArray[i-1];
      float upThreshold = thresholdArray[i];
      float width = upThreshold - downThreshold;
      float downRatio = (upThreshold - ratio) / width;
      float upRatio = (ratio - downThreshold) / width;
      gl_FragColor = colorArray[i] * upRatio + colorArray[i-1] * downRatio;
      break;
    }
  }

  if (isPremultipliedAlpha) {
    gl_FragColor.r /= gl_FragColor.a;
    gl_FragColor.g /= gl_FragColor.a;
    gl_FragColor.b /= gl_FragColor.a;
  }
  gl_FragColor.a *= textureAlpha;
}
`;

let VERTEX_SHADER: Optional<WebGLShader>;
let FRAGMENT_SHADER: Optional<WebGLShader>;
let PROGRAM: Optional<WebGLProgram>;

/**
 * ヒートマップを描画するプログラム
 */
class HeatMapProgram implements Program {
  context: WebGLRenderingContext;
  geometry: Geometry;

  vertexShader: Optional<WebGLShader>;
  fragmentShader: Optional<WebGLShader>;
  program: Optional<WebGLProgram>;

  image: Optional<TexImageSource>;
  imageAlpha: number;
  isPremultipliedAlphaImage: boolean;
  texture: WebGLTexture | null;

  private static initialTexture: Optional<TexImageSource> = null;

  private modelLocation: Optional<WebGLUniformLocation>;
  private viewLocation: Optional<WebGLUniformLocation>;
  private projectionLocation: Optional<WebGLUniformLocation>;
  private textureAlphaLocation: Optional<WebGLUniformLocation>;
  private isPremultipliedAlphaLocation: Optional<WebGLUniformLocation>;

  private colorSize: number;
  private colorWeightList: number[];
  private colorList: Color[];
  private colorSizeLocation: Optional<WebGLUniformLocation>;
  private thresholdLocationList: Array<Optional<WebGLUniformLocation>>;
  private colorLocationList: Array<Optional<WebGLUniformLocation>>;

  private modelMatrix: mat4;
  private translationMatrix: mat4;
  private rotationMatrix: mat4;
  private scaleMatrix: mat4;

  private vertexBuffer: Optional<WebGLBuffer>;
  private indexBuffer: Optional<WebGLBuffer>;

  private typedVertices: Float32Array;
  private typedIndices: Uint16Array;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param isPremultipliedAlpha アルファモードの指定
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry, isPremultipliedAlpha: boolean) {
    this.context = context;

    this.image = null;
    this.imageAlpha = MAX_ALPHA;
    this.isPremultipliedAlphaImage = isPremultipliedAlpha;
    this.texture = context.createTexture();

    this.modelMatrix = mat4.create();
    this.translationMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.scaleMatrix = mat4.create();

    this.typedVertices = new Float32Array();
    this.typedIndices = new Uint16Array();

    this.colorSize = 0;
    this.colorWeightList = [];
    this.colorList = [];
    this.thresholdLocationList = [];
    this.colorLocationList = [];
    for (let colorIndex = 0; colorIndex < MAX_COLOR_SIZE; colorIndex++) {
      this.colorWeightList.push(0);
      this.colorList.push(new Color(0, 0, 0, 0));
      this.thresholdLocationList.push(0);
      this.colorLocationList.push(null);
    }

    this.setupProgram();
    this.setGeometry(geometry);
    this.setupGeometry(geometry);
    this.initTexture();

    this.geometry = geometry;
  }

  /**
   * プログラムを準備
   * @returns {void}
   */
  setupProgram(): void {
    if (!VERTEX_SHADER) {
      const mayBeVertexShader: WebGLShader | null = this.context.createShader(this.context.VERTEX_SHADER);
      if (mayBeVertexShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create vertex shader');
        return;
      }
      VERTEX_SHADER = mayBeVertexShader;
      this.context.shaderSource(VERTEX_SHADER, vertexShaderSource);
      this.context.compileShader(VERTEX_SHADER);

      const vertexShaderCompileStatus = this.context.getShaderParameter(VERTEX_SHADER, this.context.COMPILE_STATUS);
      if (!vertexShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(VERTEX_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
        return;
      }
    }
    this.vertexShader = VERTEX_SHADER;

    if (!FRAGMENT_SHADER) {
      const mayBeFragmentShader: WebGLShader | null = this.context.createShader(this.context.FRAGMENT_SHADER);
      if (mayBeFragmentShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create fragment shader');
        return;
      }
      FRAGMENT_SHADER = mayBeFragmentShader;
      this.context.shaderSource(FRAGMENT_SHADER, fragmentShaderSource);
      this.context.compileShader(FRAGMENT_SHADER);

      const fragmentShaderCompileStatus = this.context.getShaderParameter(FRAGMENT_SHADER, this.context.COMPILE_STATUS);
      if (!fragmentShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(FRAGMENT_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.fragmentShader = FRAGMENT_SHADER;

    if (!PROGRAM) {
      const mayBeProgram = this.context.createProgram();
      if (mayBeProgram === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create program');
        return;
      }
      PROGRAM = mayBeProgram;
      this.context.attachShader(PROGRAM, this.vertexShader);
      this.context.attachShader(PROGRAM, this.fragmentShader);
      this.context.linkProgram(PROGRAM);

      const linkStatus = this.context.getProgramParameter(PROGRAM, this.context.LINK_STATUS);
      if (!linkStatus) {
        const info = this.context.getProgramInfoLog(PROGRAM);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.program = PROGRAM;

    this.context.useProgram(this.program);
  }

  /**
   * ジオメトリを準備
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setupGeometry(geometry: Geometry): void {
    if (!this.program) {
      return;
    }

    this.geometry = geometry;

    this.context.useProgram(this.program);

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertexAttribLocation = this.context.getAttribLocation(this.program, 'vertexPosition');
    const textureAttribLocation = this.context.getAttribLocation(this.program, 'texCoord');

    const VERTEX_SIZE = 3;
    const TEXTURE_SIZE = 2;
    const STRIDE = (VERTEX_SIZE + TEXTURE_SIZE) * Float32Array.BYTES_PER_ELEMENT;
    const VERTEX_OFFSET = 0;
    const TEXTURE_OFFSET = 3 * Float32Array.BYTES_PER_ELEMENT;

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);

    this.context.enableVertexAttribArray(vertexAttribLocation);
    this.context.enableVertexAttribArray(textureAttribLocation);

    this.context.vertexAttribPointer(
      vertexAttribLocation,
      VERTEX_SIZE,
      this.context.FLOAT,
      false,
      STRIDE,
      VERTEX_OFFSET
    );
    this.context.vertexAttribPointer(
      textureAttribLocation,
      TEXTURE_SIZE,
      this.context.FLOAT,
      false,
      STRIDE,
      TEXTURE_OFFSET
    );

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
  }

  /**
   * テクスチャを初期化する
   * @returns {void}
   */
  initTexture(): void {
    if (!HeatMapProgram.initialTexture) {
      const tileSize = 2;
      const canvas = document.createElement('canvas');
      canvas.width = tileSize;
      canvas.height = tileSize;
      const context = canvas.getContext('2d');
      if (!context) {
        return;
      }
      context.fillStyle = '#00000000';
      context.fillRect(0, 0, tileSize, tileSize);

      HeatMapProgram.initialTexture = canvas;
    }
    this.setTexture(HeatMapProgram.initialTexture);
  }

  /**
   * テクスチャを更新する
   * @param image テクスチャ画像ソース
   * @returns {void}
   */
  setTexture(image: TexImageSource): void {
    if (!this.program) {
      return;
    }

    if (this.image === image) {
      return;
    }

    this.image = image;

    this.context.useProgram(this.program);

    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
    this.context.texImage2D(
      this.context.TEXTURE_2D,
      0,
      this.context.RGBA,
      this.context.RGBA,
      this.context.UNSIGNED_BYTE,
      image
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_S,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_T,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MAG_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MIN_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.bindTexture(this.context.TEXTURE_2D, null);
  }

  /**
   * GLのテクスチャを設定
   * @param texture GLのテクスチャ
   * @returns {void}
   */
  setGLTexture(texture: WebGLTexture): void {
    if (!this.program) {
      return;
    }

    this.texture = texture;
    this.context.useProgram(this.program);

    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_S,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_T,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MAG_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MIN_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.bindTexture(this.context.TEXTURE_2D, null);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.geometry = geometry;

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertices = this.geometry.getVertices();
    const verticesLength = vertices.length;
    if (this.typedVertices.length !== verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    const indices = this.geometry.getIndices();
    const indicesLength = indices.length;
    if (this.typedIndices.length !== indicesLength) {
      this.typedIndices = new Uint16Array(indicesLength);
    }
    this.typedIndices.set(indices);

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);
    this.context.bufferData(this.context.ARRAY_BUFFER, this.typedVertices, this.context.STATIC_DRAW);

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    this.context.bufferData(this.context.ELEMENT_ARRAY_BUFFER, this.typedIndices, this.context.STATIC_DRAW);
  }

  /**
   * テクスチャ画像とUV画像を更新する
   * @param textureMapping テクスチャ情報
   * @returns {void}
   */
  setTextureMapping(textureMapping: TextureMapping): void {
    this.setGeometry(textureMapping.geometry);
    this.setTexture(textureMapping.imageSource);
  }

  /**
   * テクスチャが少しでも透明かどうか
   * @returns 透明性（少しでも透明であればtrue）
   */
  isTransparent(): boolean {
    return this.imageAlpha < MAX_ALPHA;
  }

  /**
   * テクスチャの透明度を取得
   * @returns 透明度
   */
  getTextureTransparency(): number {
    return this.imageAlpha;
  }

  /**
   * テクスチャの透明度を更新する
   * @param alpha 透明度
   * @returns {void}
   */
  setTextureTransparency(alpha: number): void {
    if (alpha > 1.0) {
      this.imageAlpha = 1.0;
      return;
    }
    if (alpha < 0.0) {
      this.imageAlpha = 0.0;
      return;
    }
    this.imageAlpha = alpha;
  }

  /**
   * テクスチャ更新
   * @returns {void}
   */
  updateTexture(): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
  }

  /**
   * 重みと色の対応付けを設定
   * @param colorMap 重みと色の対応付け
   * @returns {void}
   */
  setColorMap(colorMap: Map<number, Color>): void {
    const sortedWeights = Array.from(colorMap.keys()).sort();
    this.colorSize = sortedWeights.length;
    const maxWeight = sortedWeights[sortedWeights.length - 1];
    for (let index = 0; index < sortedWeights.length; index++) {
      const weight = sortedWeights[index];
      this.colorWeightList[index] = weight / maxWeight;
      const color = colorMap.get(weight);
      if (color) {
        this.colorList[index] = color;
      }
    }
  }

  /**
   * 行列情報を更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    if (!this.program) {
      return;
    }

    // model
    mat4.identity(this.scaleMatrix);
    mat4.identity(this.rotationMatrix);
    mat4.identity(this.translationMatrix);
    mat4.identity(this.modelMatrix);

    mat4.scale(this.scaleMatrix, this.scaleMatrix, object3D.scale.toFloat32Array());
    mat4.multiply(this.rotationMatrix, this.rotationMatrix, object3D.rotation.toMat4());
    mat4.translate(this.translationMatrix, this.translationMatrix, object3D.position.toFloat32Array());

    mat4.multiply(this.modelMatrix, this.modelMatrix, this.translationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.rotationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.scaleMatrix);

    this.context.useProgram(this.program);
    if (!this.modelLocation) {
      this.modelLocation = this.context.getUniformLocation(this.program, 'model');
    }
    if (!this.viewLocation) {
      this.viewLocation = this.context.getUniformLocation(this.program, 'view');
    }
    if (!this.projectionLocation) {
      this.projectionLocation = this.context.getUniformLocation(this.program, 'projection');
    }
    if (!this.textureAlphaLocation) {
      this.textureAlphaLocation = this.context.getUniformLocation(this.program, 'textureAlpha');
    }
    if (!this.isPremultipliedAlphaLocation) {
      this.isPremultipliedAlphaLocation = this.context.getUniformLocation(this.program, 'isPremultipliedAlpha');
    }
    this.context.uniformMatrix4fv(this.modelLocation, false, this.modelMatrix);
    this.context.uniformMatrix4fv(this.viewLocation, false, viewMatrix);
    this.context.uniformMatrix4fv(this.projectionLocation, false, projectionMatrix);
    this.context.uniform1f(this.textureAlphaLocation, this.imageAlpha);
    this.context.uniform1i(this.isPremultipliedAlphaLocation, this.isPremultipliedAlphaImage ? 1 : 0);

    if (!this.colorSizeLocation) {
      this.colorSizeLocation = this.context.getUniformLocation(this.program, 'colorSize');
    }
    this.context.uniform1i(this.colorSizeLocation, this.colorSize);

    for (let index = 0; index < MAX_COLOR_SIZE; index++) {
      if (!this.colorLocationList[index]) {
        this.colorLocationList[index] = this.context.getUniformLocation(this.program, `colorArray[${index}]`);
      }
      if (!this.thresholdLocationList[index]) {
        this.thresholdLocationList[index] = this.context.getUniformLocation(this.program, `thresholdArray[${index}]`);
      }

      const color = this.colorList[index];
      const threshold = this.colorWeightList[index];
      const colorLocation = this.colorLocationList[index];
      const thresholdLocation = this.thresholdLocationList[index];
      if (color && colorLocation && thresholdLocation) {
        this.context.uniform4f(colorLocation, color.r, color.g, color.b, color.a);
        this.context.uniform1f(thresholdLocation, threshold);
      }
    }
  }

  /**
   * 描画
   * @returns {void}
   */
  draw(): void {
    this.setupGeometry(this.geometry);
    this.updateTexture();

    this.context.blendFuncSeparate(
      this.context.SRC_ALPHA,
      this.context.ONE_MINUS_SRC_ALPHA,
      this.context.ONE,
      this.context.ONE
    );
    this.context.enable(this.context.BLEND);

    this.context.drawElements(
      this.context.TRIANGLES,
      this.geometry.getIndices().length,
      this.context.UNSIGNED_SHORT,
      0
    );

    this.context.disable(this.context.BLEND);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const gl = this.context;
    if (PROGRAM && VERTEX_SHADER && FRAGMENT_SHADER) {
      gl.detachShader(PROGRAM, VERTEX_SHADER);
      gl.detachShader(PROGRAM, FRAGMENT_SHADER);
      gl.deleteProgram(PROGRAM);
      PROGRAM = undefined;
    }
    if (VERTEX_SHADER) {
      gl.deleteShader(VERTEX_SHADER);
      VERTEX_SHADER = undefined;
    }
    if (FRAGMENT_SHADER) {
      gl.deleteShader(FRAGMENT_SHADER);
      FRAGMENT_SHADER = undefined;
    }
    if (this.indexBuffer) {
      gl.deleteBuffer(this.indexBuffer);
      this.indexBuffer = undefined;
    }
    if (this.vertexBuffer) {
      gl.deleteBuffer(this.vertexBuffer);
      this.vertexBuffer = undefined;
    }
    this.viewLocation = undefined;
    this.modelLocation = undefined;
    this.projectionLocation = undefined;
    this.textureAlphaLocation = undefined;
  }
}

export {HeatMapProgram};
