import {MapParts} from './MapParts';
import {MapStatus} from '../models/MapStatus';
import {Vector2} from '../../common/math/Vector2';
import {calculateWorldCoordinate, worldToLatLng} from '../utils/MapUtil';
import {Camera} from '../../engine/camera/Camera';
import {Vector3} from '../../common/math/Vector3';
import {viewPointToIntersectionForPerspectiveCamera} from '../render/MapTileScanner';
import {PerspectiveCamera} from '../../engine/camera/PerspectiveCamera';
import {calcDistance} from '../../../gaia/util';
import {ScaleOptions} from '../../../gaia/types';
import {GaIAClassName} from '../../common/util/Styles';
import {Point} from 'gaia/value';

type ZoomTableProperty = {
  display: string;
  distance: number;
};

const ZOOM_TABLE: {[level: number]: ZoomTableProperty} = {
  6: {
    display: '100km',
    distance: 100 * 1000,
  },
  7: {
    display: '50km',
    distance: 50 * 1000,
  },
  8: {
    display: '20km',
    distance: 20 * 1000,
  },
  9: {
    display: '10km',
    distance: 10 * 1000,
  },
  10: {
    display: '5km',
    distance: 5 * 1000,
  },
  11: {
    display: '2km',
    distance: 2 * 1000,
  },
  12: {
    display: '1km',
    distance: 1 * 1000,
  },
  13: {
    display: '500m',
    distance: 500,
  },
  14: {
    display: '200m',
    distance: 200,
  },
  15: {
    display: '200m',
    distance: 200,
  },
  16: {
    display: '100m',
    distance: 100,
  },
  17: {
    display: '50m',
    distance: 50,
  },
  18: {
    display: '20m',
    distance: 20,
  },
  19: {
    display: '10m',
    distance: 10,
  },
  20: {
    display: '10m',
    distance: 10,
  },
};

/**
 * 縮尺パーツ
 */
class Scale implements MapParts {
  private readonly scaleElement: HTMLElement;
  private readonly scaleMeasure: HTMLDivElement;
  private readonly scaleText: HTMLSpanElement;

  private currentZoom = 0;

  /**
   * コンストラクタ
   * @param options 初期化オプション
   */
  constructor(options?: ScaleOptions) {
    this.scaleElement = document.createElement('div');
    this.scaleElement.classList.add(GaIAClassName.Parts.Scale.Base);

    if (options?.backgroundColor) {
      this.scaleElement.style.background = options?.backgroundColor;
    }
    if (options?.scaleColor) {
      this.scaleElement.style.color = options?.scaleColor;
    }
    if (options?.offset) {
      this.scaleElement.style.left = `${options.offset.x}px`;
      this.scaleElement.style.bottom = `${options.offset.y}px`;
    }

    this.scaleMeasure = document.createElement('div');
    this.scaleMeasure.classList.add(GaIAClassName.Parts.Scale.Measure);
    if (options?.scaleColor) {
      this.scaleMeasure.style.borderColor = options?.scaleColor;
    }
    this.scaleElement.appendChild(this.scaleMeasure);

    this.scaleText = document.createElement('span');
    this.scaleText.classList.add(GaIAClassName.Parts.Scale.Text);
    if (options?.scaleColor) {
      this.scaleText.style.color = options?.scaleColor;
    }
    this.scaleElement.appendChild(this.scaleText);
  }

  /** @override */
  getParts(): HTMLElement {
    return this.scaleElement;
  }

  /** @override */
  updatePosition(position: Point): void {
    this.scaleElement.style.left = `${position.x}px`;
    this.scaleElement.style.bottom = `${position.y}px`;
  }

  /** @override */
  onUpdateStatus(status: MapStatus, camera: Camera): void {
    if (this.currentZoom !== Math.floor(status.zoomLevel)) {
      this.currentZoom = Math.floor(status.zoomLevel);
      this.scaleText.textContent = `${ZOOM_TABLE[this.currentZoom].display}`;
    }

    const meterPerPixel = this.calcMeterPerPixel(status, camera);
    const scaleWidth = ZOOM_TABLE[this.currentZoom].distance / meterPerPixel;
    this.scaleMeasure.style.width = `${scaleWidth.toFixed(1)}px`;
  }

  /**
   * meterPerPixelを計算
   * @param status MapStatus
   * @param camera Camera
   * @returns MeterPerPixel
   */
  private calcMeterPerPixel(status: MapStatus, camera: Camera): number {
    const {centerLocation, zoomLevel, clientWidth} = status;
    const upVector: Vector3 = status.polar.toUpVector3();
    const rightVector: Vector3 = status.polar.toRightVector3();
    const centerWorld: Vector3 = calculateWorldCoordinate(centerLocation);

    const rightWP: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      status,
      camera as PerspectiveCamera,
      new Vector2(1, 0),
      upVector,
      rightVector
    )._add(centerWorld);
    const right = worldToLatLng(rightWP, zoomLevel);

    const distance = calcDistance(centerLocation, right);
    return distance / (clientWidth / 2);
  }
}

export {Scale};
