import {AnnotationDBHandler} from '../../_private/map/loader/db/AnnotationDBHandler';

/**
 * @ignore
 * 保存済み注記データの削除
 * @returns {void}
 */
const deleteLocalAnnotationData = (): void => {
  const annotationDB = new AnnotationDBHandler();
  annotationDB.deleteAllMetaData();
  annotationDB.deleteAllIconData();
  annotationDB.deleteAllMainData();
};

export {deleteLocalAnnotationData};
