import {JsonObject} from '../../../../../gaia/types';
import {Optional} from '../../../../common/types';
import {createMap, GltfId} from '../../GltfReader';
import {GltfBuffer} from './GltfBuffer';

/**
 * JsonObjectからバッファを作成する
 * @param bufferObject オブジェクト
 * @returns バッファ、オブジェクトの情報が不十分な場合は `undefined`
 */
const createBufferFromJsonObject = (bufferObject: JsonObject): Optional<GltfBuffer> => {
  const uri = bufferObject.uri;
  const byteLength = bufferObject.byteLength;
  if (uri === undefined || byteLength === undefined) {
    return undefined;
  }
  return new GltfBuffer(uri, byteLength);
};

/**
 * GLTFのbuffers
 */
class GltfBuffersReader {
  private buffers: Map<GltfId, GltfBuffer>;
  private arrayBuffers: Map<GltfId, ArrayBuffer>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.buffers = new Map();
    this.arrayBuffers = new Map();
  }

  /**
   * パース
   * @param json GLTFのbuffers部分
   * @returns 成功すれば `true` , そうでなければ `false`
   */
  parse(json: JsonObject): boolean {
    const buffers = createMap(json, createBufferFromJsonObject);
    if (!buffers) {
      return false;
    }
    this.buffers = buffers;
    return this.decodeBuffers();
  }

  /**
   * バッファのbase64文字列をArrayBufferに変換する
   * @returns 成功すれば `true` , そうでなければ `false`
   */
  private decodeBuffers(): boolean {
    for (const [key, value] of this.buffers.entries()) {
      const splited = value.uri.split(',');
      if (splited.length !== 2) {
        return false;
      }
      const binaryString = window.atob(splited[1]);
      const length = binaryString.length;
      if (length !== value.byteLength) {
        return false;
      }
      const bytes = new Uint8Array(length);
      for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      this.arrayBuffers.set(key, bytes.buffer);
    }
    return true;
  }

  /**
   * バッファを取得
   * @param key キー
   * @returns キーに対応するバッファ
   */
  get(key: GltfId): Optional<ArrayBuffer> {
    return this.arrayBuffers.get(key);
  }
}

export {GltfBuffersReader};
