import {Optional} from '../../_private/common/types';

const MAX_UNSIGNED_BYTE = 255;

/**
 * 色のクラス
 */
class Color {
  /**
   * 赤成分
   */
  readonly r: number;

  /**
   * 緑成分
   */
  readonly g: number;

  /**
   * 青成分
   */
  readonly b: number;

  /**
   * 透明度成分
   */
  readonly a: number;

  /**
   * 色を作成する
   * @param r 赤成分(0以上1以下)
   * @param g 緑成分(0以上1以下)
   * @param b 青成分(0以上1以下)
   * @param a 透明度成分(0以上1以下)
   */
  constructor(r: number, g: number, b: number, a: number) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  /**
   * 複製
   * @returns 複製した色
   */
  clone(): Color {
    return new Color(this.r, this.g, this.b, this.a);
  }

  /**
   * 完全に透明かどうかを返す
   * @returns 完全に透明であればtrue, そうでなければfalse
   */
  isClear(): boolean {
    return this.a <= 0;
  }

  /**
   * 16進カラーコードに変換
   * @returns カラーコード ( `#rrggbb` )
   */
  toHexString(): string {
    // eslint-disable-next-line require-jsdoc
    const toHex = (x: number): string => ('00' + Math.round(x * 255).toString(16)).substr(-2);
    return `#${toHex(this.r)}${toHex(this.g)}${toHex(this.b)}`;
  }

  /**
   * 黒色を作成する
   * @returns 黒
   */
  static black(): Color {
    return new Color(0, 0, 0, 1.0);
  }

  /**
   * グレーを作成する
   * @returns グレー
   */
  static gray(): Color {
    return new Color(0.47, 0.47, 0.47, 1.0);
  }

  /**
   * 白を作成する
   * @returns 白
   */
  static white(): Color {
    return new Color(1.0, 1.0, 1.0, 1.0);
  }

  /**
   * 赤を作成する
   * @returns 赤
   */
  static red(): Color {
    return new Color(1.0, 0, 0, 1.0);
  }

  /**
   * 黄色を作成する
   * @returns 黄色
   */
  static yellow(): Color {
    return new Color(1.0, 1.0, 0, 1.0);
  }

  /**
   * 緑を作成する
   * @returns 緑
   */
  static green(): Color {
    return new Color(0, 1.0, 0, 1.0);
  }

  /**
   * シアンを作成する
   * @returns シアン
   */
  static cyan(): Color {
    return new Color(0, 1.0, 1.0, 1.0);
  }

  /**
   * 青を作成する
   * @returns 青
   */
  static blue(): Color {
    return new Color(0, 0, 1.0, 1.0);
  }

  /**
   * マゼンタを作成する
   * @returns マゼンタ
   */
  static magenta(): Color {
    return new Color(1.0, 0, 1.0, 1.0);
  }

  /**
   * 透明を作成する
   * @returns 透明
   */
  static clear(): Color {
    return new Color(0, 0, 0, 0);
  }

  /**
   * カラーコードの文字列から色を作成する
   * @param colorCode 16進数6桁で表現されたカラーコード文字列
   * @param alpha 透明度
   * @returns 作成した色、もしカラーコード文字列のパースに失敗するとnullを返す
   */
  static fromColorCodeSixHex(colorCode: string, alpha = 1.0): Optional<Color> {
    const colorCodeRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const regexResult = colorCodeRegex.exec(colorCode);
    if (!regexResult) {
      return null;
    }
    const r = parseInt(regexResult[1], 16);
    const g = parseInt(regexResult[2], 16);
    const b = parseInt(regexResult[3], 16);
    const a = alpha;
    if (Number.isNaN(r) || Number.isNaN(g) || Number.isNaN(b)) {
      return null;
    }
    return new Color(r / MAX_UNSIGNED_BYTE, g / MAX_UNSIGNED_BYTE, b / MAX_UNSIGNED_BYTE, a);
  }

  /**
   * カラーコードの文字列から色を作成する
   * @deprecated `fromColorCodeSixHex` を利用してください
   * @param colorCode 16進数6桁で表現されたカラーコード文字列
   * @param alpha 透明度
   * @returns 作成した色、もしカラーコード文字列のパースに失敗するとnullを返す
   */
  static fromColorCodeSixHexs(colorCode: string, alpha = 1.0): Optional<Color> {
    // eslint-disable-next-line no-console
    console.warn('This function is deprecated. Use "fromColorCodeSixHex".');
    return this.fromColorCodeSixHex(colorCode, alpha);
  }
}

export {Color};
