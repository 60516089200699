import {Size} from '../../../gaia/value';

/**
 * 要素の大きさを計算する
 * @param element HTML要素
 * @returns 大きさ
 */
const calculateElementSize = (element: HTMLElement): Size => {
  const visibility = element.style.visibility;
  element.style.visibility = 'hidden';
  const alreadyExists = document.body.contains(element);
  if (!alreadyExists) {
    document.body.appendChild(element);
  }

  const style = window.getComputedStyle(element);
  const height =
    Math.ceil(parseFloat(style.getPropertyValue('margin-top') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('border-top') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('padding-top') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('height') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('padding-bottom') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('border-bottom') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('margin-bottom') || '0'));

  const width =
    Math.ceil(parseFloat(style.getPropertyValue('margin-left') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('border-left') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('padding-left') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('width') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('padding-right') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('border-right') || '0')) +
    Math.ceil(parseFloat(style.getPropertyValue('margin-right') || '0'));

  if (!alreadyExists) {
    document.body.removeChild(element);
  }
  element.style.visibility = visibility;

  return new Size(height, width);
};

/**
 * HTMLElementに変換
 * @param element 要素or文字列
 * @returns HTMLElement
 */
const parseElement = (element: HTMLElement | string): HTMLElement => {
  if (element instanceof HTMLElement) {
    return element;
  }

  const parent = document.createElement('div');
  parent.innerHTML = element;

  return parent;
};

export {calculateElementSize, parseElement};
