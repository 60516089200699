import {CustomGeometry} from '../geometry/CustomGeometry';

/**
 * ポリラインに太さをもたせたポリゴンを構築するクラス
 * 基本的にPolylineTriangulatorから使用されることを想定している
 */
class PolylinePolygonBuilder {
  private vertices: number[];
  private indices: number[];

  private leftIndex: number;
  private rightIndex: number;
  private previousLeftIndex: number;
  private previousRightIndex: number;

  /**
   * コンストラクタ
   */
  constructor() {
    this.vertices = [];
    this.indices = [];

    this.leftIndex = -1;
    this.rightIndex = -1;
    this.previousLeftIndex = -1;
    this.previousRightIndex = -1;
  }

  /**
   * 左側に点を追加する、面は作成しない
   * @param x 追加する点のx座標
   * @param y 追加する点のy座標
   * @returns {void}
   */
  growLeft(x: number, y: number): void {
    this.addLeftPoint(x, y);
    if (this.previousLeftIndex !== -1 && this.rightIndex !== -1) {
      this.addFace(this.leftIndex, this.previousLeftIndex, this.rightIndex);
    }
  }

  /**
   * 左側に点を追加する、面は作成しない
   * @param x 追加する点のx座標
   * @param y 追加する点のy座標
   * @returns {void}
   */
  growRight(x: number, y: number): void {
    this.addRightPoint(x, y);
    if (this.previousRightIndex !== -1 && this.leftIndex !== -1) {
      this.addFace(this.rightIndex, this.previousRightIndex, this.leftIndex);
    }
  }

  /**
   * 組み立てているポリゴンを区切る
   * 点線の塗部分から休み部分にうつる際や、一筆書きが完了して次の線を描く際に使う
   * @returns {void}
   */
  enclose(): void {
    this.leftIndex = -1;
    this.rightIndex = -1;
    this.previousLeftIndex = -1;
    this.previousRightIndex = -1;
  }

  /**
   * 組み立ててきたポリゴンを返す
   * 組み立てた情報はリセットされる
   * @returns ポリゴン
   */
  build(): CustomGeometry {
    const geometry = new CustomGeometry(this.vertices, this.indices);
    this.vertices = [];
    this.indices = [];
    this.enclose();
    return geometry;
  }

  /**
   * 左側に点を追加する、面は作成しない
   * @param x 追加する点のx座標
   * @param y 追加する点のy座標
   * @returns {void}
   */
  private addLeftPoint(x: number, y: number): void {
    this.vertices.push(x, y, 0);
    this.previousLeftIndex = this.leftIndex;
    this.leftIndex = this.vertices.length / 3 - 1;
  }

  /**
   * 右側に点を追加する、面は作成しない
   * @param x 追加する点のx座標
   * @param y 追加する点のy座標
   * @returns {void}
   */
  private addRightPoint(x: number, y: number): void {
    this.vertices.push(x, y, 0);
    this.previousRightIndex = this.rightIndex;
    this.rightIndex = this.vertices.length / 3 - 1;
  }

  /**
   * 面を追加する
   * @param pointIndex1 1つ目の頂点番号
   * @param pointIndex2 2つ目の頂点番号
   * @param pointIndex3 3つ目の頂点番号
   * @returns {void}
   */
  private addFace(pointIndex1: number, pointIndex2: number, pointIndex3: number): void {
    if (pointIndex1 === -1 || pointIndex2 === -1 || pointIndex3 === -1) {
      return;
    }
    this.indices.push(pointIndex1, pointIndex2, pointIndex3);
  }
}

export {PolylinePolygonBuilder};
