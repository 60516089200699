import {Easing} from '../../../../../gaia/value/animation';

/**
 * y=x^3 の関数で徐々に減速
 */
class EaseOutCubic implements Easing {
  /** @override */
  calculateAnimationProgress(progress: number): number {
    const result = 1 - (1 - progress) ** 3;
    return result;
  }
}

export {EaseOutCubic};
