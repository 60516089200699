import {IndoorCondition} from '../../../../../gaia/value/IndoorCondition';
import {ArrayList} from '../../../../common/collection/ArrayList';
import {LRUCache} from '../../../../common/collection/LRUCache';
import {Ray3} from '../../../../common/math/Ray3';
import {getDevicePixelRatio} from '../../../../common/util/Device';
import {Camera} from '../../../../engine/camera/Camera';
import {TexturePlaneGeometry} from '../../../../engine/geometry/TexturePlaneGeometry';
import {TexturePlaneUVCoordinate} from '../../../../engine/geometry/TexturePlaneUVCoordinate';
import {TextureMapping} from '../../../../engine/program/TextureMapping';
import {GaiaContext} from '../../../GaiaContext';
import {aspectToFogDistanceRatio} from '../../../layer/FogObjectLayer';
import {IndoorBackgroundPlaneLayer} from '../../../layer/IndoorBackgroundPlaneLayer';
import {IndoorTileLoader} from '../../../loader/IndoorTileLoader';
import {IndoorTileParameter} from '../../../loader/param/IndoorTileParameter';
import {MapStatus} from '../../../models/MapStatus';
import {PaletteParameter} from '../../../models/PaletteParameter';
import {TileNumber} from '../../../models/TileNumber';
import {calculateWorldCoordinate} from '../../../utils/MapUtil';
import {IndoorAreaHelper} from '../../helper/IndoorAreaHelper';
import {TileRenderKitMapping} from '../MapRenderKitController';
import {AbstractTileRenderKit} from './AbstractTileRenderKit';

const TILE_LAYER_NAME_INDOOR = 'indoor';
const FLOOR_GROUND = 'ground';

/**
 * 屋内地図タイル制御マネージャー
 */
class IndoorTileRenderKit extends AbstractTileRenderKit {
  private backgroundLayer: IndoorBackgroundPlaneLayer;

  private indoorTileLoader: IndoorTileLoader;
  private indoorAreaHelper: IndoorAreaHelper;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private readonly tileParameterPool: LRUCache<TileNumber, IndoorTileParameter>;
  private palette: PaletteParameter;
  private condition?: IndoorCondition;

  private previousNotifiedFloorList: string[];
  private currentFloor: string;

  private visibleAreaIdList: number[] | null;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param camera カメラ
   * @param backgroundLayer 背景レイヤ
   */
  constructor(context: GaiaContext, camera: Camera, backgroundLayer: IndoorBackgroundPlaneLayer) {
    super(context, camera, false, TILE_LAYER_NAME_INDOOR);
    this.backgroundLayer = backgroundLayer;

    this.tileParameterPool = new LRUCache<TileNumber, IndoorTileParameter>(100);
    this.palette = context.getMapStatus().palette;

    this.previousNotifiedFloorList = [];
    this.currentFloor = FLOOR_GROUND;
    this.visibleAreaIdList = null;

    this.indoorTileLoader = new IndoorTileLoader(context, () => {
      if (this.status && this.tileList) {
        this.updateDrawObjects(this.status, this.tileList);
      }
    });

    this.indoorAreaHelper = new IndoorAreaHelper();

    context.getGaIAConfiguration().then((config) => {
      this.isEnable = config.features.indoor;
      if (this.isEnable) {
        this.executeLoader();
      } else {
        this.clearCache();
      }
    });
  }

  /**
   * 屋内地図取得条件の設定
   * @param condition IndoorCondition
   * @returns {void}
   */
  setIndoorCondition(condition?: IndoorCondition): void {
    if (!condition && this.condition && this.previousNotifiedFloorList.length > 0) {
      // 屋内表示がオンからオフに変更された場合は、最後に空配列でコールバックを実行
      const floorList: string[] = [];
      this.condition.onFloorListChangedCallback(floorList);
      this.previousNotifiedFloorList = floorList;

      this.currentFloor = FLOOR_GROUND;
      this.backgroundLayer.setVisible(false);
    }

    this.condition = condition;
    if (condition) {
      this.backgroundLayer.setBackgroundColor(condition?.backgroundColor);
      this.indoorAreaHelper.setMetadata(condition.metadata);
    }
    this.layer.updateTiles(new Map());
    this.indoorTileLoader.clear();
    this.tileParameterPool.clear();
  }

  /**
   * 屋内地図のフロアを設定
   * @param floor フロア
   * @returns {void}
   */
  setIndoorFloor(floor: string): void {
    if (!this.condition) {
      return;
    }

    if (floor === this.currentFloor) {
      return;
    }

    if (floor !== FLOOR_GROUND && !this.previousNotifiedFloorList.includes(floor)) {
      return;
    }

    this.backgroundLayer.setVisible(floor !== FLOOR_GROUND);
    this.currentFloor = floor;

    this.clearCache();
  }

  /**
   * 表示するエリアIDのリストを設定する
   * @param visibleAreaIdList 表示するエリアIDのリスト
   * @returns {void}
   */
  setVisibleAreaIdList(visibleAreaIdList: number[]): void {
    this.indoorTileLoader.setVisibleAreaIdList(visibleAreaIdList);
    this.clearCache();
  }

  /**
   * 表示するエリアIDのリストをクリアする（すべてのエリアを表示するようにする）
   * @returns {void}
   */
  clearVisibleAreaIdList(): void {
    this.indoorTileLoader.clearVisibleAreaIdList();
    this.clearCache();
  }

  /** @override */
  get identicalName(): keyof TileRenderKitMapping {
    return TILE_LAYER_NAME_INDOOR;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.isEnable) {
      return;
    }
    if (!this.condition) {
      return;
    }
    if (!this.palette.equals(mapStatus.palette)) {
      this.clearCache();
      this.palette = mapStatus.palette;
    }

    this.status = mapStatus;
    this.tileList = tileList;

    this.backgroundLayer.update(mapStatus);

    this.updateForLoader(mapStatus, tileList);
    this.updateForCallback();
  }

  /**
   * ローダー周りの更新処理
   * @param mapStatus 地図状態
   * @param tileList タイルリスト
   * @returns {void}
   */
  private updateForLoader(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.condition || this.currentFloor === FLOOR_GROUND) {
      return;
    }

    // メタデータの各エリアのバウンディングボックスに交差するタイルのみを通信で取得する
    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    const collidedTileList: ArrayList<TileNumber> = tileList.filter((tileNumber: TileNumber) => {
      // メタデータと交差しているタイルの東西南北のタイルも取得対象にすることで、
      // 注記だけ隣のタイルに描画される場合にも対応できるようにする
      const roundTileNumbers = [
        tileNumber,
        tileNumber.north(),
        tileNumber.south(),
        tileNumber.west(),
        tileNumber.east(),
      ];

      for (const tile of roundTileNumbers) {
        if (!tile) {
          continue;
        }

        const latLng = tileNumber.centerLocation;
        const position = calculateWorldCoordinate(latLng);
        const distance = position._subtract(centerPosition).magnitude();
        const distanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
        if (distance > cameraDistance * distanceRatio) {
          return false;
        }

        const boudingBox = tile.calculateLocationBoundingBox();
        const isIntersects = this.indoorAreaHelper.isIntersects(boudingBox);
        if (isIntersects) {
          return true;
        }
      }
      return false;
    });

    this.indoorTileLoader.jumpUpCacheSize(collidedTileList.size() * 2);
    this.tileParameterPool.jumpUpSize(collidedTileList.size() * 2);

    const requestTiles: IndoorTileParameter[] = [];
    // 生成済みテクスチャからLayerに描画物を渡す
    const tileTextureMap: Map<TileNumber, TextureMapping> = new Map();
    for (const tileNumber of collidedTileList) {
      const tileParam = this.getTileParameter(tileNumber, this.condition);
      const tex = this.indoorTileLoader.getTile(tileParam);
      if (tex) {
        const geometry = TexturePlaneGeometry.create(1, 1, TexturePlaneUVCoordinate.defaultUVCoordinate());
        tileTextureMap.set(tileNumber, new TextureMapping(geometry, tex.image));
      } else {
        requestTiles.push(tileParam);
      }
    }

    this.layer.updateTiles(tileTextureMap);

    // この時点ですべての必要なテクスチャがLayerに追加されていれば終了
    if (requestTiles.length === 0) {
      return;
    }
    // loaderにリクエストキュー追加
    this.indoorTileLoader.addRequestQueue(requestTiles);
  }

  /**
   * コールバック周りの更新処理
   * @returns {void}
   */
  private updateForCallback(): void {
    // 地図中心に屋内データがあるかチェック
    if (!this.condition) {
      return;
    }
    const mapStatus = this.context.getMapStatus();
    const worldCenter = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraWorldPosition = worldCenter._add(this.camera.position);

    const ray = new Ray3(cameraWorldPosition, worldCenter._subtract(cameraWorldPosition));
    const floorList = this.indoorAreaHelper.findCollidedFloorList(ray);

    if (JSON.stringify(floorList) === JSON.stringify(this.previousNotifiedFloorList)) {
      return;
    }

    this.previousNotifiedFloorList = floorList;
    this.condition.onFloorListChangedCallback(floorList.slice());
  }

  /** @override */
  executeLoader(): void {
    this.indoorTileLoader.executeRequest();
  }

  /**
   * IndoorTileParameterを生成
   * @param tileNumber TileNumber
   * @param condition IndoorCondition
   * @returns IndoorTileParameter
   */
  private getTileParameter(tileNumber: TileNumber, condition: IndoorCondition): IndoorTileParameter {
    if (this.tileParameterPool.has(tileNumber)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.tileParameterPool.get(tileNumber)!;
    }

    const {x, y, z} = tileNumber;
    const tileSize = getDevicePixelRatio() > 1 ? 512 : 256;

    const param = new IndoorTileParameter(x, y, z, this.palette, condition, this.currentFloor, tileSize);
    this.tileParameterPool.add(tileNumber, param);
    return param;
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  clearCache(): void {
    this.layer.updateTiles(new Map());
    this.tileParameterPool.clear();
    this.indoorTileLoader.clear();
  }

  /** @override */
  onDestroy(): void {
    this.backgroundLayer.destroy();
    this.indoorTileLoader.destroy();
  }
}

export {IndoorTileRenderKit, FLOOR_GROUND};
