/**
 * 処理のスロットリング
 * @param context 実行コンテキスト
 * @param fn 実行関数
 * @param delay スロットリング間隔
 * @returns スロットリング実行される関数
 */
const throttle = <T extends Function>(context: unknown, fn: T, delay: number): T => {
  let timerId: number;
  let lastExecTime = 0;
  return (((...args: unknown[]) => {
    const elapsedTime = performance.now() - lastExecTime;
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
    const execute = () => {
      fn.apply(context, args);
      lastExecTime = performance.now();
    };
    if (!timerId) {
      execute();
    }
    if (timerId) {
      window.clearTimeout(timerId);
      if (elapsedTime > delay) {
        execute();
      } else {
        timerId = window.setTimeout(execute, delay);
      }
    }
  }) as unknown) as T;
};

export {throttle};
