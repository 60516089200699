import {AltitudeParameter} from '../param/AltitudeParameter';
import {AbstractStoreHandler} from './AbstractStoreHandler';
import {AltitudeMainDBData} from './AnnotationDBUtil';

/**
 * 標高データ用IndexedDBオブジェクトストアハンドラ
 */
class AltitudeStoreHander extends AbstractStoreHandler<AltitudeParameter, AltitudeMainDBData> {
  /**
   * コンストラクタ
   * @param dbName DB名
   * @param storeName オブジェクトストア名
   * @param recordMax レコード上限
   */
  constructor(dbName: string, storeName: string, recordMax = 3000) {
    super(dbName, storeName, recordMax);
  }

  /** @override */
  createCacheKey(parameter: AltitudeParameter): string {
    return parameter.getCacheKey();
  }
}

export {AltitudeStoreHander};
