import {GltfId} from '../../GltfReader';

/**
 * glTFのシーン
 */
class GltfScene {
  readonly nodes: GltfId[];

  /**
   * コンストラクタ
   * @param nodes nodes
   */
  constructor(nodes: GltfId[]) {
    this.nodes = nodes;
  }
}

export {GltfScene};
