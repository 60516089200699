import {Layer} from '../../engine/layer/Layer';
import {mat4} from 'gl-matrix';
import {GaiaContext} from '../GaiaContext';
import {calculateWorldCoordinate} from '../utils/MapUtil';
import {MapStatus} from '../models/MapStatus';
import {GLMarkerGroupObject} from '../render/objects/GLMarkerGroupObject';
import {Collision} from '../../engine/collision/Collision';
import {Ray3} from '../../common/math/Ray3';

const LAYER_NAME_GL_MARKER = 'marker';

/**
 * GLマーカーレイヤー
 */
class GLMarkerLayer implements Layer {
  private context: GaiaContext;
  private markerGroupObjectList: GLMarkerGroupObject[];

  /**
   * コンストラクタ
   * @param context GaiaContext
   */
  constructor(context: GaiaContext) {
    this.context = context;
    this.markerGroupObjectList = [];
  }

  /**
   * GLMarkerGroupObjectの追加
   * @param markerGroupObject GLMarkerGroupObject
   * @returns {void}
   */
  addMarkerGroupObject(markerGroupObject: GLMarkerGroupObject): void {
    this.markerGroupObjectList.push(markerGroupObject);
  }

  /**
   * GLMarkerGroupObjectの削除
   * @param markerGroupObject GLMarkerGroupObject
   * @returns {void}
   */
  removeMarkerGroupObject(markerGroupObject: GLMarkerGroupObject): void {
    const index = this.markerGroupObjectList.indexOf(markerGroupObject);
    if (index > -1) {
      this.markerGroupObjectList.splice(index, 1);
    }
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    for (const markerGroup of this.markerGroupObjectList) {
      markerGroup.updateMarkerGroup(cameraTargetPosition, mapStatus);
    }
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    for (const marker of this.markerGroupObjectList) {
      marker.update(viewMatrix, projectionMatrix);
      marker.draw();
    }

    return true;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_GL_MARKER;
  }

  /** @override */
  destroy(): void {
    for (const group of this.markerGroupObjectList) {
      group.destroy();
    }
    this.markerGroupObjectList = [];
  }

  /** @override */
  getCollisions(ray: Ray3): Collision[] {
    const collisions: Collision[] = [];

    for (const group of this.markerGroupObjectList.values()) {
      const collidedMarkers = group.getCollidedMarker(ray);
      if (collidedMarkers.length !== 0) {
        const markerObject = collidedMarkers[collidedMarkers.length - 1];
        const collision = new Collision(group, markerObject);
        collisions.push(collision);
      }
    }

    return collisions;
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {GLMarkerLayer, LAYER_NAME_GL_MARKER};
