import {ArrayList} from '../../../../common/collection/ArrayList';
import {LRUCache} from '../../../../common/collection/LRUCache';
import {AnnotationIconHeader, IconAnnotationInfo, PaletteInfo} from '../../../../common/infra/response/AnnotationInfo';
import {ZoomLevelFixFunc} from '../../../../common/types';
import {Camera} from '../../../../engine/camera/Camera';
import {GaiaContext} from '../../../GaiaContext';
import {AnnotationLoader} from '../../../loader/AnnotationLoader';
import {MapStatus} from '../../../models/MapStatus';
import {TextAnnotationData} from '../../../models/annotation/TextAnnotationData';
import {TileNumber} from '../../../models/TileNumber';
import {MapRenderKitController, TileRenderKitMapping} from '../MapRenderKitController';
import {PaletteParameter} from '../../../models/PaletteParameter';
import {
  Language,
  MapIconClickListener,
  MapIconClickListenerOptions,
  MapIconConditionPaletteConfig,
  MapIconMouseEnterListener,
  TileSize,
} from '../../../../../gaia/types';
import {LatLng, Size, ZoomRange} from '../../../../../gaia/value';
import {Vector2} from '../../../../common/math/Vector2';
import {AnnotationMetaParameter} from '../../../loader/param/AnnotationMetaParameter';
import {getDevicePixelRatio} from '../../../../common/util/Device';
import {TexturePlaneUVCoordinate} from '../../../../engine/geometry/TexturePlaneUVCoordinate';
import {calculateWorldCoordinate, getZoomLevelFixFunc} from '../../../utils/MapUtil';
import {MapIconMainParameter} from '../../../loader/param/MapIconMainParameter';
import {MapIconMapSpot} from '../../../../common/infra/response/MapIconMainInfo';
import {transDegreeToMillisec} from '../../../../../gaia/util';
import {Ray3} from '../../../../common/math/Ray3';
import {Collision} from '../../../../engine/collision/Collision';
import {MapIconData} from '../../../models/annotation/MapIconData';
import {MapIconLayer} from '../../../layer/MapIconLayer';
import {MapIconCondition} from '../../../../../gaia/value/MapIconCondition';

export type NoteMapIconData = TextAnnotationData | MapIconData;

const LAYER_NAME_ANNOTATION = 'annotation';

/**
 * 注記オブジェクトを扱う描画キット
 */
class MapIconObjectRenderKit {
  private context: GaiaContext;
  private camera: Camera;
  private visible: boolean;

  private annotationLoader: AnnotationLoader;
  private layer: MapIconLayer;

  private iconHeader?: AnnotationIconHeader;

  /** 生成済データ */
  private noteDataListCache: LRUCache<MapIconMainParameter, NoteMapIconData[]>;

  private currentPalette: PaletteParameter;
  private readonly tileSize: TileSize;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private previousCenter: LatLng;
  private previousZoomLevel: number;
  private readonly fixIntZoomLevel: ZoomLevelFixFunc;

  readonly getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  private condition?: MapIconCondition;
  private totalZoomRange?: ZoomRange;
  private iconCollisionMargin: Vector2;

  /**
   * NTJコードからカテゴリタグへのマップ
   */
  private ntjCodeMapping: Map<string, string[]>;

  /**
   * カテゴリタグからパレット設定へのマップ
   */
  private paletteConfigMapping: Map<string, MapIconConditionPaletteConfig[]>;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKitCtl MapRenderKitController
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    this.context = context;
    this.camera = camera;

    this.noteDataListCache = new LRUCache(100);

    this.currentPalette = context.getMapStatus().palette;
    this.tileSize = getDevicePixelRatio() > 1 ? 512 : 256;

    this.annotationLoader = new AnnotationLoader(
      context,
      () => {
        if (this.status && this.tileList) {
          this.updateDrawObjects(this.status, this.tileList);
        }
      },
      () => {
        if (this.status && this.tileList) {
          this.updateDrawObjects(this.status, this.tileList);
        }
      }
    );

    this.layer = new MapIconLayer(context, this, camera);
    this.ntjCodeMapping = new Map();
    this.paletteConfigMapping = new Map();

    const mapIconOptions = context.getMapInitOptions().mapIconOptions;
    if (mapIconOptions) {
      this.visible = true;
      const condition = new MapIconCondition(mapIconOptions);
      this.setMapIconCondition(condition);
    } else {
      this.visible = false;
    }

    this.layer.setVisible(this.visible);

    this.previousCenter = context.getMapStatus().centerLocation;
    this.previousZoomLevel = this.context.getMapStatus().zoomLevel;
    this.fixIntZoomLevel = getZoomLevelFixFunc(this.context);

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKitCtl.getAllCollisions(ray);

    this.iconCollisionMargin = Vector2.zero();
  }

  /**
   * RenderKit特定用キー
   */
  get identicalName(): keyof TileRenderKitMapping {
    return LAYER_NAME_ANNOTATION;
  }

  /**
   * テキスト・アイコン注記レイヤーを取得
   * @returns テキスト・アイコン注記レイヤー
   */
  getLayer(): MapIconLayer {
    return this.layer;
  }

  /**
   * 地図アイコンクリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setMapIconClickListener(listener: MapIconClickListener, options?: MapIconClickListenerOptions): void {
    this.layer.setMapIconClickListener(listener, options);
  }

  /**
   * 地図アイコンクリックリスナーの削除
   * @returns {void}
   */
  removeMapIconClickListener(): void {
    this.layer.removeMapIconClickListener();
  }

  /**
   * 地図アイコンマウスエンターリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapIconMouseEnterListener(listener: MapIconMouseEnterListener): void {
    this.layer.setMapIconMouseEnterListener(listener);
  }

  /**
   * 地図アイコンマウスエンターリスナーの削除
   * @returns {void}
   */
  removeMapIconMouseEnterListener(): void {
    this.layer.removeMapIconMouseEnterListener();
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  private clear(): void {
    this.iconHeader = undefined;
    this.noteDataListCache.clear();
    this.annotationLoader.clear();
    this.layer.clear();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.layer.destroy();
    this.annotationLoader.destroy();
  }

  /**
   * 表示状態の設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
    this.layer.setVisible(visible);
  }

  /**
   * ローダーのリクエスト実行
   * @returns {void}
   */
  executeLoader(): void {
    this.annotationLoader.executeRequest();
  }

  /**
   * 描画物更新
   * @param mapStatus 地図状態
   * @param tileList 描画対象タイルリスト
   * @returns {void}
   */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.visible) {
      return;
    }

    if (!this.condition) {
      return;
    }

    if (!this.totalZoomRange) {
      return;
    }

    if (!this.totalZoomRange.isInRange(mapStatus.zoomLevel)) {
      this.layer.setVisible(false);
      return;
    }

    this.layer.setVisible(true);

    this.status = mapStatus;
    this.tileList = tileList;
    this.annotationLoader.jumpUpMapIconMainCacheSize(tileList.size() * 2);
    this.noteDataListCache.jumpUpSize(tileList.size() * 2);

    // paletteが変更されていたら再びメタリクエストから行う
    if (!this.currentPalette.equals(mapStatus.palette)) {
      this.clear();
      this.currentPalette = mapStatus.palette;
    }

    // シリアルチェック
    const metaParam = new AnnotationMetaParameter(this.tileSize, this.currentPalette);
    const meta = this.annotationLoader.getMetaInfo(metaParam);
    if (!meta) {
      this.annotationLoader.setMetaParameter(metaParam);
    } else if (!this.iconHeader) {
      this.iconHeader = meta.iconHeader;
    }

    // ズームレベル整数値・中心緯度経度の変化量が閾値を超えていたらリクエストキュークリア
    const isChangeCenterLat = Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) > 0.1;
    const isChangeCenterLng = Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) > 0.1;
    const isChangeZoomLevel =
      this.fixIntZoomLevel(mapStatus.zoomLevel) !== this.fixIntZoomLevel(this.previousZoomLevel);
    if (isChangeZoomLevel || isChangeCenterLat || isChangeCenterLng) {
      this.annotationLoader.clearRequestQueue();
    }
    this.previousZoomLevel = mapStatus.zoomLevel;
    this.previousCenter = mapStatus.centerLocation;

    // メインリクエスト
    const requestTiles: MapIconMainParameter[] = [];
    const noteDataList: Map<MapIconMainParameter, NoteMapIconData[]> = new Map();
    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    for (const tileNumber of tileList) {
      const latLng = tileNumber.centerLocation;
      const position = calculateWorldCoordinate(latLng);
      const distance = position._subtract(centerPosition).magnitude();
      if (distance > cameraDistance * 1.2) {
        continue;
      }

      const mapIconMainParam = new MapIconMainParameter(
        tileNumber.x,
        tileNumber.y,
        tileNumber.z,
        this.tileSize,
        this.currentPalette
      );

      const noteDataListCache = this.noteDataListCache.get(mapIconMainParam);
      if (noteDataListCache) {
        noteDataList.set(mapIconMainParam, noteDataListCache);
      } else {
        const mainInfo = this.annotationLoader.getMapIconMainInfo(mapIconMainParam);
        if (!mainInfo) {
          requestTiles.push(mapIconMainParam);
        } else {
          for (const mapSpot of mainInfo.mapspot) {
            if (!this.ntjCodeMapping.has(mapSpot.ntjCode)) {
              this.ntjCodeMapping.set(mapSpot.ntjCode, mapSpot.tag);
            }
          }

          const paletteInfo: PaletteInfo = {};
          for (const mapSpot of mainInfo.mapspot) {
            const ntjCodeHex = mapSpot.ntjCode;
            const ntjCodeDecimal = `${parseInt(ntjCodeHex, 16)}`;
            const categoryTags = this.ntjCodeMapping.get(ntjCodeHex);
            if (!categoryTags) {
              continue;
            }
            for (const categoryTag of categoryTags) {
              const iconPaletteConfigList = this.paletteConfigMapping.get(categoryTag);
              if (iconPaletteConfigList) {
                for (const iconPaletteConfig of iconPaletteConfigList) {
                  if (iconPaletteConfig.zoomRange.isInRange(mapStatus.zoomLevel)) {
                    paletteInfo[ntjCodeDecimal] = {
                      icon: {
                        visible: true,
                        force: false,
                        size: iconPaletteConfig.size ?? 100,
                        name: iconPaletteConfig.name ?? ntjCodeHex,
                      },
                    };
                    break;
                  }
                }
                break;
              }
            }
          }

          const createdNoteList = this.createNoteDataList(
            this.currentPalette.language,
            mapIconMainParam.z,
            mainInfo.mapspot,
            paletteInfo
          );
          noteDataList.set(mapIconMainParam, createdNoteList);
          if (createdNoteList.length > 0) {
            this.noteDataListCache.add(mapIconMainParam, createdNoteList);
          }
        }
      }
    }

    this.layer.update(mapStatus, noteDataList);

    // layerへのアイコン受け渡し
    if (!this.layer.isSetIconTexture) {
      const icont = this.annotationLoader.getIconTCache(metaParam);
      if (icont) {
        this.layer.setIconTexture(icont);
      }
    }

    if (requestTiles.length === 0) {
      return;
    }
    this.annotationLoader.addMapIconMainRequestQueue(requestTiles);
  }

  /**
   * NoteAnnotationDataの生成
   * @param lang 言語
   * @param zoom ズームレベル
   * @param mapSpotList MapIconMapSpotリスト
   * @param paletteInfo PaletteInfo
   * @returns NoteAnnotationDataリスト
   */
  private createNoteDataList(
    lang: Language,
    zoom: number,
    mapSpotList: MapIconMapSpot[],
    paletteInfo: PaletteInfo
  ): NoteMapIconData[] {
    const noteList: NoteMapIconData[] = [];
    for (const mapSpot of mapSpotList) {
      const ntjCode = parseInt(mapSpot.ntjCode, 16);
      if (Number.isNaN(ntjCode)) {
        continue;
      }

      const iconPaletteConfig = paletteInfo[ntjCode] ? paletteInfo[ntjCode].icon : undefined;
      if (!this.iconHeader || !iconPaletteConfig) {
        continue;
      }
      const {x, y} = this.iconHeader.mapping[iconPaletteConfig.name];
      const {iconWidth: width, iconHeight: height} = this.iconHeader;
      const overallWidth = this.iconHeader.chainX * width;
      const overallHeight = this.iconHeader.chainY * height;
      const uvTop = (y * height + 1) / overallHeight;
      const uvLeft = (x * width + 1) / overallWidth;
      const uvBottom = ((y + 1) * height - 1) / overallHeight;
      const uvRight = ((x + 1) * width - 1) / overallWidth;
      const uv = new TexturePlaneUVCoordinate(
        new Vector2(uvLeft, uvTop),
        new Vector2(uvLeft, uvBottom),
        new Vector2(uvRight, uvTop),
        new Vector2(uvRight, uvBottom)
      );
      const iconSize = new Size(width, height);
      const iconAnnotationInfo: IconAnnotationInfo = {
        angle: 0,
        isHorizon: false,
        type: 'icon',
        priority: mapSpot.priority,
        ntjCode,
        lat: transDegreeToMillisec(mapSpot.lat),
        lon: transDegreeToMillisec(mapSpot.lon),
      };
      const iconAnnotationData = MapIconData.createMapIconData(
        lang,
        this.tileSize,
        iconAnnotationInfo,
        iconPaletteConfig,
        iconSize,
        uv,
        mapSpot.spotId,
        mapSpot.name,
        mapSpot.address,
        mapSpot.postalCode,
        mapSpot.phone,
        mapSpot.provId
      );
      if (iconAnnotationData) {
        noteList.push(iconAnnotationData);
      }
    }
    return noteList;
  }

  /**
   * 地図アイコン取得条件の設定
   * @param condition MapIconCondition
   * @returns {void}
   */
  setMapIconCondition(condition?: MapIconCondition): void {
    this.condition = condition;
    if (condition) {
      if (condition.icon?.collisionMargin) {
        const margin = condition.icon.collisionMargin;
        this.iconCollisionMargin = new Vector2(margin.x, margin.y);
        this.layer.setMapIconCollisionMargin(this.iconCollisionMargin);
      }
      this.setVisible(true);
      if (condition.product) {
        this.annotationLoader.setMapIconProduct(condition.product);
      }
      const tagList = Object.keys(condition.tags);
      this.annotationLoader.setMapIconTags(tagList);
      this.paletteConfigMapping.clear();
      let minZoomLevel = 24;
      let maxZoomLevel = -1;
      for (const [categoryTag, paletteConfigList] of Object.entries(condition.tags)) {
        this.paletteConfigMapping.set(categoryTag, paletteConfigList);
        for (const paletteConfig of paletteConfigList) {
          if (minZoomLevel > paletteConfig.zoomRange.min) {
            minZoomLevel = paletteConfig.zoomRange.min;
          }
          if (maxZoomLevel < paletteConfig.zoomRange.max) {
            maxZoomLevel = paletteConfig.zoomRange.max;
          }
        }
      }
      this.totalZoomRange = new ZoomRange(minZoomLevel, maxZoomLevel);
    } else {
      this.setVisible(false);
    }
  }
}

export {MapIconObjectRenderKit};
