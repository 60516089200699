/* eslint-disable @typescript-eslint/no-explicit-any */
import {PaletteInfo} from './AnnotationInfo';

export type MapIconMapSpotParkingInfo = {
  hasParking?: boolean;
};

export type MapIconMapSpotFloorInfo = {
  onGround?: boolean;
};

export type MapIconMapSpot = {
  priority: number;
  ntjCode: string;
  tag: string[];
  provId: string;
  spotId: string;
  name: string;
  lat: number;
  lon: number;
  address: string;
  postalCode: string;
  phone: string;
  parking?: MapIconMapSpotParkingInfo;
  floor?: MapIconMapSpotFloorInfo;
  availableLargeCar?: boolean;
};

export type MapIconMainInfo = {
  lang: string;
  mapspot: MapIconMapSpot[];
  palette: PaletteInfo;
};

/**
 * MapSpotMainInfoのType Guard
 * @param item 判定対象
 * @returns MapSpotMainInfo型か
 */
export const isMapIconMainInfo = (item: any): item is MapIconMainInfo => {
  return item !== undefined && item.lang !== undefined && item.mapspot !== undefined && item.palette !== undefined;
};
