import {Point, Size} from '../../../../gaia/value';
import {PaletteAnchor} from '../../../common/infra/response/AnnotationInfo';
import {Ray3} from '../../../common/math/Ray3';
import {Rect3} from '../../../common/math/Rect3';
import {RectCollider} from '../../../common/math/RectCollider';
import {Vector3, ZERO_VECTOR} from '../../../common/math/Vector3';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {AbstractAnnotationData} from '../../models/annotation/AbstractAnnotationData';
import {calculatePixelToUnit} from '../../utils/MapUtil';

/**
 * 注記オブジェクトのクラス
 */
class AnnotationObject<T extends AbstractAnnotationData> {
  readonly data: T;
  readonly uv: TexturePlaneUVCoordinate;
  readonly position: Vector3;
  readonly anchor: PaletteAnchor;
  readonly offset: Point;
  readonly angle: number;
  readonly drawArea: Rect3;
  /** 注記同士の当たり判定に用いるコライダー */
  readonly cullingCollider: RectCollider;
  private clientSize: Size;
  private _worldSize: Size;
  private _isVisible: boolean;
  /** マウスやレイキャストとのコライダー */
  private _collider: RectCollider;

  /**
   * コンストラクタ
   * @param data AbstractAnnotationData
   * @param position 位置
   * @param clientSize 表示サイズ
   * @param uv UV座標
   * @param anchor アンカー
   * @param offset オフセット
   * @param angle 角度
   */
  constructor(
    data: T,
    position: Vector3,
    clientSize: Size,
    uv: TexturePlaneUVCoordinate,
    anchor: PaletteAnchor = 'center',
    offset: Point = new Point(0, 0),
    angle = 0
  ) {
    this.data = data;
    this.uv = uv;
    this.position = position;
    this.anchor = anchor;
    this.offset = offset;
    this.angle = angle > 360 ? 0 : angle;
    this.clientSize = clientSize;
    this._worldSize = new Size(0, 0);
    this._isVisible = false;
    this._collider = new RectCollider(ZERO_VECTOR, ZERO_VECTOR, ZERO_VECTOR);
    this.drawArea = new Rect3(ZERO_VECTOR, ZERO_VECTOR, ZERO_VECTOR);
    this.cullingCollider = new RectCollider(ZERO_VECTOR, ZERO_VECTOR, ZERO_VECTOR);
  }

  /**
   * GL空間内でのサイズ
   */
  get worldSize(): Size {
    return this._worldSize;
  }

  /**
   * 表示状態の取得
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this._isVisible = visible;
  }

  /**
   * 表示状態
   */
  get isVisible(): boolean {
    return this._isVisible;
  }

  /**
   * コライダの更新
   * @param collider RectCollider
   * @returns {void}
   */
  setCollider(collider: RectCollider): void {
    this._collider = collider;
  }

  /**
   * 注記同士の当たり判定に使うコライダーの更新
   * @param collider RectCollider
   * @returns {void}
   */
  setCullingCollider(collider: RectCollider): void {
    this.cullingCollider.set(collider);
  }

  /**
   * コライダ
   */
  get collider(): RectCollider {
    return this._collider;
  }

  /**
   * GL空間内サイズの更新
   * @param zoom ズームレベル
   * @returns {void}
   */
  updateSize(zoom: number): void {
    this._worldSize = new Size(
      calculatePixelToUnit(zoom) * this.clientSize.height,
      calculatePixelToUnit(zoom) * this.clientSize.width
    );
  }

  /**
   * 衝突判定
   * @param ray Ray3
   * @returns rayが衝突したか
   */
  isCollided(ray: Ray3): boolean {
    return this._isVisible && this._collider.isCollided(ray);
  }
}

export {AnnotationObject};
