import {TrainRouteLineStyle} from '../types';

/**
 * 鉄道路線の見た目情報
 */
class TrainRouteAppearance {
  /** 描画スタイル */
  readonly style: TrainRouteLineStyle;
  /** 表示状態 */
  readonly visible: boolean;

  /**
   * コンストラクタ
   * @param style 描画スタイル
   * @param visible 表示状態
   */
  constructor(style: TrainRouteLineStyle, visible: boolean) {
    this.style = style;
    this.visible = visible;
  }
}

export {TrainRouteAppearance};
