import {Object3D} from '../object/Object3D';

/**
 * 衝突情報クラス
 */
class Collision {
  private readonly object3D: Object3D;
  private readonly additional?: unknown;

  /**
   * コンストラクタ
   * @param object3D オブジェクト
   * @param additional 付加情報
   */
  constructor(object3D: Object3D, additional?: unknown) {
    this.object3D = object3D;
    this.additional = additional;
  }

  /**
   * オブジェクトを取得
   * @returns オブジェクト
   */
  getObject3D(): Object3D {
    return this.object3D;
  }

  /**
   * 付加情報を取得
   * @returns 付加情報
   */
  getAdditional(): unknown {
    return this.additional;
  }
}

export {Collision};
