/** DOMに付与しているクラス名一覧 */
const GaIAClassName = Object.freeze({
  Base: 'gia-base-element',
  Parts: {
    Copyright: 'gia-parts-copyright-text',
    Scale: {
      Base: 'gia-parts-scale',
      Measure: 'gia-parts-scale__measure',
      Text: 'gia-parts-scale__text',
    },
  },
  Layer: {
    Dom: 'gia-dom-layer',
  },
  Object: {
    Wrapper: 'gia-dom-object-wrapper',
    InfoWindow: {
      Base: 'gia-object-infowindow',
      Tail: 'gia-object-infowindow__tail',
      TailTop: 'gia-object-infowindow__tail--top',
      TailBottom: 'gia-object-infowindow__tail--bottom',
      TailLeft: 'gia-object-infowindow__tail--left',
      TailRight: 'gia-object-infowindow__tail--right',
      Close: 'gia-object-infowindow__close-btn',
    },
    Marker: {
      Base: 'gia-object-marker',
      Label: 'gia-object-marker__label',
    },
    ContextMenu: {
      Base: 'gia-object-context-menu',
      ListBase: 'gia-object-context-menu__base',
      ListItem: 'gia-object-context-menu__list',
      Link: 'gia-object-context-menu__link',
    },
  },
});

/** InfoWindow吹き出しのサイズ */
const INFO_WINDOW_TAIL_SIZE = 10;
/** InfoWindowの枠線色 */
const INFO_WINDOW_BORDER_COLOR = '#aaa';
/** InfoWindow背景色 */
const INFO_WINDOW_BACKGROUND_COLOR = '#fff';

// language=CSS
const styleSheet = `
/* Injected by GaIA */
.${GaIAClassName.Base} {
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: #0000;
  position: relative;
  overflow: hidden;
}

.${GaIAClassName.Parts.Copyright} {
  position: absolute;
  z-index: 5;
  padding: 2px 4px;
  right: 2px;
  bottom: 2px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 10px;
  line-height: 1.5;
}

.${GaIAClassName.Parts.Scale.Base} {
  position: absolute;
  z-index: 5;
  padding: 3px 6px;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 10px;
  left: 2px;
  bottom: 2px;
}

.${GaIAClassName.Parts.Scale.Measure} {
  display:inline-block;
  border-width: 0 1px 1px 1px;
  border-color: #000;
  border-style: solid;
  height: 4px;
  width: 50px;
  will-change: width;
}

.${GaIAClassName.Parts.Scale.Text} {
  display:inline-block;
  margin-left: 5px;
  color: #000000;
}

.${GaIAClassName.Layer.Dom} {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  overflow: visible;
}

.${GaIAClassName.Object.Wrapper} {
  user-select: text;
  position: absolute;
}

.${GaIAClassName.Object.InfoWindow.Base} {
  border: 1px solid ${INFO_WINDOW_BORDER_COLOR};
  border-radius: 3px;
  background: ${INFO_WINDOW_BACKGROUND_COLOR};
  padding: 8px;
  word-break: break-word;
  max-width: 250px;
  z-index: 2;
}

.${GaIAClassName.Object.InfoWindow.Tail} {
  height: ${INFO_WINDOW_TAIL_SIZE}px;
  width: ${INFO_WINDOW_TAIL_SIZE}px;
  position: absolute;
  background: ${INFO_WINDOW_BACKGROUND_COLOR};
  border-left: 1px solid ${INFO_WINDOW_BORDER_COLOR};
  border-bottom: 1px solid ${INFO_WINDOW_BORDER_COLOR};
}

.${GaIAClassName.Object.InfoWindow.TailTop} {
  transform: rotate(135deg);
  top: -6px;
  left: calc(50% - 6px);
}

.${GaIAClassName.Object.InfoWindow.TailBottom} {
  transform: rotate(-45deg);
  bottom: -6px;
  left: calc(50% - 6px);
}

.${GaIAClassName.Object.InfoWindow.TailLeft} {
  transform: rotate(45deg);
  left: -6px;
  top: calc(50% - 6px);
}

.${GaIAClassName.Object.InfoWindow.TailRight} {
  transform: rotate(-135deg);
  right: -6px;
  top: calc(50% - 6px);
}


.${GaIAClassName.Object.InfoWindow.Close} {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background-color: #0000;
}

.${GaIAClassName.Object.Marker.Base} {
  z-index: 1;
}

.${GaIAClassName.Object.Marker.Base} > img {
  position: absolute;
}

.${GaIAClassName.Object.Marker.Label} {
  display: inline-block;
  position: absolute;
  user-select: none;
  -webkit-user-select: none;
  transform: translate(0px, 0px);
}

.${GaIAClassName.Object.ContextMenu.Base} {
  top: 0;
  left: 0;
  z-index: 6;
}

.${GaIAClassName.Object.ContextMenu.ListBase} {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #aaa;
  box-shadow: 2px 2px 4px 0 #aaa;
  min-width: 200px;
}

.${GaIAClassName.Object.ContextMenu.ListItem} {
  background: #fff;
}

.${GaIAClassName.Object.ContextMenu.ListItem}:not(:last-child) {
  border-bottom: 1px solid #aaa;
}

.${GaIAClassName.Object.ContextMenu.Link} {
  padding: 0 10px;
  display:inline-block;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  line-height: 30px;
  text-decoration: none;
  color: #000;
}
`;

/**
 * GaIAで利用するCSS Styleをinjectする
 * @returns {void}
 */
const injectStyle = (): void => {
  const styleTag = document.createElement('style');
  styleTag.setAttribute('id', 'gaia-stylesheet');
  styleTag.innerHTML = styleSheet;
  document.head.appendChild(styleTag);
};

export {GaIAClassName, INFO_WINDOW_TAIL_SIZE, injectStyle};
