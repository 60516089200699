import {Vector3, ZERO_VECTOR} from '../../../common/math/Vector3';
import {Ray3} from '../../../common/math/Ray3';
import {RectCollider} from '../../../common/math/RectCollider';
import {GLMarkerEventMap} from '../../../../gaia/types';
import {GaIAEventListenerHelper} from '../../event/GaIAEventListenerHelper';
import {GaIAEventEmitter} from '../../../../gaia/value/interface/GaIAEventEmitter';
import {GLMarkerEvent} from '../../../../gaia/types';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {Vector2} from '../../../common/math/Vector2';

type GLMarkerExtendedEventMap = GLMarkerEventMap & {topleft_changed: GLMarkerEvent};

/**
 * GLマーカーオブジェクトのクラス
 */
class GLMarkerObject implements GaIAEventEmitter {
  private _position: Vector3;
  private _visible: boolean;
  private _collider: RectCollider;
  private _uv: TexturePlaneUVCoordinate;
  private _labelOptionsKey: string;
  private _zIndex: number;

  readonly helper: GaIAEventListenerHelper<GLMarkerExtendedEventMap>;

  /**
   * コンストラクタ
   * @param position 位置
   * @param labelOptionsKey GLMarkerLabelOptionsのKey
   * @param zIndex z-index
   * @param visible 表示状態
   */
  constructor(position: Vector3, labelOptionsKey: string, zIndex = 0, visible = true) {
    this._position = position;
    this._zIndex = zIndex;
    this._visible = visible;
    this._collider = new RectCollider(ZERO_VECTOR, ZERO_VECTOR, ZERO_VECTOR);
    this._uv = new TexturePlaneUVCoordinate(Vector2.zero(), Vector2.zero(), Vector2.zero(), Vector2.zero());

    this._labelOptionsKey = labelOptionsKey;
    this.helper = new GaIAEventListenerHelper<GLMarkerExtendedEventMap>();
  }

  /**
   * 位置を取得
   * @returns 位置
   */
  get position(): Vector3 {
    return this._position;
  }

  /**
   * 位置を設定
   * @param position 位置
   * @returns {void}
   */
  setPosition(position: Vector3): void {
    this._position = position;
  }

  /**
   * 表示状態を取得
   * @returns `true`:表示, `false`: 非表示
   */
  get isVisible(): boolean {
    return this._visible;
  }

  /**
   * 表示状態の設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this._visible = visible;
  }

  /**
   * z-indexを取得
   * @returns z-index
   */
  getZIndex(): number {
    return this._zIndex;
  }

  /**
   * コライダを取得
   * @returns コライダ
   */
  get collider(): RectCollider {
    return this._collider;
  }

  /**
   * コライダを設定
   * @param collider RectCollider
   * @returns {void}
   */
  setCollider(collider: RectCollider): void {
    this._collider = collider;
  }

  /**
   * UV座標を取得
   */
  get uv(): TexturePlaneUVCoordinate {
    return this._uv;
  }

  /**
   * UV座標を設定
   * @param uv TexturePlaneUVCoordinate
   * @returns {void}
   */
  setUv(uv: TexturePlaneUVCoordinate): void {
    this._uv = uv;
  }

  /**
   * ラベル用Keyを取得
   */
  get labelOptionsKey(): string {
    return this._labelOptionsKey;
  }

  /**
   * ラベル用Keyを設定
   * @param key ラベル用Key
   * @returns {void}
   */
  setLabelOptionsKey(key: string): void {
    this._labelOptionsKey = key;
  }

  /** @override */
  addEventListener<K extends keyof GLMarkerExtendedEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GLMarkerExtendedEventMap[K]) => any
  ): void {
    this.helper.addListener(eventName, func);
  }

  /** @override */
  removeEventListener<K extends keyof GLMarkerExtendedEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GLMarkerExtendedEventMap[K]) => any
  ): void {
    this.helper.removeListener(eventName, func);
  }

  /**
   * 衝突判定
   * @param ray Ray3
   * @returns rayが衝突したか
   */
  isCollided(ray: Ray3): boolean {
    return this._visible && this._collider.isCollided(ray);
  }

  /**
   * クリック操作が有効かどうか
   * @returns クリッカブルならtrue
   */
  isClickable(): boolean {
    return this.helper.getRegisteredListenerCount('click') > 0;
  }
}

export {GLMarkerObject};
