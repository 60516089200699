import {GltfId} from '../../GltfReader';

/**
 * glTFのbufferView
 */
class GltfBufferView {
  readonly buffer: GltfId;
  readonly byteLength: number;
  readonly byteOffset: number;
  readonly target?: number;
  readonly byteStride?: number;

  /**
   * コンストラクタ
   * @param buffer バッファのID
   * @param byteLength バイト長
   * @param byteOffset バイトオフセット
   * @param target 型
   * @param byteStride バイトストライド
   */
  constructor(buffer: GltfId, byteLength: number, byteOffset: number, target: number, byteStride?: number) {
    this.buffer = buffer;
    this.byteLength = byteLength;
    this.byteOffset = byteOffset;
    this.target = target;
    this.byteStride = byteStride;
  }
}

export {GltfBufferView};
