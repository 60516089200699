import {GaiaContext} from '../../GaiaContext';
import {DOMObjectLayer} from '../../layer/DOMObjectLayer';
import {Marker} from '../../../../gaia/object/Marker';
import {Vector2} from '../../../common/math/Vector2';
import {Camera} from '../../../engine/camera/Camera';
import {Vector3} from '../../../common/math/Vector3';
import {PerspectiveCamera} from '../../../engine/camera/PerspectiveCamera';
import {calculateWorldCoordinate, worldToLatLng} from '../../utils/MapUtil';
import {viewPointToIntersectionForPerspectiveCamera} from '../MapTileScanner';

/**
 * マーカーの動作を補助するためのヘルパークラス
 */
class MarkerHelper {
  private readonly context: GaiaContext;
  private readonly layer: DOMObjectLayer;
  private readonly camera: Camera;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param layer DOMレイヤー
   * @param camera カメラ
   */
  constructor(context: GaiaContext, layer: DOMObjectLayer, camera: Camera) {
    this.context = context;
    this.layer = layer;
    this.camera = camera;
  }

  /**
   * マーカーの追加
   * @param marker マーカー
   * @returns {void}
   */
  addMarker(marker: Marker): void {
    this.layer.add(marker);
    marker.setOnDragListener((ev: MouseEvent) => this.handleMouseOnDraggingMarker(marker, ev));
    marker.setOnTouchDragListener((ev: TouchEvent) => this.handleOnTouchDraggingMarker(marker, ev));
    marker.setOnPositionUpdateListener((_) => this.layer.update(this.camera));
  }

  /**
   * マーカーの削除
   * @param marker マーカー
   * @returns {void}
   */
  removeMarker(marker: Marker): void {
    this.layer.remove(marker);
    marker.setOnDragListener();
    marker.setOnPositionUpdateListener();
  }

  /**
   * マーカードラッグ中の処理
   * @param target 対象マーカー
   * @param clientX イベントのX座標
   * @param clientY イベントのY座標
   * @returns {void}
   */
  private handleOnDraggingMarker(target: Marker, clientX: number, clientY: number): void {
    const {clientHeight: height, clientWidth: width} = this.context.getBaseElement();
    const offset = this.context.getMapStatus().centerOffset;
    const {left, top} = this.context.getBaseElement().getBoundingClientRect();

    // [-1, 1] の区間に正規化
    const x = ((clientX - left - offset.x) / width) * 2 - 1;
    const y = -(((clientY - top - offset.y) / height) * 2 - 1);
    const vec2 = new Vector2(x, y);

    const upVector: Vector3 = this.context.getMapStatus().polar.toUpVector3();
    const rightVector: Vector3 = this.context.getMapStatus().polar.toRightVector3();
    const centerWorld: Vector3 = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);
    const vec3 = viewPointToIntersectionForPerspectiveCamera(
      this.context.getMapStatus(),
      this.camera as PerspectiveCamera,
      vec2,
      upVector,
      rightVector
    )._add(centerWorld);

    const latlng = worldToLatLng(vec3, this.context.getMapStatus().zoomLevel);
    target.setPosition(latlng);
  }

  /**
   * マーカードラッグ中の処理
   * @param target 対象マーカー
   * @param ev マウスイベント
   * @returns {void}
   */
  private handleMouseOnDraggingMarker(target: Marker, ev: MouseEvent): void {
    const clientX = ev.clientX;
    const clientY = ev.clientY;
    this.handleOnDraggingMarker(target, clientX, clientY);
  }

  /**
   * モバイル端末用のマーカードラッグ中の処理
   * @param target 対象マーカー
   * @param ev タッチイベント
   * @returns {void}
   */
  private handleOnTouchDraggingMarker(target: Marker, ev: TouchEvent): void {
    if (!ev.touches || ev.touches.length !== 1) {
      return;
    }
    const touch = ev.touches[0];
    const clientX = touch.clientX;
    const clientY = touch.clientY;
    this.handleOnDraggingMarker(target, clientX, clientY);
  }
}

export {MarkerHelper};
