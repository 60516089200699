import {Vector3} from './Vector3';
import {ValueObject} from '../../../gaia/value/interface/ValueObject';
import {JsonObject} from '../../../gaia/types';

/**
 * 2次元ベクトルのクラス
 */
class Vector2 implements ValueObject {
  /**
   * x座標の値
   */
  private _x: number;

  /**
   * y座標の値
   */
  private _y: number;

  /**
   * コンストラクタ
   * @param x x座標の値
   * @param y y座標の値
   */
  constructor(x: number, y: number) {
    this._x = x;
    this._y = y;
  }

  /**
   * x座標の値
   */
  get x(): number {
    return this._x;
  }

  /**
   * y座標の値
   */
  get y(): number {
    return this._y;
  }

  /**
   * 座標を変更する
   * @param x x座標の値
   * @param y y座標の値
   * @returns {void}
   */
  setValues(x: number, y: number): void {
    this._x = x;
    this._y = y;
  }

  /** @override */
  clone(): Vector2 {
    return new Vector2(this.x, this.y);
  }

  /** @override */
  equals(other: unknown): boolean {
    if (other instanceof Vector2 === false) {
      return false;
    }

    const otherVector2: Vector2 = other as Vector2;
    return this.x === otherVector2.x && this.y === otherVector2.y;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      x: this.x,
      y: this.y,
    };
  }

  /**
   * 引数で与えられたベクトルを足した新たなベクトルを返す
   * @deprecated
   * @param other 足すベクトル
   * @returns 足した結果のベクトル
   */
  _add(other: Vector2): Vector2 {
    return new Vector2(this._x + other._x, this._y + other._y);
  }

  /**
   * 引数で与えられたベクトルを自身に足す（破壊的）
   * @param other 足すベクトル
   * @returns 自身を返す
   */
  add(other: Vector2): Vector2 {
    this._x += other.x;
    this._y += other.y;
    return this;
  }

  /**
   * 引数で与えられたベクトルを引いた新たなベクトルを返す
   * @deprecated
   * @param other 引くベクトル
   * @returns 引いた結果のベクトル
   */
  _subtract(other: Vector2): Vector2 {
    return new Vector2(this._x - other._x, this._y - other._y);
  }

  /**
   * 引数で与えられたベクトルを自身から引く（破壊的）
   * @param other 引くベクトル
   * @returns 自身を返す
   */
  subtract(other: Vector2): Vector2 {
    this._x -= other.x;
    this._y -= other.y;
    return this;
  }

  /**
   * スカラー倍した新たなベクトルを返す
   * @deprecated
   * @param scalar スカラー係数
   * @returns スカラー倍したベクトル
   */
  _multiply(scalar: number): Vector2 {
    return new Vector2(this._x * scalar, this._y * scalar);
  }

  /**
   * スカラー倍した自身を返す（破壊的）
   * @param scalar スカラー係数
   * @returns スカラー倍した自身
   */
  multiply(scalar: number): Vector2 {
    this._x *= scalar;
    this._y *= scalar;
    return this;
  }

  /**
   * スカラー除算した新たなベクトルを返す
   * @deprecated
   * @param scalar スカラー係数
   * @returns スカラー除算したベクトル
   */
  _divide(scalar: number): Vector2 {
    return new Vector2(this.x / scalar, this.y / scalar);
  }

  /**
   * スカラー除算した自身を返す（破壊的）
   * @param scalar スカラー係数
   * @returns スカラー除算した自身
   */
  divide(scalar: number): Vector2 {
    this._x /= scalar;
    this._y /= scalar;
    return this;
  }

  /**
   * ベクトルの長さを返す
   * @returns ベクトルの長さ
   */
  magnitude(): number {
    return Math.sqrt(this.squaredMagnitude());
  }

  /**
   * ベクトルの長さの2乗を返す
   * @returns ベクトルの長さの2乗
   */
  squaredMagnitude(): number {
    return this._x ** 2 + this._y ** 2;
  }

  /**
   * 長さを1に正規化した新たなベクトルを返す
   * @deprecated
   * @returns 正規化したベクトル
   */
  _normalize(): Vector2 {
    return this._divide(this.magnitude());
  }

  /**
   * 長さを1に正規化した自身を返す（破壊的）
   * @returns 正規化した自身
   */
  normalize(): Vector2 {
    return this.divide(this.magnitude());
  }

  /**
   * 回転した新たなベクトルを返す
   * @deprecated
   * @param theta ラジアンで表現された回転角（x軸を基準に反時計回り）
   * @returns 回転したベクトル
   */
  _rotate(theta: number): Vector2 {
    const x = this._x * Math.cos(theta) - this._y * Math.sin(theta);
    const y = this._x * Math.sin(theta) + this._y * Math.cos(theta);
    return new Vector2(x, y);
  }

  /**
   * 回転した自身を返す（破壊的）
   * @param theta ラジアンで表現された回転角（x軸を基準に反時計回り）
   * @returns 回転した自身
   */
  rotate(theta: number): Vector2 {
    const x = this._x * Math.cos(theta) - this._y * Math.sin(theta);
    const y = this._x * Math.sin(theta) + this._y * Math.cos(theta);
    this._x = x;
    this._y = y;
    return this;
  }

  /**
   * zの値を0で補完し、3次元ベクトルに変換する
   * @returns 変換された3次元ベクトル
   */
  toVector3(): Vector3 {
    return new Vector3(this._x, this._y, 0);
  }

  /**
   * zの値を指定し、3次元ベクトルに変換する
   * @param z z座標の値
   * @returns 変換された3次元ベクトル
   */
  toVector3WithZ(z: number): Vector3 {
    return new Vector3(this._x, this._y, z);
  }

  /**
   * 与えられたベクトルとの内積を計算する
   * @param other ベクトル
   * @returns 内積
   */
  innerProduct(other: Vector2): number {
    return this.x * other.x + this.y * other.y;
  }

  /**
   * 与えられたベクトルとの外積を計算する
   * @param other ベクトル
   * @returns 外積
   */
  outerProduct(other: Vector2): number {
    return this.x * other.y - this.y * other.x;
  }

  /**
   * ゼロベクトルを作成する
   * @returns ゼロベクトル
   */
  static zero(): Vector2 {
    return new Vector2(0, 0);
  }

  /**
   * x, yの値が1のベクトルを返す
   * @returns x, yの値が1のベクトル
   */
  static one(): Vector2 {
    return new Vector2(1, 1);
  }
}

export {Vector2};
