import {Collider} from './Collider';
import {Ray3} from './Ray3';
import {Triangle3} from './Triangle3';

/**
 * 3角形を組み合わせてできる任意の形状のコライダ
 */
class ShapeCollider implements Collider {
  private surface: Triangle3[];

  /**
   * コンストラクタ
   * @param triangles 3角形の集合
   */
  constructor(triangles: Triangle3[]) {
    this.surface = triangles;
  }

  /** @override */
  isCollided(ray: Ray3): boolean {
    for (const triangle of this.surface) {
      if (triangle.calculateIntersection(ray)) {
        return true;
      }
    }

    return false;
  }
}

export {ShapeCollider};
