import {AltitudeParameter} from '../param/AltitudeParameter';
import {AltitudeStoreHander} from './AltitudeStoreHandler';
import {AltitudeMainDBData, idbOpen} from './AnnotationDBUtil';

const ALTITUDE_DB = 'altitude_db';
const MAIN_STORE = 'main';
const DATE_INDEX = 'date';

/**
 * 標高データ用IndexedDBハンドラ
 */
class AltitudeDBHandler {
  private readonly dbName: string;

  private readonly handler: AltitudeStoreHander;

  /**
   * コンストラクタ
   */
  constructor() {
    this.dbName = ALTITUDE_DB;
    this.handler = new AltitudeStoreHander(this.dbName, MAIN_STORE);

    if (!window.indexedDB) {
      // eslint-disable-next-line no-console
      console.warn(`[GaIA] Your browser doesn't support a stable version of IndexedDB.`);
      this.handler.setStateUnavailable();
      return;
    }

    this.setupDB(false);
  }

  /**
   * 注記用DBの初期化
   * @param retry 再試行フラグ
   * @returns {void}
   */
  private async setupDB(retry: boolean): Promise<void> {
    const db = await idbOpen(this.dbName, this.upgradeDB).catch(() => {
      // eslint-disable-next-line no-console
      console.warn(`[GaIA] Could not open indexedDB.`);
      this.handler.setStateUnavailable();
    });
    if (!db) {
      return;
    }

    // オブジェクトストアが揃っていない場合はDBごと削除して作成からやり直す
    if (!db.objectStoreNames.contains(MAIN_STORE)) {
      db.close();
      indexedDB.deleteDatabase(ALTITUDE_DB);
      if (!retry) {
        this.setupDB(true);
      }
      return;
    }

    // メインオブジェクトストアにdateインデックスがない場合も作成し直し
    const mainIndexes = db.transaction(MAIN_STORE).objectStore(MAIN_STORE).indexNames;
    if (!mainIndexes.contains(DATE_INDEX)) {
      db.close();
      indexedDB.deleteDatabase(ALTITUDE_DB);
      if (!retry) {
        this.setupDB(true);
      }
      return;
    }

    this.handler.setupStore();
  }

  /**
   * DB更新
   * @param upgradedDB 更新対象IDBDatabase
   * @returns {void}
   */
  private upgradeDB(upgradedDB: IDBDatabase): void {
    const main = upgradedDB.createObjectStore(MAIN_STORE);
    main.createIndex(DATE_INDEX, DATE_INDEX, {unique: false});
  }

  /**
   * メインデータの保存
   * @param param AltitudeParameter
   * @param data メインデータ(難読化後)
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  insertMainData(
    param: AltitudeParameter,
    data: Uint8Array,
    callback?: (isSucceeded: boolean, key: AltitudeParameter) => void
  ): void {
    const dbData: AltitudeMainDBData = {
      date: Date.now(),
      mainInfo: data,
    };
    this.handler.insertData(param, dbData, callback);
  }

  /**
   * メインデータの取得
   * @param param AltitudeParameter
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  findMainData(
    param: AltitudeParameter,
    callback: (isSucceeded: boolean, key: AltitudeParameter, data?: Uint8Array) => void
  ): void {
    this.handler.findData(param, (isSucceeded: boolean, key: AltitudeParameter, data?: AltitudeMainDBData) => {
      callback(isSucceeded, key, data?.mainInfo);
    });
  }

  /**
   * メインデータ全削除
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  deleteAllMainData(callback?: (isSucceeded: boolean) => void): void {
    this.handler.deleteAllData(callback);
  }

  /**
   * メインデータストアへのリクエストキューを削除
   * @returns {void}
   */
  clearMainRequestQueue(): void {
    this.handler.clearRequestQueue();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.handler.destroy();
  }
}

export {AltitudeDBHandler};
