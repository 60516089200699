import {AbstractUIEventObserver} from './AbstractUIEventObserver';
import {MouseEventMap} from '../../../gaia/types';
import {throttle} from '../../common/util/Culling';

const INERTIA_SCROLL_DEFAULT_ID = 0;
const ATTENUATION_RATE = 0.85;
const INERTIA_SCROLL_CONTINUE_THRESHOLD = 0.8;
const INERTIA_THRESHOLD_MIN_MOVEMENT = 8;

const THROTTLE_DURATION = 10;

// 第一ボタン(左)押下時のMouseEvent.buttonの値
const MOUSE_BUTTON_NUM_FIRST = 0;
// 第二ボタン(右)押下時のMouseEvent.buttonの値
const MOUSE_BUTTON_NUM_SECOND = 2;
// 第一ボタン(左)押下時のMouseEvent.buttonsの値
const MOUSE_BUTTONS_NUM_FIRST = 1;
// 第二ボタン(右)押下時のMouseEvent.buttonsの値
const MOUSE_BUTTONS_NUM_SECOND = 2;

/**
 * マウスイベントの監視と通知
 */
class MouseEventObserver extends AbstractUIEventObserver<MouseEventMap> {
  private isMousePressing = false;
  private isDragging = false;

  private singleClickTimeoutId = 0;
  private maybeDoubleClick = false;

  private accumulatedDelta = {x: 0, y: 0};
  /** マウス移動量を計算するための前回画面座標 */
  private mouseMovePreviousPoint = {x: 0, y: 0};

  // 慣性スクロール
  inertiaScrollDelta = {x: 0, y: 0};
  inertiaScrollId = INERTIA_SCROLL_DEFAULT_ID;

  throttleMouseMove: (ev: MouseEvent) => void;

  private isSecondButtonDragEnabled = false;

  /**
   * コンストラクタ
   * @param element HTMLElement
   */
  constructor(element: HTMLElement) {
    super(element);

    this.throttleMouseMove = throttle(this, this.throttleMouseMoveExecute, THROTTLE_DURATION);
  }

  /** @override */
  setupObserve(): void {
    this.baseElement.addEventListener('click', (ev: MouseEvent) => this.handleDefaultClick(ev));
    this.baseElement.addEventListener('dblclick', (ev: MouseEvent) => this.handleDefaultClick(ev));
    this.baseElement.addEventListener('mousedown', (ev: MouseEvent) => this.handleMouseDown(ev));
    this.baseElement.addEventListener('mouseenter', (ev: MouseEvent) => this.trigger('mouseenter', ev));
    this.baseElement.addEventListener('mouseleave', (ev: MouseEvent) => this.trigger('mouseleave', ev));
    document.addEventListener('mousemove', (ev: MouseEvent) => this.handleMouseMove(ev));
    this.baseElement.addEventListener('contextmenu', (ev: MouseEvent) => this.handleDefaultClick(ev));
  }

  /**
   * デフォルトのクリックイベントの抑制
   * @param ev MouseEvent
   * @returns {void}
   */
  private handleDefaultClick(ev: MouseEvent): void {
    // 地図操作のみデフォルトイベントを抑制する
    if ((ev.target as HTMLElement).tagName.toLowerCase() === 'canvas') {
      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  /**
   * マウスボタンが押された時の処理
   * @param ev MouseEvent
   * @returns {void}
   */
  private handleMouseDown(ev: MouseEvent): void {
    this.trigger('mousedown', ev);
    this.accumulatedDelta = {x: 0, y: 0};
    this.mouseMovePreviousPoint = {x: ev.screenX, y: ev.screenY};
    if (this.inertiaScrollId !== INERTIA_SCROLL_DEFAULT_ID) {
      this.stopInertiaScroll();
    }
    this.isMousePressing = true;
    document.addEventListener('mouseup', (ev: MouseEvent) => this.handleMouseUp(ev), {
      once: true,
    });

    if (this.singleClickTimeoutId !== 0) {
      window.clearTimeout(this.singleClickTimeoutId);
      this.singleClickTimeoutId = 0;
      this.maybeDoubleClick = true;
    }
  }

  /**
   * マウス移動時の処理
   * @param ev MouseEvent
   * @returns {void}
   */
  private handleMouseMove(ev: MouseEvent): void {
    this.trigger('mousemove', ev);
    if (!this.isMousePressing) {
      return;
    }

    // 左クリック中 または 右クリック中(有効時のみ) 以外はドラッグしない
    // Chromeではev.buttonで左右を判別できないためbuttonsを利用
    const isDraggable =
      ev.buttons === MOUSE_BUTTONS_NUM_FIRST ||
      (this.isSecondButtonDragEnabled && ev.buttons === MOUSE_BUTTONS_NUM_SECOND);
    if (!isDraggable) {
      // mouseup時の制御のためドラッグ中ステータスのみ変更する。スクロール・3D操作やdrag系イベントの発行は行わない
      this.isDragging = true;
      return;
    }

    // TODO: スクロール処理と回転・傾き処理をこのクラスかUserInputHandlerどちらかにまとめる
    const movementX = ev.screenX - this.mouseMovePreviousPoint.x;
    const movementY = ev.screenY - this.mouseMovePreviousPoint.y;
    this.mouseMovePreviousPoint = {x: ev.screenX, y: ev.screenY};
    this.inertiaScrollDelta.x = movementX;
    this.inertiaScrollDelta.y = movementY;
    if (
      Math.abs(this.inertiaScrollDelta.x) < INERTIA_THRESHOLD_MIN_MOVEMENT &&
      Math.abs(this.inertiaScrollDelta.y) < INERTIA_THRESHOLD_MIN_MOVEMENT
    ) {
      this.inertiaScrollDelta.x = 0;
      this.inertiaScrollDelta.y = 0;
    }
    if (!this.isDragging) {
      this.isDragging = true;
      this.trigger('dragstart', ev);
    } else {
      this.accumulatedDelta.x += movementX;
      this.accumulatedDelta.y += movementY;
      this.throttleMouseMove(ev);
    }
  }

  /**
   * mousemoveイベントが間引かれずに実行された時の処理
   * @param ev マウスイベント
   * @returns {void}
   */
  private throttleMouseMoveExecute(ev: MouseEvent): void {
    this.trigger(
      'drag',
      Object.assign(ev, {
        deltaX: this.accumulatedDelta.x,
        deltaY: this.accumulatedDelta.y,
      })
    );
    this.accumulatedDelta = {x: 0, y: 0};
  }

  /**
   * マウスボタンが離された時の処理
   * @param ev MouseEvent
   * @returns {void}
   */
  private handleMouseUp(ev: MouseEvent): void {
    this.isMousePressing = false;
    this.trigger('mouseup', ev);

    // 右クリック+drag&drop かつ 右ドラッグ無効時 はmouseupイベントの発行のみ
    if (this.isDragging && ev.button === MOUSE_BUTTON_NUM_SECOND && !this.isSecondButtonDragEnabled) {
      this.isDragging = false;
      return;
    }

    if (this.isDragging) {
      this.isDragging = false;
      this.trigger('dragend', ev);

      // eslint-disable-next-line
      const inertiaFn = throttle(
        this,
        (): void => {
          this.trigger(
            'inertia_mousemove',
            Object.assign(ev, {
              deltaX: this.inertiaScrollDelta.x,
              deltaY: this.inertiaScrollDelta.y,
            })
          );
          this.inertiaScrollDelta.x *= ATTENUATION_RATE;
          this.inertiaScrollDelta.y *= ATTENUATION_RATE;

          if (
            Math.abs(this.inertiaScrollDelta.x) < INERTIA_SCROLL_CONTINUE_THRESHOLD &&
            Math.abs(this.inertiaScrollDelta.y) < INERTIA_SCROLL_CONTINUE_THRESHOLD
          ) {
            this.stopInertiaScroll();
          } else {
            this.inertiaScrollId = requestAnimationFrame(inertiaFn);
          }
        },
        THROTTLE_DURATION
      );
      this.inertiaScrollId = requestAnimationFrame(inertiaFn);
    } else {
      // clickになるのは左クリック時のみ・右クリック+single clickはcontextmenu
      // dblclickは左右ボタンどちらかであれば発火
      // mouseupではev.buttonsで左右を判別できないためbuttonを利用
      if (this.maybeDoubleClick) {
        if (ev.button === MOUSE_BUTTON_NUM_FIRST || MOUSE_BUTTON_NUM_SECOND) {
          this.maybeDoubleClick = false;
          this.trigger('dblclick', ev);
        }
      } else {
        if (ev.button === MOUSE_BUTTON_NUM_FIRST) {
          this.singleClickTimeoutId = window.setTimeout(() => {
            this.trigger('click', ev);
            this.singleClickTimeoutId = 0;
          }, 200);
        } else if (ev.button === MOUSE_BUTTON_NUM_SECOND) {
          this.singleClickTimeoutId = window.setTimeout(() => {
            this.trigger('contextmenu', ev);
            this.singleClickTimeoutId = 0;
          }, 200);
        }
      }
    }
  }

  /**
   * コンテキストメニューの操作をされたときの処理
   * @param ev マウスイベント
   * @returns {void}
   */
  private handleContextMenu(ev: MouseEvent): void {
    this.trigger('contextmenu', ev);
  }

  /**
   * 慣性スクロールの破棄
   * @returns {void}
   */
  private stopInertiaScroll(): void {
    this.inertiaScrollDelta = {
      x: 0,
      y: 0,
    };
    cancelAnimationFrame(this.inertiaScrollId);
    this.inertiaScrollId = INERTIA_SCROLL_DEFAULT_ID;
  }

  /**
   * 登録されているイベントリスナの数を取得
   * @param eventName イベント名
   * @returns イベントリスナの数
   */
  getRegisteredListenerCount<K extends keyof MouseEventMap>(eventName: K): number {
    return this.helper.getRegisteredListenerCount(eventName);
  }

  /**
   * 右クリック中のドラッグを有効にするか
   * @param enabled `true` : 右クリック中のドラッグ可・ `false` : 不可(mousemoveが発生しても何も起こらない)
   * @returns {void}
   */
  setSecondButtonDragEnabled(enabled: boolean): void {
    this.isSecondButtonDragEnabled = enabled;
  }
}

export {MouseEventObserver};
