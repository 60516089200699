import {UserLocation} from '../../../../../gaia/object/UserLocation';
import {QUATERNION_IDENTITY} from '../../../../common/math/Quaternion';
import {VECTOR_ONES} from '../../../../common/math/Vector3';
import {CustomGeometry} from '../../../../engine/geometry/CustomGeometry';
import {LayerUpdateNotifierFunc} from '../../../../engine/layer/Layer';
import {GaiaContext} from '../../../GaiaContext';
import {UserLocationLayer} from '../../../layer/UserLocationLayer';
import {MapStatus} from '../../../models/MapStatus';
import {calculateWorldCoordinate} from '../../../utils/MapUtil';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';
import {UserLocationObject} from '../../objects/UserLocationObject';
import {TexturePlaneMaterial} from '../../../../engine/material/TexturePlaneMaterial';
import {UserLocationData} from '../../../../../gaia/value';
import {DEGREE_TO_RADIAN} from '../../../../common/math/MathConstants';
import {AnimationOption} from '../../../../../gaia/value/animation';

const LAYER_NAME_USER_LOCATION = 'userLocation';

/**
 * 自位置マーカーを扱う描画キット
 */
class UserLocationObjectRenderKit extends AbstractObjectRenderKit {
  private userLocationLayer: UserLocationLayer;

  private status?: MapStatus;
  private notifyUpdate?: LayerUpdateNotifierFunc;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKitCtl MapRenderKitController
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController) {
    const userLocationLayer = new UserLocationLayer();
    super(context, userLocationLayer, renderKitCtl);

    this.userLocationLayer = userLocationLayer;
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return LAYER_NAME_USER_LOCATION;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.status = mapStatus;
    this.userLocationLayer.update(mapStatus);
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }

  /**
   * 自位置マーカーを設定
   * @param userLocation UserLocation
   * @returns {void}
   */
  setUserLocation(userLocation: UserLocation): void {
    const center = calculateWorldCoordinate(this.context.getUserLocationData().getLatlng());
    const material = new TexturePlaneMaterial(this.context.getGLContext(), new CustomGeometry([], []));

    const {size, gravity} = userLocation.iconInfo;

    let userLocationObject = this.userLocationLayer.getUserLocationObject();
    userLocationObject?.setClientSize(userLocation.iconInfo.size);
    userLocationObject?.setGravity(userLocation.iconInfo.gravity);

    if (!userLocationObject) {
      userLocationObject = new UserLocationObject(
        center,
        QUATERNION_IDENTITY,
        VECTOR_ONES,
        material,
        size,
        gravity,
        userLocation.getProjectionMode(),
        userLocation.isVisible()
      );
      this.userLocationLayer.setUserLocationObject(userLocationObject);
    }

    const image = new Image();
    image.onload = (): void => {
      userLocationObject?.setTexture(image);
      if (this.status) {
        this.updateDrawObjects(this.status);
      }
    };
    image.src = userLocation.iconInfo.icon;

    // 状態変更時
    userLocation.addEventListener('appear', () => {
      userLocationObject?.setVisible(true);
      if (this.status) {
        this.updateDrawObjects(this.status);
      }
    });

    userLocation.addEventListener('disappear', () => {
      userLocationObject?.setVisible(false);
      if (this.status) {
        this.updateDrawObjects(this.status);
      }
    });

    userLocation.setStatusUpdateListener(() => {
      userLocationObject?.setProjectionMode(userLocation.getProjectionMode());
      if (this.status) {
        this.updateDrawObjects(this.status);
      }
    });
  }

  /**
   * UserLocationObjectの描画位置・角度の更新
   * @param data 位置情報
   * @param animationOption アニメーション設定
   * @returns {void}
   */
  updateUserLocationObjectPosition(data: UserLocationData, animationOption?: AnimationOption): void {
    const newPosition = calculateWorldCoordinate(data.getLatlng());
    if (this.status) {
      // 自位置の方位は、数学における角度とは正負が逆なため変換・更に度→ラジアンへ変換
      const angle = data.getHeading() * -1 * DEGREE_TO_RADIAN;
      this.userLocationLayer.updateUserLocationObjectPosition(this.status, newPosition, angle, animationOption);
    }
  }

  /** @override */
  onDestroy(): void {
    this.userLocationLayer.destroy();
    this.notifyUpdate = undefined;
  }
}

export {UserLocationObjectRenderKit};
