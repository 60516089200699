import {mat4} from 'gl-matrix';
import {Color} from '../../../gaia/value';
import {Quaternion} from '../../common/math/Quaternion';
import {Ray3} from '../../common/math/Ray3';
import {Vector3} from '../../common/math/Vector3';
import {Camera} from '../../engine/camera/Camera';
import {Collision} from '../../engine/collision/Collision';
import {TexturePlaneGeometry} from '../../engine/geometry/TexturePlaneGeometry';
import {TexturePlaneUVCoordinate} from '../../engine/geometry/TexturePlaneUVCoordinate';
import {Layer} from '../../engine/layer/Layer';
import {FogMaterial} from '../../engine/material/FogMaterial';
import {Object3D} from '../../engine/object/Object3D';
import {DepthProgram} from '../../engine/program/DepthProgram';
import {GaiaContext} from '../GaiaContext';
import {MapStatus} from '../models/MapStatus';
import {calculatePixelToUnit} from '../utils/MapUtil';

const LAYER_NAME_FOG = 'fog';

/**
 * アスペクト比から霧の距離係数を計算する
 * @param aspect アスペクト比
 * @returns 霧の距離係数
 */
const aspectToFogDistanceRatio = (aspect: number): number => {
  const verticalAspect = 1.0 / aspect;
  const maxAspect = Math.max(aspect, verticalAspect);
  const fogDistanceRatio = Math.log(maxAspect + 1.0) + 0.5;
  return fogDistanceRatio;
};

/**
 * 霧オブジェクトを描画するレイヤー
 */
class FogObjectLayer implements Layer {
  private material: FogMaterial;
  private fogObject: Object3D;
  private drawPosition: Vector3 = Vector3.zero();
  private drawScale: Vector3 = Vector3.one();

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    const geometry = TexturePlaneGeometry.create(1.0, 1.0, TexturePlaneUVCoordinate.defaultUVCoordinate());
    const fogColor = context.getMapInitOptions().fogColor ?? Color.white();
    const skyColor = context.getMapInitOptions().skyColor ?? Color.cyan();
    this.material = new FogMaterial(context.getGLContext(), geometry, fogColor, skyColor);
    this.fogObject = new Object3D(this.drawPosition, Quaternion.identity(), this.drawScale, this.material);
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_FOG;
  }

  /**
   * 画面を覆うことができるスケールを返す
   * @param mapStatus 地図状態
   * @returns スケール
   */
  private calculateScale(mapStatus: MapStatus): Vector3 {
    let objectLength = mapStatus.clientWidth;
    if (objectLength < mapStatus.clientHeight) {
      objectLength = mapStatus.clientHeight;
    }

    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    return new Vector3(mapStatus.clientWidth * ptu, mapStatus.clientHeight * ptu, 1.0);
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @param camera カメラ
   * @returns {void}
   */
  update(mapStatus: MapStatus, camera: Camera): void {
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    this.drawPosition = new Vector3(-mapStatus.centerOffset.x * ptu, mapStatus.centerOffset.y * ptu, 0);

    this.drawScale = this.calculateScale(mapStatus);

    this.material.setCameraPosition(camera.position);

    const fogDistanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
    this.material.setFogDistanceRatio(fogDistanceRatio);

    if (DepthProgram.targetTexture) {
      this.material.setGLTexture(DepthProgram.targetTexture);
    }

    const rotation = Quaternion.identity()
      .rotateZ(Math.PI / 2 + mapStatus.polar.phi)
      .rotateX(mapStatus.polar.theta);
    this.fogObject.setRotation(rotation);
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    this.fogObject.setPosition(this.drawPosition);
    this.fogObject.setScale(this.drawScale);
    this.fogObject.update(viewMatrix, projectionMatrix);
    this.fogObject.draw();
    return true;
  }

  /** @override */
  destroy(): void {
    this.fogObject.destroy();
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {FogObjectLayer, LAYER_NAME_FOG, aspectToFogDistanceRatio};
