import {PolarCoordinate3} from '../../common/math/PolarCoordinate3';
import {LatLng} from '../../../gaia/value/LatLng';
import {Language, MapLocationStatus, TileType} from '../../../gaia/types';
import {AnimationController} from './animation/AnimationController';
import {CAMERA_DISTANCE} from '../utils/MapUtil';
import {DEGREE_TO_RADIAN} from '../../common/math/MathConstants';
import {Size, ZoomRange, Point} from '../../../gaia/value';
import {clamp} from '../../common/math/MathUtil';
import {LatLngRect} from 'gaia/object';
import {PaletteParameter} from './PaletteParameter';
import {AnimationOption} from '../../../gaia/value/animation';
import {DefaultRenderTarget} from '../../common/types';

export type OnUpdateMapStatusListener = (status: MapStatus) => void;

const MIN_THETA = 0.01;
const MAX_THETA_DEFAULT = Math.PI / 3;

/**
 * 地図初期化後も変更されうる地図の状態クラス
 */
class MapStatus {
  private _centerLocation: LatLng;
  private _zoomLevel: number;

  private _clientWidth: number;
  private _clientHeight: number;
  private _centerOffset: Point;

  private _polar: PolarCoordinate3;
  private _maxTheta: number;

  private _zoomRange: ZoomRange;

  private _tileType: TileType;
  private _additionTileSet: Set<string>;

  private _palette: PaletteParameter;

  private _viewRange: LatLngRect;

  private readonly animationCtl: AnimationController;

  private readonly listener: OnUpdateMapStatusListener;

  public static defaultRenderTarget: DefaultRenderTarget = {
    type: 'default',
    size: {
      width: 0,
      height: 0,
    },
  };

  /**
   * コンストラクタ
   * @param centerLocation 中心緯度経度
   * @param zoomLevel ズームレベル
   * @param clientWidth クライアントの幅
   * @param clientHeight クライアントの高さ
   * @param centerOffset 中心基準位置オフセット
   * @param zoomRange ズームレンジ
   * @param tileType タイル種別
   * @param language 言語
   * @param viewRange 地図を表示できる範囲
   * @param listener MapStatus更新リスナー
   */
  constructor(
    centerLocation: LatLng,
    zoomLevel: number,
    clientWidth: number,
    clientHeight: number,
    centerOffset: Point,
    zoomRange: ZoomRange,
    tileType: TileType,
    language: Language,
    viewRange: LatLngRect,
    listener: OnUpdateMapStatusListener
  ) {
    this._centerLocation = centerLocation.clone();
    this._zoomLevel = zoomLevel;
    this._clientWidth = clientWidth;
    this._clientHeight = clientHeight;
    this._centerOffset = centerOffset;
    this._polar = new PolarCoordinate3(CAMERA_DISTANCE, -90 * DEGREE_TO_RADIAN, MIN_THETA * DEGREE_TO_RADIAN);
    this._maxTheta = MAX_THETA_DEFAULT;
    this._zoomRange = zoomRange;
    this._tileType = tileType;
    this._additionTileSet = new Set();
    this._palette = new PaletteParameter(language);
    this._viewRange = viewRange;
    this.animationCtl = new AnimationController();
    this.listener = listener;

    MapStatus.defaultRenderTarget.size.width = clientWidth;
    MapStatus.defaultRenderTarget.size.height = clientHeight;
  }

  /**
   * 中心緯度経度
   */
  get centerLocation(): LatLng {
    return this._centerLocation;
  }

  /**
   * ズームレベル
   */
  get zoomLevel(): number {
    return this._zoomLevel;
  }

  /**
   * クライアントの幅
   */
  get clientWidth(): number {
    return this._clientWidth;
  }

  /**
   * クライアントの高さ
   */
  get clientHeight(): number {
    return this._clientHeight;
  }

  /**
   * アスペクト比（幅を高さで割った値）
   */
  get aspect(): number {
    return this._clientWidth / this._clientHeight;
  }

  /**
   * 中心オフセット
   */
  get centerOffset(): Point {
    return this._centerOffset;
  }

  /**
   * 極座標
   */
  get polar(): PolarCoordinate3 {
    return this._polar;
  }

  /**
   * ズームレンジ
   */
  get zoomRange(): ZoomRange {
    return this._zoomRange;
  }

  /**
   * 地図言語
   */
  get language(): Language {
    return this.palette.language;
  }

  /**
   * タイル種別
   */
  get tileType(): TileType {
    return this._tileType;
  }

  /**
   * 任意タイル地図のキー名リスト
   */
  get additionTileSet(): Set<string> {
    return this._additionTileSet;
  }

  /**
   * パレット情報
   */
  get palette(): PaletteParameter {
    return this._palette;
  }

  /**
   * 地図を表示できる範囲
   */
  get viewRange(): LatLngRect {
    return this._viewRange;
  }

  /**
   * 中心緯度経度を設定
   * @param centerLocation 中心緯度経度
   * @param animationOption アニメーションの設定
   * @returns {void}
   */
  setCenterLocation(centerLocation: LatLng, animationOption?: AnimationOption): void {
    this.animationCtl.cancelAnimation();

    if (centerLocation.lat < this.viewRange.bottomRight.lat || centerLocation.lat > this.viewRange.topLeft.lat) {
      return;
    } else if (centerLocation.lng < this.viewRange.topLeft.lng || centerLocation.lng > this.viewRange.bottomRight.lng) {
      return;
    }

    if (!animationOption) {
      this.setCenter(centerLocation);
      return;
    }
    const startLatLng = this.centerLocation;
    this.animationCtl.startAnimation((progress: number) => {
      const lat = startLatLng.lat + (centerLocation.lat - startLatLng.lat) * progress;
      const lng = startLatLng.lng + (centerLocation.lng - startLatLng.lng) * progress;
      this.setCenter(new LatLng(lat, lng));
    }, animationOption);
  }

  /**
   * 中心緯度経度の書き換えとリスナーへの通知を行う
   * @param center 中心緯度経度
   * @returns {void}
   */
  private setCenter(center: LatLng): void {
    this._centerLocation = center;
    this.listener(this);
  }

  /**
   * ズームレベルを設定
   * @param zoomLevel ズームレベル
   * @param animationOption アニメーションの設定
   * @returns {void}
   */
  setZoomLevel(zoomLevel: number, animationOption?: AnimationOption): void {
    this.animationCtl.cancelAnimation();
    if (!animationOption) {
      this.setZoom(zoomLevel);
      return;
    }
    const startZoomLevel = this.zoomLevel;
    this.animationCtl.startAnimation((progress: number) => {
      const zoom = startZoomLevel + (zoomLevel - startZoomLevel) * progress;
      this.setZoom(zoom);
    }, animationOption);
  }

  /**
   * ズームレベルの書き換えとリスナーへの通知を行う
   * @param zoomLevel ズームレベル
   * @returns {void}
   */
  private setZoom(zoomLevel: number): void {
    const zoom = clamp(zoomLevel, this._zoomRange.min, this._zoomRange.max);
    this._zoomLevel = zoom;
    this.listener(this);
  }

  /**
   * ズームレベルを変更するが、再描画などを行わない
   * @param zoomLevel ズームレベル
   * @returns {void}
   */
  public setZoomLevelSilently(zoomLevel: number): void {
    this._zoomLevel = clamp(zoomLevel, this._zoomRange.min, this._zoomRange.max);
  }

  /**
   * 中心・ズームレベル・方位角・仰角を同時に設定
   * @param center 中心緯度経度
   * @param zoomLevel ズームレベル
   * @param phi ラジアン表現の方位角
   * @param theta ラジアン表現の仰角
   * @returns {void}
   */
  private setPosition(center: LatLng, zoomLevel: number, phi: number, theta: number): void {
    const zoom = clamp(zoomLevel, this._zoomRange.min, this._zoomRange.max);
    this._centerLocation = center;
    this._zoomLevel = zoom;
    this._polar.setPhi(phi);
    this._polar.setTheta(theta);
    this.listener(this);
  }

  /**
   * 指定された緯度経度・ズームレベル・極座標に移動する
   * @param latLng 緯度経度
   * @param zoomLevel ズームレベル
   * @param animationOption アニメーションの設定
   * @param polar 極座標
   * @returns {void}
   */
  moveTo(latLng: LatLng, zoomLevel: number, animationOption?: AnimationOption, polar?: PolarCoordinate3): void {
    this.animationCtl.cancelAnimation();
    const newPhi = polar?.phi ?? this.polar.phi;
    const newTheta = polar?.theta ?? this.polar.theta;
    if (!animationOption) {
      this.setPosition(latLng, zoomLevel, newPhi, newTheta);
      return;
    }
    const startZoomLevel = this.zoomLevel;
    const startLatLng = this.centerLocation;
    const startPhi = this.polar.phi;
    const startTheta = this.polar.theta;
    this.animationCtl.startAnimation((progress: number) => {
      const zoom = startZoomLevel + (zoomLevel - startZoomLevel) * progress;
      const lat = startLatLng.lat + (latLng.lat - startLatLng.lat) * progress;
      const lng = startLatLng.lng + (latLng.lng - startLatLng.lng) * progress;
      const phi = startPhi + (newPhi - startPhi) * progress;
      const theta = startTheta + (newTheta - startTheta) * progress;
      this.setPosition(new LatLng(lat, lng), zoom, phi, theta);
    }, animationOption);
  }

  /**
   * 極座標
   * @param polar 極座標
   * @returns {void}
   */
  setPolar(polar: PolarCoordinate3): void {
    this._polar = polar;
    this.listener(this);
  }

  /**
   * カメラの方位角を取得
   * @returns 方位角（ラジアン）
   */
  getPolarPhi(): number {
    return this._polar.phi;
  }

  /**
   * カメラの方位角を設定
   * @param phi 方位角（ラジアン）
   * @returns {void}
   */
  setPolarPhi(phi: number): void {
    this._polar.setPhi(phi);
    this.listener(this);
  }

  /**
   * カメラの仰角を取得
   * @returns 仰角（ラジアン）
   */
  getPolarTheta(): number {
    return this._polar.theta;
  }

  /**
   * カメラの仰角を設定
   * @param theta 仰角（ラジアン）
   * @returns {void}
   */
  setPolarTheta(theta: number): void {
    this._polar.setTheta(clamp(theta, MIN_THETA, this._maxTheta));
    this.listener(this);
  }

  /**
   * カメラの仰角の最大値を設定
   * @param maxTheta 仰角の最大値（ラジアン）
   * @returns {void}
   */
  setPolarMaxTheta(maxTheta: number): void {
    this._maxTheta = clamp(maxTheta, MIN_THETA, MAX_THETA_DEFAULT);
    const theta = this._polar.theta;
    this.setPolarTheta(theta);
  }

  /**
   * 現在の地図表示ステータスを生成
   * @returns 地図ステータス
   */
  getMapLocationStatus(): MapLocationStatus {
    return {
      center: this.centerLocation.clone(),
      zoom: this.zoomLevel,
      heading: 0, // TODO: 回転に対応したら修正
      tilt: 0, // TODO: 傾きに対応したら修正
    };
  }

  /**
   * 地図のクライアントサイズを変更
   * @param size サイズ（ピクセル）
   * @returns {void}
   */
  setClientSize(size: Size): void {
    this._clientWidth = size.width;
    this._clientHeight = size.height;

    MapStatus.defaultRenderTarget.size.width = size.width;
    MapStatus.defaultRenderTarget.size.height = size.height;
  }

  /**
   * 中心オフセットを設定
   * @param offset オフセット
   * @returns {void}
   */
  setCenterOffset(offset: Point): void {
    this._centerOffset = offset;
  }

  /**
   * ズームレンジを設定
   * @param zoomRange ズームレンジ
   * @returns {void}
   */
  setZoomRange(zoomRange: ZoomRange): void {
    this._zoomRange = zoomRange;
    if (this.zoomLevel < zoomRange.min) {
      this._zoomLevel = zoomRange.min;
      this.listener(this);
    } else if (this.zoomLevel > zoomRange.max) {
      this._zoomLevel = zoomRange.max;
      this.listener(this);
    }
  }

  /**
   * タイル種別を設定
   * @param tileType タイル種別
   * @returns {void}
   */
  setTileType(tileType: TileType): void {
    this._tileType = tileType;
    this.listener(this);
  }

  /**
   * 言語を設定
   * @param language 言語
   * @returns {void}
   */
  setLanguage(language: Language): void {
    if (this._palette.language === language) {
      return;
    }
    this._palette = new PaletteParameter(language, this.palette.name);
    this.listener(this);
  }

  /**
   * カスタムパレット名を指定
   * @param name パレット名
   * @returns {void}
   */
  setPaletteName(name?: string): void {
    if (this._palette.name === name) {
      return;
    }
    this._palette = new PaletteParameter(this.palette.language, name);
    this.listener(this);
  }

  /**
   * 任意タイル地図のキー名追加
   * @param tileName キー名
   * @returns {void}
   */
  addAdditionTileName(tileName: string): void {
    this._additionTileSet.add(tileName);
    this.listener(this);
  }

  /**
   * 任意タイル地図のキー名削除
   * @param tileName キー名
   * @returns {void}
   */
  removeAdditionTileName(tileName: string): void {
    this._additionTileSet.delete(tileName);
    this.listener(this);
  }
}
export {MapStatus};
