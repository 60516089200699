/* eslint-disable @typescript-eslint/no-explicit-any */
// 出典元：https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/geojson/index.d.ts

/** GeoJSONオブジェクトのtype値 */
export type GeoJsonTypes = GeoJSON['type'];
/** ジオメトリオブジェクトのtype値 */
export type GeoJsonGeometryTypes = GeoJsonGeometry['type'];
/** バウンディングボックス(座標範囲) */
export type BBox = [number, number, number, number] | [number, number, number, number, number, number];
/** GeoJSONオブジェクトが持つ座標 */
export type Position = number[];

/** GeoJSONオブジェクト */
export interface GeoJsonObject {
  /** 種別 */
  type: GeoJsonTypes;
  /** バウンディングボックス(座標範囲) */
  bbox?: BBox;
}

/**
 * GeoJsonObjectのType Guard
 * @param item 判定対象
 * @returns GeoJsonObject型か
 */
export const isGeoJsonObject = (item: any): item is GeoJsonObject => {
  return item.type !== undefined;
};

/** GeoJSONオブジェクト種別 */
export type GeoJSON = GeoJsonGeometry | Feature | FeatureCollection;
/** ジオメトリオブジェクト種別 */
export type GeoJsonGeometry =
  | Point
  | MultiPoint
  | LineString
  | MultiLineString
  | Polygon
  | MultiPolygon
  | GeometryCollection;

/** Pointジオメトリ */
export interface Point extends GeoJsonObject {
  /** 種別 */
  type: 'Point';
  /** 座標 */
  coordinates: Position;
}

/** MultiPointジオメトリ */
export interface MultiPoint extends GeoJsonObject {
  /** 種別 */
  type: 'MultiPoint';
  /** 座標 */
  coordinates: Position[];
}

/** LineStringジオメトリ */
export interface LineString extends GeoJsonObject {
  /** 種別 */
  type: 'LineString';
  /** 座標 */
  coordinates: Position[];
}

/**
 * LineStringのType Guard
 * @param item 判定対象
 * @returns LineString型か
 */
export const isLineString = (item: any): item is LineString => {
  return item.type === 'LineString' && item.coordinates !== undefined;
};

/** MultiLineStringジオメトリ */
export interface MultiLineString extends GeoJsonObject {
  /** 種別 */
  type: 'MultiLineString';
  /** 座標 */
  coordinates: Position[][];
}

/**
 * MultiLineStringのType Guard
 * @param item 判定対象
 * @returns MultiLineString型か
 */
export const isMultiLineString = (item: any): item is MultiLineString => {
  return item.type === 'MultiLineString' && item.coordinates !== undefined;
};

/** Polygonジオメトリ */
export interface Polygon extends GeoJsonObject {
  /** 種別 */
  type: 'Polygon';
  /** 座標 */
  coordinates: Position[][];
}

/**
 * PolygonのType Guard
 * @param item 判定対象
 * @returns Polygon型か
 */
export const isPolygon = (item: any): item is Polygon => {
  return item.type === 'Polygon' && item.coordinates !== undefined;
};

/** MultiPolygonジオメトリ */
export interface MultiPolygon extends GeoJsonObject {
  /** 種別 */
  type: 'MultiPolygon';
  /** 座標 */
  coordinates: Position[][][];
}

/**
 * MultiPolygonのType Guard
 * @param item 判定対象
 * @returns MultiPolygon型か
 */
export const isMultiPolygon = (item: any): item is MultiPolygon => {
  return item.type === 'MultiPolygon' && item.coordinates !== undefined;
};

/** ジオメトリオブジェクトの集合 */
export interface GeometryCollection extends GeoJsonObject {
  /** 種別 */
  type: 'GeometryCollection';
  /** ジオメトリ配列 */
  geometries: GeoJsonGeometry[];
}

/** プロパティ */
export type GeoJsonProperties = {[name: string]: any} | null;

/** Featureオブジェクト */
export interface Feature<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>
  extends GeoJsonObject {
  /** 種別 */
  type: 'Feature';
  /** ジオメトリ */
  geometry: G;
  /** ID */
  id?: string | number;
  /** プロパティ */
  properties: P;
}

/**
 * FeatureのType Guard
 * @param item 判定対象
 * @returns Feature型か
 */
export const isFeature = (item: any): item is Feature => {
  return item.type === 'Feature' && item.geometry !== undefined && item.properties !== undefined;
};

/** FeatureCollectionオブジェクト */
export interface FeatureCollection<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>
  extends GeoJsonObject {
  /** 種別 */
  type: 'FeatureCollection';
  /** Feature配列 */
  features: Array<Feature<G, P>>;
}

/**
 * FeatureCollectionのType Guard
 * @param item 判定対象
 * @returns FeatureCollection型か
 */
export const isFeatureCollection = (item: any): item is FeatureCollection => {
  return item.type === 'FeatureCollection' && item.features !== undefined;
};
