import {mat4} from 'gl-matrix';
import {FramebufferRenderTarget, Optional, RenderTarget} from '../../common/types';
import {Geometry} from '../geometry/Geometry';
import {Object3D} from '../object/Object3D';
import {Program} from './Program';

const FRAME_BUFFER_SIDE = 512;

const vertexShaderSource = `
attribute vec3 vertexPosition;

uniform mat4 model;
uniform mat4 view;
uniform mat4 projection;

varying vec4 position;

void main() {
  gl_Position = projection * view * model * vec4(vertexPosition, 1.0);
  position = gl_Position;
}`;

const fragmentShaderSource = `
precision highp float;

const float PI = 3.1415926;

varying vec4 position;

void main() {
  float radian = abs(position.x / position.w) * 0.5 * PI;
  float xDiff = -0.05 * cos(radian) + 1.05;
  float depth = clamp(position.z / position.w, 0.0, 1.0) * xDiff;
  gl_FragColor = vec4(vec3(depth), 1.0);
}`;

let VERTEX_SHADER: Optional<WebGLShader>;
let FRAGMENT_SHADER: Optional<WebGLShader>;
let PROGRAM: Optional<WebGLProgram>;

/**
 * 深度描画用のプログラム
 */
class DepthProgram implements Program {
  context: WebGLRenderingContext;
  geometry: Geometry;

  vertexShader: Optional<WebGLShader>;
  fragmentShader: Optional<WebGLShader>;
  program: Optional<WebGLProgram>;

  private modelLocation: Optional<WebGLUniformLocation>;
  private viewLocation: Optional<WebGLUniformLocation>;
  private projectionLocation: Optional<WebGLUniformLocation>;

  private modelMatrix: mat4;
  private translationMatrix: mat4;
  private rotationMatrix: mat4;
  private scaleMatrix: mat4;

  private vertexBuffer: Optional<WebGLBuffer>;
  private indexBuffer: Optional<WebGLBuffer>;

  private typedVertices: Float32Array;
  private typedIndices: Uint16Array;

  public static frameBuffer: Optional<WebGLFramebuffer> = undefined;
  public static targetTexture: Optional<WebGLTexture> = undefined;
  public static renderTarget: Optional<FramebufferRenderTarget> = undefined;

  /**
   * バッファをクリアする
   * @param gl WebGLRenderingContext
   * @returns {void}
   */
  static clearBuffer(gl: WebGLRenderingContext): void {
    if (!DepthProgram.renderTarget) {
      return;
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, DepthProgram.renderTarget.target);
    gl.clearColor(1.0, 1.0, 1.0, 1.0);
    gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry) {
    this.context = context;

    this.modelMatrix = mat4.create();
    this.translationMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.scaleMatrix = mat4.create();

    this.typedVertices = new Float32Array();
    this.typedIndices = new Uint16Array();

    this.setupProgram();
    this.initializeFrameBuffer();
    this.setGeometry(geometry);
    this.setupGeometry(geometry);

    this.geometry = geometry;
  }

  /**
   * プログラムを準備
   * @returns {void}
   */
  setupProgram(): void {
    if (!VERTEX_SHADER) {
      const mayBeVertexShader: WebGLShader | null = this.context.createShader(this.context.VERTEX_SHADER);
      if (mayBeVertexShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create vertex shader');
        return;
      }
      VERTEX_SHADER = mayBeVertexShader;
      this.context.shaderSource(VERTEX_SHADER, vertexShaderSource);
      this.context.compileShader(VERTEX_SHADER);

      const vertexShaderCompileStatus = this.context.getShaderParameter(VERTEX_SHADER, this.context.COMPILE_STATUS);
      if (!vertexShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(VERTEX_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
        return;
      }
    }
    this.vertexShader = VERTEX_SHADER;

    if (!FRAGMENT_SHADER) {
      const mayBeFragmentShader: WebGLShader | null = this.context.createShader(this.context.FRAGMENT_SHADER);
      if (mayBeFragmentShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create fragment shader');
        return;
      }
      FRAGMENT_SHADER = mayBeFragmentShader;
      this.context.shaderSource(FRAGMENT_SHADER, fragmentShaderSource);
      this.context.compileShader(FRAGMENT_SHADER);

      const fragmentShaderCompileStatus = this.context.getShaderParameter(FRAGMENT_SHADER, this.context.COMPILE_STATUS);
      if (!fragmentShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(FRAGMENT_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.fragmentShader = FRAGMENT_SHADER;

    if (!PROGRAM) {
      const mayBeProgram = this.context.createProgram();
      if (mayBeProgram === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create program');
        return;
      }
      PROGRAM = mayBeProgram;
      this.context.attachShader(PROGRAM, this.vertexShader);
      this.context.attachShader(PROGRAM, this.fragmentShader);
      this.context.linkProgram(PROGRAM);

      const linkStatus = this.context.getProgramParameter(PROGRAM, this.context.LINK_STATUS);
      if (!linkStatus) {
        const info = this.context.getProgramInfoLog(PROGRAM);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.program = PROGRAM;

    this.context.useProgram(this.program);
  }

  /**
   * フレームバッファを初期化
   * @returns {void}
   */
  private initializeFrameBuffer(): void {
    const gl = this.context;
    if (!DepthProgram.frameBuffer || !DepthProgram.targetTexture) {
      const framebuffer = gl.createFramebuffer();
      if (!framebuffer) {
        return;
      }
      DepthProgram.frameBuffer = framebuffer;
      gl.bindFramebuffer(gl.FRAMEBUFFER, DepthProgram.frameBuffer);

      const depthRenderBuffer = gl.createRenderbuffer();
      gl.bindRenderbuffer(gl.RENDERBUFFER, depthRenderBuffer);
      gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT16, FRAME_BUFFER_SIDE, FRAME_BUFFER_SIDE);
      gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, depthRenderBuffer);

      gl.clearColor(0.0, 0.0, 0.0, 1.0);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      DepthProgram.targetTexture = gl.createTexture();
      gl.bindTexture(gl.TEXTURE_2D, DepthProgram.targetTexture);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        FRAME_BUFFER_SIDE,
        FRAME_BUFFER_SIDE,
        0,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        null
      );
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, DepthProgram.targetTexture, 0);

      gl.bindTexture(gl.TEXTURE_2D, null);
      gl.bindRenderbuffer(gl.RENDERBUFFER, null);
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);

      DepthProgram.renderTarget = {
        type: 'framebuffer',
        size: {
          width: FRAME_BUFFER_SIDE,
          height: FRAME_BUFFER_SIDE,
        },
        target: framebuffer,
      };
    }
  }

  /**
   * ジオメトリを準備
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setupGeometry(geometry: Geometry): void {
    if (!this.program) {
      return;
    }

    const gl = this.context;
    this.geometry = geometry;

    this.context.useProgram(this.program);

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertexAttribLocation = gl.getAttribLocation(this.program, 'vertexPosition');

    const VERTEX_SIZE = 3;
    const TEXTURE_SIZE = 2;
    const STRIDE = (VERTEX_SIZE + TEXTURE_SIZE) * Float32Array.BYTES_PER_ELEMENT;
    const VERTEX_OFFSET = 0;

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(vertexAttribLocation);
    gl.vertexAttribPointer(vertexAttribLocation, VERTEX_SIZE, gl.FLOAT, false, STRIDE, VERTEX_OFFSET);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.geometry = geometry;

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertices = this.geometry.getVertices();
    const verticesLength = vertices.length;
    if (this.typedVertices.length !== verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    const indices = this.geometry.getIndices();
    const indicesLength = indices.length;
    if (this.typedIndices.length !== indicesLength) {
      this.typedIndices = new Uint16Array(indicesLength);
    }
    this.typedIndices.set(indices);

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);
    this.context.bufferData(this.context.ARRAY_BUFFER, this.typedVertices, this.context.STATIC_DRAW);

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    this.context.bufferData(this.context.ELEMENT_ARRAY_BUFFER, this.typedIndices, this.context.STATIC_DRAW);
  }

  /**
   * 行列情報を更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    if (!this.program) {
      return;
    }

    // model
    mat4.identity(this.scaleMatrix);
    mat4.identity(this.rotationMatrix);
    mat4.identity(this.translationMatrix);
    mat4.identity(this.modelMatrix);

    mat4.scale(this.scaleMatrix, this.scaleMatrix, object3D.scale.toFloat32Array());
    mat4.multiply(this.rotationMatrix, this.rotationMatrix, object3D.rotation.toMat4());
    mat4.translate(this.translationMatrix, this.translationMatrix, object3D.position.toFloat32Array());

    mat4.multiply(this.modelMatrix, this.modelMatrix, this.translationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.rotationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.scaleMatrix);

    this.context.useProgram(this.program);
    if (!this.modelLocation) {
      this.modelLocation = this.context.getUniformLocation(this.program, 'model');
    }
    if (!this.viewLocation) {
      this.viewLocation = this.context.getUniformLocation(this.program, 'view');
    }
    if (!this.projectionLocation) {
      this.projectionLocation = this.context.getUniformLocation(this.program, 'projection');
    }
    this.context.uniformMatrix4fv(this.modelLocation, false, this.modelMatrix);
    this.context.uniformMatrix4fv(this.viewLocation, false, viewMatrix);
    this.context.uniformMatrix4fv(this.projectionLocation, false, projectionMatrix);
  }

  /**
   * 描画
   * @param renderTarget 描画対象の設定
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    this.setupGeometry(this.geometry);

    // DepthProgramはフレームバッファへの書き込み前提
    if (!renderTarget || renderTarget.type !== 'framebuffer') {
      return;
    }

    const gl = this.context;

    gl.bindFramebuffer(gl.FRAMEBUFFER, renderTarget.target);
    gl.viewport(0, 0, renderTarget.size.width, renderTarget.size.height);

    gl.enable(gl.DEPTH_TEST);

    gl.drawElements(gl.TRIANGLES, this.geometry.getIndices().length, gl.UNSIGNED_SHORT, 0);

    gl.disable(gl.CULL_FACE);

    gl.disable(gl.DEPTH_TEST);
    gl.disable(gl.BLEND);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const gl = this.context;
    if (PROGRAM && VERTEX_SHADER && FRAGMENT_SHADER) {
      gl.detachShader(PROGRAM, VERTEX_SHADER);
      gl.detachShader(PROGRAM, FRAGMENT_SHADER);
      gl.deleteProgram(PROGRAM);
      PROGRAM = undefined;
    }
    if (VERTEX_SHADER) {
      gl.deleteShader(VERTEX_SHADER);
      VERTEX_SHADER = undefined;
    }
    if (FRAGMENT_SHADER) {
      gl.deleteShader(FRAGMENT_SHADER);
      FRAGMENT_SHADER = undefined;
    }
    if (this.indexBuffer) {
      gl.deleteBuffer(this.indexBuffer);
      this.indexBuffer = undefined;
    }
    if (this.vertexBuffer) {
      gl.deleteBuffer(this.vertexBuffer);
      this.vertexBuffer = undefined;
    }
    if (DepthProgram.frameBuffer) {
      gl.deleteFramebuffer(DepthProgram.frameBuffer);
      DepthProgram.frameBuffer = undefined;
    }
    this.viewLocation = undefined;
    this.modelLocation = undefined;
    this.projectionLocation = undefined;
  }
}

export {DepthProgram, FRAME_BUFFER_SIDE};
