import {MapStatus} from '../models/MapStatus';
import {ArrayList} from '../../common/collection/ArrayList';
import {TileNumber, MIN_LAT, MIN_LNG, MAX_LAT, MAX_LNG, createCompareFunctionWithDistance} from '../models/TileNumber';
import {PerspectiveCamera} from '../../engine/camera/PerspectiveCamera';
import {Camera} from '../../engine/camera/Camera';
import {Vector3} from '../../common/math/Vector3';
import {Ray3} from '../../common/math/Ray3';
import {Vector2} from '../../common/math/Vector2';
import {Optional, ZoomLevelFixFunc} from '../../common/types';
import {Ray2} from '../../common/math/Ray2';
import {Rect2} from '../../common/math/Rect2';
import {
  calculatePixelCoordinate,
  calculatePixelToUnit,
  calculateWorldCoordinate,
  pixelToWorld,
  worldToLatLng,
  getZoomLevelFixFunc,
} from '../utils/MapUtil';
import {GaiaContext} from '../GaiaContext';

const QUATER_OF_PI = Math.PI / 4;
const THREE_QUATERS_OF_PI = QUATER_OF_PI * 3;

/**
 * 画面内のタイルをリストアップするクラス
 * @packageDocumentation
 */

/**
 * タイル番号からGL座標矩形を計算
 * @param tileNumber タイル番号
 * @returns GL空間座標矩形
 */
const tileToRect = (tileNumber: TileNumber): Rect2 => {
  const topLeftPixel = tileNumber.calculatePixelCoordinate(Vector2.zero());
  const bottomRightPixel = tileNumber.calculatePixelCoordinate(Vector2.one());
  const topLeftWorld = pixelToWorld(topLeftPixel, tileNumber.z);
  const bottomRightWorld = pixelToWorld(bottomRightPixel, tileNumber.z);
  const rect2 = new Rect2(topLeftWorld.toVector2(), bottomRightWorld.toVector2());
  return rect2;
};

/**
 * ビュー上の点をGL空間の点に変換する
 * @param mapStatus 地図状態
 * @param camera カメラ
 * @param viewPoint ビュー上の点
 * @param upVector カメラの上向きベクトル
 * @param rightVector カメラの右向きベクトル
 * @returns GL空間上の点
 */
const viewPointToIntersectionForPerspectiveCamera = (
  mapStatus: MapStatus,
  camera: PerspectiveCamera,
  viewPoint: Vector2,
  upVector: Vector3,
  rightVector: Vector3
): Vector3 => {
  const ptu = calculatePixelToUnit(mapStatus.zoomLevel);

  const halfWidth = (mapStatus.clientWidth * ptu) / 2;
  const halfHeight = (mapStatus.clientHeight * ptu) / 2;
  const toTopVector = upVector._normalize()._multiply(halfHeight * viewPoint.y);
  const toRightVector = rightVector._normalize()._multiply(halfWidth * viewPoint.x);
  const toTopRightVector = toTopVector._add(toRightVector);

  const ray: Ray3 = new Ray3(camera.position, toTopRightVector._subtract(camera.position));
  const mayBeIntersection = ray.intersectsWithPlaneZEqualsParameter(0);

  if (!mayBeIntersection) {
    return Vector3.zero();
  }

  return mayBeIntersection;
};

/**
 * タイルリストに補完描画用のタイルを追加する
 * @param tileList タイル番号のリスト
 * @returns {void}
 */
// const completionUpperTiles = (tileList: ArrayList<TileNumber>): void => {
//   const completionTileList: ArrayList<TileNumber> = ArrayList.empty<TileNumber>();
//   tileList.forEach((tileNumber: TileNumber) => {
//     const upperTile = tileNumber.upper();
//     if (!upperTile) {
//       return;
//     }
//     if (!completionTileList.contains(upperTile) && !tileList.contains(upperTile)) {
//       completionTileList.push(upperTile);
//     }
//   });
//   completionTileList.forEach((upperTile: TileNumber) => {
//     tileList.push(upperTile);
//   });
// };

/**
 * タイルスキャナー
 */
class MapTileScanner {
  private static readonly tileCache = new Map<string, TileNumber>();

  private static fixIntZoomLevel: ZoomLevelFixFunc = Math.round;

  /**
   * 画面内のタイルをリストアップ
   * @param context コンテキスト
   * @param camera カメラ
   * @param buffer タイル読み込みバッファ
   * @returns 画面内のタイルリスト
   */
  static listUpTileList(context: GaiaContext, camera: Camera, buffer = 2): ArrayList<TileNumber> {
    this.fixIntZoomLevel = getZoomLevelFixFunc(context);
    if (camera instanceof PerspectiveCamera) {
      return MapTileScanner.listUpTileListForPerspectiveCamera(
        context.getMapStatus(),
        camera as PerspectiveCamera,
        buffer
      );
    } else {
      return ArrayList.empty<TileNumber>();
    }
  }

  /**
   * PerspectiveCameraが使用されている場合の、画面内のタイルをリストアップ
   * @param mapStatus 地図状態
   * @param camera カメラ
   * @param buffer タイル読み込みバッファの枚数
   * @returns 画面内のタイルリスト
   */
  private static listUpTileListForPerspectiveCamera(
    mapStatus: MapStatus,
    camera: PerspectiveCamera,
    buffer = 2
  ): ArrayList<TileNumber> {
    const tileList: ArrayList<TileNumber> = ArrayList.empty<TileNumber>();
    const upVector: Vector3 = mapStatus.polar.toUpVector3();
    const rightVector: Vector3 = mapStatus.polar.toRightVector3();
    const centerWorld: Vector3 = calculateWorldCoordinate(mapStatus.centerLocation);
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const bufferPixel = 256 * buffer * Math.SQRT2;
    const offset = mapStatus.centerOffset;

    const rotationBaseRadian = mapStatus.polar.phi + Math.PI / 2;
    const outerX = (bufferPixel + offset.x) * ptu;
    const outerY = 0;

    const radianTopRight = rotationBaseRadian + QUATER_OF_PI;
    const topRight: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      camera,
      new Vector2(1, 1),
      upVector,
      rightVector
    )
      ._add(centerWorld)
      ._add(
        new Vector3(
          outerX * Math.cos(radianTopRight) - outerY * Math.sin(radianTopRight),
          outerX * Math.sin(radianTopRight) + outerY * Math.cos(radianTopRight),
          0
        )
      );

    const radianBottomRight = rotationBaseRadian - QUATER_OF_PI;
    const bottomRight: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      camera,
      new Vector2(1, -1),
      upVector,
      rightVector
    )
      ._add(centerWorld)
      ._add(
        new Vector3(
          outerX * Math.cos(radianBottomRight) - outerY * Math.sin(radianBottomRight),
          outerX * Math.sin(radianBottomRight) + outerY * Math.cos(radianBottomRight),
          0
        )
      );

    const radianBottomLeft = rotationBaseRadian - THREE_QUATERS_OF_PI;
    const bottomLeft: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      camera,
      new Vector2(-1, -1),
      upVector,
      rightVector
    )
      ._add(centerWorld)
      ._add(
        new Vector3(
          outerX * Math.cos(radianBottomLeft) - outerY * Math.sin(radianBottomLeft),
          outerX * Math.sin(radianBottomLeft) + outerY * Math.cos(radianBottomLeft),
          0
        )
      );

    const radianTopLeft = rotationBaseRadian + THREE_QUATERS_OF_PI;
    const topLeft: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      camera,
      new Vector2(-1, 1),
      upVector,
      rightVector
    )
      ._add(centerWorld)
      ._add(
        new Vector3(
          outerX * Math.cos(radianTopLeft) - outerY * Math.sin(radianTopLeft),
          outerX * Math.sin(radianTopLeft) + outerY * Math.cos(radianTopLeft),
          0
        )
      );

    const z = this.fixIntZoomLevel(mapStatus.zoomLevel);
    tileList.merge(MapTileScanner.listUpTilesOnLineSegment(z, topRight, bottomRight));
    tileList.merge(MapTileScanner.listUpTilesOnLineSegment(z, bottomRight, bottomLeft));
    tileList.merge(MapTileScanner.listUpTilesOnLineSegment(z, bottomLeft, topLeft));
    tileList.merge(MapTileScanner.listUpTilesOnLineSegment(z, topLeft, topRight));
    MapTileScanner.fillTileList(tileList, z);

    const mapCenter = mapStatus.centerLocation;
    tileList.sort(createCompareFunctionWithDistance(mapCenter));

    return tileList;
  }

  /**
   * 2点間を直線で結んだ際に重なるタイルのリストを計算
   * @param z 整数に変換したズームレベル
   * @param s 始点
   * @param t 終点
   * @returns タイルのリスト
   */
  private static listUpTilesOnLineSegment(z: number, s: Vector3, t: Vector3): ArrayList<TileNumber> {
    const tileList: ArrayList<TileNumber> = ArrayList.empty<TileNumber>();
    const s2 = s.toVector2();
    const t2 = t.toVector2();

    MapTileScanner.addTileFromWorld(tileList, s2, z);
    MapTileScanner.addTileFromWorld(tileList, t2, z);

    const slope = s2.x === t2.x ? 2 : (t2.y - s2.y) / (t2.x - s2.x);
    if (Math.abs(slope) < 1) {
      const ray2 = s2.x < t2.x ? new Ray2(s2, t2._subtract(s2)) : new Ray2(t2, s2._subtract(t2));
      const minTile = MapTileScanner.worldToTileNumber(s2.x < t2.x ? s2 : t2, z);
      const maxTile = MapTileScanner.worldToTileNumber(s2.x < t2.x ? t2 : s2, z);
      const minX = tileToRect(minTile).right;
      const maxX = tileToRect(maxTile).left;
      const side = 2 ** (24 - z);
      const half = side / 2;
      for (let x = minX; x <= maxX; x += side) {
        const mayBeIntersection: Optional<Vector2> = ray2.intersectsWithLineXEqualsParameter(x);
        if (!mayBeIntersection) {
          return tileList;
        }
        const y = mayBeIntersection.y;
        MapTileScanner.addTileFromWorld(tileList, new Vector2(x - half, y), z);
        MapTileScanner.addTileFromWorld(tileList, new Vector2(x + half, y), z);
      }
    } else {
      const ray2 = s2.y < t2.y ? new Ray2(s2, t2._subtract(s2)) : new Ray2(t2, s2._subtract(t2));
      const minTile = MapTileScanner.worldToTileNumber(s2.y < t2.y ? s2 : t2, z);
      const maxTile = MapTileScanner.worldToTileNumber(s2.y < t2.y ? t2 : s2, z);
      const minY = tileToRect(minTile).top;
      const maxY = tileToRect(maxTile).bottom;
      const side = 2 ** (24 - z);
      const half = side / 2;
      for (let y = minY; y <= maxY; y += side) {
        const mayBeIntersection: Optional<Vector2> = ray2.intersectsWithLineYEqualsParameter(y);
        if (!mayBeIntersection) {
          return tileList;
        }
        const x = mayBeIntersection.x;
        MapTileScanner.addTileFromWorld(tileList, new Vector2(x, y - half), z);
        MapTileScanner.addTileFromWorld(tileList, new Vector2(x, y + half), z);
      }
    }

    return tileList;
  }

  /**
   * GL空間の座標をタイル番号に変換
   * @param tileList タイルリスト
   * @param world GL空間の座標
   * @param zoomLevel ズームレベル
   * @returns {void}
   */
  private static addTileFromWorld(tileList: ArrayList<TileNumber>, world: Vector2, zoomLevel: number): void {
    const tileNumber = MapTileScanner.worldToTileNumber(world, zoomLevel);
    if (tileList.contains(tileNumber)) {
      return;
    }
    tileList.push(tileNumber);
  }

  /**
   * GL空間座標をタイル番号を計算する
   * @param world GL空間座標
   * @param zoomLevel ズームレベル
   * @returns タイル番号、変換できない場合はnull
   */
  private static worldToTileNumber(world: Vector2, zoomLevel: number): TileNumber {
    const latLng = worldToLatLng(world.toVector3(), zoomLevel);
    let lat = latLng.lat;
    while (lat < MIN_LAT) {
      lat += 180;
    }
    while (lat > MAX_LAT) {
      lat -= 180;
    }

    let lng = latLng.lng;
    while (lng < MIN_LNG) {
      lng += 360;
    }
    while (lng > MAX_LNG) {
      lng -= 360;
    }

    const pixelCoordinate: Vector2 = calculatePixelCoordinate(latLng, zoomLevel);
    const x = Math.floor(pixelCoordinate.x / 256);
    const y = Math.floor(pixelCoordinate.y / 256);
    const z = Math.floor(zoomLevel);
    return MapTileScanner.getTileNumberFromPool(x, y, z);
  }

  /**
   * 与えられたタイルリストを塗りつぶし、間にあったタイルをリストに追加する
   * @param tileList 元になるタイルリスト
   * @param zoomLevel 整数のズームレベル
   * @returns {void}
   */
  private static fillTileList(tileList: ArrayList<TileNumber>, zoomLevel: number): void {
    const tileMap: {[x: number]: TileNumber[]} = {};
    tileList.forEach((tileNumber: TileNumber): void => {
      if (!tileMap[tileNumber.x]) {
        tileMap[tileNumber.x] = [];
      }
      tileMap[tileNumber.x].push(tileNumber);
    });
    for (const [x, tiles] of Object.entries(tileMap)) {
      let minY = tiles[0].y;
      let maxY = tiles[0].y;
      for (const tile of tiles) {
        if (minY > tile.y) {
          minY = tile.y;
        }
        if (maxY < tile.y) {
          maxY = tile.y;
        }
      }
      for (let y = minY + 1; y < maxY; y++) {
        const newTile = MapTileScanner.getTileNumberFromPool(parseInt(x, 10), y, zoomLevel);
        if (!tileList.contains(newTile)) {
          tileList.push(newTile);
        }
      }
    }
  }

  /**
   * TileNumberをPoolから取得する
   * @param x xの値
   * @param y yの値
   * @param z zの値
   * @returns TileNumber
   */
  private static getTileNumberFromPool(x: number, y: number, z: number): TileNumber {
    const key = `${x},${y},${z}`;
    if (!MapTileScanner.tileCache.has(key)) {
      MapTileScanner.tileCache.set(key, new TileNumber(x, y, z));
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return MapTileScanner.tileCache.get(key)!;
  }
}

export {MapTileScanner, viewPointToIntersectionForPerspectiveCamera};
