import {RainfallGradationConditionInitOptions} from '../types';
import {Color} from './Color';

/* eslint-disable prettier/prettier */
const DEFAULT_COLOR_MAP = new Map();
DEFAULT_COLOR_MAP.set( 0.0 / 1.0, new Color(149 / 255.0, 194 / 255.0, 232 / 255.0,   0 / 255.0));
DEFAULT_COLOR_MAP.set( 1.0 / 1.0, new Color(149 / 255.0, 194 / 255.0, 232 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set( 2.0 / 1.0, new Color(111 / 255.0, 175 / 255.0, 229 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set( 3.0 / 1.0, new Color( 71 / 255.0, 155 / 255.0, 227 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set( 4.0 / 1.0, new Color( 30 / 255.0, 175 / 255.0, 209 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set( 5.0 / 1.0, new Color( 31 / 255.0, 199 / 255.0, 154 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set( 6.0 / 1.0, new Color( 58 / 255.0, 196 / 255.0,  60 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set( 7.0 / 1.0, new Color(140 / 255.0, 198 / 255.0,  10 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set( 8.0 / 1.0, new Color(198 / 255.0, 198 / 255.0,  24 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set( 9.0 / 1.0, new Color(204 / 255.0, 165 / 255.0,  32 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set(10.0 / 1.0, new Color(222 / 255.0, 128 / 255.0,  17 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set(11.0 / 1.0, new Color(224 / 255.0,  85 / 255.0,  16 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set(12.0 / 1.0, new Color(204 / 255.0,   6 / 255.0,   6 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set(13.0 / 1.0, new Color(141 / 255.0,   3 / 255.0,  61 / 255.0, 178 / 255.0));
DEFAULT_COLOR_MAP.set(14.0 / 1.0, new Color( 93 / 255.0,   1 / 255.0,  72 / 255.0, 178 / 255.0));
/* eslint-enable prettier/prettier */

/**
 * グラデーション版降雨・降雪情報コンディション
 */
class RainfallGradationCondition {
  readonly offset: number;
  readonly colorMap: Map<number, Color>;
  readonly server?: string;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param server タイル取得向き先URL
   */
  constructor(options: RainfallGradationConditionInitOptions, server?: string) {
    this.offset = this.calcOffset(options);
    this.colorMap = options.colorMap ?? DEFAULT_COLOR_MAP;
    this.server = server;
  }

  /**
   * offsetを算出し取得する
   * @param options RainfallConditionInitOptions
   * @returns rainfallOffset number
   */
  private calcOffset(options: RainfallGradationConditionInitOptions): number {
    if (options.offset) {
      return Math.floor(options.offset);
    } else {
      // 現在時刻取得
      const currentDate = new Date();
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);
      // 指定時刻取得
      const time = options.time ?? new Date();
      time.setSeconds(0);
      time.setMilliseconds(0);
      // 現在時刻と指定時刻とoffset取得
      const diffTime = time.getTime() - currentDate.getTime();
      return Math.floor(diffTime / (1000 * 60));
    }
  }
}

export {RainfallGradationCondition};
