import {GaiaContext} from '../GaiaContext';
import {Camera} from '../../engine/camera/Camera';
import {Point} from '../../../gaia/value/Point';
import {DOMObject} from '../../../gaia/object/DOMObject';
import {calculateWorldCoordinate} from '../utils/MapUtil';
import {getDevicePixelRatio} from '../../common/util/Device';
import {GaIAClassName} from '../../common/util/Styles';

const OUT_OF_CANVAS = new Point(-1000, -1000);

/**
 * DOMオブジェクトを扱うレイヤー
 */
class DOMObjectLayer {
  private readonly context: GaiaContext;
  private layerDom: HTMLDivElement;
  private readonly domList: DOMObject[] = [];

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.context = context;
    this.layerDom = this.setupLayerDom();

    this.context.getBaseElement().appendChild(this.layerDom);
  }

  /**
   * LayerのDOM生成
   * @returns レイヤーDOM
   */
  private setupLayerDom(): HTMLDivElement {
    const layer = document.createElement('div');
    layer.classList.add(GaIAClassName.Layer.Dom);
    return layer;
  }

  /**
   * 要素の追加
   * @param object 要素
   * @returns {void}
   */
  add(object: DOMObject): void {
    this.domList.push(object);
    this.layerDom.appendChild(object.getWrapElement());
  }

  /**
   * 要素の削除
   * @param object 要素
   * @returns {void}
   */
  remove(object: DOMObject): void {
    const idx = this.domList.indexOf(object);
    if (idx < 0) {
      return;
    }
    this.domList.splice(idx, 1);
    this.layerDom.removeChild(object.getWrapElement());
  }

  /**
   * レイヤーの更新
   * @param camera カメラ
   * @returns {void}
   */
  update(camera: Camera): void {
    const centerWP = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);
    const {height, width} = this.context.getCanvasElement();
    const devicePixelRatio = getDevicePixelRatio();

    for (const object of this.domList) {
      const objectWP = calculateWorldCoordinate(object.getPosition());
      const clientPos = camera.worldToClient(objectWP._subtract(centerWP));
      if (!clientPos) {
        // 位置が計算できない場合は画面外に配置することで表示されないようにする
        object.updateDrawPosition(OUT_OF_CANVAS);
        continue;
      }
      const point = new Point(
        (width / devicePixelRatio) * ((clientPos.x + 1.0) / 2),
        (height / devicePixelRatio) * ((-clientPos.y + 1.0) / 2)
      );
      object.updateDrawPosition(point, this.context.getMapStatus().zoomLevel);
    }
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.domList.length = 0;
  }

  /** @override */
  usesFramebuffer(): boolean {
    return false;
  }
}

export {DOMObjectLayer};
