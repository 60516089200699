/* eslint-disable @typescript-eslint/no-explicit-any */
/** 注記メタ情報 */
export type AnnotationMetaInfo = {
  /** シリアル */
  serial: AnnotationSerial;
  /** 注記データの固有識別子 */
  identifier: string;
  /** アイコン設定 */
  iconHeader: AnnotationIconHeader;
  /** マーク設定 */
  markHeader: AnnotationIconHeader;
};

/** シリアル */
export type AnnotationSerial = {
  /** 地図データのシリアル */
  data: number;
  /** パレットのシリアル */
  palette: number;
};

/** アイコン系設定情報 */
export type AnnotationIconHeader = {
  length: number;
  chainX: number;
  chainY: number;
  iconWidth: number;
  iconHeight: number;
  mapping: AnnotationIconMapping;
};

/** アイコンマッピング情報 */
export type AnnotationIconMapping = {
  [ntjCode: string]: {
    x: number;
    y: number;
  };
};

/** 注記メイン情報 */
export type AnnotationMainInfo = {
  /** 言語 */
  lang: string;
  /** 文字・アイコン注記グループ */
  note: NoteAnnotationInfoGroup[];
  /** 国道注記情報 */
  mark: MarkAnnotationInfo[];
  /** パレット情報 */
  palette: PaletteInfo;
};

/** 文字・アイコン注記グループ */
export type NoteAnnotationInfoGroup = {
  /** グルーピングID */
  groupingId: string;
  /** 文字・アイコン注記情報 */
  group: NoteAnnotationInfo[];
};

/** 注記情報 */
export type AnnotationInfo = NoteAnnotationInfo | MarkAnnotationInfo;

/** 注記共通プロパティ */
export type CommonAnnotationInfo = {
  /** 注記種別 */
  type: string;
  /** 描画プライオリティ */
  priority: number;
  /** NTJコード */
  ntjCode: number;
  /** 緯度(度表記) */
  lat: number;
  /** 経度(度表記) */
  lon: number;
};

/** 文字・アイコン注記情報 */
export type NoteAnnotationInfo = TextAnnotationInfo | IconAnnotationInfo;

/** 文字注記情報 */
export type TextAnnotationInfo = CommonAnnotationInfo & {
  /** 表示テキスト */
  appearance: string;
  /** 角度 */
  angle: number;
  /** 横書きフラグ */
  isHorizon: boolean;
  /** 乗り換え検索用ノードID */
  nodeId?: string;
};

/** アイコン注記情報 */
export type IconAnnotationInfo = CommonAnnotationInfo & {
  /** 角度 */
  angle: number;
  /** 横書きフラグ */
  isHorizon: boolean;
  /** 乗り換え検索用ノードID */
  nodeId?: string;
};

/** 国道アイコン注記情報 */
export type MarkAnnotationInfo = CommonAnnotationInfo & {
  /** 表示テキスト */
  appearance: string;
};

/** パレット情報 */
export type PaletteInfo = {
  [ntjCode: string]: {
    note?: NotePaletteConfig;
    icon?: IconPaletteConfig;
    mark?: MarkPaletteConfig;
  };
};

/** パレット共通プロパティ */
export type CommonPaletteConfig = {
  size: number;
  visible: boolean;
};

/** 文字注記パレット情報 */
export type NotePaletteConfig = CommonPaletteConfig & {
  force: boolean;
  offset: {
    x: number;
    y: number;
  };
  color: PaletteColorInfo;
  backgroundColor: PaletteColorInfo;
  font: PaletteFontInfo;
  noteType: {
    id: number;
    dot: boolean;
  };
  anchor: number;
};

/** アイコン注記パレット情報 */
export type IconPaletteConfig = CommonPaletteConfig & {
  force: boolean;
  name: string;
};

/** 国道アイコンパレット情報 */
export type MarkPaletteConfig = CommonPaletteConfig & {
  name: string;
  color: PaletteColorInfo;
  width: number;
  font: PaletteFontInfo;
};

/** カラー情報 */
export type PaletteColorInfo = {
  r: number;
  g: number;
  b: number;
  a: number;
};

/** フォント情報 */
export type PaletteFontInfo = {
  id: number;
  bold: boolean;
  italic: boolean;
  underline: boolean;
};

/** 描画タイプ */
export type PaletteNoteType =
  // 通常
  | 'normal'
  // 縁取り
  | 'bordered-text'
  // 座布団
  | 'rect'
  // 座布団(フチ有)
  | 'bordered-rect'
  // 座布団(角丸)
  | 'round-rect'
  // 座布団(フチ有・角丸)
  | 'bordered-round-rect'
  // バルーン
  | 'balloon'
  // バルーン(フチ有)
  | 'bordered-balloon'
  // バルーン(角丸)
  | 'round-balloon'
  // バルーン(フチ有・角丸)
  | 'bordered-round-balloon';

/**
 * 描画タイプ番号からPaletteNoteTypeへ変換
 * @param number 描画タイプ番号
 * @returns PaletteNoteType
 */
export const convertToPaletteNoteType = (number: number): PaletteNoteType => {
  switch (number) {
    case 0:
      return 'normal';
    case 1:
      return 'rect';
    case 2:
      return 'bordered-text';
    case 3:
      return 'bordered-rect';
    case 4:
      return 'balloon';
    case 5:
      return 'bordered-balloon';
    case 6:
      return 'round-rect';
    case 7:
      return 'bordered-round-rect';
    case 8:
      return 'round-balloon';
    case 9:
      return 'bordered-round-balloon';
    default:
      return 'normal';
  }
};

/** アンカー */
export type PaletteAnchor =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'left'
  | 'center'
  | 'right'
  | 'bottom-left'
  | 'bottom'
  | 'bottom-right';

/**
 * アンカー番号からPaletteAnchorへ変換
 * @param number アンカー番号
 * @returns PaletteAnchor
 */
export const convertToPaletteAnchor = (number: number): PaletteAnchor => {
  switch (number) {
    case 1:
      return 'top-left';
    case 2:
      return 'top';
    case 3:
      return 'top-right';
    case 4:
      return 'left';
    case 5:
      return 'center';
    case 6:
      return 'right';
    case 7:
      return 'bottom-left';
    case 8:
      return 'bottom';
    case 9:
      return 'bottom-right';
    default:
      return 'center';
  }
};

/**
 * AnnotationMetaInfoのType Guard
 * @param item 判定対象
 * @returns AnnotationMetaInfo型か
 */
export const isAnnotationMetaInfo = (item: any): item is AnnotationMetaInfo => {
  return (
    item !== undefined &&
    item.serial !== undefined &&
    item.identifier !== undefined &&
    item.iconHeader !== undefined &&
    item.markHeader !== undefined
  );
};

/**
 * AnnotationMainInfoのType Guard
 * @param item 判定対象
 * @returns AnnotationMainInfo型か
 */
export const isAnnotationMainInfo = (item: any): item is AnnotationMainInfo => {
  return (
    item !== undefined &&
    item.lang !== undefined &&
    item.note !== undefined &&
    item.mark !== undefined &&
    item.palette !== undefined
  );
};

/**
 * AnnotationInfoのType Guard
 * @param item 判定対象
 * @returns AnnotationInfo型か
 */
export const isAnnotationInfo = (item: any): boolean => {
  return (
    item !== undefined &&
    item.type !== undefined &&
    item.priority !== undefined &&
    item.ntjCode !== undefined &&
    item.lat !== undefined &&
    item.lon !== undefined
  );
};

/**
 * TextAnnotationInfoのType Guard
 * @param item 判定対象
 * @returns TextAnnotationInfo型か
 */
export const isTextAnnotationInfo = (item: any): item is TextAnnotationInfo => {
  return isAnnotationInfo(item) && item.type === 'text' && item.appearance !== undefined;
};

/**
 * IconAnnotationInfoのType Guard
 * @param item 判定対象
 * @returns IconAnnotationInfo型か
 */
export const isIconAnnotationInfo = (item: any): item is IconAnnotationInfo => {
  return isAnnotationInfo(item) && item.type === 'icon';
};

/**
 * NoteAnnotationInfoのType Guard
 * @param item 判定対象
 * @returns NoteAnnotationInfo型か
 */
export const isNoteAnnotationInfo = (item: any): item is NoteAnnotationInfo => {
  return isTextAnnotationInfo(item) || isIconAnnotationInfo(item);
};

/**
 * MarkAnnotationInfoのType Guard
 * @param item 判定対象
 * @returns MarkAnnotationInfo型か
 */
export const isMarkAnnotationInfo = (item: any): item is MarkAnnotationInfo => {
  return isAnnotationInfo(item) && item.appearance !== undefined;
};

/**
 * PaletteConfigのType Guard
 * @param item 判定対象
 * @returns PaletteConfig型か
 */
export const isPaletteConfig = (item: any): boolean => {
  return item !== undefined && item.size !== undefined && item.visible !== undefined;
};

/**
 * NotePaletteConfigのType Guard
 * @param item 判定対象
 * @returns NotePaletteConfig型か
 */
export const isNotePaletteConfig = (item: any): item is NotePaletteConfig => {
  return (
    isPaletteConfig(item) &&
    item.force !== undefined &&
    item.offset !== undefined &&
    item.color !== undefined &&
    item.backgroundColor !== undefined &&
    item.font !== undefined &&
    item.noteType !== undefined &&
    item.anchor !== undefined
  );
};

/**
 * IconPaletteConfigのType Guard
 * @param item 判定対象
 * @returns IconPaletteConfig型か
 */
export const isIconPaletteConfig = (item: any): item is IconPaletteConfig => {
  return isPaletteConfig(item) && item.force !== undefined && item.name !== undefined;
};

/**
 * MarkPaletteConfigのType Guard
 * @param item 判定対象
 * @returns MarkPaletteConfig型か
 */
export const isMarkPaletteConfig = (item: any): item is MarkPaletteConfig => {
  return (
    isPaletteConfig(item) &&
    item.name !== undefined &&
    item.color !== undefined &&
    item.width !== undefined &&
    item.font !== undefined
  );
};
