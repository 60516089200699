import {Camera} from '../../../../engine/camera/Camera';
import {GaiaContext} from '../../../GaiaContext';
import {FogObjectLayer, LAYER_NAME_FOG} from '../../../layer/FogObjectLayer';
import {MapStatus} from '../../../models/MapStatus';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';

/**
 * 霧オブジェクトを扱う描画キット
 */
class FogObjectRenderKit extends AbstractObjectRenderKit {
  private fogObjectLayer: FogObjectLayer;
  private camera: Camera;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param fogObjectLayer レイヤー
   * @param renderKitCtl MapRenderKitController
   * @param camera カメラ
   */
  constructor(
    context: GaiaContext,
    fogObjectLayer: FogObjectLayer,
    renderKitCtl: MapRenderKitController,
    camera: Camera
  ) {
    super(context, fogObjectLayer, renderKitCtl);
    this.fogObjectLayer = fogObjectLayer;
    this.camera = camera;
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return LAYER_NAME_FOG;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.fogObjectLayer.update(mapStatus, this.camera);
  }

  /** @override */
  onDestroy(): void {
    this.fogObjectLayer.destroy();
  }
}

export {FogObjectRenderKit};
