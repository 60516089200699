import {mat4} from 'gl-matrix';
import {Vector3} from '../../common/math/Vector3';
import {Optional} from '../../common/types';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {Object3D} from '../object/Object3D';
import {HeatSpotProgram} from '../program/HeatSpotProgram';
import {Material} from './Material';

/**
 * ヒートマップ上のそれぞれのスポットを描画するマテリアル
 */
class HeatSpotMaterial implements Material {
  readonly program: HeatSpotProgram;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   */
  constructor(context: WebGLRenderingContext, geometry: CustomGeometry) {
    this.program = new HeatSpotProgram(context, geometry);
  }

  /**
   * 描画ターゲットのテクスチャを取得
   * @returns 描画ターゲットテクスチャ
   */
  getTargetTexture(): Optional<WebGLTexture> {
    return this.program.getTargetTexture();
  }

  /**
   * ビューポートをリサイズする
   * @param width 幅
   * @param height 高さ
   * @returns {void}
   */
  viewport(width: number, height: number): void {
    this.program.viewport(width, height);
  }

  /**
   * フレームバッファをクリアする
   * @returns {void}
   */
  clearFrameBuffer(): void {
    this.program.clearFrameBuffer();
  }

  /**
   * 中心座標を設定
   * @param center 中心座標
   * @returns {void}
   */
  setCenter(center: Vector3): void {
    this.program.setCenter(center);
  }

  /**
   * 半径を設定
   * @param radius 半径
   * @returns {void}
   */
  setRadius(radius: number): void {
    this.program.setRadius(radius);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: CustomGeometry): void {
    this.program.setGeometry(geometry);
  }

  /**
   * テクスチャを設定
   * @param image テクスチャ画像
   * @returns {void}
   */
  setTexture(image: TexImageSource): void {
    this.program.setTexture(image);
  }

  /**
   * 更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    this.program.update(object3D, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @returns {void}
   */
  draw(): void {
    this.program.draw();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.program.destroy();
  }
}

export {HeatSpotMaterial};
