import {mat4} from 'gl-matrix';
import {Optional} from '../../common/types';
import {getDevicePixelRatio} from '../../common/util/Device';
import {MapStatus} from '../../map/models/MapStatus';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {Object3D} from '../object/Object3D';
import {Program} from './Program';

const DEFAULT_POINT_SIZE = 4.0;

const vertexShaderSource = `
attribute vec3 vertexPosition;

uniform mat4 model;
uniform mat4 view;
uniform mat4 projection;

uniform float pointSize;

void main() {
  gl_Position = projection * view * model * vec4(vertexPosition, 1.0);
  gl_PointSize = pointSize;
}
`;

const fragmentShaderSource = `
precision mediump float;

uniform sampler2D texture;

uniform int useTexture;

void main(void){

  vec4 smpColor = vec4(0.0, 0.0, 0.0, 1.0);

  if(bool(useTexture)){
    smpColor = texture2D(texture, gl_PointCoord);
  }

  if(smpColor.a == 0.0){
    discard;
  }

  gl_FragColor = smpColor;
}`;

let VERTEX_SHADER: Optional<WebGLShader>;
let FRAGMENT_SHADER: Optional<WebGLShader>;
let PROGRAM: Optional<WebGLProgram>;

const DEFAULT_TEX_PARAMETER_MAP = new Map([
  [WebGLRenderingContext.TEXTURE_WRAP_S, WebGLRenderingContext.CLAMP_TO_EDGE],
  [WebGLRenderingContext.TEXTURE_WRAP_T, WebGLRenderingContext.CLAMP_TO_EDGE],
  [WebGLRenderingContext.TEXTURE_MAG_FILTER, WebGLRenderingContext.LINEAR],
  [WebGLRenderingContext.TEXTURE_MIN_FILTER, WebGLRenderingContext.LINEAR],
]);

/**
 * ポイントスプライトを描画するプログラム
 */
class PointSpriteProgram implements Program {
  context: WebGLRenderingContext;
  geometry: CustomGeometry;

  vertexShader: Optional<WebGLShader>;
  fragmentShader: Optional<WebGLShader>;
  program: Optional<WebGLProgram>;

  private image: Optional<TexImageSource>;
  private texture: WebGLTexture | null;

  private modelLocation: Optional<WebGLUniformLocation>;
  private viewLocation: Optional<WebGLUniformLocation>;
  private projectionLocation: Optional<WebGLUniformLocation>;
  private useTextureLocation: Optional<WebGLUniformLocation>;
  private pointSizeLocation: Optional<WebGLUniformLocation>;

  private modelMatrix: mat4;
  private translationMatrix: mat4;
  private rotationMatrix: mat4;
  private scaleMatrix: mat4;
  private pointSize: number;

  private vertexBuffer: Optional<WebGLBuffer>;

  private typedVertices: Float32Array;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   */
  constructor(context: WebGLRenderingContext, geometry: CustomGeometry) {
    this.context = context;
    this.geometry = geometry;

    this.vertexShader = null;
    this.fragmentShader = null;
    this.program = null;

    this.texture = context.createTexture();
    this.image = null;

    this.modelMatrix = mat4.create();
    this.translationMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.scaleMatrix = mat4.create();
    this.pointSize = DEFAULT_POINT_SIZE;

    this.typedVertices = new Float32Array();

    this.setupProgram();
    this.setGeometry(geometry);
    this.setupGeometry();
  }

  /**
   * シェーダをコンパイルしてプログラムを準備する
   * @returns {void}
   */
  setupProgram(): void {
    if (!VERTEX_SHADER) {
      const mayBeVertexShader: Optional<WebGLShader> = this.context.createShader(this.context.VERTEX_SHADER);
      if (mayBeVertexShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] PointSpriteProgram.setupProgram() could not create vertex shader');
        return;
      }
      VERTEX_SHADER = mayBeVertexShader;
      this.context.shaderSource(VERTEX_SHADER, vertexShaderSource);
      this.context.compileShader(VERTEX_SHADER);

      const vertexShaderCompileStatus = this.context.getShaderParameter(VERTEX_SHADER, this.context.COMPILE_STATUS);
      if (!vertexShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(VERTEX_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
        return;
      }
    }
    this.vertexShader = VERTEX_SHADER;

    if (!FRAGMENT_SHADER) {
      const mayBeFragmentShader: Optional<WebGLShader> = this.context.createShader(this.context.FRAGMENT_SHADER);
      if (mayBeFragmentShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] PointSpriteProgram.setupProgram() could not create fragment shader');
        return;
      }
      FRAGMENT_SHADER = mayBeFragmentShader;
      this.context.shaderSource(FRAGMENT_SHADER, fragmentShaderSource);
      this.context.compileShader(FRAGMENT_SHADER);

      const fragmentShaderCompileStatus = this.context.getShaderParameter(FRAGMENT_SHADER, this.context.COMPILE_STATUS);
      if (!fragmentShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(FRAGMENT_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.fragmentShader = FRAGMENT_SHADER;

    if (!PROGRAM) {
      const mayBeProgram = this.context.createProgram();
      if (mayBeProgram === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] PointSpriteProgram.setupProgram() could not create program');
        return;
      }
      PROGRAM = mayBeProgram;
      this.context.attachShader(PROGRAM, this.vertexShader);
      this.context.attachShader(PROGRAM, this.fragmentShader);
      this.context.linkProgram(PROGRAM);

      const linkStatus = this.context.getProgramParameter(PROGRAM, this.context.LINK_STATUS);
      if (!linkStatus) {
        const info = this.context.getProgramInfoLog(PROGRAM);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.program = PROGRAM;

    this.context.useProgram(this.program);
  }

  /**
   * ジオメトリを読み込む
   * @returns {void}
   */
  setupGeometry(): void {
    if (!this.program) {
      return;
    }

    const gl = this.context;
    gl.useProgram(this.program);

    if (!this.vertexBuffer) {
      this.vertexBuffer = gl.createBuffer();
    }

    const vertexAttribLocation = gl.getAttribLocation(this.program, 'vertexPosition');

    const VERTEX_SIZE = 3;
    const STRIDE = 0;
    const VERTEX_OFFSET = 0;

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(vertexAttribLocation);

    gl.vertexAttribPointer(vertexAttribLocation, VERTEX_SIZE, gl.FLOAT, false, STRIDE, VERTEX_OFFSET);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: CustomGeometry): void {
    this.geometry = geometry;
    const gl = this.context;

    if (!this.vertexBuffer) {
      this.vertexBuffer = gl.createBuffer();
    }

    const vertices = this.geometry.getVertices();
    const verticesLength = vertices.length;
    if (this.typedVertices.length !== verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.typedVertices, gl.STATIC_DRAW);
  }

  /**
   * テクスチャを更新する
   * @param image テクスチャ画像ソース
   * @param texParameterMap テクスチャのパラメータ
   * @returns {void}
   */
  setTexture(image: TexImageSource, texParameterMap: Map<GLenum, GLenum> = DEFAULT_TEX_PARAMETER_MAP): void {
    if (!this.program) {
      return;
    }

    if (this.image === image) {
      return;
    }

    this.image = image;
    const gl = this.context;

    gl.useProgram(this.program);
    gl.bindTexture(gl.TEXTURE_2D, this.texture);
    texParameterMap.forEach((value, key) => {
      this.context.texParameteri(this.context.TEXTURE_2D, key, value);
    });
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  /**
   * 点の大きさを指定（単位はピクセル）
   * @param pointSize 点の大きさ
   * @returns {void}
   */
  setPointSize(pointSize: number): void {
    this.pointSize = pointSize;
  }

  /**
   * テクスチャ更新
   * @returns {void}
   */
  updateTexture(): void {
    if (!this.program) {
      return;
    }

    if (!this.image) {
      return;
    }

    const gl = this.context;

    gl.useProgram(this.program);
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, this.texture);
  }

  /**
   * 現在のカメラの状況に合わせて行列を更新する
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    if (!this.program) {
      return;
    }

    // model
    mat4.identity(this.scaleMatrix);
    mat4.identity(this.rotationMatrix);
    mat4.identity(this.translationMatrix);
    mat4.identity(this.modelMatrix);

    // Object3Dの親子関係を実装するならここを修正
    mat4.scale(this.scaleMatrix, this.scaleMatrix, object3D.scale.toFloat32Array());
    mat4.multiply(this.rotationMatrix, this.rotationMatrix, object3D.rotation.toMat4());
    const localTranslation = mat4.create();
    mat4.translate(localTranslation, localTranslation, object3D.position.toFloat32Array());
    mat4.multiply(this.translationMatrix, this.translationMatrix, localTranslation);

    mat4.multiply(this.modelMatrix, this.modelMatrix, this.translationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.rotationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.scaleMatrix);

    const gl = this.context;

    gl.useProgram(this.program);
    if (!this.modelLocation) {
      this.modelLocation = gl.getUniformLocation(this.program, 'model');
    }
    if (!this.viewLocation) {
      this.viewLocation = gl.getUniformLocation(this.program, 'view');
    }
    if (!this.projectionLocation) {
      this.projectionLocation = gl.getUniformLocation(this.program, 'projection');
    }
    if (!this.useTextureLocation) {
      this.useTextureLocation = gl.getUniformLocation(this.program, 'useTexture');
    }
    if (!this.pointSizeLocation) {
      this.pointSizeLocation = gl.getUniformLocation(this.program, 'pointSize');
    }
    gl.uniformMatrix4fv(this.modelLocation, false, this.modelMatrix);
    gl.uniformMatrix4fv(this.viewLocation, false, viewMatrix);
    gl.uniformMatrix4fv(this.projectionLocation, false, projectionMatrix);
    gl.uniform1i(this.useTextureLocation, this.image ? 1 : 0);
    gl.uniform1f(this.pointSizeLocation, this.pointSize);
  }

  /**
   * 描画する
   * @returns {void}
   */
  draw(): void {
    this.setupGeometry();
    this.updateTexture();

    const gl = this.context;
    const renderTarget = MapStatus.defaultRenderTarget;
    const dpr = getDevicePixelRatio();
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.viewport(0, 0, dpr * renderTarget.size.width, dpr * renderTarget.size.height);

    gl.blendFuncSeparate(gl.SRC_COLOR, gl.ONE_MINUS_SRC_COLOR, gl.ONE, gl.ONE);
    gl.disable(gl.BLEND);
    gl.disable(gl.DEPTH_TEST);

    const count = this.geometry.getVertices().length / 3;
    gl.drawArrays(gl.POINTS, 0, count);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const gl = this.context;
    if (PROGRAM && VERTEX_SHADER && FRAGMENT_SHADER) {
      gl.detachShader(PROGRAM, VERTEX_SHADER);
      gl.detachShader(PROGRAM, FRAGMENT_SHADER);
      gl.deleteProgram(PROGRAM);
      PROGRAM = undefined;
    }
    if (VERTEX_SHADER) {
      gl.deleteShader(VERTEX_SHADER);
      VERTEX_SHADER = undefined;
    }
    if (FRAGMENT_SHADER) {
      gl.deleteShader(FRAGMENT_SHADER);
      FRAGMENT_SHADER = undefined;
    }
    if (this.vertexBuffer) {
      gl.deleteBuffer(this.vertexBuffer);
      this.vertexBuffer = undefined;
    }
    this.viewLocation = undefined;
    this.modelLocation = undefined;
    this.projectionLocation = undefined;
  }
}

export {PointSpriteProgram, DEFAULT_POINT_SIZE};
