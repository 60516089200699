import {MouseEventObserver} from './event/MouseEventObserver';
import {WheelEventObserver} from './event/WheelEventObserver';
import {
  ATTENUATION_RATE,
  FLICK_THRESHOLD,
  INERTIA_SCROLL_CONTINUE_THRESHOLD,
  INERTIA_SCROLL_DEFAULT_ID,
  THROTTLE_DURATION,
  TouchEventObserver,
} from './event/TouchEventObserver';
import {GaiaContext} from './GaiaContext';
import {LatLng, Point} from '../../gaia/value';
import {MapStatus} from './models/MapStatus';
import {KeyboardEventObserver} from './event/KeyboardEventObserver';
import {
  ExtendedKeyboardEvent,
  ExtendedMouseEvent,
  ExtendedTouchEvent,
  GaIAEventListenerOptions,
  KeyboardEventMap,
  MouseEventMap,
  TouchEventMap,
  WheelEventMap,
} from '../../gaia/types';
import {EventListenerFunctionReturnType, Optional} from '../common/types';
import {Camera} from '../engine/camera/Camera';
import {Vector2} from '../common/math/Vector2';
import {Vector3} from '../common/math/Vector3';
import {PerspectiveCamera} from '../engine/camera/PerspectiveCamera';
import {EaseOutCubic} from './models/animation/easing/EaseOutCubic';
import {
  calculatePixelCoordinate,
  calculateWorldCoordinate,
  pixelToLatLng,
  worldToLatLng,
  calculatePixelToUnit,
} from './utils/MapUtil';
import {viewPointToIntersectionForPerspectiveCamera} from './render/MapTileScanner';
import {clamp} from '../common/math/MathUtil';
import {Ray3} from '../common/math/Ray3';
import {RectCollider} from '../common/math/RectCollider';
import {Collision} from '../engine/collision/Collision';
import {MapRenderKitController} from './render/kit/MapRenderKitController';
import {AnimationOption} from '../../gaia/value/animation';
import {GLMarkerObjectRenderKit} from './render/kit/object/GLMarkerObjectRenderKit';
import {throttle} from '../common/util/Culling';
import {Marker} from '../../gaia/object';

// マウスの第二ボタン
const MouseSecondButton = 2;

// キーボードのコントロールキー
const KeyboardControlButtonName = 'Control';

// キーボード操作で移動するピクセル数
const ArrowKeyMovePixels = 50;

/**
 * 地図へのユーザー操作を一元管理する
 */
class UserInputHandler {
  private context: GaiaContext;
  private readonly camera: Camera;
  private mapStatus: MapStatus;

  private readonly animationOption: AnimationOption;

  private readonly mouse: MouseEventObserver;
  private readonly wheel: WheelEventObserver;
  private readonly touch?: TouchEventObserver;
  private readonly keyboard: KeyboardEventObserver;

  private rotationEnabled = false;
  private tiltEnabled = false;

  private wheelEnable = true;

  private ignoreMapMove = false;
  private resetDebounceId = 0;

  private isMapFocused = false;

  // 地図回転に必要なメンバ変数
  private isMouseSecondButtonDown = false;
  private isControlKeyDown = false;
  private isRotating = false;
  private onMouseDownFor3D?: (ev: MouseEvent) => void;
  private onMouseUpFor3D?: () => void;
  private onDragFor3D?: (ev: MouseEvent) => void;
  private onKeyDownFor3D?: (ev: KeyboardEvent) => void;
  private onKeyUpFor3D?: (ev: KeyboardEvent) => void;
  private onTouchRotationFor3D?: (ev: ExtendedTouchEvent) => void;
  private onTouchTiltFor3D?: (ev: ExtendedTouchEvent) => void;

  private getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  private ignoreClickEvent = false;
  private doneClickEvent = 0;

  // 慣性スクロールに必要なメンバ変数
  private touchStartTime: number;
  private inertiaDeltaX: number;
  private inertiaDeltaY: number;
  private inertiaScrollId: number;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param camera カメラ
   * @param renderKitCtl MapRenderKitController
   */
  constructor(context: GaiaContext, camera: Camera, renderKitCtl: MapRenderKitController) {
    this.context = context;
    this.camera = camera;
    this.mapStatus = context.getMapStatus();

    this.animationOption = new AnimationOption(0.25, new EaseOutCubic());

    const element = context.getBaseElement();
    this.mouse = new MouseEventObserver(element);
    this.wheel = new WheelEventObserver(element);
    if (this.isTouchDevice()) {
      this.touch = new TouchEventObserver(element);
    }

    this.keyboard = new KeyboardEventObserver(element);

    this.setupMapMove();
    this.setupZoom();
    this.setupMapFocused();
    this.setupMapRotationAndTilt();

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKitCtl.getAllCollisions(ray);

    this.touchStartTime = performance.now();
    this.inertiaDeltaX = 0;
    this.inertiaDeltaY = 0;
    this.inertiaScrollId = INERTIA_SCROLL_DEFAULT_ID;
  }

  /**
   * タッチデバイスかどうか判定
   * @returns `true` の場合はタッチ対応デバイス
   */
  private isTouchDevice(): boolean {
    return 'ontouchstart' in window;
  }

  /**
   * 慣性スクロールの発動を試みる
   * @returns {void}
   */
  private tryFlick(): void {
    const now = performance.now();

    if (now - this.touchStartTime > 1000) {
      // 暫定: 1sec以上ならロングタップ扱い
    } else {
      if (Math.abs(this.inertiaDeltaX) > FLICK_THRESHOLD || Math.abs(this.inertiaDeltaY) > FLICK_THRESHOLD) {
        const flickFn = throttle(
          this,
          (): void => {
            this.mapMove(this.inertiaDeltaX, this.inertiaDeltaY);
            this.inertiaDeltaX *= ATTENUATION_RATE;
            this.inertiaDeltaY *= ATTENUATION_RATE;

            if (
              Math.abs(this.inertiaDeltaX) < INERTIA_SCROLL_CONTINUE_THRESHOLD &&
              Math.abs(this.inertiaDeltaY) < INERTIA_SCROLL_CONTINUE_THRESHOLD
            ) {
              // stop
              this.inertiaDeltaX = 0;
              this.inertiaDeltaY = 0;
              cancelAnimationFrame(this.inertiaScrollId);
              this.inertiaScrollId = INERTIA_SCROLL_DEFAULT_ID;
            } else {
              this.inertiaScrollId = requestAnimationFrame(flickFn);
            }
          },
          THROTTLE_DURATION
        );
        this.inertiaScrollId = requestAnimationFrame(flickFn);
      }
    }
  }

  /**
   * 地図移動操作の設定
   * @returns {void}
   */
  private setupMapMove(): void {
    if (this.isTouchDevice()) {
      this.touch?.addEventListener('touchstart', (ev: TouchEvent) => {
        this.detectDragstartFromIgnoreElement(ev);
        this.touchStartTime = performance.now();
      });
      this.touch?.addEventListener('touchend', () => {
        this.ignoreMapMove = false;

        if (GLMarkerObjectRenderKit.touched || Marker.touched) {
          Marker.touched = false;
          GLMarkerObjectRenderKit.touched = false;
        } else {
          this.tryFlick();
        }
      });
      this.touch?.addEventListener('singlescroll', (ev: ExtendedTouchEvent) => {
        if (this.isRotating) {
          return;
        }
        if (ev.deltaX && ev.deltaY && ev.deltaX.length > 0 && ev.deltaY.length > 0) {
          this.mapMove(ev.deltaX[0], ev.deltaY[0]);
          this.inertiaDeltaX = ev.deltaX[0];
          this.inertiaDeltaY = ev.deltaY[0];
        }
      });
    } else {
      this.mouse.addEventListener('mousedown', (ev: MouseEvent) => this.detectDragstartFromIgnoreElement(ev));
      this.mouse.addEventListener('mouseup', () => {
        if (this.ignoreMapMove) {
          this.resetIgnoreMapMoveWithDebounce();
        }
      });
      this.mouse.addEventListener('drag', (ev: ExtendedMouseEvent) => {
        if (this.isRotating) {
          return;
        }
        const deltaX = ev.deltaX ?? 0;
        const deltaY = ev.deltaY ?? 0;
        this.mapMove(deltaX, deltaY);
      });
      this.mouse.addEventListener('inertia_mousemove', (ev: ExtendedMouseEvent) => {
        if (this.isRotating) {
          return;
        }
        const deltaX = ev.deltaX ?? 0;
        const deltaY = ev.deltaY ?? 0;
        this.mapMove(deltaX, deltaY);
        this.resetIgnoreMapMoveWithDebounce();
      });
    }

    this.keyboard.addEventListener('arrowkeydown', (ev: ExtendedKeyboardEvent) => {
      if (!this.isMapFocused) {
        return;
      }
      const deltaX = ev.deltaX ?? 0;
      const deltaY = ev.deltaY ?? 0;

      this.mapMove(-deltaX * ArrowKeyMovePixels, deltaY * ArrowKeyMovePixels);

      ev.preventDefault();
    });
  }

  /**
   * 地図ズーム操作の設定
   * @returns {void}
   */
  private setupZoom(): void {
    if (this.isTouchDevice()) {
      this.touch?.addEventListener('pinch', (ev: ExtendedTouchEvent) => {
        if (ev.touches && ev.touches.length === 2) {
          this.mapPinch(ev);
        }
      });
      this.touch?.addEventListener('singletap_2f', (ev: ExtendedTouchEvent) => {
        const clientPixelPosition = new Vector2(ev.multiTouchCenter?.x ?? 0, ev.multiTouchCenter?.y ?? 0);
        const worldCenter = calculateWorldCoordinate(this.mapStatus.centerLocation);
        const tapPosition = this.calculateClientToWorld(clientPixelPosition, worldCenter);
        if (!tapPosition) {
          return;
        }

        // 現在の地図の状態でオフセットに該当する位置を求める
        const {clientHeight, clientWidth} = this.context.getBaseElement();
        const {x: offsetX, y: offsetY} = this.mapStatus.centerOffset;
        const clientOffsetWorld = this.calculateClientToWorld(
          new Vector2(clientWidth / 2 + offsetX, clientHeight / 2 + offsetY),
          worldCenter
        );
        if (!clientOffsetWorld) {
          return;
        }

        const zoomLevel = this.mapStatus.zoomLevel;
        const newZoomLv = Math.round(zoomLevel - 1);

        // クリック位置＝オフセット位置になるときの地図中心を求める
        // 地図中心とオフセット該当位置の差分を算出
        const distance = worldCenter._subtract(clientOffsetWorld);
        // newZoomLvにした際の差分を算出
        const integerDiff = newZoomLv - Math.floor(zoomLevel);
        const s = 1 / 2 ** integerDiff;
        const decimal = zoomLevel - Math.floor(zoomLevel);
        const scale = decimal * s + s;
        const distanceOnNewZoomLv = distance._multiply(scale);
        // クリック位置から↑の差分だけずらした場所を新たな地図中心とする
        const newCenter = tapPosition._add(distanceOnNewZoomLv);
        const latLng = worldToLatLng(newCenter, zoomLevel);

        this.mapStatus.moveTo(latLng, newZoomLv, this.animationOption);
      });
      // ダブルタップはdblclickとみなす
    }

    // MEMO: iOS 13.1以降で連続したtouchイベントが発火しない問題があるので、dblclickで対応
    this.mouse.addEventListener('dblclick', (ev: MouseEvent) => {
      const {top, left} = this.context.getBaseElement().getBoundingClientRect();
      const clientPixelPosition = new Vector2(
        ev.pageX - left - window.pageXOffset,
        ev.pageY - top - window.pageYOffset
      );
      const worldCenter = calculateWorldCoordinate(this.mapStatus.centerLocation);
      const clickPosition = this.calculateClientToWorld(clientPixelPosition, worldCenter);
      if (!clickPosition) {
        return;
      }

      // 現在の地図の状態でオフセットに該当する位置を求める
      const {clientHeight, clientWidth} = this.context.getBaseElement();
      const {x: offsetX, y: offsetY} = this.mapStatus.centerOffset;
      const clientOffsetWorld = this.calculateClientToWorld(
        new Vector2(clientWidth / 2 + offsetX, clientHeight / 2 + offsetY),
        worldCenter
      );
      if (!clientOffsetWorld) {
        return;
      }

      const zoomLevel = this.mapStatus.zoomLevel;
      let newZoomLv = Math.round(zoomLevel + 1);
      if (ev.button === MouseSecondButton) {
        newZoomLv = Math.round(zoomLevel - 1);
      }

      // クリック位置＝オフセット位置になるときの地図中心を求める
      // 地図中心とオフセット該当位置の差分を算出
      const distance = worldCenter._subtract(clientOffsetWorld);
      // newZoomLvにした際の差分を算出
      const integerDiff = newZoomLv - Math.floor(zoomLevel);
      const s = 1 / 2 ** integerDiff;
      const decimal = zoomLevel - Math.floor(zoomLevel);
      const scale = decimal * s + s;
      const distanceOnNewZoomLv = distance._multiply(scale);
      // クリック位置から↑の差分だけずらした場所を新たな地図中心とする
      const newCenter = clickPosition._add(distanceOnNewZoomLv);
      const latLng = worldToLatLng(newCenter, zoomLevel);

      this.mapStatus.moveTo(latLng, newZoomLv, this.animationOption);
    });
    this.wheel.addEventListener('wheel', (ev: WheelEvent) => {
      if (this.isMouseSecondButtonDown || !this.wheelEnable) {
        return;
      }

      const coefficient = ev.deltaMode === WheelEvent.DOM_DELTA_LINE ? 0.15 : 0.01;
      const {zoomLevel, zoomRange} = this.context.getMapStatus();
      let diff = -ev.deltaY * coefficient;
      if (navigator.userAgent.toLowerCase().includes('windows')) {
        // windowsは一律固定値で
        diff = ev.deltaY < 0 ? 0.2 : -0.2;
      }
      if ((diff < 0 && zoomLevel === zoomRange.min) || (diff > 0 && zoomLevel === zoomRange.max)) {
        return;
      }
      const zoom = clamp(zoomLevel + diff, zoomRange.min, zoomRange.max);
      const deltaZoom = zoom - zoomLevel;
      const delta = 1 / Math.pow(2, deltaZoom) - 1;

      const {top, left} = this.context.getBaseElement().getBoundingClientRect();
      const clientPixelPosition = new Vector2(
        ev.pageX - left - window.pageXOffset,
        ev.pageY - top - window.pageYOffset
      );
      const worldCenter = calculateWorldCoordinate(this.mapStatus.centerLocation);
      const clickPosition = this.calculateClientToWorld(clientPixelPosition, worldCenter);
      if (!clickPosition) {
        return;
      }

      const distance = new Vector3(clickPosition.x - worldCenter.x, clickPosition.y - worldCenter.y, 0);
      const newCenter = worldCenter._subtract(distance._multiply(delta));
      const latlng = worldToLatLng(newCenter, this.mapStatus.zoomLevel);

      // 先にsetZoomを行わないとMapCoreController#clampZoomLevel()が正常に動作しない
      this.setZoom(zoom);
      this.setCenter(latlng);
    });

    this.keyboard.addEventListener('zoomkeydown', (ev: ExtendedKeyboardEvent) => {
      const deltaZoomLevel = ev.deltaZoomLevel ?? 0;
      const zoomLevel = this.mapStatus.zoomLevel;
      this.setZoom(zoomLevel + deltaZoomLevel);
    });
  }

  /**
   * 地図のフォーカス状態を制御
   * @returns {void}
   */
  private setupMapFocused(): void {
    this.mouse.addEventListener('mousedown', () => (this.isMapFocused = true));
    this.mouse.addEventListener('mouseleave', () => {
      // eslint-disable-next-line require-jsdoc, @typescript-eslint/explicit-function-return-type
      const listenerFn = (ev: MouseEvent) => {
        if (ev.target === document.body) {
          return;
        }
        this.isMapFocused = false;
        document.removeEventListener('click', listenerFn);
      };
      document.addEventListener('click', listenerFn);
    });
  }

  // TODO: スクロール処理と回転・傾き処理をこのクラスかUserInputHandlerどちらかにまとめる
  /**
   * 地図の回転を制御
   * @returns {void}
   */
  private setupMapRotationAndTilt(): void {
    this.onMouseDownFor3D = (ev: MouseEvent): void => {
      if (ev.button !== MouseSecondButton) {
        this.isMouseSecondButtonDown = false;
        if (this.isControlKeyDown) {
          this.isRotating = true;
        }
        return;
      }
      if (ev.target === this.context.getCanvasElement()) {
        this.isMouseSecondButtonDown = true;
        this.isRotating = true;
      }
    };
    this.onMouseUpFor3D = (): void => {
      this.isMouseSecondButtonDown = false;
      this.isControlKeyDown = false;
      this.isRotating = false;
    };
    this.onDragFor3D = (ev: MouseEvent): void => {
      if (!this.isRotating) {
        return;
      }
      if (ev.target !== this.context.getCanvasElement()) {
        return;
      }
      if (ev.movementX && this.rotationEnabled) {
        const phi = this.mapStatus.getPolarPhi();
        this.mapStatus.setPolarPhi(phi - ev.movementX * 0.005);
      }
      if (ev.movementY && this.tiltEnabled) {
        const theta = this.mapStatus.getPolarTheta();
        this.mapStatus.setPolarTheta(theta - ev.movementY * 0.005);
      }
    };

    this.onKeyDownFor3D = (ev: KeyboardEvent): void => {
      if (ev.key === KeyboardControlButtonName) {
        this.isControlKeyDown = true;
      }
    };
    this.onKeyUpFor3D = (ev: KeyboardEvent): void => {
      if (ev.key === KeyboardControlButtonName) {
        this.isControlKeyDown = false;
      }
    };

    if (this.isTouchDevice()) {
      this.onTouchRotationFor3D = (ev: ExtendedTouchEvent): void => {
        if (!ev.deltaRotation || !this.rotationEnabled) {
          return;
        }
        const phi = this.mapStatus.getPolarPhi();
        this.mapStatus.setPolarPhi(phi - ev.deltaRotation);
      };
      this.onTouchTiltFor3D = (ev: ExtendedTouchEvent): void => {
        if (!ev.deltaTilt || !this.tiltEnabled) {
          return;
        }
        const theta = this.mapStatus.getPolarTheta();
        this.mapStatus.setPolarTheta(theta - ev.deltaTilt * 0.005);
      };
    }
  }

  /**
   * 指定されたピクセル数で地図をずらす
   * @param deltaPixelX xの値
   * @param deltaPixelY yの値
   * @returns {void}
   */
  private mapMove(deltaPixelX: number, deltaPixelY: number): void {
    if (this.ignoreMapMove) {
      return;
    }
    const center = this.mapStatus.centerLocation;
    const pixel = calculatePixelCoordinate(center, this.mapStatus.zoomLevel);
    const radian = -this.mapStatus.polar.phi - Math.PI / 2;
    const movePixelX = -(deltaPixelX * Math.cos(radian) - deltaPixelY * Math.sin(radian));
    const movePixelY = -(deltaPixelX * Math.sin(radian) + deltaPixelY * Math.cos(radian));
    const movedPixel = pixel.add(new Vector2(movePixelX, movePixelY));
    const movedCenter = pixelToLatLng(movedPixel, this.mapStatus.zoomLevel);
    this.setCenter(movedCenter);
  }

  /**
   * 地図上でピンチ操作を行われた際に地図を移動する
   * @param ev タッチイベント
   * @returns {void}
   */
  private mapPinch(ev: ExtendedTouchEvent): void {
    const canvas = this.context.getCanvasElement();
    const rect = canvas.getBoundingClientRect();
    const touch1 = ev.touches[0];
    const touch2 = ev.touches[1];

    const worldCenter = calculateWorldCoordinate(this.mapStatus.centerLocation);
    const pinchCenterX = (touch1.pageX + touch2.pageX) / 2 - rect.left - window.pageXOffset;
    const pinchCenterY = (touch1.pageY + touch2.pageY) / 2 - rect.top - window.pageYOffset;
    const pinchCenter = this.calculateClientToWorld(new Vector2(pinchCenterX, pinchCenterY), worldCenter);
    if (!pinchCenter) {
      return;
    }

    const distance = new Vector3(pinchCenter.x - worldCenter.x, pinchCenter.y - worldCenter.y, 0);
    const delta = ev.pinchDelta ?? 0;
    const zoomChangeRatio = Math.pow(2, delta * 0.01);
    const newCenter = worldCenter._subtract(distance._multiply(1 - zoomChangeRatio));
    const latlng = worldToLatLng(newCenter, this.mapStatus.zoomLevel);
    this.setCenter(latlng);
    this.setZoom(this.mapStatus.zoomLevel + delta * 0.01);
  }

  /**
   * クライアント座標を緯度経度に変換する
   * @deprecated 3D操作有効時は正しい値が取れないため `calculateClientToWorld` を利用する
   * @param clientPixelPosition ピクセルで表現されたクライアント座標
   * @param worldCenter 地図中心
   * @returns 変換した緯度経度
   */
  private calculateLatLng(clientPixelPosition: Vector2): Optional<LatLng> {
    const worldCenter = calculateWorldCoordinate(this.mapStatus.centerLocation);
    const intersection = this.calculateClientToWorld(clientPixelPosition, worldCenter);
    if (!intersection) {
      return undefined;
    }

    return worldToLatLng(intersection, this.mapStatus.zoomLevel);
  }

  /**
   * クライアント座標をワールド座標に変換する
   * @param clientPixelPosition ピクセルで表現されたクライアント座標
   * @param worldCenter 地図中心
   * @returns ワールド座標
   */
  private calculateClientToWorld(clientPixelPosition: Vector2, worldCenter: Vector3): Optional<Vector3> {
    const ptu = calculatePixelToUnit(this.mapStatus.zoomLevel);
    const upVector = this.mapStatus.polar.toUpVector3();
    const rightVector = this.mapStatus.polar.toRightVector3();

    const cursorRay = this.clientPixelToRay(clientPixelPosition, worldCenter, upVector, rightVector, ptu);
    const mapSurface = this.createMapSurface(worldCenter, upVector, rightVector, ptu, this.mapStatus.centerOffset);

    return mapSurface.calculateIntersection(cursorRay);
  }

  /**
   * カメラからクライアント座標までのレイを作成
   * @param clientPixelPosition ピクセルで表現されたクライアント座標
   * @param worldCenter 地図中心
   * @param upVector 極座標上方向のベクトル
   * @param rightVector 極座標右方向のベクトル
   * @param ptu ピクセルからGL空間上の長さに変換する係数
   * @returns カメラからクライアント座標までのレイ
   */
  private clientPixelToRay(
    clientPixelPosition: Vector2,
    worldCenter: Vector3,
    upVector: Vector3,
    rightVector: Vector3,
    ptu: number
  ): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const clientPositionX = clientPixelPosition.x;
    const clientPositionY = clientPixelPosition.y;

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const halfWidth = (this.mapStatus.clientWidth * ptu) / 2;
    const halfHeight = (this.mapStatus.clientHeight * ptu) / 2;

    const toTopVector = upVector._normalize()._multiply(halfHeight * y);
    const toRightVector = rightVector._normalize()._multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);

    const start = worldCenter._add(this.camera.position);

    const cameraTarget = (this.camera as PerspectiveCamera).target;
    const direction = cameraTarget._subtract(this.camera.position)._add(toTopRightVector);

    return new Ray3(start, direction);
  }

  /**
   * 画面全体を覆うコライダを作成
   * @param worldCenter 地図中心
   * @param upVector 極座標上方向のベクトル
   * @param rightVector 極座標右方向のベクトル
   * @param ptu ピクセルからGL空間上の長さに変換する係数
   * @param centerOffset 地図中心オフセット
   * @returns 画面全体を覆うコライダ
   */
  private createMapSurface(
    worldCenter: Vector3,
    upVector: Vector3,
    rightVector: Vector3,
    ptu: number,
    centerOffset: Point
  ): RectCollider {
    const outerX = (256 * Math.SQRT2 + Math.abs(centerOffset.x)) * ptu;
    const rotationBaseRadian = this.mapStatus.polar.phi + Math.PI / 2;

    const radianTopLeft = rotationBaseRadian + (Math.PI / 4) * 3;
    const topLeft: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      this.mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(-1, 1),
      upVector,
      rightVector
    )
      ._add(worldCenter)
      ._add(new Vector3(outerX * Math.cos(radianTopLeft), outerX * Math.sin(radianTopLeft), 0));

    const radianTopRight = rotationBaseRadian + Math.PI / 4;
    const topRight: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      this.mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(1, 1),
      upVector,
      rightVector
    )
      ._add(new Vector3(outerX * Math.cos(radianTopRight), outerX * Math.sin(radianTopRight), 0))
      ._add(worldCenter);

    const radianBottomLeft = rotationBaseRadian - (Math.PI / 4) * 3;
    const bottomLeft: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      this.mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(-1, -1),
      upVector,
      rightVector
    )
      ._add(new Vector3(outerX * Math.cos(radianBottomLeft), outerX * Math.sin(radianBottomLeft), 0))
      ._add(worldCenter);

    return new RectCollider(topLeft, topRight, bottomLeft);
  }

  /**
   * ドラッグ操作開始が表示物上から始まっていないかどうかを判定し、地図移動を無効化するか制御する
   * @param ev イベント
   * @returns {void}
   */
  private detectDragstartFromIgnoreElement(ev: MouseEvent | TouchEvent): void {
    for (const value of ev.composedPath()) {
      if (value instanceof Window || value instanceof Document) {
        continue;
      }
      const classList = (value as Element).classList;
      if (!classList) {
        continue;
      }
      this.ignoreMapMove = classList.contains('gia-dom-layer') || this.ignoreMapMove;
    }
  }

  /**
   * 地図移動を無視するフラグのリセットを最後の呼び出しから一定時間後におこなう
   * @returns {void}
   */
  private resetIgnoreMapMoveWithDebounce(): void {
    if (this.resetDebounceId !== 0) {
      window.clearTimeout(this.resetDebounceId);
    }
    this.resetDebounceId = window.setTimeout(() => {
      this.ignoreMapMove = false;
    }, 100);
  }

  /**
   * 中心座標を設定
   * @param latlng 緯度経度
   * @returns {void}
   */
  private setCenter(latlng: LatLng): void {
    this.mapStatus.setCenterLocation(latlng);
  }

  /**
   * ズームレベルを設定
   * @param zoom ズームレベル
   * @returns {void}
   */
  private setZoom(zoom: number): void {
    this.mapStatus.setZoomLevel(zoom);
  }

  /**
   * 地図移動を無視する
   * @returns {void}
   */
  stopMapMove(): void {
    this.ignoreMapMove = true;
  }

  /**
   * マウスイベントリスナーの追加
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  addMouseEventListener<K extends keyof MouseEventMap>(
    eventName: K,
    func: (ev: MouseEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    if (eventName === 'click') {
      this.mouse.addEventListener(
        eventName,
        (ev: ExtendedMouseEvent) => {
          const {top, left} = this.context.getBaseElement().getBoundingClientRect();
          const clientPixelPosition = new Vector2(
            ev.pageX - left - window.pageXOffset,
            ev.pageY - top - window.pageYOffset
          );
          const worldCenter = calculateWorldCoordinate(this.mapStatus.centerLocation);
          const upVector = this.mapStatus.polar.toUpVector3();
          const rightVector = this.mapStatus.polar.toRightVector3();
          const ptu = calculatePixelToUnit(this.mapStatus.zoomLevel);
          const ray = this.clientPixelToRay(clientPixelPosition, worldCenter, upVector, rightVector, ptu);
          const allCollisions = this.getAllCollisions(ray);
          for (const collisions of allCollisions.values()) {
            // 地図上のオブジェクトに衝突していたら地図のイベントは発行しない
            if (collisions.length > 0) {
              return;
            }
          }

          if (this.ignoreClickEvent) {
            if (this.doneClickEvent < this.mouse.getRegisteredListenerCount(eventName) - 1) {
              this.doneClickEvent++;
            } else {
              this.ignoreClickEvent = false;
              this.doneClickEvent = 0;
            }
            return;
          }

          const clickPosition = this.calculateClientToWorld(clientPixelPosition, worldCenter);
          if (!clickPosition) {
            return;
          }
          const latlng = worldToLatLng(clickPosition, this.mapStatus.zoomLevel);
          if (latlng) {
            ev.position = latlng;
          }
          func(ev);
        },
        options
      );
    } else {
      this.mouse.addEventListener(eventName, func, options);
    }
  }

  /**
   * マウスイベントリスナーの削除
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  removeMouseEventListener<K extends keyof MouseEventMap>(
    eventName: K,
    func: (ev: MouseEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.mouse.removeEventListener(eventName, func, options);
  }

  /**
   * 一時的にクリックイベント発行を止める
   * @returns {void}
   */
  stopClickEventTemporarily(): void {
    this.ignoreClickEvent = true;
  }

  /**
   * マウスホイールイベントリスナーの追加
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  addWheelEventListener<K extends keyof WheelEventMap>(
    eventName: K,
    func: (ev: WheelEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.wheel.addEventListener(eventName, func, options);
  }

  /**
   * マウスホイールイベントリスナーの削除
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  removeWheelEventListener<K extends keyof WheelEventMap>(
    eventName: K,
    func: (ev: WheelEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.wheel.removeEventListener(eventName, func, options);
  }

  /**
   * タッチイベントリスナーの追加
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  addTouchEventListener<K extends keyof TouchEventMap>(
    eventName: K,
    func: (ev: TouchEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.touch?.addEventListener(eventName, func, options);
  }

  /**
   * タッチイベントリスナーの削除
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  removeTouchEventListener<K extends keyof TouchEventMap>(
    eventName: K,
    func: (ev: TouchEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.touch?.removeEventListener(eventName, func, options);
  }

  /**
   * キーボードイベントリスナーの追加
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  addKeyboardEventListener<K extends keyof KeyboardEventMap>(
    eventName: K,
    func: (ev: KeyboardEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.keyboard.addEventListener(eventName, func, options);
  }

  /**
   * キーボードイベントリスナーの削除
   * @param eventName イベント名
   * @param func リスナー関数
   * @param options オプション
   * @returns {void}
   */
  removeKeyboardEventListener<K extends keyof KeyboardEventMap>(
    eventName: K,
    func: (ev: KeyboardEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.keyboard.removeEventListener(eventName, func, options);
  }

  /**
   * 地図の回転操作が有効になっているかを取得
   * @returns 地図の回転操作が有効であれば `true` 無効であれば `false`
   */
  isMapRotationEnabled(): boolean {
    return this.rotationEnabled;
  }

  /**
   * 地図の傾き操作が有効になっているかを取得
   * @returns 地図の傾き操作が有効であれば `true` 無効であれば `false`
   */
  isMapTiltEnabled(): boolean {
    return this.tiltEnabled;
  }

  /**
   * 地図の回転操作を有効にする
   * 有効にすると、下記のいずれかの操作を行った際に地図が傾いたり回転するようになる
   * ・PC
   *   ・コントロールキーを押しながらドラッグアンドドロップ
   *   ・右クリックでドラッグアンドドロップ
   * ・モバイル
   *   ・2本指で上下にスワイプ、もしくは2本指で回転
   * @returns {void}
   */
  enableMapRotation(): void {
    if (
      !this.onMouseDownFor3D ||
      !this.onMouseUpFor3D ||
      !this.onDragFor3D ||
      !this.onKeyDownFor3D ||
      !this.onKeyUpFor3D
    ) {
      return;
    }

    this.rotationEnabled = true;
    this.mouse.setSecondButtonDragEnabled(true);

    if (this.tiltEnabled) {
      return;
    }

    this.mouse.addEventListener('mousedown', this.onMouseDownFor3D);
    this.mouse.addEventListener('mouseup', this.onMouseUpFor3D);
    this.mouse.addEventListener('drag', this.onDragFor3D);
    this.keyboard.addEventListener('keydown', this.onKeyDownFor3D);
    this.keyboard.addEventListener('keyup', this.onKeyUpFor3D);
    if (this.onTouchRotationFor3D) {
      this.touch?.addEventListener('rotation', this.onTouchRotationFor3D);
    }
    if (this.onTouchTiltFor3D) {
      this.touch?.addEventListener('tilt', this.onTouchTiltFor3D);
    }
  }

  /**
   * 地図の回転操作を無効にする
   * 無効にすると、下記の操作を行っても地図が傾いたり回転したりしなくなる
   * ・PC
   *   ・コントロールキーを押しながらドラッグアンドドロップ
   *   ・右クリックでドラッグアンドドロップ
   * ・モバイル
   *   ・2本指で上下にスワイプ、もしくは2本指で回転
   * @returns {void}
   */
  disableMapRotation(): void {
    if (
      !this.onMouseDownFor3D ||
      !this.onMouseUpFor3D ||
      !this.onDragFor3D ||
      !this.onKeyDownFor3D ||
      !this.onKeyUpFor3D
    ) {
      return;
    }

    this.rotationEnabled = false;

    if (this.tiltEnabled) {
      return;
    }

    this.mouse.setSecondButtonDragEnabled(false);
    this.mouse.removeEventListener('mousedown', this.onMouseDownFor3D);
    this.mouse.removeEventListener('mouseup', this.onMouseUpFor3D);
    this.mouse.removeEventListener('drag', this.onDragFor3D);
    this.keyboard.removeEventListener('keydown', this.onKeyDownFor3D);
    this.keyboard.removeEventListener('keyup', this.onKeyUpFor3D);
    if (this.onTouchRotationFor3D) {
      this.touch?.removeEventListener('rotation', this.onTouchRotationFor3D);
    }
    if (this.onTouchTiltFor3D) {
      this.touch?.removeEventListener('tilt', this.onTouchTiltFor3D);
    }
  }

  /**
   * 地図の傾き操作を有効にする
   * 有効にすると、下記の操作を行った際に地図が傾くようになる
   * ・PC
   *   ・コントロールキーを押しながらドラッグアンドドロップ
   *   ・右クリックでドラッグアンドドロップ
   * ・モバイル
   *   ・2本指で上下にスワイプ
   * @returns {void}
   */
  enableMapTilt(): void {
    if (
      !this.onMouseDownFor3D ||
      !this.onMouseUpFor3D ||
      !this.onDragFor3D ||
      !this.onKeyDownFor3D ||
      !this.onKeyUpFor3D
    ) {
      return;
    }

    this.tiltEnabled = true;
    this.mouse.setSecondButtonDragEnabled(true);

    if (this.rotationEnabled) {
      return;
    }

    this.mouse.addEventListener('mousedown', this.onMouseDownFor3D);
    this.mouse.addEventListener('mouseup', this.onMouseUpFor3D);
    this.mouse.addEventListener('drag', this.onDragFor3D);
    this.keyboard.addEventListener('keydown', this.onKeyDownFor3D);
    this.keyboard.addEventListener('keyup', this.onKeyUpFor3D);
    if (this.onTouchRotationFor3D) {
      this.touch?.addEventListener('rotation', this.onTouchRotationFor3D);
    }
    if (this.onTouchTiltFor3D) {
      this.touch?.addEventListener('tilt', this.onTouchTiltFor3D);
    }
  }

  /**
   * 地図の傾き操作を無効にする
   * 無効にすると、下記の操作を行っても地図が傾かなくなる
   * ・PC
   *   ・コントロールキーを押しながらドラッグアンドドロップ
   *   ・右クリックでドラッグアンドドロップ
   * ・モバイル
   *   ・2本指で上下にスワイプ
   * @returns {void}
   */
  disableMapTilt(): void {
    if (
      !this.onMouseDownFor3D ||
      !this.onMouseUpFor3D ||
      !this.onDragFor3D ||
      !this.onKeyDownFor3D ||
      !this.onKeyUpFor3D
    ) {
      return;
    }

    this.tiltEnabled = false;

    if (this.rotationEnabled) {
      return;
    }

    this.mouse.setSecondButtonDragEnabled(false);
    this.mouse.removeEventListener('mousedown', this.onMouseDownFor3D);
    this.mouse.removeEventListener('mouseup', this.onMouseUpFor3D);
    this.mouse.removeEventListener('drag', this.onDragFor3D);
    this.keyboard.removeEventListener('keydown', this.onKeyDownFor3D);
    this.keyboard.removeEventListener('keyup', this.onKeyUpFor3D);
    if (this.onTouchRotationFor3D) {
      this.touch?.removeEventListener('rotation', this.onTouchRotationFor3D);
    }
    if (this.onTouchTiltFor3D) {
      this.touch?.removeEventListener('tilt', this.onTouchTiltFor3D);
    }
  }

  /**
   * ホイール操作の有効/無効を設定
   * @param enable  有効にする場合はtrue, 無効にする場合はfalse
   * @returns {void}
   */
  setWheelZoomEnable(enable: boolean): void {
    this.wheelEnable = enable;
    this.wheel.setPreventDefault(enable);
  }

  /**
   * 地図上でロングタップと見なす時間（ミリ秒）を設定する
   * @param milliseconds ロングタップと見なす時間（ミリ秒）
   * @returns {void}
   */
  setMapLongTapMilliseconds(milliseconds: number): void {
    this.touch?.setLongTapMilliseconds(milliseconds);
  }
}

export {UserInputHandler};
