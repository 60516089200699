import {mat4, vec3} from 'gl-matrix';

import {Vector3} from './Vector3';

/**
 * 四元数のクラス ( a + bi + cj + dk )
 */
class Quaternion {
  /**
   * 実部、またはスカラー成分
   */
  a: number;

  /**
   * 虚部のiの係数
   */
  b: number;

  /**
   * 虚部のjの係数
   */
  c: number;

  /**
   * 虚部のkの係数
   */
  d: number;

  /**
   * toMat4() で使用するフィールド
   */
  private static matrix: mat4 = mat4.create();

  /**
   * product() で使用するフィールド
   */
  private static vec: vec3 = vec3.create();

  private static temporaryQuaternion: Quaternion = Quaternion.identity();

  /**
   * 四元数を作成する
   * @param a 実部
   * @param b 虚部のiの係数
   * @param c 虚部のjの係数
   * @param d 虚部のkの係数
   */
  constructor(a: number, b: number, c: number, d: number) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
  }

  /**
   * 値を変更する
   * @param a 実部
   * @param b 虚部のiの係数
   * @param c 虚部のjの係数
   * @param d 虚部のkの係数
   * @returns {void}
   */
  setValues(a: number, b: number, c: number, d: number): void {
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
  }

  /**
   * 複製
   * @returns 複製した四元数
   */
  clone(): Quaternion {
    return new Quaternion(this.a, this.b, this.c, this.d);
  }

  /**
   * 引数に与えられた四元数を足した新たな四元数を作成する
   * @param other 足す四元数
   * @returns 足した結果の四元数
   */
  _add(other: Quaternion): Quaternion {
    return new Quaternion(this.a + other.a, this.b + other.b, this.c + other.c, this.d + other.d);
  }

  /**
   * スカラー倍した新たな四元数を作成する
   * @param scalar スカラー係数
   * @returns スカラー倍した結果の四元数
   */
  _scalarTimes(scalar: number): Quaternion {
    return new Quaternion(this.a * scalar, this.b * scalar, this.c * scalar, this.d * scalar);
  }

  /**
   * 与えられた四元数との積の結果を、新たな四元数で返す
   * @param other かける四元数
   * @returns 積の結果の四元数
   */
  _multiply(other: Quaternion): Quaternion {
    return new Quaternion(
      this.a * other.a - this.b * other.b - this.c * other.c - this.d * other.d,
      this.a * other.b + this.b * other.a + this.c * other.d - this.d * other.c,
      this.a * other.c - this.b * other.d + this.c * other.a + this.d * other.b,
      this.a * other.d + this.b * other.c - this.c * other.b + this.d * other.a
    );
  }

  /**
   * 与えられた四元数を自身にかける（破壊的）
   * @param other かける四元数
   * @returns 自身を返す
   */
  multiply(other: Quaternion): Quaternion {
    this.setValues(
      this.a * other.a - this.b * other.b - this.c * other.c - this.d * other.d,
      this.a * other.b + this.b * other.a + this.c * other.d - this.d * other.c,
      this.a * other.c - this.b * other.d + this.c * other.a + this.d * other.b,
      this.a * other.d + this.b * other.c - this.c * other.b + this.d * other.a
    );
    return this;
  }

  /**
   * ノルムを返す
   * @returns ノルム
   */
  norm(): number {
    return Math.sqrt(this.squaredNorm());
  }

  /**
   * ノルムの2乗を返す
   * @returns ノルムの2乗
   */
  squaredNorm(): number {
    return this.a ** 2 + this.b ** 2 + this.c ** 2 + this.d ** 2;
  }

  /**
   * 共軛四元数を返す
   * @returns 共軛四元数
   */
  _conjugate(): Quaternion {
    return new Quaternion(this.a, -this.b, -this.c, -this.d);
  }

  /**
   * 逆元の四元数を返す
   * @returns 逆元
   */
  _inverse(): Quaternion {
    return this._conjugate()._scalarTimes(1 / this.squaredNorm());
  }

  /**
   * 3次元ベクトルを回転した新たなベクトルを返す
   * @param vector3 3次元ベクトル
   * @returns 回転したベクトル
   */
  _product(vector3: Vector3): Vector3 {
    const mat: mat4 = this.toMat4();
    vec3.set(Quaternion.vec, vector3.x, vector3.y, vector3.z);
    vec3.transformMat4(Quaternion.vec, Quaternion.vec, mat);
    return new Vector3(Quaternion.vec[0], Quaternion.vec[1], Quaternion.vec[2]);
  }

  /**
   * 引数の3次元ベクトルを回転させて返す（破壊的）
   * @param vector3 3次元ベクトル
   * @returns 引数のベクトルを返す
   */
  product(vector3: Vector3): Vector3 {
    const mat: mat4 = this.toMat4();
    vec3.set(Quaternion.vec, vector3.x, vector3.y, vector3.z);
    vec3.transformMat4(Quaternion.vec, Quaternion.vec, mat);
    vector3.setValues(Quaternion.vec[0], Quaternion.vec[1], Quaternion.vec[2]);
    return vector3;
  }

  /**
   * x軸の周りを引数で与えられた角度で回転した新たな四元数を返す
   * @param radian ラジアンで表現された回転角度
   * @returns 回転した四元数
   */
  _rotateX(radian: number): Quaternion {
    Quaternion.temporaryQuaternion.setFromRadianAndAxis(radian, new Vector3(1, 0, 0));
    return this._multiply(Quaternion.temporaryQuaternion);
  }

  /**
   * 自身をx軸の周りで回転して返す（破壊的）
   * @param radian ラジアンで表現された回転角度
   * @returns 自身を返す
   */
  rotateX(radian: number): Quaternion {
    Quaternion.temporaryQuaternion.setFromRadianAndAxis(radian, new Vector3(1, 0, 0));
    return this.multiply(Quaternion.temporaryQuaternion);
  }

  /**
   * y軸の周りを引数で与えられた角度で回転した新たな四元数を返す
   * @param radian ラジアンで表現された回転角度
   * @returns 回転した四元数
   */
  _rotateY(radian: number): Quaternion {
    Quaternion.temporaryQuaternion.setFromRadianAndAxis(radian, new Vector3(0, 1, 0));
    return this._multiply(Quaternion.temporaryQuaternion);
  }

  /**
   * 自身をy軸の周りで回転して返す（破壊的）
   * @param radian ラジアンで表現された回転角度
   * @returns 自身を返す
   */
  rotateY(radian: number): Quaternion {
    Quaternion.temporaryQuaternion.setFromRadianAndAxis(radian, new Vector3(0, 1, 0));
    return this.multiply(Quaternion.temporaryQuaternion);
  }

  /**
   * z軸の周りを引数で与えられた角度で回転した新たな四元数を返す
   * @param radian ラジアンで表現された回転角度
   * @returns 回転した四元数
   */
  _rotateZ(radian: number): Quaternion {
    Quaternion.temporaryQuaternion.setFromRadianAndAxis(radian, new Vector3(0, 0, 1));
    return this._multiply(Quaternion.temporaryQuaternion);
  }

  /**
   * 自身をz軸の周りで回転して返す（破壊的）
   * @param radian ラジアンで表現された回転角度
   * @returns 自身を返す
   */
  rotateZ(radian: number): Quaternion {
    Quaternion.temporaryQuaternion.setFromRadianAndAxis(radian, new Vector3(0, 0, 1));
    return this.multiply(Quaternion.temporaryQuaternion);
  }

  /**
   * 4次元行列に変換する
   * @returns 行列
   */
  toMat4(): mat4 {
    mat4.identity(Quaternion.matrix);
    const s = 2 / this.norm();
    const a = this.a;
    const b = this.b;
    const c = this.c;
    const d = this.d;
    mat4.set(
      Quaternion.matrix,
      1 - s * (c ** 2 + d ** 2),
      s * (b * c + d * a),
      s * (b * d - a * c),
      0,
      s * (b * c - a * d),
      1 - s * (b ** 2 + d ** 2),
      s * (c * d + a * b),
      0,
      s * (b * d + a * c),
      s * (c * d - a * b),
      1 - s * (b ** 2 + c ** 2),
      0,
      0,
      0,
      0,
      1
    );
    return Quaternion.matrix;
  }

  /**
   * 与えられたベクトルの周りを与えられた角度で回転する四元数を作成する
   * @param radian ラジアンで表現された回転角
   * @param vector3 回転の軸となる3次元ベクトル
   * @returns 四元数
   */
  static fromRadianAndAxis(radian: number, vector3: Vector3): Quaternion {
    const quaternion = new Quaternion(0, 0, 0, 0);
    quaternion.setFromRadianAndAxis(radian, vector3);
    return quaternion;
  }

  /**
   * 与えられたベクトルの周りを与えられた角度で回転する四元数になるように値を設定する
   * @param radian ラジアンで表現された回転角
   * @param vector3 回転の軸となる3次元ベクトル
   * @returns 自身を返す
   */
  setFromRadianAndAxis(radian: number, vector3: Vector3): Quaternion {
    if (vector3.isZero()) {
      this.setValues(0, 0, 0, 1);
      return this;
    }

    const normalized = vector3._normalize();
    const halfRadian = radian / 2;
    const sinHalfRadian = Math.sin(halfRadian);
    this.setValues(
      Math.cos(halfRadian),
      normalized.x * sinHalfRadian,
      normalized.y * sinHalfRadian,
      normalized.z * sinHalfRadian
    );
    return this;
  }

  /**
   * 回転しないような単位四元数を返す
   * @returns 四元数
   */
  static identity(): Quaternion {
    return Quaternion.fromRadianAndAxis(0, new Vector3(0, 1, 0));
  }
}

const QUATERNION_IDENTITY = Quaternion.identity();

export {Quaternion, QUATERNION_IDENTITY};
