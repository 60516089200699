import {TileObjectLayer} from './TileObjectLayer';
import {GaiaContext} from '../GaiaContext';

/**
 * 任意タイル地図レイヤー
 */
class AdditionTileLayer extends TileObjectLayer {
  readonly priority: number;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param usesFadeInAnimation 新しいタイル表示時にフェードインのアニメーションを利用するかどうか
   * @param priority レイヤー表示優先度
   * @param layerName レイヤー名
   * @param transparency レイヤー不透明度
   */
  constructor(
    context: GaiaContext,
    usesFadeInAnimation: boolean,
    priority: number,
    layerName?: string,
    transparency?: number
  ) {
    super(context, false, usesFadeInAnimation, layerName, transparency);
    this.priority = priority;
  }
}

export {AdditionTileLayer};
