import {JsonObject} from '../../../../gaia/types';
import {AnnotationMetaParameter} from '../param/AnnotationMetaParameter';
import {AnnotationStoreHandler} from './AnnotationStoreHandler';
import {AnnotationMainParameter} from '../param/AnnotationMainParameter';
import {AnnotationIconDBData, AnnotationMainDBData, idbOpen} from './AnnotationDBUtil';

const ANNOTATION_DB = 'annotation_db';
const META_STORE = 'meta';
const ICON_STORE = 'icon';
const MAIN_STORE = 'main';
const DATE_INDEX = 'date';

/**
 * 注記用IndexedDBハンドラ
 */
class AnnotationDBHandler {
  private readonly dbName: string;

  private readonly metaHandler: AnnotationStoreHandler<AnnotationMetaParameter, AnnotationMainDBData>;
  private readonly iconHandler: AnnotationStoreHandler<AnnotationMetaParameter, AnnotationIconDBData>;
  private readonly mainHandler: AnnotationStoreHandler<AnnotationMainParameter, AnnotationMainDBData>;

  /**
   * コンストラクタ
   * @param mainRecordMax メインobjectstoreのレコード上限
   */
  constructor(mainRecordMax?: number) {
    this.dbName = ANNOTATION_DB;
    this.metaHandler = new AnnotationStoreHandler(this.dbName, META_STORE);
    this.iconHandler = new AnnotationStoreHandler(this.dbName, ICON_STORE);
    this.mainHandler = new AnnotationStoreHandler(this.dbName, MAIN_STORE, mainRecordMax);

    if (!window.indexedDB) {
      // eslint-disable-next-line no-console
      console.warn(`[GaIA] Your browser doesn't support a stable version of IndexedDB.`);
      this.metaHandler.setStateUnavailable();
      this.iconHandler.setStateUnavailable();
      this.mainHandler.setStateUnavailable();
      return;
    }
    this.setupDB(false);
  }

  /**
   * 注記用DBの初期化
   * @param retry 再試行フラグ
   * @returns {void}
   */
  private async setupDB(retry: boolean): Promise<void> {
    const db = await idbOpen(this.dbName, this.upgradeDB).catch(() => {
      // eslint-disable-next-line no-console
      console.warn(`[GaIA] Could not open indexedDB.`);
      this.metaHandler.setStateUnavailable();
      this.iconHandler.setStateUnavailable();
      this.mainHandler.setStateUnavailable();
    });
    if (!db) {
      return;
    }

    // オブジェクトストアが揃っていない場合はDBごと削除して作成からやり直す
    if (
      !db.objectStoreNames.contains(META_STORE) ||
      !db.objectStoreNames.contains(ICON_STORE) ||
      !db.objectStoreNames.contains(MAIN_STORE)
    ) {
      db.close();
      indexedDB.deleteDatabase(ANNOTATION_DB);
      if (!retry) {
        this.setupDB(true);
      }
      return;
    }

    // メインオブジェクトストアにdateインデックスがない場合も作成し直し
    const mainIndexes = db.transaction(MAIN_STORE).objectStore(MAIN_STORE).indexNames;
    if (!mainIndexes.contains(DATE_INDEX)) {
      db.close();
      indexedDB.deleteDatabase(ANNOTATION_DB);
      if (!retry) {
        this.setupDB(true);
      }
      return;
    }

    this.metaHandler.setupStore();
    this.iconHandler.setupStore();
    this.mainHandler.setupStore();
  }

  /**
   * DB更新
   * @param upgradedDB 更新対象IDBDatabase
   * @returns {void}
   */
  private upgradeDB(upgradedDB: IDBDatabase): void {
    upgradedDB.createObjectStore(META_STORE);
    upgradedDB.createObjectStore(ICON_STORE);
    const main = upgradedDB.createObjectStore(MAIN_STORE);
    main.createIndex(DATE_INDEX, DATE_INDEX, {unique: false});
  }

  /**
   * メタデータの保存
   * @param metaParam AnnotationMetaParameter
   * @param data メタデータのJson
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  insertMetaData(
    metaParam: AnnotationMetaParameter,
    data: JsonObject,
    callback?: (isSucceeded: boolean, key: AnnotationMetaParameter) => void
  ): void {
    this.metaHandler.insertData(metaParam, data, callback);
  }

  /**
   * メタデータの取得
   * @param metaParam AnnotationMetaParameter
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  findMetaData(
    metaParam: AnnotationMetaParameter,
    callback: (isSucceeded: boolean, key: AnnotationMetaParameter, data?: JsonObject) => void
  ): void {
    this.metaHandler.findData(metaParam, callback);
  }

  /**
   * メタデータ全削除
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  deleteAllMetaData(callback?: (isSucceeded: boolean) => void): void {
    this.metaHandler.deleteAllData(callback);
  }

  /**
   * アイコンデータの保存
   * @param metaParam AnnotationMetaParameter
   * @param data アイコンzipデータ
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  insertIconData(
    metaParam: AnnotationMetaParameter,
    data: Blob,
    callback?: (isSucceeded: boolean, key: AnnotationMetaParameter) => void
  ): void {
    this.iconHandler.insertData(metaParam, data, callback);
  }

  /**
   * アイコンデータの取得
   * @param metaParam AnnotationMetaParameter
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  findIconData(
    metaParam: AnnotationMetaParameter,
    callback: (isSucceeded: boolean, key: AnnotationMetaParameter, data?: Blob) => void
  ): void {
    this.iconHandler.findData(
      metaParam,
      (isSucceeded: boolean, key: AnnotationMetaParameter, iconData?: AnnotationIconDBData) => {
        callback(isSucceeded, key, iconData?.mainInfo);
      }
    );
  }

  /**
   * アイコンデータ全削除
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  deleteAllIconData(callback?: (isSucceeded: boolean) => void): void {
    this.iconHandler.deleteAllData(callback);
  }

  /**
   * メインデータの保存
   * @param mainParam AnnotationMainParameter
   * @param data メインデータ(難読化後)
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  insertMainData(
    mainParam: AnnotationMainParameter,
    data: string,
    callback?: (isSucceeded: boolean, key: AnnotationMainParameter) => void
  ): void {
    const dbData: AnnotationMainDBData = {
      date: Date.now(),
      mainInfo: data,
    };
    this.mainHandler.insertData(mainParam, dbData, callback);
  }

  /**
   * メインデータの取得
   * @param metaParam AnnotationMainParameter
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  findMainData(
    metaParam: AnnotationMainParameter,
    callback: (isSucceeded: boolean, key: AnnotationMainParameter, data?: string) => void
  ): void {
    this.mainHandler.findData(
      metaParam,
      (isSucceeded: boolean, key: AnnotationMainParameter, data?: AnnotationMainDBData) => {
        callback(isSucceeded, key, data?.mainInfo);
      }
    );
  }

  /**
   * メインデータ全削除
   * @param callback オブジェクトストア操作完了通知
   * @returns {void}
   */
  deleteAllMainData(callback?: (isSucceeded: boolean) => void): void {
    this.mainHandler.deleteAllData(callback);
  }

  /**
   * メインデータストアへのリクエストキューを削除
   * @returns {void}
   */
  clearMainRequestQueue(): void {
    this.mainHandler.clearRequestQueue();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.metaHandler.destroy();
    this.iconHandler.destroy();
    this.mainHandler.destroy();
  }
}

export {AnnotationDBHandler};
