import {JsonObject, TileSize} from '../../../../gaia/types';
import {ValueObject} from '../../../../gaia/value/interface/ValueObject';
import {CacheKey} from '../../../common/types';
import {PaletteParameter} from '../../models/PaletteParameter';

/**
 * 新規開通道路メタパラメータ
 */
class RoadShapeOpenedMetaParameter implements ValueObject, CacheKey {
  readonly tileSize: TileSize;
  readonly palette: PaletteParameter;
  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param tileSize タイルサイズ
   * @param palette パレット情報
   */
  constructor(tileSize: TileSize, palette: PaletteParameter) {
    this.tileSize = tileSize;
    this.palette = palette;
    this._cacheKey = `RM(${this.tileSize},${this.palette.language},${this.palette.name})`;
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof RoadShapeOpenedMetaParameter)) {
      return false;
    }
    const other = obj as RoadShapeOpenedMetaParameter;
    return (
      other.tileSize === this.tileSize &&
      other.palette.language === this.palette.language &&
      other.palette.name === this.palette.name
    );
  }

  /** @override */
  clone(): RoadShapeOpenedMetaParameter {
    return new RoadShapeOpenedMetaParameter(this.tileSize, this.palette);
  }

  /** @override */
  toObject(): JsonObject {
    return {
      tileSize: this.tileSize,
      palette: {
        language: this.palette.language,
        name: this.palette.name,
      },
    };
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {RoadShapeOpenedMetaParameter};
