import {Vector3} from '../../../common/math/Vector3';
import {Quaternion} from '../../../common/math/Quaternion';
import {SingleColorMaterial} from '../../../engine/material/SingleColorMaterial';
import {calculatePixelToUnit} from '../../utils/MapUtil';
import {FigureObject} from './FigureObject';
import {MapStatus} from '../../models/MapStatus';
import {PolylineTriangulator} from '../../../engine/polygon/PolylineTriangulator';
import {Vector2} from '../../../common/math/Vector2';
import {douglasPeucker} from '../../../common/math/MathUtil';

const DOUGLAS_PEUCKER_TOLERANCE = 16;
const MAX_VERTICES_SIZE = 65535 * 3; // WebGLの仕様で、頂点の数は (2^16)-1 以下でないといけない

/**
 * ポリラインを表示するオブジェクト
 */
class PolylineObject extends FigureObject {
  private readonly material: SingleColorMaterial;

  /** 太さをもたない形状（GL空間座標の点列で保持） */
  readonly path: Vector3[];
  readonly thickness: number;

  private readonly dashArray: number[];

  private currentZoomLevel: number;

  private strokePath: Vector2[];
  private strokeVertices: number[];

  /**
   * コンストラクタ
   * @param position 位置
   * @param rotation 回転
   * @param scale 拡縮
   * @param material マテリアル
   * @param basePosition ベース位置
   * @param path ポリラインの形状（太さを持たない）
   * @param thickness ポリラインの太さ（ピクセル）
   * @param visible 表示フラグ
   * @param zIndex 重なり順
   * @param dashArray 点線のパターン
   */
  constructor(
    position: Vector3,
    rotation: Quaternion,
    scale: Vector3,
    material: SingleColorMaterial,
    basePosition: Vector3,
    path: Vector3[],
    thickness: number,
    visible: boolean,
    zIndex: number,
    dashArray: number[]
  ) {
    super(position, rotation, scale, material, basePosition, visible, zIndex);
    this.material = material;
    this.path = path;
    this.thickness = thickness;
    this.dashArray = dashArray.slice();

    this.strokePath = [];
    this.strokeVertices = [];

    this.currentZoomLevel = 0;
  }

  /**
   * 2次元ベクトル配列のパスを取得
   * @returns 2次元ベクトル配列のパス
   */
  getStrokePath(): Vector2[] {
    return this.strokePath;
  }

  /** @override */
  updateFigure(cameraTargetPosition: Vector3, mapStatus: MapStatus): void {
    this.setPositionValues(
      this.basePosition.x - cameraTargetPosition.x,
      this.basePosition.y - cameraTargetPosition.y,
      this.basePosition.z - cameraTargetPosition.z
    );

    if (Math.ceil(this.currentZoomLevel) !== Math.ceil(mapStatus.zoomLevel)) {
      this.updateThickness(Math.ceil(mapStatus.zoomLevel));
      this.currentZoomLevel = mapStatus.zoomLevel;
    }
  }

  /**
   * 太さを考慮したポリライン形状を再計算する
   * @param zoomLevel ズームレベル
   * @returns {void}
   */
  updateThickness(zoomLevel: number): void {
    if (this.strokePath.length === 0 || this.strokePath.length !== this.path.length) {
      this.strokePath = [];
      for (const position of this.path) {
        this.strokePath.push(new Vector2(position.x, position.y));
      }
    } else {
      for (let index = 0; index < this.path.length; index++) {
        this.strokePath[index].setValues(this.path[index].x, this.path[index].y);
      }
    }

    const pixelToUnit = calculatePixelToUnit(zoomLevel);

    const dashArray = this.dashArray.map((value) => value * this.thickness * pixelToUnit);

    let triangulator = new PolylineTriangulator(this.thickness * pixelToUnit, dashArray);

    // 同じ点が連続する場合は事前に一方を削除する
    for (let index = 0; index < this.strokePath.length - 1; index++) {
      const current = this.strokePath[index];
      const next = this.strokePath[index + 1];
      if (current.equals(next)) {
        this.strokePath.splice(index, 1);
        index--;
      }
    }

    // まず頂点を間引かずにジオメトリを計算する
    triangulator.addStroke(this.strokePath);
    let geometry = triangulator.triangulate();

    // もしジオメトリの頂点数がWebGLの仕様上限にひっかかる場合は、緯度経度の頂点間引きを行ってからジオメトリを計算する
    if (geometry.getVertices().length > MAX_VERTICES_SIZE) {
      const thinnedStroke = douglasPeucker(this.strokePath, DOUGLAS_PEUCKER_TOLERANCE);
      triangulator = new PolylineTriangulator(this.thickness * pixelToUnit, dashArray);
      triangulator.addStroke(thinnedStroke);
      geometry = triangulator.triangulate();
    }

    this.material.setGeometry(geometry);
  }
}

export {PolylineObject};
