import {Camera} from './Camera';
import {mat4, vec3} from 'gl-matrix';
import {Vector3} from '../../common/math/Vector3';
import {Color} from '../../../gaia/value/Color';
import {Optional} from '../../common/types';
import {Vector2} from '../../common/math/Vector2';
import {DEGREE_TO_RADIAN} from '../../common/math/MathConstants';

/**
 * 遠近感のある一般的なカメラ
 */
class PerspectiveCamera implements Camera {
  private readonly context: WebGLRenderingContext;

  private _position: Vector3;
  private _target: Vector3;
  private _upVector: Vector3;
  private _backgroundColor: Color;

  private _verticalFov: number;
  private _aspect: number;
  private _near: number;
  private _far: number;

  private _viewMatrix: mat4;
  private _projectionMatrix: mat4;

  // Framebuffer用の変数
  private _viewMatrixWithNoRotation: mat4;
  private _projectionMatrixWithNoRotation: mat4;
  private _positionWithNoRotation: Vector3;
  private _upVectorWithNoRotation: Float32Array;
  private _nearForNoRotation: number;
  private _farForNoRotation: number;

  // worldToClient() で使用する変数
  private static normalizedPos: vec3 = vec3.create();

  /**
   * コンストラクタ
   * @param context WebGLRenderingContext
   * @param position カメラの座標
   * @param target カメラが映す対象の座標
   * @param upVector カメラの上向きのベクトル
   * @param backgroundColor 背景色
   * @param verticalFov 縦方向のfov
   * @param aspect 画面のアスペクト比
   * @param near カメラに映る最小距離
   * @param far カメラに映る最大距離
   */
  constructor(
    context: WebGLRenderingContext,
    position: Vector3,
    target: Vector3,
    upVector: Vector3,
    backgroundColor: Color,
    verticalFov: number,
    aspect: number,
    near: number,
    far: number
  ) {
    this.context = context;

    this._position = position.clone();
    this._target = target.clone();
    this._upVector = upVector.clone();
    this._backgroundColor = backgroundColor.clone();

    this._verticalFov = verticalFov;
    this._aspect = aspect;
    this._near = near;
    this._far = far;

    this._viewMatrix = mat4.create();
    this._projectionMatrix = mat4.create();

    this._viewMatrixWithNoRotation = mat4.create();
    this._projectionMatrixWithNoRotation = mat4.create();
    this._positionWithNoRotation = position.clone();
    this._upVectorWithNoRotation = new Float32Array([0, 1, 0]);
    this._nearForNoRotation = near;
    this._farForNoRotation = far;
  }

  /**
   * 位置
   */
  get position(): Vector3 {
    return this._position;
  }

  /**
   * 位置を設定
   * @param position 位置
   * @returns {void}
   */
  setPosition(position: Vector3): void {
    this._position = position;
  }

  /**
   * Framebuffer用のポジションを設定
   * @param positionWithNoRotation Framebuffer用のポジション
   * @returns {void}
   */
  setPositionWithNoRotation(positionWithNoRotation: Vector3): void {
    this._positionWithNoRotation = positionWithNoRotation;
  }

  /**
   * カメラの注視点
   */
  get target(): Vector3 {
    return this._target;
  }

  /**
   * カメラの注視点を設定
   * @param target 注視点のベクトル
   * @returns {void}
   */
  setTarget(target: Vector3): void {
    this._target = target;
  }

  /**
   * 上向きベクトル
   */
  get upVector(): Vector3 {
    return this._upVector;
  }

  /**
   * 上向きベクトルを設定
   * @param upVector 上向きベクトル
   * @returns {void}
   */
  setUpVector(upVector: Vector3): void {
    this._upVector = upVector;
  }

  /**
   * カメラのFOVを取得
   */
  get verticalFov(): number {
    return this._verticalFov;
  }

  /**
   * カメラのFOVを設定
   * @param verticalFov 度表現で縦方向の上下を合わせたFOV
   * @returns {void}
   */
  setVerticalFov(verticalFov: number): void {
    this._verticalFov = verticalFov;
  }

  /**
   * 地図のクライアントサイズを変更
   * @param width 幅（ピクセル）
   * @param height 高さ（ピクセル）
   * @returns {void}
   */
  setClientSize(width: number, height: number): void {
    this.context.viewport(0, 0, width, height);

    const aspect = width / height;
    this._aspect = aspect;
  }

  /**
   * ビュー変換行列
   */
  get viewMatrix(): mat4 {
    return this._viewMatrix;
  }

  /**
   * 投影変換行列
   */
  get projectionMatrix(): mat4 {
    return this._projectionMatrix;
  }

  /**
   * Framebuffer用のビュー変換行列
   */
  get viewMatrixWithNoRotation(): mat4 {
    return this._viewMatrixWithNoRotation;
  }

  /**
   * Framebuffer用の投影変換行列
   */
  get projectionMatrixWithNoRotation(): mat4 {
    return this._projectionMatrixWithNoRotation;
  }

  /**
   * 最小の距離を設定
   * @param near 最小の距離
   * @returns {void}
   */
  setNear(near: number): void {
    this._near = near;
  }

  /**
   * 最大の距離を設定
   * @param far 最大の距離
   * @returns {void}
   */
  setFar(far: number): void {
    this._far = far;
  }

  /**
   * 回転を無視する場合の最小の距離を設定
   * @param nearForNoRotation 最小の距離
   * @returns {void}
   */
  setNearForNoRotation(nearForNoRotation: number): void {
    this._nearForNoRotation = nearForNoRotation;
  }

  /**
   * 回転を無視する場合の最大の距離を設定
   * @param farForNoRotation 最大の距離
   * @returns {void}
   */
  setFarForNoRotation(farForNoRotation: number): void {
    this._farForNoRotation = farForNoRotation;
  }

  /**
   * 描画をクリア
   * @returns {void}
   */
  clear(): void {
    this.context.clearColor(
      this._backgroundColor.r,
      this._backgroundColor.g,
      this._backgroundColor.b,
      this._backgroundColor.a
    );

    this.context.clear(this.context.COLOR_BUFFER_BIT | this.context.DEPTH_BUFFER_BIT);
  }

  /**
   * 描画を更新
   * @returns {void}
   */
  flush(): void {
    this.context.flush();
  }

  /**
   * 更新
   * @returns {void}
   */
  update(): void {
    this.calculateViewMatrix();
    this.calculateProjectionMatrix();

    this.calculateViewMatrixWithNoRotation();
    this.calculateProjectionMatrixWithNoRotation();
  }

  /**
   * ワールド座標をクライアント座標に変換する
   * 変換できない座標が渡された場合は null を返す
   * @param worldPosition ワールド座標
   * @returns クライアント座標もしくはnull
   */
  worldToClient(worldPosition: Vector3): Optional<Vector2> {
    // 入力の座標をビュー変換行列で変換する
    vec3.set(PerspectiveCamera.normalizedPos, worldPosition.x, worldPosition.y, worldPosition.z);
    vec3.transformMat4(PerspectiveCamera.normalizedPos, PerspectiveCamera.normalizedPos, this.viewMatrix);

    if (PerspectiveCamera.normalizedPos[2] >= 0) {
      // 変換対象のワールド座標が、カメラよりも後ろ側にあるため、
      // PerspectiveCameraではクライアント座標に変換できない
      return null;
    }

    const halfVerticalFovRadian = (this.verticalFov / 2) * DEGREE_TO_RADIAN;
    const baseZ = -Math.cos(halfVerticalFovRadian);
    const baseY = Math.sin(halfVerticalFovRadian) * (PerspectiveCamera.normalizedPos[2] / baseZ);
    const baseX = this._aspect * baseY;

    const ratioY = PerspectiveCamera.normalizedPos[1] / baseY;
    const ratioX = PerspectiveCamera.normalizedPos[0] / baseX;

    return new Vector2(ratioX, ratioY);
  }

  /**
   * ビュー変換行列を計算する
   * @returns ビュー変換行列
   */
  private calculateViewMatrix(): mat4 {
    mat4.identity(this._viewMatrix);
    mat4.lookAt(
      this._viewMatrix,
      this._position.toFloat32Array(),
      this._target.toFloat32Array(),
      this._upVector.toFloat32Array()
    );
    return this._viewMatrix;
  }

  /**
   * 投影変換行列を計算する
   * @returns 投影変換行列
   */
  private calculateProjectionMatrix(): mat4 {
    mat4.identity(this._projectionMatrix);
    mat4.perspective(this._projectionMatrix, this._verticalFov * DEGREE_TO_RADIAN, this._aspect, this._near, this._far);
    return this._projectionMatrix;
  }

  /**
   * Framebuffer用のビュー変換行列を計算する
   * @returns {void}
   */
  private calculateViewMatrixWithNoRotation(): void {
    mat4.identity(this._viewMatrixWithNoRotation);
    mat4.lookAt(
      this._viewMatrixWithNoRotation,
      this._positionWithNoRotation.toFloat32Array(),
      this._target.toFloat32Array(),
      this._upVectorWithNoRotation
    );
  }

  /**
   * Framebuffer用の投影変換行列を計算する
   * @returns {void}
   */
  private calculateProjectionMatrixWithNoRotation(): void {
    mat4.identity(this._projectionMatrixWithNoRotation);
    const aspectForFB = 1.0;
    mat4.perspective(
      this._projectionMatrixWithNoRotation,
      this._verticalFov * DEGREE_TO_RADIAN,
      aspectForFB,
      this._nearForNoRotation,
      this._farForNoRotation
    );
  }
}

export {PerspectiveCamera};
