import {MapStatus} from '../../../models/MapStatus';
import {MapRenderKitController, TileRenderKitMapping} from '../MapRenderKitController';
import {LAYER_NAME_ROADSHAPEOPENED, RoadShapeOpenedLayer} from '../../../layer/RoadShapeOpenedLayer';
import {GaiaContext} from '../../../GaiaContext';
import {RoadShapeOpenedLoader} from '../../../loader/RoadShapeOpenedLoader';
import {isRoadShapeOpenedFeature, isRoadShapeOpenedInfo} from '../../../../common/infra/response/RoadShapeOpenedInfo';
import {LatLng, RoadShapeOpenedAppearance} from '../../../../../gaia/value';
import {calculateWorldCoordinate, getZoomLevelFixFunc} from '../../../utils/MapUtil';
import {ArrayList} from '../../../../common/collection/ArrayList';
import {TileNumber} from '../../../models/TileNumber';
import {Camera} from '../../../../engine/camera/Camera';
import {aspectToFogDistanceRatio} from '../../../layer/FogObjectLayer';
import {ZoomLevelFixFunc, Optional} from '../../../../common/types';
import {RoadShapeOpenedCondition} from '../../../../../gaia/value/RoadShapeOpenedCondition';
import {RoadShapeOpenedClickListener, TileSize} from '../../../../../gaia/types';
import {Ray3} from '../../../../common/math/Ray3';
import {Collision} from '../../../../engine/collision/Collision';
import {RoadShapeOpenedMetaParameter} from '../../../loader/param/RoadShapeOpenedMetaParameter';
import {RoadShapeOpenedMainParameter} from '../../../loader/param/RoadShapeOpenedMainParameter';
import {PaletteParameter} from '../../../models/PaletteParameter';
import {RoadShapeOpenedData} from '../../../models/annotation/RoadShapeOpenedData';
import {getDevicePixelRatio} from '../../../../common/util/Device';

// 更新の間隔（単位はミリ秒）
const INTERVAL_UPDATE_MS = 600;

// TODO: データがタイル単位かつseedから取得する機能のRenderKitを抽象化する
/**
 * 新規開通道路オブジェクトを扱う描画キット
 */
class RoadShapeOpenedObjectRenderKit {
  private context: GaiaContext;
  private camera: Camera;
  /** 機能の利用可否 */
  private isEnable = true;

  private roadShapeOpenedLoader: RoadShapeOpenedLoader;
  private roadShapeOpenedLayer: RoadShapeOpenedLayer;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private currentPalette: PaletteParameter;
  private tileSize: TileSize;

  private previousCenter: LatLng;
  private previousZoomLevel: number;
  private readonly fixIntZoomLevel: ZoomLevelFixFunc;

  private previousChangedTime: number;
  private nextUpdate: Optional<NodeJS.Timeout>;
  private running: boolean;

  readonly getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  private condition?: RoadShapeOpenedCondition;

  // TODO: レスポンスから生成したFeatureObjectをキャッシュとして持たせるか検討

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKitCtl MapRenderKitController
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    this.context = context;
    this.camera = camera;

    this.currentPalette = context.getMapStatus().palette;
    this.tileSize = getDevicePixelRatio() > 1 ? 512 : 256;

    this.roadShapeOpenedLoader = new RoadShapeOpenedLoader(context, () => {
      if (this.status && this.tileList) {
        this.updateDrawObjects(this.status, this.tileList);
      }
    });
    this.roadShapeOpenedLayer = new RoadShapeOpenedLayer(context, this, camera);

    this.previousCenter = context.getMapStatus().centerLocation;
    this.previousZoomLevel = this.context.getMapStatus().zoomLevel;
    this.fixIntZoomLevel = getZoomLevelFixFunc(this.context);

    this.previousChangedTime = performance.now();
    this.running = false;

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKitCtl.getAllCollisions(ray);

    context.getGaIAConfiguration().then((config) => {
      this.isEnable = config.features.roadshapeopened;
      if (this.isEnable) {
        this.executeLoader();
      } else {
        this.clear();
      }
    });
  }

  /**
   * 新規開通道路のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setRoadShapeOpenedCondition(condition?: RoadShapeOpenedCondition): void {
    this.condition = condition;
    if (condition) {
      this.roadShapeOpenedLayer.setVisible(true);
      this.roadShapeOpenedLayer.setBaseDate(condition.baseDate);
      this.roadShapeOpenedLayer.setRoadShapeOpenedCallback(condition.callback);
    } else {
      this.roadShapeOpenedLayer.setVisible(false);
      this.roadShapeOpenedLoader.clear();
      this.roadShapeOpenedLayer.clear();
    }
  }

  /**
   * 新規開通道路クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setRoadShapeOpenedClickListener(listener: RoadShapeOpenedClickListener): void {
    this.roadShapeOpenedLayer.setRoadShapeOpenedClickListener(listener);
  }

  /**
   * RenderKit特定用キー
   */
  get identicalName(): keyof TileRenderKitMapping {
    return LAYER_NAME_ROADSHAPEOPENED;
  }

  /**
   * 新規開通道路レイヤーを取得
   * @returns 新規開通道路レイヤー
   */
  getLayer(): RoadShapeOpenedLayer {
    return this.roadShapeOpenedLayer;
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  private clear(): void {
    this.roadShapeOpenedLoader.clear();
    this.roadShapeOpenedLayer.clear();
  }

  /**
   * ローダーのリクエスト実行
   * @returns {void}
   */
  executeLoader(): void {
    this.roadShapeOpenedLoader.executeRequest();
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.isEnable) {
      return;
    }
    if (!this.condition) {
      return;
    }

    // paletteが変更されていたら再びメタリクエストから行う
    if (!this.currentPalette.equals(mapStatus.palette)) {
      this.clear();
      this.currentPalette = mapStatus.palette;
    }

    if (!this.condition.zoomRange.isInRange(mapStatus.zoomLevel)) {
      this.roadShapeOpenedLayer.setVisible(false);
      return;
    }

    const metadata = this.roadShapeOpenedLoader.fetchMetaInfo();
    if (!metadata) {
      const metaParam = new RoadShapeOpenedMetaParameter(this.tileSize, this.currentPalette);
      this.roadShapeOpenedLoader.setMetaParameter(metaParam);
      return;
    }

    this.roadShapeOpenedLayer.setVisible(true);

    this.status = mapStatus;
    this.tileList = tileList;
    this.roadShapeOpenedLoader.jumpUpCacheSize(tileList.size() * 2);

    // ズームレベル整数値・中心緯度経度の変化量が閾値を超えていたらリクエストキュークリア
    const isChangeCenterLat = Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) > 0.1;
    const isChangeCenterLng = Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) > 0.1;
    const isChangeZoomLevel =
      this.fixIntZoomLevel(mapStatus.zoomLevel) !== this.fixIntZoomLevel(this.previousZoomLevel);
    if (isChangeZoomLevel || isChangeCenterLat || isChangeCenterLng) {
      this.roadShapeOpenedLoader.clearRequestQueue();
    }

    const littleChangedLat =
      Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) * 2 ** (mapStatus.zoomLevel + 16) > 0.00000001;
    const littleChangedLon =
      Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) * 2 ** (mapStatus.zoomLevel + 16) > 0.00000001;
    const littleChangedZoomLevel = mapStatus.zoomLevel !== this.previousZoomLevel;
    this.previousZoomLevel = mapStatus.zoomLevel;
    this.previousCenter = mapStatus.centerLocation;
    if (littleChangedLat || littleChangedLon || littleChangedZoomLevel) {
      this.previousChangedTime = performance.now();
    }

    if (performance.now() - this.previousChangedTime < INTERVAL_UPDATE_MS) {
      this.clearNextUpdate();
      this.nextUpdate = setTimeout(() => {
        this.processRoadShapeOpenedData();
        this.clearNextUpdate();
      }, INTERVAL_UPDATE_MS);
    } else {
      this.processRoadShapeOpenedData();
    }

    // リクエスト
    const requestTiles: TileNumber[] = [];
    const requiredInfo: Map<TileNumber, RoadShapeOpenedData[]> = new Map();

    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    for (const tileNumber of tileList) {
      const latLng = tileNumber.centerLocation;
      const position = calculateWorldCoordinate(latLng);
      const distance = position._subtract(centerPosition).magnitude();
      const distanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
      if (distance > cameraDistance * distanceRatio * 0.9) {
        continue;
      }

      const mainParam = new RoadShapeOpenedMainParameter(
        tileNumber.x,
        tileNumber.y,
        tileNumber.z,
        this.tileSize,
        this.currentPalette
      );

      const cache = this.roadShapeOpenedLoader.getCache(mainParam);
      if (!cache || !isRoadShapeOpenedInfo(cache)) {
        requestTiles.push(tileNumber);
        continue;
      }

      const dataList: RoadShapeOpenedData[] = [];
      for (const feature of cache.features) {
        if (!isRoadShapeOpenedFeature(feature)) {
          continue;
        }

        const ntjCode = feature.properties.ntjCode;
        if (!ntjCode) {
          continue;
        }

        const palette = cache.palette[ntjCode];
        const appearance = RoadShapeOpenedAppearance.createRoadShapeOpenedAppearance(palette);
        if (!appearance) {
          continue;
        }

        const data = new RoadShapeOpenedData(feature, appearance);

        dataList.push(data);
      }

      requiredInfo.set(tileNumber, dataList);
    }

    this.roadShapeOpenedLayer.update(mapStatus, requiredInfo);

    if (requestTiles.length === 0) {
      return;
    }
    this.roadShapeOpenedLoader.addRequestQueue(requestTiles, this.tileSize, this.currentPalette);
  }

  /**
   * setTimeoutで仕込んでおいた地図更新を取り消す
   * @returns {void}
   */
  private clearNextUpdate(): void {
    if (!this.nextUpdate) {
      return;
    }
    clearTimeout(this.nextUpdate);
    this.nextUpdate = null;
  }

  /**
   * 後回しにしていたテクスチャ化などの処理を実行する
   * @returns {void}
   */
  private processRoadShapeOpenedData(): void {
    if (this.running || !this.status || !this.tileList) {
      return;
    }
    this.running = true;
    this.roadShapeOpenedLoader.processRoadShapeOpenedData();
    this.updateDrawObjects(this.status, this.tileList);
    this.running = false;
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.roadShapeOpenedLayer.destroy();
    this.roadShapeOpenedLoader.destroy();
  }
}

export {RoadShapeOpenedObjectRenderKit};
