import {TexturePlaneGeometry} from '../geometry/TexturePlaneGeometry';

/**
 * テクスチャ画像と、そのどの部分を切り取って描画するかを保持するクラス
 */
class TextureMapping {
  /** ジオメトリ */
  readonly geometry: TexturePlaneGeometry;

  /** テクスチャ画像 */
  readonly imageSource: TexImageSource;

  /**
   * コンストラクタ
   * @param geometry 形状
   * @param imageSource テクスチャ画像
   */
  constructor(geometry: TexturePlaneGeometry, imageSource: TexImageSource) {
    this.geometry = geometry;
    this.imageSource = imageSource;
  }
}

export {TextureMapping};
