import {PolarCoordinate3} from '../../../common/math/PolarCoordinate3';
import {Quaternion} from '../../../common/math/Quaternion';
import {Ray3} from '../../../common/math/Ray3';
import {Vector2} from '../../../common/math/Vector2';
import {Vector3} from '../../../common/math/Vector3';
import {Optional} from '../../../common/types';
import {Camera} from '../../../engine/camera/Camera';
import {CustomGeometry} from '../../../engine/geometry/CustomGeometry';
import {TexturePlaneMaterial} from '../../../engine/material/TexturePlaneMaterial';
import {AbstractIconAnnotationData} from '../../models/annotation/AbstractIconAnnotationData';
import {calculatePixelToUnit} from '../../utils/MapUtil';
import {AbstractAnnotationGroupObject} from './AbstractAnnotationGroupObject';
import {AnnotationObject} from './AnnotationObject';

/**
 * アイコン注記をまとめて描画するオブジェクト
 */
class IconAnnotationGroupObject<D extends AbstractIconAnnotationData> extends AbstractAnnotationGroupObject<
  AbstractIconAnnotationData
> {
  private annotationObjectList: Map<string, AnnotationObject<D>>;

  private collisionMargin: Vector2;

  /**
   * コンストラクタ
   * @param material TexturePlaneMaterial
   * @param geometry CustomGeometry
   * @param position 位置
   */
  constructor(material: TexturePlaneMaterial, geometry: CustomGeometry, position: Vector3) {
    super(material, geometry, position);
    this.annotationObjectList = new Map();
    this.collisionMargin = Vector2.zero();
  }

  /**
   * AnnotationObject追加
   * @param objects アイコン注記のAnnotationObject
   * @returns {void}
   */
  addAnnotationObjects(objects: Map<string, AnnotationObject<D>>): void {
    for (const [key, obj] of objects.entries()) {
      this.annotationObjectList.set(key, obj);
    }
  }

  /**
   * AnnotationObject削除
   * @param keyList 削除対象となるIconAnnotationDataのcacheKey
   * @returns {void}
   */
  removeAnnotationObjects(keyList: string[]): void {
    for (const key of keyList) {
      this.annotationObjectList.delete(key);
    }
  }

  /** @override */
  getDisplayingObjectList(): Array<AnnotationObject<D>> {
    const displaying: Array<AnnotationObject<D>> = [];
    for (const obj of this.annotationObjectList.values()) {
      if (obj.isVisible) {
        displaying.push(obj);
      }
    }
    return displaying;
  }

  /**
   * アイコンの当たり判定のマージンを設定する
   * @param margin マージン
   * @returns {void}
   */
  setCollisionMargin(margin: Vector2): void {
    this.collisionMargin.setValues(margin.x, margin.y);
  }

  /** @override */
  updateColliders(zoom: number, polar: PolarCoordinate3): void {
    const rotation = Quaternion.identity()
      .rotateZ(Math.PI / 2 + polar.phi)
      .rotateX(polar.theta);
    for (const obj of this.annotationObjectList.values()) {
      if (zoom !== this.currentZoomLevel || obj.worldSize.width === 0 || obj.worldSize.height === 0) {
        obj.updateSize(zoom);
      }

      const ptu = calculatePixelToUnit(zoom);

      const drawArea = this.calculateColliderArea(obj, rotation, zoom);
      obj.drawArea.set(drawArea.topLeft, drawArea.right, drawArea.down);
      obj.collider.setValues(drawArea.topLeft, drawArea.right, drawArea.down);
      obj.cullingCollider.setValues(
        drawArea.topLeft._add(new Vector3(-this.collisionMargin.x * ptu, this.collisionMargin.y * ptu, 0)),
        drawArea.right._addX(this.collisionMargin.x * 2 * ptu),
        drawArea.down._addY(-this.collisionMargin.y * 2 * ptu)
      );
    }
    this.currentZoomLevel = zoom;
  }

  /** @override */
  cullInGroup(camera: Camera, cameraTargetPosition: Vector3): void {
    this.cullHelper.clear();
    const iconObjectList = Array.from(this.annotationObjectList.values());
    iconObjectList.sort((a, b) => b.data.priority - a.data.priority);
    for (const obj of iconObjectList) {
      const topLeft = camera.worldToClient(obj.cullingCollider.rect.topLeft._subtract(cameraTargetPosition));
      const topRight = camera.worldToClient(obj.cullingCollider.rect.topRight._subtract(cameraTargetPosition));
      const bottomLeft = camera.worldToClient(obj.cullingCollider.rect.bottomLeft._subtract(cameraTargetPosition));
      const bottomRight = camera.worldToClient(obj.cullingCollider.rect.bottomRight._subtract(cameraTargetPosition));
      if (!topLeft || !topRight || !bottomLeft || !bottomRight) {
        continue;
      }
      const isSpace = this.cullHelper.canProvideSpace(topLeft, topRight, bottomLeft, bottomRight, obj.data);
      obj.setVisible(isSpace);
    }
  }

  /** @override */
  updateAnnotationGroup(cameraTargetPosition: Vector3): void {
    this.setPositionValues(
      this.basePosition.x - cameraTargetPosition.x,
      this.basePosition.y - cameraTargetPosition.y,
      this.basePosition.z - cameraTargetPosition.z
    );

    const vertices: number[] = [];
    const indices: number[] = [];
    const visibles: boolean[] = [];
    for (const obj of this.annotationObjectList.values()) {
      visibles.push(obj.isVisible);
      if (!obj.isVisible) {
        continue;
      }
      for (const a of this.calculateVertices(obj.drawArea, obj.uv, obj.isVisible)) {
        vertices.push(a);
      }
      for (const b of this.calculateIndices(indices.length / 6)) {
        indices.push(b);
      }
    }
    this.geometry.setVertices(vertices);
    this.geometry.setIndices(indices);
    this.material.setGeometry(this.geometry);
  }

  /** @override */
  getCollidedAnnotation(ray: Ray3): Optional<AnnotationObject<D>> {
    for (const obj of this.annotationObjectList.values()) {
      if (obj.isCollided(ray)) {
        return obj;
      }
    }
    return undefined;
  }
}

export {IconAnnotationGroupObject};
