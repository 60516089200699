import {Object3D} from '../../../engine/object/Object3D';
import {Vector3, ZERO_VECTOR} from '../../../common/math/Vector3';
import {Quaternion} from '../../../common/math/Quaternion';
import {CustomGeometry} from '../../../engine/geometry/CustomGeometry';
import {MapStatus} from '../../models/MapStatus';
import {GLMarkerIconInfo, Size} from '../../../../gaia/value';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {TexturePlaneMaterial} from '../../../engine/material/TexturePlaneMaterial';
import {GLMarkerObject} from './GLMarkerObject';
import {Ray3} from '../../../common/math/Ray3';
import {calculatePixelToUnit, calculateWorldCoordinate} from '../../utils/MapUtil';
import {Rect3} from '../../../common/math/Rect3';
import {GLMarkerLabelOptions, MarkerGravity} from '../../../../gaia/types';
import {GLMarkerTextureMapping} from '../helper/GLMarkerTextureHelper';
import {Optional} from '../../../common/types';
import {Camera} from '../../../engine/camera/Camera';

const VERTICES_DEFAULT_LENGTH = 20;
const EMPTY_VERTICES = new Array(VERTICES_DEFAULT_LENGTH).fill(0);

/**
 * GLMarkerIconInfoが共通のマーカーをまとめて表示するクラス
 */
class GLMarkerGroupObject extends Object3D {
  private readonly camera: Camera;

  readonly material: TexturePlaneMaterial;
  private mapStatus: MapStatus | null = null;

  readonly basePosition: Vector3;
  readonly markerInfo: GLMarkerIconInfo;

  private geometry: CustomGeometry;
  private markerObjectList: GLMarkerObject[];
  private markerSize: Size;

  private currentZoomLevel: number;

  private textureMapping?: GLMarkerTextureMapping;
  readonly labelOptionsMap: Map<string, GLMarkerLabelOptions>;
  private _markerImage?: HTMLImageElement;

  /**
   * コンストラクタ
   * @param position 位置
   * @param rotation 回転
   * @param scale 拡縮
   * @param material マテリアル
   * @param markerInfo GLMarkerIconInfo
   * @param onMarkerImageLoaded マーカー画像読み込み完了通知
   * @param camera カメラ
   */
  constructor(
    position: Vector3,
    rotation: Quaternion,
    scale: Vector3,
    material: TexturePlaneMaterial,
    markerInfo: GLMarkerIconInfo,
    onMarkerImageLoaded: () => void,
    camera: Camera
  ) {
    super(position, rotation, scale, material);
    this.camera = camera;
    this.material = material;

    this.basePosition = position;
    this.markerInfo = markerInfo;

    this.geometry = new CustomGeometry([], []);
    this.markerObjectList = [];
    this.markerSize = new Size(0, 0);
    this.currentZoomLevel = 0;

    this.labelOptionsMap = new Map();
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = (): void => {
      this._markerImage = image;
      onMarkerImageLoaded();
    };
    image.src = markerInfo.icon;
  }

  /**
   * テクスチャの設定
   * @param textureMapping GLMarkerTextureMapping
   * @returns {void}
   */
  setTexture(textureMapping: GLMarkerTextureMapping): void {
    this.textureMapping = textureMapping;

    this.material.setTexture(textureMapping.texture);
    for (const markerObject of this.markerObjectList) {
      if (!(markerObject.labelOptionsKey in textureMapping.details)) {
        continue;
      }
      const uv = textureMapping.details[markerObject.labelOptionsKey].uv;
      markerObject.setUv(uv);
    }
  }

  /**
   * GLMarkerLabelOptionsを追加
   * @param key GLMarkerLabelOptionsのキー
   * @param labelOptions GLMarkerLabelOptions
   * @returns 追加可否
   */
  addLabelOptions(key: string, labelOptions: GLMarkerLabelOptions): boolean {
    if (this.labelOptionsMap.has(key)) {
      return false;
    }
    this.labelOptionsMap.set(key, labelOptions);
    return true;
  }

  /**
   * マーカーオブジェクトのリストを取得
   */
  get markerObjects(): GLMarkerObject[] {
    return this.markerObjectList;
  }

  /**
   * マーカー画像を取得
   */
  get markerImage(): Optional<HTMLImageElement> {
    return this._markerImage;
  }

  /**
   * markerObjectListが空か
   * @returns 空であるか
   */
  isEmpty(): boolean {
    return this.markerObjectList.length === 0;
  }

  /**
   * GLMarkerObjectを追加
   * @param markerObject GLMarkerObject
   * @returns {void}
   */
  addMarkerObject(markerObject: GLMarkerObject): void {
    this.markerObjectList.push(markerObject);

    const vertices = this.geometry.getVertices();
    const drawArea = this.calculateDrawArea(markerObject);
    Array.prototype.push.apply(vertices, this.calculateVertices(drawArea, markerObject.uv, markerObject.isVisible));
    this.geometry.setVertices(vertices);

    const indices = this.geometry.getIndices();
    Array.prototype.push.apply(indices, this.calculateIndices(indices.length / 6));
    this.geometry.setIndices(indices);

    // 既存のテクスチャがあればそのUV座標を使う
    if (this.textureMapping && markerObject.labelOptionsKey in this.textureMapping.details) {
      const uv = this.textureMapping.details[markerObject.labelOptionsKey].uv;
      markerObject.setUv(uv);
    }
  }

  /**
   * GLMarkerObjectを削除
   * @param markerObject 削除対象のGLMarkerObject
   * @returns {void}
   */
  removeMarkerObject(markerObject: GLMarkerObject): void {
    const index = this.markerObjectList.indexOf(markerObject);
    if (index < 0) {
      return;
    }
    this.markerObjectList.splice(index, 1);

    const vertices = this.geometry.getVertices();
    vertices.splice(index * 20, 20);
    this.geometry.setVertices(vertices);

    const indices = this.geometry.getIndices();
    indices.splice(-6, 6);
    this.geometry.setIndices(indices);
  }

  /**
   * 描画更新
   * @param cameraTargetPosition カメラの注視点
   * @param mapStatus MapStatus
   * @returns {void}
   */
  updateMarkerGroup(cameraTargetPosition: Vector3, mapStatus: MapStatus): void {
    this.mapStatus = mapStatus;

    this.setPositionValues(
      this.basePosition.x - cameraTargetPosition.x,
      this.basePosition.y - cameraTargetPosition.y,
      this.basePosition.z - cameraTargetPosition.z
    );

    const zoomLevel = mapStatus.zoomLevel;
    if (zoomLevel !== this.currentZoomLevel) {
      this.markerSize = new Size(
        calculatePixelToUnit(zoomLevel) * this.markerInfo.size.height,
        calculatePixelToUnit(zoomLevel) * this.markerInfo.size.width
      );
      this.currentZoomLevel = zoomLevel;
    }

    this.updateAllVertices(mapStatus);
  }

  /**
   * 全マーカーの頂点座標更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  updateAllVertices(mapStatus: MapStatus): void {
    const vertices: number[] = [];

    // マーカーをz-indexと奥行きで比較して遠い順番にソートする
    const radian = -mapStatus.polar.phi - Math.PI / 2;
    this.markerObjectList.sort((a: GLMarkerObject, b: GLMarkerObject) => {
      if (a.getZIndex() !== b.getZIndex()) {
        return a.getZIndex() - b.getZIndex();
      }
      return b.position.toVector2().rotate(radian).y - a.position.toVector2().rotate(radian).y;
    });

    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    for (const markerObject of this.markerObjectList) {
      const position = markerObject.position;
      const distance = position._subtract(centerPosition).magnitude();
      if (distance > cameraDistance * 1.5) {
        const drawArea = new Rect3(position, Vector3.zero(), Vector3.zero());
        const newVertices = this.calculateVertices(drawArea, markerObject.uv, markerObject.isVisible);
        Array.prototype.push.apply(vertices, newVertices);
        this.updateCollider(markerObject, drawArea);
      } else {
        const drawArea = this.calculateDrawArea(markerObject);
        const newVertices = this.calculateVertices(drawArea, markerObject.uv, markerObject.isVisible);
        Array.prototype.push.apply(vertices, newVertices);
        this.updateCollider(markerObject, drawArea);
      }
    }

    this.geometry.setVertices(vertices);
    this.material.setGeometry(this.geometry);
  }

  /**
   * マーカー単位での頂点座標更新
   * @param markerObject GLMarkerObject
   * @returns {void}
   */
  updateVertices(markerObject: GLMarkerObject): void {
    const index = this.markerObjectList.indexOf(markerObject);
    if (index < 0) {
      return;
    }

    const vertices = this.geometry.getVertices();
    const drawArea = this.calculateDrawArea(markerObject);
    const newVertices = this.calculateVertices(drawArea, markerObject.uv, markerObject.isVisible);
    vertices.splice(index * 20, 20, ...newVertices);

    this.geometry.setVertices(vertices);
    this.material.setGeometry(this.geometry);

    this.updateCollider(markerObject, drawArea);
  }

  /**
   * コライダを更新
   * @param markerObject GLMarkerObject
   * @param drawArea 描画範囲
   * @returns void
   */
  private updateCollider(markerObject: GLMarkerObject, drawArea: Rect3): void {
    if (!this.textureMapping || !this.mapStatus) {
      return;
    }
    const key = markerObject.labelOptionsKey;
    const imageDetail = this.textureMapping.details[key].image;
    const ptu = calculatePixelToUnit(this.mapStatus.zoomLevel);

    const rotation = this.mapStatus
      ? Quaternion.identity()
          ._rotateZ(Math.PI / 2 + this.mapStatus.polar.phi)
          ._rotateX(this.mapStatus.polar.theta)
      : Quaternion.identity();

    const rotatedImageDetailTopLeft = rotation._product(imageDetail.topLeft._multiply(ptu));
    const rotatedImageDetailRight = rotation._product(imageDetail.right._multiply(ptu));
    const rotatedImageDetailDown = rotation._product(imageDetail.down._multiply(-ptu));

    markerObject.collider.setTopLeft(drawArea.topLeft._add(rotatedImageDetailTopLeft));
    markerObject.collider.setRight(rotatedImageDetailRight);
    markerObject.collider.setDown(rotatedImageDetailDown);
  }

  /**
   * Group内でのGLMarkerObjectの描画領域を算出する
   * @param markerObject GLMarkerObject
   * @returns 描画領域
   */
  private calculateDrawArea(markerObject: GLMarkerObject): Rect3 {
    if (!this.textureMapping || !this.mapStatus) {
      return new Rect3(ZERO_VECTOR, ZERO_VECTOR, ZERO_VECTOR);
    }

    const key = markerObject.labelOptionsKey;
    const detail = this.textureMapping.details[key];

    if (!detail) {
      return new Rect3(ZERO_VECTOR, ZERO_VECTOR, ZERO_VECTOR);
    }

    const rotation = this.mapStatus
      ? Quaternion.identity()
          ._rotateZ(Math.PI / 2 + this.mapStatus.polar.phi)
          ._rotateX(this.mapStatus.polar.theta)
      : Quaternion.identity();

    const centerX = markerObject.position.x + this.calculateCenterOffsetX(this.markerInfo.gravity, this.markerSize);
    const centerY = markerObject.position.y + this.calculateCenterOffsetY(this.markerInfo.gravity, this.markerSize);
    const ptu = calculatePixelToUnit(this.mapStatus.zoomLevel);
    const width = detail.size.width * ptu;
    const height = detail.size.height * ptu;

    const imageCenter = detail.image.topLeft._add(detail.image.right._divide(2))._add(detail.image.down._divide(2));

    const topLeft = new Vector3(centerX - imageCenter.x * ptu, centerY + imageCenter.y * ptu, 0);
    const rotatedTopLeft = rotation._product(topLeft._subtract(markerObject.position))._add(markerObject.position);
    const rotatedRight = rotation._product(new Vector3(width, 0, 0));
    const rotatedDown = rotation._product(new Vector3(0, -height, 0));

    return new Rect3(rotatedTopLeft, rotatedRight, rotatedDown);
  }

  /**
   * 描画領域に対応する頂点座標を算出する
   * @param drawArea 描画領域
   * @param uv UV座標
   * @param isVisible 表示状態
   * @returns 頂点座標配列
   */
  private calculateVertices(drawArea: Rect3, uv: TexturePlaneUVCoordinate, isVisible: boolean): number[] {
    if (!isVisible) {
      return EMPTY_VERTICES;
    }

    const vertices: number[] = [];

    const {topLeft, bottomLeft, topRight, bottomRight} = drawArea;
    const relativeTopLeft = topLeft._subtract(this.basePosition);
    const relativeBottomLeft = bottomLeft._subtract(this.basePosition);
    const relativeTopRight = topRight._subtract(this.basePosition);
    const relativeBottomRight = bottomRight._subtract(this.basePosition);

    vertices.push(
      relativeTopLeft.x,
      relativeTopLeft.y,
      relativeTopLeft.z,
      uv.topLeft.x,
      uv.topLeft.y,
      relativeBottomLeft.x,
      relativeBottomLeft.y,
      relativeBottomLeft.z,
      uv.bottomLeft.x,
      uv.bottomLeft.y,
      relativeTopRight.x,
      relativeTopRight.y,
      relativeTopRight.z,
      uv.topRight.x,
      uv.topRight.y,
      relativeBottomRight.x,
      relativeBottomRight.y,
      relativeBottomRight.z,
      uv.bottomRight.x,
      uv.bottomRight.y
    );

    return vertices;
  }

  /**
   * GLMarkerObjectに対応するインデックス座標を算出する
   * @param planeCount オフセット
   * @returns インデックス座標配列
   */
  private calculateIndices(planeCount: number): number[] {
    const indices: number[] = [];
    indices.push(
      0 + planeCount * 4,
      1 + planeCount * 4,
      2 + planeCount * 4,
      1 + planeCount * 4,
      3 + planeCount * 4,
      2 + planeCount * 4
    );
    return indices;
  }

  /**
   * マーカーの中心座標のオフセットを算出する(x座標)
   * @param gravity MarkerGravity
   * @param markerSize GL空間内におけるマーカーサイズ
   * @returns x成分のオフセット
   */
  private calculateCenterOffsetX(gravity: MarkerGravity, markerSize: Size): number {
    switch (gravity) {
      case 'left':
      case 'bottom-left':
      case 'top-left':
        return markerSize.width / 2;
      case 'right':
      case 'bottom-right':
      case 'top-right':
        return -markerSize.width / 2;
      default:
        return 0;
    }
  }

  /**
   * マーカーの中心座標のオフセットを算出する(y座標)
   * @param gravity MarkerGravity
   * @param markerSize GL空間内におけるマーカーサイズ
   * @returns y成分のオフセット
   */
  private calculateCenterOffsetY(gravity: MarkerGravity, markerSize: Size): number {
    switch (gravity) {
      case 'top':
      case 'top-left':
      case 'top-right':
        return -markerSize.height / 2;
      case 'bottom':
      case 'bottom-left':
      case 'bottom-right':
        return markerSize.height / 2;
      default:
        return 0;
    }
  }

  /**
   * rayと衝突するGLMarkerObjectを取得
   * @param ray Ray3
   * @returns rayと衝突する全GLMarkerObject
   */
  getCollidedMarker(ray: Ray3): GLMarkerObject[] {
    const collided: GLMarkerObject[] = [];
    for (const markerObject of this.markerObjectList) {
      if (markerObject.isCollided(ray)) {
        collided.push(markerObject);
      }
    }

    return collided;
  }
}

export {GLMarkerGroupObject};
