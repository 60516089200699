import {AbstractTileRenderKit, calculateTexturePlaneUVCoordinate} from './AbstractTileRenderKit';
import {GaiaContext} from '../../../GaiaContext';
import {MapStatus} from '../../../models/MapStatus';
import {ArrayList} from '../../../../common/collection/ArrayList';
import {TileNumber} from '../../../models/TileNumber';
import {TileParameter} from '../../../models/TileParameter';
import {TextureMapping} from '../../../../engine/program/TextureMapping';
import {SatelliteAnnotationLoader} from '../../../loader/SatelliteAnnotationLoader';
import {TexturePlaneGeometry} from '../../../../engine/geometry/TexturePlaneGeometry';
import {LRUCache} from '../../../../common/collection/LRUCache';
import {TileRenderKitMapping} from '../MapRenderKitController';
import {getDevicePixelRatio} from '../../../../common/util/Device';
import {PaletteParameter} from '../../../models/PaletteParameter';
import {Camera} from '../../../../engine/camera/Camera';
import {calculateWorldCoordinate} from '../../../utils/MapUtil';
import {aspectToFogDistanceRatio} from '../../../layer/FogObjectLayer';

const TILE_LAYER_NAME_SATELLITE_ANNOTATION = 'satelliteAnnotation';

/**
 * 注記タイル制御マネージャー
 */
class SatelliteAnnotationRenderKit extends AbstractTileRenderKit {
  /** 航空衛星写真注記ローダ */
  private loader: SatelliteAnnotationLoader;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private readonly tileParameterPool: LRUCache<TileNumber, TileParameter>;

  private _isVisible: boolean;
  private palette: PaletteParameter;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param camera Camera
   */
  constructor(context: GaiaContext, camera: Camera) {
    super(context, camera, false, TILE_LAYER_NAME_SATELLITE_ANNOTATION);

    this.loader = new SatelliteAnnotationLoader(context, () => {
      if (this.status && this.tileList) {
        this.updateDrawObjects(this.status, this.tileList);
      }
    });

    this.tileParameterPool = new LRUCache<TileNumber, TileParameter>(100);
    this._isVisible = context.getMapStatus().tileType === 'satellite';
    this.palette = context.getMapStatus().palette;
  }

  /** @override */
  get identicalName(): keyof TileRenderKitMapping {
    return TILE_LAYER_NAME_SATELLITE_ANNOTATION;
  }

  /**
   * 表示状態を設定
   * @param isVisible 表示状態
   * @returns {void}
   */
  setVisible(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  /**
   * 表示状態を取得
   * @returns 表示状態
   */
  isVisible(): boolean {
    return this._isVisible;
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  clearCache(): void {
    this.layer.updateTiles(new Map());
    this.tileParameterPool.clear();
    this.loader.clear();
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.palette.equals(mapStatus.palette)) {
      this.clearCache();
      this.palette = mapStatus.palette;
    }

    this.status = mapStatus;
    this.tileList = tileList;
    const requestTiles: TileParameter[] = [];
    const tileTextureMap: Map<TileNumber, TextureMapping> = new Map();

    if (mapStatus.tileType !== 'satellite') {
      this._isVisible = false;
    }

    if (!this._isVisible) {
      this.layer.updateTiles(tileTextureMap);
      return;
    }

    this.loader.jumpUpCacheSize(tileList.size() * 2);
    this.tileParameterPool.jumpUpSize(tileList.size() * 2);

    // 生成済みテクスチャからLayerに描画物を渡す
    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    for (const tileNumber of tileList) {
      const latLng = tileNumber.centerLocation;
      const position = calculateWorldCoordinate(latLng);
      const distance = position._subtract(centerPosition).magnitude();
      const distanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
      if (distance > cameraDistance * distanceRatio) {
        continue;
      }

      const tileParam = this.getTileParameter(tileNumber);
      const tex = this.loader.getTile(tileParam);
      if (tex) {
        const uv = calculateTexturePlaneUVCoordinate(tileNumber, tileNumber);
        if (!uv) {
          continue;
        }
        const geometry = TexturePlaneGeometry.create(1, 1, uv);
        tileTextureMap.set(tileNumber, new TextureMapping(geometry, tex.image));
      } else {
        requestTiles.push(tileParam);
      }
    }

    this.layer.updateTiles(tileTextureMap);

    // この時点ですべての必要なテクスチャがLayerに追加されていれば終了
    if (requestTiles.length === 0) {
      return;
    }

    // loaderにリクエストキュー追加
    this.loader.addRequestQueue(requestTiles);
  }

  /** @override */
  executeLoader(): void {
    this.loader.executeRequest();
  }

  /**
   * TileNumber → TileParameterを生成
   * @param tileNumber TileNumber
   * @param lang 言語
   * @returns TileParameter
   */
  private getTileParameter(tileNumber: TileNumber): TileParameter {
    if (this.tileParameterPool.has(tileNumber)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.tileParameterPool.get(tileNumber)!;
    }
    const {x, y, z} = tileNumber;
    const tileSize = getDevicePixelRatio() > 1 ? 512 : 256;
    const param = new TileParameter(x, y, z, tileSize, 'tile', this.palette);
    this.tileParameterPool.add(tileNumber, param);
    return param;
  }

  /** @override */
  onDestroy(): void {
    this.loader.destroy();
  }
}

export {SatelliteAnnotationRenderKit};
