import {Layer} from '../../engine/layer/Layer';
import {Ray3} from '../../common/math/Ray3';
import {mat4} from 'gl-matrix';
import {Collision} from '../../engine/collision/Collision';
import {GaiaContext} from '../GaiaContext';
import {PolylineObject} from '../render/objects/PolylineObject';
import {MapStatus} from '../models/MapStatus';
import {calculateWorldCoordinate, calculatePixelToUnit} from '../utils/MapUtil';
import {isTrainRouteLocation, TrainRouteFeature, TrainRouteProperty} from '../../common/infra/response/TrainRouteInfo';
import {Vector3, VECTOR_ONES} from '../../common/math/Vector3';
import {LatLng} from '../../../gaia/value';
import {SingleColorMaterial} from '../../engine/material/SingleColorMaterial';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {QUATERNION_IDENTITY} from '../../common/math/Quaternion';
import {DASH_ARRAY_SOLID} from '../../../gaia/object/shape/Polyline';
import {TrainRouteAppearance} from '../../../gaia/value/TrainRouteAppearance';
import {TrainRouteObject} from '../render/objects/TrainRouteObject';
import {TrainRouteCallback, TrainRouteClickListener, TrainRouteLineStyleOption} from '../../../gaia/types';
import {MouseEventObserver} from '../event/MouseEventObserver';
import {Camera} from '../../engine/camera/Camera';
import {PerspectiveCamera} from '../../engine/camera/PerspectiveCamera';
import {TrainRouteObjectRenderKit} from '../render/kit/object/TrainRouteObjectRenderKit';
import {ArrayList} from '../../common/collection/ArrayList';
import {Vector2} from '../../common/math/Vector2';
import {douglasPeucker} from '../../common/math/MathUtil';
import {TileNumber} from '../models/TileNumber';

const LAYER_NAME_TRAINROUTE = 'trainroute';

type TrainRouteSegmentType = 'inline' | 'outline';

/** ズームレベルごとのダグラスポーカー間引きの許容距離 */
const DOUGLAS_PEUCKER_TOLERANCE_TABLE: {[key: number]: number} = {
  6: 1600,
  7: 800,
  8: 100,
  9: 100,
  10: 80,
  11: 40,
  12: 10,
  13: 10,
  14: 10,
  15: 0,
  16: 0,
  17: 0,
  18: 0,
  19: 0,
  20: 0,
};

/**
 * 鉄道路線図レイヤー
 */
class TrainRouteLayer implements Layer {
  private readonly context: GaiaContext;
  private readonly camera: Camera;
  private visible: boolean;

  private currentTile: ArrayList<TileNumber>;
  private inDisplayTrainRouteObjects: Map<string, TrainRouteObject>;
  private dataMap: Map<TrainRouteAppearance, TrainRouteProperty>;

  private onTrainRouteClick?: TrainRouteClickListener;
  private mouse: MouseEventObserver;
  private hoveredObject?: TrainRouteObject;

  private callback?: TrainRouteCallback;

  private isDestroyed = false;

  readonly getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKit TrainRouteObjectRenderKit
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKit: TrainRouteObjectRenderKit, camera: Camera) {
    this.context = context;
    this.camera = camera;
    this.visible = false;

    this.currentTile = ArrayList.empty();
    this.inDisplayTrainRouteObjects = new Map();
    this.dataMap = new Map();

    this.onTrainRouteClick = undefined;
    this.mouse = new MouseEventObserver(context.getBaseElement());
    this.setupHover();
    this.setupClick();

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKit.getAllCollisions(ray);
  }

  /**
   * ホバー時の設定
   * @returns {void}
   */
  private setupHover(): void {
    this.mouse.addEventListener('mousemove', (ev: MouseEvent) => {
      if (!this.onTrainRouteClick || this.inDisplayTrainRouteObjects.size === 0 || !this.isOnMap(ev)) {
        return;
      }
      const ray = this.clientPositionToRay(ev.offsetX, ev.offsetY);
      const allCollisions = this.getAllCollisions(ray);

      for (const [layerName, collisions] of allCollisions.entries()) {
        if (layerName === LAYER_NAME_TRAINROUTE) {
          // 自分のレイヤーで当たり判定があるときは処理を行う
          let collidedTrainRouteObject: TrainRouteObject | undefined;
          if (collisions.length > 0) {
            const collision = collisions[0];
            const additional = collision.getAdditional();
            if (additional) {
              collidedTrainRouteObject = additional as TrainRouteObject;
            }
          }

          if (collidedTrainRouteObject) {
            this.hoveredObject = collidedTrainRouteObject;
            this.context.getBaseElement().style.cursor = 'pointer';
          } else {
            this.hoveredObject = undefined;
            this.context.getBaseElement().style.cursor = 'auto';
          }

          break;
        }

        // 自分のレイヤーより前に当たり判定があるときは何もしない
        if (collisions.length > 0) {
          this.hoveredObject = undefined;
          return;
        }
      }
    });
  }

  /**
   * クリック時の設定
   * @returns {void}
   */
  private setupClick(): void {
    this.mouse.addEventListener('click', () => {
      if (!this.onTrainRouteClick || this.inDisplayTrainRouteObjects.size === 0 || !this.hoveredObject) {
        return;
      }

      const data = this.hoveredObject.property;
      this.onTrainRouteClick?.(data);
    });
  }

  /**
   * 地図に対するマウスイベントかを判定
   * @param ev MouseEvent
   * @returns 地図に対するマウスイベントか
   */
  private isOnMap(ev: MouseEvent): boolean {
    for (const value of ev.composedPath()) {
      const classList = (value as Element).classList;
      if (classList && classList.contains('gia-base-element')) {
        return true;
      }
    }
    return false;
  }

  // TODO クライアント座標→Ray処理をUtilにうつす
  /**
   * クライアント座標をレイキャストに変換する
   * @param clientPositionX x座標
   * @param clientPositionY y座標
   * @returns レイキャスト
   */
  private clientPositionToRay(clientPositionX: number, clientPositionY: number): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const mapStatus = this.context.getMapStatus();

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const halfWidth = (mapStatus.clientWidth * ptu) / 2;
    const halfHeight = (mapStatus.clientHeight * ptu) / 2;
    const toTopVector = mapStatus.polar
      .toUpVector3()
      .normalize()
      .multiply(halfHeight * y);
    const toRightVector = mapStatus.polar
      .toRightVector3()
      .normalize()
      .multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);
    const start = calculateWorldCoordinate(mapStatus.centerLocation).add(this.camera.position);

    const direction = (this.camera as PerspectiveCamera).target._subtract(this.camera.position).add(toTopRightVector);

    const ray = new Ray3(start, direction);

    return ray;
  }

  /**
   * 表示状態の設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  /**
   * 鉄道路線図用コールバックの設定
   * @param callback コールバック関数
   * @returns {void}
   */
  setTrainRouteCallback(callback: TrainRouteCallback): void {
    this.callback = callback;
  }

  /**
   * 鉄道路線図クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setTrainRouteClickListener(listener: TrainRouteClickListener): void {
    this.onTrainRouteClick = listener;
  }

  /**
   * クリア処理
   * @returns {void}
   */
  clear(): void {
    this.currentTile = ArrayList.empty();
    this.inDisplayTrainRouteObjects.clear();
    this.dataMap.clear();
    this.hoveredObject = undefined;
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @param requiredTileMap 描画する鉄道路線図データ
   * @returns {void}
   */
  update(mapStatus: MapStatus, requiredTileMap: Map<TileNumber, TrainRouteFeature[]>): void {
    if (this.isDestroyed) {
      return;
    }

    // 不要になったPolylineObjectを削除する
    const requiredTileList: ArrayList<TileNumber> = ArrayList.from<TileNumber>(Array.from(requiredTileMap.keys()));
    for (const tile of this.currentTile) {
      const index = requiredTileList.indexOf(tile);
      if (index < 0) {
        for (const [rellineId, trainRoute] of this.inDisplayTrainRouteObjects.entries()) {
          trainRoute.removePolylineObjects(tile);

          if (trainRoute.inlineSegmentMap.size === 0 && trainRoute.outlineSegmentMap.size === 0) {
            this.inDisplayTrainRouteObjects.delete(rellineId);
          }
        }
      }
    }

    // 新しく描画する必要があるPolyliObjectを追加する
    if (!this.callback) {
      return;
    }

    for (const [tile, featureList] of requiredTileMap.entries()) {
      if (this.currentTile.indexOf(tile) >= 0) {
        continue;
      }

      for (const feature of featureList) {
        const properties = feature.properties;
        const rellineId = properties.rellineId;
        if (!rellineId) {
          // 路線IDがないものは不正データとする
          continue;
        }

        const appearance = this.callback?.(feature.properties);
        if (!appearance || !appearance.visible) {
          continue;
        }

        let trainRouteObject = this.inDisplayTrainRouteObjects.get(rellineId);
        if (!trainRouteObject) {
          trainRouteObject = new TrainRouteObject(properties);
          this.inDisplayTrainRouteObjects.set(rellineId, trainRouteObject);
        }
        const {inline: inlineStyle, outline: outlineStyle} = appearance.style;
        if (inlineStyle) {
          const polyline = this.createPolylineObject(feature, inlineStyle, tile.z);
          trainRouteObject.addPolylineObjects(tile, polyline, 'inline');
        }
        if (outlineStyle) {
          const polyline = this.createPolylineObject(feature, outlineStyle, tile.z);
          trainRouteObject.addPolylineObjects(tile, polyline, 'outline');
        }
      }
    }

    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    for (const trainRouteObject of this.inDisplayTrainRouteObjects.values()) {
      trainRouteObject.updateTrainRouteObject(cameraTargetPosition, mapStatus);
      trainRouteObject.updateColliders(mapStatus.zoomLevel);
    }

    this.currentTile = requiredTileList;
  }

  /**
   * 鉄道路線図情報および見た目情報をもとにPolylineObjectを作成
   * @param feature TrainRouteFeature
   * @param style TrainRouteLineStyleOption
   * @param zoom 表示中ズームレベル
   * @returns PolylineObject
   */
  private createPolylineObject(
    feature: TrainRouteFeature,
    style: TrainRouteLineStyleOption,
    zoom: number
  ): PolylineObject {
    const vec2Path: Vector2[] = [];
    const locationList = feature.geometry.locations;
    const {lat: baseLat, lon: baseLng} = locationList[0];
    const basePosition = calculateWorldCoordinate(new LatLng(baseLat, baseLng));
    for (const location of locationList) {
      if (!isTrainRouteLocation(location)) {
        continue;
      }

      const lat = location.lat;
      const lng = location.lon;
      const latlng = new LatLng(lat, lng);
      const position = calculateWorldCoordinate(latlng).subtract(basePosition);
      vec2Path.push(position.toVector2());
    }

    // 間引きを行う
    const tolerance = DOUGLAS_PEUCKER_TOLERANCE_TABLE[zoom] ?? 100;
    const thinnedStroke = douglasPeucker(vec2Path, tolerance);
    const path: Vector3[] = [];
    for (const vec2 of thinnedStroke) {
      path.push(vec2.toVector3());
    }

    const material = new SingleColorMaterial(this.context.getGLContext(), new CustomGeometry([], []), style.color);
    const polyline = new PolylineObject(
      basePosition,
      QUATERNION_IDENTITY,
      VECTOR_ONES,
      material,
      basePosition,
      path,
      style.weight,
      true,
      0,
      style.dashArray ?? DASH_ARRAY_SOLID
    );

    return polyline;
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (!this.visible) {
      return true;
    }

    for (const trainRouteObject of this.inDisplayTrainRouteObjects.values()) {
      trainRouteObject.updateDraw(viewMatrix, projectionMatrix);
    }
    return true;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_TRAINROUTE;
  }

  /** @override */
  getCollisions(ray: Ray3): Collision[] {
    if (!this.onTrainRouteClick) {
      return [];
    }

    const collisions: Collision[] = [];
    const trainRoutes = Array.from(this.inDisplayTrainRouteObjects.values());
    for (const trainRoute of trainRoutes) {
      const collidedPolyline = trainRoute.getCollidedPolyline(ray);
      if (collidedPolyline) {
        collisions.push(new Collision(collidedPolyline, trainRoute));
      }
    }

    return collisions;
  }

  /** @override */
  destroy(): void {
    for (const obj of this.inDisplayTrainRouteObjects.values()) {
      obj.destroy();
    }
    this.inDisplayTrainRouteObjects.clear();
    this.dataMap.clear();
    this.hoveredObject?.destroy();
    this.hoveredObject = undefined;
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {TrainRouteLayer, LAYER_NAME_TRAINROUTE, TrainRouteSegmentType};
