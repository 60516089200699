import {mat4} from 'gl-matrix';
import {AdditionTileLayer} from './AdditionTileLayer';
import {Layer} from '../../engine/layer/Layer';
import {Ray3} from '../../common/math/Ray3';
import {Collision} from '../../engine/collision/Collision';
import {TILE_LAYER_NAME_ADDITION} from '../render/kit/tile/AdditionTileRenderKit';

/**
 * 任意タイル地図のレイヤーをまとめるレイヤー
 */
class AdditionTileGroupLayer implements Layer {
  private layerList: AdditionTileLayer[];
  private _isAltitudeMode: boolean;

  /**
   * コンストラクタ
   */
  constructor() {
    this.layerList = [];
    this._isAltitudeMode = false;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return TILE_LAYER_NAME_ADDITION;
  }

  /**
   * レイヤーリストを取得
   * @returns レイヤーリスト
   */
  getLayerList(): AdditionTileLayer[] {
    return this.layerList;
  }

  /**
   * 標高モードの状態を設定
   * @param altitudeMode 標高モードの状態
   * @returns {void}
   */
  setAltitudeMode(altitudeMode: boolean): void {
    this._isAltitudeMode = altitudeMode;
  }

  /**
   * 任意タイル地図レイヤーを追加
   * @param layer 任意タイル地図レイヤー
   * @returns {void}
   */
  addAdditionTileLayer(layer: AdditionTileLayer): void {
    this.layerList.push(layer);
    this.layerList.sort((a, b) => a.priority - b.priority);
  }

  /**
   * 任意タイル地図レイヤーを削除
   * @param layerName 削除対象のレイヤー名
   * @returns {void}
   */
  removeAdditionTileLayer(layerName: string): void {
    for (let i = 0, length = this.layerList.length; i < length; i++) {
      const layer = this.layerList[i];
      if (layer.getIdenticalLayerName() === layerName) {
        this.layerList.splice(i, 1);
        return;
      }
    }
  }

  /**
   * 全レイヤー名のリストを取得
   * @returns レイヤー名リスト
   */
  getLayerNameList(): string[] {
    const nameList = [];
    for (const layer of this.layerList) {
      nameList.push(layer.getIdenticalLayerName());
    }
    return nameList;
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (this.layerList.length === 0) {
      return true;
    }

    let updateFinished = true;
    for (const layer of this.layerList) {
      updateFinished = layer.updateLayer(viewMatrix, projectionMatrix) && updateFinished;
    }

    return updateFinished;
  }

  /** @override */
  destroy(): void {
    for (const layer of this.layerList) {
      layer.destroy();
    }
    this.layerList.length = 0;
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return this._isAltitudeMode;
  }
}

export {AdditionTileGroupLayer};
