import {
  UserLocationInitOptions,
  GaIAEventListenerOptions,
  UserLocationEventMap,
  UserLocationProjectionMode,
} from '../types';
import {GLMarkerIconInfo} from '../value';
import {GaIAEventEmitter} from '../value/interface/GaIAEventEmitter';
import {GaIAEventListenerHelper} from '../../_private/map/event/GaIAEventListenerHelper';

/**
 * 自位置マーカーのクラス
 */
class UserLocation implements GaIAEventEmitter {
  /** 画像情報 */
  readonly iconInfo: GLMarkerIconInfo;
  /** 表示状態 */
  private visible: boolean;
  /** 投影モード */
  private projectionMode: UserLocationProjectionMode;

  private readonly helper: GaIAEventListenerHelper<UserLocationEventMap>;
  private onStatusUpdateListener?: (userLocation: UserLocation) => void;

  /**
   * コンストラクタ
   * @param options オプション
   */
  constructor(options: UserLocationInitOptions) {
    this.iconInfo = options.info;
    this.visible = true;
    this.projectionMode = options.projectionMode ?? 'orthographic';

    this.helper = new GaIAEventListenerHelper<UserLocationEventMap>();
  }

  /**
   * 自位置マーカーを表示
   * @returns {void}
   */
  show(): void {
    if (this.visible) {
      return;
    }

    this.visible = true;
    this.helper.trigger('appear', {
      sourceObject: this,
    });
  }

  /**
   * 自位置マーカーを非表示
   * @returns {void}
   */
  hide(): void {
    if (!this.visible) {
      return;
    }

    this.visible = false;
    this.helper.trigger('disappear', {
      sourceObject: this,
    });
  }

  /**
   * 表示状態の取得
   * @returns `true`:表示, `false`: 非表示
   */
  isVisible(): boolean {
    return this.visible;
  }

  /**
   * 投影モードの設定
   * @param mode UserLocationProjectionMode
   * @returns {void}
   */
  setProjectionMode(mode: UserLocationProjectionMode): void {
    this.projectionMode = mode;
    this.onStatusUpdateListener?.(this);
  }

  /**
   * 投影モードの取得
   * @returns 投影モード
   */
  getProjectionMode(): UserLocationProjectionMode {
    return this.projectionMode;
  }

  /** @override */
  addEventListener<K extends keyof UserLocationEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: UserLocationEventMap[K]) => any,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.addListener(eventName, func, options);
  }

  /** @override */
  removeEventListener<K extends keyof UserLocationEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: UserLocationEventMap[K]) => any,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.removeListener(eventName, func, options);
  }

  /**
   * 状態更新通知リスナーの設定
   * @ignore
   * @param listener リスナー関数
   * @returns {void}
   */
  setStatusUpdateListener(listener: (userLocation: UserLocation) => void): void {
    this.onStatusUpdateListener = listener;
  }
}

export {UserLocation};
