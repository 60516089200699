import {mat4} from 'gl-matrix';
import {Program} from './Program';
import {Geometry} from '../geometry/Geometry';
import {Optional, RenderTarget} from '../../common/types';
import {Object3D} from '../object/Object3D';
import {TextureMapping} from './TextureMapping';
import {getDevicePixelRatio} from '../../common/util/Device';
import {MapStatus} from '../../map/models/MapStatus';

const MAX_ALPHA = 1.0;

const vertexShaderSource = `
attribute vec3 vertexPosition;
attribute vec2 texCoord;

uniform mat4 model;
uniform mat4 view;
uniform mat4 projection;

varying vec2 textureCoord;

void main() {
  textureCoord = texCoord;
  gl_Position = projection * view * model * vec4(vertexPosition, 1.0);
}
`;

const fragmentShaderSource = `
precision highp float;

uniform sampler2D tex;
uniform float textureAlpha;
uniform bool isPremultipliedAlpha;

varying vec2 textureCoord;

void main() {
  gl_FragColor = texture2D(tex, textureCoord);
  if (isPremultipliedAlpha && gl_FragColor.a > 0.0) {
    gl_FragColor.r /= gl_FragColor.a;
    gl_FragColor.g /= gl_FragColor.a;
    gl_FragColor.b /= gl_FragColor.a;
  }
  gl_FragColor.a *= textureAlpha;
}
`;

let VERTEX_SHADER: Optional<WebGLShader>;
let FRAGMENT_SHADER: Optional<WebGLShader>;
let PROGRAM: Optional<WebGLProgram>;
const DEFAULT_TEX_PARAMETER_MAP = new Map([
  [WebGLRenderingContext.TEXTURE_WRAP_S, WebGLRenderingContext.CLAMP_TO_EDGE],
  [WebGLRenderingContext.TEXTURE_WRAP_T, WebGLRenderingContext.CLAMP_TO_EDGE],
  [WebGLRenderingContext.TEXTURE_MAG_FILTER, WebGLRenderingContext.LINEAR],
  [WebGLRenderingContext.TEXTURE_MIN_FILTER, WebGLRenderingContext.LINEAR],
]);

/**
 * テクスチャ用のプログラム
 */
class TexturePlaneProgram implements Program {
  context: WebGLRenderingContext;
  geometry: Geometry;

  vertexShader: Optional<WebGLShader>;
  fragmentShader: Optional<WebGLShader>;
  program: Optional<WebGLProgram>;

  static initialTexture: TexImageSource | undefined;
  image: Optional<TexImageSource>;
  imageAlpha: number;
  isPremultipliedAlphaImage: boolean;
  texture: WebGLTexture | null;

  private modelLocation: Optional<WebGLUniformLocation>;
  private viewLocation: Optional<WebGLUniformLocation>;
  private projectionLocation: Optional<WebGLUniformLocation>;
  private textureAlphaLocation: Optional<WebGLUniformLocation>;
  private isPremultipliedAlphaLocation: Optional<WebGLUniformLocation>;

  private modelMatrix: mat4;
  private translationMatrix: mat4;
  private rotationMatrix: mat4;
  private scaleMatrix: mat4;

  private vertexBuffer: Optional<WebGLBuffer>;
  private indexBuffer: Optional<WebGLBuffer>;

  private typedVertices: Float32Array;
  private typedIndices: Uint16Array;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param isPremultipliedAlpha アルファモードの指定
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry, isPremultipliedAlpha: boolean) {
    this.context = context;

    this.imageAlpha = MAX_ALPHA;
    this.isPremultipliedAlphaImage = isPremultipliedAlpha;
    this.texture = context.createTexture();

    this.modelMatrix = mat4.create();
    this.translationMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.scaleMatrix = mat4.create();

    this.typedVertices = new Float32Array();
    this.typedIndices = new Uint16Array();

    this.setupProgram();
    this.setGeometry(geometry);
    this.setupGeometry(geometry);
    this.initTexture();

    this.geometry = geometry;
  }

  /**
   * プログラムを準備
   * @returns {void}
   */
  setupProgram(): void {
    if (!VERTEX_SHADER) {
      const mayBeVertexShader: WebGLShader | null = this.context.createShader(this.context.VERTEX_SHADER);
      if (mayBeVertexShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create vertex shader');
        return;
      }
      VERTEX_SHADER = mayBeVertexShader;
      this.context.shaderSource(VERTEX_SHADER, vertexShaderSource);
      this.context.compileShader(VERTEX_SHADER);

      const vertexShaderCompileStatus = this.context.getShaderParameter(VERTEX_SHADER, this.context.COMPILE_STATUS);
      if (!vertexShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(VERTEX_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
        return;
      }
    }
    this.vertexShader = VERTEX_SHADER;

    if (!FRAGMENT_SHADER) {
      const mayBeFragmentShader: WebGLShader | null = this.context.createShader(this.context.FRAGMENT_SHADER);
      if (mayBeFragmentShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create fragment shader');
        return;
      }
      FRAGMENT_SHADER = mayBeFragmentShader;
      this.context.shaderSource(FRAGMENT_SHADER, fragmentShaderSource);
      this.context.compileShader(FRAGMENT_SHADER);

      const fragmentShaderCompileStatus = this.context.getShaderParameter(FRAGMENT_SHADER, this.context.COMPILE_STATUS);
      if (!fragmentShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(FRAGMENT_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.fragmentShader = FRAGMENT_SHADER;

    if (!PROGRAM) {
      const mayBeProgram = this.context.createProgram();
      if (mayBeProgram === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create program');
        return;
      }
      PROGRAM = mayBeProgram;
      this.context.attachShader(PROGRAM, this.vertexShader);
      this.context.attachShader(PROGRAM, this.fragmentShader);
      this.context.linkProgram(PROGRAM);

      const linkStatus = this.context.getProgramParameter(PROGRAM, this.context.LINK_STATUS);
      if (!linkStatus) {
        const info = this.context.getProgramInfoLog(PROGRAM);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.program = PROGRAM;

    this.context.useProgram(this.program);
  }

  /**
   * ジオメトリを準備
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setupGeometry(geometry: Geometry): void {
    if (!this.program) {
      return;
    }

    this.geometry = geometry;

    this.context.useProgram(this.program);
    const gl = this.context;

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertexAttribLocation = gl.getAttribLocation(this.program, 'vertexPosition');
    const textureAttribLocation = gl.getAttribLocation(this.program, 'texCoord');

    const VERTEX_SIZE = 3;
    const TEXTURE_SIZE = 2;
    const STRIDE = (VERTEX_SIZE + TEXTURE_SIZE) * Float32Array.BYTES_PER_ELEMENT;
    const VERTEX_OFFSET = 0;
    const TEXTURE_OFFSET = 3 * Float32Array.BYTES_PER_ELEMENT;

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);

    gl.enableVertexAttribArray(vertexAttribLocation);
    gl.enableVertexAttribArray(textureAttribLocation);

    gl.vertexAttribPointer(vertexAttribLocation, VERTEX_SIZE, gl.FLOAT, false, STRIDE, VERTEX_OFFSET);
    gl.vertexAttribPointer(textureAttribLocation, TEXTURE_SIZE, gl.FLOAT, false, STRIDE, TEXTURE_OFFSET);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
  }

  /**
   * テクスチャを初期化する
   * @returns {void}
   */
  initTexture(): void {
    if (!TexturePlaneProgram.initialTexture) {
      const tileSize = 2;
      const canvas = document.createElement('canvas');
      canvas.width = tileSize;
      canvas.height = tileSize;
      const context = canvas.getContext('2d');
      if (!context) {
        return;
      }
      context.fillStyle = '#00000000';
      context.fillRect(0, 0, tileSize, tileSize);

      TexturePlaneProgram.initialTexture = canvas;
    }
    this.setTexture(TexturePlaneProgram.initialTexture);
  }

  /**
   * テクスチャを更新する
   * @param image テクスチャ画像ソース
   * @param texParameterMap テクスチャパラメータ
   * @returns {void}
   */
  setTexture(image: TexImageSource, texParameterMap: Map<GLenum, GLenum> = DEFAULT_TEX_PARAMETER_MAP): void {
    if (!this.program) {
      return;
    }

    if (this.image === image) {
      return;
    }

    this.image = image;

    this.context.useProgram(this.program);

    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
    this.context.texImage2D(
      this.context.TEXTURE_2D,
      0,
      this.context.RGBA,
      this.context.RGBA,
      this.context.UNSIGNED_BYTE,
      image
    );
    texParameterMap.forEach((value, key) => {
      this.context.texParameteri(this.context.TEXTURE_2D, key, value);
    });
    this.context.bindTexture(this.context.TEXTURE_2D, null);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.geometry = geometry;
    const gl = this.context;

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertices = this.geometry.getVertices();
    const verticesLength = vertices.length;
    if (this.typedVertices.length !== verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    const indices = this.geometry.getIndices();
    const indicesLength = indices.length;
    if (this.typedIndices.length !== indicesLength) {
      this.typedIndices = new Uint16Array(indicesLength);
    }
    this.typedIndices.set(indices);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.typedVertices, gl.STATIC_DRAW);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.typedIndices, gl.STATIC_DRAW);
  }

  /**
   * テクスチャ画像とUV画像を更新する
   * @param textureMapping テクスチャ情報
   * @returns {void}
   */
  setTextureMapping(textureMapping: TextureMapping): void {
    this.setGeometry(textureMapping.geometry);
    this.setTexture(textureMapping.imageSource);
  }

  /**
   * テクスチャが少しでも透明かどうか
   * @returns 透明性（少しでも透明であればtrue）
   */
  isTransparent(): boolean {
    return this.imageAlpha < MAX_ALPHA;
  }

  /**
   * テクスチャの透明度を取得
   * @returns 透明度
   */
  getTextureTransparency(): number {
    return this.imageAlpha;
  }

  /**
   * テクスチャの透明度を更新する
   * @param alpha 透明度
   * @returns {void}
   */
  setTextureTransparency(alpha: number): void {
    this.imageAlpha = alpha;
  }

  /**
   * テクスチャ更新
   * @returns {void}
   */
  updateTexture(): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
  }

  /**
   * 行列情報を更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    if (!this.program) {
      return;
    }

    // model
    mat4.identity(this.scaleMatrix);
    mat4.identity(this.rotationMatrix);
    mat4.identity(this.translationMatrix);
    mat4.identity(this.modelMatrix);

    mat4.scale(this.scaleMatrix, this.scaleMatrix, object3D.scale.toFloat32Array());
    mat4.multiply(this.rotationMatrix, this.rotationMatrix, object3D.rotation.toMat4());
    mat4.translate(this.translationMatrix, this.translationMatrix, object3D.position.toFloat32Array());

    mat4.multiply(this.modelMatrix, this.modelMatrix, this.translationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.rotationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.scaleMatrix);

    this.context.useProgram(this.program);
    if (!this.modelLocation) {
      this.modelLocation = this.context.getUniformLocation(this.program, 'model');
    }
    if (!this.viewLocation) {
      this.viewLocation = this.context.getUniformLocation(this.program, 'view');
    }
    if (!this.projectionLocation) {
      this.projectionLocation = this.context.getUniformLocation(this.program, 'projection');
    }
    if (!this.textureAlphaLocation) {
      this.textureAlphaLocation = this.context.getUniformLocation(this.program, 'textureAlpha');
    }
    if (!this.isPremultipliedAlphaLocation) {
      this.isPremultipliedAlphaLocation = this.context.getUniformLocation(this.program, 'isPremultipliedAlpha');
    }
    this.context.uniformMatrix4fv(this.modelLocation, false, this.modelMatrix);
    this.context.uniformMatrix4fv(this.viewLocation, false, viewMatrix);
    this.context.uniformMatrix4fv(this.projectionLocation, false, projectionMatrix);
    this.context.uniform1f(this.textureAlphaLocation, this.imageAlpha);
    this.context.uniform1i(this.isPremultipliedAlphaLocation, this.isPremultipliedAlphaImage ? 1 : 0);
  }

  /**
   * 描画
   * @param renderTarget 描画対象の設定
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    this.setupGeometry(this.geometry);
    this.updateTexture();

    const gl = this.context;

    if (!renderTarget) {
      renderTarget = MapStatus.defaultRenderTarget;
    }

    if (renderTarget.type === 'default') {
      const dpr = getDevicePixelRatio();
      gl.viewport(0, 0, dpr * renderTarget.size.width, dpr * renderTarget.size.height);
    } else if (renderTarget.type === 'framebuffer') {
      gl.viewport(0, 0, renderTarget.size.width, renderTarget.size.height);
      gl.bindFramebuffer(gl.FRAMEBUFFER, renderTarget.target);
    }

    this.context.blendFuncSeparate(
      this.context.SRC_ALPHA,
      this.context.ONE_MINUS_SRC_ALPHA,
      this.context.ONE,
      this.context.ONE
    );
    this.context.enable(this.context.BLEND);

    gl.disable(gl.DEPTH_TEST);

    this.context.drawElements(
      this.context.TRIANGLES,
      this.geometry.getIndices().length,
      this.context.UNSIGNED_SHORT,
      0
    );

    if (renderTarget.type !== 'framebuffer') {
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    }
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const gl = this.context;
    if (PROGRAM && VERTEX_SHADER && FRAGMENT_SHADER) {
      gl.detachShader(PROGRAM, VERTEX_SHADER);
      gl.detachShader(PROGRAM, FRAGMENT_SHADER);
      gl.deleteProgram(PROGRAM);
      PROGRAM = undefined;
    }
    if (VERTEX_SHADER) {
      gl.deleteShader(VERTEX_SHADER);
      VERTEX_SHADER = undefined;
    }
    if (FRAGMENT_SHADER) {
      gl.deleteShader(FRAGMENT_SHADER);
      FRAGMENT_SHADER = undefined;
    }
    if (this.indexBuffer) {
      gl.deleteBuffer(this.indexBuffer);
      this.indexBuffer = undefined;
    }
    if (this.vertexBuffer) {
      gl.deleteBuffer(this.vertexBuffer);
      this.vertexBuffer = undefined;
    }
    this.viewLocation = undefined;
    this.modelLocation = undefined;
    this.projectionLocation = undefined;
    this.textureAlphaLocation = undefined;
  }
}

export {TexturePlaneProgram};
