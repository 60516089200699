// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExecuteFuncOnWorker = (this: Worker, ev: MessageEvent) => any;

/**
 * 任意の関数を実行するWebWorkerを生成
 * @param fn Workerで実行する処理
 * @param name Worker名
 * @returns WebWorker
 */
const createWorker = (fn: ExecuteFuncOnWorker, name?: string): Worker => {
  const blob = new Blob(['self.onmessage = ', fn.toString(), ''], {type: 'application/javascript'});
  const url = URL.createObjectURL(blob);
  const worker = new Worker(url, name ? {name} : {});
  URL.revokeObjectURL(url);
  return worker;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 任意の関数(別の関数の実行を含む)を実行するWebWorkerを生成
 * @param mainFn Workerで実行する処理
 * @param otherfnList mainFnの中で実行される関数
 * @param name Worker名
 * @returns WebWorker
 */
const createWorkerWithMethods = (mainFn: ExecuteFuncOnWorker, otherfnList: any[], name?: string): Worker => {
  let otherFnString = '';
  for (const otherFn of otherfnList) {
    otherFnString += `var ${otherFn.name} = ${otherFn.toString()}\n`;
  }
  const blob = new Blob([otherFnString, 'self.onmessage = ', mainFn.toString(), '']);
  const url = URL.createObjectURL(blob);
  const worker = new Worker(url, name ? {name} : {});
  URL.revokeObjectURL(url);
  return worker;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export {ExecuteFuncOnWorker, createWorker, createWorkerWithMethods};
