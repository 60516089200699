import {PolarCoordinate3} from '../../../common/math/PolarCoordinate3';
import {Quaternion} from '../../../common/math/Quaternion';
import {Ray3} from '../../../common/math/Ray3';
import {Vector2} from '../../../common/math/Vector2';
import {Vector3} from '../../../common/math/Vector3';
import {Optional} from '../../../common/types';
import {Camera} from '../../../engine/camera/Camera';
import {CustomGeometry} from '../../../engine/geometry/CustomGeometry';
import {TexturePlaneMaterial} from '../../../engine/material/TexturePlaneMaterial';
import {SQ_CULLING_LENGTH} from '../../layer/MarkAnnotationLayer';
import {MarkAnnotationData} from '../../models/annotation/MarkAnnotationData';
import {AbstractAnnotationGroupObject} from './AbstractAnnotationGroupObject';
import {AnnotationObject} from './AnnotationObject';

/**
 * 国道アイコン注記をまとめて描画するオブジェクト
 */
class MarkAnnotationGroupObject extends AbstractAnnotationGroupObject<MarkAnnotationData> {
  private annotationObjectList: Array<AnnotationObject<MarkAnnotationData>>;

  /**
   * コンストラクタ
   * @param material TexturePlaneMaterial
   * @param geometry CustomGeometry
   * @param annotationObjectList AnnotationObjectリスト
   */
  constructor(
    material: TexturePlaneMaterial,
    geometry: CustomGeometry,
    annotationObjectList: Array<AnnotationObject<MarkAnnotationData>>
  ) {
    super(material, geometry, annotationObjectList[0].position);
    this.annotationObjectList = annotationObjectList;
  }

  /** @override */
  getDisplayingObjectList(): Array<AnnotationObject<MarkAnnotationData>> {
    const displaying: Array<AnnotationObject<MarkAnnotationData>> = [];
    for (const obj of this.annotationObjectList) {
      if (obj.isVisible) {
        displaying.push(obj);
      }
    }
    return displaying;
  }

  /** @override */
  updateColliders(zoom: number, polar: PolarCoordinate3): void {
    const rotation = Quaternion.identity()
      ._rotateZ(Math.PI / 2 + polar.phi)
      ._rotateX(polar.theta);
    for (const obj of this.annotationObjectList) {
      if (zoom !== this.currentZoomLevel) {
        obj.updateSize(zoom);
      }

      const drawArea = this.calculateColliderArea(obj, rotation, zoom);
      obj.collider.setTopLeft(drawArea.topLeft);
      obj.collider.setRight(drawArea.right);
      obj.collider.setDown(drawArea.down);
    }
    this.currentZoomLevel = zoom;
  }

  /** @override */
  cullInGroup(camera: Camera, cameraTargetPosition: Vector3): void {
    for (const obj of this.annotationObjectList) {
      const topLeft = camera.worldToClient(obj.collider.rect.topLeft._subtract(cameraTargetPosition));
      const topRight = camera.worldToClient(obj.collider.rect.topRight._subtract(cameraTargetPosition));
      const bottomLeft = camera.worldToClient(obj.collider.rect.bottomLeft._subtract(cameraTargetPosition));
      const bottomRight = camera.worldToClient(obj.collider.rect.bottomRight._subtract(cameraTargetPosition));
      if (!topLeft || !topRight || !bottomLeft || !bottomRight) {
        continue;
      }
      if (this.checkCullSameMark(topLeft, obj.data)) {
        obj.setVisible(false);
        continue;
      }
      obj.setVisible(this.cullHelper.canProvideSpace(topLeft, topRight, bottomLeft, bottomRight, obj.data));
    }
    this.cullHelper.clear();
  }

  /**
   * 同一国道アイコンの間引き判定
   * @param targetPos 判定対象の位置
   * @param targetData 判定対象のデータ
   * @returns `true`: 間引く, `false`: 間引かない
   */
  private checkCullSameMark(targetPos: Vector2, targetData: MarkAnnotationData): boolean {
    const rbushTree = this.cullHelper.tree.all();
    for (const box of rbushTree) {
      if (!targetData.equals(box.data)) {
        continue;
      }
      const length =
        (targetPos.x - box.minX) * (targetPos.x - box.minX) + (targetPos.y - box.maxY) * (targetPos.y - box.maxY);
      if (length < SQ_CULLING_LENGTH) {
        return true;
      }
    }
    return false;
  }

  /** @override */
  updateAnnotationGroup(cameraTargetPosition: Vector3): void {
    this.setPositionValues(
      this.basePosition.x - cameraTargetPosition.x,
      this.basePosition.y - cameraTargetPosition.y,
      this.basePosition.z - cameraTargetPosition.z
    );

    const vertices: number[] = [];
    const indices: number[] = [];
    for (const obj of this.annotationObjectList) {
      if (!obj.isVisible) {
        continue;
      }
      vertices.push(...this.calculateVertices(obj.collider.rect, obj.uv, obj.isVisible));
      indices.push(...this.calculateIndices(indices.length / 6));
    }
    this.geometry.setVertices(vertices);
    this.geometry.setIndices(indices);
    this.material.setGeometry(this.geometry);
  }

  /** @override */
  getCollidedAnnotation(ray: Ray3): Optional<AnnotationObject<MarkAnnotationData>> {
    for (const obj of this.annotationObjectList) {
      if (obj.isCollided(ray)) {
        return obj;
      }
    }
    return undefined;
  }
}

export {MarkAnnotationGroupObject};
