import {GaiaContext} from '../_private/map/GaiaContext';
import {GaIAClassName, injectStyle} from '../_private/common/util/Styles';
import {MapCoreController} from '../_private/map/MapCoreController';
import {GaIAError} from './value/GaIAError';
import {AccessInformation, isAccessInformation} from './AccessInformation';
import {PolarCoordinate3} from '../_private/common/math/PolarCoordinate3';
import {GaIAEventEmitter} from './value/interface/GaIAEventEmitter';
import {
  CongestionLevel,
  GaIAEvent,
  GaIAEventListenerOptions,
  GaIAEventMap,
  Language,
  MapInitSettings,
  AnnotationClickListener,
  TileType,
  AnnotationClickListenerOptions,
  UserLocationTrackingMode,
  MapIconClickListener,
  MapIconClickListenerOptions,
  MapIconMouseEnterListener,
  AnnotationFontFamilyMap,
  LoadingProgressListener,
  TrainRouteClickListener,
  RoadShapeOpenedClickListener,
  ExternalAnnotationClickListener,
} from './types';
import {
  Color,
  CongestionInfo,
  ContextMenu,
  GeoJsonFigureCondition,
  LatLng,
  Point,
  RainfallCondition,
  ThunderCondition,
  SnowfallCondition,
  PollenCondition,
  CongestionCondition,
  Size,
  TrafficCondition,
  TyphoonCondition,
  ZoomRange,
  OrbitCondition,
  ExternalAnnotationCondition,
} from './value';
import {Circle, GLMarker, InfoWindow, Marker, Polygon, Polyline} from './object';
import {LatLngRect} from './object/LatLngRect';
import {IndoorCondition} from './value/IndoorCondition';
import {LandmarkCondition} from './value/LandmarkCondition';
import {UserLocation} from './object/UserLocation';
import {UserLocationData} from './value/UserLocationData';
import {MapIconCondition} from './value/MapIconCondition';
import {HeatMapCondition} from './value/HeatMapCondition';
import {CenterMarkerCondition} from './value/CenterMarkerCondition';
import {TrainRouteCondition} from './value/TrainRouteCondition';
import {RoadShapeOpenedCondition} from './value/RoadShapeOpenedCondition';
import {AdditionTileCondition} from './value/AdditionTileCondition';
import {RainfallGradationCondition} from './value/RainfallGradationCondition';
import {AnimationOption} from './value/animation';
import {AltitudeCondition} from './value/AltitudeCondition';

/**
 * 地図クラス
 */
class Map implements GaIAEventEmitter {
  private readonly mapController: MapCoreController;

  /**
   * コンストラクタ
   * @deprecated サービスID指定のI/Fを利用してください
   * @param access サーバー通信情報設定クラス
   * @param settings 設定
   */
  constructor(access: AccessInformation, settings: MapInitSettings);

  /**
   * コンストラクタ
   * @param id プロダクトID(サービスID)
   * @param settings 設定
   */
  constructor(id: string, settings: MapInitSettings);

  /** @override */
  constructor(arg1: string | AccessInformation, settings: MapInitSettings) {
    const {target} = settings;

    injectStyle();

    const baseElement = this.initBaseElement(target);

    let context: GaiaContext;
    if (typeof arg1 === 'string') {
      context = new GaiaContext({
        base: baseElement,
        productId: arg1,
        settings: settings,
      });
      this.mapController = new MapCoreController(context);
      this.mapController.mapUpdate();
    } else if (isAccessInformation(arg1)) {
      const access = arg1;
      context = new GaiaContext(
        {
          base: baseElement,
          productId: access.getInformation().productId,
          settings: settings,
        },
        access
      );
      this.mapController = new MapCoreController(context);
      if (access.getInformation().name) {
        this.mapController.setPaletteName(access.getInformation().name);
      }
      this.mapController.mapUpdate();
    } else {
      throw new Error('Arguments are invalid.');
    }

    // 地図の初期化に失敗した場合
    context.getGaIAConfiguration().catch(() => {
      this.destroy();
    });
  }

  /**
   * 地図表示の基底DOM要素の初期化
   * @param target 対象DOM要素/DOM要素を指定するセレクタ
   * @returns 基底DOM
   */
  private initBaseElement(target: string | HTMLElement): HTMLElement {
    let element;
    if (typeof target === 'string') {
      const el = document.querySelector(target as string);
      if (!el) {
        throw new GaIAError(`Element "${target}" was not found.`);
      }
      if (!(el instanceof HTMLElement)) {
        throw new GaIAError(`Element "${target}" was not HTMLElement.`);
      }
      element = el;
    } else {
      element = target;
    }

    element.classList.add(GaIAClassName.Base);

    return element;
  }

  /**
   * 中心緯度経度を取得
   * @returns 中心緯度経度
   */
  getCenter(): LatLng {
    return this.mapController.getCenterLocation();
  }

  /**
   * 中心緯度経度を設定
   * @param centerLocation 中心緯度経度
   * @param isAnimation アニメーション有効フラグ(デフォルト: false)
   * @returns {void}
   */
  setCenter(centerLocation: LatLng, isAnimation: AnimationOption | boolean = false): void {
    this.mapController.setCenterLocation(centerLocation, isAnimation);
  }

  /**
   * 指定した画面座標に位置する地点の緯度経度を算出
   * @deprecated `getLatLngFromClientCoord` を使用してください
   * @param point 画面座標(地図領域の左上を起点)
   * @returns 緯度経度
   */
  getLatLngFromPixel(point: Point): LatLng {
    return this.mapController.getLatLngFromPixel(point);
  }

  /**
   * 指定した画面座標に位置する地点の緯度経度を算出
   * @param clientCoord 画面座標(地図領域の左上を起点)
   * @returns 緯度経度(緯度経度が算出できない場合はundefined)
   */
  getLatLngFromClientCoord(clientCoord: Point): LatLng | undefined {
    const latlng = this.mapController.getLatLngFromClientCoord(clientCoord);
    if (latlng) {
      return latlng;
    }
  }

  /**
   * 現在のズームレベルを取得
   * @returns ズームレベル
   */
  getZoomLevel(): number {
    return this.mapController.getZoomLevel();
  }

  /**
   * ズームレベルを設定
   * @param zoomLevel ズームレベル
   * @param isAnimation アニメーション有効フラグ(デフォルト: false)
   * @returns {void}
   */
  setZoomLevel(zoomLevel: number, isAnimation: AnimationOption | boolean = false): void {
    this.mapController.setZoomLevel(zoomLevel, isAnimation);
  }

  /**
   * ズームインを行う
   * @param isAnimation アニメーション有効フラグ
   * @returns {void}
   */
  zoomIn(isAnimation?: boolean): void {
    const currentZoomLevel = this.getZoomLevel();
    this.setZoomLevel(currentZoomLevel % 1 === 0 ? currentZoomLevel + 1 : Math.ceil(currentZoomLevel), isAnimation);
  }

  /**
   * ズームアウトを行う
   * @param isAnimation アニメーション有効フラグ
   * @returns {void}
   */
  zoomOut(isAnimation?: boolean): void {
    const currentZoomLevel = this.getZoomLevel();
    this.setZoomLevel(currentZoomLevel % 1 === 0 ? currentZoomLevel - 1 : Math.floor(currentZoomLevel), isAnimation);
  }

  /**
   * ズームレンジを取得
   * @returns ズームレンジ
   */
  getZoomRange(): ZoomRange {
    return this.mapController.getZoomRange();
  }

  /**
   * ズームレンジを設定
   * @param zoomRange ズームレンジ
   * @returns {void}
   */
  setZoomRange(zoomRange: ZoomRange): void {
    this.mapController.setZoomRange(zoomRange);
  }

  /**
   * 最大ズームレベルかを判定
   * @returns true:最大ズームレベル
   */
  isMaxZoomLevel(): boolean {
    if (this.mapController.getZoomLevel() >= this.mapController.getZoomRange().max) {
      return true;
    }

    return false;
  }

  /**
   * 最小ズームレベルかを判定
   * @returns true:最小ズームレベル
   */
  isMinZoomLevel(): boolean {
    if (this.mapController.getZoomLevel() <= this.mapController.getZoomRange().min) {
      return true;
    }

    return false;
  }

  /**
   * 緯度経度とズームレベルを指定して移動
   * @param latLng 緯度経度
   * @param zoomLevel ズームレベル
   * @param isAnimation アニメーション有効フラグ(デフォルト: false)
   * @returns {void}
   */
  moveTo(latLng: LatLng, zoomLevel: number, isAnimation = false): void {
    this.mapController.moveTo(latLng, zoomLevel, isAnimation);
  }

  /**
   * LatLngRectが画面内に収まる位置に移動<br>
   * ※最適なズームレベルがズームレンジ外になる場合は画面内に収めることができません。
   * @param rect LatLngRect
   * @param isAnimation アニメーション有効フラグ(デフォルト: false)
   * @param maxZoomLevel 最大ズームレベル
   * @returns {void}
   */
  moveBasedOnLatLngRect(rect: LatLngRect, isAnimation = false, maxZoomLevel?: number): void {
    this.mapController.moveBasedOnLatLngRect(rect, isAnimation, maxZoomLevel);
  }

  /**
   * 極座標を取得
   * @returns 極座標
   */
  getPolar(): PolarCoordinate3 {
    return this.mapController.getPolar();
  }

  /**
   * 極座標を設定
   * @param polar 極座標
   * @returns {void}
   */
  setPolar(polar: PolarCoordinate3): void {
    this.mapController.setPolar(polar);
  }

  /**
   * 地図の傾きを取得
   * @returns 地図の傾き（単位は度）
   */
  getTilt(): number {
    return this.mapController.getTilt();
  }

  /**
   * 地図の傾きを設定
   * @param tilt 地図の傾き（単位は度）
   * @returns {void}
   */
  setTilt(tilt: number): void {
    this.mapController.setTilt(tilt);
  }

  /**
   * 地図の傾きの最大角度を設定
   * @param maxTilt 地図の傾きの最大値（単位は度、60度以上には設定できません）
   * @returns {void}
   */
  setMaxTilt(maxTilt: number): void {
    this.mapController.setMaxTilt(maxTilt);
  }

  /**
   * 地図の回転角を取得
   * @returns 地図の回転角（単位は度）
   */
  getDirection(): number {
    return this.mapController.getDirection();
  }

  /**
   * 地図の回転角を設定
   * @param direction 地図の回転角（単位は度）
   * @returns {void}
   */
  setDirection(direction: number): void {
    this.mapController.setDirection(direction);
  }

  /**
   * クライアントの大きさを取得
   * @returns クライアントの大きさ
   */
  getClientSize(): Size {
    return this.mapController.getClientSize();
  }

  /**
   * 地図要素のサイズを変更する
   * @param size 地図要素のサイズ（ピクセル）
   * @returns {void}
   */
  setClientSize(size: Size): void {
    this.mapController.setClientSize(size);
  }

  /**
   * 縮尺の表示位置を調整
   * @param position 位置
   * @returns {void}
   */
  setScalePosition(position: Point): void {
    this.mapController.setScalePosition(position);
  }

  /**
   * コピーライトの表示位置を調整
   * @param position 位置
   * @returns {void}
   */
  setCopyrightPosition(position: Point): void {
    this.mapController.setCopyrightPosition(position);
  }

  /**
   * 地図の言語を設定
   * @param language 地図の言語
   * @returns {void}
   */
  setLanguage(language: Language): void {
    this.mapController.setLanguage(language);
  }

  /**
   * タイル種別を設定
   * @param tileType タイル種別
   * @returns {void}
   */
  setTileType(tileType: TileType): void {
    this.mapController.setTileType(tileType);
  }

  /**
   * カスタムパレット名を設定
   * @param name パレット名 (空の場合はデフォルトに戻ります)
   * @returns {void}
   */
  setPaletteName(name?: string): void {
    this.mapController.setPaletteName(name);
  }

  /**
   * 中心地点の基準位置ずらしを設定
   * @param offset offset量
   * @returns {void}
   */
  setCenterOffset(offset: Point): void {
    this.mapController.setCenterOffset(offset);
  }

  /**
   * 地図の回転操作が有効になっているかを取得
   * @returns 地図の回転操作が有効であれば `true` 無効であれば `false`
   */
  isMapRotationEnabled(): boolean {
    return this.mapController.isMapRotationEnabled();
  }

  /**
   * 地図の傾き操作が有効になっているかを取得
   * @returns 地図の傾き操作が有効であれば `true` 無効であれば `false`
   */
  isMapTiltEnabled(): boolean {
    return this.mapController.isMapTiltEnabled();
  }

  /**
   * 地図の回転操作の有効/無効を設定
   * 有効にすると、下記のいずれかの操作を行った際に地図が傾いたり回転するようになる
   * ・PC
   *   ・コントロールキーを押しながらドラッグアンドドロップ
   *   ・右クリックでドラッグアンドドロップ
   * ・モバイル
   *   ・2本指で上下にスワイプ、もしくは2本指で回転
   * @param flag 有効にする場合はtrue, 無効にする場合はfalse
   * @returns {void}
   */
  setMapRotationEnabled(flag: boolean): void {
    this.mapController.setMapRotationEnabled(flag);
  }

  /**
   * 地図の傾き操作の有効/無効を設定
   * 有効にすると、下記の操作を行った際に地図が傾くようになる
   * ・PC
   *   ・コントロールキーを押しながらドラッグアンドドロップ
   *   ・右クリックでドラッグアンドドロップ
   * ・モバイル
   *   ・2本指で上下にスワイプ
   * @param flag 有効にする場合はtrue, 無効にする場合はfalse
   * @returns {void}
   */
  setMapTiltEnabled(flag: boolean): void {
    this.mapController.setMapTiltEnabled(flag);
  }

  /**
   * マウスホイール操作による拡縮の有効/無効設定
   * @param enable 有効にする場合はtrue, 無効にする場合はfalse
   * @returns {void}
   */
  setWheelZoomEnable(enable: boolean): void {
    this.mapController.setWheelZoomEnable(enable);
  }

  /**
   * タイルの3Dビル表示設定を取得
   * @returns 3Dビルの表示フラグ
   */
  isTileBuilding3DEnabled(): boolean {
    return this.mapController.isTileBuilding3DEnabled();
  }

  /**
   * タイルの3Dビル表示設定
   * @param enable 表示フラグ
   * @returns {void}
   */
  setTileBuilding3DEnabled(enable: boolean): void {
    this.mapController.setTileBuilding3DEnabled(enable);
  }

  /** @override */
  addEventListener<K extends keyof GaIAEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GaIAEventMap[K]) => any,
    options?: GaIAEventListenerOptions
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.mapController.addEventListener(eventName, func as (ev: GaIAEvent) => any, options);
  }

  /** @override */
  removeEventListener<K extends keyof GaIAEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GaIAEventMap[K]) => any,
    options?: GaIAEventListenerOptions
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.mapController.removeEventListener(eventName, func as (ev: GaIAEvent) => any, options);
  }

  /**
   * 地図タイルの描画進捗更新リスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapTileLoadingProgressListener(listener: LoadingProgressListener): void {
    this.mapController.setMapTileLoadingProgressListener(listener);
  }

  /**
   * 地図タイルの描画進捗更新リスナーを削除
   * @returns {void}
   */
  removeMapTileLoadingProgressListener(): void {
    this.mapController.setMapTileLoadingProgressListener(undefined);
  }

  /**
   * 注記の描画進捗更新リスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setAnnotationLoadingProgressListener(listener: LoadingProgressListener): void {
    this.mapController.setAnnotationLoadingProgressListener(listener);
  }

  /**
   * 注記の描画進捗更新リスナーを削除
   * @returns {void}
   */
  removeAnnotationLoadingProgressListener(): void {
    this.mapController.setAnnotationLoadingProgressListener(undefined);
  }

  /**
   * 注記クリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setAnnotationClickListener(listener: AnnotationClickListener, options?: AnnotationClickListenerOptions): void {
    this.mapController.setAnnotationClickListener(listener, options);
  }

  /**
   * 注記クリックリスナーの削除
   * @returns {void}
   */
  removeAnnotationClickListener(): void {
    this.mapController.removeAnnotationClickListener();
  }

  /**
   * 注記のフォント情報を設定
   * @param fontFamilyMap フォント情報
   * @returns {void}
   */
  setAnnotationFontFamilyMap(fontFamilyMap: AnnotationFontFamilyMap): void {
    this.mapController.setAnnotationFontFamilyMap(fontFamilyMap);
  }

  /**
   * 地図アイコンクリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setMapIconClickListener(listener: MapIconClickListener, options?: MapIconClickListenerOptions): void {
    this.mapController.setMapIconClickListener(listener, options);
  }

  /**
   * 地図アイコンクリックリスナーの削除
   * @returns {void}
   */
  removeMapIconClickListener(): void {
    this.mapController.removeMapIconClickListener();
  }

  /**
   * 地図アイコンマウスエンターリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setMapIconMouseEnterListener(listener: MapIconMouseEnterListener): void {
    this.mapController.setMapIconMouseEnterListener(listener);
  }

  /**
   * 地図アイコンマウスエンターリスナーの削除
   * @returns {void}
   */
  removeMapIconMouseEnterListener(): void {
    this.mapController.removeMapIconMouseEnterListener();
  }

  /**
   * InfoWindowを追加
   * @param infoWindow {@link InfoWindow}オブジェクト
   * @returns {void}
   */
  addInfoWindow(infoWindow: InfoWindow): void {
    this.mapController.addInfoWindow(infoWindow);
  }

  /**
   * InfoWindowを削除
   * @param infoWindow {@link InfoWindow}オブジェクト
   * @returns {void}
   */
  removeInfoWindow(infoWindow: InfoWindow): void {
    this.mapController.removeInfoWindow(infoWindow);
  }

  /**
   * Markerを追加
   * @param marker {@link Marker}オブジェクト
   * @returns {void}
   */
  addMarker(marker: Marker): void {
    this.mapController.addMarker(marker);
  }

  /**
   * Markerを削除
   * @param marker {@link Marker}オブジェクト
   * @returns {void}
   */
  removeMarker(marker: Marker): void {
    this.mapController.removeMarker(marker);
  }

  /**
   * GLMarkerの追加
   * @param marker GLMarker
   * @returns {void}
   */
  addGLMarker(marker: GLMarker): void {
    this.mapController.addGLMarkers([marker]);
  }

  /**
   * 複数のGLMarkerをまとめて追加 <br>
   * ※ 複数マーカー追加時はパフォーマンスの観点からこちらのメソッドを推奨
   *
   * @param markers GLMarkerの配列
   * @returns {void}
   */
  addGLMarkers(markers: GLMarker[]): void {
    this.mapController.addGLMarkers(markers);
  }

  /**
   * GLMarkerの削除
   * @param marker 削除対象のGLMarker
   * @returns {void}
   */
  removeGLMarker(marker: GLMarker): void {
    this.mapController.removeGLMarkers([marker]);
  }

  /**
   * 複数のGLMarkerをまとめて削除 <br>
   * ※ 複数マーカー削除時はパフォーマンスの観点からこちらのメソッドを推奨
   *
   * @param markers 削除するGLMarkerの配列
   * @returns {void}
   */
  removeGLMarkers(markers: GLMarker[]): void {
    this.mapController.removeGLMarkers(markers);
  }

  /**
   * 地図上のロングタップと見なす時間（ミリ秒）を設定
   * @param milliseconds ミリ秒
   * @returns {void}
   */
  setMapLongTapMilliseconds(milliseconds: number): void {
    this.mapController.setMapLongTapMilliseconds(milliseconds);
  }

  /**
   * 自位置マーカーを設定
   * @param userLocation UserLocation
   * @returns {void}
   */
  setUserLocation(userLocation: UserLocation): void {
    this.mapController.setUserLocation(userLocation);
  }

  /**
   * 自位置情報を設定
   * @param userLocationData UserLocationData
   * @param isAnimation 移動時のアニメーション有無
   * @returns {void}
   */
  setUserLocationData(userLocationData: UserLocationData, isAnimation = false): void {
    this.mapController.setUserLocationData(userLocationData, isAnimation);
  }

  /**
   * 自位置情報の取得
   * @returns 自位置情報
   */
  getUserLocationData(): UserLocationData {
    return this.mapController.getUserLocationData();
  }

  /**
   * トラッキングモードを設定
   * @param mode トラッキングモード
   * @returns {void}
   */
  setTrackingMode(mode: UserLocationTrackingMode): void {
    this.mapController.setTrackingMode(mode);
  }

  /**
   * トラッキングモードの取得
   * @returns トラッキングモード
   */
  getTrackingMode(): UserLocationTrackingMode {
    return this.mapController.getTrackingMode();
  }

  /**
   * 自位置情報の更新間隔を設定
   * @param interval 更新間隔(秒)
   * @returns {void}
   */
  setUserLocationDataInterval(interval: number): void {
    this.mapController.setUserLocationDataInterval(interval);
  }

  /**
   * ポリゴンを追加
   * @param polygon ポリゴン
   * @returns {void}
   */
  addPolygon(polygon: Polygon): void {
    this.mapController.addPolygon(polygon);
  }

  /**
   * ポリゴンを削除
   * @param polygon ポリゴン
   * @returns {void}
   */
  removePolygon(polygon: Polygon): void {
    this.mapController.removePolygon(polygon);
  }

  /**
   * ポリラインを追加
   * @param polyline ポリライン
   * @returns {void}
   */
  addPolyline(polyline: Polyline): void {
    this.mapController.addPolyline(polyline);
  }

  /**
   * ポリラインを削除
   * @param polyline ポリライン
   * @returns {void}
   */
  removePolyline(polyline: Polyline): void {
    this.mapController.removePolyline(polyline);
  }

  /**
   * 円を追加
   * @param circle 円
   * @returns {void}
   */
  addCircle(circle: Circle): void {
    this.mapController.addCircle(circle);
  }

  /**
   * 円を削除
   * @param circle 円
   * @returns {void}
   */
  removeCircle(circle: Circle): void {
    this.mapController.removeCircle(circle);
  }

  /**
   * GeoJSON形状追加
   * @param condition GeoJsonFigureCondition
   * @returns {void}
   */
  addGeoJsonFigure(condition: GeoJsonFigureCondition): void {
    this.mapController.addGeoJsonFigure(condition);
  }

  /**
   * GeoJSON形状削除
   * @param condition 削除対象のGeoJsonFigureCondition
   * @returns {void}
   */
  removeGeoJsonFigure(condition: GeoJsonFigureCondition): void {
    this.mapController.removeGeoJsonFigure(condition);
  }

  /**
   * ヒートマップを追加
   * @param condition HeatMapCondition
   * @returns {void}
   */
  addHeatMap(condition: HeatMapCondition): void {
    this.mapController.addHeatMap(condition);
  }

  /**
   * ヒートマップを削除
   * @param condition HeatMapCondition
   * @returns {void}
   */
  removeHeatMap(condition: HeatMapCondition): void {
    this.mapController.removeHeatMap(condition);
  }

  /**
   * 混雑度情報を設定
   * @deprecated
   * @param congestionInfo 混雑度情報
   * @param colorTable 配色(key: 混雑レベル, value: 色) ※混雑レベル: 1〜9・指定がないレベルはデフォルト色を使用
   * @returns {void}
   */
  setCongestionInfo(congestionInfo: CongestionInfo, colorTable?: {[key in CongestionLevel]: Color}): void {
    this.mapController.setCongestionInfo(congestionInfo, colorTable);
  }

  /**
   * 混雑度情報をクリア
   * @deprecated
   * @returns {void}
   */
  clearCongestionInfo(): void {
    this.mapController.clearCongestionInfo();
  }

  /**
   * 航空衛星写真の注記表示状態を設定(航空衛星写真表示時のみ表示可)
   * @param isVisible 表示状態
   * @returns {void}
   */
  setSatelliteAnnotationVisible(isVisible: boolean): void {
    this.mapController.setSatelliteAnnotationVisible(isVisible);
  }

  /**
   * 航空衛星写真の注記表示状態を取得
   * @returns 表示状態
   */
  isSatelliteAnnotationVisible(): boolean {
    return this.mapController.isSatelliteAnnotationVisible();
  }

  /**
   * 渋滞情報Conditionの設定
   * @param condition TrafficCondition
   * @returns {void}
   */
  setTrafficCondition(condition: TrafficCondition): void {
    this.mapController.setTrafficCondition(condition);
  }

  /**
   * 渋滞情報のクリア
   * @returns {void}
   */
  clearTrafficCondition(): void {
    this.mapController.clearTrafficCondition();
  }

  /**
   * 降雨・降雪情報Conditionの設定
   * @param condition RainfallCondition
   * @returns {void}
   */
  setRainfallCondition(condition: RainfallCondition): void {
    this.mapController.setRainfallCondition(condition);
  }

  /**
   * 降雨・降雪情報のクリア
   * @returns {void}
   */
  clearRainfallCondition(): void {
    this.mapController.clearRainfallCondition();
  }

  /**
   * 降雨・降雪情報グラデーションConditionの設定
   * @param condition RainfallGradationCondition
   * @returns {void}
   */
  setRainfallGradationCondition(condition: RainfallGradationCondition): void {
    this.mapController.setRainfallGradationCondition(condition);
  }

  /**
   * 降雨・降雪情報グラデーションConditionのクリア
   * @returns {void}
   */
  clearRainfallGradationCondition(): void {
    this.mapController.clearRainfallGradationCondition();
  }

  /**
   * 雷ナウキャスト情報Conditionの設定
   * @param condition ThunderCondition
   * @returns {void}
   */
  setThunderCondition(condition: ThunderCondition): void {
    this.mapController.setThunderCondition(condition);
  }

  /**
   * 雷ナウキャスト情報のクリア
   * @returns {void}
   */
  clearThunderCondition(): void {
    this.mapController.clearThunderCondition();
  }

  /**
   * 積雪深情報Conditionの設定
   * @param condition SnowfallCondition
   * @returns {void}
   */
  setSnowfallCondition(condition: SnowfallCondition): void {
    this.mapController.setSnowfallCondition(condition);
  }

  /**
   * 積雪深情報のクリア
   * @returns {void}
   */
  clearSnowfallCondition(): void {
    this.mapController.clearSnowfallCondition();
  }

  /**
   * 花粉情報Conditionの設定
   * @param condition PollenCondition
   * @returns {void}
   */
  setPollenCondition(condition: PollenCondition): void {
    this.mapController.setPollenCondition(condition);
  }

  /**
   * 花粉情報のクリア
   * @returns {void}
   */
  clearPollenCondition(): void {
    this.mapController.clearPollenCondition();
  }

  /**
   * 混雑度情報Conditionの設定
   * @param condition CongestionCondition
   * @returns {void}
   */
  setCongestionCondition(condition: CongestionCondition): void {
    this.mapController.setCongestionCondition(condition);
  }

  /**
   * 混雑度情報のクリア
   * @returns {void}
   */
  clearCongestionCondition(): void {
    this.mapController.clearCongestionCondition();
  }

  /**
   * 台風情報Conditionの設定
   * @param condition TyphoonCondition
   * @returns {void}
   */
  setTyphoonCondition(condition: TyphoonCondition): void {
    this.mapController.setTyphoonCondition(condition);
  }

  /**
   * 台風情報のクリア
   * @returns {void}
   */
  clearTyphoonCondition(): void {
    this.mapController.clearTyphoonCondition();
  }

  /**
   * フロアリストが変化したときに実行されるコールバック関数を設定
   * @param condition コールバック関数
   * @returns {void}
   */
  setIndoorCondition(condition: IndoorCondition): void {
    this.mapController.setIndoorCondition(condition);
  }

  /**
   * フロアリストが変化したときに実行されるコールバック関数をクリア
   * @returns {void}
   */
  clearIndoorCondition(): void {
    this.mapController.clearIndoorCondition();
  }

  /**
   * 屋内地図のフロアを設定
   * @param floor フロア
   * @returns {void}
   */
  setIndoorFloor(floor: string): void {
    this.mapController.setIndoorFloor(floor);
  }

  /**
   * 表示するエリアIDのリストを設定する
   * @param visibleAreaIdList 表示するエリアIDのリスト
   * @returns {void}
   */
  setIndoorVisibleAreaIdList(visibleAreaIdList: number[]): void {
    this.mapController.setIndoorVisibleAreaIdList(visibleAreaIdList);
  }

  /**
   * 表示するエリアIDのリストをクリアする（すべてのエリアを表示するようにする）
   * @returns {void}
   */
  clearIndoorVisibleAreaIdList(): void {
    this.mapController.clearIndoorVisibleAreaIdList();
  }

  /**
   * 任意タイル地図のオプションを設定
   * @param key 設定対象のキー名
   * @param condition 表示設定
   * @returns {void}
   */
  setAdditionTileCondition(key: string, condition: AdditionTileCondition): void {
    this.mapController.setAdditionTileCondition(key, condition);
  }

  /**
   * 任意タイル地図のオプションをクリア
   * @param key クリア対象のキー名
   * @returns {void}
   */
  clearAdditionTileCondition(key: string): void {
    this.mapController.clearAdditionTileCondition(key);
  }

  /**
   * 任意タイル地図のオプション登録済みキー名リストを取得
   * @returns 登録済みキー名リスト
   */
  getAdditionTileKeyNameList(): string[] {
    return this.mapController.getAdditionTileKeyNameList();
  }

  /**
   * 指定したキーが任意タイル地図のオプション登録済みかどうか
   * @param key キー名
   * @returns 登録済みか
   */
  hasAdditionTileKeyName(key: string): boolean {
    return this.mapController.hasAdditionTileKeyName(key);
  }

  /**
   * 3Dランドマークのオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setLandmarkCondition(condition: LandmarkCondition): void {
    this.mapController.setLandmarkCondition(condition);
  }

  /**
   * 3Dランドマークのオプションをクリア
   * @returns {void}
   */
  clearLandmarkCondition(): void {
    this.mapController.clearLandmarkCondition();
  }

  /**
   * 地図アイコン取得条件の設定
   * @param condition 条件
   * @returns {void}
   */
  setMapIconCondition(condition: MapIconCondition): void {
    this.mapController.setMapIconCondition(condition);
  }

  /**
   * 地図アイコン取得条件のクリア
   * @returns {void}
   */
  clearMapIconCondition(): void {
    this.mapController.clearMapIconCondition();
  }

  /**
   * 社外由来注記のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setExternalAnnotationCondition(condition: ExternalAnnotationCondition): void {
    this.mapController.setExternalAnnotationCondition(condition);
  }

  /**
   * 社外由来注記のオプションをクリア
   * @returns {void}
   */
  clearExternalAnnotationCondition(): void {
    this.mapController.clearExternalAnnotationCondition();
  }

  /**
   * 社外由来注記クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setExternalAnnotationClickListener(listener: ExternalAnnotationClickListener): void {
    this.mapController.setExternalAnnotationClickListener(listener);
  }

  /**
   * コンテキストメニューを設定
   * @param menu コンテキストメニュー
   * @returns {void}
   */
  setContextMenu(menu: ContextMenu): void {
    this.mapController.setContextMenu(menu);
  }

  /**
   * 軌跡(moon)のオプションを設定
   * @deprecated setRoadShapeOpenedCondition()を利用してください
   * @param condition 表示設定
   * @returns {void}
   */
  setOrbitCondition(condition: OrbitCondition): void {
    this.mapController.setOrbitCondition(condition);
  }

  /**
   * 軌跡(moon)のオプションをクリア
   * @deprecated clearRoadShapeOpenedCondition()を利用してください
   * @returns {void}
   */
  clearOrbitCondition(): void {
    this.mapController.clearOrbitCondition();
  }

  /**
   * 十字マーカーのオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setCenterMarkerCondition(condition: CenterMarkerCondition): void {
    this.mapController.setCenterMarkerCondition(condition);
  }

  /**
   * 十字マーカーのオプションをクリア
   * @returns {void}
   */
  clearCenterMarkerCondition(): void {
    this.mapController.clearCenterMarkerCondition();
  }

  /**
   * 鉄道路線図のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setTrainRouteCondition(condition: TrainRouteCondition): void {
    this.mapController.setTrainRouteCondition(condition);
  }

  /**
   * 鉄道路線図のオプションをクリア
   * @returns {void}
   */
  clearTrainRouteCondition(): void {
    this.mapController.clearTrainRouteCondition();
  }

  /**
   * 鉄道路線図クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setTrainRouteClickListener(listener: TrainRouteClickListener): void {
    this.mapController.setTrainRouteClickListener(listener);
  }

  /**
   * 新規開通道路のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setRoadShapeOpenedCondition(condition: RoadShapeOpenedCondition): void {
    this.mapController.setRoadShapeOpenedCondition(condition);
  }

  /**
   * 新規開通道路のオプションをクリア
   * @returns {void}
   */
  clearRoadShapeOpenedCondition(): void {
    this.mapController.clearRoadShapeOpenedCondition();
  }

  /**
   * 新規開通道路クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setRoadShapeOpenedClickListener(listener: RoadShapeOpenedClickListener): void {
    this.mapController.setRoadShapeOpenedClickListener(listener);
  }

  /**
   * 標高表示条件の設定
   * @param condition 条件
   * @returns {void}
   */
  setAltitudeCondition(condition: AltitudeCondition): void {
    this.mapController.setAltitudeCondition(condition);
  }

  /**
   * 標高表示条件のクリア
   * @returns {void}
   */
  clearAltitudeCondition(): void {
    this.mapController.clearAltitudeCondition();
  }

  /**
   * コンテキストメニューを削除
   * @returns {void}
   */
  clearContextMenu(): void {
    this.mapController.clearContextMenu();
  }

  /**
   * 地図インスタンスが作成されてからの描画回数を取得
   * @returns 描画回数
   */
  getDrawCount(): number {
    return this.mapController.getDrawCount();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.mapController.destroy();
  }
}

export {Map};
