/* eslint-disable @typescript-eslint/camelcase */
import {ExternalAnnotationFeature, ExternalAnnotationPluginMap} from '../../../gaia/types';
import {Optional} from '../../common/types';
import {LoaderState} from './AbstractTileLoader';
import {GaiaContext} from '../GaiaContext';

/**
 * 社外由来注記ローダー
 */
class ExternalAnnotationLoader {
  private state: LoaderState = 'initialized';

  private pluginMap?: ExternalAnnotationPluginMap | undefined;

  private drawingFeatures: ExternalAnnotationFeature[];
  private erasingFeatures: ExternalAnnotationFeature[];

  /**
   * コンストラクタ
   * @param context GaiaContext
   */
  constructor(context: GaiaContext) {
    context
      .getGaIAConfiguration()
      .then((res) => {
        if (!res.server.data) {
          return;
        }
        this.setState('ready');
        this.executeRequest();
      })
      .catch(() => {
        // do nothing
      });

    this.drawingFeatures = [];
    this.erasingFeatures = [];
  }

  /**
   * 追加対象のFeatureを1つ取得
   * @returns {ExternalAnnotationFeature | undefined}
   */
  public popDrawingStack(): ExternalAnnotationFeature | undefined {
    return this.drawingFeatures.pop();
  }

  /**
   * 削除対象のFeatureを1つ取得
   * @returns {ExternalAnnotationFeature | undefined}
   */
  public popErasingStack(): ExternalAnnotationFeature | undefined {
    return this.erasingFeatures.pop();
  }

  /**
   * renderkit層で初期化したpluginの連想配列を渡す
   * @param pluginMap pluginMap
   * @returns {void}
   */
  public setPluginMap(pluginMap: ExternalAnnotationPluginMap): void {
    this.pluginMap = pluginMap;
  }

  /**
   * Loaderの状態を判定
   * @param state LoaderState
   * @returns 指定された状態のときにtrue
   */
  private isState(state: LoaderState): boolean {
    return this.state === state;
  }

  /**
   * Loaderの状態を設定
   * @param state LoaderState
   * @returns {void}
   */
  private setState(state: LoaderState): void {
    // TODO: 状態遷移を制御するかどうか
    this.state = state;
  }

  /**
   * プラグインとキャッシュを全てクリア
   * @returns {void}
   */
  clear(): void {
    this.pluginMap = undefined;
    this.drawingFeatures = [];
    this.erasingFeatures = [];
  }

  /**
   * リクエストを実行
   * @returns {void}
   */
  executeRequest(): void {
    if (!this.isState('ready')) {
      return;
    }
    if (!this.pluginMap) {
      return;
    }

    const mopra = this.pluginMap['mopra'];

    if (mopra) {
      mopra.checkMoving();
      mopra.checkZooming();

      // eslint-disable-next-line
      while (true) {
        const item = mopra.popDrawingStack();
        if (!item) {
          break;
        }
        this.drawingFeatures.push(item);
      }
      // eslint-disable-next-line
      while (true) {
        const item = mopra.popErasingStack();
        if (!item) {
          break;
        }
        this.erasingFeatures.push(item);
      }
    }
  }

  /**
   * リクエストを実行
   * @param feature アイコンに紐付いたPOI情報
   * @returns {Optional}
   */
  public getMopraClickHandler(feature: ExternalAnnotationFeature): Optional<(event: unknown) => void> {
    if (!this.pluginMap) {
      return;
    }

    const mopra = this.pluginMap['mopra'];
    if (mopra) {
      return mopra.getClickHandler(feature);
    }
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.setState('destroyed');
  }
}

export {ExternalAnnotationLoader};
