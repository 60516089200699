import {ValueObject} from './interface/ValueObject';
import {JsonObject} from '../types';

/**
 * サイズ情報保持クラス
 */
class Size implements ValueObject {
  /** 高さ */
  readonly height: number;
  /** 幅 */
  readonly width: number;

  /**
   * コンストラクタ
   * @param height 高さ
   * @param width 幅
   */
  constructor(height: number, width: number) {
    this.height = height;
    this.width = width;
  }

  /** @override */
  clone(): Size {
    return new Size(this.height, this.width);
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof Size)) {
      return false;
    }
    const other = obj as Size;
    return other.height === this.height && other.width === this.width;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      height: this.height,
      width: this.width,
    };
  }
}

export {Size};
