import {ContextMenuItem, ContextMenuOptions} from '../types';

/**
 * コンテキストメニュー設定
 */
class ContextMenu {
  /** @ignore */
  readonly items: ContextMenuItem[];
  /** @ignore */
  readonly options?: ContextMenuOptions;

  /**
   * コンストラクタ
   * @param items メニュー項目
   * @param options オプション
   */
  constructor(items: ContextMenuItem[], options?: ContextMenuOptions) {
    this.items = items;
    this.options = options;
  }
}

export {ContextMenu};
