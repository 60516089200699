import {Optional} from '../../common/types';
import {TileImage} from '../../common/infra/response/TileImage';
import {TileNumber} from '../models/TileNumber';
import {Queue} from '../../common/collection/Queue';
import {AbstractTileRequester, TileRequestUrlBuilderFunc} from '../../common/infra/request/AbstractTileRequester';
import {HTMLImageElementTileRequester} from '../../common/infra/request/HTMLImageElementTileRequester';
import {LRUCache} from '../../common/collection/LRUCache';
import {GaiaContext} from '../GaiaContext';

type OnFinishRequestFunc = () => void;
export type LoaderState = 'initialized' | 'ready' | 'destroyed';

// TODO: エラー時の無限リクエスト対策を入れる(AdditionTileLoader参照)
/**
 * タイル読み込みローダーの抽象クラス
 */
abstract class AbstractTileLoader<T extends TileNumber> {
  private readonly cache: LRUCache<T, TileImage>;

  private readonly requestQueue: Queue<T>;
  private readonly requestingTile: Set<string>;

  private requestFailureCounter: Map<string, number>;
  private lastRequestTimeMap: Map<string, number>;

  protected readonly urlBuilder: TileRequestUrlBuilderFunc<T>;
  private readonly onFinishRequest: OnFinishRequestFunc;

  private readonly requester: AbstractTileRequester<T>;

  private state: LoaderState = 'initialized';

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param builder タイルリクエスト生成関数
   * @param onFinishRequest タイルリクエスト完了通知
   */
  constructor(
    context: GaiaContext,
    builder: TileRequestUrlBuilderFunc<T>,
    onFinishRequest: OnFinishRequestFunc = (): void => void 0
  ) {
    this.cache = new LRUCache<T, TileImage>(200, (_, v) => {
      if ('ImageBitmap' in window && v.image instanceof ImageBitmap) {
        (v.image as ImageBitmap).close();
      }
    });

    this.requestQueue = new Queue<T>(150);
    this.requestingTile = new Set<string>();

    this.requestFailureCounter = new Map();
    this.lastRequestTimeMap = new Map();

    this.urlBuilder = builder;
    this.onFinishRequest = onFinishRequest;

    this.requester = new HTMLImageElementTileRequester(this.urlBuilder);
  }

  /**
   * Loaderの状態を判定
   * @param state LoaderState
   * @returns 指定された状態のときにtrue
   */
  protected isState(state: LoaderState): boolean {
    return this.state === state;
  }

  /**
   * Loaderの状態を設定
   * @param state LoaderState
   * @returns {void}
   */
  protected setState(state: LoaderState): void {
    // TODO: 状態遷移を制御するかどうか
    this.state = state;
  }

  /**
   * キャッシュとリクエストをすべてクリアする
   * @returns {void}
   */
  clear(): void {
    this.cache.clear();
    this.requestQueue.clear();
    this.requestingTile.clear();
    this.requester.clear();
  }

  /**
   * リクエストキューをクリア
   * @returns {void}
   */
  clearRequestQueue(): void {
    this.requestQueue.clear();
    this.requestingTile.clear();
    this.requester.clear();
  }

  /**
   * キャッシュにあるTileImageを返す
   * @param tileNumber タイルパラメータ
   * @returns TileImage
   */
  getTile(tileNumber: T): Optional<TileImage> {
    return this.cache.get(tileNumber);
  }

  /**
   * 描画に必要なタイルをリクエストキューに追加
   * @param tileList リクエスト対象タイルリスト
   * @returns {void}
   */
  addRequestQueue(tileList: T[]): void {
    for (const tile of tileList) {
      if (this.cache.has(tile)) {
        continue;
      }
      this.requestQueue.enqueue(tile);
    }
  }

  /**
   * リクエスト中のタイル数を取得
   * @returns タイル数
   */
  getRequestingTileSize(): number {
    return this.requestingTile.size;
  }

  /**
   * キューに積まれているリクエストを実行する
   * @returns {void}
   */
  executeRequest(): void {
    if (!this.isState('ready')) {
      return;
    }
    if (this.requestQueue.size === 0) {
      return;
    }

    const requestTiles: T[] = [];
    while (this.requestQueue.size > 0) {
      const tile = this.requestQueue.dequeue();
      if (!tile) {
        break;
      }

      const cacheKey = tile.getCacheKey();
      if (this.cache.has(tile) || this.requestingTile.has(cacheKey)) {
        continue;
      }

      const lastRequestTime = this.lastRequestTimeMap.get(cacheKey);
      const failureCount = this.requestFailureCounter.get(cacheKey) ?? 0;
      const now = performance.now();
      if (lastRequestTime && now - lastRequestTime < failureCount * 1000) {
        continue;
      }

      requestTiles.push(tile);
      this.requestingTile.add(cacheKey);
      this.lastRequestTimeMap.set(cacheKey, performance.now());
    }

    this.requester.request(requestTiles, (tile, tex) => {
      if (this.isState('destroyed')) {
        // リクエスト中に地図が破棄されたらキャッシュしない
        return;
      }

      const cacheKey = tile.getCacheKey();

      if (!this.requestingTile.has(cacheKey)) {
        // リクエスト対象から外れたものはキャッシュしない
        return;
      }

      if (!tex) {
        // 通信がタイムアウトなどのエラーになった際は、リクエスト中のタイルから削除して
        // 次のリクエスト要求が来た際に要求が通るようにする
        this.requestingTile.delete(cacheKey);
        const failureCount = this.requestFailureCounter.get(cacheKey) ?? 0;
        this.requestFailureCounter.set(cacheKey, failureCount + 1);
        return;
      }

      this.cache.add(tile, new TileImage(tile, tex));
      this.requestingTile.delete(cacheKey);
      this.requestFailureCounter.delete(cacheKey);
      this.onFinishRequest();
    });
  }

  /**
   * キャッシュサイズ上限を拡張
   * @param size 変更後サイズ
   * @returns {void}
   */
  jumpUpCacheSize(size: number): void {
    this.cache.jumpUpSize(size);
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.cache.clear();
    this.requester.destroy();
    this.requestingTile.clear();
    this.requestQueue.clear();
    this.setState('destroyed');
  }
}

export {AbstractTileLoader, OnFinishRequestFunc};
