import {FigureObject} from './FigureObject';
import {ShapeCollider} from '../../../common/math/ShapeCollider';
import {PolylineTriangulator} from '../../../engine/polygon/PolylineTriangulator';
import {PolylineObject} from './PolylineObject';
import {Vector3} from '../../../common/math/Vector3';
import {DASH_ARRAY_SOLID} from '../../../../gaia/object/shape/Polyline';
import {Triangle3} from '../../../common/math/Triangle3';
import {Vector2} from '../../../common/math/Vector2';
import {PolygonObject} from './PolygonObject';
import {Ray3} from '../../../common/math/Ray3';
import {PolylineEventMap} from '../../../../gaia/types';
import {EventListenerFunctionReturnType} from '../../../common/types';
import {LatLng} from '../../../../gaia/value';

/** 当たり判定を実際のポリライン幅からどのくらい拡げるか */
const COLLIDER_WIDTH_BUFFER = 8;

type CollidableFigureEvent = {
  sourceObject: PolylineObject;
  position?: LatLng;
};
type CollidableFigureEventFunction = (ev: CollidableFigureEvent) => EventListenerFunctionReturnType;

/**
 * 図形オブジェクトを衝突可能にするためのクラス
 */
class CollidableFigureObject {
  readonly object: FigureObject;
  private collider: ShapeCollider;
  private isCollidable = false;

  readonly listenerMap: Map<keyof PolylineEventMap, CollidableFigureEventFunction[]>;

  /**
   * コンストラクタ
   * @param figureObject FigureObject
   */
  constructor(figureObject: FigureObject) {
    this.object = figureObject;
    this.collider = new ShapeCollider([]);

    this.listenerMap = new Map();
  }

  /**
   * 衝突可否の設定
   * @param enable 衝突可否
   * @returns {void}
   */
  setCollidable(enable: boolean): void {
    this.isCollidable = enable;
  }

  /**
   * コライダの更新
   * @param ptu ピクセルからGL空間上の長さに変換する係数
   * @returns {void}
   */
  updateCollider(ptu: number): void {
    if (!this.object.isVisible() || !this.isCollidable) {
      return;
    }

    if (this.object instanceof PolylineObject) {
      const collider = this.createColliderOfPolyline(this.object, ptu);
      this.collider = collider;
    } else if (this.object instanceof PolygonObject) {
      // TODO ポリゴンに対応する
    }
  }

  /**
   * ポリライン用コライダの生成
   * @param polylineObject PolylineObject
   * @param ptu ピクセルからGL空間上の長さに変換する係数
   * @returns ポリライン用コライダ
   */
  private createColliderOfPolyline(polylineObject: PolylineObject, ptu: number): ShapeCollider {
    const {path, thickness} = polylineObject;
    const vec2Path: Vector2[] = [];
    for (const vec3Position of path) {
      vec2Path.push(vec3Position._add(this.object.basePosition).toVector2());
    }

    const triangulator = new PolylineTriangulator((thickness + COLLIDER_WIDTH_BUFFER) * ptu, DASH_ARRAY_SOLID);
    triangulator.addStroke(vec2Path);
    const geometry = triangulator.triangulate();

    const triangles: Triangle3[] = [];
    const indices = geometry.getIndices();
    const vertices = geometry.getVertices();
    for (let index = 0; index < indices.length; index += 3) {
      const i1 = indices[index];
      const i2 = indices[index + 1];
      const i3 = indices[index + 2];
      const p1 = new Vector3(vertices[i1 * 3], vertices[i1 * 3 + 1], vertices[i1 * 3 + 2]);
      const p2 = new Vector3(vertices[i2 * 3], vertices[i2 * 3 + 1], vertices[i2 * 3 + 2]);
      const p3 = new Vector3(vertices[i3 * 3], vertices[i3 * 3 + 1], vertices[i3 * 3 + 2]);
      const triangle = new Triangle3(p1, p2._subtract(p1), p3._subtract(p1));
      triangles.push(triangle);
    }
    return new ShapeCollider(triangles);
  }

  /**
   * 衝突判定
   * @param ray Ray3
   * @returns rayが衝突したか
   */
  isCollided(ray: Ray3): boolean {
    if (!this.isCollidable) {
      return false;
    }
    return this.collider.isCollided(ray);
  }

  /**
   * イベントリスナーの追加
   * @param eventName イベント名
   * @param func リスナー関数
   * @returns {void}
   */
  addEventListener<K extends keyof PolylineEventMap>(eventName: K, func: CollidableFigureEventFunction): void {
    if (!this.listenerMap.has(eventName)) {
      this.listenerMap.set(eventName, []);
    }

    const listeners = this.listenerMap.get(eventName) ?? [];
    if (listeners.includes(func)) {
      return;
    } else {
      listeners.push(func);
    }
  }

  /**
   * イベントリスナーの削除
   * @param eventName イベント名
   * @param func リスナー関数
   * @returns {void}
   */
  removeEventListener<K extends keyof PolylineEventMap>(eventName: K, func: CollidableFigureEventFunction): void {
    const handlerList = this.listenerMap.get(eventName);
    if (!handlerList) {
      return;
    }
    const idx = handlerList.indexOf(func);
    if (idx >= 0) {
      handlerList.splice(idx, 1);
    }
  }

  /**
   * イベントハンドラの実行
   * @param eventName イベント名
   * @param event イベントオブジェクト
   * @returns {void}
   */
  eventTrigger<K extends keyof PolylineEventMap>(eventName: K, event: CollidableFigureEvent): void {
    const handlerList = this.listenerMap.get(eventName) ?? [];
    for (const func of handlerList) {
      func(event);
    }
  }
}

export {CollidableFigureObject, CollidableFigureEvent};
