import {GaiaContext} from '../GaiaContext';
import {AbstractTileLoader, OnFinishRequestFunc} from './AbstractTileLoader';
import {TrafficTileParameter} from './param/TrafficTileParameter';
import {QueryParameter} from '../../common/types';
import {TrafficCondition} from '../../../gaia/value';
import {buildQueryString} from '../../common/util/URL';

/**
 * 日付のクエリパラメータに変換
 * @param date Dateオブジェクト
 * @returns 日付文字列(yyyyMMddHHmmss)
 */
const toTimeParamString = (date: Date): string => {
  const year = `${date.getFullYear()}`;
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const hour = `0${date.getHours()}`.slice(-2);
  const minute = `0${date.getMinutes()}`.slice(-2);
  const second = `0${date.getSeconds()}`.slice(-2);

  return `${year}${month}${day}${hour}${minute}${second}`;
};

/**
 * 交通情報タイルローダー
 */
class TrafficTileLoader extends AbstractTileLoader<TrafficTileParameter> {
  private serverUrl = '';
  private productId = '';

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );

    context
      .getGaIAConfiguration()
      .then((res) => {
        this.serverUrl = `${res.server.tile}map/${res.productId}`;
        this.productId = res.productId;
        this.setState('ready');
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * タイルのURLを生成
   * @param tile TileNumber
   * @returns リクエストURL
   */
  private createUrl(tile: TrafficTileParameter): string {
    const {x, y, z, tileSize, palette, annotation} = tile;
    const baseQuery: QueryParameter = {
      product: 'web',
      lang: palette.language,
    };
    if (!annotation) {
      baseQuery.annotation = 'false';
    }
    if (palette.name) {
      baseQuery['palette-name'] = palette.name;
    }
    const trafficQuery = this.createQueryString(tile.condition);

    const urlBase = tile.condition.server ? `${tile.condition.server}map/${this.productId}` : this.serverUrl;
    return `${urlBase}/tile/traffic/${tileSize}/${z}/${y}/${x}?${buildQueryString(baseQuery)}&${trafficQuery}`;
  }

  /**
   * リクエストのクエリパラメータ生成
   * @param condition TrafficCondition
   * @returns クエリパラメータ文字列
   */
  private createQueryString(condition: TrafficCondition): string {
    const {trafficTime, source, showArrow} = condition;

    const query: QueryParameter = {};
    // 渋滞ソース
    switch (source) {
      case 'both':
        query.tfsrc = 'vics.probe';
        query.uid = condition.uid;
        break;
      case 'vics':
        query.tfsrc = 'vics';
        query.uid = condition.uid;
        break;
      case 'probe':
        query.tfsrc = 'probe';
        break;
      case 'none':
        query['use-traffic'] = 'false';
    }

    // 時刻
    query['traffic-time'] = toTimeParamString(trafficTime);

    // 矢印
    query.drawArrow = `${showArrow}`;

    // 大型車規制
    const roadRegulation = condition.roadRegulation;
    if (roadRegulation) {
      query.roadregulation = 'true';
      query['regulation-time'] = toTimeParamString(condition.regulationTime);
      query['car-type'] = `${roadRegulation.carType}`;

      query.height = `${roadRegulation.height}`;
      query.width = `${roadRegulation.width}`;
      query.length = `${roadRegulation.length}`;
      query.weight = `${roadRegulation.weight}`;
      query['max-load'] = `${roadRegulation.maxLoad}`;
      query['dangerous-goods'] = `${roadRegulation.dangerousGoods ?? false}`;
    }

    // 定義規制
    const definedRegulation = condition.definedRegulation;
    if (definedRegulation) {
      query.definedregulation = 'true';
      query['regulation-time'] = toTimeParamString(condition.regulationTime);

      query.drcategory = definedRegulation.category.join('.');
      query.tandem = `${definedRegulation.tandem ?? false}`;
      if (definedRegulation.displacement === 'all') {
        query.displacement = 'all';
      } else if (definedRegulation.displacement) {
        query.displacement = definedRegulation.displacement.map((d) => `${d}`).join('.');
      }
    }

    return buildQueryString(query);
  }
}

export {TrafficTileLoader};
