import {Optional} from '../types';
import {GaIAError} from '../../../gaia/value/GaIAError';
import {LinkedList} from './LinkedList';

/**
 * キューを実現するクラス
 */
class Queue<T> {
  private items: LinkedList<T> = new LinkedList();

  private readonly limit: number;

  /**
   * コンストラクタ
   * @param limit キューのサイズ上限
   */
  constructor(limit = 0) {
    if (limit < 0) {
      throw new GaIAError('limit must be larger than 0');
    }

    this.limit = limit;
  }

  /**
   * キューに積まれた要素数を取得
   * @returns 要素数
   */
  get size(): number {
    return this.items.size();
  }

  /**
   * キューに追加
   * @param items 要素
   * @returns {void}
   */
  enqueue(...items: T[]): void {
    for (const item of items) {
      this.items.insertLast(item);
    }
    if (this.limit > 0) {
      while (this.size > this.limit) {
        this.dequeue();
      }
    }
  }

  /**
   * キューから取り出し
   * @returns 取り出された要素
   */
  dequeue(): Optional<T> {
    if (this.items.size() === 0) {
      return undefined;
    }
    const item = this.items.deleteFirst();
    return item;
  }

  /**
   * キューのクリア
   * @returns {void}
   */
  clear(): void {
    this.items.clear();
  }
}

export {Queue};
