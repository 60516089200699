import {Layer} from '../../engine/layer/Layer';
import {mat4} from 'gl-matrix';
import {MapStatus} from '../models/MapStatus';
import {calculateWorldCoordinate, calculatePixelToUnit, worldToLatLng} from '../utils/MapUtil';
import {GaiaContext} from '../GaiaContext';
import {FigureObject} from '../render/objects/FigureObject';
import {Collision} from '../../engine/collision/Collision';
import {Ray3} from '../../common/math/Ray3';
import {CollidableFigureObject} from '../render/objects/CollidableFigureObejct';
import {MouseEventObserver} from '../event/MouseEventObserver';
import {PerspectiveCamera} from '../../engine/camera/PerspectiveCamera';
import {Camera} from '../../engine/camera/Camera';
import {TILE_LAYER_NAME_SHAPE} from '../render/kit/object/ShapeObjectRenderKit';
import {MapRenderKitController} from '../render/kit/MapRenderKitController';
import {PolylineObject} from '../render/objects/PolylineObject';
import {Object3D} from '../../engine/object/Object3D';
import {Optional, RenderTarget} from '../../common/types';
import {Vector2} from '../../common/math/Vector2';
import {Vector3} from '../../common/math/Vector3';
import {Point, LatLng} from '../../../gaia/value';
import {RectCollider} from '../../common/math/RectCollider';
import {viewPointToIntersectionForPerspectiveCamera} from '../render/MapTileScanner';
import {AltitudeProgram} from '../../engine/program/AltitudeProgram';

/**
 * 形状を扱うレイヤー
 */
class ShapeLayer implements Layer {
  private context: GaiaContext;
  private camera: Camera;

  private figures: CollidableFigureObject[];

  private previousZoomLevel: number;

  private lastDeletedFigure?: FigureObject;

  private mouse: MouseEventObserver;
  private getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;
  private hoveredObject?: CollidableFigureObject;

  private _isAltitudeMode: boolean;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param renderKitCtl MapRenderKitController
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    this.context = context;
    this.camera = camera;

    this.figures = [];

    this.previousZoomLevel = context.getMapStatus().zoomLevel;
    this._isAltitudeMode = false;

    this.mouse = new MouseEventObserver(context.getBaseElement());
    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKitCtl.getAllCollisions(ray);
    this.setupHover();
    this.setupClick();
  }

  /**
   * 標高モードの状態を設定
   * @param altitudeMode 標高モードの状態
   * @returns {void}
   */
  setAltitudeMode(altitudeMode: boolean): void {
    this._isAltitudeMode = altitudeMode;
  }

  /**
   * ホバー時の設定
   * @returns {void}
   */
  private setupHover(): void {
    this.mouse.addEventListener('mousemove', (ev: MouseEvent) => {
      if (this.figures.length === 0 || !this.isOnMap(ev)) {
        return;
      }

      const ray = this.clientPositionToRay(ev.offsetX, ev.offsetY);
      const allCollisions = this.getAllCollisions(ray);

      for (const [layerName, collisions] of allCollisions.entries()) {
        if (layerName === TILE_LAYER_NAME_SHAPE) {
          // 自分のレイヤーで当たり判定があるときは処理を行う
          let collidedFigure: CollidableFigureObject | undefined;
          if (collisions.length > 0) {
            const collision = collisions[0];
            const additional = collision.getAdditional();
            if (additional instanceof CollidableFigureObject) {
              collidedFigure = additional;
            }
          }

          if (collidedFigure) {
            if (collidedFigure.object instanceof PolylineObject) {
              collidedFigure.eventTrigger('mouseover', {
                sourceObject: collidedFigure.object,
                position: this.calculateClickedLatLng(ev) ?? undefined,
              });
            }

            this.hoveredObject = collidedFigure;
            this.context.getBaseElement().style.cursor = 'pointer';
          } else {
            this.hoveredObject = undefined;
            this.context.getBaseElement().style.cursor = 'auto';
          }

          break;
        }

        // 自分のレイヤーより前に当たり判定があるときは何もしない
        if (collisions.length > 0) {
          this.hoveredObject = undefined;
          return;
        }
      }
    });
  }

  /**
   * クリック時の設定
   * @returns {void}
   */
  private setupClick(): void {
    this.mouse.addEventListener('click', (ev) => {
      if (this.figures.length === 0 || !this.hoveredObject) {
        return;
      }

      if (this.hoveredObject.object instanceof PolylineObject) {
        this.hoveredObject.eventTrigger('click', {
          sourceObject: this.hoveredObject.object,
          position: this.calculateClickedLatLng(ev) ?? undefined,
        });
      }
    });
  }

  // TODO クライアント座標→Ray処理をUtilにうつす
  /**
   * クライアント座標をレイキャストに変換する
   * @param clientPositionX x座標
   * @param clientPositionY y座標
   * @returns レイキャスト
   */
  private clientPositionToRay(clientPositionX: number, clientPositionY: number): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const mapStatus = this.context.getMapStatus();

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const halfWidth = (mapStatus.clientWidth * ptu) / 2;
    const halfHeight = (mapStatus.clientHeight * ptu) / 2;
    const toTopVector = mapStatus.polar
      .toUpVector3()
      .normalize()
      .multiply(halfHeight * y);
    const toRightVector = mapStatus.polar
      .toRightVector3()
      .normalize()
      .multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);
    const start = calculateWorldCoordinate(mapStatus.centerLocation).add(this.camera.position);

    const direction = (this.camera as PerspectiveCamera).target._subtract(this.camera.position).add(toTopRightVector);

    const ray = new Ray3(start, direction);

    return ray;
  }

  /**
   * 地図に対するマウスイベントかを判定
   * @param ev MouseEvent
   * @returns 地図に対するマウスイベントか
   */
  private isOnMap(ev: MouseEvent): boolean {
    for (const value of ev.composedPath()) {
      const classList = (value as Element).classList;
      if (classList && classList.contains('gia-base-element')) {
        return true;
      }
    }
    return false;
  }

  /**
   * MouseEventからクリック緯度経度を算出
   * @param ev MouseEvent
   * @returns 緯度経度
   */
  private calculateClickedLatLng(ev: MouseEvent): Optional<LatLng> {
    const {top, left} = this.context.getBaseElement().getBoundingClientRect();
    const clientPixelPosition = new Vector2(ev.pageX - left - window.pageXOffset, ev.pageY - top - window.pageYOffset);

    const mapStatus = this.context.getMapStatus();
    const clickPosition = this.calculateClientToWorld(mapStatus, clientPixelPosition);
    if (!clickPosition) {
      return;
    }
    const latlng = worldToLatLng(clickPosition, mapStatus.zoomLevel);

    return latlng;
  }

  /**
   * クライアント座標をワールド座標に変換する
   * @param mapStatus MapStatus
   * @param clientPixelPosition ピクセルで表現されたクライアント座標
   * @param worldCenter 地図中心
   * @returns ワールド座標
   */
  private calculateClientToWorld(mapStatus: MapStatus, clientPixelPosition: Vector2): Optional<Vector3> {
    const {zoomLevel, polar, centerOffset} = mapStatus;

    const ptu = calculatePixelToUnit(zoomLevel);
    const upVector = polar.toUpVector3();
    const rightVector = polar.toRightVector3();

    const worldCenter = calculateWorldCoordinate(mapStatus.centerLocation);
    const cursorRay = this.clientPixelToRay(clientPixelPosition, worldCenter, upVector, rightVector, ptu);
    const mapSurface = this.createMapSurface(mapStatus, worldCenter, upVector, rightVector, ptu, centerOffset);

    return mapSurface.calculateIntersection(cursorRay);
  }

  /**
   * カメラからクライアント座標までのレイを作成
   * @param clientPixelPosition ピクセルで表現されたクライアント座標
   * @param worldCenter 地図中心
   * @param upVector 極座標上方向のベクトル
   * @param rightVector 極座標右方向のベクトル
   * @param ptu ピクセルからGL空間上の長さに変換する係数
   * @returns カメラからクライアント座標までのレイ
   */
  private clientPixelToRay(
    clientPixelPosition: Vector2,
    worldCenter: Vector3,
    upVector: Vector3,
    rightVector: Vector3,
    ptu: number
  ): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const clientPositionX = clientPixelPosition.x;
    const clientPositionY = clientPixelPosition.y;

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const halfWidth = (clientWidth * ptu) / 2;
    const halfHeight = (clientHeight * ptu) / 2;

    const toTopVector = upVector._normalize()._multiply(halfHeight * y);
    const toRightVector = rightVector._normalize()._multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);

    const start = worldCenter._add(this.camera.position);

    const cameraTarget = (this.camera as PerspectiveCamera).target;
    const direction = cameraTarget._subtract(this.camera.position)._add(toTopRightVector);

    return new Ray3(start, direction);
  }

  /**
   * 画面全体を覆うコライダを作成
   * @param mapStatus MapStatus
   * @param worldCenter 地図中心
   * @param upVector 極座標上方向のベクトル
   * @param rightVector 極座標右方向のベクトル
   * @param ptu ピクセルからGL空間上の長さに変換する係数
   * @param centerOffset 地図中心オフセット
   * @returns 画面全体を覆うコライダ
   */
  private createMapSurface(
    mapStatus: MapStatus,
    worldCenter: Vector3,
    upVector: Vector3,
    rightVector: Vector3,
    ptu: number,
    centerOffset: Point
  ): RectCollider {
    const outerX = (256 * Math.SQRT2 + Math.abs(centerOffset.x)) * ptu;
    const rotationBaseRadian = mapStatus.polar.phi + Math.PI / 2;

    const radianTopLeft = rotationBaseRadian + (Math.PI / 4) * 3;
    const topLeft: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(-1, 1),
      upVector,
      rightVector
    )
      ._add(worldCenter)
      ._add(new Vector3(outerX * Math.cos(radianTopLeft), outerX * Math.sin(radianTopLeft), 0));

    const radianTopRight = rotationBaseRadian + Math.PI / 4;
    const topRight: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(1, 1),
      upVector,
      rightVector
    )
      ._add(new Vector3(outerX * Math.cos(radianTopRight), outerX * Math.sin(radianTopRight), 0))
      ._add(worldCenter);

    const radianBottomLeft = rotationBaseRadian - (Math.PI / 4) * 3;
    const bottomLeft: Vector3 = viewPointToIntersectionForPerspectiveCamera(
      mapStatus,
      this.camera as PerspectiveCamera,
      new Vector2(-1, -1),
      upVector,
      rightVector
    )
      ._add(new Vector3(outerX * Math.cos(radianBottomLeft), outerX * Math.sin(radianBottomLeft), 0))
      ._add(worldCenter);

    return new RectCollider(topLeft, topRight, bottomLeft);
  }

  /** @override */
  getIdenticalLayerName(): string {
    return TILE_LAYER_NAME_SHAPE;
  }

  /**
   * 図形を追加する
   * @param figure 図形オブジェクト
   * @returns {void}
   */
  addFigure(figure: FigureObject): Optional<CollidableFigureObject> {
    const foundIndex = this.getCollidableFigureLastIndexOf(figure);
    if (foundIndex >= 0) {
      return;
    }

    let index = this.figures.length - 1;
    while (index >= 0 && figure.zIndex < this.figures[index].object.zIndex) {
      index--;
    }
    const collidableFigure = new CollidableFigureObject(figure);
    this.figures.splice(index + 1, 0, collidableFigure);

    return collidableFigure;
  }

  /**
   * 図形を削除する
   * @param figure 図形オブジェクト
   * @returns {void}
   */
  removeFigure(figure: FigureObject): void {
    const index = this.getCollidableFigureLastIndexOf(figure);
    if (index < 0) {
      return;
    }
    this.lastDeletedFigure = figure;
    this.figures.splice(index, 1);
  }

  /**
   * 該当するFigureObjectを持つCollidableFigureObjectのインデックスを取得
   * @param figure インデックスを取得する対象のFigureObject
   * @returns インデックス(該当するものがなければ-1)
   */
  private getCollidableFigureLastIndexOf(figure: FigureObject): number {
    for (let i = 0, length = this.figures.length; i < length; i++) {
      if (this.figures[i].object === figure) {
        return i;
      }
    }

    return -1;
  }

  /**
   * 地図更新
   * @param mapStatus 地図のステータス
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    for (const figure of this.figures) {
      figure.object.updateFigure(cameraTargetPosition, mapStatus);
      figure.updateCollider(ptu);
    }
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    for (const figure of this.figures) {
      this.updateDraw(figure.object, viewMatrix, projectionMatrix);
    }
    return true;
  }

  /**
   * 描画対象の状態を取得
   * @returns 描画対象の状態
   */
  private getRenderTarget(): RenderTarget {
    if (this._isAltitudeMode && AltitudeProgram.renderTarget) {
      return AltitudeProgram.renderTarget;
    }
    return MapStatus.defaultRenderTarget;
  }

  /**
   * オブジェクトの更新と描画を行う
   * @param figureObject 図形オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  private updateDraw(figureObject: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    const renderTarget = this.getRenderTarget();
    if (!figureObject.isVisible()) {
      return;
    }
    figureObject.update(viewMatrix, projectionMatrix);
    figureObject.draw(renderTarget);
  }

  /** @override */
  destroy(): void {
    for (const figureObject of this.figures) {
      figureObject.object.destroy();
    }
    this.figures = [];
    this.lastDeletedFigure?.destroy();
    this.lastDeletedFigure = undefined;
  }

  /** @override */
  getCollisions(ray: Ray3): Collision[] {
    const collisions: Collision[] = [];

    for (const figure of this.figures.slice().reverse()) {
      if (figure.isCollided(ray)) {
        const collision = new Collision(figure.object, figure);
        collisions.push(collision);
      }
    }

    return collisions;
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return this._isAltitudeMode;
  }
}

export {ShapeLayer};
