import {Vector2} from './Vector2';
import {Ray3} from './Ray3';
import {Optional} from '../types';

/**
 * 2次元レイ
 */
class Ray2 {
  start: Vector2;
  direction: Vector2;

  /**
   * コンストラクタ
   * @param start 始点
   * @param direction 方向ベクトル
   */
  constructor(start: Vector2, direction: Vector2) {
    this.start = start.clone();
    this.direction = direction.clone();
  }

  /**
   * レイを伸ばした際に、指定したx座標になる点を計算
   * @param x x座標
   * @returns 衝突する場合はその点、そうでない場合はnull
   */
  intersectsWithLineXEqualsParameter(x: number): Optional<Vector2> {
    const ray3 = new Ray3(this.start.toVector3(), this.direction.toVector3());
    const result = ray3.intersectsWithPlaneXEqualsParameter(x);
    return result ? result.toVector2() : null;
  }

  /**
   * レイを伸ばした際に、指定したy座標になる点を計算
   * @param y y座標
   * @returns 衝突する場合はその点、そうでない場合はnull
   */
  intersectsWithLineYEqualsParameter(y: number): Optional<Vector2> {
    const ray3 = new Ray3(this.start.toVector3(), this.direction.toVector3());
    const result = ray3.intersectsWithPlaneYEqualsParameter(y);
    return result ? result.toVector2() : null;
  }
}

export {Ray2};
