import {ValueObject} from './interface/ValueObject';
import {JsonObject} from '../types';
import {GaIAError} from './GaIAError';

/**
 * 緯度経度を扱うクラス
 */
class LatLng implements ValueObject {
  /** 緯度 (ミリ秒表記) */
  readonly latMillisec: number;
  /** 経度 (ミリ秒表記) */
  readonly lngMillisec: number;

  /**
   * コンストラクタ
   * @param lat 緯度 - latitude (度表記)
   * @param lng 経度 - longitude (度表記)
   */
  constructor(lat: number, lng: number) {
    if (Math.abs(lat) > 90) {
      throw new GaIAError(`invalid lat value. lat: ${lat}`);
    }
    if (Math.abs(lng) > 180) {
      throw new GaIAError(`invalid lng value. lng: ${lng}`);
    }
    this.latMillisec = Math.round(lat * 3600000);
    this.lngMillisec = Math.round(lng * 3600000);
  }

  /**
   * 緯度(latitude)を取得
   * @returns 緯度
   */
  get lat(): number {
    return this.latMillisec / 3600000;
  }

  /**
   * 経度(longitude)を取得
   * @returns 経度
   */
  get lng(): number {
    return this.lngMillisec / 3600000;
  }

  /** @override */
  clone(): LatLng {
    return new LatLng(this.lat, this.lng);
  }

  /** @override */
  equals(obj: unknown): boolean {
    if (!(obj instanceof LatLng)) {
      return false;
    }
    const other = obj as LatLng;
    return other.latMillisec === this.latMillisec && other.lngMillisec === this.lngMillisec;
  }

  /** @override */
  toObject(): JsonObject {
    return {
      lat: this.lat,
      lng: this.lng,
    };
  }
}

export {LatLng};
