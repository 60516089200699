import {GaiaContext} from '../../GaiaContext';
import {GeoJsonFigureCondition, LatLng} from '../../../../gaia/value';
import {
  GeoJsonObject,
  Position,
  isGeoJsonObject,
  isFeatureCollection,
  isFeature,
  isLineString,
  isMultiLineString,
  Feature,
  isPolygon,
  isMultiPolygon,
} from '../../../../gaia/value/GeoJson';
import {GeoJsonFigureInfo, GeoJsonFigureStyle} from '../objects/GeoJsonFigureInfo';
import {Polyline, Polygon} from '../../../../gaia/object';
import {Figure} from '../../../../gaia/object/shape/Figure';
import {CoordUnit} from '../../../../gaia/types';
import {transMillisecToDegree} from '../../../../gaia/util';
import {DASH_ARRAY_SOLID} from '../../../../gaia/object/shape/Polyline';
import {RouteArrow} from '../objects/RouteArrow';
import {createRouteArrows} from './RouteShape';

/**
 * GeoJSON形状表示のためのヘルパークラス
 */
class GeoJsonFigureHelper {
  private context: GaiaContext;
  private figureMap: Map<GeoJsonFigureCondition, Figure[]>;
  private routeArrowMap: Map<GeoJsonFigureCondition, RouteArrow[]>;

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param camera カメラ
   */
  constructor(context: GaiaContext) {
    this.context = context;
    this.figureMap = new Map<GeoJsonFigureCondition, Figure[]>();
    this.routeArrowMap = new Map<GeoJsonFigureCondition, RouteArrow[]>();
  }

  /**
   * GeoJsonからGeoJsonFigureInfo配列生成
   * @param condition GeoJsonFigureCondition
   * @returns GeoJsonFigureInfoの配列
   */
  createGeoJsonFigureInfoList(condition: GeoJsonFigureCondition): GeoJsonFigureInfo[] {
    const figureInfoList: GeoJsonFigureInfo[] = [];
    if (this.figureMap.has(condition)) {
      return figureInfoList;
    }

    const geoJsonObject = condition.geoJson;
    if (!isGeoJsonObject(geoJsonObject)) {
      return figureInfoList;
    }

    const featureList = this.geoJsonObjectToFeatureList(geoJsonObject);
    for (const feature of featureList) {
      Array.prototype.push.apply(figureInfoList, this.createGeoJsonFigureInfo(feature, condition));
    }

    return figureInfoList;
  }

  /**
   * GeoJsonFigureInfoからFigureを作成
   * @param figureInfoList GeoJsonFigureInfo配列
   * @returns 図形配列
   */
  geoJsonFigureInfoToFigureList(figureInfoList: GeoJsonFigureInfo[]): Figure[] {
    const figureList: Figure[] = [];
    for (const figureInfo of figureInfoList) {
      const style = figureInfo.style;

      if (figureInfo.figureType === 'Polyline') {
        if (style.outLineWeight > 0) {
          const outLine = new Polyline({
            path: figureInfo.path,
            strokeColor: style.outLineColor,
            strokeWeight: style.outLineWeight,
            zIndex: figureInfo.zIndex,
            strokeDashArray: style.outLineDashArray,
          });
          figureList.push(outLine);
        }

        const inLine = new Polyline({
          path: figureInfo.path,
          strokeColor: style.color,
          strokeWeight: style.weight,
          zIndex: figureInfo.zIndex,
          strokeDashArray: style.dashArray,
        });
        figureList.push(inLine);
        continue;
      }

      const polygon = new Polygon({
        paths: figureInfo.path,
        fillColor: style.color,
        strokeColor: style.outLineColor,
        strokeWeight: style.outLineWeight,
        zIndex: figureInfo.zIndex,
      });
      figureList.push(polygon);
    }

    return figureList;
  }

  /**
   * 生成済み図形リストに追加
   * @param condition GeoJsonFigureCondition
   * @param figureList 生成済み図形配列
   * @returns {void}
   */
  addToFigureList(condition: GeoJsonFigureCondition, figureList: Figure[]): void {
    this.figureMap.set(condition, figureList);
  }

  /**
   * GeoJsonFigureConditionに紐づく生成済の図形配列の取り出しと削除
   * @param condition GeoJsonFigureCondition
   * @returns 図形配列
   */
  extractFigureList(condition: GeoJsonFigureCondition): Figure[] {
    const figureList = this.figureMap.get(condition);
    this.figureMap.delete(condition);
    return figureList ?? [];
  }

  /**
   * ルート線矢印を生成しキャッシュに格納する
   * @param condition GeoJsonFigureCondition
   * @returns ルート線矢印
   */
  createRouteArrows(condition: GeoJsonFigureCondition): RouteArrow[] {
    if (this.routeArrowMap.has(condition)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.routeArrowMap.get(condition)!;
    }
    const arrows = createRouteArrows(condition);
    this.routeArrowMap.set(condition, arrows);
    return arrows;
  }

  /**
   * GeoJsonFigureConditionに紐づく生成済のルート線矢印の取り出しと削除
   * @param condition GeoJsonFigureCondition
   * @returns ルート線矢印
   */
  extractRouteArrows(condition: GeoJsonFigureCondition): RouteArrow[] {
    const arrows = this.routeArrowMap.get(condition);
    this.routeArrowMap.delete(condition);
    return arrows ?? [];
  }

  /**
   * GeoJsonObjectからFeature作成
   * @param geoJsonObject GeoJsonObject
   * @returns Feature配列
   */
  private geoJsonObjectToFeatureList(geoJsonObject: GeoJsonObject): Feature[] {
    if (isFeatureCollection(geoJsonObject)) {
      return geoJsonObject.features;
    }

    if (isFeature(geoJsonObject)) {
      return [geoJsonObject];
    }

    return [];
  }

  /**
   * FeatureとFigureStyleからGeoJsonFigureInfo作成
   * @param feature Feature
   * @param condition GeoJsonFigureCondition
   * @returns GeoJsonFigureInfo配列
   */
  private createGeoJsonFigureInfo(feature: Feature, condition: GeoJsonFigureCondition): GeoJsonFigureInfo[] {
    const geoJsonFigureList: GeoJsonFigureInfo[] = [];
    const geometry = feature.geometry;
    const polylineStyle: GeoJsonFigureStyle = {
      color: condition.inlineOption.color,
      weight: condition.inlineOption.weight,
      dashArray: condition.inlineOption.dashArray,
      outLineColor: condition.outlineOption.color,
      outLineWeight: condition.outlineOption.weight,
      outLineDashArray: condition.outlineOption.dashArray,
    };

    if (isLineString(geometry)) {
      const path = this.positionToLatlng(geometry.coordinates, condition.coordUnit);
      const geoJsonFigure = new GeoJsonFigureInfo(
        path,
        'Polyline',
        feature.properties,
        polylineStyle,
        condition.zIndex
      );
      geoJsonFigureList.push(geoJsonFigure);
      return geoJsonFigureList;
    }

    if (isMultiLineString(geometry)) {
      const coordinatesArray = geometry.coordinates;
      for (const coordinates of coordinatesArray) {
        const path = this.positionToLatlng(coordinates, condition.coordUnit);
        const geoJsonFigure = new GeoJsonFigureInfo(
          path,
          'Polyline',
          feature.properties,
          polylineStyle,
          condition.zIndex
        );
        geoJsonFigureList.push(geoJsonFigure);
      }
      return geoJsonFigureList;
    }

    const polygonStyle: GeoJsonFigureStyle = {
      color: condition.polygonOption.color,
      weight: 0,
      dashArray: DASH_ARRAY_SOLID,
      outLineColor: condition.outlineOption.color,
      outLineWeight: condition.outlineOption.weight,
      outLineDashArray: condition.outlineOption.dashArray,
    };

    if (isPolygon(geometry)) {
      // TODO: 中抜きポリゴンに対応する
      const path = this.positionToLatlng(geometry.coordinates[0], condition.coordUnit);
      const geoJsonFigure = new GeoJsonFigureInfo(path, 'Polygon', feature.properties, polygonStyle, condition.zIndex);
      geoJsonFigureList.push(geoJsonFigure);
      return geoJsonFigureList;
    }

    if (isMultiPolygon(geometry)) {
      const polygonCoordinatesArray = geometry.coordinates;
      for (const polygonCoordinates of polygonCoordinatesArray) {
        const path = this.positionToLatlng(polygonCoordinates[0], condition.coordUnit);
        const geoJsonFigure = new GeoJsonFigureInfo(
          path,
          'Polygon',
          feature.properties,
          polygonStyle,
          condition.zIndex
        );
        geoJsonFigureList.push(geoJsonFigure);
      }
      return geoJsonFigureList;
    }

    return geoJsonFigureList;
  }

  /**
   * Position配列をLatLng配列に変換
   * @param positionList Position型の緯度経度配列
   * @param unit 緯度経度の単位
   * @returns LatLng型の緯度経度配列
   */
  private positionToLatlng(positionList: Position[], unit: CoordUnit): LatLng[] {
    const latlngList: LatLng[] = [];
    for (const coordinate of positionList) {
      if (unit === 'millisec') {
        const latlng = new LatLng(transMillisecToDegree(coordinate[1]), transMillisecToDegree(coordinate[0]));
        latlngList.push(latlng);
        continue;
      }

      const latlng = new LatLng(coordinate[1], coordinate[0]);
      latlngList.push(latlng);
    }

    return latlngList;
  }
}

export {GeoJsonFigureHelper};
