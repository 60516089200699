import {RoadShapeOpenedProperty} from '../../../common/infra/response/RoadShapeOpenedInfo';
import {Vector3} from '../../../common/math/Vector3';
import {MapStatus} from '../../models/MapStatus';
import {mat4} from 'gl-matrix';
import {Triangle3} from '../../../common/math/Triangle3';
import {ShapeCollider} from '../../../common/math/ShapeCollider';
import {Ray3} from '../../../common/math/Ray3';
import {RoadShapeOpenedSegmentType} from '../../layer/RoadShapeOpenedLayer';
import {PolylineObject} from './PolylineObject';
import {Optional} from '../../../common/types';
import {PolylineTriangulator} from '../../../engine/polygon/PolylineTriangulator';
import {DASH_ARRAY_SOLID} from '../../../../gaia/object/shape/Polyline';
import {Vector2} from '../../../common/math/Vector2';
import {calculatePixelToUnit} from '../../utils/MapUtil';
import {TileNumber} from '../../models/TileNumber';

type RoadShapeOpenedSegments = Map<PolylineObject, ShapeCollider>;

const COLLIDER_THICKNESS_MARGIN = 4;

/**
 * 一本の道路を表すクラス
 */
class RoadShapeOpenedObject {
  readonly property: RoadShapeOpenedProperty;

  // キー = TileNumberのcacheKey
  readonly inlineSegmentMap: Map<string, RoadShapeOpenedSegments>;
  readonly outlineSegmentMap: Map<string, RoadShapeOpenedSegments>;

  private _isVisible: boolean;

  /**
   * コンストラクタ
   * @param property RoadShapeOpenedProperty
   */
  constructor(property: RoadShapeOpenedProperty) {
    this.property = property;

    this.inlineSegmentMap = new Map();
    this.outlineSegmentMap = new Map();

    this._isVisible = true;
  }

  /**
   * PolylineObjectを追加する
   * @param tile TileNumber
   * @param polyline PolylineObject
   * @param segmentType 外線か内線か
   * @returns {void}
   */
  addPolylineObjects(tile: TileNumber, polyline: PolylineObject, segmentType: RoadShapeOpenedSegmentType): void {
    // すでに同じタイル番号で追加されていればsegment情報をマージする
    if (segmentType === 'inline') {
      const existingSegments = this.inlineSegmentMap.get(tile.getCacheKey());
      if (existingSegments) {
        existingSegments.set(polyline, new ShapeCollider([]));
      } else {
        const segment: RoadShapeOpenedSegments = new Map([[polyline, new ShapeCollider([])]]);
        this.inlineSegmentMap.set(tile.getCacheKey(), segment);
      }

      return;
    }

    const existingSegments = this.outlineSegmentMap.get(tile.getCacheKey());
    if (existingSegments) {
      existingSegments.set(polyline, new ShapeCollider([]));
    } else {
      const segment: RoadShapeOpenedSegments = new Map([[polyline, new ShapeCollider([])]]);
      this.outlineSegmentMap.set(tile.getCacheKey(), segment);
    }
  }

  /**
   * タイル単位でPolylineObjectを削除
   * @param tile RoadShapeOpenedPrameter
   * @returns {void}
   */
  removePolylineObjects(tile: TileNumber): void {
    this.inlineSegmentMap.delete(tile.getCacheKey());
    this.outlineSegmentMap.delete(tile.getCacheKey());
  }

  /**
   * 表示状態を設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    if (visible === this._isVisible) {
      return;
    }

    this._isVisible = visible;
    const inlineSegments = Array.from(this.inlineSegmentMap.values());
    const outlineSegments = Array.from(this.outlineSegmentMap.values());
    const allSegments = [...inlineSegments, ...outlineSegments];
    for (const segments of allSegments) {
      for (const polyline of segments.keys()) {
        polyline.setVisible(visible);
      }
    }
  }

  /**
   * 表示状態を取得
   * @returns 表示状態
   */
  isVisible(): boolean {
    return this._isVisible;
  }

  /**
   * 描画更新
   * @param cameraTargetPosition cameraの注視点
   * @param mapStatus 地図状態
   * @returns {void}
   */
  updateRoadShapeOpenedObject(cameraTargetPosition: Vector3, mapStatus: MapStatus): void {
    const inlineSegments = Array.from(this.inlineSegmentMap.values());
    const outlineSegments = Array.from(this.outlineSegmentMap.values());
    const allSegments = [...inlineSegments, ...outlineSegments];
    for (const segments of allSegments.values()) {
      for (const object of segments.keys()) {
        object.updateFigure(cameraTargetPosition, mapStatus);
      }
    }
  }

  /**
   * PolylineObjectの更新と描画
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  updateDraw(viewMatrix: mat4, projectionMatrix: mat4): void {
    const outlineSegments = Array.from(this.outlineSegmentMap.values());
    for (const segments of outlineSegments) {
      for (const polyline of segments.keys()) {
        polyline.update(viewMatrix, projectionMatrix);
        polyline.draw();
      }
    }

    const inlineSegments = Array.from(this.inlineSegmentMap.values());
    for (const segments of inlineSegments) {
      for (const polyline of segments.keys()) {
        polyline.update(viewMatrix, projectionMatrix);
        polyline.draw();
      }
    }
  }

  /**
   * 各PolylineObjectに対応するColliderを更新
   * @param zoomLevel ズームレベル
   * @returns {void}
   */
  updateColliders(zoomLevel: number): void {
    // TODO inline, outlineともに存在する場合はcolliderをどちらか一方にする

    const ptu = calculatePixelToUnit(zoomLevel);

    const inlineSegments = Array.from(this.inlineSegmentMap.values());
    const outlineSegments = Array.from(this.outlineSegmentMap.values());
    const allSegments = [...inlineSegments, ...outlineSegments];
    for (const segments of allSegments.values()) {
      for (const polyline of segments.keys()) {
        const {path, thickness} = polyline;
        const vec2Path: Vector2[] = [];
        for (const vec3Position of path) {
          vec2Path.push(vec3Position.toVector2());
        }

        // 見た目が破線でもコライダは実線にする
        // コライダは実際の線よりも少し太くする
        const triangulator = new PolylineTriangulator((thickness + COLLIDER_THICKNESS_MARGIN) * ptu, DASH_ARRAY_SOLID);
        triangulator.addStroke(polyline.getStrokePath());
        const geometry = triangulator.triangulate();

        const triangles: Triangle3[] = [];
        const indices = geometry.getIndices();
        const vertices = geometry.getVertices();
        const basePosition = polyline.basePosition;
        for (let index = 0; index < indices.length; index += 3) {
          const i1 = indices[index];
          const i2 = indices[index + 1];
          const i3 = indices[index + 2];
          const p1 = new Vector3(vertices[i1 * 3], vertices[i1 * 3 + 1], vertices[i1 * 3 + 2]).add(basePosition);
          const p2 = new Vector3(vertices[i2 * 3], vertices[i2 * 3 + 1], vertices[i2 * 3 + 2]).add(basePosition);
          const p3 = new Vector3(vertices[i3 * 3], vertices[i3 * 3 + 1], vertices[i3 * 3 + 2]).add(basePosition);
          const triangle = new Triangle3(p1, p2._subtract(p1), p3._subtract(p1));
          triangles.push(triangle);
        }
        const collider = new ShapeCollider(triangles);
        segments.set(polyline, collider);
      }
    }
  }

  /**
   * rayと衝突するPolylineObjectを取得
   * @param ray Ray3
   * @returns PolylineObject
   */
  getCollidedPolyline(ray: Ray3): Optional<PolylineObject> {
    const inlineSegments = Array.from(this.inlineSegmentMap.values());
    const outlineSegments = Array.from(this.outlineSegmentMap.values());
    const allSegments = [...inlineSegments, ...outlineSegments];
    for (const segments of allSegments.values()) {
      for (const [polyline, collider] of segments.entries()) {
        if (collider.isCollided(ray)) {
          return polyline;
        }
      }
    }

    return undefined;
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const inlineSegments = Array.from(this.inlineSegmentMap.values());
    const outlineSegments = Array.from(this.outlineSegmentMap.values());
    const allSegments = [...inlineSegments, ...outlineSegments];
    for (const segments of allSegments.values()) {
      for (const object of segments.keys()) {
        object.destroy();
      }
    }
  }
}

export {RoadShapeOpenedObject};
