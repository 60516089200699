import {LatLng, Color} from '../../value';
import {Figure} from './Figure';
import {PolylineOptions, PolylineEventMap, PolylineEvent} from '../../types';
import {GaIAEventEmitter} from '../../value/interface/GaIAEventEmitter';

const DASH_ARRAY_SOLID = [1000 * 1000 * 1000];

/**
 * ポリラインの表現クラス
 */
class Polyline extends Figure implements GaIAEventEmitter {
  /** ポリライン形状 */
  public readonly path: LatLng[];
  /** 線の色 */
  public readonly strokeColor: Color;
  /** 線の太さ */
  public readonly strokeWeight: number;
  /** 点線のパターン */
  public readonly strokeDashArray: number[];

  readonly listeners: {[key: string]: (ev: PolylineEvent) => void};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onAddEventListener?: (eventName: keyof PolylineEventMap, func: (ev: PolylineEvent) => any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onRemoveEventListener?: (eventName: keyof PolylineEventMap, func: (ev: PolylineEvent) => any) => void;

  /**
   * コンストラクタ
   * @param options オプション
   */
  constructor(options: PolylineOptions) {
    super(options);

    this.path = options.path;
    this.strokeColor = options.strokeColor ?? Color.black();
    this.strokeWeight = options.strokeWeight ?? 1;
    this.strokeDashArray = options.strokeDashArray ?? DASH_ARRAY_SOLID;

    this.listeners = {};
  }

  /**
   * イベント追加・削除時のリスナーを設定
   * @ignore
   * @param add イベント追加リスナー
   * @param remove イベント削除リスナー
   * @returns {void}
   */
  setOnEventUpdatedListener(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    add?: (eventName: keyof PolylineEventMap, func: (ev: PolylineEvent) => any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    remove?: (eventName: keyof PolylineEventMap, func: (ev: PolylineEvent) => any) => void
  ): void {
    this.onAddEventListener = add;
    this.onRemoveEventListener = remove;
  }

  /** @override */
  addEventListener<K extends keyof PolylineEventMap>(
    eventName: K,
    func: (
      ev: PolylineEventMap[K]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => any
  ): void {
    if (this.onAddEventListener) {
      this.onAddEventListener(eventName, func);
      return;
    }
    this.listeners[eventName] = func;
  }

  /** @override */
  removeEventListener<K extends keyof PolylineEventMap>(
    eventName: K,
    func: (
      ev: PolylineEventMap[K]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => any
  ): void {
    if (this.onRemoveEventListener) {
      this.onRemoveEventListener(eventName, func);
      return;
    }
    delete this.listeners[eventName];
  }
}

export {Polyline, DASH_ARRAY_SOLID};
