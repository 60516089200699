import {ArrayList} from '../../../../common/collection/ArrayList';
import {LRUCache} from '../../../../common/collection/LRUCache';
import {
  AnnotationIconHeader,
  IconAnnotationInfo,
  isIconAnnotationInfo,
  isIconPaletteConfig,
  isMarkAnnotationInfo,
  isMarkPaletteConfig,
  isNotePaletteConfig,
  isTextAnnotationInfo,
  MarkAnnotationInfo,
  NoteAnnotationInfoGroup,
  PaletteInfo,
  TextAnnotationInfo,
} from '../../../../common/infra/response/AnnotationInfo';
import {Optional, ZoomLevelFixFunc} from '../../../../common/types';
import {Camera} from '../../../../engine/camera/Camera';
import {GaiaContext} from '../../../GaiaContext';
import {MarkAnnotationLayer} from '../../../layer/MarkAnnotationLayer';
import {NoteAnnotationLayer} from '../../../layer/NoteAnnotationLayer';
import {AnnotationLoader} from '../../../loader/AnnotationLoader';
import {AnnotationMainParameter} from '../../../loader/param/AnnotationMainParameter';
import {IconAnnotationData} from '../../../models/annotation/IconAnnotationData';
import {MapStatus} from '../../../models/MapStatus';
import {TextAnnotationData} from '../../../models/annotation/TextAnnotationData';
import {TileNumber} from '../../../models/TileNumber';
import {MapRenderKitController, TileRenderKitMapping} from '../MapRenderKitController';
import {PaletteParameter} from '../../../models/PaletteParameter';
import {
  Language,
  AnnotationClickListener,
  TileSize,
  AnnotationClickListenerOptions,
  AnnotationFontFamilyMap,
  LoadingProgressListener,
} from '../../../../../gaia/types';
import {LatLng, Point, Size} from '../../../../../gaia/value';
import {Vector2} from '../../../../common/math/Vector2';
import {MarkAnnotationData} from '../../../models/annotation/MarkAnnotationData';
import {AnnotationMetaParameter} from '../../../loader/param/AnnotationMetaParameter';
import {getDevicePixelRatio} from '../../../../common/util/Device';
import {TexturePlaneUVCoordinate} from '../../../../engine/geometry/TexturePlaneUVCoordinate';
import {calculateWorldCoordinate, getZoomLevelFixFunc} from '../../../utils/MapUtil';
import {Ray3} from '../../../../common/math/Ray3';
import {Collision} from '../../../../engine/collision/Collision';
import {aspectToFogDistanceRatio} from '../../../layer/FogObjectLayer';

export type NoteAnnotationData = TextAnnotationData | IconAnnotationData;

const LAYER_NAME_ANNOTATION = 'annotation';

// 更新の間隔（単位はミリ秒）
const INTERVAL_UPDATE_MS = 200;

/**
 * 注記オブジェクトを扱う描画キット
 */
class AnnotationObjectRenderKit {
  private context: GaiaContext;
  private camera: Camera;
  private visible: boolean;

  private annotationLoader: AnnotationLoader;
  private noteLayer: NoteAnnotationLayer;
  private markLayer: MarkAnnotationLayer;

  private iconHeader?: AnnotationIconHeader;
  private markHeader?: AnnotationIconHeader;

  /** 生成済データ */
  private noteDataListCache: LRUCache<AnnotationMainParameter, NoteAnnotationData[]>;
  private markDataListCache: LRUCache<AnnotationMainParameter, MarkAnnotationData[]>;

  private currentPalette: PaletteParameter;
  private readonly tileSize: TileSize;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private previousCenter: LatLng;
  private previousZoomLevel: number;
  private readonly fixIntZoomLevel: ZoomLevelFixFunc;

  private previousChangedTime: number;
  private nextUpdate: Optional<NodeJS.Timeout>;
  private running: boolean;

  readonly getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  private isSetIconTable: boolean;
  private previousLoadingProgress: number;
  private onUpdateLoadingProgress?: LoadingProgressListener;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKitCtl MapRenderKitController
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    this.context = context;
    this.camera = camera;
    this.visible = context.getMapInitOptions().isAnnotationEnabled ?? false;

    this.noteLayer = new NoteAnnotationLayer(context, this, camera);
    this.markLayer = new MarkAnnotationLayer(context, camera);
    this.noteLayer.setVisible(this.visible);
    this.markLayer.setVisible(this.visible);

    this.noteDataListCache = new LRUCache(100);
    this.markDataListCache = new LRUCache(100);

    this.currentPalette = context.getMapStatus().palette;
    this.tileSize = getDevicePixelRatio() > 1 ? 512 : 256;

    this.annotationLoader = new AnnotationLoader(
      context,
      () => {
        if (this.status && this.tileList) {
          this.updateDrawObjects(this.status, this.tileList);
        }
      },
      () => {
        if (this.status && this.tileList) {
          this.updateDrawObjects(this.status, this.tileList);
        }
      }
    );

    this.previousCenter = context.getMapStatus().centerLocation;
    this.previousZoomLevel = this.context.getMapStatus().zoomLevel;
    this.fixIntZoomLevel = getZoomLevelFixFunc(this.context);

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKitCtl.getAllCollisions(ray);

    this.previousChangedTime = performance.now();
    this.nextUpdate = null;
    this.running = false;

    this.isSetIconTable = false;
    this.previousLoadingProgress = 0;
    this.onUpdateLoadingProgress = undefined;
  }

  /**
   * 標高取得コールバックを設定
   * @param callback コールバック
   * @returns {void}
   */
  setAltitudeCallback(callback: (latLng: LatLng) => number): void {
    this.noteLayer.setAltitudeCallback(callback);
  }

  /**
   * RenderKit特定用キー
   */
  get identicalName(): keyof TileRenderKitMapping {
    return LAYER_NAME_ANNOTATION;
  }

  /**
   * テキスト・アイコン注記レイヤーを取得
   * @returns テキスト・アイコン注記レイヤー
   */
  getNoteLayer(): NoteAnnotationLayer {
    return this.noteLayer;
  }

  /**
   * 国道アイコン注記レイヤーを取得
   * @returns 国道アイコン注記レイヤー
   */
  getMarkLayer(): MarkAnnotationLayer {
    return this.markLayer;
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  private clear(): void {
    this.iconHeader = undefined;
    this.markHeader = undefined;
    this.noteDataListCache.clear();
    this.markDataListCache.clear();
    this.annotationLoader.clear();
    this.noteLayer.clear();
    this.markLayer.clear();
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.noteLayer.destroy();
    this.markLayer.destroy();
    this.annotationLoader.destroy();
  }

  /**
   * 注記クリックリスナーの設定
   * @param listener リスナー関数
   * @param options オプション
   * @returns {void}
   */
  setAnnotationClickListener(listener: AnnotationClickListener, options?: AnnotationClickListenerOptions): void {
    this.noteLayer.setAnnotationClickListener(listener, options);
  }

  /**
   * 注記クリックリスナーの削除
   * @returns {void}
   */
  removeAnnotationClickListener(): void {
    this.noteLayer.removeAnnotationClickListener();
  }

  /**
   * 表示状態の設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
    this.noteLayer.setVisible(visible);
    this.markLayer.setVisible(visible);
  }

  /**
   * フォント情報を設定
   * @param fontFamilyMap フォント情報
   * @returns {void}
   */
  setFontFamilyMap(fontFamilyMap: AnnotationFontFamilyMap): void {
    this.noteLayer.setFontFamilyMap(fontFamilyMap);
  }

  /**
   * 注記の描画進捗更新リスナーを設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setAnnotationLoadingProgressListener(listener?: LoadingProgressListener): void {
    this.onUpdateLoadingProgress = listener;
    this.onUpdateLoadingProgress?.(this.previousLoadingProgress);
  }

  /**
   * ローダーのリクエスト実行
   * @returns {void}
   */
  executeLoader(): void {
    this.annotationLoader.executeRequest();
  }

  /**
   * 描画物更新
   * @param mapStatus 地図状態
   * @param tileList 描画対象タイルリスト
   * @returns {void}
   */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.visible) {
      return;
    }

    this.status = mapStatus;
    this.tileList = tileList;
    this.annotationLoader.jumpUpMainCacheSize(tileList.size() * 2);
    this.noteDataListCache.jumpUpSize(tileList.size() * 2);
    this.markDataListCache.jumpUpSize(tileList.size() * 2);

    // paletteが変更されていたら再びメタリクエストから行う
    if (!this.currentPalette.equals(mapStatus.palette)) {
      this.clear();
      this.currentPalette = mapStatus.palette;
    }

    // シリアルチェック
    const metaParam = new AnnotationMetaParameter(this.tileSize, this.currentPalette);
    const meta = this.annotationLoader.getMetaInfo(metaParam);
    if (!meta) {
      this.annotationLoader.setMetaParameter(metaParam);
    } else if (!this.iconHeader || !this.markHeader) {
      this.iconHeader = meta.iconHeader;
      this.markHeader = meta.markHeader;
    }

    // ズームレベル整数値・中心緯度経度の変化量が閾値を超えていたらリクエストキュークリア
    const isChangeCenterLat = Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) > 0.1;
    const isChangeCenterLng = Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) > 0.1;
    const isChangeZoomLevel =
      this.fixIntZoomLevel(mapStatus.zoomLevel) !== this.fixIntZoomLevel(this.previousZoomLevel);
    if (isChangeZoomLevel || isChangeCenterLat || isChangeCenterLng) {
      this.annotationLoader.clearRequestQueue();
    }

    // 緯度経度やズームレベルが少しでも変化していればテクスチャ化などの重い処理を後回しにする
    const littleChangedLat =
      Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) * 2 ** (mapStatus.zoomLevel + 16) > 0.00000001;
    const littleChangedLon =
      Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) * 2 ** (mapStatus.zoomLevel + 16) > 0.00000001;
    const littleChangedZoomLevel = mapStatus.zoomLevel !== this.previousZoomLevel;
    this.previousZoomLevel = mapStatus.zoomLevel;
    this.previousCenter = mapStatus.centerLocation;
    if (littleChangedLat || littleChangedLon || littleChangedZoomLevel) {
      this.previousChangedTime = performance.now();
    }

    if (performance.now() - this.previousChangedTime < INTERVAL_UPDATE_MS) {
      this.clearNextUpdate();
      this.nextUpdate = setTimeout(() => {
        this.processAnnotationData();
        this.clearNextUpdate();
      }, INTERVAL_UPDATE_MS);
    } else {
      this.processAnnotationData();
    }

    // メインリクエスト
    const requestTiles: AnnotationMainParameter[] = [];
    const noteDataList: Map<AnnotationMainParameter, NoteAnnotationData[]> = new Map();
    const markDataList: Map<AnnotationMainParameter, MarkAnnotationData[]> = new Map();
    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    let loadedTileCount = 0;
    for (const tileNumber of tileList) {
      const latLng = tileNumber.centerLocation;
      const position = calculateWorldCoordinate(latLng);
      const distance = position._subtract(centerPosition).magnitude();
      const distanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
      if (distance > cameraDistance * distanceRatio * 0.9) {
        loadedTileCount++;
        continue;
      }

      const annotationParam = new AnnotationMainParameter(
        tileNumber.x,
        tileNumber.y,
        tileNumber.z,
        this.tileSize,
        this.currentPalette
      );

      const noteDataListCache = this.noteDataListCache.get(annotationParam);
      const markDataListCache = this.markDataListCache.get(annotationParam);
      if (noteDataListCache && markDataListCache) {
        noteDataList.set(annotationParam, noteDataListCache);
        markDataList.set(annotationParam, markDataListCache);
      } else {
        const mainInfo = this.annotationLoader.getMainInfo(annotationParam);
        if (!mainInfo) {
          requestTiles.push(annotationParam);
        } else {
          const createdNoteList = this.createNoteDataList(
            this.currentPalette.language,
            annotationParam.z,
            mainInfo.note,
            mainInfo.palette
          );
          noteDataList.set(annotationParam, createdNoteList);
          loadedTileCount++;
          if (createdNoteList.length > 0) {
            this.noteDataListCache.add(annotationParam, createdNoteList);
          }

          const createdMarkList = this.createMarkDataList(
            this.currentPalette.language,
            annotationParam.z,
            mainInfo.mark,
            mainInfo.palette
          );
          markDataList.set(annotationParam, createdMarkList);
          if (createdMarkList.length > 0) {
            this.markDataListCache.add(annotationParam, createdMarkList);
          }
        }
      }
    }

    this.noteLayer.update(mapStatus, noteDataList);
    this.markLayer.update(mapStatus, markDataList);

    // layerへのアイコン受け渡し
    if (!this.noteLayer.isSetIconTexture) {
      const icont = this.annotationLoader.getIconTCache(metaParam);
      if (icont) {
        this.isSetIconTable = true;
        this.noteLayer.setIconTexture(icont);
      }
    }
    if (!this.markLayer.isSetMatTexture) {
      const markt = this.annotationLoader.getMarkTCache(metaParam);
      if (markt) {
        this.markLayer.setMatTexture(markt);
      }
    }

    const noteLoadingProgress = loadedTileCount / tileList.size();
    const loadingProgress = noteLoadingProgress * 0.95 + (this.isSetIconTable ? 0.05 : 0);
    if (loadingProgress !== this.previousLoadingProgress) {
      this.previousLoadingProgress = loadingProgress;
      this.onUpdateLoadingProgress?.(loadingProgress);
    }

    if (requestTiles.length === 0) {
      return;
    }
    this.annotationLoader.addMainRequestQueue(requestTiles);
  }

  /**
   * NoteAnnotationDataの生成
   * @param lang 言語
   * @param zoom ズームレベル
   * @param noteInfoGroup NoteAnnotationInfoGroupリスト
   * @param paletteInfo PaletteInfo
   * @returns NoteAnnotationDataリスト
   */
  private createNoteDataList(
    lang: Language,
    zoom: number,
    noteInfoGroup: NoteAnnotationInfoGroup[],
    paletteInfo: PaletteInfo
  ): NoteAnnotationData[] {
    const noteList: NoteAnnotationData[] = [];
    for (const group of noteInfoGroup) {
      const noteInfoList = group.group;
      for (const noteInfo of noteInfoList) {
        // text
        if (isTextAnnotationInfo(noteInfo)) {
          const data = this.createTextData(lang, zoom, noteInfo, paletteInfo);
          if (data) {
            noteList.push(data);
          }
          continue;
        }

        // icon
        if (isIconAnnotationInfo(noteInfo)) {
          const data = this.createIconData(lang, noteInfo, paletteInfo);
          if (data) {
            noteList.push(data);
          }
        }
      }
    }
    return noteList;
  }

  /**
   * TextAnnotationDataの生成
   * @param lang 言語
   * @param zoom ズームレベル
   * @param textInfo TextAnnotationInfo
   * @param paletteInfo PaletteInfo
   * @returns TextAnnotationData
   */
  private createTextData(
    lang: Language,
    zoom: number,
    textInfo: TextAnnotationInfo,
    paletteInfo: PaletteInfo
  ): Optional<TextAnnotationData> {
    const notePaletteConfig = paletteInfo[textInfo.ntjCode].note;
    if (!notePaletteConfig || !isNotePaletteConfig(notePaletteConfig) || !notePaletteConfig.visible) {
      return;
    }
    return TextAnnotationData.createAnnotationData(lang, zoom, this.tileSize, textInfo, notePaletteConfig);
  }

  /**
   * IconAnnotationDataの生成
   * @param lang 言語
   * @param iconInfo IconAnnotationInfo
   * @param paletteInfo PaletteInfo
   * @returns IconAnnotationData
   */
  private createIconData(
    lang: Language,
    iconInfo: IconAnnotationInfo,
    paletteInfo: PaletteInfo
  ): Optional<IconAnnotationData> {
    const iconPaletteConfig = paletteInfo[iconInfo.ntjCode].icon;
    // 該当するパレットがある かつ IconHeader内に該当するマッピング情報がある場合にのみ描画する
    if (
      !iconPaletteConfig ||
      !isIconPaletteConfig(iconPaletteConfig) ||
      !this.iconHeader ||
      !(iconPaletteConfig.name in this.iconHeader.mapping) ||
      !iconPaletteConfig.visible
    ) {
      return;
    }
    const {x, y} = this.iconHeader.mapping[iconPaletteConfig.name];
    const {iconWidth: width, iconHeight: height} = this.iconHeader;
    const overallWidth = this.iconHeader.chainX * width;
    const overallHeight = this.iconHeader.chainY * height;

    // 隣接したアイコンの端が入って見栄えが悪いことがあるため、一回り小さく切り取る
    const uvTop = (y * height + 1) / overallHeight;
    const uvLeft = (x * width + 1) / overallWidth;
    const uvBottom = ((y + 1) * height - 1) / overallHeight;
    const uvRight = ((x + 1) * width - 1) / overallWidth;
    return IconAnnotationData.createAnnotationData(
      lang,
      this.tileSize,
      iconInfo,
      iconPaletteConfig,
      new Size(height, width),
      new TexturePlaneUVCoordinate(
        new Vector2(uvLeft, uvTop),
        new Vector2(uvLeft, uvBottom),
        new Vector2(uvRight, uvTop),
        new Vector2(uvRight, uvBottom)
      )
    );
  }

  /**
   * MarkAnnotationDataの生成
   * @param lang 言語
   * @param zoom ズームレベル
   * @param markInfo MarkAnnotationInfoリスト
   * @param paletteInfo PaletteInfo
   * @returns MarkAnnotationDataリスト
   */
  private createMarkDataList(
    lang: Language,
    zoom: number,
    markInfo: MarkAnnotationInfo[],
    paletteInfo: PaletteInfo
  ): MarkAnnotationData[] {
    const markList: MarkAnnotationData[] = [];
    for (const info of markInfo) {
      const palette = paletteInfo[info.ntjCode].mark;
      // 該当するパレットがある かつ MarkHeader内に該当するマッピング情報がある場合にのみ描画する
      if (
        !palette ||
        !isMarkAnnotationInfo(info) ||
        !isMarkPaletteConfig(palette) ||
        !this.markHeader ||
        !(palette.name in this.markHeader.mapping) ||
        !palette.visible
      ) {
        continue;
      }
      const {x, y} = this.markHeader.mapping[palette.name];
      const {iconWidth, iconHeight} = this.markHeader;
      const imageWidth = iconWidth * 2;
      const imageHeight = iconHeight * 2;
      const data = MarkAnnotationData.createAnnotationData(
        lang,
        zoom,
        this.tileSize,
        info,
        palette,
        new Size(palette.size * iconWidth * 0.01, palette.size * iconHeight * 0.01),
        new Point(x * imageWidth, y * imageHeight),
        new Point((x + 1) * imageWidth, (y + 1) * imageHeight),
        new Size(imageHeight, imageWidth)
      );
      markList.push(data);
    }
    return markList;
  }

  /**
   * setTimeoutで仕込んでおいた地図更新を取り消す
   * @returns {void}
   */
  private clearNextUpdate(): void {
    if (!this.nextUpdate) {
      return;
    }
    clearTimeout(this.nextUpdate);
    this.nextUpdate = null;
  }

  /**
   * 後回しにしていたテクスチャ化などの処理を実行する
   * @returns {void}
   */
  private processAnnotationData(): void {
    if (this.running || !this.status || !this.tileList) {
      return;
    }
    this.running = true;
    this.annotationLoader.processAnnotationData();
    this.updateDrawObjects(this.status, this.tileList);
    this.running = false;
  }
}

export {AnnotationObjectRenderKit};
