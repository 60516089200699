import {Object3D} from '../../../engine/object/Object3D';
import {Vector3} from '../../../common/math/Vector3';
import {Quaternion} from '../../../common/math/Quaternion';
import {MapStatus} from '../../models/MapStatus';
import {TileNumber} from '../../models/TileNumber';
import {TextureMapping} from '../../../engine/program/TextureMapping';
import {TexturePlaneGeometry} from '../../../engine/geometry/TexturePlaneGeometry';
import {calculatePixelCoordinate, calculatePixelToUnit, calculateWorldCoordinate} from '../../utils/MapUtil';
import {ZoomLevelFixFunc} from '_private/common/types';
import {GradationMaterial} from '../../../engine/material/GradationMaterial';

/**
 * タイル画像を描画するオブジェクト
 */
class GradationTileObject3D extends Object3D {
  material: GradationMaterial;
  tileNumber: TileNumber;

  isCompletion: boolean;
  private _isSetTexture: boolean;

  isSub: boolean;

  private readonly fixIntZoomLevel: (zoom: number) => number;

  /**
   * コンストラクタ
   * @param position 位置
   * @param material マテリアル
   * @param tileNumber タイルパラメータ
   * @param zoomLevelFixFunc ズームレベル整数補正関数
   * @param isSub サブタイルかどうか
   */
  constructor(
    position: Vector3,
    material: GradationMaterial,
    tileNumber: TileNumber,
    zoomLevelFixFunc: ZoomLevelFixFunc,
    isSub = false
  ) {
    const rotation = Quaternion.fromRadianAndAxis(0, new Vector3(0, 1, 0));
    const scale = Vector3.one()._multiply(0.5);
    super(position, rotation, scale, material);

    this.material = material;
    this.tileNumber = tileNumber;
    this.isCompletion = false;
    this._isSetTexture = false;
    this.isSub = isSub;

    this.fixIntZoomLevel = zoomLevelFixFunc;
  }

  /**
   * テクスチャがセットされているか
   */
  get isSetTexture(): boolean {
    return this._isSetTexture;
  }

  /**
   * 地図更新
   * @param mapStatus 地図状態
   * @param cameraTargetPosition カメラが注視している点の座標
   * @returns {void}
   */
  mapUpdate(mapStatus: MapStatus, cameraTargetPosition: Vector3): void {
    const latLng = this.tileNumber.centerLocation;
    const zoomLevel = mapStatus.zoomLevel;
    const centerPosition = calculateWorldCoordinate(latLng);
    this.setPositionValues(
      centerPosition.x - cameraTargetPosition.x,
      centerPosition.y - cameraTargetPosition.y,
      centerPosition.z - cameraTargetPosition.z
    );

    const centerPixel = calculatePixelCoordinate(mapStatus.centerLocation, zoomLevel);
    const pixelOffset = centerPixel._subtract(calculatePixelCoordinate(latLng, zoomLevel));
    pixelOffset.setValues(-1 * pixelOffset.x, pixelOffset.y);
    const ptu = calculatePixelToUnit(zoomLevel);
    this.material.setPixelOffset(pixelOffset);
    this.material.setPixelToUnit(ptu);

    const targetZoomLevel = this.tileNumber.z;
    const scaleBase = calculatePixelToUnit(targetZoomLevel) * 256;
    this.material.setTextureSize(scaleBase);
    this.setScaleValues(scaleBase, scaleBase, scaleBase);
  }

  /**
   * テクスチャをセットする
   * @param image テクスチャ
   * @returns {void}
   */
  setTexture(image: TexImageSource): void {
    if (this._isSetTexture) {
      return;
    }

    const geometry = TexturePlaneGeometry.create(1, 1);
    this.material.setGeometry(geometry);

    this.material.setTileNumber(this.tileNumber);
    this.material.setTexture(image);
    this._isSetTexture = true;
  }

  /**
   * テクスチャ情報をセット
   * @param textureMapping テクスチャ情報
   * @returns {void}
   */
  setTextureMapping(textureMapping: TextureMapping): void {
    if (this._isSetTexture) {
      return;
    }

    if (this.isCompletion) {
      return;
    }

    this.material.setTextureMapping(textureMapping);
    this.isCompletion = true;
  }

  /**
   * 透明かどうか
   * @returns 少しでも透明な場合はtrue、そうでない場合はfalse
   */
  isTransparent(): boolean {
    return this.material.isTransparent();
  }

  /**
   * 透明度を取得
   * @returns 透明度
   */
  getTransparency(): number {
    return this.material.getTextureTransparency();
  }

  /**
   * タイルテクスチャの透明度を設定
   * @param alpha 透明度
   * @returns {void}
   */
  setTransparency(alpha: number): void {
    this.material.setTextureTransparency(alpha);
  }
}

export {GradationTileObject3D};
