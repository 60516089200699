import {GaiaContext} from '../GaiaContext';
import {OnFinishRequestFunc, LoaderState} from './AbstractTileLoader';
import {Optional, QueryParameter} from '../../common/types';
import {LRUCache} from '../../common/collection/LRUCache';
import {Queue} from '../../common/collection/Queue';
import {RoadShapeOpenedInfoRequester} from '../../common/infra/request/RoadShapeOpenedInfoRequester';
import {JsonObject, TileSize} from '../../../gaia/types';
import {TileNumber} from '../models/TileNumber';
import {RoadShapeOpenedMetaInfo} from '../../common/infra/response/RoadShapeOpenedInfo';
import {RoadShapeOpenedMetaParameter} from './param/RoadShapeOpenedMetaParameter';
import {RoadShapeOpenedMainParameter} from './param/RoadShapeOpenedMainParameter';
import {PaletteParameter} from '../models/PaletteParameter';
import {buildQueryString} from '../../common/util/URL';

const PATH_ROADSHAPEOPENED = 'data/roadshape/opened';

// TODO ズームレベルに関わらずどこか1つのズームレベルのデータを使い回す（ズームレベル変化時の通信を減らす）
/**
 * 新規開通道路ローダー
 */
class RoadShapeOpenedLoader {
  private serverUrl = '';
  private readonly requester: RoadShapeOpenedInfoRequester;

  private cache: LRUCache<RoadShapeOpenedMainParameter, JsonObject>;
  private requestQueue: Queue<RoadShapeOpenedMainParameter>;
  private requestingTile: Set<string>;
  private onFinishRequestFunc: OnFinishRequestFunc;

  private isRequestingMeta: boolean;
  private currentMetaParam?: RoadShapeOpenedMetaParameter;
  private metaCache?: RoadShapeOpenedMetaInfo;

  private state: LoaderState = 'initialized';

  private processRoadShapeOpenedDataTasks: Map<RoadShapeOpenedMainParameter, () => void> = new Map();

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequestFunc データ読み込み完了通知
   */
  constructor(context: GaiaContext, onFinishRequestFunc: OnFinishRequestFunc) {
    this.cache = new LRUCache(100);
    this.requestQueue = new Queue(150);
    this.requestingTile = new Set<string>();
    this.onFinishRequestFunc = onFinishRequestFunc;
    this.isRequestingMeta = false;

    context
      .getGaIAConfiguration()
      .then((res) => {
        if (!res.server.data) {
          return;
        }
        this.serverUrl = `${res.server.data}v1/${res.productId}`;
        this.setState('ready');
        this.executeRequest();
      })
      .catch(() => {
        // do nothing
      });

    this.requester = new RoadShapeOpenedInfoRequester((param) => {
      return this.createUrl(param);
    });
  }

  /**
   * AnnotationMetaParameter設定
   * @param metaParam AnnotationMetaParameter
   * @returns {void}
   */
  setMetaParameter(metaParam: RoadShapeOpenedMetaParameter): void {
    if (!metaParam.equals(this.currentMetaParam)) {
      this.currentMetaParam = metaParam;
      this.metaCache = undefined;
    }
  }

  /**
   * メタリクエストのURLを生成
   * @returns リクエストURL
   */
  private createMetaUrl(): Optional<string> {
    if (!this.currentMetaParam) {
      return;
    }
    const {tileSize, palette} = this.currentMetaParam;
    const query: QueryParameter = {};
    if (palette && palette.name) {
      query['palette'] = palette.name;
    }
    return `${this.serverUrl}/${PATH_ROADSHAPEOPENED}/${palette.language}/${tileSize}/meta?${buildQueryString(query)}`;
  }

  /**
   * リクエストURLを生成
   * @param param リクエストパラメータ
   * @returns リクエストURL
   */
  private createUrl(param: RoadShapeOpenedMainParameter): string {
    const {x, y, z, tileSize, palette} = param;
    const query: QueryParameter = {};
    if (palette && palette.name) {
      query['palette'] = palette.name;
    }
    return `${this.serverUrl}/${PATH_ROADSHAPEOPENED}/${palette.language}/${tileSize}/${z}/${x}/${y}?${buildQueryString(
      query
    )}`;
  }

  /**
   * Loaderの状態を判定
   * @param state LoaderState
   * @returns 指定された状態のときにtrue
   */
  private isState(state: LoaderState): boolean {
    return this.state === state;
  }

  /**
   * Loaderの状態を設定
   * @param state LoaderState
   * @returns {void}
   */
  private setState(state: LoaderState): void {
    // TODO: 状態遷移を制御するかどうか
    this.state = state;
  }

  /**
   * キャッシュとリクエストを全てクリアする
   * @returns {void}
   */
  clear(): void {
    this.cache.clear();
    this.requestQueue.clear();
    this.requestingTile.clear();
    this.requester.clear();
    this.metaCache = undefined;
  }

  /**
   * リクエストキューをクリア
   * @returns {void}
   */
  clearRequestQueue(): void {
    this.requestQueue.clear();
    this.requestingTile.clear();
    this.requester.clear();
  }

  /**
   * キャッシュにあるデータを返す
   * @param param TileNumber
   * @returns キャッシュ済みデータ
   */
  getCache(param: RoadShapeOpenedMainParameter): Optional<JsonObject> {
    return this.cache.get(param);
  }

  /**
   * 描画に必要なタイルをリクエストキューに追加
   * @param tileList TileNumberリスト
   * @param tileSize タイルサイズ
   * @param palette パレットパラメータ
   * @param date 基準日時
   * @returns {void}
   */
  addRequestQueue(tileList: TileNumber[], tileSize: TileSize, palette: PaletteParameter): void {
    for (const tile of tileList) {
      const param = new RoadShapeOpenedMainParameter(tile.x, tile.y, tile.z, tileSize, palette);

      if (this.cache.has(param)) {
        continue;
      }
      if (!this.checkDataAvailability(tile)) {
        continue;
      }

      this.requestQueue.enqueue(param);
    }
  }

  /**
   * キャッシュがあれば返却し、なければ通信する
   * @returns RoadShapeOpenedMetaInfo
   */
  fetchMetaInfo(): Optional<RoadShapeOpenedMetaInfo> {
    if (!this.metaCache) {
      this.executeMetaRequest();
    }
    return this.metaCache;
  }

  /**
   * メタリクエストを実行
   * @param onMetaLoad メタ読み込み完了通知
   * @returns {void}
   */
  private executeMetaRequest(): void {
    // loaderの初期化が済んでいなければ中止
    if (!this.isState('ready')) {
      return;
    }

    // 既にリクエスト中 or メタパラメータが未設定なら中止
    if (this.isRequestingMeta || !this.currentMetaParam) {
      return;
    }

    // 既にキャッシュがあるなら中止
    if (this.metaCache) {
      return;
    }

    this.isRequestingMeta = true;
    this.requestMetadata();
  }

  /**
   * 通信でのメタリクエスト
   * @param tile TileNumber
   * @returns {void}
   */
  private requestMetadata(): void {
    const url = this.createMetaUrl();
    if (!url) {
      return;
    }
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        this.isRequestingMeta = false;
        this.metaCache = {
          serial: json.serial,
          availability: json.availability,
        };
      });
  }

  /**
   * リクエストを実行
   * @returns {void}
   */
  executeRequest(): void {
    if (!this.isState('ready')) {
      return;
    }

    if (this.requestQueue.size === 0) {
      return;
    }

    while (this.requestQueue.size > 0) {
      const param = this.requestQueue.dequeue();
      if (!param) {
        break;
      }
      if (this.cache.has(param) || this.requestingTile.has(param.getCacheKey())) {
        continue;
      }
      this.requestRoadShapeOpened(param);
    }
  }

  /**
   * 通信でのリクエスト
   * @param param リクエストパラメータ
   * @returns {void}
   */
  private requestRoadShapeOpened(param: RoadShapeOpenedMainParameter): void {
    this.requestingTile.add(param.getCacheKey());

    this.requester.requestRoadShapeOpened(param, (param, encryptedData) => {
      if (this.isState('destroyed') || !this.requestingTile.has(param.getCacheKey())) {
        // リクエスト中に地図が破棄された or リクエスト対象から外れたものはキャッシュしない
        return;
      }

      const info = this.convertToJson(encryptedData);
      if (info) {
        this.processRoadShapeOpenedDataTasks.set(param, () => {
          // 無限リクエスト対策のため、データが不正であってもキャッシュには積んでおく
          this.cache.add(param, info);
          this.requestingTile.delete(param.getCacheKey());
          this.onFinishRequestFunc();
        });
      }
    });
  }

  /**
   * 難読化されているデータをJsonに変換
   * @param str 難読化された新規開通道路データ
   * @returns Json
   */
  private convertToJson(str: string): JsonObject {
    try {
      const reversed = str.split('').reverse().join('');
      const decoded = decodeURIComponent(escape(atob(reversed)));
      return JSON.parse(decoded);
    } catch {
      return {};
    }
  }

  /**
   * キャッシュサイズ上限を拡張
   * @param size 変更後サイズ
   * @returns {void}
   */
  jumpUpCacheSize(size: number): void {
    this.cache.jumpUpSize(size);
  }

  /**
   * フレームレート維持のためあとにまわしていたタスクを実行する
   * @param tileList タイル番号のリスト
   * @returns {void}
   */
  processRoadShapeOpenedData(): void {
    this.processRoadShapeOpenedDataTasks.forEach((task, _tile) => {
      task();
    });
    this.processRoadShapeOpenedDataTasks.clear();
  }

  /**
   * メタ情報と照合して、対象タイルが新規開通道路データを含むか判定する
   * @param tile タイル番号
   * @returns {void}
   */
  private checkDataAvailability(tile: TileNumber): boolean {
    if (!this.metaCache || !this.metaCache.availability) {
      return false;
    }

    try {
      return this.metaCache.availability[tile.z][tile.y].includes(tile.x);
    } catch (e) {
      // 参照エラーならfalse
      return false;
    }
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.clear();
    this.requester.destroy();
    this.setState('destroyed');
  }
}

export {RoadShapeOpenedLoader};
