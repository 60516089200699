import {Easing} from './easing/Easing';

/**
 * アニメーションの設定
 */
class AnimationOption {
  /**
   * アニメーションにかかる秒数
   */
  readonly seconds: number;

  /**
   * イージング
   */
  readonly easing: Easing;

  /**
   * コンストラクタ
   * @param seconds アニメーションにかけるフレーム数
   * @param easing イージングの種類
   */
  constructor(seconds: number, easing: Easing) {
    this.seconds = seconds;
    this.easing = easing;
  }
}

export {AnimationOption};
