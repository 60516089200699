import {mat4} from 'gl-matrix';
import {AltitudeCondition, LatLng} from '../../../gaia/value';
import {Quaternion} from '../../common/math/Quaternion';
import {Ray3} from '../../common/math/Ray3';
import {Vector2} from '../../common/math/Vector2';
import {Vector3} from '../../common/math/Vector3';
import {Camera} from '../../engine/camera/Camera';
import {Collision} from '../../engine/collision/Collision';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {Layer} from '../../engine/layer/Layer';
import {AltitudeMaterial} from '../../engine/material/AltitudeMaterial';
import {DepthMaterial} from '../../engine/material/DepthMaterial';
import {Object3D} from '../../engine/object/Object3D';
import {AltitudeProgram, FRAME_BUFFER_SIDE} from '../../engine/program/AltitudeProgram';
import {DepthProgram} from '../../engine/program/DepthProgram';
import {GaiaContext} from '../GaiaContext';
import {MapStatus} from '../models/MapStatus';
import {calculatePixelCoordinate, calculatePixelToUnit, pixelToLatLng} from '../utils/MapUtil';

const LAYER_NAME_ALTITUDE = 'altitude';

/**
 * 標高曲面描画レイヤー
 */
class AltitudeObjectLayer implements Layer {
  private readonly context: GaiaContext;
  private mapStatus: MapStatus;

  private altitudeGeometry: CustomGeometry;
  private altitudePosition: Vector3;
  private altitudeScale: Vector3;

  private altitudeMaterial: AltitudeMaterial;
  private altitudeObject: Object3D;

  private depthMaterial: DepthMaterial;
  private depthObject: Object3D;

  private getAltitudeCallback: (latLng: LatLng) => number;
  private condition?: AltitudeCondition;

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.context = context;
    this.mapStatus = context.getMapStatus();
    this.getAltitudeCallback = (): number => 0;

    const width = 1.0;
    const height = 1.0;
    const xNumber = 100;
    const yNumber = 100;
    const vertices: number[] = [];
    const indices: number[] = [];
    const maxX = width / 2.0;
    const minX = -width / 2.0;
    const maxY = height / 2.0;
    const minY = -height / 2.0;
    for (let yIndex = 0; yIndex < yNumber; yIndex++) {
      const yRatio = (1.0 * yIndex) / yNumber;
      const y = minY + yRatio * (maxY - minY);
      for (let xIndex = 0; xIndex < xNumber; xIndex++) {
        const xRatio = (1.0 * xIndex) / xNumber;
        const xSlide = yIndex % 2 === 0 ? 0.0 : width / (xNumber * 2.0);
        const x = minX + xRatio * (maxX - minX) + xSlide;
        const z = 0;
        const u = xRatio + (yIndex % 2 === 0 ? 0.0 : 0.5 / xNumber);
        const v = yRatio;

        vertices.push(x, y, z, u, v);

        if (yIndex === 0) {
          continue;
        }
        const current = yIndex * xNumber + xIndex;
        const up = (yIndex - 1) * xNumber + xIndex;
        const upRight = up + 1;
        const upLeft = up - 1;
        const left = current - 1;
        const right = current + 1;
        if (yIndex % 2 !== 0) {
          if (xIndex % 2 === 0) {
            indices.push(up, upRight, current);
            if (xIndex !== 0) {
              indices.push(left, up, current);
            }
          } else {
            indices.push(left, up, current);
            if (xIndex !== xNumber - 1) {
              indices.push(up, upRight, current);
            }
          }
        } else {
          if (xIndex % 2 === 0) {
            indices.push(up, right, current);
            if (xIndex !== 0) {
              indices.push(upLeft, up, current);
            }
          } else {
            indices.push(upLeft, up, current);
            if (xIndex !== xNumber - 1) {
              indices.push(up, right, current);
            }
          }
        }
      }
    }
    const geometry = new CustomGeometry(vertices, indices);
    this.altitudeGeometry = geometry;
    this.altitudePosition = Vector3.zero();
    this.altitudeScale = Vector3.one();

    this.altitudeMaterial = new AltitudeMaterial(context.getGLContext(), geometry);
    this.altitudeObject = new Object3D(
      this.altitudePosition,
      Quaternion.identity(),
      this.altitudeScale,
      this.altitudeMaterial
    );

    this.depthMaterial = new DepthMaterial(context.getGLContext(), geometry);
    this.depthObject = new Object3D(
      this.altitudePosition,
      Quaternion.identity(),
      this.altitudeScale,
      this.depthMaterial
    );
  }

  /**
   * 表示状態を設定
   * @param condition 表示状態
   * @returns {void}
   */
  setCondition(condition?: AltitudeCondition): void {
    this.condition = condition;
  }

  /**
   * 標高取得コールバックを設定
   * @param getAltitudeCallback 標高取得コールバック
   * @returns {void}
   */
  setAltitudeCallback(getAltitudeCallback: (latLng: LatLng) => number): void {
    this.getAltitudeCallback = getAltitudeCallback;
  }

  /**
   * 更新
   * @param mapStatus 地図状態
   * @param _camera カメラ
   * @returns {void}
   */
  update(mapStatus: MapStatus, _camera: Camera): void {
    this.mapStatus = mapStatus;
    const zoomLevel = mapStatus.zoomLevel;

    const zoomRemainder = 1.0 + (zoomLevel % 1.0);
    const ptu = calculatePixelToUnit(zoomLevel);
    const scaleValue = FRAME_BUFFER_SIDE * ptu;
    const centerPixel = calculatePixelCoordinate(mapStatus.centerLocation, zoomLevel);
    const centerUnit = centerPixel._multiply(-ptu);
    const x = centerUnit.x % ((scaleValue * zoomRemainder) / 100.0);
    const y = -centerUnit.y % ((scaleValue * zoomRemainder) / 100.0);
    this.altitudePosition.setValues(x, y, 0.0);

    const xOffset = x / scaleValue;
    const yOffset = y / scaleValue;
    this.altitudeMaterial.setUvOffset(new Vector2(xOffset, yOffset));
    this.altitudeMaterial.setZoomRemainder(zoomRemainder);

    const halfSide = FRAME_BUFFER_SIDE / 2;
    const diff = new Vector2(halfSide, halfSide);
    const bottomLeft = centerPixel._subtract(diff);
    const topRight = centerPixel._add(diff);
    const southWest = pixelToLatLng(bottomLeft, mapStatus.zoomLevel);
    const northEast = pixelToLatLng(topRight, mapStatus.zoomLevel);

    const vertices = this.altitudeGeometry.getVertices();
    const verticesLength = vertices.length;
    for (let index = 0; index < verticesLength; index += 5) {
      const ratio = 1.0 / zoomRemainder;
      const margin = (1.0 - ratio) / 2.0;
      const u = (vertices[index + 3] - margin) * zoomRemainder + xOffset;
      const v = (vertices[index + 4] - margin) * zoomRemainder + yOffset;
      const lat = southWest.lat + (1.0 - v) * (northEast.lat - southWest.lat);
      const lng = southWest.lng + u * (northEast.lng - southWest.lng);
      const latLng = new LatLng(lat, lng);
      const altitude = this.getAltitudeCallback(latLng);
      const z = altitude;
      this.altitudeGeometry.setVerticesValue(z, index + 2);
    }

    this.altitudeMaterial.setGeometry(this.altitudeGeometry);
    this.depthMaterial.setGeometry(this.altitudeGeometry);
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_ALTITUDE;
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (!this.condition) {
      return true;
    }
    if (!AltitudeProgram.targetTexture) {
      return true;
    }

    const zoomLevel = this.mapStatus.zoomLevel;
    const zoomRemainder = 1.0 + (zoomLevel % 1.0);
    const ptu = calculatePixelToUnit(zoomLevel);
    const scaleValue = FRAME_BUFFER_SIDE * ptu;
    this.altitudeScale.setValues(scaleValue * zoomRemainder, scaleValue * zoomRemainder, 1.0);

    this.altitudeMaterial.setGLTexture(AltitudeProgram.targetTexture);
    this.altitudeObject.setPosition(this.altitudePosition);
    this.altitudeObject.setScale(this.altitudeScale);
    this.altitudeObject.update(viewMatrix, projectionMatrix);
    this.altitudeObject.draw(MapStatus.defaultRenderTarget);

    if (DepthProgram.renderTarget) {
      this.depthObject.setPosition(this.altitudePosition);
      this.depthObject.setScale(this.altitudeScale);
      this.depthObject.update(viewMatrix, projectionMatrix);
      this.depthObject.draw(DepthProgram.renderTarget);
    }

    return true;
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.altitudeObject.destroy();
    this.depthObject.destroy();
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    // TODO: 実装
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {AltitudeObjectLayer, LAYER_NAME_ALTITUDE};
