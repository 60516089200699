import {LatLng, Color} from '../../../../gaia/value';
import {GeoJsonProperties} from '../../../../gaia/value/GeoJson';

type FigureType = 'Polyline' | 'Polygon';

/** 図形の見た目情報 */
type GeoJsonFigureStyle = {
  color: Color;
  weight: number;
  dashArray: number[];
  outLineColor: Color;
  outLineWeight: number;
  outLineDashArray: number[];
};

/**
 * GeoJSONから作成した形状クラス
 */
class GeoJsonFigureInfo {
  readonly path: LatLng[];
  readonly figureType: FigureType;
  readonly properties: GeoJsonProperties;
  readonly zIndex?: number;
  private _style: GeoJsonFigureStyle;

  /**
   * コンストラクタ
   * @param path パス
   * @param figureType 図形種別
   * @param properties propertiesフィールドの内容
   * @param style 見た目情報
   * @param zIndex zIndex
   */
  constructor(
    path: LatLng[],
    figureType: FigureType,
    properties: GeoJsonProperties,
    style: GeoJsonFigureStyle,
    zIndex?: number
  ) {
    this.path = path;
    this.figureType = figureType;
    this.properties = properties;
    this.zIndex = zIndex;
    this._style = style;
  }

  /**
   * 見た目の情報取得
   */
  get style(): GeoJsonFigureStyle {
    return this._style;
  }

  /**
   * 見た目を設定
   * @param style 見た目の情報
   * @returns {void}
   */
  setStyle(style: GeoJsonFigureStyle): void {
    this._style = style;
  }
}

export {GeoJsonFigureInfo, FigureType, GeoJsonFigureStyle};
