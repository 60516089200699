import {JsonObject} from '../../../../gaia/types';

export type AnnotationMainDBData = {
  date: number;
  mainInfo: JsonObject;
};

export type AnnotationIconDBData = {
  date: number;
  mainInfo: Blob;
};

export type AltitudeMainDBData = {
  date: number;
  mainInfo: Uint8Array;
};

export type MainDBData = AnnotationMainDBData | AnnotationIconDBData | AltitudeMainDBData;

export type DBActionType = 'insert' | 'get' | 'all_delete';

export type DBRequest = {
  id: string;
  actionType: DBActionType;
  dbName: string;
  storeName: string;
  key?: string;
  data?: JsonObject;
  recordMax?: number;
};

export type DBResponse = {
  id: string;
  actionType: DBActionType;
  isSucceeded: boolean;
  key?: string;
  data?: JsonObject;
};

/**
 * indexedDBを開く
 * @param dbName 対象DB名
 * @param onupgradeneeded onupgradeneeded時に実行する関数
 * @returns IDBDatabase
 */
export const idbOpen = (
  dbName: string,
  onupgradeneeded?: (db: IDBDatabase) => void
): Promise<IDBDatabase | undefined> => {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName);
    openRequest.onupgradeneeded = (ev): void => {
      if (ev.target instanceof IDBOpenDBRequest) {
        onupgradeneeded?.(ev.target.result);
      }
    };
    openRequest.onsuccess = (ev): void => {
      if (ev.target instanceof IDBOpenDBRequest) {
        return resolve(ev.target.result);
      }
      resolve(undefined);
    };
    openRequest.onerror = reject;
  });
};

/**
 * オブジェクトストアへの保存
 * @param store 対象オブジェクトストア
 * @param data データ
 * @param key キー
 * @returns {void}
 */
export const idbPut = (
  store: IDBObjectStore,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  key?: IDBValidKey
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const putRequest = store.put(data, key);
    putRequest.onsuccess = (): void => resolve();
    putRequest.onerror = reject;
  });
};

/**
 * オブジェクトストアからのデータ取得
 * @param store 対象オブジェクトストア
 * @param key キー
 * @returns 取得したデータ
 */
export const idbGet = (
  store: IDBObjectStore,
  key: IDBValidKey | IDBKeyRange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const getRequest = store.get(key);
    getRequest.onsuccess = (ev): void => {
      if (ev.target instanceof IDBRequest) {
        return resolve(ev.target.result);
      }
      return resolve(undefined);
    };
    getRequest.onerror = reject;
  });
};

/**
 * オブジェクトストアの全データ削除
 * @param store 対象オブジェクトストア
 * @returns {void}
 */
export const idbClear = (store: IDBObjectStore): Promise<void> => {
  return new Promise((resolve, reject) => {
    const clearRequest = store.clear();
    clearRequest.onsuccess = (): void => resolve();
    clearRequest.onerror = reject;
  });
};

/**
 * オブジェクトストアのレコード数取得
 * @param store 対象オブジェクトストア
 * @returns レコード数
 */
export const idbStoreCount = (store: IDBObjectStore): Promise<number | undefined> => {
  return new Promise((resolve, reject) => {
    const countRequest = store.count();
    countRequest.onsuccess = (ev): void => {
      if (ev.target instanceof IDBRequest && typeof ev.target.result === 'number') {
        return resolve(ev.target.result);
      }
      resolve(undefined);
    };
    countRequest.onerror = reject;
  });
};

/**
 * インデックスのcursor取得
 * @param index 対象インデックス
 * @returns IDBCursor
 */
export const idbIndexOpenCursor = (index: IDBIndex): Promise<IDBCursor | undefined> => {
  return new Promise((resolve, reject) => {
    const openCursorRequest = index.openCursor();
    openCursorRequest.onsuccess = (ev): void => {
      if (ev.target instanceof IDBRequest && ev.target.result instanceof IDBCursor) {
        return resolve(ev.target.result);
      }
      resolve(undefined);
    };
    openCursorRequest.onerror = reject;
  });
};

/**
 * レコードを指定数分削除
 * @param index 対象インデックス
 * @param deleteNum 削除数
 * @returns {void}
 */
export const deleteRecords = (index: IDBIndex, deleteNum: number): Promise<void> => {
  let counter = 0;
  return new Promise((resolve, reject) => {
    const openCursorRequest = index.openCursor();
    openCursorRequest.onsuccess = (ev): void => {
      if (!(ev.target instanceof IDBRequest && ev.target.result instanceof IDBCursor)) {
        return resolve(undefined);
      }

      const cursor = ev.target.result;
      if (!cursor || counter >= deleteNum) {
        return resolve();
      }
      cursor.delete();
      counter++;
      cursor.continue();
    };

    openCursorRequest.onerror = (e): void => {
      reject(e);
    };
  });
};

/**
 * IndexedDBへのデータ保存リクエスト
 * @param ev MessageEvent
 * @returns {void}
 */
export const dbInsertRequest = (ev: MessageEvent): void => {
  const {id, actionType, dbName, storeName, recordMax, key, data} = ev.data as DBRequest;
  const message: DBResponse = {id, actionType, isSucceeded: false, key};

  if (!recordMax || !key || !data) {
    ((self as unknown) as Worker).postMessage(message);
    return;
  }

  let store: IDBObjectStore | undefined = undefined;
  let isSucceeded = true;
  idbOpen(dbName)
    .then((db) => {
      if (!db) {
        isSucceeded = false;
        return;
      }

      store = db.transaction(storeName, 'readwrite').objectStore(storeName);
      return idbStoreCount(store);
    })
    .then((recordNum) => {
      if (!store || !recordNum) {
        isSucceeded = false;
        return;
      }

      // storeにdate indexがある場合のみ超過分の削除を行う
      if (store.indexNames.contains('date')) {
        const deleteNum = recordNum + 1 - recordMax;
        if (deleteNum > 0) {
          return deleteRecords(store.index('date'), deleteNum);
        }
      }
      return;
    })
    .then(() => {
      if (!store) {
        isSucceeded = false;
        return;
      }
      return idbPut(store, data, key);
    })
    .then(() => {
      message.isSucceeded = isSucceeded;
      ((self as unknown) as Worker).postMessage(message);
    })
    .catch(() => {
      ((self as unknown) as Worker).postMessage(message);
    });
};

/**
 * IndexedDBへのデータ取得リクエスト
 * @param ev MessageEvent
 * @returns {void}
 */
export const dbGetRequest = (ev: MessageEvent): void => {
  const {id, actionType, dbName, storeName, key} = ev.data as DBRequest;
  const message: DBResponse = {id, actionType, isSucceeded: false, key};

  if (!key) {
    ((self as unknown) as Worker).postMessage(message);
    return;
  }

  let store: IDBObjectStore | undefined = undefined;
  let isSucceeded = true;
  idbOpen(dbName)
    .then((db) => {
      if (!db) {
        isSucceeded = false;
        return;
      }
      store = db.transaction(storeName, 'readwrite').objectStore(storeName);
      return idbGet(store, key);
    })
    .then((data) => {
      if (!store || !isSucceeded) {
        ((self as unknown) as Worker).postMessage(message);
        return;
      }

      message.isSucceeded = true;
      message.data = data;
      if (store.indexNames.contains('date') && data) {
        // 最終アクセス日時を更新
        const newMainData: MainDBData = {date: Date.now(), mainInfo: data.mainInfo};
        idbPut(store, newMainData, key);
      }
      ((self as unknown) as Worker).postMessage(message);
    })
    .catch(() => {
      ((self as unknown) as Worker).postMessage(message);
    });
};

/**
 * IndexedDBへの全データ削除リクエスト
 * @param ev MessageEvent
 * @returns {void}
 */
export const dbAllDeleteRequest = (ev: MessageEvent): void => {
  const {id, actionType, dbName, storeName} = ev.data as DBRequest;
  const message: DBResponse = {id, actionType, isSucceeded: false};

  let isSucceeded = true;
  idbOpen(dbName)
    .then((db) => {
      if (!db) {
        isSucceeded = false;
        return;
      }

      return idbClear(db.transaction(storeName, 'readwrite').objectStore(storeName));
    })
    .then(() => {
      message.isSucceeded = isSucceeded;
      ((self as unknown) as Worker).postMessage(message);
      return;
    })
    .catch(() => {
      ((self as unknown) as Worker).postMessage(message);
    });
};

/**
 * IndexedDBへのリクエスト
 * @param ev MessageEvent
 * @returns {void}
 */
export const dbRequest = (ev: MessageEvent): void => {
  switch ((ev.data as DBRequest).actionType) {
    case 'insert':
      dbInsertRequest(ev);
      return;
    case 'get':
      dbGetRequest(ev);
      return;
    case 'all_delete':
      dbAllDeleteRequest(ev);
      return;
    default:
      return;
  }
};
