import {GaiaContext} from '../GaiaContext';
import {AbstractTileLoader, OnFinishRequestFunc} from './AbstractTileLoader';
import {SnowfallTileParameter} from './param/SnowfallTileParameter';

/**
 * 積雪深ナウキャスト情報タイルローダー
 */
class SnowfallTileLoader extends AbstractTileLoader<SnowfallTileParameter> {
  private serverUrl = '';
  private productId = '';

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );

    context
      .getGaIAConfiguration()
      .then((res) => {
        this.serverUrl = `${res.server.tile}map/${res.productId}`;
        this.productId = res.productId;
        this.setState('ready');
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * タイルのURLを生成
   * @param tile SnowfallTileParameter
   * @returns リクエストURL
   */
  private createUrl(tile: SnowfallTileParameter): string {
    const {x, y, z, tileSize} = tile;
    const urlBase = tile.condition.server ? `${tile.condition.server}map/${this.productId}` : this.serverUrl;

    return `${urlBase}/tile/snowfall/${tileSize}/${z}/${y}/${x}?product=web&snowfall-offsettime=${tile.condition.offset}`;
  }
}

export {SnowfallTileLoader};
