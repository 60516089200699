import {LatLng} from '../value';
import {findMaxValue, findMinValue} from '../../_private/common/math/MathUtil';
import {Optional} from '../../_private/common/types';
import {GaIAError} from '../value/GaIAError';

/**
 * 地図上の範囲情報クラス
 */
class LatLngRect {
  /** 左上の緯度経度 */
  readonly topLeft: LatLng;
  /** 右上の緯度経度 */
  readonly topRight: LatLng;
  /** 左下の緯度経度 */
  readonly bottomLeft: LatLng;
  /** 右下の緯度経度 */
  readonly bottomRight: LatLng;
  /** 範囲の中心緯度経度 */
  readonly center: LatLng;

  /**
   * コンストラクタ
   * @param topLeft 左上の緯度経度
   * @param bottomRight 右下の緯度経度
   */
  constructor(topLeft: LatLng, bottomRight: LatLng) {
    if (topLeft.lat < bottomRight.lat) {
      throw new GaIAError(`bottomRight.lat is higher than topLeft.lat`);
    }

    if (topLeft.lng > bottomRight.lng) {
      throw new GaIAError(`topLeft.lng is higher than bottomRight.lng`);
    }

    this.topLeft = topLeft;
    this.bottomRight = bottomRight;
    this.topRight = new LatLng(topLeft.lat, bottomRight.lng);
    this.bottomLeft = new LatLng(bottomRight.lat, topLeft.lng);

    this.center = new LatLng((topLeft.lat + bottomRight.lat) / 2, (topLeft.lng + bottomRight.lng) / 2);
  }
}

/**
 * 指定された緯度経度が全て入る矩形作成
 * @param locationList 緯度経度配列
 * @param centerLocation 中心緯度経度
 * @returns LatLngRect ※locationsListが空の場合はnull
 */
const locationsToLatLngRect = (locationList: LatLng[], centerLocation?: LatLng): Optional<LatLngRect> => {
  if (locationList.length === 0) {
    return null;
  }

  const latList: number[] = [];
  const lngList: number[] = [];

  for (const location of locationList) {
    latList.push(location.lat);
    lngList.push(location.lng);
  }

  const maxLat = findMaxValue(latList);
  const minLat = findMinValue(latList);
  const maxLng = findMaxValue(lngList);
  const minLng = findMinValue(lngList);

  let topLeft = new LatLng(maxLat, minLng);
  let bottomRight = new LatLng(minLat, maxLng);

  if (centerLocation) {
    // 中心からの絶対値の最大を算出する
    const maxLatAbs = Math.abs(centerLocation.lat - maxLat);
    const minLatAbs = Math.abs(centerLocation.lat - minLat);
    const distLat = maxLatAbs > minLatAbs ? maxLatAbs : minLatAbs;

    const maxLngAbs = Math.abs(centerLocation.lng - maxLng);
    const minLngAbs = Math.abs(centerLocation.lng - minLng);
    const distLng = maxLngAbs > minLngAbs ? maxLngAbs : minLngAbs;

    topLeft = new LatLng(centerLocation.lat + distLat, centerLocation.lng - distLng);
    bottomRight = new LatLng(centerLocation.lat - distLat, centerLocation.lng + distLng);
  }

  return new LatLngRect(topLeft, bottomRight);
};

export {LatLngRect, locationsToLatLngRect};
