import {AbstractTileLoader, OnFinishRequestFunc} from './AbstractTileLoader';
import {TileParameter} from '../models/TileParameter';
import {GaiaContext} from '../GaiaContext';
import {QueryParameter} from '../../common/types';
import {buildQueryString} from '../../common/util/URL';

/**
 * 注記タイルローダー
 */
class SatelliteAnnotationLoader extends AbstractTileLoader<TileParameter> {
  private serverUrl = '';

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequest 通信完了通知
   * @param lang 言語
   */
  constructor(context: GaiaContext, onFinishRequest: OnFinishRequestFunc) {
    super(
      context,
      (tile) => {
        return this.createUrl(tile);
      },
      onFinishRequest
    );

    context
      .getGaIAConfiguration()
      .then((res) => {
        this.serverUrl = `${res.server.tile}map/${res.productId}`;
        this.setState('ready');
        super.executeRequest();
      })
      .catch(() => {
        // do nothing
      });
  }
  /**
   * タイルのURLを生成
   * @param tile TileNumber
   * @returns リクエストURL
   */
  private createUrl(tile: TileParameter): string {
    const {x, y, z, tileType, tileSize, palette} = tile;
    const query: QueryParameter = {
      product: 'web',
      lang: palette.language,
    };
    if (palette.name) {
      query['palette-name'] = palette.name;
    }
    return `${this.serverUrl}/${tileType}/annotation/${tileSize}/${z}/${y}/${x}?${buildQueryString(query)}`;
  }
}

export {SatelliteAnnotationLoader};
