import {PaletteColorInfo, PaletteFontInfo, PaletteNoteType} from '../../../common/infra/response/AnnotationInfo';
import {CacheKey} from '../../../common/types';

/**
 * テキスト注記テクスチャ作成用データ
 */
class TextAnnotationTextureData implements CacheKey {
  readonly appearance: string;
  readonly isHorizon: boolean;
  readonly size: number;
  readonly color: PaletteColorInfo;
  readonly backgroundColor: PaletteColorInfo;
  readonly font: PaletteFontInfo;
  readonly noteType: PaletteNoteType;
  readonly ntjCode: number;
  readonly zoom: number;

  readonly cacheKey: string;

  /**
   * コンストラクタ
   * @param appearance 表示テキスト
   * @param isHorizon 横書きフラグ
   * @param size テキストサイズ
   * @param color テキストの色
   * @param backgroundColor 背景色
   * @param font フォント
   * @param noteType 描画タイプ
   * @param ntjCode NTJコード
   * @param zoom ズームレベル
   */
  constructor(
    appearance: string,
    isHorizon: boolean,
    size: number,
    color: PaletteColorInfo,
    backgroundColor: PaletteColorInfo,
    font: PaletteFontInfo,
    noteType: PaletteNoteType,
    ntjCode: number,
    zoom: number
  ) {
    this.appearance = appearance;
    this.isHorizon = isHorizon;
    this.size = size;
    this.color = color;
    this.backgroundColor = backgroundColor;
    this.font = font;
    this.noteType = noteType;
    this.ntjCode = ntjCode;
    this.zoom = zoom;

    this.cacheKey = `TT(${appearance}, ${zoom}, ${ntjCode})`;
  }

  /** @override */
  getCacheKey(): string {
    return this.cacheKey;
  }

  /**
   * 同一判定
   * @param other TextAnnotationTextureData
   * @returns `true` なら同一
   */
  equals(other: TextAnnotationTextureData): boolean {
    return (
      other.appearance === this.appearance &&
      other.isHorizon === this.isHorizon &&
      other.size === this.size &&
      other.color === this.color &&
      other.backgroundColor === this.backgroundColor &&
      other.font === this.font &&
      other.noteType === this.noteType &&
      other.ntjCode === this.ntjCode &&
      other.zoom === this.zoom
    );
  }
}

export {TextAnnotationTextureData};
