import {TileNumber} from '../../models/TileNumber';
import {TileSize} from '../../../../gaia/types';
import {SnowfallCondition} from '../../../../gaia/value/SnowfallCondition';

/**
 * 積雪深情報タイルのパラメータクラス
 */
class SnowfallTileParameter extends TileNumber {
  /** タイルサイズ */
  readonly tileSize: TileSize;
  /** コンディション */
  readonly condition: SnowfallCondition;

  private readonly _cacheKey: string;

  /**
   * コンストラクタ
   * @param x タイルX番号
   * @param y タイルY番号
   * @param z タイルZ番号
   * @param condition リクエストオプション
   * @param tileSize タイル画像サイズ
   */
  constructor(x: number, y: number, z: number, condition: SnowfallCondition, tileSize: TileSize = 256) {
    super(x, y, z);
    this.tileSize = tileSize;
    this.condition = condition;

    this._cacheKey = `snowfall(${this.x},${this.y},${this.z},${this.condition.offset})`;
  }

  /** @override */
  getCacheKey(): string {
    return this._cacheKey;
  }
}

export {SnowfallTileParameter};
