import {DOMObject} from '../../../../gaia/object/DOMObject';
import {Color, LatLng, Point, ZoomRange} from '../../../../gaia/value';

/**
 * ルート線矢印用SVGを作成
 * @param color ルート描画色
 * @returns 矢印SVG
 */
const createArrowSVG = (color: Color): string =>
  `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30px" height="30px" viewBox="0 0 30 30"><defs/>
<g>
<ellipse cx="15" cy="15" rx="13" ry="13" fill="${color.toHexString()}" stroke="#ffffff" stroke-width="2" pointer-events="all"/>
<path d="M 15 22 L 15 8" fill="none" stroke="#ffffff" stroke-width="3" stroke-miterlimit="10" pointer-events="stroke" stroke-linecap="round" />
<path d="M 8 15 L 15 8 L 22 15" fill="none" stroke="#ffffff" stroke-width="3" stroke-miterlimit="10" pointer-events="all" stroke-linecap="round" />
</g>
</svg>`;

/**
 * ルート線矢印オブジェクト
 */
class RouteArrow extends DOMObject {
  /** 方位(北を0として時計回り、0 - 359) */
  private readonly direction: number;

  /**
   * コンストラクタ
   * @param position 表示位置
   * @param direction 方向
   * @param color 色
   * @param zoomRange 表示ズームレベル
   */
  constructor(position: LatLng, direction: number, color: Color, zoomRange: ZoomRange) {
    super(position, {
      content: createArrowSVG(color),
      zoomRange,
    });
    this.direction = direction;
  }

  /** @override */
  protected getOffsetX(): number {
    return -15;
  }

  /** @override */
  protected getOffsetY(): number {
    return -15;
  }

  /** @override */
  updateDrawPosition(point?: Point, zoom?: number): void {
    super.updateDrawPosition(point, zoom);
    this.getWrapElement().style.transform = `${this.getWrapElement().style.transform} rotate(${this.direction}deg)`;
  }
}

export {RouteArrow};
