import {mat4} from 'gl-matrix';
import {CustomGeometry} from '../geometry/CustomGeometry';
import {Object3D} from '../object/Object3D';
import {PointSpriteProgram} from '../program/PointSpriteProgram';
import {Material} from './Material';

/**
 * ポイントスプライト用のマテリアル
 */
class PointSpriteMaterial implements Material {
  private readonly context: WebGLRenderingContext;
  readonly program: PointSpriteProgram;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   */
  constructor(context: WebGLRenderingContext, geometry: CustomGeometry) {
    this.context = context;
    this.program = new PointSpriteProgram(context, geometry);
  }

  /**
   * 更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    this.program.update(object3D, viewMatrix, projectionMatrix);
  }

  /**
   * 描画
   * @returns {void}
   */
  draw(): void {
    this.program.draw();
  }

  /**
   * テクスチャを更新
   * @param image テクスチャ
   * @returns {void}
   */
  setTexture(image: TexImageSource): void {
    this.program.setTexture(image);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: CustomGeometry): void {
    this.program.setGeometry(geometry);
  }

  /**
   * 点の大きさを指定（単位はピクセル）
   * @param pointSize 点の大きさ
   * @returns {void}
   */
  setPointSize(pointSize: number): void {
    this.program.setPointSize(pointSize);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.program.destroy();
  }
}

export {PointSpriteMaterial};
