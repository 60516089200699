import {ExternalAnnotationCallback, ExternalAnnotationInitOptions} from '../types';
import {Size} from './Size';
import {ZoomRange} from './ZoomRange';

/**
 * 社外由来注記コンディション
 */
class ExternalAnnotationCondition {
  /** 表示ズームレベル範囲 */
  readonly zoomRange: ZoomRange;
  /**広告アイコンサイズ */
  readonly iconSize?: Size;
  /**広告アイコン衝突判定補正値 (全てのアイコン同士) */
  readonly collisionMarginForAllIcon?: number;
  /**広告アイコン衝突判定補正値 (同じ見た目のアイコン同士) */
  readonly collisionMarginForSameIcon?: number;
  /** 社外由来注記のセットアップ用コールバック */
  readonly callback?: ExternalAnnotationCallback;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param callback 社外由来注記コールバック
   */
  constructor(options: ExternalAnnotationInitOptions, callback?: ExternalAnnotationCallback) {
    this.zoomRange = options.zoomRange ?? new ZoomRange();
    this.iconSize = options.iconSize;
    this.collisionMarginForAllIcon = options.collisionMarginForAllIcon;
    this.collisionMarginForSameIcon = options.collisionMarginForSameIcon;
    this.callback = callback;
  }
}

export {ExternalAnnotationCondition};
