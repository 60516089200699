import {Layer} from '../../engine/layer/Layer';
import {Object3D} from '../../engine/object/Object3D';
import {SingleColorMaterial} from '../../engine/material/SingleColorMaterial';
import {GaiaContext} from '../GaiaContext';
import {MapStatus} from '../models/MapStatus';
import {LatLng} from '../../../gaia/value';
import {calculateWorldCoordinate} from '../utils/MapUtil';
import {Color} from '../../../gaia/value/Color';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {ZERO_VECTOR, VECTOR_ONES} from '../../common/math/Vector3';
import {QUATERNION_IDENTITY} from '../../common/math/Quaternion';
import {CongestionInfo} from '../../../gaia/value/CongestionInfo';
import {Optional} from '../../common/types';
import {transTokyoToWGS, transMillisecToDegree} from '../../../gaia/util';
import {CongestionLevel} from '../../../gaia/types';
import {mat4} from 'gl-matrix';
import {findMaxValue, findMinValue} from '../../common/math/MathUtil';
import {Collision} from '../../engine/collision/Collision';
import {Ray3} from '../../common/math/Ray3';

const MAX_CONGESTION_LEVEL = 9;

const DEFAULT_COLOR_TABLE = new Map([
  [0, new Color(0, 0, 0, 0)],
  [1, new Color(0.85, 0.94, 0.99, 0.75)],
  [2, new Color(0.74, 0.9, 0.98, 0.75)],
  [3, new Color(0.97, 0.9, 0.4, 0.75)],
  [4, new Color(0.96, 0.77, 0.3, 0.75)],
  [5, new Color(0.94, 0.63, 0.25, 0.75)],
  [6, new Color(0.92, 0.49, 0.22, 0.75)],
  [7, new Color(0.9, 0.29, 0.2, 0.75)],
  [8, new Color(0.89, 0.15, 0.16, 0.75)],
  [9, new Color(0.78, 0.09, 0.09, 0.75)],
]);

/**
 * 混雑度情報レイヤー
 */
class CongestionLayer implements Layer {
  objects: Object3D[];
  private readonly context: GaiaContext;

  private previousCongestionInfo: Optional<CongestionInfo>;
  private colorTable: Map<number, Color>;
  private bottomLeftLatlng: LatLng;

  private congestionMatrix: number[][];
  private materials: SingleColorMaterial[];

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.objects = [];
    this.context = context;

    this.colorTable = DEFAULT_COLOR_TABLE;
    this.bottomLeftLatlng = new LatLng(0, 0);

    this.congestionMatrix = [];
    this.materials = [];
  }

  /** @override */
  getIdenticalLayerName(): string {
    return 'congestion';
  }

  /**
   * 混雑度情報を設定
   * @param congestionInfo 混雑度情報
   * @param colorTable 配色
   * @returns {void}
   */
  setCongestionInfo(congestionInfo: CongestionInfo, colorTable?: {[key in CongestionLevel]: Color}): void {
    if (colorTable) {
      for (const [key, color] of Object.entries(colorTable)) {
        this.colorTable.set(parseInt(key, 10), color);
      }
    }
    this.createCongestionObject(congestionInfo);
  }

  /**
   * 混雑度情報をクリア
   * @returns {void}
   */
  clearCongestionInfo(): void {
    this.objects = [];
    this.materials = [];
    this.congestionMatrix = [];
    this.previousCongestionInfo = null;
  }

  /**
   * 混雑度ヒートマップを生成
   * @param congestionInfo 混雑度情報
   * @returns {void}
   */
  private createCongestionObject(congestionInfo: CongestionInfo): void {
    if (this.previousCongestionInfo && congestionInfo.equals(this.previousCongestionInfo)) {
      return;
    }
    this.previousCongestionInfo = congestionInfo;

    const gridWidth = congestionInfo.gridSize.width;
    const gridHeight = congestionInfo.gridSize.height;

    const congestionArray = congestionInfo.congestionArray;
    const xArray: number[] = [];
    const yArray: number[] = [];
    for (const congestion of congestionArray) {
      xArray.push(congestion.position.x);
      yArray.push(congestion.position.y);
    }

    const xMax = findMaxValue(xArray);
    const xMin = findMinValue(xArray);
    const yMax = findMaxValue(yArray);
    const yMin = findMinValue(yArray);

    this.bottomLeftLatlng = transTokyoToWGS(
      new LatLng(transMillisecToDegree(yMin * gridHeight), transMillisecToDegree(xMin * gridWidth))
    );

    // congestionMatrixに値を詰める
    this.congestionMatrix = [];
    const maxRows = yMax - yMin + 1;
    const maxCols = xMax - xMin + 1;
    const emptyRow = Array(maxCols).fill(0);
    for (let i = 0; i < maxRows; i++) {
      this.congestionMatrix.push(emptyRow.concat());
    }
    for (let i = 0, arrayLength = congestionArray.length; i < arrayLength; i++) {
      const xIndex = xArray[i] - xMin;
      const yIndex = yArray[i] - yMin;
      this.congestionMatrix[yIndex][xIndex] = congestionArray[i].level;
    }

    // object生成
    const gl = this.context.getGLContext();
    for (let congestionLevel = 1; congestionLevel <= MAX_CONGESTION_LEVEL; congestionLevel++) {
      const geometry = this.createGeometry(congestionLevel, xMin, yMin, gridWidth, gridHeight);

      if (this.materials[congestionLevel - 1]) {
        this.materials[congestionLevel - 1].setGeometry(geometry);
        continue;
      }

      const material = new SingleColorMaterial(gl, geometry, this.colorTable.get(congestionLevel) ?? Color.black());
      this.materials.push(material);
      const obj = new Object3D(ZERO_VECTOR, QUATERNION_IDENTITY, VECTOR_ONES, material);
      this.objects.push(obj);
    }
  }

  /**
   * congestionMatrixをもとにCustomGeometry作成
   * @param congestionLevel 混雑度
   * @param xMin x最小値
   * @param yMin y最大値
   * @param gridWidth グリッド幅
   * @param gridHeight グリッド高さ
   * @returns ジオメトリ
   */
  private createGeometry(
    congestionLevel: number,
    xMin: number,
    yMin: number,
    gridWidth: number,
    gridHeight: number
  ): CustomGeometry {
    const bottomLeftPosition = calculateWorldCoordinate(this.bottomLeftLatlng);
    const vertices: number[] = [];
    const indices: number[] = [];
    for (let rowIndex = 0, rowLength = this.congestionMatrix.length; rowIndex < rowLength; rowIndex++) {
      for (let colIndex = 0, colLength = this.congestionMatrix[rowIndex].length; colIndex < colLength; colIndex++) {
        if (this.congestionMatrix[rowIndex][colIndex] === congestionLevel) {
          // 左下
          const lbLatlng = transTokyoToWGS(
            new LatLng(
              transMillisecToDegree((rowIndex + yMin) * gridHeight),
              transMillisecToDegree((colIndex + xMin) * gridWidth)
            )
          );
          const lb = calculateWorldCoordinate(lbLatlng)._subtract(bottomLeftPosition);
          // 左上
          const lt = calculateWorldCoordinate(
            new LatLng(
              transMillisecToDegree(lbLatlng.latMillisec + gridHeight),
              transMillisecToDegree(lbLatlng.lngMillisec)
            )
          )._subtract(bottomLeftPosition);
          // 右上
          const rt = calculateWorldCoordinate(
            new LatLng(
              transMillisecToDegree(lbLatlng.latMillisec + gridHeight),
              transMillisecToDegree(lbLatlng.lngMillisec + gridWidth)
            )
          )._subtract(bottomLeftPosition);
          // 右下
          const rb = calculateWorldCoordinate(
            new LatLng(
              transMillisecToDegree(lbLatlng.latMillisec),
              transMillisecToDegree(lbLatlng.lngMillisec + gridWidth)
            )
          )._subtract(bottomLeftPosition);

          vertices.push(lt.x, lt.y, 0, lb.x, lb.y, 0, rt.x, rt.y, 0, rb.x, rb.y, 0);
          const planeCount = indices.length / 6;
          indices.push(
            0 + planeCount * 4,
            1 + planeCount * 4,
            2 + planeCount * 4,
            1 + planeCount * 4,
            3 + planeCount * 4,
            2 + planeCount * 4
          );
        }
      }
    }

    return new CustomGeometry(vertices, indices);
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const position = calculateWorldCoordinate(this.bottomLeftLatlng)._subtract(cameraTargetPosition);
    for (const obj of this.objects) {
      obj.setPosition(position);
    }
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    for (const obj of this.objects) {
      obj.update(viewMatrix, projectionMatrix);
      obj.draw();
    }
    return true;
  }

  /** @override */
  destroy(): void {
    for (const material of this.materials) {
      material.destroy();
    }
    this.materials = [];
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {CongestionLayer};
