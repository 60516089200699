import {TyphoonConditionInitOptions} from '../types';

/**
 * 台風情報コンディション
 */
class TyphoonCondition {
  /** タイル取得向き先URL */
  readonly server?: string;
  /** 日付フォーマット */
  readonly dateFormat?: string;

  /**
   * コンストラクタ
   * @param options 表示オプション
   * @param server タイル取得向き先URL
   */
  constructor(options: TyphoonConditionInitOptions, server?: string) {
    this.server = server;
    this.dateFormat = options.dateFormat;
  }
}

export {TyphoonCondition};
