import {LatLng} from '../value';
import {GLMarkerIconInfo} from '../value/GLMarkerIconInfo';
import {GaIAEventEmitter} from '../value/interface/GaIAEventEmitter';
import {GLMarkerInitOptions, GLMarkerEvent, GLMarkerEventMap, GLMarkerLabelOptions, MarkerProperties} from '../types';
import {Optional} from '../../_private/common/types';
import {AnimationController} from '../../_private/map/models/animation/AnimationController';
import {AnimationOption} from '../value/animation';

/**
 * GLマーカーのクラス
 */
class GLMarker implements GaIAEventEmitter {
  /** 位置 */
  private position: LatLng;
  /** 目標（アニメーションが完了した際の）位置 */
  private targetPosition: LatLng;
  /** 画像情報 */
  readonly info: GLMarkerIconInfo;
  /** 表示状態 */
  private visible: boolean;
  /** ドラッグ操作可否 */
  private draggable: boolean;
  /** 文字ラベル */
  private label?: GLMarkerLabelOptions;
  /** マーカー名 */
  private readonly name?: string;
  /** z-index（デフォルトは0） */
  private readonly zIndex: number;
  /** プロパティ */
  private properties?: MarkerProperties;

  /** アニメーション管理 */
  private animationController: AnimationController;

  readonly listeners: {[key: string]: (ev: GLMarkerEvent) => void};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onAddEventListener?: (eventName: keyof GLMarkerEventMap, func: (ev: GLMarkerEvent) => any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onRemoveEventListener?: (eventName: keyof GLMarkerEventMap, func: (ev: GLMarkerEvent) => any) => void;
  private onMarkerStatusUpdateListener?: (marker: GLMarker) => void;
  private onMarkerVisibleUpdateListener?: (marker: GLMarker) => void;
  private onLabelUpdateListener?: (marker: GLMarker) => void;

  /**
   * コンストラクタ
   * @param options オプション
   */
  constructor(options: GLMarkerInitOptions) {
    this.position = options.position;
    this.targetPosition = options.position;
    this.info = options.info;
    this.visible = options.isVisible ?? true;
    this.draggable = options.draggable ?? false;
    this.label = options.label;
    this.name = options.name;
    this.zIndex = options.zIndex ?? 0;
    this.properties = undefined;
    this.listeners = {};
    this.animationController = new AnimationController();
  }

  /**
   * 位置を設定
   * @param position 位置の緯度経度
   * @param animationOption アニメーションオプション
   * @returns {void}
   */
  setPosition(position: LatLng, animationOption?: AnimationOption): void {
    if (animationOption) {
      this.animationController.completeAnimation();
      const startPosition = this.position;
      this.targetPosition = position;
      this.animationController.startAnimation((progress: number) => {
        const lat = startPosition.lat + progress * (this.targetPosition.lat - startPosition.lat);
        const lng = startPosition.lng + progress * (this.targetPosition.lng - startPosition.lng);
        this.position = new LatLng(lat, lng);
        this.onMarkerStatusUpdateListener?.(this);
      }, animationOption);
      return;
    }
    this.position = position;
    this.onMarkerStatusUpdateListener?.(this);
  }

  /**
   * 位置を取得
   * @returns 位置の緯度経度
   */
  getPosition(): LatLng {
    return this.position;
  }

  /**
   * GLマーカーを表示
   * @returns {void}
   */
  show(): void {
    if (this.visible) {
      return;
    }

    this.visible = true;
    this.onMarkerVisibleUpdateListener?.(this);
  }

  /**
   * GLマーカーを非表示
   * @param options オプション
   * @returns {void}
   */
  hide(): void {
    if (!this.visible) {
      return;
    }

    this.visible = false;
    this.onMarkerVisibleUpdateListener?.(this);
  }

  /**
   * 表示状態の取得
   * @returns `true`:表示, `false`: 非表示
   */
  isVisible(): boolean {
    return this.visible;
  }

  /**
   * ドラッグ有効化設定
   * @param draggable `true` : 有効, `false` : 無効
   * @returns {void}
   */
  setDraggable(draggable: boolean): void {
    if (this.draggable === draggable) {
      return;
    }

    this.draggable = draggable;
    this.onMarkerStatusUpdateListener?.(this);
  }

  /**
   * ドラッグ操作可否の取得
   * @returns `true` : 有効, `false` : 無効
   */
  getDraggable(): boolean {
    return this.draggable;
  }

  /**
   * 新しくラベルを設定する
   * @param labelOptions GLMarkerLabelOptions
   * @returns {void}
   */
  setLabel(labelOptions: GLMarkerLabelOptions): void {
    if (JSON.stringify(this.label) === JSON.stringify(labelOptions)) {
      return;
    }

    this.label = labelOptions;
    this.onLabelUpdateListener?.(this);
  }

  /**
   * MarkerLabelOptionsを取得
   * @returns MarkerLabelOptions
   */
  getLabel(): GLMarkerLabelOptions | undefined {
    return this.label;
  }

  /**
   * 識別用名称を取得
   * @returns 識別用名称
   */
  getName(): Optional<string> {
    return this.name;
  }

  /**
   * z-indexを取得
   * @returns z-index
   */
  getZIndex(): number {
    return this.zIndex;
  }

  /**
   * プロパティを取得
   * @returns プロパティ
   */
  getProperties(): MarkerProperties | undefined {
    return this.properties;
  }

  /**
   * プロパティを設定
   * @param properties プロパティ
   * @returns {void}
   */
  setProperties(properties: MarkerProperties | undefined): void {
    this.properties = properties;
  }

  /**
   * イベント追加・削除時のリスナーを設定
   * @ignore
   * @param add イベント追加リスナー
   * @param remove イベント削除リスナー
   * @returns {void}
   */
  setOnEventUpdatedListener(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    add?: (eventName: keyof GLMarkerEventMap, func: (ev: GLMarkerEvent) => any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    remove?: (eventName: keyof GLMarkerEventMap, func: (ev: GLMarkerEvent) => any) => void
  ): void {
    this.onAddEventListener = add;
    this.onRemoveEventListener = remove;
  }

  /**
   * 状態更新通知リスナーの設定
   * @ignore
   * @param listener リスナー関数
   * @returns {void}
   */
  setOnMarkerStatusUpdateListener(listener: (marker: GLMarker) => void): void {
    this.onMarkerStatusUpdateListener = listener;
  }

  /**
   * 表示更新通知リスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setOnMarkerVisibleUpdateListener(listener: (marker: GLMarker) => void): void {
    this.onMarkerVisibleUpdateListener = listener;
  }

  /**
   * ラベル更新通知リスナーの設定
   * @ignore
   * @param listener リスナー関数
   * @returns {void}
   */
  setOnLabelUpdateListener(listener: (marker: GLMarker) => void): void {
    this.onLabelUpdateListener = listener;
  }

  /**
   * イベントリスナー追加
   * @param eventName イベント名
   * @param func イベントハンドラ
   * @returns {void}
   */
  addEventListener<K extends keyof GLMarkerEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GLMarkerEventMap[K]) => any
  ): void {
    if (this.onAddEventListener) {
      this.onAddEventListener(eventName, func);
      return;
    }
    this.listeners[eventName] = func;
  }

  /**
   * イベントリスナー削除
   * @param eventName イベント名
   * @param func イベントハンドラ
   * @returns {void}
   */
  removeEventListener<K extends keyof GLMarkerEventMap>(
    eventName: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    func: (ev: GLMarkerEventMap[K]) => any
  ): void {
    if (this.onRemoveEventListener) {
      this.onRemoveEventListener(eventName, func);
      return;
    }
    delete this.listeners[eventName];
  }
}

export {GLMarker};
