import {GaiaContext} from '../GaiaContext';
import {OnFinishRequestFunc, LoaderState} from './AbstractTileLoader';
import {Optional} from '../../common/types';
import {LRUCache} from '../../common/collection/LRUCache';
import {Queue} from '../../common/collection/Queue';
import {TrainRouteInfoRequester} from '../../common/infra/request/TrainRouteInfoRequester';
import {JsonObject} from '../../../gaia/types';
import {TileNumber} from '../models/TileNumber';

const PATH_TRAINROUTE = 'data/trainroute';

// TODO ズームレベルに関わらずどこか1つのズームレベルのデータを使い回す（ズームレベル変化時の通信を減らす）
/**
 * 鉄道路線図ローダー
 */
class TrainRouteLoader {
  private serverUrl = '';
  private readonly requester: TrainRouteInfoRequester;

  private cache: LRUCache<TileNumber, JsonObject>;
  private requestQueue: Queue<TileNumber>;
  private requestingTile: Set<string>;
  private onFinishRequestFunc: OnFinishRequestFunc;

  private state: LoaderState = 'initialized';

  private processTrainRouteDataTasks: Map<TileNumber, () => void> = new Map();

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param onFinishRequestFunc データ読み込み完了通知
   */
  constructor(context: GaiaContext, onFinishRequestFunc: OnFinishRequestFunc) {
    this.cache = new LRUCache(100);
    this.requestQueue = new Queue(150);
    this.requestingTile = new Set<string>();
    this.onFinishRequestFunc = onFinishRequestFunc;

    context
      .getGaIAConfiguration()
      .then((res) => {
        if (!res.server.data) {
          return;
        }
        this.serverUrl = `${res.server.data}v1/${res.productId}`;
        this.setState('ready');
        this.executeRequest();
      })
      .catch(() => {
        // do nothing
      });

    this.requester = new TrainRouteInfoRequester((tile) => {
      return this.createUrl(tile);
    });
  }

  /**
   * リクエストURLを生成
   * @param tile TileNumber
   * @returns リクエストURL
   */
  private createUrl(tile: TileNumber): string {
    const {x, y, z} = tile;
    return `${this.serverUrl}/${PATH_TRAINROUTE}/${z}/${x}/${y}`;
  }

  /**
   * Loaderの状態を判定
   * @param state LoaderState
   * @returns 指定された状態のときにtrue
   */
  private isState(state: LoaderState): boolean {
    return this.state === state;
  }

  /**
   * Loaderの状態を設定
   * @param state LoaderState
   * @returns {void}
   */
  private setState(state: LoaderState): void {
    // TODO: 状態遷移を制御するかどうか
    this.state = state;
  }

  /**
   * キャッシュとリクエストを全てクリアする
   * @returns {void}
   */
  clear(): void {
    this.cache.clear();
    this.requestQueue.clear();
    this.requestingTile.clear();
    this.requester.clear();
  }

  /**
   * リクエストキューをクリア
   * @returns {void}
   */
  clearRequestQueue(): void {
    this.requestQueue.clear();
    this.requestingTile.clear();
    this.requester.clear();
  }

  /**
   * キャッシュにあるデータを返す
   * @param tile TileNumber
   * @returns キャッシュ済みデータ
   */
  getCache(tile: TileNumber): Optional<JsonObject> {
    return this.cache.get(tile);
  }

  /**
   * 描画に必要なタイルをリクエストキューに追加
   * @param tileList TileNumberリスト
   * @returns {void}
   */
  addRequestQueue(tileList: TileNumber[]): void {
    for (const tile of tileList) {
      if (this.cache.has(tile)) {
        continue;
      }
      this.requestQueue.enqueue(tile);
    }
  }

  /**
   * リクエストを実行
   * @returns {void}
   */
  executeRequest(): void {
    if (!this.isState('ready')) {
      return;
    }

    if (this.requestQueue.size === 0) {
      return;
    }

    while (this.requestQueue.size > 0) {
      const tile = this.requestQueue.dequeue();
      if (!tile) {
        break;
      }
      if (this.cache.has(tile) || this.requestingTile.has(tile.getCacheKey())) {
        continue;
      }
      this.requestTrainRoute(tile);
    }
  }

  /**
   * 通信でのリクエスト
   * @param tile TileNumber
   * @returns {void}
   */
  private requestTrainRoute(tile: TileNumber): void {
    this.requestingTile.add(tile.getCacheKey());

    this.requester.requestTrainRoute(tile, (tile, encryptedData) => {
      if (this.isState('destroyed') || !this.requestingTile.has(tile.getCacheKey())) {
        // リクエスト中に地図が破棄された or リクエスト対象から外れたものはキャッシュしない
        return;
      }

      const info = this.convertToJson(encryptedData);
      if (info) {
        this.processTrainRouteDataTasks.set(tile, () => {
          // 無限リクエスト対策のため、データが不正であってもキャッシュには積んでおく
          this.cache.add(tile, info);
          this.requestingTile.delete(tile.getCacheKey());
          this.onFinishRequestFunc();
        });
      }
    });
  }

  /**
   * 難読化されているデータをJsonに変換
   * @param str 難読化された鉄道路線図データ
   * @returns Json
   */
  private convertToJson(str: string): JsonObject {
    try {
      const reversed = str.split('').reverse().join('');
      const decoded = decodeURIComponent(escape(atob(reversed)));
      return JSON.parse(decoded);
    } catch {
      return {};
    }
  }

  /**
   * キャッシュサイズ上限を拡張
   * @param size 変更後サイズ
   * @returns {void}
   */
  jumpUpCacheSize(size: number): void {
    this.cache.jumpUpSize(size);
  }

  /**
   * フレームレート維持のためあとにまわしていたタスクを実行する
   * @param tileList タイル番号のリスト
   * @returns {void}
   */
  processTrainRouteData(): void {
    this.processTrainRouteDataTasks.forEach((task, _tile) => {
      task();
    });
    this.processTrainRouteDataTasks.clear();
  }

  /**
   * 破棄
   * @returns {void}
   */
  destroy(): void {
    this.clear();
    this.requester.destroy();
    this.setState('destroyed');
  }
}

export {TrainRouteLoader};
