import {MapIconConditionInitOptions, MapIconConditionInitOptionsIcon, MapIconConditionPaletteConfig} from '../types';

/**
 * 地図アイコンコンディション
 */
class MapIconCondition {
  readonly tags: {[tag: string]: MapIconConditionPaletteConfig[]};
  readonly product?: string;
  readonly icon?: MapIconConditionInitOptionsIcon;

  /**
   * コンストラクタ
   * @param options オプション
   */
  constructor(options: MapIconConditionInitOptions) {
    this.tags = options.tags;
    this.product = options.product;
    this.icon = options.icon;
  }
}

export {MapIconCondition, MapIconConditionInitOptions};
