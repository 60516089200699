import {mat4} from 'gl-matrix';
import {Color} from '../../../gaia/value/Color';
import {Vector3} from '../../common/math/Vector3';
import {Optional, RenderTarget} from '../../common/types';
import {getDevicePixelRatio} from '../../common/util/Device';
import {MapStatus} from '../../map/models/MapStatus';
import {Geometry} from '../geometry/Geometry';
import {Object3D} from '../object/Object3D';
import {DepthProgram, FRAME_BUFFER_SIDE} from './DepthProgram';
import {Program} from './Program';

const vertexShaderSource = `
attribute vec3 vertexPosition;
attribute vec2 texCoord;

uniform mat4 model;
uniform mat4 view;
uniform mat4 projection;

varying vec3 position;
varying vec2 textureCoord;

void main() {
  gl_Position = projection * view * model * vec4(vertexPosition, 1.0);
  
  position = (model * vec4(vertexPosition, 1.0)).xyz;
  textureCoord = texCoord;
}
`;

const fragmentShaderSource = `
precision highp float;

uniform sampler2D tex;
uniform vec3 cameraPosition;
uniform vec4 fogColor;
uniform vec4 skyColor;
uniform float fogDistanceRatio;

varying vec3 position;
varying vec2 textureCoord;

void main() {

  vec2 uv = vec2(textureCoord.x, 1.0 - textureCoord.y);
  vec4 pixel = texture2D(tex, uv);
  float depth = (pixel.r + pixel.g + pixel.b) / 3.0;

  float skyThreshold = 0.999;
  float fogThreshold = 0.96;
  float clearThreshold = 0.94;
  vec4 clearColor = vec4(1.0, 1.0, 1.0, 0.0);

  float skyness = clamp((depth - fogThreshold) / (skyThreshold - fogThreshold), 0.0, 1.0);
  float fogness = clamp((depth - clearThreshold) / (fogThreshold - clearThreshold), 0.0, 1.0);
  float clearness = 1.0 - fogness;

  gl_FragColor = skyness > 0.0 ? (skyness * skyColor + (1.0 - skyness) * fogColor) : (fogness * fogColor + clearness * clearColor);
}
`;

let VERTEX_SHADER: Optional<WebGLShader>;
let FRAGMENT_SHADER: Optional<WebGLShader>;
let PROGRAM: Optional<WebGLProgram>;

/**
 * 霧用のプログラム
 */
class FogProgram implements Program {
  context: WebGLRenderingContext;
  geometry: Geometry;

  vertexShader: Optional<WebGLShader>;
  fragmentShader: Optional<WebGLShader>;
  program: Optional<WebGLProgram>;

  private fogDistanceRatio: number;
  private texture: WebGLTexture | null;

  private modelLocation: Optional<WebGLUniformLocation>;
  private viewLocation: Optional<WebGLUniformLocation>;
  private projectionLocation: Optional<WebGLUniformLocation>;
  private cameraPositionLocation: Optional<WebGLUniformLocation>;
  private fogColorLocation: Optional<WebGLUniformLocation>;
  private skyColorLocation: Optional<WebGLUniformLocation>;
  private fogDistanceRatioLocation: Optional<WebGLUniformLocation>;

  private modelMatrix: mat4;
  private translationMatrix: mat4;
  private rotationMatrix: mat4;
  private scaleMatrix: mat4;

  private vertexBuffer: Optional<WebGLBuffer>;
  private indexBuffer: Optional<WebGLBuffer>;

  private typedVertices: Float32Array;
  private typedIndices: Uint16Array;

  /**
   * コンストラクタ
   * @param context WebGLコンテキスト
   * @param geometry ジオメトリ
   * @param fogColor 霧の色
   * @param skyColor 空の色
   */
  constructor(context: WebGLRenderingContext, geometry: Geometry, fogColor: Color, skyColor: Color) {
    this.context = context;

    this.fogDistanceRatio = 1.0;
    this.texture = DepthProgram.targetTexture ?? null;

    this.modelMatrix = mat4.create();
    this.translationMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.scaleMatrix = mat4.create();

    this.typedVertices = new Float32Array();
    this.typedIndices = new Uint16Array();

    this.setupProgram();
    this.setGeometry(geometry);
    this.setupGeometry(geometry);

    this.setFogColor(fogColor);
    this.setSkyColor(skyColor);

    this.geometry = geometry;
  }

  /**
   * プログラムを準備
   * @returns {void}
   */
  setupProgram(): void {
    if (!VERTEX_SHADER) {
      const mayBeVertexShader: WebGLShader | null = this.context.createShader(this.context.VERTEX_SHADER);
      if (mayBeVertexShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create vertex shader');
        return;
      }
      VERTEX_SHADER = mayBeVertexShader;
      this.context.shaderSource(VERTEX_SHADER, vertexShaderSource);
      this.context.compileShader(VERTEX_SHADER);

      const vertexShaderCompileStatus = this.context.getShaderParameter(VERTEX_SHADER, this.context.COMPILE_STATUS);
      if (!vertexShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(VERTEX_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
        return;
      }
    }
    this.vertexShader = VERTEX_SHADER;

    if (!FRAGMENT_SHADER) {
      const mayBeFragmentShader: WebGLShader | null = this.context.createShader(this.context.FRAGMENT_SHADER);
      if (mayBeFragmentShader === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create fragment shader');
        return;
      }
      FRAGMENT_SHADER = mayBeFragmentShader;
      this.context.shaderSource(FRAGMENT_SHADER, fragmentShaderSource);
      this.context.compileShader(FRAGMENT_SHADER);

      const fragmentShaderCompileStatus = this.context.getShaderParameter(FRAGMENT_SHADER, this.context.COMPILE_STATUS);
      if (!fragmentShaderCompileStatus) {
        const info = this.context.getShaderInfoLog(FRAGMENT_SHADER);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.fragmentShader = FRAGMENT_SHADER;

    if (!PROGRAM) {
      const mayBeProgram = this.context.createProgram();
      if (mayBeProgram === null) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] CanvasTextureProgram.setupProgram() could not create program');
        return;
      }
      PROGRAM = mayBeProgram;
      this.context.attachShader(PROGRAM, this.vertexShader);
      this.context.attachShader(PROGRAM, this.fragmentShader);
      this.context.linkProgram(PROGRAM);

      const linkStatus = this.context.getProgramParameter(PROGRAM, this.context.LINK_STATUS);
      if (!linkStatus) {
        const info = this.context.getProgramInfoLog(PROGRAM);
        // eslint-disable-next-line no-console
        console.warn(info);
      }
    }
    this.program = PROGRAM;

    this.context.useProgram(this.program);
  }

  /**
   * ジオメトリを準備
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setupGeometry(geometry: Geometry): void {
    if (!this.program) {
      return;
    }

    this.geometry = geometry;
    const gl = this.context;

    gl.useProgram(this.program);

    if (!this.vertexBuffer) {
      this.vertexBuffer = gl.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = gl.createBuffer();
    }

    const vertexAttribLocation = gl.getAttribLocation(this.program, 'vertexPosition');
    const textureAttribLocation = gl.getAttribLocation(this.program, 'texCoord');

    const VERTEX_SIZE = 3;
    const TEXTURE_SIZE = 2;
    const STRIDE = (VERTEX_SIZE + TEXTURE_SIZE) * Float32Array.BYTES_PER_ELEMENT;
    const VERTEX_OFFSET = 0;
    const TEXTURE_OFFSET = 3 * Float32Array.BYTES_PER_ELEMENT;

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);

    gl.enableVertexAttribArray(vertexAttribLocation);
    gl.enableVertexAttribArray(textureAttribLocation);

    gl.vertexAttribPointer(vertexAttribLocation, VERTEX_SIZE, gl.FLOAT, false, STRIDE, VERTEX_OFFSET);
    gl.vertexAttribPointer(textureAttribLocation, TEXTURE_SIZE, gl.FLOAT, false, STRIDE, TEXTURE_OFFSET);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
  }

  /**
   * ジオメトリを変更する
   * @param geometry ジオメトリ
   * @returns {void}
   */
  setGeometry(geometry: Geometry): void {
    this.geometry = geometry;

    if (!this.vertexBuffer) {
      this.vertexBuffer = this.context.createBuffer();
    }

    if (!this.indexBuffer) {
      this.indexBuffer = this.context.createBuffer();
    }

    const vertices = this.geometry.getVertices();
    const verticesLength = vertices.length;
    if (this.typedVertices.length !== verticesLength) {
      this.typedVertices = new Float32Array(verticesLength);
    }
    this.typedVertices.set(vertices);

    const indices = this.geometry.getIndices();
    const indicesLength = indices.length;
    if (this.typedIndices.length !== indicesLength) {
      this.typedIndices = new Uint16Array(indicesLength);
    }
    this.typedIndices.set(indices);

    this.context.bindBuffer(this.context.ARRAY_BUFFER, this.vertexBuffer);
    this.context.bufferData(this.context.ARRAY_BUFFER, this.typedVertices, this.context.STATIC_DRAW);

    this.context.bindBuffer(this.context.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    this.context.bufferData(this.context.ELEMENT_ARRAY_BUFFER, this.typedIndices, this.context.STATIC_DRAW);
  }

  /**
   * テクスチャを設定
   * @param texture テクスチャ
   * @returns {void}
   */
  setGLTexture(texture: WebGLTexture): void {
    if (!this.program) {
      return;
    }

    this.texture = texture;
    this.context.useProgram(this.program);
    const gl = this.context;

    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, FRAME_BUFFER_SIDE, FRAME_BUFFER_SIDE, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_S,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_WRAP_T,
      WebGLRenderingContext.CLAMP_TO_EDGE
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MAG_FILTER,
      WebGLRenderingContext.LINEAR
    );
    this.context.texParameteri(
      this.context.TEXTURE_2D,
      WebGLRenderingContext.TEXTURE_MIN_FILTER,
      WebGLRenderingContext.LINEAR
    );
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, FRAME_BUFFER_SIDE, FRAME_BUFFER_SIDE, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);

    this.context.bindTexture(this.context.TEXTURE_2D, null);
  }

  /**
   * 行列情報を更新
   * @param object3D オブジェクト
   * @param viewMatrix ビュー変換行列
   * @param projectionMatrix 投影変換行列
   * @returns {void}
   */
  update(object3D: Object3D, viewMatrix: mat4, projectionMatrix: mat4): void {
    if (!this.program) {
      return;
    }

    if (!this.texture && DepthProgram.targetTexture) {
      this.setGLTexture(DepthProgram.targetTexture);
    }

    // model
    mat4.identity(this.scaleMatrix);
    mat4.identity(this.rotationMatrix);
    mat4.identity(this.translationMatrix);
    mat4.identity(this.modelMatrix);

    mat4.scale(this.scaleMatrix, this.scaleMatrix, object3D.scale.toFloat32Array());
    mat4.multiply(this.rotationMatrix, this.rotationMatrix, object3D.rotation.toMat4());
    mat4.translate(this.translationMatrix, this.translationMatrix, object3D.position.toFloat32Array());

    mat4.multiply(this.modelMatrix, this.modelMatrix, this.translationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.rotationMatrix);
    mat4.multiply(this.modelMatrix, this.modelMatrix, this.scaleMatrix);

    this.context.useProgram(this.program);
    if (!this.modelLocation) {
      this.modelLocation = this.context.getUniformLocation(this.program, 'model');
    }
    if (!this.viewLocation) {
      this.viewLocation = this.context.getUniformLocation(this.program, 'view');
    }
    if (!this.projectionLocation) {
      this.projectionLocation = this.context.getUniformLocation(this.program, 'projection');
    }
    if (!this.fogDistanceRatioLocation) {
      this.fogDistanceRatioLocation = this.context.getUniformLocation(this.program, 'fogDistanceRatio');
    }
    this.context.uniformMatrix4fv(this.modelLocation, false, this.modelMatrix);
    this.context.uniformMatrix4fv(this.viewLocation, false, viewMatrix);
    this.context.uniformMatrix4fv(this.projectionLocation, false, projectionMatrix);
    this.context.uniform1f(this.fogDistanceRatioLocation, this.fogDistanceRatio);
  }

  /**
   * テクスチャ更新
   * @returns {void}
   */
  updateTexture(): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
  }

  /**
   * カメラの位置を設定
   * @param cameraPosition カメラの位置
   * @returns {void}
   */
  setCameraPosition(cameraPosition: Vector3): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    if (!this.cameraPositionLocation) {
      this.cameraPositionLocation = this.context.getUniformLocation(this.program, 'cameraPosition');
    }
    this.context.uniform3f(this.cameraPositionLocation, cameraPosition.x, cameraPosition.y, cameraPosition.z);
  }

  /**
   * 霧の色を設定
   * @param fogColor 霧の色
   * @returns {void}
   */
  setFogColor(fogColor: Color): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    if (!this.fogColorLocation) {
      this.fogColorLocation = this.context.getUniformLocation(this.program, 'fogColor');
    }
    this.context.uniform4f(this.fogColorLocation, fogColor.r, fogColor.g, fogColor.b, fogColor.a);
  }

  /**
   * 空の色を設定
   * @param skyColor 霧の色
   * @returns {void}
   */
  setSkyColor(skyColor: Color): void {
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    if (!this.skyColorLocation) {
      this.skyColorLocation = this.context.getUniformLocation(this.program, 'skyColor');
    }
    this.context.uniform4f(this.skyColorLocation, skyColor.r, skyColor.g, skyColor.b, skyColor.a);
  }

  /**
   * 霧の距離係数を設定
   * @param fogDistanceRatio 霧の距離係数
   * @returns {void}
   */
  setFogDistanceRatio(fogDistanceRatio: number): void {
    this.fogDistanceRatio = fogDistanceRatio;
    if (!this.program) {
      return;
    }

    this.context.useProgram(this.program);
    if (!this.fogDistanceRatioLocation) {
      this.fogDistanceRatioLocation = this.context.getUniformLocation(this.program, 'fogDistanceRatio');
    }
    this.context.uniform1f(this.fogDistanceRatioLocation, this.fogDistanceRatio);
  }

  /**
   * 描画
   * @param renderTarget 描画対象の状態
   * @returns {void}
   */
  draw(renderTarget?: RenderTarget): void {
    this.setupGeometry(this.geometry);
    this.updateTexture();

    const gl = this.context;
    const target = renderTarget ?? MapStatus.defaultRenderTarget;

    if (target.type === 'default') {
      const dpr = getDevicePixelRatio();
      gl.viewport(0, 0, dpr * target.size.width, dpr * target.size.height);
    }

    gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE);
    gl.enable(gl.BLEND);
    gl.enable(gl.DEPTH_TEST);

    gl.drawElements(gl.TRIANGLES, this.geometry.getIndices().length, gl.UNSIGNED_SHORT, 0);

    gl.disable(gl.DEPTH_TEST);
    gl.disable(gl.BLEND);
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    const gl = this.context;
    if (PROGRAM && VERTEX_SHADER && FRAGMENT_SHADER) {
      gl.detachShader(PROGRAM, VERTEX_SHADER);
      gl.detachShader(PROGRAM, FRAGMENT_SHADER);
      gl.deleteProgram(PROGRAM);
      PROGRAM = undefined;
    }
    if (VERTEX_SHADER) {
      gl.deleteShader(VERTEX_SHADER);
      VERTEX_SHADER = undefined;
    }
    if (FRAGMENT_SHADER) {
      gl.deleteShader(FRAGMENT_SHADER);
      FRAGMENT_SHADER = undefined;
    }
    if (this.indexBuffer) {
      gl.deleteBuffer(this.indexBuffer);
      this.indexBuffer = undefined;
    }
    if (this.vertexBuffer) {
      gl.deleteBuffer(this.vertexBuffer);
      this.vertexBuffer = undefined;
    }
    this.viewLocation = undefined;
    this.modelLocation = undefined;
    this.projectionLocation = undefined;
    this.cameraPositionLocation = undefined;
    this.fogColorLocation = undefined;
    this.skyColorLocation = undefined;
  }
}

export {FogProgram};
