import {GaiaContext} from '../GaiaContext';
import {MapStatus} from '../models/MapStatus';

import {ExternalAnnotationObjectRenderKit} from '../render/kit/object/ExternalAnnotationObjectRenderKit';
import {ExternalAnnotationClickListener, ExternalAnnotationFeature, ExternalAnnotationPluginMap} from 'gaia/types';

import {calculatePixelToUnit, calculateWorldCoordinate} from '../utils/MapUtil';

import {MouseEventObserver} from '../event/MouseEventObserver';
import {TouchEventObserver} from '../event/TouchEventObserver';

import {AnnotationCullHelper} from '../render/helper/AnnotationCullHelper';

import {GLMarker} from 'gaia/object';
import {GLMarkerIconInfo} from 'gaia/value';
import {GLMarkerObject} from '../render/objects/GLMarkerObject';
import {GLMarkerGroupObject} from '../render/objects/GLMarkerGroupObject';

import {Ray3} from '../../common/math/Ray3';
import {Vector3} from '_private/common/math/Vector3';
import {Optional} from '_private/common/types';
import {SimpleCache} from '../../common/collection/SimpleCache';
import {Camera} from '../../engine/camera/Camera';
import {Collision} from '../../engine/collision/Collision';
import {Layer} from '../../engine/layer/Layer';
import {PerspectiveCamera} from '_private/engine/camera/PerspectiveCamera';

import {mat4} from 'gl-matrix';

const LAYER_NAME_EXTERNAL_ANNOTATION = 'externalAnnotation';

/**
 * 社外由来注記レイヤー
 */
class ExternalAnnotationLayer implements Layer {
  private readonly context: GaiaContext;
  private readonly camera: Camera;
  private visible: boolean;

  private readonly mouse: MouseEventObserver;
  private readonly touch: TouchEventObserver;

  private onExternalAnnotationClick?: ExternalAnnotationClickListener;

  private isDestroyed = false;

  private getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  private helperForSameIcon: AnnotationCullHelper;
  private helperForAllIcon: AnnotationCullHelper;

  private colliderMarginForSameIcon: number | undefined;
  private colliderMarginForAllIcon: number | undefined;

  private markerObjectMap: Map<GLMarkerObject, GLMarker>;
  private markerGroupObjects: SimpleCache<GLMarkerIconInfo, GLMarkerGroupObject>;

  private hoveredMarker?: GLMarker;
  private hoveredExternalAnnotationObject?: GLMarkerObject;

  private pluginMap: ExternalAnnotationPluginMap | undefined;

  private suspendCull = false;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKit AnnotationObjectRenderKit
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKit: ExternalAnnotationObjectRenderKit, camera: Camera) {
    this.context = context;
    this.camera = camera;
    this.visible = false;

    this.mouse = new MouseEventObserver(context.getBaseElement());
    this.touch = new TouchEventObserver(context.getBaseElement());

    this.setupClick();
    this.setupHover();

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKit.getAllCollisions(ray);

    this.helperForSameIcon = new AnnotationCullHelper();
    this.helperForAllIcon = new AnnotationCullHelper();

    this.markerObjectMap = new Map();
    this.markerGroupObjects = new SimpleCache();

    this.mouse.addEventListener('mousedown', () => {
      this.suspendCull = true;
    });
    this.mouse.addEventListener('mouseup', () => {
      this.suspendCull = false;
    });
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    if (this.isDestroyed) {
      return;
    }

    const cameraTargetPosition = calculateWorldCoordinate(mapStatus.centerLocation);

    for (const markerGroup of this.markerGroupObjects.values()) {
      markerGroup.updateMarkerGroup(cameraTargetPosition, mapStatus);
    }

    if (!this.suspendCull) {
      this.cullInMarkerGroupObjects(this.camera, cameraTargetPosition);
    }
  }

  /**
   * renderkit層で初期化したpluginの連想配列を渡す
   * @param pluginMap plugins
   * @returns {void}
   */
  setPluginMap(pluginMap: ExternalAnnotationPluginMap): void {
    this.pluginMap = pluginMap;
  }

  /**
   * 社外由来注記の衝突判定補正値を設定 (同じ見た目のアイコン同士)
   * @param margin 補正値
   * @returns {void}
   */
  setColliderMarginForSameIcon(margin: number | undefined): void {
    this.colliderMarginForSameIcon = margin;
  }

  /**
   * 社外由来注記の衝突判定補正値を設定 (全てのアイコン同士)
   * @param margin 補正値
   * @returns {void}
   */
  setColliderMarginForAllIcon(margin: number | undefined): void {
    this.colliderMarginForAllIcon = margin;
  }

  /**
   * GLMarkerObjectを追加
   * @param markerObj GLMarkerObject
   * @param marker GLMarker
   * @returns {void}
   */
  addMarkerObject(markerObj: GLMarkerObject, marker: GLMarker): void {
    // 初回の重なり間引き処理までは all false
    markerObj.setVisible(false);
    this.markerObjectMap.set(markerObj, marker);
  }

  /**
   * GLMarkerObjectを削除
   * @param markerObj markerObject
   * @returns {void}
   */
  removeMarkerObject(markerObj: GLMarkerObject): void {
    this.markerObjectMap.delete(markerObj);
  }

  /**
   * GLMarkerに対応するGLMarkerObjectをmarkerObjectMapから取得
   * @param targetMarker 対象GLMarker
   * @returns 対応するGLMarkerObject | null
   */
  findMarkerObject(targetMarker: GLMarker): Optional<GLMarkerObject> {
    for (const [markerObject, marker] of this.markerObjectMap.entries()) {
      if (marker === targetMarker) {
        return markerObject;
      }
    }
    return null;
  }

  /**
   * GLMarkerGroupObjectを追加
   * @param info GLMarkerIconInfo
   * @param markerGroupObject GLMarkerGroupObject
   * @returns {void}
   */
  addMarkerGroupObject(info: GLMarkerIconInfo, markerGroupObject: GLMarkerGroupObject): void {
    this.markerGroupObjects.add(info, markerGroupObject);
  }

  /**
   * GLMarkerGroupObjectを削除
   * @param info GLMarkerIconInfo
   * @returns {void}
   */
  removeMarkerGroupObject(info: GLMarkerIconInfo): void {
    const markerGroupObject = this.fetchMarkerGroupObject(info);
    if (!markerGroupObject) {
      return;
    }

    this.markerGroupObjects.remove(info);
  }

  /**
   * GLMarkerGroupObjectを取得
   * @param info GLMarkerIconInfo
   * @returns {void}
   */
  fetchMarkerGroupObject(info: GLMarkerIconInfo): Optional<GLMarkerGroupObject> {
    return this.markerGroupObjects.get(info);
  }

  /**
   * 社外由来注記クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setExternalAnnotationClickListener(listener: ExternalAnnotationClickListener): void {
    this.onExternalAnnotationClick = listener;
  }

  /**
   * クリック時の設定
   * @returns {void}
   */
  private setupClick(): void {
    this.mouse.addEventListener('click', (ev: MouseEvent) => {
      if (!this.hoveredExternalAnnotationObject || !this.hoveredMarker || !this.pluginMap) {
        return;
      }

      this.onExternalAnnotationClick?.(this.hoveredMarker.getProperties() as ExternalAnnotationFeature, this.pluginMap);

      this.hoveredExternalAnnotationObject.helper.trigger('click', {
        sourceObject: this.hoveredMarker,
        native: ev,
      });
    });
  }

  /**
   * ホバー時の設定
   * @returns {void}
   */
  private setupHover(): void {
    this.mouse.addEventListener('mousemove', (ev: MouseEvent) => {
      this.onHover(ev, ev.offsetX, ev.offsetY);
    });

    this.touch.addEventListener('touchstart', (ev: TouchEvent) => {
      if (!ev.touches || ev.touches.length !== 1) {
        return;
      }

      const rect = this.context.getBaseElement().getBoundingClientRect();

      const touch = ev.touches[0];
      const clientPositionX = touch.clientX - rect.left;
      const clientPositionY = touch.clientY - rect.top;
      this.onHover(ev, clientPositionX, clientPositionY);
    });
  }

  /**
   * ホバー時の処理
   * @param ev UIEvent
   * @param clientPositionX クライアントのX座標
   * @param clientPositionY クライアントのY座標
   * @returns {void}
   */
  private onHover(ev: UIEvent, clientPositionX: number, clientPositionY: number): void {
    if (this.markerGroupObjects.size() === 0 || !this.isOnMap(ev)) {
      return;
    }

    const ray: Ray3 = this.clientPositionToRay(clientPositionX, clientPositionY);
    const allCollisions = this.getAllCollisions(ray);

    for (const [layerName, collisions] of allCollisions.entries()) {
      if (layerName === LAYER_NAME_EXTERNAL_ANNOTATION) {
        // 社外由来注記のレイヤーで当たり判定があった場合はカーソルを変更する
        let collidedExternalAnnotationObject: GLMarkerObject | undefined;
        if (collisions.length > 0) {
          const collision = collisions[0];
          const additional = collision.getAdditional();
          if (additional) {
            collidedExternalAnnotationObject = additional as GLMarkerObject;
          }
        }

        if (collidedExternalAnnotationObject) {
          this.context.getBaseElement().style.cursor = 'pointer';
          this.hoveredMarker = this.markerObjectMap.get(collidedExternalAnnotationObject);
          this.hoveredExternalAnnotationObject = collidedExternalAnnotationObject;

          if (this.hoveredMarker && collidedExternalAnnotationObject !== this.hoveredExternalAnnotationObject) {
            // ホバーしたマーカーに対してmouseoverを発行
            collidedExternalAnnotationObject.helper.trigger('mouseover', {
              sourceObject: this.hoveredMarker,
              native: ev,
            });
          }

          this.hoveredExternalAnnotationObject = collidedExternalAnnotationObject;
          const markerName = this.hoveredMarker?.getName();
          if (markerName) {
            this.context.getBaseElement().title = markerName;
          }
        } else {
          if (this.hoveredExternalAnnotationObject) {
            const marker = this.markerObjectMap.get(this.hoveredExternalAnnotationObject);
            if (marker) {
              // 1つ前のフレームで別のマーカー上をホバーしていた場合はmouseoutを発行
              this.hoveredExternalAnnotationObject.helper.trigger('mouseout', {
                sourceObject: marker,
                native: ev,
              });
            }
          }
          this.hoveredExternalAnnotationObject = undefined;
          this.hoveredMarker = undefined;
          this.context.getBaseElement().style.cursor = 'auto';
          this.context.getBaseElement().title = '';
        }
        break;
      }

      if (collisions.length > 0) {
        // 社外由来注記のレイヤーより手前で当たり判定があった場合は何もしない
        return;
      }
    }
  }

  /**
   * 地図に対するマウスイベントかを判定
   * @param ev MouseEvent
   * @returns 地図に対するマウスイベントか
   */
  private isOnMap(ev: UIEvent): boolean {
    for (const value of ev.composedPath()) {
      const classList = (value as Element).classList;
      if (classList && classList.contains('gia-base-element')) {
        return true;
      }
    }
    return false;
  }

  /**
   * 画面上での座標を3次元空間のレイキャストに変換する
   * @param clientPositionX x座標
   * @param clientPositionY y座標
   * @returns レイキャスト
   */
  private clientPositionToRay(clientPositionX: number, clientPositionY: number): Ray3 {
    const {clientHeight, clientWidth} = this.context.getBaseElement();
    const mapStatus = this.context.getMapStatus();

    const x = (clientPositionX / clientWidth) * 2 - 1;
    const y = -(clientPositionY / clientHeight) * 2 + 1;
    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const halfWidth = (mapStatus.clientWidth * ptu) / 2;
    const halfHeight = (mapStatus.clientHeight * ptu) / 2;
    const upVector = mapStatus.polar.toUpVector3();
    const rightVector = mapStatus.polar.toRightVector3();
    const toTopVector = upVector._normalize()._multiply(halfHeight * y);
    const toRightVector = rightVector._normalize()._multiply(halfWidth * x);
    const toTopRightVector = toTopVector._add(toRightVector);
    const worldCenter = calculateWorldCoordinate(mapStatus.centerLocation);
    const start = worldCenter._add(this.camera.position);

    const cameraTarget = (this.camera as PerspectiveCamera).target;
    const direction = cameraTarget._subtract(this.camera.position)._add(toTopRightVector);

    const ray = new Ray3(start, direction);
    return ray;
  }

  /**
   * MarkerGroupObjectのリストについて重なり間引き
   * @param camera Camera
   * @param cameraTargetPosition 中心位置
   * @returns {void}
   */
  private cullInMarkerGroupObjects(camera: Camera, cameraTargetPosition: Vector3): void {
    for (const groupObj of this.markerGroupObjects.values()) {
      const markerObjects = groupObj.markerObjects;
      for (const markerObj of markerObjects) {
        const topLeft = camera.worldToClient(markerObj.collider.rect.topLeft._subtract(cameraTargetPosition));
        const topRight = camera.worldToClient(markerObj.collider.rect.topRight._subtract(cameraTargetPosition));
        const bottomLeft = camera.worldToClient(markerObj.collider.rect.bottomLeft._subtract(cameraTargetPosition));
        const bottomRight = camera.worldToClient(markerObj.collider.rect.bottomRight._subtract(cameraTargetPosition));

        if (!topLeft || !topRight || !bottomLeft || !bottomRight) {
          continue;
        }

        // 同じ見た目 / 異なる見た目のアイコンで衝突判定を2回
        const isSpacefulWithSameIcon = this.helperForSameIcon.canProvideSpace(
          topLeft,
          topRight,
          bottomLeft,
          bottomRight,
          undefined,
          this.colliderMarginForSameIcon
        );
        const isSpacefulWithAllIcon = this.helperForAllIcon.canProvideSpace(
          topLeft,
          topRight,
          bottomLeft,
          bottomRight,
          undefined,
          this.colliderMarginForAllIcon
        );

        markerObj.setVisible(isSpacefulWithSameIcon && isSpacefulWithAllIcon);
      }
      this.helperForSameIcon.clear();
    }
    this.helperForAllIcon.clear();
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (!this.visible) {
      return true;
    }
    for (const marker of this.markerGroupObjects.values()) {
      marker.update(viewMatrix, projectionMatrix);
      marker.draw();
    }

    return true;
  }

  /** @override */
  getCollisions(ray: Ray3): Collision[] {
    const collisions: Collision[] = [];

    for (const group of this.markerGroupObjects.values()) {
      const collidedMarkers = group.getCollidedMarker(ray);
      if (collidedMarkers.length !== 0) {
        const markerObject = collidedMarkers[collidedMarkers.length - 1];
        const collision = new Collision(group, markerObject);
        collisions.push(collision);
      }
    }

    return collisions;
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }

  /** @override */
  destroy(): void {
    this.isDestroyed = true;
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_EXTERNAL_ANNOTATION;
  }

  /**
   * 表示状態を設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  /**
   * クリア処理
   * @returns {void}
   */
  clear(): void {
    this.markerGroupObjects.clear();
    this.markerObjectMap.clear();
  }
}

export {ExternalAnnotationLayer};
