import {Point, Size} from '../../../../gaia/value';
import {TextAnnotationTextureData} from '../../models/annotation/TextAnnotationTextureData';
import potpack from 'potpack';
import {TexturePlaneUVCoordinate} from '../../../engine/geometry/TexturePlaneUVCoordinate';
import {Vector2} from '../../../common/math/Vector2';
import {PaletteFontInfo} from '../../../common/infra/response/AnnotationInfo';
import {MarkAnnotationTextureData} from '../../models/annotation/MarkAnnotationTextureData';
import {ArrayList} from '../../../common/collection/ArrayList';
import {Optional} from '../../../common/types';
import {TextAnnotationData} from '../../models/annotation/TextAnnotationData';
import {AnnotationFontFamilyMap, AnnotationOptions, FontFamily, GenericFontFamily} from '../../../../gaia/types';

export type AnnotationTextureMapping = {
  texture: TexImageSource;
  details: {
    [data: string]: AnnotationMappingDetail;
  };
};
export type AnnotationMappingDetail = {
  size: Size;
  uv: TexturePlaneUVCoordinate;
};
type PotPackBox = {
  w: number;
  h: number;
  x: number;
  y: number;
  key: string;
  font: string;
  scale: number;
  textAscent: number;
};
type TextureData = TextAnnotationTextureData | MarkAnnotationTextureData;

const FONT_BLANK = "15px 'AdobeBlank'";

const GENERIC_FONT_FAMILY_SERIF = 'serif';
const GENERIC_FONT_FAMILY_SANS_SERIF = 'sans-serif';
const GENERIC_FONT_FAMILY_MONOSPACE = 'monospace';
const GENERIC_FONT_FAMILY_CURSIVE = 'cursive';
const GENERIC_FONT_FAMILY_FANTASY = 'fantasy';
const GENERIC_FONT_FAMILY_SYSTEM_UI = 'system-ui';
const GENERIC_FONT_FAMILY_UI_SERIF = 'ui-serif';
const GENERIC_FONT_FAMILY_UI_SANS_SERIF = 'ui-sans-serif';
const GENERIC_FONT_FAMILY_UI_MONOSPACE = 'ui-monospace';
const GENERIC_FONT_FAMILY_UI_ROUNDED = 'ui-rounded';
const GENERIC_FONT_FAMILY_EMOJI = 'emoji';
const GENERIC_FONT_FAMILY_MATH = 'math';
const GENERIC_FONT_FAMILY_FANGSONG = 'fangsong';

const GENERIC_FONT_FAMILY_LIST = [
  GENERIC_FONT_FAMILY_SERIF,
  GENERIC_FONT_FAMILY_SANS_SERIF,
  GENERIC_FONT_FAMILY_MONOSPACE,
  GENERIC_FONT_FAMILY_CURSIVE,
  GENERIC_FONT_FAMILY_FANTASY,
  GENERIC_FONT_FAMILY_SYSTEM_UI,
  GENERIC_FONT_FAMILY_UI_SERIF,
  GENERIC_FONT_FAMILY_UI_SANS_SERIF,
  GENERIC_FONT_FAMILY_UI_MONOSPACE,
  GENERIC_FONT_FAMILY_UI_ROUNDED,
  GENERIC_FONT_FAMILY_EMOJI,
  GENERIC_FONT_FAMILY_MATH,
  GENERIC_FONT_FAMILY_FANGSONG,
];

type DrawCharacterFontInfo = {
  fontFamily: FontFamily;
  width: number;
};
type DrawTileFontInfo = {
  timestamp: number;
  fontInfo: {
    sansSerif: Map<string, DrawCharacterFontInfo>;
    serif: Map<string, DrawCharacterFontInfo>;
    serifBold: Map<string, DrawCharacterFontInfo>;
    sansSerifBold: Map<string, DrawCharacterFontInfo>;
  };
};

const MAX_TEXT_BORDER_WIDTH = 3;
const DEFAULT_MARGIN = 4;
const DEFAULT_TEXT_BORDER_WIDTH = 2;
const DEFAULT_LINE_WIDTH = 1;
const DEFAULT_PADDING = 6;
const DEFAULT_RADIUS = 8;

// 見た目改善のため、注記テクスチャを大きめにラスタライズする際の拡大率
const BIG_TEXTURE_RATIO = 1.5;

/**
 * 注記用テクスチャ生成ヘルパークラス
 */
class AnnotationTextureHelper {
  private textureDataKeyMap: Map<string, TextureData>;
  private margin = DEFAULT_MARGIN;

  /** 文字のフチ線の太さの設定値 */
  private originalTextBorder = DEFAULT_TEXT_BORDER_WIDTH;

  /** originalTextBorderにテクスチャ拡大率やpixelRatioをかけたもの */
  private textBorder: number;

  private lineWidth = DEFAULT_LINE_WIDTH;
  private padding = DEFAULT_PADDING;
  private radius = DEFAULT_RADIUS;

  private fontCheckContext: CanvasRenderingContext2D;

  /**
   * コンストラクタ
   * @param annotationOptions 注記オプション
   */
  constructor(annotationOptions: Optional<AnnotationOptions> = undefined) {
    this.textureDataKeyMap = new Map();

    const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
    this.fontCheckContext = canvas.getContext('2d') as CanvasRenderingContext2D;

    if (annotationOptions && annotationOptions.textBorder) {
      this.originalTextBorder = annotationOptions.textBorder;
      if (this.originalTextBorder > MAX_TEXT_BORDER_WIDTH) {
        this.originalTextBorder = MAX_TEXT_BORDER_WIDTH;
      }
    }
    this.textBorder = this.originalTextBorder * BIG_TEXTURE_RATIO;
  }

  /**
   * Blankフォントがすでにロード済みであるか判定する
   * @param characters 文字列
   * @returns Blankフォントがすでにロード済みであるかどうか
   */
  private hasBlankFont(characters: string): boolean {
    this.fontCheckContext.font = FONT_BLANK;
    const width = this.fontCheckContext.measureText(characters).width;
    return width <= 0;
  }

  /**
   * ラベル化
   * @param fontFamily フォントファミリー
   * @returns フォントファミリーをラベルに変えたもの
   */
  private toLabel(fontFamily: FontFamily): string {
    if (typeof fontFamily === 'number') {
      if (fontFamily < 0 || fontFamily >= GENERIC_FONT_FAMILY_LIST.length) {
        return '';
      }
      return GENERIC_FONT_FAMILY_LIST[fontFamily];
    }
    if (typeof fontFamily === 'string') {
      return `'${fontFamily}'`;
    }
    return '';
  }

  /**
   * フォントファミリーのリストを抽出する
   * @param fontId フォントID
   * @param fontFamilyMap フォントファミリーのマップ
   * @returns 抽出したフォントファミリーのリスト
   */
  extractFontFamilyList(fontId: number, fontFamilyMap: AnnotationFontFamilyMap): Optional<FontFamily[]> {
    switch (fontId) {
      case 0:
        return fontFamilyMap.sansSerif;
      case 1:
        return fontFamilyMap.serif;
      case 2:
        return fontFamilyMap.serifBold;
      case 3:
        return fontFamilyMap.sansSerifBold;
      default:
        return undefined;
    }
  }

  /**
   * 注記文字に適応されたフォント名と幅を返す
   * @param textDataList 注記文字列データのリスト
   * @param fontFamilyMap フォントIDに対応するフォントファミリーのリスト
   * @returns DrawTileFontInfo
   */
  createDrawTileFontInfo(
    textDataList: TextAnnotationData[],
    fontFamilyMap: AnnotationFontFamilyMap
  ): Optional<DrawTileFontInfo> {
    const characters = textDataList.map((textData: TextAnnotationData) => textData.textureData.appearance).join('');
    if (!this.hasBlankFont(characters)) {
      return undefined;
    }

    const timestamp: number = Math.floor(performance.now());
    const sansSerifCharacterFontInfo: Map<string, DrawCharacterFontInfo> = new Map();
    const serifCharacterFontInfo: Map<string, DrawCharacterFontInfo> = new Map();
    const serifBoldCharacterFontInfo: Map<string, DrawCharacterFontInfo> = new Map();
    const sansSerifBoldCharacterFontInfo: Map<string, DrawCharacterFontInfo> = new Map();
    for (const textData of textDataList) {
      const fontId = textData.textureData.font.id;
      const fontFamilyList = this.extractFontFamilyList(fontId, fontFamilyMap);
      if (!fontFamilyList) {
        continue;
      }
      const lastFontFamily = fontFamilyList[fontFamilyList.length - 1];
      for (const character of textData.textureData.appearance) {
        for (const fontFamily of fontFamilyList) {
          this.fontCheckContext.font = `15px ${this.toLabel(fontFamily)}, 'AdobeBlank'`;
          const width = this.fontCheckContext.measureText(character).width;
          if (width > 0 || fontFamily === lastFontFamily) {
            const characterFontInfo =
              fontId === 1
                ? serifCharacterFontInfo
                : fontId === 2
                ? serifBoldCharacterFontInfo
                : fontId === 3
                ? sansSerifBoldCharacterFontInfo
                : sansSerifCharacterFontInfo;
            characterFontInfo.set(character, {
              fontFamily,
              width,
            });
            break;
          }
        }
      }
    }
    return {
      timestamp,
      fontInfo: {
        sansSerif: sansSerifCharacterFontInfo,
        serif: serifCharacterFontInfo,
        serifBold: serifBoldCharacterFontInfo,
        sansSerifBold: sansSerifBoldCharacterFontInfo,
      },
    };
  }

  /**
   * 前回作成されたフォント情報を更新する
   * @param drawTileFontInfo 前回作成したフォント情報
   * @param fontFamilyMap フォントIDに対応するフォントファミリーのリスト
   * @param swapInterval フォントの切り替わりを判定する間隔
   * @returns 更新したフォント情報
   */
  recreateDrawTileFontInfo(
    drawTileFontInfo: DrawTileFontInfo,
    fontFamilyMap: AnnotationFontFamilyMap,
    swapInterval: number
  ): Optional<DrawTileFontInfo> {
    const characters: string = Object.values(drawTileFontInfo.fontInfo)
      .map((characterFontInfo) => [...characterFontInfo.keys()])
      .flat()
      .join('');
    if (!this.hasBlankFont(characters)) {
      return undefined;
    }

    const timestamp: number = Math.floor(performance.now());
    if (timestamp - drawTileFontInfo.timestamp < swapInterval) {
      return undefined;
    }
    drawTileFontInfo.timestamp = timestamp;
    let updated = false;
    for (const [fontType, characterFontMap] of Object.entries(drawTileFontInfo.fontInfo)) {
      const fontFamilyList =
        fontType === 'serif'
          ? fontFamilyMap.serif
          : fontType === 'serifBold'
          ? fontFamilyMap.serifBold
          : fontType === 'sansSerifBold'
          ? fontFamilyMap.sansSerifBold
          : fontFamilyMap.sansSerif;
      for (const [character, drawCharacterFontInfo] of characterFontMap.entries()) {
        for (const fontFamily of fontFamilyList) {
          this.fontCheckContext.font = `15px ${this.toLabel(fontFamily)}, 'AdobeBlank'`;
          const width = this.fontCheckContext.measureText(character).width;
          if (width > 0) {
            if (fontFamily !== drawCharacterFontInfo.fontFamily) {
              updated = true;
            }
            characterFontMap.set(character, {
              fontFamily,
              width,
            });
            break;
          }
        }
      }
    }

    if (!updated) {
      return undefined;
    }

    return drawTileFontInfo;
  }

  /**
   * タイル単位で一枚のテキスト注記用テクスチャを生成
   * @param dataList 対象のTextAnnotationTextureDataリスト
   * @param pixelRatio DevicePixelRatio
   * @param fontFamilyMap 各フォントIDに対応するフォントファミリーのリスト
   * @returns AnnotationTextureMapping
   */
  createTextTextureByTile(
    dataList: TextAnnotationTextureData[],
    pixelRatio: number,
    fontFamilyMap: AnnotationFontFamilyMap
  ): Optional<AnnotationTextureMapping> {
    const characters: string = dataList.map((data: TextAnnotationTextureData) => data.appearance).join('');
    if (!this.hasBlankFont(characters)) {
      return undefined;
    }
    this.textBorder = this.originalTextBorder * BIG_TEXTURE_RATIO * pixelRatio;
    this.lineWidth = DEFAULT_LINE_WIDTH * pixelRatio;
    this.padding = DEFAULT_PADDING * pixelRatio;
    this.radius = DEFAULT_RADIUS * pixelRatio;

    this.textureDataKeyMap.clear();
    const details: {[data: string]: AnnotationMappingDetail} = {};
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return {
        texture: canvas,
        details,
      };
    }

    // 各注記テクスチャを正方形に収める
    const boxes: Array<{w: number; h: number; key: string; font: string; scale: number; textAscent: number}> = [];
    for (const data of dataList) {
      const splitedText = data.appearance.split('\\n');
      const fontId = data.font.id;
      const fontFamilyList =
        fontId === 1
          ? fontFamilyMap.serif
          : fontId === 2
          ? fontFamilyMap.serifBold
          : fontId === 3
          ? fontFamilyMap.sansSerifBold
          : fontFamilyMap.sansSerif;
      const largeFont = this.createFontSettingsString(data.font, data.size * BIG_TEXTURE_RATIO, fontFamilyList);
      const largeSize = this.calculateDrawTextSize(ctx, splitedText, data, largeFont);
      const font = this.createFontSettingsString(data.font, data.size, fontFamilyList);
      const size = this.calculateDrawTextSize(ctx, splitedText, data, font);

      const textAscent = ctx.measureText(data.appearance).actualBoundingBoxAscent;
      const scale = size.width / largeSize.width;
      boxes.push({
        w: largeSize.width,
        h: largeSize.height,
        key: data.getCacheKey(),
        font: largeFont,
        scale,
        textAscent,
      });
      this.textureDataKeyMap.set(data.getCacheKey(), data);
    }
    const {w: canvasWidth, h: canvasHeight} = potpack(boxes);
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const convertedBoxes: PotPackBox[] = [];
    boxes.map((box) => {
      convertedBoxes.push(box as PotPackBox);
    });

    // テクスチャを生成
    for (const box of convertedBoxes) {
      const {w: width, h: height, x, y, key, font, scale} = box;
      const data = this.textureDataKeyMap.get(key);
      if (!data) {
        continue;
      }

      this.drawTextTexture(ctx, data, box, font);
      const uv = new TexturePlaneUVCoordinate(
        new Vector2(x / canvasWidth, y / canvasHeight),
        new Vector2(x / canvasWidth, (y + height) / canvasHeight),
        new Vector2((x + width) / canvasWidth, y / canvasHeight),
        new Vector2((x + width) / canvasWidth, (y + height) / canvasHeight)
      );

      details[key] = {size: new Size(height * scale, width * scale), uv};
    }

    return {
      texture: canvas,
      details,
    };
  }

  /**
   * フォント設定用の文字列作成
   * @param fontInfo PaletteFontInfo
   * @param textSize 文字サイズ
   * @param fontFamilyList フォント
   * @returns フォント設定
   */
  private createFontSettingsString(fontInfo: PaletteFontInfo, textSize: number, fontFamilyList: FontFamily[]): string {
    const fontSettingsArray: string[] = [];
    if (fontInfo.italic) {
      fontSettingsArray.push('italic');
    }
    if (fontInfo.bold) {
      fontSettingsArray.push('bold');
    }
    fontSettingsArray.push(`${textSize}px`);
    fontSettingsArray.push(fontFamilyList.map((fontFamily: FontFamily) => this.toLabel(fontFamily)).join(', '));
    return fontSettingsArray.join(' ');
  }

  /**
   * 描画後の文字列サイズを計算
   * @param ctx テキスト用キャンバスのCanvasRenderingContext2D
   * @param splitedText 改行コード毎に分割した文字列
   * @param data TextAnnotationTextureData
   * @param fontSettings フォント設定
   * @returns 文字列サイズ
   */
  private calculateDrawTextSize(
    ctx: CanvasRenderingContext2D,
    splitedText: string[],
    data: TextAnnotationTextureData,
    fontSettings: string
  ): Size {
    if (data.appearance.length === 0) {
      return new Size(0, 0);
    }

    ctx.font = fontSettings;
    ctx.textBaseline = 'top';

    let appearanceW = 0;
    let maxHeight = 0;
    for (const text of splitedText) {
      const textMeasure = ctx.measureText(text);
      if (appearanceW < textMeasure.width) {
        appearanceW = textMeasure.width;
      }
      const height = textMeasure.actualBoundingBoxDescent + textMeasure.actualBoundingBoxAscent;
      if (maxHeight < height) {
        maxHeight = height;
      }
    }
    let appearanceH = maxHeight * splitedText.length;

    if (data.font.underline) {
      appearanceH += this.lineWidth * splitedText.length;
    }
    const italicOverHang = data.font.italic ? 5 : 0;
    appearanceW += italicOverHang;

    let offset = this.margin * 2;
    if (!(data.noteType === 'normal' || data.noteType === 'bordered-text')) {
      offset += this.padding * 2;
    }

    switch (data.noteType) {
      case 'bordered-rect':
      case 'bordered-round-rect':
      case 'bordered-balloon':
      case 'bordered-round-balloon':
        offset += this.lineWidth * 2; // 縁線の分ずらす
        break;
      case 'bordered-text':
        offset += this.textBorder * 2;
    }

    return new Size(appearanceH + offset, appearanceW + offset);
  }

  /**
   * テキスト注記テクスチャを描画
   * @param ctx テキスト用キャンバスのCanvasRenderingContext2D
   * @param data 描画対象のTextAnnotationTextureData
   * @param box 描画対象に対応するPotPackBox
   * @param fontSettings フォント設定
   * @returns {void}
   */
  private drawTextTexture(
    ctx: CanvasRenderingContext2D,
    data: TextAnnotationTextureData,
    box: PotPackBox,
    fontSettings: string
  ): void {
    let textStartOffset = this.margin;

    // 座布団
    // 座布団のフチ線は半透明にしない
    const matBorderColorData = {
      r: data.color.r,
      g: data.color.g,
      b: data.color.b,
      a: 1,
    };

    const matBackgroundAlpha = data.backgroundColor.a / 255;
    const matBackgroundColorData = {
      r: data.backgroundColor.r * matBackgroundAlpha,
      g: data.backgroundColor.g * matBackgroundAlpha,
      b: data.backgroundColor.b * matBackgroundAlpha,
      a: matBackgroundAlpha,
    };

    const matSize = new Size(box.h - this.margin * 2, box.w - this.margin * 2);
    if (!(data.noteType === 'normal' || data.noteType === 'bordered-text')) {
      textStartOffset += this.padding;
      const backgroundColor = `rgba(${matBackgroundColorData.r}, ${matBackgroundColorData.g}, ${matBackgroundColorData.b}, ${matBackgroundColorData.a})`;
      let matBorderColor: string | undefined = undefined;
      let isRoundCorner = false;

      // TODO バルーン表示に対応する
      switch (data.noteType) {
        case 'bordered-rect':
        case 'bordered-balloon':
          matBorderColor = `rgba(${matBorderColorData.r}, ${matBorderColorData.g}, ${matBorderColorData.b}, ${matBorderColorData.a})`;
          textStartOffset += this.lineWidth;
          break;
        case 'round-rect':
        case 'round-balloon':
          isRoundCorner = true;
          break;
        case 'bordered-round-rect':
        case 'bordered-round-balloon':
          matBorderColor = `rgba(${matBorderColorData.r}, ${matBorderColorData.g}, ${matBorderColorData.b}, ${matBorderColorData.a})`;
          isRoundCorner = true;
          textStartOffset += this.lineWidth;
      }
      this.drawMat(
        ctx,
        new Point(box.x + this.margin, box.y + this.margin),
        matSize,
        backgroundColor,
        matBorderColor,
        isRoundCorner
      );
    }

    // テキスト
    // テキスト・テキストのフチ線は半透明にしない
    const textColorData = {
      r: data.color.r,
      g: data.color.g,
      b: data.color.b,
      a: 1,
    };

    const textBorderColorData = {
      r: data.backgroundColor.r,
      g: data.backgroundColor.g,
      b: data.backgroundColor.b,
      a: 1,
    };

    const textMainColor = `rgba(${textColorData.r}, ${textColorData.g}, ${textColorData.b}, ${textColorData.a})`;
    let borderColor: string | undefined = undefined;
    if (data.noteType === 'normal') {
      borderColor = `rgba(255, 255, 255, 1)`;
    } else if (data.noteType === 'bordered-text') {
      borderColor = `rgba(${textBorderColorData.r}, ${textBorderColorData.g}, ${textBorderColorData.b}, ${textBorderColorData.a})`;
      textStartOffset += this.textBorder;
    }
    // textBaseLineより上にはみ出す分だけずらす
    const textStartPoint = new Point(box.x + textStartOffset, box.y + textStartOffset + box.textAscent);
    this.drawText(ctx, data.appearance.split('\\n'), textStartPoint, fontSettings, textMainColor, borderColor);

    // 下線
    if (data.font.underline) {
      this.drawUnderLine(
        ctx,
        textMainColor,
        data.isHorizon,
        new Size(box.h - textStartOffset * 2, box.w - textStartOffset * 2),
        textStartPoint
      );
    }
  }

  /**
   * テキスト描画
   * @param ctx テキスト用キャンバスのCanvasRenderingContext2D
   * @param splitedText 改行コード毎に分割した文字列
   * @param start 描画開始位置
   * @param fontSettings フォント設定
   * @param mainColor メイン色
   * @param borderColor 縁取り色
   * @returns {void}
   */
  private drawText(
    ctx: CanvasRenderingContext2D,
    splitedText: string[],
    start: Point,
    fontSettings: string,
    mainColor: string,
    borderColor?: string
  ): void {
    ctx.font = fontSettings;
    ctx.textBaseline = 'top';
    const heightPerLine = this.calculateHeightPerLine(ctx, splitedText);

    for (let i = 0, length = splitedText.length; i < length; i++) {
      if (borderColor) {
        if (this.originalTextBorder >= 1 && this.originalTextBorder <= 2) {
          ctx.strokeStyle = borderColor;
          ctx.lineWidth = this.textBorder * BIG_TEXTURE_RATIO;
          ctx.strokeText(splitedText[i], start.x, start.y + heightPerLine * i);
        } else {
          ctx.fillStyle = borderColor;
          for (let radius = 1; radius < this.textBorder; radius++) {
            const maxAngleIndex = 8;
            for (let angleIndex = 0; angleIndex < maxAngleIndex; angleIndex++) {
              const radian = (angleIndex / maxAngleIndex) * 2.0 * Math.PI;
              const offsetX = radius * Math.cos(radian);
              const offsetY = radius * Math.sin(radian);
              ctx.fillText(splitedText[i], start.x + offsetX, start.y + heightPerLine * i + offsetY);
            }
          }
        }
      }
      ctx.fillStyle = mainColor;
      ctx.fillText(splitedText[i], start.x, start.y + heightPerLine * i);
    }
  }

  /**
   * 1行あたりの高さを求める
   * @param ctx テキスト用キャンバスのCanvasRenderingContext2D
   * @param splitedText 改行コード毎に分割した文字列
   * @returns 1行単位の最大の高さ
   */
  private calculateHeightPerLine(ctx: CanvasRenderingContext2D, splitedText: string[]): number {
    let maxHeight = 0;
    for (const text of splitedText) {
      const textMeasure = ctx.measureText(text);
      const height = textMeasure.actualBoundingBoxDescent + textMeasure.actualBoundingBoxAscent;
      if (maxHeight < height) {
        maxHeight = height;
      }
    }
    return maxHeight;
  }

  /**
   * 下線の描画
   * @param ctx テキスト用キャンバスのCanvasRenderingContext2D
   * @param color 色
   * @param isHorizon 横書きフラグ
   * @param drawTextSize 描画後文字列サイズ
   * @param textTopLeft 文字列描画開始位置
   * @returns {void}
   */
  private drawUnderLine(
    ctx: CanvasRenderingContext2D,
    color: string,
    isHorizon: boolean,
    drawTextSize: Size,
    textTopLeft: Point
  ): void {
    ctx.strokeStyle = color;
    ctx.lineWidth = this.lineWidth;
    ctx.beginPath();
    if (isHorizon) {
      ctx.moveTo(textTopLeft.x, textTopLeft.y + drawTextSize.height);
      ctx.lineTo(textTopLeft.x + drawTextSize.width, textTopLeft.y + drawTextSize.height);
    } else {
      ctx.moveTo(textTopLeft.x + drawTextSize.width, textTopLeft.y);
      ctx.lineTo(textTopLeft.x + drawTextSize.width, textTopLeft.y + drawTextSize.height);
    }
    ctx.stroke();
  }

  /**
   * 座布団描画
   * @param ctx テキスト用キャンバスのCanvasRenderingContext2D
   * @param start 描画開始位置
   * @param size 座布団サイズ
   * @param fillColor 塗りつぶし色
   * @param borderColor 縁取り色
   * @param isRoundCorner 角丸にするか
   * @returns {void}
   */
  private drawMat(
    ctx: CanvasRenderingContext2D,
    start: Point,
    size: Size,
    fillColor: string,
    borderColor?: string,
    isRoundCorner = false
  ): void {
    if (isRoundCorner) {
      this.drawRoundRect(ctx, start.x, start.y, size.width, size.height, this.radius, fillColor, borderColor);
      return;
    }

    ctx.fillStyle = fillColor;
    ctx.lineWidth = this.lineWidth;
    ctx.fillRect(start.x, start.y, size.width, size.height);
    if (borderColor) {
      ctx.strokeStyle = borderColor;
      ctx.strokeRect(start.x, start.y, size.width, size.height);
    }
    return;
  }

  /**
   * 角丸の矩形を描画
   * @param ctx テキスト用キャンバスのCanvasRenderingContext2D
   * @param x 矩形の左上X座標
   * @param y 矩形の左上Y座標
   * @param width 矩形幅
   * @param height 矩形高さ
   * @param radius 半径
   * @param fillColor 塗りつぶし色
   * @param borderColor 縁取り色
   * @returns {void}
   */
  private drawRoundRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    fillColor: string,
    borderColor?: string
  ): void {
    ctx.fillStyle = fillColor;

    ctx.beginPath();
    ctx.moveTo(x, y + radius);
    ctx.arc(x + radius, y + height - radius, radius, Math.PI, Math.PI * 0.5, true);
    ctx.arc(x + width - radius, y + height - radius, radius, Math.PI * 0.5, 0, true);
    ctx.arc(x + width - radius, y + radius, radius, 0, Math.PI * 1.5, true);
    ctx.arc(x + radius, y + radius, radius, Math.PI * 1.5, Math.PI, true);
    ctx.closePath();
    ctx.fill();

    if (borderColor) {
      ctx.strokeStyle = borderColor;
      ctx.lineWidth = this.lineWidth;
      ctx.stroke();
    }
  }

  /**
   * タイル単位で一枚の国道アイコン用テクスチャを生成
   * @param dataList 対象のMarkAnnotationTextureDataリスト
   * @param matTexture 座布団テクスチャ
   * @returns AnnotationTextureMapping
   */
  createMarkTextureByTile(
    dataList: ArrayList<MarkAnnotationTextureData>,
    matTexture: CanvasImageSource
  ): AnnotationTextureMapping {
    this.textureDataKeyMap.clear();
    const details: {[data: string]: AnnotationMappingDetail} = {};
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return {texture: canvas, details};
    }

    // 各注記テクスチャを正方形に収める
    const boxes: Array<{w: number; h: number; key: string}> = [];
    for (const data of dataList) {
      boxes.push({w: data.imageSize.width, h: data.imageSize.height, key: data.getCacheKey()});
      this.textureDataKeyMap.set(data.getCacheKey(), data);
    }
    const {w: canvasWidth, h: canvasHeight} = potpack(boxes);
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const convertedBoxes: PotPackBox[] = [];
    boxes.map((box) => {
      convertedBoxes.push(box as PotPackBox);
    });

    // テクスチャを生成
    for (const box of convertedBoxes) {
      const {w: width, h: height, x, y, key} = box;
      const data = this.textureDataKeyMap.get(key);
      if (!(data instanceof MarkAnnotationTextureData)) {
        continue;
      }

      ctx.drawImage(
        matTexture,
        data.cropStart.x,
        data.cropStart.y,
        data.imageSize.width,
        data.imageSize.height,
        x,
        y,
        width,
        height
      );

      // 国道アイコンは固定で標準フォント
      ctx.font = this.createFontSettingsString(data.font, data.size * 2, [GenericFontFamily.SansSerif]);
      ctx.fillStyle = `rgba(${data.color.r}, ${data.color.g}, ${data.color.b}, ${data.color.a})`;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.fillText(data.appearance, x + width / 2, y + height / 2);
      const uv = new TexturePlaneUVCoordinate(
        new Vector2((x + this.margin) / canvasWidth, (y + this.margin) / canvasHeight),
        new Vector2((x + this.margin) / canvasWidth, (y + height - this.margin) / canvasHeight),
        new Vector2((x + width - this.margin) / canvasWidth, (y + this.margin) / canvasHeight),
        new Vector2((x + width - this.margin) / canvasWidth, (y + height - this.margin) / canvasHeight)
      );
      details[key] = {size: new Size(height, width), uv};
    }

    return {texture: canvas, details};
  }
}

export {AnnotationTextureHelper, DrawTileFontInfo, DrawCharacterFontInfo};
