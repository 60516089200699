import {MapStatus} from '../../../models/MapStatus';
import {MapRenderKitController, TileRenderKitMapping} from '../MapRenderKitController';
import {LAYER_NAME_TRAINROUTE, TrainRouteLayer} from '../../../layer/TrainRouteLayer';
import {GaiaContext} from '../../../GaiaContext';
import {TrainRouteLoader} from '../../../loader/TrainRouteLoader';
import {
  isTrainRouteFeature,
  TrainRouteFeature,
  isTrainRouteInfo,
} from '../../../../common/infra/response/TrainRouteInfo';
import {LatLng} from '../../../../../gaia/value';
import {calculateWorldCoordinate, getZoomLevelFixFunc} from '../../../utils/MapUtil';
import {ArrayList} from '../../../../common/collection/ArrayList';
import {TileNumber} from '../../../models/TileNumber';
import {Camera} from '../../../../engine/camera/Camera';
import {aspectToFogDistanceRatio} from '../../../layer/FogObjectLayer';
import {ZoomLevelFixFunc, Optional} from '../../../../common/types';
import {TrainRouteCondition} from '../../../../../gaia/value/TrainRouteCondition';
import {TrainRouteClickListener} from '../../../../../gaia/types';
import {Ray3} from '../../../../common/math/Ray3';
import {Collision} from '../../../../engine/collision/Collision';

// 更新の間隔（単位はミリ秒）
const INTERVAL_UPDATE_MS = 600;

// TODO: データがタイル単位かつseedから取得する機能のRenderKitを抽象化する
/**
 * 鉄道路線図オブジェクトを扱う描画キット
 */
class TrainRouteObjectRenderKit {
  private context: GaiaContext;
  private camera: Camera;
  /** 機能の利用可否 */
  private isEnable = true;

  private trainRouteLoader: TrainRouteLoader;
  private trainRouteLayer: TrainRouteLayer;

  private status?: MapStatus;
  private tileList?: ArrayList<TileNumber>;

  private previousCenter: LatLng;
  private previousZoomLevel: number;
  private readonly fixIntZoomLevel: ZoomLevelFixFunc;

  private previousChangedTime: number;
  private nextUpdate: Optional<NodeJS.Timeout>;
  private running: boolean;

  readonly getAllCollisions: (ray: Ray3) => Map<string, Collision[]>;

  private condition?: TrainRouteCondition;

  // TODO: レスポンスから生成したTrainFeatureObjectをキャッシュとして持たせるか検討

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKitCtl MapRenderKitController
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    this.context = context;
    this.camera = camera;

    this.trainRouteLoader = new TrainRouteLoader(context, () => {
      if (this.status && this.tileList) {
        this.updateDrawObjects(this.status, this.tileList);
      }
    });
    this.trainRouteLayer = new TrainRouteLayer(context, this, camera);

    this.previousCenter = context.getMapStatus().centerLocation;
    this.previousZoomLevel = this.context.getMapStatus().zoomLevel;
    this.fixIntZoomLevel = getZoomLevelFixFunc(this.context);

    this.previousChangedTime = performance.now();
    this.running = false;

    this.getAllCollisions = (ray: Ray3): Map<string, Collision[]> => renderKitCtl.getAllCollisions(ray);

    context.getGaIAConfiguration().then((config) => {
      this.isEnable = config.features.trainroute;
      if (this.isEnable) {
        this.executeLoader();
      } else {
        this.clear();
      }
    });
  }

  /**
   * 鉄道路線図のオプションを設定
   * @param condition 表示設定
   * @returns {void}
   */
  setTrainRouteCondition(condition?: TrainRouteCondition): void {
    this.condition = condition;
    if (condition) {
      this.trainRouteLayer.setVisible(true);
      this.trainRouteLayer.setTrainRouteCallback(condition.callback);
    } else {
      this.trainRouteLayer.setVisible(false);
      this.trainRouteLoader.clear();
      this.trainRouteLayer.clear();
    }
  }

  /**
   * 鉄道路線図クリックリスナーの設定
   * @param listener リスナー関数
   * @returns {void}
   */
  setTrainRouteClickListener(listener: TrainRouteClickListener): void {
    this.trainRouteLayer.setTrainRouteClickListener(listener);
  }

  /**
   * RenderKit特定用キー
   */
  get identicalName(): keyof TileRenderKitMapping {
    return LAYER_NAME_TRAINROUTE;
  }

  /**
   * 鉄道路線図レイヤーを取得
   * @returns 鉄道路線図レイヤー
   */
  getLayer(): TrainRouteLayer {
    return this.trainRouteLayer;
  }

  /**
   * キャッシュクリア
   * @returns {void}
   */
  private clear(): void {
    this.trainRouteLoader.clear();
    this.trainRouteLayer.clear();
  }

  /**
   * ローダーのリクエスト実行
   * @returns {void}
   */
  executeLoader(): void {
    this.trainRouteLoader.executeRequest();
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void {
    if (!this.isEnable) {
      return;
    }
    if (!this.condition) {
      return;
    }

    if (!this.condition.zoomRange.isInRange(mapStatus.zoomLevel)) {
      this.trainRouteLayer.setVisible(false);
      return;
    }

    this.trainRouteLayer.setVisible(true);

    this.status = mapStatus;
    this.tileList = tileList;
    this.trainRouteLoader.jumpUpCacheSize(tileList.size() * 2);

    // ズームレベル整数値・中心緯度経度の変化量が閾値を超えていたらリクエストキュークリア
    const isChangeCenterLat = Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) > 0.1;
    const isChangeCenterLng = Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) > 0.1;
    const isChangeZoomLevel =
      this.fixIntZoomLevel(mapStatus.zoomLevel) !== this.fixIntZoomLevel(this.previousZoomLevel);
    if (isChangeZoomLevel || isChangeCenterLat || isChangeCenterLng) {
      this.trainRouteLoader.clearRequestQueue();
    }

    const littleChangedLat =
      Math.abs(this.previousCenter.lat - mapStatus.centerLocation.lat) * 2 ** (mapStatus.zoomLevel + 16) > 0.00000001;
    const littleChangedLon =
      Math.abs(this.previousCenter.lng - mapStatus.centerLocation.lng) * 2 ** (mapStatus.zoomLevel + 16) > 0.00000001;
    const littleChangedZoomLevel = mapStatus.zoomLevel !== this.previousZoomLevel;
    this.previousZoomLevel = mapStatus.zoomLevel;
    this.previousCenter = mapStatus.centerLocation;
    if (littleChangedLat || littleChangedLon || littleChangedZoomLevel) {
      this.previousChangedTime = performance.now();
    }

    if (performance.now() - this.previousChangedTime < INTERVAL_UPDATE_MS) {
      this.clearNextUpdate();
      this.nextUpdate = setTimeout(() => {
        this.processTrainRouteData();
        this.clearNextUpdate();
      }, INTERVAL_UPDATE_MS);
    } else {
      this.processTrainRouteData();
    }

    // リクエスト
    const requestTiles: TileNumber[] = [];
    const requiredInfo: Map<TileNumber, TrainRouteFeature[]> = new Map();

    const centerPosition = calculateWorldCoordinate(mapStatus.centerLocation);
    const cameraDistance = this.camera.position.magnitude();
    for (const tileNumber of tileList) {
      const latLng = tileNumber.centerLocation;
      const position = calculateWorldCoordinate(latLng);
      const distance = position._subtract(centerPosition).magnitude();
      const distanceRatio = aspectToFogDistanceRatio(mapStatus.aspect);
      if (distance > cameraDistance * distanceRatio * 0.9) {
        continue;
      }

      const cache = this.trainRouteLoader.getCache(tileNumber);
      if (!cache || !isTrainRouteInfo(cache)) {
        requestTiles.push(tileNumber);
        continue;
      }

      const featureList: TrainRouteFeature[] = [];
      for (const feature of cache.features) {
        if (!isTrainRouteFeature(feature)) {
          continue;
        }

        featureList.push(feature);
      }

      requiredInfo.set(tileNumber, featureList);
    }

    this.trainRouteLayer.update(mapStatus, requiredInfo);

    if (requestTiles.length === 0) {
      return;
    }
    this.trainRouteLoader.addRequestQueue(requestTiles);
  }

  /**
   * setTimeoutで仕込んでおいた地図更新を取り消す
   * @returns {void}
   */
  private clearNextUpdate(): void {
    if (!this.nextUpdate) {
      return;
    }
    clearTimeout(this.nextUpdate);
    this.nextUpdate = null;
  }

  /**
   * 後回しにしていたテクスチャ化などの処理を実行する
   * @returns {void}
   */
  private processTrainRouteData(): void {
    if (this.running || !this.status || !this.tileList) {
      return;
    }
    this.running = true;
    this.trainRouteLoader.processTrainRouteData();
    this.updateDrawObjects(this.status, this.tileList);
    this.running = false;
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.trainRouteLayer.destroy();
    this.trainRouteLoader.destroy();
  }
}

export {TrainRouteObjectRenderKit};
