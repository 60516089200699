import {mat4} from 'gl-matrix';
import {Color} from '../../../gaia/value';
import {CenterMarkerCondition} from '../../../gaia/value/CenterMarkerCondition';
import {Quaternion} from '../../common/math/Quaternion';
import {Ray3} from '../../common/math/Ray3';
import {Vector3} from '../../common/math/Vector3';
import {Collision} from '../../engine/collision/Collision';
import {CustomGeometry} from '../../engine/geometry/CustomGeometry';
import {Layer} from '../../engine/layer/Layer';
import {SingleColorMaterial} from '../../engine/material/SingleColorMaterial';
import {Object3D} from '../../engine/object/Object3D';
import {GaiaContext} from '../GaiaContext';
import {MapStatus} from '../models/MapStatus';
import {calculatePixelToUnit} from '../utils/MapUtil';

const LAYER_NAME_CENTER_MARKER = 'centerMarker';

/**
 * 十字マーカー描画レイヤー
 */
class CenterMarkerObjectLayer implements Layer {
  private context: GaiaContext;
  private verticalLine?: Object3D;
  private horizontalLine?: Object3D;
  private verticalMaterial: SingleColorMaterial;
  private horizontalMaterial: SingleColorMaterial;

  private width: number;
  private length: number;

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.context = context;
    this.width = 0;
    this.length = 0;

    const halfSide = 0.5;
    // prettier-ignore
    const geometry = new CustomGeometry(
      [
        -halfSide, halfSide, 0,
        -halfSide, -halfSide, 0,
        halfSide, halfSide, 0,
        halfSide, -halfSide, 0
      ],
      [
        0, 1, 2,
        1, 3, 2,
      ]
    );
    this.verticalMaterial = new SingleColorMaterial(context.getGLContext(), geometry, Color.clear());
    this.horizontalMaterial = new SingleColorMaterial(context.getGLContext(), geometry, Color.clear());
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_CENTER_MARKER;
  }

  /**
   * コンディションを設定
   * @param condition CenterMarkerCondition
   * @returns {void}
   */
  setCondition(condition?: CenterMarkerCondition): void {
    if (!condition && this.horizontalLine && this.verticalLine) {
      // すでに十字マーカーが存在し、これを非表示にする必要がある場合
      this.horizontalLine.setVisible(false);
      this.verticalLine.setVisible(false);
      return;
    }

    if (condition) {
      this.verticalMaterial.setColor(condition.color);
      this.horizontalMaterial.setColor(condition.color);
      this.width = condition.width;
      this.length = condition.length;
    }

    if (condition && !this.horizontalLine && !this.verticalLine) {
      // 十字マーカーを作成する必要がある場合
      const position = Vector3.zero();
      const rotation = Quaternion.identity();
      const scale = Vector3.one();
      this.verticalLine = new Object3D(position, rotation, scale, this.verticalMaterial);
      this.horizontalLine = new Object3D(position, rotation, scale, this.horizontalMaterial);
    }

    if (condition && this.horizontalLine && this.verticalLine) {
      // 十字マーカーが存在し、これを表示する必要がある場合
      this.verticalLine.setVisible(true);
      this.horizontalLine.setVisible(true);
    }
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (
      !this.horizontalLine ||
      !this.verticalLine ||
      !this.horizontalLine.isVisible() ||
      !this.verticalLine.isVisible()
    ) {
      return true;
    }

    this.horizontalLine.update(viewMatrix, projectionMatrix);
    this.horizontalLine.draw();

    this.verticalLine.update(viewMatrix, projectionMatrix);
    this.verticalLine.draw();

    return true;
  }

  /** @override */
  destroy(): void {
    if (this.horizontalLine) {
      this.horizontalLine.destroy();
    }
    if (this.verticalLine) {
      this.verticalLine.destroy();
    }
    this.horizontalMaterial.destroy();
    this.verticalMaterial.destroy();
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    // 十字マーカーに当たり判定は現状不要
    return [];
  }

  /**
   * 更新処理
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    if (
      !this.horizontalLine ||
      !this.verticalLine ||
      !this.horizontalLine.isVisible() ||
      !this.verticalLine.isVisible()
    ) {
      return;
    }

    const ptu = calculatePixelToUnit(mapStatus.zoomLevel);
    const horizontalScale = new Vector3(this.length * ptu, this.width * ptu, 1);
    const verticalScale = new Vector3(this.width * ptu, this.length * ptu, 1);
    this.horizontalLine.setScale(horizontalScale);
    this.verticalLine.setScale(verticalScale);
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {CenterMarkerObjectLayer, LAYER_NAME_CENTER_MARKER};
