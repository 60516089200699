/* eslint-disable @typescript-eslint/no-explicit-any */
import {Color, GeoJsonFigureCondition, LatLng, ZoomRange} from '../../../../gaia/value';
import {
  DEFAULT_GEOJSON_COLOR,
  DEFAULT_GEOJSON_LINE_WEIGHT,
  DEFAULT_GEOJSON_OUTLINE_COLOR,
  DEFAULT_GEOJSON_OUTLINE_WEIGHT,
} from '../../../../gaia/value/GeoJsonFigureCondition';
import {GeoJsonFigureInfo, GeoJsonFigureStyle} from '../objects/GeoJsonFigureInfo';
import {DASH_ARRAY_SOLID} from '../../../../gaia/object/shape/Polyline';
import {RouteArrow} from '../objects/RouteArrow';
import {isFeatureCollection, isLineString} from '../../../../gaia/value/GeoJson';
import {transMillisecToDegree} from '../../../../gaia/util';
import {Vector2} from '../../../common/math/Vector2';

type RouteShapeProperties = {
  ways: string;
  transport_shape?: string;
  section: string;
  inline: RouteShapeLineInfo;
  outline: RouteShapeLineInfo;
  route_no: string;
  indoor?: boolean;
  restriction?: boolean;
};

/**
 * 線の種類から点線のパターンを生成する
 * @param lineStyle 線の種類
 * @param weight 線の太さ（ピクセル）
 * @returns 点線のパターン
 */
const createDashArray = (lineStyle: string): number[] => {
  switch (lineStyle) {
    case 'solid':
      // 実線
      return DASH_ARRAY_SOLID;
    case 'dashed':
      // 破線
      return [2, 1];
    case 'dotted':
      // 点線
      return [1, 2];
    case 'auxiliary':
      // 補助線
      return [2, 2];
    default:
      // 意図しない線種は実線にする
      return DASH_ARRAY_SOLID;
  }
};

/**
 * RouteShapePropertiesのType Guard
 * @param item 判定対象
 * @returns 判定結果
 */
const isRouteShapeProperties = (item: any): item is RouteShapeProperties => {
  return (
    item.ways !== undefined &&
    item.section !== undefined &&
    item.inline !== undefined &&
    item.outline !== undefined &&
    item.route_no !== undefined
  );
};

/**
 * 2つプロパティの見た目が等しいか判定する
 * @param a 1つ目のプロパティ
 * @param b 2つ目のプロパティ
 * @returns 2つプロパティの見た目が等しいかどうか
 */
const isSameAppearanceProperties: (a: Record<string, any>, b: Record<string, any>) => boolean = (
  a: Record<string, any>,
  b: Record<string, any>
) => {
  const checkKeys = ['line_style', 'color', 'width', 'opacity'];
  for (const key of checkKeys) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};

/**
 * route/shapeのGeoJSONで見た目が同じ部分で繋がっている線を1つにまとめる
 * @param geoJsonFigureInfoList 処理するGeoJsonFigureInfoListの配列
 * @returns {void}
 */
const joinLineHasSamePropertiesForRouteShape: (geoJsonFigureInfoList: GeoJsonFigureInfo[]) => void = (
  geoJsonFigureInfoList: GeoJsonFigureInfo[]
) => {
  let previousInlineProperties: Record<string, any> | null = null;
  let previousOutlineProperties: Record<string, any> | null = null;
  for (let index = 0; index < geoJsonFigureInfoList.length; index++) {
    const geoJsonFigureInfo = geoJsonFigureInfoList[index];
    const inlineProperties = geoJsonFigureInfo?.properties?.['inline'];
    const outlineProperties = geoJsonFigureInfo?.properties?.['outline'];
    if (!inlineProperties || !outlineProperties) {
      previousInlineProperties = null;
      previousOutlineProperties = null;
      continue;
    }
    if (!previousInlineProperties || !previousOutlineProperties) {
      previousInlineProperties = inlineProperties;
      previousOutlineProperties = outlineProperties;
      continue;
    }
    const isSameInline = isSameAppearanceProperties(previousInlineProperties, inlineProperties);
    const isSameOutline = isSameAppearanceProperties(previousOutlineProperties, outlineProperties);
    // 同じ見た目かチェック
    if (isSameInline && isSameOutline) {
      const previousGeoJsonFigureInfo = geoJsonFigureInfoList[index - 1];
      const previousPathLength = previousGeoJsonFigureInfo.path.length;
      // 直前のパスの最後の点と、今見ているパスの最初の点が同じかチェック
      if (previousGeoJsonFigureInfo.path[previousPathLength - 1].equals(geoJsonFigureInfo.path[0])) {
        // 直前のパスに、今見ているパスの頂点を追加
        previousGeoJsonFigureInfo.path.splice(previousPathLength, 0, ...geoJsonFigureInfo.path);
        // 今見ているパスは直前のパスに入れたため必要なくなるので削除
        geoJsonFigureInfoList.splice(index, 1);
        // 配列が短くなったので、indexを調整
        index--;
      }
    }
    previousInlineProperties = inlineProperties;
    previousOutlineProperties = outlineProperties;
  }
};

/**
 * route/shapeのGeoJSONで線が途切れている部分があれば、補助線で繋げる
 * @param geoJsonFigureInfoList 補完するGeoJsonFigureInfoListの配列
 * @returns {void}
 */
const complementWarpForRouteShape: (geoJsonFigureInfoList: GeoJsonFigureInfo[]) => void = (
  geoJsonFigureInfoList: GeoJsonFigureInfo[]
) => {
  for (let figureInfoIndex = 1; figureInfoIndex < geoJsonFigureInfoList.length; figureInfoIndex++) {
    const currentPath = geoJsonFigureInfoList[figureInfoIndex].path;
    const currentHeadLatLng = currentPath[0];

    const previousPath = geoJsonFigureInfoList[figureInfoIndex - 1].path;
    const previousTailLatLng = previousPath[previousPath.length - 1];

    // 1つ前の形状の最後の点と、今の形状の最初の点が異なる場合は、それらを直線でつなぐ
    if (!currentHeadLatLng.equals(previousTailLatLng)) {
      const insertionFigureInfo: GeoJsonFigureInfo = new GeoJsonFigureInfo(
        [previousTailLatLng, currentHeadLatLng],
        'Polyline',
        {},
        {
          color: new Color(0.3, 0.3, 0.3, 0.9),
          weight: 7,
          dashArray: [2, 2],
          outLineColor: Color.clear(),
          outLineWeight: 0,
          outLineDashArray: DASH_ARRAY_SOLID,
        }
      );
      geoJsonFigureInfoList.splice(figureInfoIndex, 0, insertionFigureInfo);
      figureInfoIndex++;
    }
  }
};

type RouteShapeLineInfo = {
  line_style: string;
  color: string;
  width: number;
  opacity: number;
};

/**
 * mochaの/route/shape APIから取得したルート線の見た目情報を作成
 * @param properties /route/shapeで取得したGeoJSON内のproperties
 * @param condition GeoJsonFigureCondition
 * @returns ルート線の見た目情報
 */
const createFigureStyleOfRouteShape = (
  properties: RouteShapeProperties,
  condition: GeoJsonFigureCondition
): GeoJsonFigureStyle => {
  let color = condition.inlineOption.color;
  const propertiesColor = Color.fromColorCodeSixHex(properties.inline.color, properties.inline.opacity);
  if (color === DEFAULT_GEOJSON_COLOR && propertiesColor) {
    color = propertiesColor;
  }

  let weight = condition.inlineOption.weight;
  const propertiesWeight = properties.inline.width;
  if (weight === DEFAULT_GEOJSON_LINE_WEIGHT) {
    weight = propertiesWeight;
  }

  let dashArray = condition.inlineOption.dashArray;
  const propertiesDashArray = createDashArray(properties.inline.line_style);
  if (JSON.stringify(dashArray) === JSON.stringify(DASH_ARRAY_SOLID)) {
    dashArray = propertiesDashArray;
  }

  let outLineColor = condition.outlineOption.color;
  const propertiesOutLineColor = Color.fromColorCodeSixHex(properties.outline.color, properties.outline.opacity);
  if (outLineColor === DEFAULT_GEOJSON_OUTLINE_COLOR && propertiesOutLineColor) {
    outLineColor = propertiesOutLineColor;
  }

  let outLineWeight = condition.outlineOption.weight;
  const propertiesOutLineWeight = properties.outline.width;
  if (outLineWeight === DEFAULT_GEOJSON_OUTLINE_WEIGHT) {
    outLineWeight = propertiesOutLineWeight;
  }

  let outLineDashArray = condition.outlineOption.dashArray;
  const propertiesOutLineDashArray = createDashArray(properties.outline.line_style);
  if (JSON.stringify(outLineDashArray) === JSON.stringify(DASH_ARRAY_SOLID)) {
    outLineDashArray = propertiesOutLineDashArray;
  }

  return {
    color,
    weight,
    dashArray,
    outLineColor,
    outLineWeight,
    outLineDashArray,
  };
};

/**
 * ルート線の方向矢印を生成
 * @param condition GeoJsonFigureCondition
 * @returns RouteArrowのリスト
 */
const createRouteArrows = (condition: GeoJsonFigureCondition): RouteArrow[] => {
  const arrows: RouteArrow[] = [];
  if (!condition.showRouteArrow) {
    return arrows;
  }

  const geojson = condition.geoJson;

  if (!isFeatureCollection(geojson)) {
    return arrows;
  }

  for (let i = 0, len = geojson.features.length; i < len; i++) {
    if (i % 2 !== 0) {
      continue;
    }
    const feature = geojson.features[i];
    if (!isRouteShapeProperties(feature.properties)) {
      continue;
    }
    if (feature.properties.inline.line_style !== 'solid') {
      continue;
    }

    if (!isLineString(feature.geometry)) {
      continue;
    }
    if (feature.geometry.coordinates.length <= 1) {
      continue;
    }
    const mid = Math.floor(feature.geometry.coordinates.length / 2);

    const pos =
      condition.coordUnit === 'degree'
        ? new LatLng(feature.geometry.coordinates[mid - 1][1], feature.geometry.coordinates[mid - 1][0])
        : new LatLng(
            transMillisecToDegree(feature.geometry.coordinates[mid - 1][1]),
            transMillisecToDegree(feature.geometry.coordinates[mid - 1][0])
          );

    const vec = new Vector2(
      feature.geometry.coordinates[mid][0] - feature.geometry.coordinates[mid - 1][0],
      feature.geometry.coordinates[mid][1] - feature.geometry.coordinates[mid - 1][1]
    ).normalize();
    const deg = Math.round((Math.atan2(vec.y, vec.x) * 180) / Math.PI);
    const {r, g, b} = Color.fromColorCodeSixHex(feature.properties.inline.color) || Color.green();
    const color = new Color(r * 0.4, g * 0.4, b * 0.4, 1); // 少し色を暗くする
    const zoomRange = arrows.length % 2 === 0 ? new ZoomRange(9, 20) : new ZoomRange(16, 20);
    const arrow = new RouteArrow(pos, (-deg % 360) + 90, color, zoomRange);
    arrows.push(arrow);
  }

  return arrows;
};

export {
  createFigureStyleOfRouteShape,
  isRouteShapeProperties,
  joinLineHasSamePropertiesForRouteShape,
  complementWarpForRouteShape,
  createRouteArrows,
};
