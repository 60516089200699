import {GaiaContext} from '../GaiaContext';
import {MapStatus} from '../models/MapStatus';
import {LatLng} from '../../../gaia/value/LatLng';
import {MapEventMap, MapLocationStatus, GaIAEventListenerOptions} from '../../../gaia/types';
import {GaIAEventListenerHelper} from './GaIAEventListenerHelper';
import {EventListenerFunctionReturnType} from '../../common/types';

type MapEventNameList = keyof MapEventMap;
type EventListenerFunction = (ev: MapLocationStatus) => EventListenerFunctionReturnType;

/**
 * 地図イベント監視・通知クラス
 */
class MapEventObserver {
  private centerLocation: LatLng;
  private zoomLevel: number;

  private readonly helper: GaIAEventListenerHelper<MapEventMap>;

  /**
   * コンストラクタ
   * @param context コンテキスト
   */
  constructor(context: GaiaContext) {
    this.helper = new GaIAEventListenerHelper<MapEventMap>();

    context.addOnMapStatusUpdateListener((status) => this.handleChangeMapStatus(status));
    const status = context.getMapStatus();

    this.centerLocation = status.centerLocation;
    this.zoomLevel = status.zoomLevel;
  }

  /**
   * イベントリスナーを設定
   * @param eventName イベント名
   * @param func イベントハンドラ
   * @param options オプション
   * @returns {void}
   */
  addListener<K extends MapEventNameList>(
    eventName: K,
    func: (ev: MapEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.addListener(eventName, func, options);
  }

  /**
   * イベントリスナーの削除
   * @param eventName イベント名
   * @param func イベントハンドラ
   * @param options オプション
   * @returns {void}
   */
  removeListener<K extends MapEventNameList>(
    eventName: K,
    func: (ev: MapEventMap[K]) => EventListenerFunctionReturnType,
    options?: GaIAEventListenerOptions
  ): void {
    this.helper.removeListener(eventName, func, options);
  }

  /**
   * イベントハンドラの実行
   * @param eventName イベント名
   * @param event イベントオブジェクト
   * @returns {void}
   */
  private trigger<K extends MapEventNameList>(eventName: K, event: MapEventMap[K]): void {
    this.helper.trigger(eventName, event);
  }

  /**
   * 地図ステータス変更のハンドリング
   * @param status 地図ステータス
   * @returns {void}
   */
  private handleChangeMapStatus(status: MapStatus): void {
    // 地図中心
    if (!this.centerLocation.equals(status.centerLocation)) {
      this.trigger('centermoved', status.getMapLocationStatus());
    }

    // ズームレベル
    if (this.zoomLevel !== status.zoomLevel) {
      this.trigger('zoomchanged', status.getMapLocationStatus());
    }

    this.update(status);
  }

  /**
   * 現在の状態を更新
   * @param status 地図ステータス
   * @returns {void}
   */
  private update(status: MapStatus): void {
    this.centerLocation = status.centerLocation;
    this.zoomLevel = status.zoomLevel;
  }
}

export {MapEventObserver};
