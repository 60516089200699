import {mat4} from 'gl-matrix';
import {DEGREE_TO_RADIAN} from '../../common/math/MathConstants';
import {Quaternion} from '../../common/math/Quaternion';
import {Ray3} from '../../common/math/Ray3';
import {Vector3} from '../../common/math/Vector3';
import {Camera} from '../../engine/camera/Camera';
import {Collision} from '../../engine/collision/Collision';
import {Layer} from '../../engine/layer/Layer';
import {PbrMetallicRoughnessMaterial} from '../../engine/material/PbrMetallicRoughnessMaterial';
import {Object3D} from '../../engine/object/Object3D';
import {DepthProgram} from '../../engine/program/DepthProgram';
import {GaiaContext} from '../GaiaContext';
import {LandmarkPlacement} from '../loader/LandmarkLoader';
import {MapStatus} from '../models/MapStatus';
import {MAX_ZOOM_LEVEL} from '../models/TileNumber';
import {LandmarkObjectInfo} from '../render/kit/object/LandmarkObjectRenderKit';
import {calculateWorldCoordinate} from '../utils/MapUtil';

const LAYER_NAME_LANDMARK = 'landmark';

const MIN_MINIATURE_RATIO = 0.0;
const MAX_MINIATURE_RATIO = 1.0;
const DELTA_MINIATURE_RATIO = 0.1;

/**
 * 3Dランドマーク用のレイヤー
 */
class LandmarkObjectLayer implements Layer {
  private context: GaiaContext;
  private camera: Camera;

  private visible: boolean;

  private landmarkObjects: Object3D[];
  private depthObjects: Object3D[];

  private mapStatus: MapStatus;
  private maxZoomLevelMiniature: number;
  private miniatureRatio: number;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param camera Camera
   */
  constructor(context: GaiaContext, camera: Camera) {
    this.context = context;
    this.camera = camera;
    this.visible = true;
    this.landmarkObjects = [];
    this.depthObjects = [];

    this.mapStatus = context.getMapStatus();
    this.maxZoomLevelMiniature = MAX_ZOOM_LEVEL;
    this.miniatureRatio = 0.0;
  }

  /**
   * 表示状態を設定
   * @param visible 表示状態
   * @returns {void}
   */
  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  /** @override */
  updateLayer(viewMatrix: mat4, projectionMatrix: mat4): boolean {
    if (!this.visible) {
      return true;
    }

    for (const landmarkObject of this.landmarkObjects) {
      if (!landmarkObject.isVisible()) {
        continue;
      }
      landmarkObject.update(viewMatrix, projectionMatrix);
      landmarkObject.draw();
    }

    if (DepthProgram.renderTarget) {
      for (const depthObject of this.depthObjects) {
        if (!depthObject.isVisible()) {
          continue;
        }
        depthObject.update(viewMatrix, projectionMatrix);
        depthObject.draw(DepthProgram.renderTarget);
      }
    }

    return true;
  }

  /**
   * ランドマークレイヤーの更新
   * @param mapStatus 地図状態
   * @param maxZoomLevelMiniature ミニチュアモードの最大ズームレベル
   * @returns {void}
   */
  update(mapStatus: MapStatus, maxZoomLevelMiniature: number): void {
    if (!this.visible) {
      return;
    }

    this.mapStatus = mapStatus;
    this.maxZoomLevelMiniature = maxZoomLevelMiniature;

    const isMiniature = mapStatus.zoomLevel <= maxZoomLevelMiniature;
    if (isMiniature && this.miniatureRatio < MAX_MINIATURE_RATIO) {
      this.miniatureRatio += DELTA_MINIATURE_RATIO;
      if (this.miniatureRatio >= MAX_MINIATURE_RATIO) {
        this.miniatureRatio = MAX_MINIATURE_RATIO;
      }
    }
    if (!isMiniature && this.miniatureRatio > MIN_MINIATURE_RATIO) {
      this.miniatureRatio -= DELTA_MINIATURE_RATIO;
      if (this.miniatureRatio <= MIN_MINIATURE_RATIO) {
        this.miniatureRatio = MIN_MINIATURE_RATIO;
      }
    }
  }

  /**
   * ランドマークオブジェクトの更新
   * @param landmarkObject ランドマークオブジェクト
   * @param depthObject 深度オブジェクト
   * @param landmarkInfo 付与されている情報
   * @param placement 回転などの情報
   * @param centerWorldPosition 中心のワールド座標
   * @returns {void}
   */
  updateLandmarkObject(
    landmarkObject: Object3D,
    depthObject: Object3D,
    landmarkInfo: LandmarkObjectInfo,
    placement: LandmarkPlacement,
    centerWorldPosition: Vector3
  ): void {
    if (!this.hasLandmarkObject(landmarkObject)) {
      return;
    }

    const polarPhi = 1.0 * this.mapStatus.getPolarPhi() + 0.0 * Math.PI;
    const polarTheta = 0.25 * Math.PI;
    const radius = 1000000000000000.0;
    const lightDiff = new Vector3(
      radius * Math.cos(polarTheta) * Math.cos(polarPhi),
      radius * Math.cos(polarTheta) * Math.sin(polarPhi),
      radius * Math.sin(polarTheta)
    );

    const material = landmarkObject.getMaterial() as PbrMetallicRoughnessMaterial;
    material.setLight(lightDiff);

    const normalScalar = placement.scale * 2.1;
    const miniScalar = normalScalar * (1.0 / 2 ** (this.mapStatus.zoomLevel - this.maxZoomLevelMiniature));
    const scalar = normalScalar * (MAX_MINIATURE_RATIO - this.miniatureRatio) + miniScalar * this.miniatureRatio;
    const scale = landmarkInfo.localScale._multiply(scalar);
    landmarkObject.setScale(scale);
    depthObject.setScale(scale);

    const radian = placement.rotation * DEGREE_TO_RADIAN;
    const phi = this.mapStatus.getPolarPhi() + Math.PI / 2 + radian;
    const axis = Quaternion.identity()._rotateZ(phi)._product(new Vector3(1, 0, 0));
    const theta = this.mapStatus.getPolarTheta() - Math.PI / 4;
    const miniQuaternion = Quaternion.fromRadianAndAxis(theta * this.miniatureRatio, axis);
    const landmarkRotation = Quaternion.identity()._rotateZ(-radian)._multiply(miniQuaternion);
    const globalRotation = landmarkRotation._multiply(landmarkInfo.localRotation);
    landmarkObject.setRotation(globalRotation);
    depthObject.setRotation(globalRotation);

    const location = placement.latLng;
    const worldPosition = calculateWorldCoordinate(location);
    const localTranslation = landmarkRotation._product(landmarkInfo.localPosition)._multiply(scalar);
    const position = worldPosition._subtract(centerWorldPosition)._add(localTranslation);
    landmarkObject.setPosition(position);
    depthObject.setPosition(position);
  }

  /** @override */
  getIdenticalLayerName(): string {
    return LAYER_NAME_LANDMARK;
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    for (const landmarkObject of this.landmarkObjects) {
      landmarkObject.destroy();
    }
    this.landmarkObjects = [];
  }

  /**
   * レイヤーにランドマークオブジェクトがあるかどうか
   * @param object3D ランドマークのオブジェクト
   * @returns すでにあれば `true` そうでなければ `false`
   */
  hasLandmarkObject(object3D: Object3D): boolean {
    return this.landmarkObjects.includes(object3D);
  }

  /**
   * レイヤーに深度オブジェクトがあるかどうか
   * @param object3D 深度オブジェクト
   * @returns すでにあれば `true` そうでなければ `false`
   */
  hasDepthObject(object3D: Object3D): boolean {
    return this.depthObjects.includes(object3D);
  }

  /**
   * ランドマークオブジェクト追加
   * @param object3D ランドマークのオブジェクト
   * @returns {void}
   */
  addLandmarkObject(object3D: Object3D): void {
    if (this.hasLandmarkObject(object3D)) {
      return;
    }
    this.landmarkObjects.push(object3D);
  }

  /**
   * 深度オブジェクト追加
   * @param object3D 深度オブジェクト
   * @returns {void}
   */
  addDepthObject(object3D: Object3D): void {
    if (this.hasDepthObject(object3D)) {
      return;
    }
    this.depthObjects.push(object3D);
  }

  /**
   * ランドマークオブジェクトの削除
   * @param object3D ランドマークのオブジェクト
   * @returns {void}
   */
  removeLandmarkObject(object3D: Object3D): void {
    const index = this.landmarkObjects.indexOf(object3D);
    if (index < 0) {
      return;
    }
    this.landmarkObjects.splice(index, 1);
  }

  /**
   * 深度オブジェクトの削除
   * @param object3D 深度オブジェクト
   * @returns {void}
   */
  removeDepthObject(object3D: Object3D): void {
    const index = this.depthObjects.indexOf(object3D);
    if (index < 0) {
      return;
    }
    this.depthObjects.splice(index, 1);
  }

  /** @override */
  getCollisions(_ray: Ray3): Collision[] {
    return [];
  }

  /** @override */
  requireNoRotationMatrix(): boolean {
    return false;
  }
}

export {LandmarkObjectLayer, LAYER_NAME_LANDMARK};
