import {GaiaContext} from '../GaiaContext';
import {MapStatus} from '../models/MapStatus';
import {MapParts} from './MapParts';
import {Camera} from '../../engine/camera/Camera';

/**
 * 地図上のパーツ表示制御コントローラー
 */
class MapPartsController {
  private readonly context: GaiaContext;
  private readonly camera: Camera;

  private readonly partsList: MapParts[] = [];

  /**
   * コンストラクタ
   * @param context コンテキスト
   * @param camera Camera
   */
  constructor(context: GaiaContext, camera: Camera) {
    this.context = context;
    this.context.addOnMapStatusUpdateListener((s) => this.onUpdateMapStatus(s));

    this.camera = camera;
  }

  /**
   * 地図パーツの追加
   * @param parts 地図パーツ
   * @returns {void}
   */
  addParts(parts: MapParts): void {
    this.partsList.push(parts);
    this.context.getBaseElement().appendChild(parts.getParts());
    parts.onUpdateStatus(this.context.getMapStatus(), this.camera);
  }

  /**
   * 地図パーツの削除
   * @param parts 地図パーツ
   * @returns {void}
   */
  removeParts(parts: MapParts): void {
    const idx = this.partsList.indexOf(parts);
    if (idx < 0) {
      return;
    }
    this.context.getBaseElement().removeChild(parts.getParts());
    this.partsList.splice(idx, 1);
  }

  /**
   * 地図ステータス更新
   * @param status MapStatus
   * @returns {void}
   */
  private onUpdateMapStatus(status: MapStatus): void {
    for (const parts of this.partsList) {
      parts.onUpdateStatus(status, this.camera);
    }
  }
}

export {MapPartsController};
