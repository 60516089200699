import {LatLng, Point} from '../../../../../gaia/value';
import {LandmarkCondition} from '../../../../../gaia/value/LandmarkCondition';
import {Quaternion} from '../../../../common/math/Quaternion';
import {Vector2} from '../../../../common/math/Vector2';
import {Vector3} from '../../../../common/math/Vector3';
import {Camera} from '../../../../engine/camera/Camera';
import {CustomGeometry} from '../../../../engine/geometry/CustomGeometry';
import {GltfReader} from '../../../../engine/gltf/GltfReader';
import {LayerUpdateNotifierFunc} from '../../../../engine/layer/Layer';
import {DepthMaterial} from '../../../../engine/material/DepthMaterial';
import {PbrMetallicRoughnessMaterial} from '../../../../engine/material/PbrMetallicRoughnessMaterial';
import {Object3D} from '../../../../engine/object/Object3D';
import {GaiaContext} from '../../../GaiaContext';
import {LandmarkObjectLayer, LAYER_NAME_LANDMARK} from '../../../layer/LandmarkObjectLayer';
import {LandmarkLoader, LandmarkMetadata} from '../../../loader/LandmarkLoader';
import {MapStatus} from '../../../models/MapStatus';
import {calculateWorldCoordinate} from '../../../utils/MapUtil';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';
import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';

type LandmarkObjectInfo = {
  localPosition: Vector3;
  localRotation: Quaternion;
  localScale: Vector3;
};

/**
 * 3Dランドマークを扱う描画キット
 */
class LandmarkObjectRenderKit extends AbstractObjectRenderKit {
  private landmarkLayer: LandmarkObjectLayer;
  private camera: Camera;

  private condition?: LandmarkCondition;

  private landmarkLoader: LandmarkLoader;

  private landmarkObjectMap: Map<string, Object3D[]>;
  private depthObjectMap: Map<string, Object3D[]>;
  private landmarkObjectInfoMap: Map<string, LandmarkObjectInfo[]>;

  private previousZoomLevel: number;
  private mapStatus: MapStatus;

  private notifyUpdate?: LayerUpdateNotifierFunc;

  /**
   * コンストラクタ
   * @param context GaiaContext
   * @param renderKitCtl MapRenderKitController
   * @param camera Camera
   */
  constructor(context: GaiaContext, renderKitCtl: MapRenderKitController, camera: Camera) {
    const landmarkLayer = new LandmarkObjectLayer(context, camera);
    super(context, landmarkLayer, renderKitCtl);

    this.landmarkLayer = landmarkLayer;
    this.camera = camera;

    this.landmarkLoader = new LandmarkLoader(context, () => {
      this.updateDrawObjects(this.mapStatus);
      this.notifyUpdate?.();
    });

    this.landmarkObjectMap = new Map();
    this.depthObjectMap = new Map();
    this.landmarkObjectInfoMap = new Map();

    this.previousZoomLevel = context.getMapStatus().zoomLevel;
    this.mapStatus = context.getMapStatus();
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return LAYER_NAME_LANDMARK;
  }

  /** @override */
  onDestroy(): void {
    this.removeAllLandmarkObjects();
    for (const objects of this.landmarkObjectMap.values()) {
      for (const object3D of objects) {
        object3D.destroy();
      }
    }
    this.landmarkObjectMap = new Map();
    this.landmarkLayer.destroy();
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    if (!this.condition) {
      return;
    }

    const metadata = this.landmarkLoader.getMetadata();

    if (!metadata) {
      this.landmarkLoader.requestMetadata();
      return;
    }

    // ズームレベルが表示ズームレンジ外に変わった場合はすべてのランドマークを非表示にする
    if (
      this.condition.visibleZoomRange.isInRange(this.previousZoomLevel) &&
      !this.condition.visibleZoomRange.isInRange(mapStatus.zoomLevel)
    ) {
      this.removeAllLandmarkObjects();
    }
    this.previousZoomLevel = mapStatus.zoomLevel;
    this.mapStatus = mapStatus;

    // ズームレベルが表示ズームレンジ外のとき、更新を行わない
    if (!this.condition.visibleZoomRange.isInRange(mapStatus.zoomLevel)) {
      return;
    }

    this.addLandmarkObjectIfNeeded(metadata);
    this.updateObjects(metadata);
  }

  /**
   * 画面内にまだ描画されていない3Dランドマークがあれば追加する
   * @param metadata メタデータ
   * @returns {void}
   */
  private addLandmarkObjectIfNeeded(metadata: LandmarkMetadata): void {
    const offset = this.context.getMapStatus().centerOffset;
    const centerWorldPosition = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);
    for (const [name, placement] of metadata.placement.entries()) {
      // 画面外にオブジェクトがあれば非表示にする
      const isLandmarkInViewport =
        this.isLocationInViewport(placement.latLng, centerWorldPosition, offset) ||
        this.isLocationInViewport(placement.corners.topLeft, centerWorldPosition, offset) ||
        this.isLocationInViewport(placement.corners.topRight, centerWorldPosition, offset) ||
        this.isLocationInViewport(placement.corners.bottomLeft, centerWorldPosition, offset) ||
        this.isLocationInViewport(placement.corners.bottomRight, centerWorldPosition, offset);
      if (!isLandmarkInViewport) {
        const objects = this.landmarkObjectMap.get(name);
        if (objects) {
          for (const object3D of objects) {
            this.landmarkLayer.removeLandmarkObject(object3D);
          }
        }
        const depthObjects = this.depthObjectMap.get(name);
        if (depthObjects) {
          for (const object3D of depthObjects) {
            this.landmarkLayer.removeDepthObject(object3D);
          }
        }
        continue;
      }

      // 描画済みであれば何もしない
      if (this.landmarkObjectMap.has(name)) {
        const objects = this.landmarkObjectMap.get(name);
        const depthObjects = this.depthObjectMap.get(name);
        if (objects) {
          for (const object3D of objects) {
            this.landmarkLayer.addLandmarkObject(object3D);
          }
        }
        if (depthObjects) {
          for (const object3D of depthObjects) {
            this.landmarkLayer.addDepthObject(object3D);
          }
        }
        continue;
      }

      // gltf形式がサポートされていないランドマークの場合は要求しない
      if (placement.available.indexOf('gltf') === -1) {
        continue;
      }

      const gltf = this.landmarkLoader.getLandmark(name);
      if (!gltf) {
        // ランドマークのデータがない場合は、通信して取得するよう要求する
        this.landmarkLoader.requestLandmark(name);
        continue;
      }

      // まだ描画されていない場合は、glTFを解析してObject3Dを作成する
      const gltfReader = new GltfReader();
      const success = gltfReader.parse(gltf);
      if (!success) {
        continue;
      }

      const gl = this.context.getGLContext();
      const builtGltfNodeList = gltfReader.build(gl);
      if (!builtGltfNodeList) {
        // 解析に失敗した場合はデータ不正なので何も描画しない
        this.landmarkObjectMap.set(name, []);
        this.depthObjectMap.set(name, []);
        this.landmarkObjectInfoMap.set(name, []);
        continue;
      }

      const landmarkObjectList: Object3D[] = [];
      const depthObjectList: Object3D[] = [];
      const landmarkInfoList: LandmarkObjectInfo[] = [];
      for (const built of builtGltfNodeList) {
        const node = built.node;

        const landmarkMaterial = new PbrMetallicRoughnessMaterial(gl, built.geometry, built.color);
        const landmarkObject = new Object3D(node.translation, node.rotation, node.scale, landmarkMaterial);
        landmarkObjectList.push(landmarkObject);

        // verticesのshallow copyを作成し、適当なuv座標をさしこむ
        const depthVertices = [...built.geometry.getVertices()];
        const deleteCount = 0;
        const u = 0.1;
        const v = 0.2;
        for (let index = built.geometry.getVertices().length; index > 0; index -= 3) {
          depthVertices.splice(index, deleteCount, u, v);
        }
        const depthGeometry = new CustomGeometry(depthVertices, built.geometry.getIndices());
        const depthMaterial = new DepthMaterial(gl, depthGeometry);
        const depthObject = new Object3D(node.translation, node.rotation, node.scale, depthMaterial);
        depthObjectList.push(depthObject);

        landmarkInfoList.push({
          localPosition: node.translation.clone(),
          localRotation: node.rotation.clone(),
          localScale: node.scale.clone(),
        });
      }

      this.landmarkObjectMap.set(name, landmarkObjectList);
      this.depthObjectMap.set(name, depthObjectList);
      this.landmarkObjectInfoMap.set(name, landmarkInfoList);
    }
  }

  /**
   * 緯度経度が画面内に入っているか判定する
   * @param location 緯度経度
   * @param centerWorldPosition 現在のカメラが見ている世界の中心
   * @param offset オフセット
   * @returns 緯度経度が画面内に入っているかどうか
   */
  private isLocationInViewport(location: LatLng, centerWorldPosition: Vector3, offset: Point): boolean {
    const {height, width} = this.context.getCanvasElement();
    const worldPosition = calculateWorldCoordinate(location);
    const clientPosition = this.camera.worldToClient(worldPosition._subtract(centerWorldPosition));
    if (!clientPosition) {
      return false;
    }
    const point = new Vector2(
      ((width / devicePixelRatio) * ((-clientPosition.x + 1.0) / 2) + offset.x * 2) / width,
      ((height / devicePixelRatio) * ((clientPosition.y + 1.0) / 2) + offset.y * 2) / height
    );

    const min = -0.1;
    const max = 1.1;
    return point.x >= min && point.x <= max && point.y >= min && point.y <= max;
  }

  /**
   * 描画済みのランドマークの位置を更新する
   * @param metadata メタデータ
   * @returns {void}
   */
  updateObjects(metadata: LandmarkMetadata): void {
    if (!this.condition) {
      return;
    }

    this.landmarkLayer.update(this.mapStatus, this.condition.maxZoomLevelMiniature);

    const centerWorldPosition = calculateWorldCoordinate(this.context.getMapStatus().centerLocation);
    for (const name of this.landmarkObjectMap.keys()) {
      const placement = metadata.placement.get(name);
      if (!placement) {
        continue;
      }
      const landmarkObjectList = this.landmarkObjectMap.get(name);
      const depthObjectList = this.depthObjectMap.get(name);
      const landmarkInfoList = this.landmarkObjectInfoMap.get(name);
      if (
        !placement ||
        !landmarkObjectList ||
        !depthObjectList ||
        !landmarkInfoList ||
        landmarkObjectList.length !== depthObjectList.length ||
        landmarkObjectList.length !== landmarkInfoList.length
      ) {
        continue;
      }

      for (let index = 0; index < landmarkInfoList.length; index++) {
        const landmarkObject = landmarkObjectList[index];
        const depthObject = depthObjectList[index];
        const landmarkInfo = landmarkInfoList[index];
        this.landmarkLayer.updateLandmarkObject(
          landmarkObject,
          depthObject,
          landmarkInfo,
          placement,
          centerWorldPosition
        );
      }
    }
  }

  /**
   * すべてのランドマークを非表示にする
   * @returns {void}
   */
  private removeAllLandmarkObjects(): void {
    for (const objects of this.landmarkObjectMap.values()) {
      for (const object3D of objects) {
        this.landmarkLayer.removeLandmarkObject(object3D);
      }
    }
    for (const objects of this.depthObjectMap.values()) {
      for (const object3D of objects) {
        this.landmarkLayer.removeDepthObject(object3D);
      }
    }
  }

  /**
   * 条件の設定
   * @param condition LandmarkCondition
   * @returns {void}
   */
  setLandmarkConditeon(condition?: LandmarkCondition): void {
    this.removeAllLandmarkObjects();
    this.condition = condition;
  }

  /**
   * レイヤー更新通知関数を設定
   * @param notifierFunc コールバック関数
   * @returns {void}
   */
  setNotifierFunc(notifierFunc: LayerUpdateNotifierFunc): void {
    this.notifyUpdate = notifierFunc;
  }
}

export {LandmarkObjectRenderKit, LandmarkObjectInfo};
