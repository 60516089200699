import {Vector2} from '../../../common/math/Vector2';
import RBush, {BBox} from 'rbush';
import {AbstractAnnotationData} from '../../models/annotation/AbstractAnnotationData';

type AnnotationBBox = BBox & {
  data?: AbstractAnnotationData;
};

/**
 * 注記用間引きヘルパークラス
 */
class AnnotationCullHelper {
  readonly tree: RBush<AnnotationBBox>;

  /**
   * コンストラクタ
   */
  constructor() {
    this.tree = new RBush();
  }

  /**
   * スペースが存在するか判定
   * @param topLeft 左上位置
   * @param topRight 右上位置
   * @param bottomLeft 左下位置
   * @param bottomRight 右下位置
   * @param data AbstractAnnotationData
   * @param margin 空きスペース補正値
   * @returns `true`: スペースあり `false`: スペースなし
   */
  canProvideSpace(
    topLeft: Vector2,
    topRight: Vector2,
    bottomLeft: Vector2,
    bottomRight: Vector2,
    data?: AbstractAnnotationData,
    margin?: number
  ): boolean {
    const marginVal = margin ?? 0;

    const minX = Math.min(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x) - marginVal;
    const minY = Math.min(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y) - marginVal;
    const maxX = Math.max(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x) + marginVal;
    const maxY = Math.max(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y) + marginVal;

    const newRect: AnnotationBBox = {minX, minY, maxX, maxY, data};
    if (this.tree.collides(newRect)) {
      return false;
    }

    this.tree.insert(newRect);
    return true;
  }

  /**
   * 重なり判定リストをクリア
   * @returns {void}
   */
  clear(): void {
    this.tree.clear();
  }
}

export {AnnotationCullHelper};
