import {JsonObject} from '../../../../../gaia/types';
import {Optional} from '../../../../common/types';
import {createMap, GltfId} from '../../GltfReader';
import {GltfBufferView} from './GltfBufferView';

/**
 * JsonObjectからバッファビューを作成する
 * @param bufferViewObject オブジェクト
 * @returns バッファビュー、オブジェクトの情報が不十分な場合は `undefined`
 */
const createBufferViewFromJsonObject = (bufferViewObject: JsonObject): Optional<GltfBufferView> => {
  const buffer = bufferViewObject.buffer;
  const byteLength = bufferViewObject.byteLength;
  const byteOffset = bufferViewObject.byteOffset ?? 0;
  const target = bufferViewObject.target;
  const byteStride = bufferViewObject.byteStride;
  if (buffer === undefined || byteLength === undefined) {
    return undefined;
  }
  return new GltfBufferView(buffer, byteLength, byteOffset, target, byteStride);
};

/**
 * BLTFのbufferViews
 */
class GltfBufferViewsReader {
  private bufferViews: Map<GltfId, GltfBufferView>;

  /**
   * コンストラクタ
   * @param json GLTFのbufferViewsの部分
   */
  constructor() {
    this.bufferViews = new Map();
  }

  /**
   * パース
   * @param json glTFのbufferViewsの部分
   * @returns パースが成功すれば `true` , そうでなければ `false`
   */
  parse(json: JsonObject): boolean {
    const bufferViews = createMap(json, createBufferViewFromJsonObject);
    if (!bufferViews) {
      return false;
    }
    this.bufferViews = bufferViews;
    return true;
  }

  /**
   * バッファを取得
   * @param key キー
   * @returns キーに対応するバッファ
   */
  get(key: GltfId): Optional<GltfBufferView> {
    return this.bufferViews.get(key);
  }
}

export {GltfBufferViewsReader};
