import {OrbitConditionInitOptions} from '../types';

/**
 * 軌跡(moon)コンディション
 */
class OrbitCondition {
  readonly openedRoad?: boolean;
  readonly baseDate?: Date;
  readonly server?: string;

  /**
   * コンストラクタ
   * @deprecated 端末内で形状描画するseed版を利用してください
   * @param options 表示オプション
   * @param server タイル取得向き先URL
   */
  constructor(options: OrbitConditionInitOptions, server?: string) {
    this.openedRoad = options.openedRoad;
    this.baseDate = options.baseDate;
    this.server = server;
  }
}

export {OrbitCondition};
