import {GaiaContext} from '../../../GaiaContext';
import {CongestionLayer} from '../../../layer/CongestionLayer';
import {CongestionInfo, Color} from '../../../../../gaia/value';
import {MapStatus} from '../../../models/MapStatus';
import {CongestionLevel} from '../../../../../gaia/types';
import {AbstractObjectRenderKit} from './AbstractObjectRenderKit';
import {MapRenderKitController, ObjectRenderKitMapping} from '../MapRenderKitController';

const TILE_LAYER_NAME_CONGESTION = 'congestion';

/**
 * 混雑度表示制御マネージャー
 */
class CongestionObjectRenderKit extends AbstractObjectRenderKit {
  private readonly congestionLayer: CongestionLayer;

  /**
   * コンストラクタ
   * @param context Context
   * @param congestionLayer CongestionLayer
   * @param renderKitCtl MapRenderKitController
   */
  constructor(context: GaiaContext, congestionLayer: CongestionLayer, renderKitCtl: MapRenderKitController) {
    super(context, congestionLayer, renderKitCtl);

    this.congestionLayer = congestionLayer;
  }

  /** @override */
  get identicalName(): keyof ObjectRenderKitMapping {
    return TILE_LAYER_NAME_CONGESTION;
  }

  /** @override */
  updateDrawObjects(mapStatus: MapStatus): void {
    this.congestionLayer.update(mapStatus);
  }

  /**
   * レイヤーを取得
   * @returns 混雑度レイヤー
   */
  getLayer(): CongestionLayer {
    return this.congestionLayer;
  }

  /**
   * 混雑度情報を設定
   * @param congestionInfo 混雑度情報
   * @param colorTable 配色
   * @returns {void}
   */
  setCongestionInfo(congestionInfo: CongestionInfo, colorTable?: {[key in CongestionLevel]: Color}): void {
    this.congestionLayer.setCongestionInfo(congestionInfo, colorTable);
  }

  /**
   * 混雑度情報をクリア
   * @returns {void}
   */
  clearCongestionInfo(): void {
    this.congestionLayer.clearCongestionInfo();
  }

  /**
   * 描画更新
   * @param mapStatus 地図状態
   * @returns {void}
   */
  update(mapStatus: MapStatus): void {
    this.congestionLayer.update(mapStatus);
  }

  /** @override */
  onDestroy(): void {
    this.congestionLayer.destroy();
  }
}

export {CongestionObjectRenderKit};
