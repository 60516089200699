import {MapStatus} from '../../../models/MapStatus';
import {GaiaContext} from '../../../GaiaContext';
import {TileNumber} from '../../../models/TileNumber';
import {TileRenderKitMapping} from '../MapRenderKitController';
import {Optional, ZoomLevelFixFunc} from '../../../../common/types';
import {TexturePlaneUVCoordinate} from '../../../../engine/geometry/TexturePlaneUVCoordinate';
import {Vector2} from '../../../../common/math/Vector2';
import {ArrayList} from '../../../../common/collection/ArrayList';
import {TileObjectLayer} from '../../../layer/TileObjectLayer';
import {getZoomLevelFixFunc} from '../../../utils/MapUtil';
import {Camera} from '../../../../engine/camera/Camera';
import {GradationTileObjectLayer} from '../../../layer/GradationTileObjectLayer';

/**
 * タイル描画処理のベースクラス
 * TODO: 移せる処理は随時こっちに移す
 */
abstract class AbstractTileRenderKit {
  /** Context */
  protected readonly context: GaiaContext;

  protected readonly layer: TileObjectLayer | GradationTileObjectLayer;

  protected readonly fixIntZoomLevel: ZoomLevelFixFunc;

  protected readonly camera: Camera;

  /** 機能の利用可否 */
  protected isEnable = true;

  /**
   * コンストラクタ
   * @param context Context
   * @param camera Camera
   * @param usesFadeInAnimation 新しいタイル表示時にフェードインのアニメーションを利用するかどうか
   * @param layerName レイヤー名
   * @param transparency レイヤー不透明度
   * @param isGradation グラデーションするかどうか
   * @param representativeZoomLevels 代表ズームレベル
   */
  constructor(
    context: GaiaContext,
    camera: Camera,
    usesFadeInAnimation = false,
    layerName?: string,
    transparency?: number,
    isGradation = false,
    representativeZoomLevels?: number[]
  ) {
    this.context = context;
    this.camera = camera;

    if (isGradation) {
      this.layer = new GradationTileObjectLayer(
        this.context,
        false,
        usesFadeInAnimation,
        layerName,
        representativeZoomLevels
      );
    } else {
      this.layer = new TileObjectLayer(this.context, false, usesFadeInAnimation, layerName, transparency);
    }

    this.fixIntZoomLevel = getZoomLevelFixFunc(this.context);
  }

  /** RenderKit特定用キー */
  abstract get identicalName(): keyof TileRenderKitMapping;

  /**
   * タイルのレンダリング
   * @param mapStatus 地図状態
   * @param tileList 描画対象タイルリスト
   * @returns {void}
   */
  abstract updateDrawObjects(mapStatus: MapStatus, tileList: ArrayList<TileNumber>): void;

  /**
   * ローダーのリクエスト実行
   * @returns {void}
   */
  abstract executeLoader(): void;

  /**
   * 破棄された時の処理
   * @returns {void}
   */
  abstract onDestroy(): void;

  /**
   * 標高モードが設定されているかを返す
   * @returns 標高モードの状態
   */
  isAltitudeMode(): boolean {
    return this.layer.isAltitudeMode();
  }

  /**
   * 標高モードの状態を設定する
   * @param altitudeMode 標高モードの状態
   * @returns {void}
   */
  setAltitudeMode(altitudeMode: boolean): void {
    this.layer.setAltitudeMode(altitudeMode);
  }

  /**
   * レイヤーを取得
   * @returns レイヤー
   */
  getLayer(): TileObjectLayer | GradationTileObjectLayer {
    return this.layer;
  }

  /**
   * 破棄処理
   * @returns {void}
   */
  destroy(): void {
    this.layer.destroy();
    this.onDestroy();
  }
}

/**
 * タイル番号とその上位のタイル番号から、補完描画の際に必要なUV座標を計算する
 * @param tileNumber タイル番号
 * @param upperTileNumber ズームレベルが1小さいタイル番号
 * @returns テクスチャのUV座標を返す。補完するタイル番号でなければnullを返す
 */
const calculateTexturePlaneUVCoordinate = (
  tileNumber: TileNumber,
  upperTileNumber: TileNumber
): Optional<TexturePlaneUVCoordinate> => {
  if (tileNumber.z < upperTileNumber.z) {
    return null;
  }
  if (tileNumber.z === upperTileNumber.z) {
    return TexturePlaneUVCoordinate.defaultUVCoordinate();
  }

  const diffZoomLevel = tileNumber.z - upperTileNumber.z;
  const magnification = Math.round(2 ** diffZoomLevel);
  const topLeftTileNumber = new TileNumber(
    upperTileNumber.x * magnification,
    upperTileNumber.y * magnification,
    upperTileNumber.z * magnification
  );
  const topLeft: Vector2 = new Vector2(
    (tileNumber.x - topLeftTileNumber.x) / magnification,
    (tileNumber.y - topLeftTileNumber.y) / magnification
  );
  const inverseMag = 1 / magnification;
  const topRight: Vector2 = topLeft._add(new Vector2(inverseMag, 0));
  const bottomLeft: Vector2 = topLeft._add(new Vector2(0, inverseMag));
  const bottomRight: Vector2 = topLeft._add(new Vector2(inverseMag, inverseMag));
  return new TexturePlaneUVCoordinate(topLeft, bottomLeft, topRight, bottomRight);
};

export {AbstractTileRenderKit, calculateTexturePlaneUVCoordinate};
